import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(public translate: TranslateService) {
    translate.addLangs(["br", "pt"]);
    translate.setDefaultLang('br');
    let browserLang = 'br';
    if (window.navigator.language == "pt-BR") {
      browserLang = "br";
    } else if(window.navigator.language == "pt-PT") {
      browserLang = "pt";
    }
    translate.use(browserLang.match(/br|pt/) ? browserLang : 'br');
  }
}
