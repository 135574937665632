import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Validacoes } from '../../../../../../utils/validacoes';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-epididimo-esquerdo',
  templateUrl: './epididimo-esquerdo.component.html',
  styleUrls: ['./epididimo-esquerdo.component.css']
})

export class EpididimoEsquerdoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;

  // Epididimite
  @ViewChild('rdEpididimite') rdEpididimite: MatRadioButton;
  @ViewChild('slDimensoesEpididimite') slDimensoesEpididimite: MatSelect;
  @ViewChild('slEcotexturaEpididimite') slEcotexturaEpididimite: MatSelect;
  @ViewChild('slLocalizacaoEpididimite') slLocalizacaoEpididimite: MatSelect;

  // Nódulo
  @ViewChild('ckNodulo') ckNodulo: MatCheckbox;
  @ViewChild('slEcogenicidadeNodulo') slEcogenicidadeNodulo: MatSelect;
  @ViewChild('slEcotexturaNodulo') slEcotexturaNodulo: MatSelect;
  @ViewChild('slCalcificacaoNodulo') slCalcificacaoNodulo: MatSelect;
  @ViewChild('slContornoNodulo') slContornoNodulo: MatSelect;
  @ViewChild('slLocalizacaoNodulo') slLocalizacaoNodulo: MatSelect;
  @ViewChild('slDopplerNodulo') slDopplerNodulo: MatSelect;
  @ViewChild('inMedidaNodulo1') inMedidaNodulo1: ElementRef;
  @ViewChild('inMedidaNodulo2') inMedidaNodulo2: ElementRef;
  @ViewChild('inMedidaNodulo3') inMedidaNodulo3: ElementRef;

  // Cisto
  @ViewChild('ckCisto') ckCisto: MatCheckbox;
  @ViewChild('slLocalizacaoCisto') slLocalizacaoCisto: MatSelect;
  @ViewChild('inMedidaCisto') inMedidaCisto: ElementRef;

  // Coleção
  @ViewChild('ckColecao') ckColecao: MatCheckbox;
  @ViewChild('rdOrganizada') rdOrganizada: MatRadioButton;
  @ViewChild('rdNaoOrganizada') rdNaoOrganizada: MatRadioButton;
  @ViewChild('inMedidaColecao1') inMedidaColecao1: ElementRef;
  @ViewChild('inMedidaColecao2') inMedidaColecao2: ElementRef;
  @ViewChild('inMedidaColecao3') inMedidaColecao3: ElementRef;
  @ViewChild('inVolumeColecao') inVolumeColecao: ElementRef;
  @ViewChild('slLocalizacaoColecao') slLocalizacaoColecao: MatSelect;

  dimensoes: ArrGenerico[] = [
    { value: 'preservadas', viewValue: 'Preservadas' },
    { value: 'aumentadas', viewValue: 'Aumentadas' }
  ];

  ecotexturas: ArrGenerico[] = [
    { value: 'homogênea', viewValue: 'Homogênea' },
    { value: 'heterogênea', viewValue: 'Heterogênea' }
  ];

  ecotexturasMasculino: ArrGenerico[] = [
    { value: 'homogêneo', viewValue: 'Homogêneo' },
    { value: 'heterogêneo', viewValue: 'Heterogêneo' }
  ];

  localizacoes: ArrGenerico[] = [
    { value: 'na cabeça', viewValue: 'Cabeça' },
    { value: 'no corpo', viewValue: 'Corpo' },
    { value: 'na cauda', viewValue: 'Cauda' },
    { value: 'difusa', viewValue: 'Difusa' }

  ];

  ecogenicidades: ArrGenerico[] = [
    { value: 'hipoecogênico', viewValue: 'Hipoecogênico' },
    { value: 'isoecogênico', viewValue: 'Isoecogênico' },
    { value: 'hiperecogênico', viewValue: 'Hiperecogênico' }
  ];

  calcificacoes: ArrGenerico[] = [
    { value: 'com', viewValue: 'Com' },
    { value: 'sem', viewValue: 'Sem' }
  ];

  contornos: ArrGenerico[] = [
    { value: 'regulares', viewValue: 'Regulares' },
    { value: 'irregulares', viewValue: 'Irregulares' },
    { value: 'parcialmente caracterizados', viewValue: 'Parcialmente caracterizados' }
  ];

  dopplers: ArrGenerico[] = [
    { value: 'com', viewValue: 'Com' },
    { value: 'sem', viewValue: 'Sem' }
  ];

  showAndHideTerms: any;
  countLesoes = 0;
  volumeColecao = 'Volume';
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahEpididimite: false,
      sahNodulo: false,
      sahCisto: false,
      sahColecao: false,
      sahOrgazinada: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahEpididimite = false;
    this.catchIDs();
  }

  clickEpididimite() {
    this.showAndHideTerms.sahEpididimite = true;
    this.catchIDs();
  }

  clickNodulo() {
    if (this.showAndHideTerms.sahNodulo == false) {
      this.showAndHideTerms.sahNodulo = true;
    } else {
      this.showAndHideTerms.sahNodulo = false;
    }
    this.catchIDs();
  }

  clickCisto() {
    if (this.showAndHideTerms.sahCisto == false) {
      this.showAndHideTerms.sahCisto = true;
    } else {
      this.showAndHideTerms.sahCisto = false;
    }
    this.catchIDs();
  }

  clickColecao() {
    if (this.showAndHideTerms.sahColecao == false) {
      this.showAndHideTerms.sahColecao = true;
      this.showAndHideTerms.sahOrgazinada = true;
    } else {
      this.showAndHideTerms.sahColecao = false;
    }
    this.catchIDs();
  }

  clickOrganizada() {
    this.showAndHideTerms.sahOrgazinada = true;
    this.catchIDs();
  }

  clickNaoOrganizada() {
    this.showAndHideTerms.sahOrgazinada = false;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      this.emitNoduloSimples();

      // Cisto
      if (this.countLesoes > 0) {
        this.emitCistoLesao();
      } else {
        this.emitCistoSimples();
      }

      // Coleção
      if (this.countLesoes > 0) {
        this.emitColecaoLesao();
      } else {
        this.emitColecaoSimples();
      }
    }

    if (this.rdEpididimite.checked) {
      this.termoSelecionado.emit(this.rdEpididimite.id);

      if (this.slDimensoesEpididimite !== undefined && this.slDimensoesEpididimite.value !== undefined) {
        const objeto = { elemento: this.slDimensoesEpididimite, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcotexturaEpididimite !== undefined && this.slEcotexturaEpididimite.value !== undefined) {
        const objeto = { elemento: this.slEcotexturaEpididimite, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacaoEpididimite !== undefined && this.slLocalizacaoEpididimite.value !== undefined) {
        const objeto = { elemento: this.slLocalizacaoEpididimite, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.emitColecaoSimples();
      this.emitNoduloLesao();
      this.emitCistoLesao();

    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  emitNoduloSimples() {
    if (this.ckNodulo !== undefined && this.ckNodulo.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);

      if (this.slEcogenicidadeNodulo !== undefined && this.slEcogenicidadeNodulo.value !== undefined) {
        const objeto = { elemento: this.slEcogenicidadeNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcotexturaNodulo !== undefined && this.slEcotexturaNodulo.value !== undefined) {
        const objeto = { elemento: this.slEcotexturaNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slCalcificacaoNodulo !== undefined && this.slCalcificacaoNodulo.value !== undefined) {
        const objeto = { elemento: this.slCalcificacaoNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slContornoNodulo !== undefined && this.slContornoNodulo.value !== undefined) {
        const objeto = { elemento: this.slContornoNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacaoNodulo !== undefined && this.slLocalizacaoNodulo.value !== undefined) {
        const objeto = { elemento: this.slLocalizacaoNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDopplerNodulo !== undefined && this.slDopplerNodulo.value !== undefined) {
        const objeto = { elemento: this.slDopplerNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoNodulo(true);
    }
  }

  emitNoduloLesao() {
    if (this.ckNodulo !== undefined && this.ckNodulo.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckNodulo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slEcogenicidadeNodulo !== undefined && this.slEcogenicidadeNodulo.value !== undefined) {
        const objeto = { elemento: this.slEcogenicidadeNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcotexturaNodulo !== undefined && this.slEcotexturaNodulo.value !== undefined) {
        const objeto = { elemento: this.slEcotexturaNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slCalcificacaoNodulo !== undefined && this.slCalcificacaoNodulo.value !== undefined) {
        const objeto = { elemento: this.slCalcificacaoNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slContornoNodulo !== undefined && this.slContornoNodulo.value !== undefined) {
        const objeto = { elemento: this.slContornoNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacaoNodulo !== undefined && this.slLocalizacaoNodulo.value !== undefined) {
        const objeto = { elemento: this.slLocalizacaoNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDopplerNodulo !== undefined && this.slDopplerNodulo.value !== undefined) {
        const objeto = { elemento: this.slDopplerNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoNodulo(false);
    }
  }

  emitCistoSimples() {
    if (this.ckCisto !== undefined && this.ckCisto.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCisto.id);

      if (this.slLocalizacaoCisto !== undefined && this.slLocalizacaoCisto.value !== undefined) {
        const objeto = { elemento: this.slLocalizacaoCisto, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedidaCisto !== undefined &&
        this.inMedidaCisto.nativeElement.id !== undefined &&
        this.inMedidaCisto.nativeElement.value != '') {

        const objeto = { elemento: this.inMedidaCisto.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCistoLesao() {
    if (this.ckCisto !== undefined && this.ckCisto.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCisto.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacaoCisto !== undefined && this.slLocalizacaoCisto.value !== undefined) {
        const objeto = { elemento: this.slLocalizacaoCisto, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedidaCisto !== undefined &&
        this.inMedidaCisto.nativeElement.id !== undefined &&
        this.inMedidaCisto.nativeElement.value != '') {

        const objeto = { elemento: this.inMedidaCisto.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitColecaoSimples() {
    if (this.ckColecao !== undefined && this.ckColecao.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckColecao.id);

      this.formataMedindoColecao(true);

      if (this.slLocalizacaoColecao != undefined && this.slLocalizacaoColecao.value !== undefined) {
        const objeto = { elemento: this.slLocalizacaoColecao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.rdOrganizada !== undefined && this.rdOrganizada.checked) {
        this.termoSelecionado.emit(this.rdOrganizada.id);

        let  objeto;
        if (!this.inVolumeColecao.nativeElement.value && this.volumeColecao !== 'Volume') {
          const object  = {id: this.inVolumeColecao.nativeElement.id, value: this.volumeColecao, cenario: this.countLesoes};
          objeto = {elemento: object, isPrimeiraLesao: true};
        }
        else {
          objeto = { elemento: this.inVolumeColecao.nativeElement, isPrimeiraLesao: true };
        }
        this.termosGenericos.emit(objeto);

      } else if (this.rdNaoOrganizada !== undefined && this.rdNaoOrganizada.checked) {
        this.termoSelecionado.emit(this.rdNaoOrganizada.id);
      }
    }
  }

  emitColecaoLesao() {
    if (this.ckColecao !== undefined && this.ckColecao.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckColecao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      this.formataMedindoColecao(false);

      if (this.slLocalizacaoColecao != undefined && this.slLocalizacaoColecao.value !== undefined) {
        const objeto = { elemento: this.slLocalizacaoColecao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.rdOrganizada !== undefined && this.rdOrganizada.checked) {
        const objeto = { id: this.rdOrganizada.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        let  elemento;
        if (!this.inVolumeColecao.nativeElement.value && this.volumeColecao !== 'Volume') {
          const object  = {id: this.inVolumeColecao.nativeElement.id, value: this.volumeColecao, cenario: this.countLesoes};
          elemento = {elemento: object, isPrimeiraLesao: false};
        }
        else {
          elemento = { elemento: this.inVolumeColecao.nativeElement, isPrimeiraLesao: false };
        }
        this.termosGenericos.emit(elemento);

      } else if (this.rdNaoOrganizada !== undefined && this.rdNaoOrganizada.checked) {
        const objeto = { id: this.rdNaoOrganizada.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  formataMedindoNodulo(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedidaNodulo1 != undefined &&
      this.inMedidaNodulo1.nativeElement.id != undefined &&
      this.inMedidaNodulo1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo1.nativeElement.value);
    }

    if (this.inMedidaNodulo2 != undefined &&
      this.inMedidaNodulo2.nativeElement.id != undefined &&
      this.inMedidaNodulo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaNodulo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo2.nativeElement.value);
      }
    }

    if (this.inMedidaNodulo3 != undefined &&
      this.inMedidaNodulo3.nativeElement.id != undefined &&
      this.inMedidaNodulo3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaNodulo3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = { id: 744, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoColecao(isPrimeiraLesao) {
    let medindoValor = '';
    let resultado = 0;

    if (this.inMedidaColecao1 != undefined &&
      this.inMedidaColecao1.nativeElement.id != undefined &&
      this.inMedidaColecao1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaColecao1.nativeElement.value);
      resultado = this.inMedidaColecao1.nativeElement.value;
    }

    if (this.inMedidaColecao2 != undefined &&
      this.inMedidaColecao2.nativeElement.id != undefined &&
      this.inMedidaColecao2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaColecao2.nativeElement.value);
        resultado = resultado * this.inMedidaColecao2.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaColecao2.nativeElement.value);
        resultado = this.inMedidaColecao2.nativeElement.value;
      }
    }

    if (this.inMedidaColecao3 != undefined &&
      this.inMedidaColecao3.nativeElement.id != undefined &&
      this.inMedidaColecao3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaColecao3.nativeElement.value);
        resultado = resultado * this.inMedidaColecao3.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaColecao3.nativeElement.value);        
        resultado = this.inMedidaColecao3.nativeElement.value;
      }
    }

    if (medindoValor != '') {
      resultado = resultado * 0.523;
      this.volumeColecao = resultado.toFixed(1).toString();

      const objetoElemento = { id: 752, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }
}

