import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton } from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-nervo-mediano',
  templateUrl: './nervo-mediano.component.html',
  styleUrls: ['./nervo-mediano.component.css']
})
export class NervoMedianoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdNeuropatia') rdNeuropatia: MatRadioButton;
  @ViewChild('inArea') inArea: ElementRef;

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahNeuropatia: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahNeuropatia = false;
    this.catchIDs();
  }

  clickNeuropatia() {
    this.showAndHideTerms.sahNeuropatia = true;
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);

      if (this.inArea && this.inArea.nativeElement.value) {
        const objeto = { elemento: this.inArea.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdNeuropatia.checked) {
      this.termoSelecionado.emit(this.rdNeuropatia.value);
      
      if (this.inArea && this.inArea.nativeElement.value) {
        const objeto = { elemento: this.inArea.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
