import { Component, OnInit, ViewChild, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';



@Component({
  selector: 'app-tendoes-extensores',
  templateUrl: './tendoes-extensores.component.html',
  styleUrls: ['./tendoes-extensores.component.css']
})
export class TendoesExtensoresComponent implements OnInit {

  public showAndHideTerms: any;
  public addForm: FormGroup;
  public linha: number;
  public objeto: any;
  public objetofilho: any;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();



  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTendinopatia') rdTendinopatia: MatRadioButton;
  @ViewChild('sldedos') sldedos: MatSelect;
  @ViewChildren('TendinopatiaChildren') TendinopatiaChildren: QueryList<any>;



  public dedos: ArrayDropDown[] = [
    { value: '1° dedo', viewValue: '1° Dedo' },
    { value: '2° dedo', viewValue: '2° Dedo' },
    { value: '3° dedo', viewValue: '3° Dedo' },
    { value: '4° dedo', viewValue: '4° Dedo' },
    { value: '5° dedo', viewValue: '5° Dedo' },
  ];


  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahNormal: false,
      sahTendinopatia: true
    };
    this.addForm = this._fb.group({
      Presente: this._fb.array([this.addDedosGrup()]),
    });
  }

  public addDedosGrup(): any {
    return this._fb.group({
      dedo: [],
    });
  }

  public addPresente(): any {
    this.PresenteArray.push(this.addDedosGrup());
    this.catchIDs();
  }

  public removePresente(index): any {
    this.PresenteArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }


  get PresenteArray() {
    return <FormArray>this.addForm.get('Presente');
  }

  public clickNormal(): any {
    this.showAndHideTerms.sahTendinopatia = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  public clickTendinopatia(): any {
    this.showAndHideTerms.sahTendinopatia = true;
    this.showAndHideTerms.sahNormal = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  public changeSelect(): any {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.PresenteArray.value[0].dedo )){
      this.rdTendinopatia.checked = true;
    }
  }
  public verificaVolume(): any {
    this.linha = 0;
    this.TendinopatiaChildren.forEach(obj => {

      if (+obj.id === 1748) this.linha++;


      this.objetofilho = { id: obj.id, value: obj.value, cenario: 1 };
      this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true  };
      this.lesoesDinamicas.emit(this.objeto);
    });
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }
    if (this.rdTendinopatia.checked) {
      this.termoSelecionado.emit(this.rdTendinopatia.value);
      this.verificaVolume();
    }

    this.listaCompleta.emit();
  }


  ajustaArrayDinamico() {
    if (this.rdTendinopatia && !this.rdTendinopatia.checked) {

      const arrayTotal = this.PresenteArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.PresenteArray.length - 1;

        if (index !== 0) {
          this.PresenteArray.removeAt(index);
        }
      }
      this.PresenteArray.reset();
    }
  }
}
