import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuTcComponent } from './menu-tc.component';
import { MatCardModule, MatButtonToggleModule, MatIconModule, MatTooltipModule } from '@angular/material';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonToggleModule,
    MatIconModule,
    MatTooltipModule,
    FilterPipeModule,
    FlexLayoutModule
  ],
  exports: [MenuTcComponent],
  declarations: [MenuTcComponent]
})
export class MenuTcModule { }
