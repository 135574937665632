import { MatRadioButton } from '@angular/material';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-processo-transverso',
  templateUrl: './processo-transverso.component.html',
  styleUrls: ['./processo-transverso.component.css']
})
export class ProcessoTransversoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdDireita') rdDireita: MatRadioButton;
  @ViewChild('rdEsquerda') rdEsquerda: MatRadioButton;
  @ViewChild('rdBilateral') rdBilateral: MatRadioButton;

  countLesoes = 0;
  showAndHideTerms: any;

  fraturaDireitaFormGroup: FormGroup;
  fraturaDireitaBiFormGroup: FormGroup;
  fraturaEsquerdaFormGroup: FormGroup;
  fraturaEsquerdaBiFormGroup: FormGroup;

  fraturaDireita: any;
  fraturaDireitaBi: any;
  fraturaEsquerda: any;
  fraturaEsquerdaBi: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {

    this.iniciaFormFraturaDireita();
    this.iniciaFormFraturaEsquerda();

    this.fraturaDireita = [
      { id: 'direitaT12', value: 'T12', name: 'T12' },
      { id: 'direitaL1', value: 'L1', name: 'L1' },
      { id: 'direitaL2', value: 'L2', name: 'L2' },
      { id: 'direitaL3', value: 'L3', name: 'L3' },
      { id: 'direitaL4', value: 'L4', name: 'L4' },
      { id: 'direitaL5', value: 'L5', name: 'L5' },
    ];

    this.fraturaEsquerda = [
      { id: 'esquerdaT12', value: 'T12', name: 'T12' },
      { id: 'esquerdaL1', value: 'L1', name: 'L1' },
      { id: 'esquerdaL2', value: 'L2', name: 'L2' },
      { id: 'esquerdaL3', value: 'L3', name: 'L3' },
      { id: 'esquerdaL4', value: 'L4', name: 'L4' },
      { id: 'esquerdaL5', value: 'L5', name: 'L5' },
    ];

    this.fraturaDireitaBi = [
      { id: 'biDirT12', value: 'T12', name: 'T12' },
      { id: 'biDirL1', value: 'L1', name: 'L1' },
      { id: 'biDirL2', value: 'L2', name: 'L2' },
      { id: 'biDirL3', value: 'L3', name: 'L3' },
      { id: 'biDirL4', value: 'L4', name: 'L4' },
      { id: 'biDirL5', value: 'L5', name: 'L5' },
    ];

    this.fraturaEsquerdaBi = [
      { id: 'biEsqT12', value: 'T12', name: 'T12' },
      { id: 'biEsqL1', value: 'L1', name: 'L1' },
      { id: 'biEsqL2', value: 'L2', name: 'L2' },
      { id: 'biEsqL3', value: 'L3', name: 'L3' },
      { id: 'biEsqL4', value: 'L4', name: 'L4' },
      { id: 'biEsqL5', value: 'L5', name: 'L5' },
    ];

    this.showAndHideTerms = {
      sahFraturaDireita: false,
      sahFraturaEsquerda: false,
      sahFraturaBilateral: false
    };
  }

  clickNormal(){
    this.showAndHideTerms.sahFraturaDireita = false;
    this.showAndHideTerms.sahFraturaEsquerda = false;
    this.showAndHideTerms.sahFraturaBilateral = false;
    this.catchIDs();
  }

  clickFraturaDireita(){
    this.showAndHideTerms.sahFraturaDireita = true,
    this.showAndHideTerms.sahFraturaEsquerda = false,
    this.showAndHideTerms.sahFraturaBilateral = false
    this.catchIDs();
  }

  clickFraturaEsquerda(){
    this.showAndHideTerms.sahFraturaDireita = false;
    this.showAndHideTerms.sahFraturaEsquerda = true;
    this.showAndHideTerms.sahFraturaBilateral = false;
    this.catchIDs();
  }

  clickBilateral(){
    this.showAndHideTerms.sahFraturaDireita = false;
    this.showAndHideTerms.sahFraturaEsquerda = false;
    this.showAndHideTerms.sahFraturaBilateral = true;
    this.catchIDs();
  }

  clickCheckboxFraturaDireita(event) {
    let fraturaDireita= <FormArray>this.fraturaDireitaFormGroup.get('fraturaDireita') as FormArray;

    if (event.checked) {
      fraturaDireita.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iLit = fraturaDireita.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      fraturaDireita.removeAt(iLit);
    }

    this.catchIDs();
  }


  clickCheckboxFraturaEsquerda(event) {
    let fraturaEsquerda= <FormArray>this.fraturaEsquerdaFormGroup.get('fraturaEsquerda') as FormArray;

    if (event.checked) {
      fraturaEsquerda.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iEsc = fraturaEsquerda.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      fraturaEsquerda.removeAt(iEsc);
    }

    this.catchIDs();
  }

  clickCheckboxFraturaBiDir(event) {
    let fraturaBiDireita = <FormArray>this.fraturaDireitaBiFormGroup.get('fraturaDireitaBi') as FormArray;

    if (event.checked) {
      fraturaBiDireita.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iDirBi = fraturaBiDireita.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      fraturaBiDireita.removeAt(iDirBi);
    }

    this.catchIDs();
  }

  clickCheckboxFraturaBiEsq(event) {
    let fraturaBiEsquerda = <FormArray>this.fraturaEsquerdaBiFormGroup.get('fraturaEsquerdaBi') as FormArray;

    if (event.checked) {
      fraturaBiEsquerda.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iEsqBi = fraturaBiEsquerda.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      fraturaBiEsquerda.removeAt(iEsqBi);
    }

    this.catchIDs();
  }

  iniciaFormFraturaDireita() {
    this.fraturaDireitaFormGroup = this.fb.group({
      fraturaDireita: this.fb.array([])
    });
  }

  iniciaFormFraturaEsquerda() {
    this.fraturaEsquerdaFormGroup= this.fb.group({
      fraturaEsquerda: this.fb.array([])
    });
  }

  iniciaFormFraturaMultipla() {
    this.fraturaDireitaBiFormGroup = this.fb.group({
      fraturaDireitaBi: this.fb.array([])
    });
    this.fraturaEsquerdaBiFormGroup = this.fb.group({
      fraturaEsquerdaBi: this.fb.array([]),
    });
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (!this.rdDireita.checked) {
      this.iniciaFormFraturaDireita();
    }
    if (!this.rdEsquerda.checked) {
      this.iniciaFormFraturaEsquerda();
    }

    if (!this.rdBilateral.checked) {
      this.iniciaFormFraturaMultipla();
    }

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdDireita.checked) {
      this.emitFraturaDireita();
    }

    if (this.rdEsquerda.checked) {
      this.emitFraturaEsquerda();
    }

    if (this.rdBilateral.checked) {
      this.emitFraturaBilateral();
    }

    this.listaCompleta.emit();
  }

  emitFraturaDireita(){
    this.termoSelecionado.emit(this.rdDireita.id);

      let posicao = this.fraturaDireitaFormGroup.value.fraturaDireita;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5329, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5327, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
  }

  emitFraturaEsquerda(){
    this.termoSelecionado.emit(this.rdEsquerda.id);

      let posicao = this.fraturaEsquerdaFormGroup.value.fraturaEsquerda;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5330, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5328, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
  }

  emitFraturaBilateral(){
    this.termoSelecionado.emit(this.rdBilateral.id);

      let posicaoDir = this.fraturaDireitaBiFormGroup.value.fraturaDireitaBi;
      let posicaoTratadaDir = this.replaceVirgula(posicaoDir.toString());

      let posicaoEsq = this.fraturaEsquerdaBiFormGroup.value.fraturaEsquerdaBi;
      let posicaoTratadaEsq = this.replaceVirgula(posicaoEsq.toString());

      if (posicaoDir.length > 1) {
        let objeto = { id: 5327, value: posicaoTratadaDir };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);

      } else if (posicaoDir.length == 1) {
        let objeto = { id: 5327, value: posicaoDir };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }

      if (posicaoEsq.length > 1) {
        let objeto = { id: 5328, value: posicaoTratadaEsq };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);

      } else if (posicaoEsq.length == 1) {
        let objeto = { id: 5328, value: posicaoEsq };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
  }

  replaceVirgula(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e ';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }

}
