import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-anatomia-fetal',
  templateUrl: './anatomia-fetal.component.html',
  styleUrls: ['./anatomia-fetal.component.css']
})
export class AnatomiaFetalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaAlterado = new EventEmitter();

  @ViewChild('slCranio') slCranio: MatSelect;
  @ViewChild('slColuna') slColuna: MatSelect;
  @ViewChild('slCoracao') slCoracao: MatSelect;
  @ViewChild('slAbdome') slAbdome: MatSelect;
  @ViewChild('slEstomago') slEstomago: MatSelect;
  @ViewChild('slBexRins') slBexRins: MatSelect;
  @ViewChild('slMaos') slMaos: MatSelect;
  @ViewChild('slPes') slPes: MatSelect;

  @ViewChild('inOutrasCra') inOutrasCra: ElementRef;
  @ViewChild('inAlteradoCol') inAlteradoCol: ElementRef;
  @ViewChild('inAlteradoCor') inAlteradoCor: ElementRef;
  @ViewChild('inAlteradoAbd') inAlteradoAbd: ElementRef;
  @ViewChild('inAlteradoEst') inAlteradoEst: ElementRef;
  @ViewChild('inOutrasBexRins') inOutrasBexRins: ElementRef;
  @ViewChild('inOutrasMaos') inOutrasMaos: ElementRef;
  @ViewChild('inOutrasPes') inOutrasPes: ElementRef;
  

  showAndHideTerms: any;
  
  cranioDefault: string;
  colunaDefault: string;
  coracaoDefault: string;
  abdDefault: string;
  estomagoDefault: string;
  bexigaDefault: string;
  maoDefault: string;
  peDefault: string;
  
  cranios: ArrayDropDown[] = [
    { value: 'Normal', viewValue: 'Aparentemente normal' },
    { value: 'Acrania', viewValue: 'Acrania' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  colunas: ArrayDropDown[] = [
    { value: 'Normal', viewValue: 'Aparentemente normal' },
    { value: 'Alterado: ', viewValue: 'Alterado' }
  ];

  coracoes: ArrayDropDown[] = [
    { value: 'Corte de 4 câmaras aparentemente normal', viewValue: 'Corte de 4 câmaras aparentemente normal' },
    { value: 'Alterado: ', viewValue: 'Alterado' }
  ];

  abdomes: ArrayDropDown[] = [
    { value: 'Normal', viewValue: 'Aparentemente normal' },
    { value: 'Alterado: ', viewValue: 'Alterado' }
  ];

  estomagos: ArrayDropDown[] = [
    { value: 'Normal', viewValue: 'Aparentemente normal' },
    { value: 'Bolha gástrica não visualizada', viewValue: 'Bolha gástrica não visualizada'},
    { value: 'Alterado: ', viewValue: 'Alterado' }
  ];

  bexigas: ArrayDropDown[] = [
    { value: 'Visíveis', viewValue: 'Visíveis' },
    { value: 'Bexiga não visualizada', viewValue: 'Bexiga não visualizada' },
    { value: 'Rins não visualizados', viewValue: 'Rins não visualizados' },
    { value: 'Ambos não visualizados', viewValue: 'Ambos não visualizados' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  maos: ArrayDropDown[] = [
    { value: 'Ambas visualizadas', viewValue: 'Ambas visualizadas' },
    { value: 'Apenas uma mão visualizada', viewValue: 'Apenas uma mão visualizada' },
    { value: 'Não visualizadas', viewValue: 'Não visualizadas' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  pes: ArrayDropDown[] = [
    { value: 'Ambos visualizados', viewValue: 'Ambos visualizados' },
    { value: 'Apenas um pé visualizado', viewValue: 'Apenas um pé visualizado' },
    { value: 'Não visualizados', viewValue: 'Não visualizados' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  constructor() { }

  ngOnInit() {
    this.slCranio.value = '**';
    this.slColuna.value = '**';
    this.slCoracao.value = '**';
    this.slAbdome.value = '**';
    this.slEstomago.value = '**';
    this.slBexRins.value = '**';
    this.slMaos.value = '**';
    this.slPes.value = '**';

    this.showAndHideTerms = {
      sahAlteradoCra: false,
      sahAlteradoCol: false,
      sahAlteradoCor: false,
      sahAlteradoAbd: false,
      sahAlteradoEst: false,
      sahAlteradoBexRins: false,
      sahAlteradoMaos: false,
      sahAlteradoPes: false
    }
    this.catchIDs();
  }

  changeSelect() {
    this.verificaAlterado();
    this.catchIDs();
  }

  blur(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    let elemento;
    debugger

    if (this.slCranio && this.slCranio.value) {
      if (this.inOutrasCra && this.slCranio.value == 'Outras alterações: ')
        elemento = { id: this.slCranio.id, value: this.inOutrasCra.nativeElement.value };
      else
        elemento = { id: this.slCranio.id, value: this.slCranio.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    } else {
      const elemento = { id: 4366, value: this.cranioDefault }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slColuna && this.slColuna.value) {
      if (this.inAlteradoCol && this.slColuna.value == 'Alterado: ')
        elemento = { id: this.slColuna.id, value: this.inAlteradoCol.nativeElement.value };
      else
        elemento = { id: this.slColuna.id, value: this.slColuna.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    } else {
      const elemento = { id: 4367, value: this.colunaDefault }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slCoracao && this.slCoracao.value) {
      if (this.inAlteradoCor && this.slCoracao.value == 'Alterado: ')
        elemento = { id: this.slCoracao.id, value: this.inAlteradoCor.nativeElement.value };
      else
        elemento = { id: this.slCoracao.id, value: this.slCoracao.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    } else {
      const elemento = { id: 4368, value: this.coracaoDefault }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slAbdome && this.slAbdome.value) {
      if (this.inAlteradoAbd && this.slAbdome.value == 'Alterado: ')
        elemento = { id: this.slAbdome.id, value: this.inAlteradoAbd.nativeElement.value };
      else
        elemento = { id: this.slAbdome.id, value: this.slAbdome.value };
      
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    } else {
      const elemento = { id: 4369, value: this.abdDefault }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    } 

    if (this.slEstomago && this.slEstomago.value) {
      if (this.inAlteradoEst && this.slEstomago.value == 'Alterado: ')
        elemento = { id: this.slEstomago.id, value: this.inAlteradoEst.nativeElement.value };
      else
        elemento = { id: this.slEstomago.id, value: this.slEstomago.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    } else {
      const elemento = { id: 4370, value: this.estomagoDefault }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slBexRins && this.slBexRins.value) {
      if (this.inOutrasBexRins && this.slCranio.value == 'Outras alterações: ')
        elemento = { id: this.slBexRins.id, value: this.inOutrasBexRins.nativeElement.value };
      else
        elemento = { id: this.slBexRins.id, value: this.slBexRins.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    } else {
      const elemento = { id: 4371, value: this.bexigaDefault }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slMaos && this.slMaos.value) {
      if (this.inOutrasMaos && this.slMaos.value == 'Outras alterações: ')
        elemento = { id: this.slMaos.id, value: this.inOutrasMaos.nativeElement.value };
      else
        elemento = { id: this.slMaos.id, value: this.slMaos.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    } else {
      const elemento = { id: 4372, value: this.maoDefault }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slPes && this.slPes.value) {
      if (this.inOutrasPes && this.slPes.value == 'Outras alterações: ')
        elemento = { id: this.slPes.id, value: this.inOutrasPes.nativeElement.value };
      else
        elemento = { id: this.slPes.id, value: this.slPes.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    } else {
      const elemento = { id: 4373, value: this.peDefault }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }


    this.listaCompleta.emit();

  }

  verificaAlterado() {
    if (this.slCranio.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoCra = true;
    } else {
      this.showAndHideTerms.sahAlteradoCra = false;
    }

    if (this.slColuna.value === 'Alterado: ') {
      this.showAndHideTerms.sahAlteradoCol = true;
    } else {
      this.showAndHideTerms.sahAlteradoCol = false;
    }

    if (this.slCoracao.value === 'Alterado: ') {
      this.showAndHideTerms.sahAlteradoCor = true;
    } else {
      this.showAndHideTerms.sahAlteradoCor = false;
    }

    if (this.slAbdome.value === 'Alterado: ') {
      this.showAndHideTerms.sahAlteradoAbd = true;
    } else {
      this.showAndHideTerms.sahAlteradoAbd = false;
    }

    if (this.slEstomago.value === 'Alterado: ') {
      this.showAndHideTerms.sahAlteradoEst = true;
    } else {
      this.showAndHideTerms.sahAlteradoEst = false;
    }

    if (this.slBexRins.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoBexRins = true;
    } else {
      this.showAndHideTerms.sahAlteradoBexRins = false;
    }

    if (this.slMaos.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoMaos = true;
    } else {
      this.showAndHideTerms.sahAlteradoMaos = false;
    }

    if (this.slPes.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoPes = true;
    } else {
      this.showAndHideTerms.sahAlteradoPes = false;
    }


  }

}
