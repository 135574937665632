import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Plano } from '../models/BO/Plano';
import { UserHasPlan } from '../models/BO/UserHasPlan';
import { Speciality } from '../models/BO/Speciality';

@Injectable({
  providedIn: 'root'
})
export class ListaPlanosService {

  constructor(private http: HttpClient) { }

  buscarPlanos(): Observable<Plano[]> {
    return this.http.get<Plano[]>(environment.nLaudoApi + "Plan/Plan/");
  }
  buscarPlanosVoucher(): Observable<Plano[]> {
    return this.http.get<Plano[]>(environment.nLaudoApi + "Plan/PlanVoucher/");
  }

  getPlano(): Observable<Plano> {
    return this.http.get<Plano>(environment.nLaudoApi + "Plan/Plan/");
  }

  buscarPlanosPorUsuario(userID: number): Observable<Array<Array<Speciality>>> {
    return this.http.get<Array<Array<Speciality>>>(environment.nLaudoApi + "Plan/BuscaPlanoPorUsuario/" + userID);
  }

  buscaPlanoDoUsuario(userID: number): Observable<Array<UserHasPlan>>{
    return this.http.get<Array<UserHasPlan>>(environment.nLaudoApi + `Plan/PlanByUser/${userID}`);
  }

  buscarFavoritosPorUsuario(userID: number): Observable<Array<Speciality>> {
    return this.http.get<Array<Speciality>>(environment.nLaudoApi + "Plan/BuscaFavoritosPorUsuario/" + userID);
  }
  buscarPlanosPorVoucher(voucherID: number): Observable<Plano> {
    return this.http.get<Plano>(environment.nLaudoApi + "Plan/BuscaPlanoPorVoucher/" + voucherID);
  }
}
