import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { MatSelect } from '@angular/material';


export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-ultrassonografia',
  templateUrl: './ultrassonografia.component.html',
  styleUrls: ['./ultrassonografia.component.css']
})
export class UltrassonografiaComponent implements OnInit {

  @Output() UltrassonografiaEmmit = new EventEmitter();
  @Output() LateralidadeEmit = new EventEmitter();
  @Input() bilateral = false;


  @ViewChild('slUltrassonografia') slUltrassonografia: MatSelect;
  @ViewChild('slLateralidadeMas') slLateralidadeMas: MatSelect;
  @ViewChild('slLateralidadeFem') slLateralidadeFem: MatSelect;


  public ShowHideLado: any;

  public Regiao: ArrGenerico[] = [
    { value: 'do braço', viewValue: 'Braço' },
    { value: 'do antebraço', viewValue: 'Antebraço' },
    { value: 'da coxa', viewValue: 'Coxa' },
    { value: 'da perna', viewValue: 'Perna' }
  ];

  ladosMas: ArrGenerico[] = [
    { value: 'esquerdo', viewValue: 'Esquerdo' },
    { value: 'direito', viewValue: 'Direito' },
  ];

  LadoFem: ArrGenerico[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' }

  ];

  constructor() { }

  ngOnInit() {
    this.ShowHideLado = {
      Masculino: false,
      Feminino: false
    }
  }
  changeSelect() {
    //Mudança para musculos-tendoes-bilateral
    if (!this.bilateral) {
      if (this.slUltrassonografia.value === 'do braço' || this.slUltrassonografia.value === 'do antebraço') {
        this.ShowHideLado.Masculino = true;
        this.ShowHideLado.Feminino = false;
      } else if (this.slUltrassonografia.value === 'da coxa' || this.slUltrassonografia.value === 'da perna') {
        this.ShowHideLado.Masculino = false;
        this.ShowHideLado.Feminino = true;
      }
    }
    if (this.slUltrassonografia) {
      this.UltrassonografiaEmmit.emit(this.slUltrassonografia.value);
    }
  }

  changeSelects() {
    if (this.slLateralidadeMas) {
      this.LateralidadeEmit.emit(this.slLateralidadeMas.value);
    } else if (this.slLateralidadeFem) {
      this.LateralidadeEmit.emit(this.slLateralidadeFem.value);
    }
  }

}
