
import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatDialog, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { LiquidoAmnioticoDialogComponent } from 'src/app/utils/dialog/table/liquido-amniotico-dialog/liquido-amniotico-dialog.component';

@Component({
  selector: 'app-liquido-amniotico',
  templateUrl: './liquido-amniotico.component.html',
  styleUrls: ['./liquido-amniotico.component.css']
})
export class LiquidoAmnioticoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdReduzido') rdReduzido: MatRadioButton;
  @ViewChild('rdAumentado') rdAumentado: MatRadioButton;
  @ViewChild('ckBolsao') ckBolsao: MatCheckbox;

  @ViewChild('inILA') inILA: ElementRef;
  @ViewChild('inMBV') inMBV: ElementRef;
  @ViewChild('ckMBV') ckMBV: MatCheckbox;


  constructor(public dialog: MatDialog) { }
  showAndHideTerms: any;
  unidadeMedida: string;

  ngOnInit() {
    this.showAndHideTerms = {
      sahMbv: false,
    }
    let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickMbv(){
    if (this.showAndHideTerms.sahMbv == false) {
      this.showAndHideTerms.sahMbv = true;
    } else {
      this.showAndHideTerms.sahMbv = false;
    }
  }

  clickNormal() {
    this.catchIDs();
  }

  blurParametros(){
    this.catchIDs();
  }

  clickTabelaReferencia() {
    this.dialog.open(LiquidoAmnioticoDialogComponent, {});

  }

  clickBolsao() {
    if(this.showAndHideTerms.sahInsereMBV == true){
      this.showAndHideTerms.sahInsereMBV = false;
    }
    else{
      this.showAndHideTerms.sahInsereMBV = true;
    }
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }
    if (this.rdAumentado.checked ) {
      this.termoSelecionado.emit(this.rdAumentado.id);
    }
    if (this.rdReduzido.checked) {
      this.termoSelecionado.emit(this.rdReduzido.id);
    }

    if (this.inILA && this.inILA.nativeElement.value){
      const objetoElemento = {id: "2005", value: this.inILA.nativeElement.value};
      const objetoCriado = { elemento: objetoElemento};
      this.termosGenericos.emit(objetoCriado);
    }

    if (this.inMBV && this.inMBV.nativeElement.value){
      const objetoElemento = {id: this.inMBV.nativeElement.id, value: this.inMBV.nativeElement.value};
      const objetoCriado = { elemento: objetoElemento};
      this.termosGenericos.emit(objetoCriado);
    }

    this.listaCompleta.emit();
  }
}
