import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton } from '@angular/material';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-tabela-penis',
  templateUrl: './tabela-penis.component.html',
  styleUrls: ['./tabela-penis.component.css']
})
export class TabelaPenisComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaMedida = new EventEmitter();
  @Output() tabelaTexto = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdnaocitar') rdnaocitar: MatRadioButton;
  @ViewChild('rdcitar') rdcitar: MatRadioButton;

  // Direita
  @ViewChild('VelSisPreDir') VelSisPreDir: ElementRef;
  @ViewChild('VelSisPosDir') VelSisPosDir: ElementRef;
  @ViewChild('VelDisPosDir') VelDisPosDir: ElementRef;
  @ViewChild('AceleracaoDir') AceleracaoDir: ElementRef;

  // Esquerda
  @ViewChild('VelSisPreEsq') VelSisPreEsq: ElementRef;
  @ViewChild('VelSisPosEsq') VelSisPosEsq: ElementRef;
  @ViewChild('VelDisPosEsq') VelDisPosEsq: ElementRef;
  @ViewChild('AceleracaoEsq') AceleracaoEsq: ElementRef;

  //Calibre
  @ViewChild('ckCitarCalibres') ckCitarCalibres: MatCheckbox;
  @ViewChild('CalibrePreEsq') CalibrePreEsq: ElementRef;
  @ViewChild('CalibrePosEsq') CalibrePosEsq: ElementRef;
  @ViewChild('CalibrePosDir') CalibrePosDir: ElementRef;
  @ViewChild('CalibrePreDir') CalibrePreDir: ElementRef;

  //Citar IR
  @ViewChild('ckCitarIR') ckCitarIR: MatCheckbox;
  @ViewChild('IREsq') IREsq: ElementRef;
  @ViewChild('IRDir') IRDir: ElementRef;

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCitar: false,
      sahCalibres: false, 
      sahIR: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public ClickNaoCitar(): any {
    this.showAndHideTerms.sahCitar = false;
    this.showAndHideTerms.sahCalibres = false;
    this.showAndHideTerms.sahIR = false;
    this.catchIDs();
  }

  public ClickCitar(): any {
    this.showAndHideTerms.sahCitar = true;
    this.catchIDs();
  }

  clickCitarCalibres(){
    if (this.showAndHideTerms.sahCalibres == false) {
      this.showAndHideTerms.sahCalibres = true;
    } else {
      this.showAndHideTerms.sahCalibres = false;
    }
    this.catchIDs();
  }

  clickCitarIR(){
    if (this.showAndHideTerms.sahIR == false) {
      this.showAndHideTerms.sahIR = true;
    } else {
      this.showAndHideTerms.sahIR = false;
    }
    this.catchIDs();
  }

  public blurMedida(): any {
    this.catchIDs();
    this.tabelaTexto.emit('element');
  }

  public catchIDs(): any {
    this.enviaMedida.emit('Clean');

    if (this.rdnaocitar && this.rdnaocitar.checked) {
      const element = { value: '', id: this.rdnaocitar.id }
      this.enviaMedida.emit(element);
    }
    //direita
    if (this.VelSisPreDir && this.VelSisPreDir.nativeElement.value) {
      const element = { value: this.VelSisPreDir.nativeElement.value, id: this.VelSisPreDir.nativeElement.id }
      this.enviaMedida.emit(element);
    }
    if (this.VelSisPosDir && this.VelSisPosDir.nativeElement.value) {
      const element = { value: this.VelSisPosDir.nativeElement.value, id: this.VelSisPosDir.nativeElement.id }
      this.enviaMedida.emit(element);
    }
    if (this.VelDisPosDir && this.VelDisPosDir.nativeElement.value) {
      const element = { value: this.VelDisPosDir.nativeElement.value, id: this.VelDisPosDir.nativeElement.id }
      this.enviaMedida.emit(element);
    }
    if (this.AceleracaoDir && this.AceleracaoDir.nativeElement.value) {
      const element = { value: this.AceleracaoDir.nativeElement.value, id: this.AceleracaoDir.nativeElement.id }
      this.enviaMedida.emit(element);
    }

    //Esquerda
    if (this.VelSisPreEsq && this.VelSisPreEsq.nativeElement.value) {
      const element = { value: this.VelSisPreEsq.nativeElement.value, id: this.VelSisPreEsq.nativeElement.id }
      this.enviaMedida.emit(element);
    }
    if (this.VelSisPosEsq && this.VelSisPosEsq.nativeElement.value) {
      const element = { value: this.VelSisPosEsq.nativeElement.value, id: this.VelSisPosEsq.nativeElement.id }
      this.enviaMedida.emit(element);
    }
    if (this.VelDisPosEsq && this.VelDisPosEsq.nativeElement.value) {
      const element = { value: this.VelDisPosEsq.nativeElement.value, id: this.VelDisPosEsq.nativeElement.id }
      this.enviaMedida.emit(element);
    }
    if (this.AceleracaoEsq && this.AceleracaoEsq.nativeElement.value) {
      const element = { value: this.AceleracaoEsq.nativeElement.value, id: this.AceleracaoEsq.nativeElement.id }
      this.enviaMedida.emit(element);
    }

    
    if (this.ckCitarCalibres) {
        const element = { value: this.ckCitarCalibres.checked, id: this.ckCitarCalibres.id }
        this.enviaMedida.emit(element);
        if(this.ckCitarCalibres.checked){
          if (this.CalibrePreDir && this.CalibrePreDir.nativeElement.value) {
            const element = { value: this.CalibrePreDir.nativeElement.value, id: this.CalibrePreDir.nativeElement.id }
            this.enviaMedida.emit(element);
          }
          if (this.CalibrePreEsq && this.CalibrePreEsq.nativeElement.value) {
            const element = { value: this.CalibrePreEsq.nativeElement.value, id: this.CalibrePreEsq.nativeElement.id }
            this.enviaMedida.emit(element);
          }
          if (this.CalibrePosDir && this.CalibrePosDir.nativeElement.value) {
            const element = { value: this.CalibrePosDir.nativeElement.value, id: this.CalibrePosDir.nativeElement.id }
            this.enviaMedida.emit(element);
          }
          if (this.CalibrePosEsq && this.CalibrePosEsq.nativeElement.value) {
            const element = { value: this.CalibrePosEsq.nativeElement.value, id: this.CalibrePosEsq.nativeElement.id }
            this.enviaMedida.emit(element);
          }
        }
    }

    if (this.ckCitarIR) {
      const element = { value: this.ckCitarIR.checked, id: this.ckCitarIR.id }
      this.enviaMedida.emit(element);
      if(this.ckCitarIR.checked){
        if (this.IRDir && this.IRDir.nativeElement.value) {
          const element = { value: this.IRDir.nativeElement.value, id: this.IRDir.nativeElement.id }
          this.enviaMedida.emit(element);
        }
        if (this.IREsq && this.IREsq.nativeElement.value) {
          const element = { value: this.IREsq.nativeElement.value, id: this.IREsq.nativeElement.id }
          this.enviaMedida.emit(element);
        }
      }
  }

    this.listaCompleta.emit();
  }
}
