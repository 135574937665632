import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatRadioButton} from '@angular/material';

@Component({
  selector: 'app-indicacao',
  templateUrl: './indicacao.component.html',
  styleUrls: ['./indicacao.component.css']
})
export class IndicacaoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  // @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdRastreamento') rdRastreamento: MatRadioButton;
  @ViewChild('rdRotina') rdRotina: MatRadioButton;
  @ViewChild('rdAcompanhamentoNodulo') rdAcompanhamentoNodulo: MatRadioButton;
  @ViewChild('rdAchadosMamograficos') rdAchadosMamograficos: MatRadioButton;
  @ViewChild('rdPosOperatorio') rdPosOperatorio: MatRadioButton;
  @ViewChild('rdPesquisaColecao') rdPesquisaColecao: MatRadioButton;
  @ViewChild('rdAvaliacaoImplante') rdAvaliacaoImplante: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickRastreamento() {
    this.catchIDs();
  }

  clickAcompanhamentoNodulo() {
    this.catchIDs();
  }

  clickAchadosMamograficos() {
    this.catchIDs();
  }

  clickPosOperatorio() {
    this.catchIDs();
  }

  clickPesquisaColecao() {
    this.catchIDs();
  }

  clickAvaliacaoImplante() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');


    if (this.rdRastreamento.checked) {
      this.termoSelecionado.emit(this.rdRastreamento.id);
    }

    if (this.rdRotina.checked) {
      this.termoSelecionado.emit(this.rdRotina.id);
    }

    if (this.rdAcompanhamentoNodulo.checked) {
      this.termoSelecionado.emit(this.rdAcompanhamentoNodulo.id);
    }

    if (this.rdAchadosMamograficos.checked) {
      this.termoSelecionado.emit(this.rdAchadosMamograficos.id);
    }

    if (this.rdPosOperatorio.checked) {
      this.termoSelecionado.emit(this.rdPosOperatorio.id);
    }

    if (this.rdPesquisaColecao.checked) {
      this.termoSelecionado.emit(this.rdPesquisaColecao.id);
    }

    if (this.rdAvaliacaoImplante.checked) {
      this.termoSelecionado.emit(this.rdAvaliacaoImplante.id);
    }

    // this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

}
