import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatTooltipModule, 
  MatSlideToggleModule} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from 'src/app/utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { MorfologicoSegundoTrimestreComponent } from './morfologico-segundo-trimestre.component';
import { MstCoracaoComponent } from './termosComponents/mst-coracao/mst-coracao.component';
import { MstAbdomeComponent } from './termosComponents/mst-abdome/mst-abdome.component';
import { MstGenitaliaComponent } from './termosComponents/mst-genitalia/mst-genitalia.component';
import { MstColunaComponent } from './termosComponents/mst-coluna/mst-coluna.component';
import { MstExtremidadesComponent } from './termosComponents/mst-extremidades/mst-extremidades.component';
import { MstPlacentaComponent } from './termosComponents/mst-placenta/mst-placenta.component';
import { MstCordaoUmbilicalComponent } from './termosComponents/mst-cordao-umbilical/mst-cordao-umbilical.component';
import { MstLiquidoAmnioticoComponent } from './termosComponents/mst-liquido-amniotico/mst-liquido-amniotico.component';
import { ObsCalculadoraModule } from '../obs-calculadora/obs-calculadora.module';
import { MstCrescimentoFetalComponent } from './termosComponents/mst-crescimento-fetal/mst-crescimento-fetal.component';
import { MstFetoComponent } from './termosComponents/mst-feto/mst-feto.component';
import { MstParametrosBiometricosComponent } from './termosComponents/mst-param-biometricos/mst-param-biometricos.component';
import { MstCranioComponent } from './termosComponents/mst-cranio/mst-cranio.component';
import { MstFaceComponent } from './termosComponents/mst-face/mst-face.component';
import { MstToraxComponent } from './termosComponents/mst-torax/mst-torax.component';
import { UsgMorfologicaPrimeiroTrimestreModule } from '../usg-morfologica-primeiro-trimestre/usg-morfologica-primeiro-trimestre.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';
import { TransvSegTercTrimestreModule } from '../transv-seg-terc-trimestre/transv-seg-terc-trimestre.module';
// import { CrescimentoFetalComponent } from '../usg-morfologica-primeiro-trimestre/termosComponents/crescimento-fetal/crescimento-fetal.component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    ObsCalculadoraModule,
    UsgMorfologicaPrimeiroTrimestreModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
    TransvSegTercTrimestreModule
  ],
  exports: [
    MorfologicoSegundoTrimestreComponent,
    MstCoracaoComponent,
    MstAbdomeComponent,
    MstGenitaliaComponent,
    MstColunaComponent,
    MstExtremidadesComponent,
    MstPlacentaComponent,
    MstCordaoUmbilicalComponent,
    MstLiquidoAmnioticoComponent,
    MstCrescimentoFetalComponent,
    MstFetoComponent,
    MstParametrosBiometricosComponent,
    MstCranioComponent,
    MstFaceComponent,
    MstToraxComponent,
  ],
  declarations: [
    MorfologicoSegundoTrimestreComponent,
    MstCoracaoComponent,
    MstAbdomeComponent,
    MstGenitaliaComponent,
    MstColunaComponent,
    MstExtremidadesComponent,
    MstPlacentaComponent,
    MstCordaoUmbilicalComponent,
    MstLiquidoAmnioticoComponent,
    MstCrescimentoFetalComponent,
    MstFetoComponent,
    MstParametrosBiometricosComponent,
    MstCranioComponent,
    MstFaceComponent,
    MstToraxComponent,
  ]
})
export class MorfologicoSegundoTrimestreModule { }
