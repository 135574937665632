import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ObstetricoRelacaoIRValoresService } from 'src/app/services/obstetrico-relacao-ir-valores.service';

@Component({
  selector: 'app-arteria-cerebral',
  templateUrl: './arteria-cerebral.component.html',
  styleUrls: ['./arteria-cerebral.component.css']
})
export class ArteriaCerebralComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAumentado') rdAumentado: MatRadioButton;

  @ViewChild('inIR') inIR: ElementRef;
  @ViewChild('inIP') inIP: ElementRef;
  @ViewChild('inIPACM') inIPACM: ElementRef;


  showAndHideTerms: any;

  constructor(private _valoresObstetricosIR: ObstetricoRelacaoIRValoresService) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAumentado: true,
    };
  }

  clickDefault() {
    this.showAndHideTerms.sahAumentado = true;
    this.catchIDs();
  }

  clickAumentao() {
    this.showAndHideTerms.sahAumentado = false;
    this.catchIDs();
  }

  blurParametros() {
    this.catchIDs();
  }

  blurIPACM() {

    this._valoresObstetricosIR.ipACM = this.inIP.nativeElement.value;
    this.catchIDs();
  }

  blurIRACM() {
 
    this._valoresObstetricosIR.irACM = this.inIR.nativeElement.value;
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.inIR && this.inIR.nativeElement.value) {
        const objeto = { elemento: this.inIR.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inIP && this.inIP.nativeElement.value) {
        const objeto = { elemento: this.inIP.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);

        if (this.inIPACM && this.inIPACM.nativeElement.value) {
          const objeto = { elemento: this.inIPACM.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
    }

    if (this.rdAumentado.checked) {
      this.termoSelecionado.emit(this.rdAumentado.id);

      if (this.inIR && this.inIR.nativeElement.value) {
        const objeto = { elemento: this.inIR.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inIP && this.inIP.nativeElement.value) {
        const objeto = { elemento: this.inIP.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
