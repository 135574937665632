import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckbox, MatRadioButton, MatSelect} from '@angular/material';

@Component({
  selector: 'app-bexiga',
  templateUrl: './bexiga.component.html',
  styleUrls: ['./bexiga.component.css']
})

export class BexigaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdEspessada') rdEspessada: MatRadioButton;
  @ViewChild('rdParcial') rdParcial: MatRadioButton;
  @ViewChild('rdEsforco') rdEsforco: MatRadioButton;
  @ViewChild('rdSemReplecao') rdSemReplecao: MatRadioButton;

  @ViewChild('ckBalaoSonda') ckBalaoSonda: MatCheckbox;
  @ViewChild('ckDebris') ckDebris: MatCheckbox;

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahSemReplecao: true
    };
  }

  clickNormal($event) {
    this.showAndHideTerms.sahSemReplecao = true;
    this.catchIDs();
  }

  clickEpessada($event) {
    this.showAndHideTerms.sahSemReplecao = true;
    this.catchIDs();
  }

  clickParcial($event) {
    this.showAndHideTerms.sahSemReplecao = true;
    this.catchIDs();
  }

  clickEsforco($event) {
    this.showAndHideTerms.sahSemReplecao = true;
    this.catchIDs();
  }

  clickSemReplecao($event) {
    this.showAndHideTerms.sahSemReplecao = false;
    this.catchIDs();
  }

  clickBalaoSonda($event) {
    this.showAndHideTerms.sahSemReplecao = true;
    this.catchIDs();
  }

  clickDebris($event) {
    this.showAndHideTerms.sahSemReplecao = true;
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.ckBalaoSonda != undefined && this.ckBalaoSonda.checked) {
        this.termoSelecionado.emit(this.ckBalaoSonda.id);
      }

      if (this.ckDebris != undefined && this.ckDebris.checked) {
        this.termoSelecionado.emit(this.ckDebris.id);
      }
    }

    if (this.rdEspessada.checked) {
      this.termoSelecionado.emit(this.rdEspessada.id);

      if (this.ckBalaoSonda != undefined && this.ckBalaoSonda.checked) {
        this.termoSelecionado.emit(this.ckBalaoSonda.id);
      }

      if (this.ckDebris != undefined && this.ckDebris.checked) {
        this.termoSelecionado.emit(this.ckDebris.id);
      }
    }

    if (this.rdParcial.checked) {
      this.termoSelecionado.emit(this.rdParcial.id);

      if (this.ckBalaoSonda != undefined && this.ckBalaoSonda.checked) {
        this.termoSelecionado.emit(this.ckBalaoSonda.id);
      }

      if (this.ckDebris != undefined && this.ckDebris.checked) {
        this.termoSelecionado.emit(this.ckDebris.id);
      }
    }

    if (this.rdEsforco.checked) {
      this.termoSelecionado.emit(this.rdEsforco.id);

      if (this.ckBalaoSonda != undefined && this.ckBalaoSonda.checked) {
        this.termoSelecionado.emit(this.ckBalaoSonda.id);
      }

      if (this.ckDebris != undefined && this.ckDebris.checked) {
        this.termoSelecionado.emit(this.ckDebris.id);
      }
    }

    if (this.rdSemReplecao.checked) {
      this.termoSelecionado.emit(this.rdSemReplecao.id);
    }

    this.listaCompleta.emit();
  }
}
