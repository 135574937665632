import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-lateralidade',
  templateUrl: './lateralidade.component.html',
  styleUrls: ['./lateralidade.component.css']
})
export class LateralidadeComponent implements OnInit {

  @Input() InGeneroLat;

  @Output() lateralidadeEmmit = new EventEmitter();


  @ViewChild('slLateralidadeMas') slLateralidadeMas: MatSelect;
  @ViewChild('slLateralidadeFem') slLateralidadeFem: MatSelect;
  @ViewChild('slLateralidadeMasBi') slLateralidadeMasBi: MatSelect;
  @ViewChild('slLateralidadeFemBi') slLateralidadeFemBi: MatSelect;
  
  @ViewChild('rdMamografia') rdMamografia: MatRadioButton;
  @ViewChild('rdDireita') rdDireita: MatRadioButton;
  @ViewChild('rdEsquerda') rdEsquerda: MatRadioButton;
  @ViewChild('rdBilateral') rdBilateral: MatRadioButton;


  public ShowHideLateralidade: any;
  tituloSelected: string;

  ladosMas: ArrayDropDown[] = [
    { value: 'esquerdo', viewValue: 'Esquerdo' },
    { value: 'direito', viewValue: 'Direito' }
  ];

  LadosFem: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' }
  ];

  ladosMasBi: ArrayDropDown[] = [
    { value: 'esquerdo', viewValue: 'Esquerdo' },
    { value: 'direito', viewValue: 'Direito' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  LadosFemBi: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  ladosMamas: ArrayDropDown[] = [
    { value: '', viewValue: 'Mamografia' },
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' },    
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  constructor() { }

  ngOnInit() {
    this.ShowHideLateralidade = {
      masculino: false,
      feminino: false,
      masculinoBi: false,
      femininoBi: false,
      mamografico: false
    };
    if (this.InGeneroLat === 'feminino') {
      this.ShowHideLateralidade.feminino = true;
      this.ShowHideLateralidade.masculino = false;
      this.ShowHideLateralidade.masculinoBi = false;
      this.ShowHideLateralidade.femininoBi = false;
      this.ShowHideLateralidade.mamografico = false;
    } else if (this.InGeneroLat === 'masculino') {
      this.ShowHideLateralidade.masculino = true;
      this.ShowHideLateralidade.feminino = false;
      this.ShowHideLateralidade.masculinoBi = false;
      this.ShowHideLateralidade.femininoBi = false;
      this.ShowHideLateralidade.mamografico = false;
    } else if (this.InGeneroLat === 'femininoBi') {
      this.ShowHideLateralidade.femininoBi = true;
      this.ShowHideLateralidade.masculino = false;
      this.ShowHideLateralidade.feminino = false;
      this.ShowHideLateralidade.masculinoBi = false;
      this.ShowHideLateralidade.mamografico = false;
    } else if (this.InGeneroLat === 'masculinoBi') {
      this.ShowHideLateralidade.masculinoBi = true;
      this.ShowHideLateralidade.masculino = false;
      this.ShowHideLateralidade.feminino = false;
      this.ShowHideLateralidade.femininoBi = false;
      this.ShowHideLateralidade.mamografico = false;
    } else if (this.InGeneroLat === 'mamografico') {
      this.ShowHideLateralidade.mamografico = true;
      this.ShowHideLateralidade.masculinoBi = false;
      this.ShowHideLateralidade.masculino = false;
      this.ShowHideLateralidade.feminino = false;
      this.ShowHideLateralidade.femininoBi = false; 
    }

    this.tituloSelected = this.ladosMamas[0].value;
  }

  changeSelect() {
    if (this.slLateralidadeMas) {
      this.lateralidadeEmmit.emit(this.slLateralidadeMas.value);
    } else if (this.slLateralidadeFem) {
      this.lateralidadeEmmit.emit(this.slLateralidadeFem.value);
    } else if (this.slLateralidadeMasBi) {
      this.lateralidadeEmmit.emit(this.slLateralidadeMasBi.value);
    } else if (this.slLateralidadeFemBi) {
      this.lateralidadeEmmit.emit(this.slLateralidadeFemBi.value);
    } 
  }
  clickRadio(){
    if (this.rdMamografia && this.rdMamografia.checked) {
      this.lateralidadeEmmit.emit(this.rdMamografia.value);
    } else if (this.rdDireita && this.rdDireita.checked) {
      this.lateralidadeEmmit.emit(this.rdDireita.value);
    }  else if (this.rdEsquerda && this.rdEsquerda.checked) {
      this.lateralidadeEmmit.emit(this.rdEsquerda.value);
    }  else if (this.rdBilateral && this.rdBilateral.checked) {
      this.lateralidadeEmmit.emit(this.rdBilateral.value);
    } 
  }

}
