import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-opt-ovarios',
  templateUrl: './opt-ovarios.component.html',
  styleUrls: ['./opt-ovarios.component.css']
})
export class OptOvariosComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormais') rdNormais: MatRadioButton;
  @ViewChild('rdDirNaoCaract') rdDirNaoCaract: MatRadioButton;
  @ViewChild('rdEsqNaoCaract') rdEsqNaoCaract: MatRadioButton;
  @ViewChild('rdNaoCaract') rdNaoCaract: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickNormais() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormais && this.rdNormais.checked) {
      this.termoSelecionado.emit(this.rdNormais.id);
    }

    if (this.rdDirNaoCaract && this.rdDirNaoCaract.checked) {
      this.termoSelecionado.emit(this.rdDirNaoCaract.id);
    }

    if (this.rdEsqNaoCaract && this.rdEsqNaoCaract.checked) {
      this.termoSelecionado.emit(this.rdEsqNaoCaract.id);
    }

    if (this.rdNaoCaract && this.rdNaoCaract.checked) {
      this.termoSelecionado.emit(this.rdNaoCaract.id);
    }

    this.listaCompleta.emit();
  }
}
