import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatRadioButton, MatSelect} from '@angular/material';

export interface LadoMama {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-linfonodomegalia',
  templateUrl: './linfonodomegalia.component.html',
  styleUrls: ['./linfonodomegalia.component.css']
})
export class LinfonodomegaliaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  // @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPerdaPadrao') rdPerdaPadrao: MatRadioButton;
  @ViewChild('rdPreservada') rdPreservada: MatRadioButton;
  
  @ViewChild('slLadoMana') slLadoMana: MatSelect;
  @ViewChild('inMedindo') inMedindo: ElementRef;

  ladosMama: LadoMama[] = [
    {value: 'direita', viewValue: 'Direita'},
    {value: 'esquerda', viewValue: 'Esquerda'},
    {value: 'bilateral', viewValue: 'Bilateral'}
  ];

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAusente: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickAusente() {
    this.showAndHideTerms.sahAusente = false;
    this.catchIDs();
  }

  clickPerdaPadrao() {
    this.showAndHideTerms.sahAusente = true;
    this.catchIDs();
  }

  changeLadoMama() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {

    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPerdaPadrao.checked) {
      this.termoSelecionado.emit(this.rdPerdaPadrao.id);

      if (this.inMedindo != undefined &&
        this.inMedindo.nativeElement.id != undefined &&
        this.inMedindo.nativeElement.value != '') {

        const objeto = {elemento: this.inMedindo.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoMana != undefined && this.slLadoMana.value != undefined) {
        const objeto = {elemento: this.slLadoMana, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdPreservada.checked) {
      this.termoSelecionado.emit(this.rdPreservada.id);

      if (this.inMedindo != undefined &&
        this.inMedindo.nativeElement.id != undefined &&
        this.inMedindo.nativeElement.value != '') {

        const objeto = {elemento: this.inMedindo.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoMana != undefined && this.slLadoMana.value != undefined) {
        const objeto = {elemento: this.slLadoMana, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    // this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }
}
