import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {  Observable } from 'rxjs';
import { Speciality } from '../models/BO/Speciality';
import {  take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ListaPlanosFavoritosService {

  constructor(private http: HttpClient) { }

  favoritos:Speciality[] = [];
  inclusaoFinalizada:boolean=true;

  buscarFavoritosPorUsuario(userID: number) {
   this.http.get<Array<Speciality>>(environment.nLaudoApi + "MedicalReport/BuscaFavoritosPorUsuario/" + userID)
      .pipe(take(1)).toPromise().then(favs => {
        this.favoritos = favs;
      });
  }

  buscarFavsPorUsuario(userID: number): Observable<any> {
    return this.http.get<Array<Speciality>>(environment.nLaudoApi + "MedicalReport/BuscaFavoritosPorUsuario/" + userID);
   }

  insereFavoritoUsuario(userID: number, exame: Speciality) {
    const url = environment.nLaudoApi + 'medicalReport/AdicionaFavoritoPorUsuario';
    const obj = {
      'UserID': userID,
      'MedicalReportTypeID': exame.id
    };

    this.http.post<Speciality[]>(url, obj).pipe(take(1)).toPromise().then(favs => {
      this.favoritos = favs;
    });
    this.favoritos.push(exame);

    this.buscarFavoritosPorUsuario(userID);
  }

  apagarFavoritoUsuario(userID: number, exame: Speciality) {
    const url = environment.nLaudoApi + 'medicalReport/ExcluiFavoritoPorUsuario';
    const obj = {
      'UserID': userID,
      'MedicalReportTypeID': exame.id
    };

    this.http.post<Speciality[]>(url, obj).pipe(take(1)).toPromise().then(favs => {
      this.favoritos = favs;
    });
    this.buscarFavoritosPorUsuario(userID);
  }

  isFavorito(medicalReport: Speciality): boolean {
    return this.favoritos.filter(x => x.id == medicalReport.id).length > 0
  }

  ApagarTodosFavoritosUsuario(favoritosAntigo:Array<any>){
    this.inclusaoFinalizada=false;
    const userId = localStorage.getItem('userID');
    const urlDelete = environment.nLaudoApi + 'medicalReport/ExcluiFavoritoPorUsuario';
    for (let i = 0; i < favoritosAntigo.length; i++) {
      let id = favoritosAntigo[i].id;
      setTimeout(()=>{
        this.http.post<Speciality[]>(urlDelete, {'UserID':+userId,'MedicalReportTypeID': id }).subscribe();
      }, i * 100);
    }
  }

  IncluirTodosFavoritosUsuario(favoritosAntigo:Array<any>){
    const userId = localStorage.getItem('userID');
    const urlIncluir = environment.nLaudoApi + 'medicalReport/AdicionaFavoritoPorUsuario';
    setTimeout(()=>{
      for (let i = 0; i < favoritosAntigo.length; i++) {
        let id = favoritosAntigo[i].id;
        setTimeout(()=>{
          this.http.post<Speciality[]>(urlIncluir, {'UserID':+userId,'MedicalReportTypeID': id }).subscribe();
        },i * 255)
        if(i==favoritosAntigo.length-1){
          this.inclusaoFinalizada=true;
        }
      }
    },1000);
  }

}
