import { Component, OnInit, EventEmitter, Output, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-tendoes-extensores-pe',
  templateUrl: './tendoes-extensores-pe.component.html',
  styleUrls: ['./tendoes-extensores-pe.component.css']
})
export class TendoesExtensoresPeComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTendinopatia') rdTendinopatia: MatRadioButton;

  @ViewChildren('tendoesExtensoresChildren') tendoesExtensoresChildren: QueryList<any>;

  dedos: ArrayDropDown[] = [
    { value: 'hálux', viewValue: 'hálux' },
    { value: '2° dedo', viewValue: '2° dedo' },
    { value: '3° dedo', viewValue: '3° dedo' },
    { value: '4° dedo', viewValue: '4° dedo' },
    { value: '5° dedo', viewValue: '5° dedo' }
  ];

  showAndHideTerms: any;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  objeto: any;
  objetofilho: any;


  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true
    };
    this.lesoesForm = this._fb.group({
      tendoesExtensores: this._fb.array([this.addtendoesExtensores()]),
    });
  }

  //Dinamico
  addtendoesExtensores() {
    return this._fb.group({
      dedo: []
    });
  }

  addTendoes() {
    this.tendoesExtensoresArray.push(this.addtendoesExtensores());
    this.catchIDs();
  }

  get tendoesExtensoresArray() {
    return <FormArray>this.lesoesForm.get('tendoesExtensores');
  }

  removeTendoes(index) {
    this.tendoesExtensoresArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickTendinopatia() {
    this.showAndHideTerms.sahAlterado = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.tendoesExtensoresArray.value[0].dedo)){
      this.rdTendinopatia.checked = true;
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdTendinopatia.checked) {
      this.termoSelecionado.emit(this.rdTendinopatia.value);
      this.verificaTendoes();
    }

    this.listaCompleta.emit();
  }

  verificaTendoes() {
    this.linha = 0;
    this.tendoesExtensoresChildren.forEach(obj => {

      if(+obj.id === 1769 ) this.linha++;

      this.objetofilho = { id: obj.id, value: obj.value, cenario: 1 };
      this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
      this.lesoesDinamicas.emit(this.objeto);
    });
  }

  ajustaArrayDinamico() {
    if ((this.rdTendinopatia && !this.rdTendinopatia.checked)) {
      const arrayTotal = this.tendoesExtensoresArray.length;
      for (let i  = 0; i <= arrayTotal; i++) {
        const index = this.tendoesExtensoresArray.length - 1
        if (index != 0) {
          this.tendoesExtensoresArray.removeAt(index);
        }
      }
      this.tendoesExtensoresArray.reset();
    }
  }

}
