import { Component, OnInit, EventEmitter, ElementRef, Output, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-fascia-plantar-pe',
  templateUrl: './fascia-plantar-pe.component.html',
  styleUrls: ['./fascia-plantar-pe.component.css']
})
export class FasciaPlantarPeComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdFasciite') rdFasciite: MatRadioButton;
  @ViewChild('rdRotura') rdRotura: MatRadioButton;
  @ViewChild('rdFibromatose') rdFibromatose: MatRadioButton;
  @ViewChild('rdUmNodulo') rdUmNodulo: MatRadioButton;
  @ViewChild('rdDoisNodulos') rdDoisNodulos: MatRadioButton;

  @ViewChild('sllocalizacaoTerco') sllocalizacaoTerco: MatSelect;
  @ViewChild('slBanda') slBanda: MatSelect;

  @ViewChild('inEspessura') inEspessura: ElementRef;
  @ViewChild('inDistancia') inDistancia: ElementRef;
  @ViewChild('inMedida') inMedida: ElementRef;

  @ViewChildren('doisNodulosChildren') doisNodulosChildren: QueryList<any>;

  localizacaoTercos: ArrayDropDown[] = [
    { value: 'proximal', viewValue: 'Proximal' },
    { value: 'médio', viewValue: 'Médio' },
    { value: 'distal', viewValue: 'Distal' }
  ];

  bandas: ArrayDropDown[] = [
    { value: 'central', viewValue: 'Central' },
    { value: 'medial', viewValue: 'Medial' },
    { value: 'lateral', viewValue: 'Lateral' }
  ];

  showAndHideTerms: any;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  objeto: any;
  objetofilho: any;
  unidadeMedida: string;

  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahFasciite: false,
      sahRotura: false,
      sahFibromatose: false,
      sahUmNodulo: false,
      sahDoisNodulos: false
    };
    this.lesoesForm = this._fb.group({
      doisNodulos: this._fb.array([this.adddoisNodulos()]),
    });
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  //Dinamico
  adddoisNodulos() {
    return this._fb.group({
      localizacaoTerco: [],
      banda: [],
      medida: []
    });
  }

  addDNodulos() {
    this.doisNodulosArray.push(this.adddoisNodulos());
    this.catchIDs();
  }

  get doisNodulosArray() {
    return <FormArray>this.lesoesForm.get('doisNodulos');
  }

  removeDNodulos(index) {
    this.doisNodulosArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  clickNormal() {
    this.showAndHideTerms.sahFasciite = false;
    this.showAndHideTerms.sahRotura = false;
    this.showAndHideTerms.sahFibromatose = false;
    this.showAndHideTerms.sahUmNodulo = false;
    this.showAndHideTerms.sahDoisNodulos = false;
    this.catchIDs();
  }

  clickFasciite() {
    this.showAndHideTerms.sahFasciite = true;
    this.showAndHideTerms.sahRotura = false;
    this.showAndHideTerms.sahFibromatose = false;
    this.showAndHideTerms.sahUmNodulo = false;
    this.showAndHideTerms.sahDoisNodulos = false;
    this.catchIDs();
  }

  clickRotura() {
    this.showAndHideTerms.sahFasciite = false;
    this.showAndHideTerms.sahRotura = true;
    this.showAndHideTerms.sahFibromatose = false;
    this.showAndHideTerms.sahUmNodulo = false;
    this.showAndHideTerms.sahDoisNodulos = false;
    this.catchIDs();
  }

  clickFibromatose() {
    this.showAndHideTerms.sahFasciite = false;
    this.showAndHideTerms.sahRotura = false;
    this.showAndHideTerms.sahFibromatose = true;
    this.showAndHideTerms.sahUmNodulo = true;
    this.showAndHideTerms.sahDoisNodulos = false;
    this.catchIDs();
  }

  clickUmNodulo() {
    this.showAndHideTerms.sahFasciite = false;
    this.showAndHideTerms.sahRotura = false;
    this.showAndHideTerms.sahFibromatose = true;
    this.showAndHideTerms.sahUmNodulo = true;
    this.showAndHideTerms.sahDoisNodulos = false;
    this.catchIDs();
  }

  clickDoisNodulos() {
    this.showAndHideTerms.sahFasciite = false;
    this.showAndHideTerms.sahRotura = false;
    this.showAndHideTerms.sahFibromatose = true;
    this.showAndHideTerms.sahUmNodulo = false;
    this.showAndHideTerms.sahDoisNodulos = true;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdFasciite.checked) {
      this.termoSelecionado.emit(this.rdFasciite.value);
      if (this.inEspessura && this.inEspessura.nativeElement.value) {
        const comMedidaID = "1779";
        this.termoSelecionado.emit(comMedidaID);
        const objeto = { elemento: this.inEspessura.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);

      } else {
        const semMedidaID = "1778";
        this.termoSelecionado.emit(semMedidaID);
      }
    }

    if (this.rdRotura.checked) {
      this.termoSelecionado.emit(this.rdRotura.value);
      if (this.inDistancia && this.inDistancia.nativeElement.value) {
        const objeto = { elemento: this.inDistancia.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdFibromatose.checked) {
      this.termoSelecionado.emit(this.rdFibromatose.value);
      if (this.rdUmNodulo && this.rdUmNodulo.checked) {
        this.termoSelecionado.emit(this.rdUmNodulo.value);
        if (this.sllocalizacaoTerco && this.sllocalizacaoTerco.value) {
          const objeto = { elemento: this.sllocalizacaoTerco, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
        if (this.slBanda && this.slBanda.value) {
          const objeto = { elemento: this.slBanda, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
        if (this.inMedida && this.inMedida.nativeElement.value) {
          const objeto = { elemento: this.inMedida.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
      if (this.rdDoisNodulos && this.rdDoisNodulos.checked) {
        this.termoSelecionado.emit(this.rdDoisNodulos.value);
        this.verificaDoisNodulos();
      }
    }

    this.listaCompleta.emit();
  }

  verificaDoisNodulos() {
    this.linha = 0;
    this.doisNodulosChildren.forEach(obj => {

      if (obj.nativeElement) {

        this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
        this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
        this.lesoesDinamicas.emit(this.objeto);

      } else {
        if(+obj.id === 1786) {
          this.linha++;
        }

      this.objetofilho = { id: obj.id, value: obj.value, cenario: 1 };
      this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
      this.lesoesDinamicas.emit(this.objeto);
      }
    });
  }

  ajustaArrayDinamico() {
      const arrayTotal = this.doisNodulosArray.length;
      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.doisNodulosArray.length - 1
        if (index != 0) {
          this.doisNodulosArray.removeAt(index);
        }
      }
      this.doisNodulosArray.reset();
  }


}
