import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-arteria-tibial-posterior',
  templateUrl: './arteria-tibial-posterior.component.html',
  styleUrls: ['./arteria-tibial-posterior.component.css']
})
export class ArteriaTibialPosteriorComponent implements OnInit {


  public showAndHideTerms: any;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdnaoCaracterizada') rdnaoCaracterizada: MatRadioButton;
  @ViewChild('rdTibialPosteriorPlacaComMedida') rdTibialPosteriorPlacaComMedida: MatRadioButton;
  @ViewChild('rdMultiplasplacasSemFluxoVascularDetectavel') rdMultiplasplacasSemFluxoVascularDetectavel: MatRadioButton;
  @ViewChild('rdTibialPosteriorMultiplasPlacasEstenosantes') rdTibialPosteriorMultiplasPlacasEstenosantes: MatRadioButton;
  @ViewChild('rdTibialPosteriorTromboseComMedida') rdTibialPosteriorTromboseComMedida: MatRadioButton;
  @ViewChild('rdTibialPosteriorEndoproteseStent') rdTibialPosteriorEndoproteseStent: MatRadioButton;
  @ViewChild('rdTibialPosteriorAneurismaSemtrombose') rdTibialPosteriorAneurismaSemtrombose: MatRadioButton;
  @ViewChild('slmotivo') slmotivo: MatSelect;
  @ViewChild('sllocalizacao') sllocalizacao: MatSelect;
  @ViewChild('slecotextura') slecotextura: MatSelect;
  @ViewChild('slecogenicidade') slecogenicidade: MatSelect;
  @ViewChild('slsuperficie') slsuperficie: MatSelect;
  @ViewChild('Inespessura') Inespessura: ElementRef;
  @ViewChild('slestenose') slestenose: MatSelect;
  @ViewChild('slfluxodistal') slfluxodistal: MatSelect;
  @ViewChild('Inextensao') Inextensao: ElementRef;
  @ViewChild('Incalibre') Incalibre: ElementRef;
  @ViewChild('sllocalizacaos') sllocalizacaos: MatSelect;
  @ViewChild('slFluxosDistals') slFluxosDistals: MatSelect;


  public motivo: ArrayDropDown[] = [
    { value: 'edema', viewValue: 'Edema' },
    { value: 'atenuação do feixe acústico', viewValue: 'Atenuação do feixe acústico' },
  ];

  public localizacao: ArrayDropDown[] = [
    { value: 'no terço proximal', viewValue: 'Terço Proximal' },
    { value: 'no terço médio', viewValue: 'Terço Médio' },
    { value: 'no terço distal', viewValue: 'Terço Distal' },
  ];

  public ecogenicidade: ArrayDropDown[] = [
    { value: 'calcificada', viewValue: 'Calcificada' },
    { value: 'mista', viewValue: 'Mista' },
    { value: 'iso/ hiperecogênica', viewValue: 'Iso/ Hiperecogênica' },
    { value: 'hipoecogênica', viewValue: 'Hipoecogênica' },
  ];


  public ecotextura: ArrayDropDown[] = [
    { value: 'homogênea', viewValue: 'Homogênea' },
    { value: 'heterogênea', viewValue: 'Heterogênea' },
  ];

  public superficie: ArrayDropDown[] = [
    { value: 'regular', viewValue: 'Regular' },
    { value: 'irregular', viewValue: 'Irregular' },
    { value: 'ulcerada', viewValue: 'Ulcerada' },
  ];


  public estenose: ArrayDropDown[] = [
    {
      value: 'sem determinar estenose hemodinamicamente significativa (< 50%)',
      viewValue: 'Sem determinar estenose hemodinamicamente significativa (< 50%)'
    },
    {
      value: 'determinando estenose hemodinamicamente significativa (≥ 50%)',
      viewValue: 'Determinando estenose hemodinamicamente significativa (≥ 50%)'
    },
    { value: 'determinando suboclusão', viewValue: 'Determinando Suboclusão' },
  ];

  public FluxosDistals: ArrayDropDown[] = [
    { value: 'trifásico', viewValue: 'Trifásico' },
    { value: 'bifásico', viewValue: 'Bifásico' },
    { value: 'monofásico', viewValue: 'Monofásico' },
    { value: 'tardus-parvus', viewValue: 'Tardus-parvus' },
  ];

  public fluxodistal: ArrayDropDown[] = [
    { value: 'bifásico', viewValue: 'Bifásico' },
    { value: 'monofásico', viewValue: 'Monofásico' },
    { value: 'tardus-parvus', viewValue: 'Tardus-parvus' },
  ];

  public localizacaos: ArrayDropDown[] = [
    { value: 'em toda sua extensão', viewValue: 'Toda Sua Extensão' },
    { value: 'no terço proximal', viewValue: 'Terço Proximal' },
    { value: 'no terço médio', viewValue: 'Terço Médio' },
    { value: 'no terço distal', viewValue: 'Terço Distal' },
  ];

  public unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahnaoCaracterizada: false,
      sahTibialPosteriorPlacaComMedida: false,
      sahTibialPosteriorMultiplasPlacasEstenosantes: false,
      sahMultiplasplacasSemFluxoVascularDetectavel: false,
      sahTibialPosteriorTromboseComMedida: false,
      sahTibialPosteriorEndoproteseStenta: false,
      sahTibialPosteriorAneurismaSemtrombose: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public clicknormal(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahTibialPosteriorPlacaComMedida = false;
    this.showAndHideTerms.sahTibialPosteriorMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahMultiplasplacasSemFluxoVascularDetectavel = false;
    this.showAndHideTerms.sahTibialPosteriorTromboseComMedida = false;
    this.showAndHideTerms.sahTibialPosteriorEndoproteseStenta = false;
    this.showAndHideTerms.sahTibialPosteriorAneurismaSemtrombose = false;
    this.catchIDs();
  }

  public clicknaocaracterizada(): any {
    this.showAndHideTerms.sahnaoCaracterizada = true;
    this.showAndHideTerms.sahTibialPosteriorPlacaComMedida = false;
    this.showAndHideTerms.sahTibialPosteriorMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahMultiplasplacasSemFluxoVascularDetectavel = false;
    this.showAndHideTerms.sahTibialPosteriorTromboseComMedida = false;
    this.showAndHideTerms.sahTibialPosteriorEndoproteseStenta = false;
    this.showAndHideTerms.sahTibialPosteriorAneurismaSemtrombose = false;
    this.catchIDs();
  }

  public clickTibialPosteriorPlacaComMedida(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahTibialPosteriorPlacaComMedida = true;
    this.showAndHideTerms.sahTibialPosteriorMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahMultiplasplacasSemFluxoVascularDetectavel = false;
    this.showAndHideTerms.sahTibialPosteriorTromboseComMedida = false;
    this.showAndHideTerms.sahTibialPosteriorEndoproteseStenta = false;
    this.showAndHideTerms.sahTibialPosteriorAneurismaSemtrombose = false;
    this.catchIDs();
  }


  public clickTibialPosteriorMultiplasPlacasEstenosantes(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahTibialPosteriorPlacaComMedida = false;
    this.showAndHideTerms.sahTibialPosteriorMultiplasPlacasEstenosantes = true;
    this.showAndHideTerms.sahMultiplasplacasSemFluxoVascularDetectavel = false;
    this.showAndHideTerms.sahTibialPosteriorTromboseComMedida = false;
    this.showAndHideTerms.sahTibialPosteriorEndoproteseStenta = false;
    this.showAndHideTerms.sahTibialPosteriorAneurismaSemtrombose = false;
    this.catchIDs();
  }

  public clickTibialPosteriorMultiplasplacasSemFluxoVascularDetectavel(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahTibialPosteriorPlacaComMedida = false;
    this.showAndHideTerms.sahTibialPosteriorMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahMultiplasplacasSemFluxoVascularDetectavel = true;
    this.showAndHideTerms.sahTibialPosteriorTromboseComMedida = false;
    this.showAndHideTerms.sahTibialPosteriorEndoproteseStenta = false;
    this.catchIDs();
  }

  public clickTibialPosteriorTromboseComMedida(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahTibialPosteriorPlacaComMedida = false;
    this.showAndHideTerms.sahTibialPosteriorMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahMultiplasplacasSemFluxoVascularDetectavel = false;
    this.showAndHideTerms.sahTibialPosteriorTromboseComMedida = true;
    this.showAndHideTerms.sahTibialPosteriorEndoproteseStenta = false;
    this.showAndHideTerms.sahTibialPosteriorAneurismaSemtrombose = false;
    this.catchIDs();
  }

  public clickTibialPosteriorEndoproteseStenta(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahTibialPosteriorPlacaComMedida = false;
    this.showAndHideTerms.sahTibialPosteriorMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahMultiplasplacasSemFluxoVascularDetectavel = false;
    this.showAndHideTerms.sahTibialPosteriorTromboseComMedida = false;
    this.showAndHideTerms.sahTibialPosteriorEndoproteseStenta = true;
    this.showAndHideTerms.sahTibialPosteriorAneurismaSemtrombose = false;
    this.catchIDs();
  }

  public clickTibialPosteriorAneurismaSemtrombose(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahTibialPosteriorPlacaComMedida = false;
    this.showAndHideTerms.sahTibialPosteriorMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahMultiplasplacasSemFluxoVascularDetectavel = false;
    this.showAndHideTerms.sahTibialPosteriorTromboseComMedida = false;
    this.showAndHideTerms.sahTibialPosteriorEndoproteseStenta = false;
    this.showAndHideTerms.sahTibialPosteriorAneurismaSemtrombose = true;
    this.catchIDs();
  }

  public changeSelect(): any {
    this.catchIDs();
  }


  public blurMedindo(): any {
    this.catchIDs();
  }


  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    // Não Caracterizada
    if (this.rdnaoCaracterizada && this.rdnaoCaracterizada.checked) {
      this.termoSelecionado.emit(this.rdnaoCaracterizada.id);

      if (this.slmotivo && this.slmotivo.value) {
        const objeto = { elemento: this.slmotivo };
        this.termosGenericos.emit(objeto);
      }
    }

    // Placa Com Medida
    if (this.rdTibialPosteriorPlacaComMedida && this.rdTibialPosteriorPlacaComMedida.checked) {
      this.termoSelecionado.emit(this.rdTibialPosteriorPlacaComMedida.id);


      if (this.sllocalizacao && this.sllocalizacao.value) {
        const objeto = { elemento: this.sllocalizacao };
        this.termosGenericos.emit(objeto);
      }

      if (this.slecogenicidade && this.slecogenicidade.value) {
        const objeto = { elemento: this.slecogenicidade };
        this.termosGenericos.emit(objeto);
      }

      if (this.slecotextura && this.slecotextura.value) {
        const objeto = { elemento: this.slecotextura };
        this.termosGenericos.emit(objeto);
      }

      if (this.slsuperficie && this.slsuperficie.value) {
        const objeto = { elemento: this.slsuperficie };
        this.termosGenericos.emit(objeto);
      }

      if (this.Inespessura && this.Inespessura.nativeElement.value) {
        const objeto = { elemento: this.Inespessura.nativeElement };
        this.termosGenericos.emit(objeto);
      }

      if (this.slestenose && this.slestenose.value) {
        const objeto = { elemento: this.slestenose };
        this.termosGenericos.emit(objeto);
      }

      if (this.slFluxosDistals && this.slFluxosDistals.value) {
        const objeto = { elemento: this.slFluxosDistals };
        this.termosGenericos.emit(objeto);
      }

      if (this.Inextensao && this.Inextensao.nativeElement.value) {
        const objeto = { elemento: this.Inextensao.nativeElement };
        this.termosGenericos.emit(objeto);
      }
    }

    // TibialPosterior Multiplas Placas Estenosantes
    if (this.rdTibialPosteriorMultiplasPlacasEstenosantes && this.rdTibialPosteriorMultiplasPlacasEstenosantes.checked) {
      this.termoSelecionado.emit(this.rdTibialPosteriorMultiplasPlacasEstenosantes.id);

      if (this.slfluxodistal && this.slfluxodistal.value) {
        const objeto = { elemento: this.slfluxodistal };
        this.termosGenericos.emit(objeto);
      }
    }


    // Múltiplas placas Sem Fluxo Vascular detectável
    if (this.rdMultiplasplacasSemFluxoVascularDetectavel && this.rdMultiplasplacasSemFluxoVascularDetectavel.checked) {
      this.termoSelecionado.emit(this.rdMultiplasplacasSemFluxoVascularDetectavel.id);
    }


    // TibialPosterior Trombose Com Medida
    if (this.rdTibialPosteriorTromboseComMedida && this.rdTibialPosteriorTromboseComMedida.checked) {
      this.termoSelecionado.emit(this.rdTibialPosteriorTromboseComMedida.id);

      if (this.sllocalizacaos && this.sllocalizacaos.value) {
        const objeto = { elemento: this.sllocalizacaos };
        this.termosGenericos.emit(objeto);
      }

      if (this.Inextensao && this.Inextensao.nativeElement.value) {
        const objeto = { elemento: this.Inextensao.nativeElement };
        this.termosGenericos.emit(objeto);
      }
    }

    // TibialPosterior Endoprotese Stent
    if (this.rdTibialPosteriorEndoproteseStent && this.rdTibialPosteriorEndoproteseStent.checked) {
      this.termoSelecionado.emit(this.rdTibialPosteriorEndoproteseStent.id);

      if (this.sllocalizacao && this.sllocalizacao.value) {
        const objeto = { elemento: this.sllocalizacao };
        this.termosGenericos.emit(objeto);
      }

      if (this.slestenose && this.slestenose.value) {
        const objeto = { elemento: this.slestenose };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();

  }

}
