import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ElementRef} from '@angular/core';
import {Validacoes} from '../../../../../../utils/validacoes';
import {MatRadioButton} from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-pre-operatorio',
  templateUrl: './pre-operatorio.component.html',
  styleUrls: ['./pre-operatorio.component.css']
})
export class PreOperatorioComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdNaoPreOp') rdNaoPreOp: MatRadioButton;
  @ViewChild('rdSimPreOp') rdSimPreOp: MatRadioButton;

  @ViewChild('inEspessuraSubAcima') inEspessuraSubAcima: ElementRef;
  @ViewChild('inDistanciaSubAcima') inDistanciaSubAcima: ElementRef;
  @ViewChild('inEspessuraSubAbaixo') inEspessuraSubAbaixo: ElementRef;
  @ViewChild('inDistanciaSubAbaixo') inDistanciaSubAbaixo: ElementRef;
  @ViewChild('inEspessuraPeritonealAcima') inEspessuraPeritonealAcima: ElementRef;
  @ViewChild('inDistanciaPeritonealAcima') inDistanciaPeritonealAcima: ElementRef;
  @ViewChild('inEspessuraPeritonealAbaixo') inEspessuraPeritonealAbaixo: ElementRef;
  @ViewChild('inDistanciaPeritonealAbaixo') inDistanciaPeritonealAbaixo: ElementRef;

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMedidas: true,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNaoPreOp() {
    this.showAndHideTerms.sahMedidas = false;
    this.catchIDs();
  }

  clickSimPreOp() {
    this.showAndHideTerms.sahMedidas = true;
    this.catchIDs();
  }

  blurMedindo() {
    this.rdSimPreOp.checked = true;
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoPreOp.checked) {
      this.termoSelecionado.emit(this.rdNaoPreOp.id);

    } else if (this.rdSimPreOp.checked) {
      this.termoSelecionado.emit(this.rdSimPreOp.id);

      if (this.inEspessuraSubAcima !== undefined &&
        this.inEspessuraSubAcima.nativeElement.id !== undefined &&
        this.inEspessuraSubAcima.nativeElement.value != '') {

        const objeto = {elemento: this.inEspessuraSubAcima.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistanciaSubAcima !== undefined &&
        this.inDistanciaSubAcima.nativeElement.id !== undefined &&
        this.inDistanciaSubAcima.nativeElement.value != '') {

        const objeto = {elemento: this.inDistanciaSubAcima.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inEspessuraSubAbaixo !== undefined &&
        this.inEspessuraSubAbaixo.nativeElement.id !== undefined &&
        this.inEspessuraSubAbaixo.nativeElement.value != '') {

        const objeto = {elemento: this.inEspessuraSubAbaixo.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistanciaSubAbaixo !== undefined &&
        this.inDistanciaSubAbaixo.nativeElement.id !== undefined &&
        this.inDistanciaSubAbaixo.nativeElement.value != '') {

        const objeto = {elemento: this.inDistanciaSubAbaixo.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inEspessuraPeritonealAcima !== undefined &&
        this.inEspessuraPeritonealAcima.nativeElement.id !== undefined &&
        this.inEspessuraPeritonealAcima.nativeElement.value != '') {

        const objeto = {elemento: this.inEspessuraPeritonealAcima.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistanciaPeritonealAcima !== undefined &&
        this.inDistanciaPeritonealAcima.nativeElement.id !== undefined &&
        this.inDistanciaPeritonealAcima.nativeElement.value != '') {

        const objeto = {elemento: this.inDistanciaPeritonealAcima.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inEspessuraPeritonealAbaixo !== undefined &&
        this.inEspessuraPeritonealAbaixo.nativeElement.id !== undefined &&
        this.inEspessuraPeritonealAbaixo.nativeElement.value != '') {

        const objeto = {elemento: this.inEspessuraPeritonealAbaixo.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistanciaPeritonealAbaixo !== undefined &&
        this.inDistanciaPeritonealAbaixo.nativeElement.id !== undefined &&
        this.inDistanciaPeritonealAbaixo.nativeElement.value != '') {

        const objeto = {elemento: this.inDistanciaPeritonealAbaixo.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }
}
