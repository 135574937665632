import { Component, OnInit, EventEmitter, Output, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-superficie-ossea-tornozelo',
  templateUrl: './superficie-ossea-tornozelo.component.html',
  styleUrls: ['./superficie-ossea-tornozelo.component.css']
})
export class SuperficieOsseaTornozeloComponent implements OnInit {


  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdEnteCalc') rdEnteCalc: MatRadioButton;
  @ViewChild('rd1EnteCalc') rd1EnteCalc: MatRadioButton;
  @ViewChild('rd2EnteCalc') rd2EnteCalc: MatRadioButton;
  @ViewChild('rdOsteMarg') rdOsteMarg: MatRadioButton;

  @ViewChildren('entCalcChildren') entCalcChildren: QueryList<any>;
  @ViewChild('slOsteMarg') slOsteMarg: MatSelect;

  localEnte: ArrayDropDown[] = [
    { value: 'no aspecto posterior', viewValue: 'Aspecto posterior' },
    { value: 'no aspecto plantar', viewValue: 'Aspecto plantar' }
  ];

  localOste: ArrayDropDown[] = [
    { value: 'tibiotalares', viewValue: 'Tibiotalares' },
    { value: 'na face dorsal do mediopé', viewValue: 'Face dorsal' }
  ];

  showAndHideTerms: any;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  objeto: any;
  objetofilho: any;


  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahEnteCalc: false,
      sahOsteMarg: false
    };
    this.lesoesForm = this._fb.group({
      entCalc: this._fb.array([this.addEnteCalcGrup()]),
    });
  }

  //Dinamico Fibromatose
  addEnteCalcGrup() {
    return this._fb.group({
      locEnte: []
    });
  }

  addEnteCalc() {
    this.entCalcArray.push(this.addEnteCalcGrup());
    this.catchIDs();
  }

  removeEnteCalc(index) {
    this.entCalcArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get entCalcArray() {
    return <FormArray>this.lesoesForm.get('entCalc');
  }

  clickNormal() {
    this.showAndHideTerms.sahEnteCalc = false;
    this.showAndHideTerms.sahOsteMarg = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickEnteCalc() {
    this.showAndHideTerms.sahEnteCalc = true;
    this.showAndHideTerms.sahOsteMarg = false;
    this.catchIDs();
  }

  clickOsteMarg() {
    this.showAndHideTerms.sahOsteMarg = true;
    this.showAndHideTerms.sahEnteCalc = false;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdEnteCalc.checked) {
      this.termoSelecionado.emit(this.rdEnteCalc.value);
      if (this.rd1EnteCalc && this.rd1EnteCalc.checked) {
        this.termoSelecionado.emit(this.rd1EnteCalc.value);
        this.verificaEntCalc();
      } else if (this.rd2EnteCalc && this.rd2EnteCalc.checked) {
        this.termoSelecionado.emit(this.rd2EnteCalc.value);
        this.verificaEntCalc();
      }
    }

    if (this.rdOsteMarg.checked) {
      this.termoSelecionado.emit(this.rdOsteMarg.value);

      if (this.slOsteMarg && this.slOsteMarg.value) {
        const objeto = { elemento: this.slOsteMarg, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

  verificaEntCalc() {
    this.linha = 0;
    this.entCalcChildren.forEach(obj => {

      if(+obj.id === 1397) this.linha++;

      this.objetofilho = { id: obj.id, value: obj.value, cenario: 1 };
      this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
      this.lesoesDinamicas.emit(this.objeto);
    });
  }

  ajustaArrayDinamico() {
    if ((this.rdEnteCalc && !this.rdEnteCalc.checked)) {
      const arrayTotal = this.entCalcArray.length;
      for (let i  = 0; i <= arrayTotal; i++) {
        const index = this.entCalcArray.length - 1
        if (index != 0) {
          this.entCalcArray.removeAt(index);
        }
      }
      this.entCalcArray.reset();
    }
  }

}
