import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-mst-coluna',
  templateUrl: './mst-coluna.component.html',
  styleUrls: ['./mst-coluna.component.css']
})
export class MstColunaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaAlterado = new EventEmitter();

  @ViewChild('slColuna') slColuna: MatSelect;

  @ViewChild('inAlteradaColuna') inAlteradaColuna: ElementRef;

  showAndHideTerms: any;

  colunas: ArrayDropDown[] = [
    { value: 'Sem alterações nos três planos visualizados', viewValue: 'Sem alterações nos três planos visualizados' },
    { value: 'Alterada: ', viewValue: 'Alterada' }
  ];


  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlteradoColuna: false,
    }
  }

  changeSelect() {
    this.verificaAlterado();
    this.catchIDs();
  }

  blur() {
    this.catchIDs();
  }



  catchIDs() {
    this.termoSelecionado.emit('Clean');
    let elemento;
    if (this.slColuna && this.slColuna.value) {
      if (this.inAlteradaColuna && this.slColuna.value == 'Alterada: ')
        elemento = { id: this.slColuna.id, value: this.inAlteradaColuna.nativeElement.value };
      else
        elemento = { id: this.slColuna.id, value: this.slColuna.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }


    this.listaCompleta.emit();

  }

  verificaAlterado() {
    if (this.slColuna.value == 'Alterada: ') {
      this.showAndHideTerms.sahAlteradoColuna = true;
    } else {
      this.showAndHideTerms.sahAlteradoColuna = false;
    }
  }

}
