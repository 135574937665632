import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';


@Component({
  selector: 'app-arcabouco',
  templateUrl: './arcabouco.component.html',
  styleUrls: ['./arcabouco.component.css']
})
export class ArcaboucoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckEspondiliose') ckEspondiliose: MatCheckbox;

  @ViewChild('ckFratura') ckFratura: MatCheckbox;
  @ViewChild('ckSemFraturas') ckSemFraturas: MatCheckbox;  
  @ViewChild('rd1ArcoCostal') rd1ArcoCostal: MatRadioButton;
  @ViewChild('rd2ArcoCostal') rd2ArcoCostal: MatRadioButton;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slLateralidade') slLateralidade: MatSelect;

  @ViewChild('ckFraturaConso') ckFraturaConso: MatCheckbox;
  @ViewChild('rd1FraturaCons') rd1FraturaCons: MatRadioButton;
  @ViewChild('rd2FraturaCons') rd2FraturaCons: MatRadioButton;
  @ViewChild('slLocalizacaoCons') slLocalizacaoCons: MatSelect;
  @ViewChild('slLateralidadeCons') slLateralidadeCons: MatSelect;

  @ViewChild('ckEsternorrafia') ckEsternorrafia: MatCheckbox;

  @ViewChildren('fraturaConsoChildren') fraturaConsoChildren: QueryList<any>;
  @ViewChildren('arcoCostalChildren') arcoCostalChildren: QueryList<any>;


  lateralidades: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' }
  ];

  localizacoes: ArrayDropDown[] = [
    { value: '1°', viewValue: '1°' },
    { value: '2°', viewValue: '2°' },
    { value: '3°', viewValue: '3°' },
    { value: '4°', viewValue: '4°' },
    { value: '5°', viewValue: '5°' },
    { value: '6°', viewValue: '6°' },
    { value: '7°', viewValue: '7°' },
    { value: '8°', viewValue: '8°' },
    { value: '9°', viewValue: '9°' },
    { value: '10°', viewValue: '10°' },
    { value: '11°', viewValue: '11°' },
    { value: '12°', viewValue: '12°' }
  ];

  showAndHideTerms: any;
  countLesoes = 0;
  localizaFratura:any ;
  lesoesForm: FormGroup;
  linhaFraturaCons: number;
  linhaArcoCostal: number;
  obj: any;
  objetofilho: any;

  constructor(private _validacoes: Validacoes, private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahFratura: false,
      sahFraturaConso: false
    };
    this.lesoesForm = this._fb.group({
      fConsolidada: this._fb.array([this.addLinfoGrup()]),
      fArcoCostal: this._fb.array([this.addLinfoGrup()]),
    });
  }

  //Dinamico
  addLinfoGrup() {
    return this._fb.group({
      localizacaoCons: [],
      lateralidadeCons: [],
    });
  }

  addfConsolidada() {
    this.fConsolidadaArray.push(this.addLinfoGrup());
    this.catchIDs();
  }

  addfArcoCostal() {
    this.fArcoCostalArray.push(this.addLinfoGrup());
    this.catchIDs();
  }

  removeArcoCostal(index) {
    this.fArcoCostalArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }
  removeConsolidada(index) {
    this.fConsolidadaArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get fConsolidadaArray() {
    return <FormArray>this.lesoesForm.get('fConsolidada');
  }
  get fArcoCostalArray() {
    return <FormArray>this.lesoesForm.get('fArcoCostal');
  }

  ajustaArcoCostalArrayDinamico() {
    const arrayTotal = this.fArcoCostalArray.length;
    for (let i = 0; i <= arrayTotal; i++) {
      const index = this.fArcoCostalArray.length - 1
      if (index != 0) {
        this.fArcoCostalArray.removeAt(index);
      }
    }
    this.fArcoCostalArray.reset();
  }

  ajustaConsolidadaArrayDinamico() {
    const arrayTotal = this.fConsolidadaArray.length;
    for (let i = 0; i <= arrayTotal; i++) {
      const index = this.fConsolidadaArray.length - 1
      if (index != 0) {
        this.fConsolidadaArray.removeAt(index);
      }
    }
    this.fConsolidadaArray.reset();
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahFratura = false;
    this.showAndHideTerms.sahFraturaConso = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahFratura = false;
    this.showAndHideTerms.sahFraturaConso = false;
    this.catchIDs();
  }

  clickEspo() {
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickEster() {
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickFratura() {
    if (this.showAndHideTerms.sahFratura === false)
      this.showAndHideTerms.sahFratura = true;
    else
      this.showAndHideTerms.sahFratura = false;

    this.rdAlterado.checked = true;
    this.ajustaArcoCostalArrayDinamico();
    this.verificaAlterado();
    this.catchIDs();
  }

  clickFratCons() {
    if (this.showAndHideTerms.sahFraturaConso === false)
      this.showAndHideTerms.sahFraturaConso = true;
    else
      this.showAndHideTerms.sahFraturaConso = false;

    this.rdAlterado.checked = true;
    this.ajustaConsolidadaArrayDinamico();
    this.verificaAlterado();
    this.catchIDs();
  }

  clickRd1Fratura(){
    this.ajustaConsolidadaArrayDinamico();

  }

  clickRd1Arco(){
    this.ajustaArcoCostalArrayDinamico();
  }

  clickDefault() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {

      if (this.ckEspondiliose && this.ckEspondiliose.checked) {
        this.termoSelecionado.emit(this.ckEspondiliose.id);
        this.countLesoes++;
      }

      if (this.countLesoes > 0)
        this.emitFraturaLesao();
      else
        this.emitFratura();


      if (this.countLesoes > 0)
        this.emitFraturaConsoLesao();
      else
        this.emitFraturaConso();

      if (this.ckEsternorrafia && this.ckEsternorrafia.checked) {
        if (this.countLesoes > 0) {
          this.countLesoes++;
          const objeto = { id: this.ckEsternorrafia.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          this.termoSelecionado.emit(this.ckEsternorrafia.id);
        }
      }
    }

    //Sem Fraturas PRECISA SER O ULTIMO
    if (this.countLesoes > 0)
      this.emitSemFraturasLesao();
    else
      this.emitSemFraturas();

    this.listaCompleta.emit();
  }

  emitSemFraturasLesao(){
    if (this.ckSemFraturas && this.ckSemFraturas.checked) {
      this.countLesoes++;
      const objetoPar = { id: this.ckSemFraturas.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoPar);
      const objetoNorm = { id: 1457, cenario: this.countLesoes }; //ID de normal
      this.lesoesSelecionadas.emit(objetoNorm);
    }
  }

  emitSemFraturas(){
    if (this.ckSemFraturas && this.ckSemFraturas.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckSemFraturas.id);
    }
  }

  emitFratura() {
    if (this.ckFratura && this.ckFratura.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFratura.id);

      if (this.rd1ArcoCostal && this.rd1ArcoCostal.checked){
        this.termoSelecionado.emit(this.rd1ArcoCostal.id);

        if (this.slLocalizacao && this.slLocalizacao.value) {
          const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLateralidade && this.slLateralidade.value) {
          const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      else if (this.rd2ArcoCostal && this.rd2ArcoCostal.checked){
        this.termoSelecionado.emit(this.rd2ArcoCostal.id);
        let posicao = 0;
        this.linhaArcoCostal = 0;

        this.arcoCostalChildren.forEach(obj => {

            if (+obj.id === 1462) this.linhaArcoCostal++;
            if(obj.value){
              this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
              this.obj = { elemento: this.objetofilho, linha: this.linhaArcoCostal, posicao: 0, isPrimeiraLesao: true };
              this.lesoesDinamicas.emit(this.obj);
            }

        });

      }

    }
  }



  emitFraturaLesao() {
    if (this.ckFratura && this.ckFratura.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckFratura.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rd1ArcoCostal && this.rd1ArcoCostal.checked) {
        const objeto = { id: this.rd1ArcoCostal.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.slLocalizacao && this.slLocalizacao.value) {
          const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLateralidade && this.slLateralidade.value) {
          const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

      } else if (this.rd2ArcoCostal && this.rd2ArcoCostal.checked) {
        const objeto = { id: this.rd2ArcoCostal.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        let posicao = 0;
        this.linhaArcoCostal = 0;

        this.arcoCostalChildren.forEach(obj => {

          if (+obj.id === 1462) this.linhaArcoCostal++;
          if(obj.value){
            this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linhaArcoCostal, posicao: 0, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.obj);
          }
        });

      }

    }
  }

  emitFraturaConso() {
    if (this.ckFraturaConso && this.ckFraturaConso.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFraturaConso.id);

      if (this.rd1FraturaCons && this.rd1FraturaCons.checked){
        this.termoSelecionado.emit(this.rd1FraturaCons.id);

        if (this.slLocalizacaoCons && this.slLocalizacaoCons.value) {
          const objeto = { elemento: this.slLocalizacaoCons, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLateralidadeCons && this.slLateralidadeCons.value) {
          const objeto = { elemento: this.slLateralidadeCons, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
      else if (this.rd2FraturaCons && this.rd2FraturaCons.checked){
        this.termoSelecionado.emit(this.rd2FraturaCons.id);

        let posicao = 0;
        this.linhaFraturaCons = 0;

        this.fraturaConsoChildren.forEach(obj => {

            if (+obj.id === 1467) this.linhaFraturaCons++;
            if(obj.value){
              this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
              this.obj = { elemento: this.objetofilho, linha: this.linhaFraturaCons, posicao: 0, isPrimeiraLesao: true };
              this.lesoesDinamicas.emit(this.obj);
            }
        });

      }



    }
  }

  emitFraturaConsoLesao() {
    if (this.ckFraturaConso && this.ckFraturaConso.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckFraturaConso.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rd1FraturaCons && this.rd1FraturaCons.checked) {
        const objeto = { id: this.rd1FraturaCons.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.slLocalizacaoCons && this.slLocalizacaoCons.value) {
          const objeto = { elemento: this.slLocalizacaoCons, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLateralidadeCons && this.slLateralidadeCons.value) {
          const objeto = { elemento: this.slLateralidadeCons, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

      } else if (this.rd2FraturaCons && this.rd2FraturaCons.checked) {
        const objeto = { id: this.rd2FraturaCons.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        let posicao = 0;
        this.linhaFraturaCons = 0;

        this.fraturaConsoChildren.forEach(obj => {

            if (+obj.id === 1467) this.linhaFraturaCons++;
            if(obj.value){
              this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
              this.obj = { elemento: this.objetofilho, linha: this.linhaFraturaCons, posicao: 0, isPrimeiraLesao: false };
              this.lesoesDinamicas.emit(this.obj);
            }
        });

      }


    }
  }

  verificaAlterado() {
    if (!this.ckEspondiliose.checked &&
      !this.ckFratura.checked &&
      !this.ckFraturaConso.checked &&
      !this.ckEsternorrafia.checked) {
      // Marca normal
      this.rdNormal.checked = true;
    }
  }
}
