import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from './user';
import { HttpClient } from '@angular/common/http';
import { LoginResult } from '../models/BO/LoginResult';
import { ProtocoloResult } from '../models/BO/ProtocoloResult';
import { environment } from '../../environments/environment';
import { Integracao } from '../models/BO/Integracao';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  loggedIn = new BehaviorSubject<boolean>(false);
  integrationRoute = new BehaviorSubject<boolean>(false);

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get isIntegrationRoute() {
    return this.integrationRoute.asObservable();
  }

  constructor(
    private router: Router,
    private _http: HttpClient
  ) { }

  checkToken(token: string) {
    if (token !== '') {
      this.loggedIn.next(true);
      this.router.navigate(['/cadastro']);
    }
  }
  
  loginEmail(user: any): Observable<LoginResult> {
    let _user: User = user;
    let obj = {
      "Email": _user.Email,
      "PasswordMain": _user.Senha,
      "Ip": _user.Ip,
      "Browser": _user.Browser,
      "Coordinates": _user.Coordinates,
    };
    let postURL: string = environment.nLaudoApi + 'auth/auth';
    return this._http.post<LoginResult>(postURL, obj)
  }

  validaProtocolo(dadosProtocolo: any): Observable<ProtocoloResult> {
    const protocolo = {
      "Protocolo": dadosProtocolo.protocolo,
      "Senha": dadosProtocolo.password
    }
    let url = environment.nLaudoApi + 'Exames/Validar';
    return this._http.post<ProtocoloResult>(url, protocolo);
  }

  loginProt(user: any): Observable<LoginResult> {

    let postURL: string = environment.nLaudoApi + 'auth';
    return this._http.post<LoginResult>(postURL, user)
  }

  recuperarSenha(user: any): Observable<LoginResult> {
    const url = environment.nLaudoApi + 'User/RecuperarSenha';

    const _user: User = user;
    const obj = {
      'email': _user.Email
    };

    return this._http.post<LoginResult>(url, obj);
  }

  logout() {
    this.loggedIn.next(false);
    this.integrationRoute.next(false);
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  checkReportToken(reportToken: string , userid: string) {
    let postURL: string = environment.integrationAPI + 'Report/ReportTokenVerify';
    const obj = {
      ReportToken: reportToken,
      Userid: +userid,
    };
    return this._http.post<Integracao>(postURL, obj)
  }

  checkPasswordToken(user: any): Observable<LoginResult> {
    const url = environment.nLaudoApi + 'User/TokenSenha';

    const _user: User = user;
    const obj = {
      'ResetPasswordToken': _user.PasswordToken
    };

    return this._http.post<LoginResult>(url, obj);
  }

  updatePassword(user: any): Observable<LoginResult> {
    const url = environment.nLaudoApi + 'User/AlteraSenha';

    const _user: User = user;
    const obj = {
      'ResetPasswordToken': _user.PasswordToken,
      'PasswordMain': _user.Senha
    };

    return this._http.post<LoginResult>(url, obj);
  }
  
  getIPAddress() {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", "https://api.ipify.org/?format=json", true);
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            resolve(JSON.parse(xhr.responseText).ip);
          } else {
            reject(xhr.statusText);
          }
        }
      };
      xhr.send();
    });
  }
  
}

