import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-derrame-articular-joelho',
  templateUrl: './derrame-articular-joelho.component.html',
  styleUrls: ['./derrame-articular-joelho.component.css']
})
export class DerrameArticularJoelhoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;
  @ViewChild('slVolume') slVolume: MatSelect;

  volumes: ArrayDropDown[] = [
    { value: 'Pequeno', viewValue: 'Pequeno' },
    { value: 'Moderado', viewValue: 'Moderado' },
    { value: 'Acentuado', viewValue: 'Acentuado' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.slVolume && this.slVolume.value) ){
      this.rdPresente.checked = true;
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.value);
      
      if (this.slVolume && this.slVolume.value) {
        const objeto = { elemento: this.slVolume, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
