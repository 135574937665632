import {Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatCheckbox, MatRadioButton, MatSelect} from '@angular/material';
import {Validacoes} from '../../../../../../utils/validacoes';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-nodulo-doppler',
  templateUrl: './nodulo-doppler.component.html',
  styleUrls: ['./nodulo-doppler.component.css']
})
export class NoduloDopplerComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdAusencia') rdAusencia: MatRadioButton;
  @ViewChild('rdUmNodulo') rdUmNodulo: MatRadioButton;
  @ViewChild('rdDoisNodulos') rdDoisNodulos: MatRadioButton;

  @ViewChild('ckDoisNoduloSem') ckDoisNoduloSem: MatCheckbox;
  @ViewChild('ckDoisNodulosNaoSem') ckDoisNodulosNaoSem: MatCheckbox;

  @ViewChild('slEcoginicidade') slEcoginicidade: MatSelect;
  @ViewChild('slForma') slForma: MatSelect;
  @ViewChild('slMargem') slMargem: MatSelect;
  @ViewChild('slEixo') slEixo: MatSelect;
  @ViewChild('slSombra') slSombra: MatSelect;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slLadoMama') slLadoMama: MatSelect;
  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  @ViewChild('inMedida3') inMedida3: ElementRef;
  @ViewChild('inDistPele') inDistPele: ElementRef;
  @ViewChild('inDistPapila') inDistPapila: ElementRef;
  @ViewChild('slFluxoDoppler') slFluxoDoppler: MatSelect;

  @ViewChildren('noduloSemChildren') noduloSemChildren: QueryList<any>;
  @ViewChildren('noduloNaoSemChildren') noduloNaoSemChildren: QueryList<any>;

  ecogenicidades: ArrayDropDown[] = [
    {value: 'hipoecogênico', viewValue: 'Hipoecogênico'},
    {value: 'isoecogênico', viewValue: 'Isoecogênico'},
    {value: 'hiperecogênico', viewValue: 'Hiperecogênico'}
  ];

  formas: ArrayDropDown[] = [
    {value: 'oval|false', viewValue: 'Oval'},
    {value: 'redondo|false', viewValue: 'Redondo'},
    {value: 'irregular|true', viewValue: 'Irregular'}
  ];

  formasPlura: ArrayDropDown[] = [
    {value: 'ovalado|false', viewValue: 'Oval'},
    {value: 'redondo|false', viewValue: 'Redondo'},
    {value: 'irregular|true', viewValue: 'Irregular'}
  ];

  margens: ArrayDropDown[] = [
    {value: 'circunscrito|false', viewValue: 'Circunscrito'},
    {value: 'não circunscrito|true', viewValue: 'Não circunscrito'},
    {value: 'angulado|true', viewValue: 'Angulado'},
    {value: 'indistinto|true', viewValue: 'Indistinto'},
    {value: 'microlobulado|true', viewValue: 'Microlobulado'},
    {value: 'espiculado|true', viewValue: 'Espiculado'}
  ];

  eixos: ArrayDropDown[] = [
    {value: 'paralela|false', viewValue: 'Paralela'},
    {value: 'vertical|true', viewValue: 'Vertical'}
  ];

  sombras: ArrayDropDown[] = [
    {value: 'sem|false', viewValue: 'Sem'},
    {value: 'com|true', viewValue: 'Com'}
  ];

  localizacoes: ArrayDropDown[] = [
    {value: 'à 1 hora', viewValue: '1 Hora'},
    {value: 'às 2 horas', viewValue: '2 Horas'},
    {value: 'às 3 horas', viewValue: '3 Horas'},
    {value: 'às 4 horas', viewValue: '4 Horas'},
    {value: 'às 5 horas', viewValue: '5 Horas'},
    {value: 'às 6 horas', viewValue: '6 Horas'},
    {value: 'às 7 horas', viewValue: '7 Horas'},
    {value: 'às 8 horas', viewValue: '8 Horas'},
    {value: 'às 9 horas', viewValue: '9 Horas'},
    {value: 'às 10 horas', viewValue: '10 Horas'},
    {value: 'às 11 horas', viewValue: '11 Horas'},
    {value: 'às 12 horas', viewValue: '12 Horas'},
    {value: 'na região retroareolar', viewValue: 'Região retroareolar'}
   ];

  localizacoesSem: ArrayDropDown[] = [
    {value: 'À 1 hora', viewValue: '1 Hora'},
    {value: 'Às 2 horas', viewValue: '2 Horas'},
    {value: 'Às 3 horas', viewValue: '3 Horas'},
    {value: 'Às 4 horas', viewValue: '4 Horas'},
    {value: 'Às 5 horas', viewValue: '5 Horas'},
    {value: 'Às 6 horas', viewValue: '6 Horas'},
    {value: 'Às 7 horas', viewValue: '7 Horas'},
    {value: 'Às 8 horas', viewValue: '8 Horas'},
    {value: 'Às 9 horas', viewValue: '9 Horas'},
    {value: 'Às 10 horas', viewValue: '10 Horas'},
    {value: 'Às 11 horas', viewValue: '11 Horas'},
    {value: 'Às 12 horas', viewValue: '12 Horas'},
    {value: 'Na região retroareolar', viewValue: 'Região retroareolar'}
  ];

  ladosMama: ArrayDropDown[] = [
    {value: 'direita', viewValue: 'Direita'},
    {value: 'esquerda', viewValue: 'Esquerda'}
  ];

  ladosMamaCompleto: ArrayDropDown[] = [
    {value: 'na mama direita', viewValue: 'Direita'},
    {value: 'na mama esquerda', viewValue: 'Esquerda'}
  ];

  fluxoDoppler: ArrayDropDown[] = [
    {value: 'Não apresenta fluxo ao estudo Doppler', viewValue: 'Sem fluxo'},
    {value: 'Apresenta fluxo ao estudo Doppler', viewValue: 'Com Fluxo'}
  ];

  lesoesForm: FormGroup;
  showAndHideTerms: any;
  linha = 0;
  objeto: any;
  objetofilho: any;
  countLesoes = 0;
  unidadeMedida: string;
  desabilitado: boolean;

  ecogenicidadeSelected = this.ecogenicidades[0].value;
  formaSelected = this.formas[0].value;
  formaPluralSelected = this.formasPlura[0].value;
  margemSelected = this.margens[0].value;
  eixoSelected = this.eixos[0].value;
  sombraSelected = this.sombras[0].value;

  constructor(private _validacoes: Validacoes, private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahNodulos: false,
      sahNoduloSimples: true,
      sahNoduloDoisMais: false,
      sahDoisNoduloSem: false,
      sahDoisNodulosNaoSem: false,
      sahPlusDoisNodulos: false,
      sahPlusNoduloNaoSem: false,
      sahDistPapila: true,
    };
    this.lesoesForm = this._fb.group({
      noduloSem: this._fb.array([this.addNoduloSemGrup()]),
      noduloNaoSem: this._fb.array([this.addNoduloNaoSemGrup()])
    });
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  //NODULO SEMELHANTE
  addNoduloSemGrup() {
      return this._fb.group({
        localizacao: [],
        medindo: [],
        ladoMama: [],
        distPele: [],
        distPapila: [],
        fluxoDoppler: []
    });
  }

  addNoduloSem() {
    this.noduloSemArray.push(this.addNoduloSemGrup());
    this.catchIDs();
  }

  removeNoduloSem(index) {
    this.noduloSemArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get noduloSemArray() {
    return <FormArray>this.lesoesForm.get('noduloSem');
  }

  //NODULO NAO SEMELHANTE
  addNoduloNaoSemGrup() {
    return this._fb.group({
      ecogenicidade: [],
      forma: [],
      margem: [],
      eixo: [],
      sombra: [],
      localizacao: [],
      medindo: [],
      ladoMama: [],
      distPele: [],
      distPapila: [],
      fluxoDoppler: []
    });
  }

  addNoduloNaoSem() {
    this.noduloNaoSemArray.push(this.addNoduloNaoSemGrup());
    this.catchIDs();
  }

  removeNoduloNaoSem(index) {
    this.noduloNaoSemArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get noduloNaoSemArray() {
    return <FormArray>this.lesoesForm.get('noduloNaoSem');
  }

  clickAusencia() {
    this.showAndHideTerms.sahNodulos = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickUmNodulo() {
    this.showAndHideTerms.sahNodulos = true;
    this.showAndHideTerms.sahNoduloSimples = true;
    this.showAndHideTerms.sahNoduloDoisMais = false;
    this.showAndHideTerms.sahDoisNoduloSem = false;
    this.showAndHideTerms.sahDoisNodulosNaoSem = false;
    this.showAndHideTerms.sahDistPapila = true;

    this.ecogenicidadeSelected = 'hipoecogênico';
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickDoisNodulos() {
    this.showAndHideTerms.sahNodulos = true;
    this.showAndHideTerms.sahNoduloSimples = false;
    this.showAndHideTerms.sahNoduloDoisMais = true;
    this.showAndHideTerms.sahDoisNoduloSem = false;
    this.showAndHideTerms.sahDoisNodulosNaoSem = false;
    this.showAndHideTerms.sahDistPapila = true;

    this.catchIDs();
  }

  clickDoisNodulosSem($event) {
    this.showAndHideTerms.sahDistPapila = true;
    if (this.showAndHideTerms.sahDoisNoduloSem == false) {
      this.showAndHideTerms.sahNoduloDoisMais = true;
      this.showAndHideTerms.sahDoisNoduloSem = true;
      this.showAndHideTerms.sahPlusNoduloSem = true;
      this.showAndHideTerms.sahNoduloSimples = false;
      this.ecogenicidadeSelected = 'hipoecogênico';
    } else {
      this.showAndHideTerms.sahNoduloDoisMais = true;
      this.showAndHideTerms.sahDoisNoduloSem = false;
      this.showAndHideTerms.sahPlusNoduloSem = false;
    }

    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickDoisNodulosNaoSem() {
    this.showAndHideTerms.sahDistPapila = true;
    if (this.showAndHideTerms.sahDoisNodulosNaoSem == false) {
      this.showAndHideTerms.sahNoduloDoisMais = true;
      this.showAndHideTerms.sahDoisNodulosNaoSem = true;
      this.showAndHideTerms.sahPlusNoduloNaoSem = true;
      this.showAndHideTerms.sahNoduloSimples = false;
      this.ecogenicidadeSelected = 'hipoecogênico';
    } else {
      this.showAndHideTerms.sahNoduloDoisMais = true;
      this.showAndHideTerms.sahDoisNodulosNaoSem = false;
      this.showAndHideTerms.sahPlusNoduloNaoSem = false;
    }

    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  changeEcoginicidade() {
    this.catchIDs();
  }

  changeForma() {
    this.catchIDs();
  }

  changeMargem() {
    this.catchIDs();
  }

  changeEixo() {
    this.catchIDs();
  }

  changeSombra() {
    this.catchIDs();
  }

  changeLocalizacao() {
    if(this.slLocalizacao && this.slLocalizacao.value === 'na região retroareolar')
    this.desabilitado = true;
    else 
    this.desabilitado = false;

    this.catchIDs();
  }
  changeDoppler() {
    this.catchIDs();
  }

  changeLadoMama() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.countLesoes = 0;

    if (this.rdAusencia.checked) {
      this.termoSelecionado.emit(this.rdAusencia.id);
      //this.noduloSiples();
    }

    if (this.rdUmNodulo.checked) {
      this.termoSelecionado.emit(this.rdUmNodulo.id);
      this.noduloSiples();
    }

    if (this.rdDoisNodulos.checked) {
      this.termoSelecionado.emit(this.rdDoisNodulos.id);

      if (this.ckDoisNoduloSem != undefined && this.ckDoisNoduloSem.checked) {
        this.termoSelecionado.emit(this.ckDoisNoduloSem.id);
            this.nodulosSemelhantes();
        }

      if (this.ckDoisNodulosNaoSem != undefined && this.ckDoisNodulosNaoSem.checked) {
        //this.termoSelecionado.emit(this.ckDoisNodulosNaoSem.id);
        if (this.countLesoes > 0) {
          this.nodulosNaoSemelhantesLesao();
        } else {
          this.nodulosNaoSemelhantesSimples();
        }
      }
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();

  }

  noduloSiples() {
    this.countLesoes++;

    // POG - Ajustar isso e pegar pelo form. Ass: Gabs
    if (this.slEcoginicidade != undefined) {
      if (this.slEcoginicidade.value != undefined) {
        const objeto = {elemento: this.slEcoginicidade, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 4618, value: this.ecogenicidadeSelected }
        const objeto = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    // POG - Ajustar isso e pegar pelo form. Ass: Gabs
    if (this.slForma != undefined) {
      if (this.slForma.value != undefined) {
        const objeto = {elemento: this.slForma, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 4619, value: this.formaSelected }
        const objeto = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    // POG - Ajustar isso e pegar pelo form. Ass: Gabs
    if (this.slMargem != undefined) {
      if (this.slMargem.value != undefined) {
        const objeto = {elemento: this.slMargem, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 4620, value: this.margemSelected }
        const objeto = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    // POG - Ajustar isso e pegar pelo form. Ass: Gabs
    if (this.slEixo != undefined) {
      if (this.slEixo.value != undefined) {
        const objeto = {elemento: this.slEixo, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 4621, value: this.eixoSelected }
        const objeto = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    // POG - Ajustar isso e pegar pelo form. Ass: Gabs
    if (this.slSombra != undefined) {
      if (this.slSombra.value != undefined) {
        const objeto = {elemento: this.slSombra, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 4622, value: this.sombraSelected }
        const objeto = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.slLocalizacao != undefined && this.slLocalizacao.value != undefined) {
      const objeto = {elemento: this.slLocalizacao, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
      if(this.slLocalizacao.value === 'na região retroareolar'){
        const elemento = { id: 4654}
        const retroareolar = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(retroareolar);
      }
        
    }

    if (this.slLadoMama != undefined && this.slLadoMama.value != undefined) {
      const objeto = {elemento: this.slLadoMama, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    this.formataMedindo();

    if (this.inDistPele != undefined &&
      this.inDistPele.nativeElement.id != undefined &&
      this.inDistPele.nativeElement.value != '') {

      const objeto = {elemento: this.inDistPele.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.inDistPapila != undefined &&
      this.inDistPapila.nativeElement.id != undefined &&
      this.inDistPapila.nativeElement.value != '') {

      const objeto = {elemento: this.inDistPapila.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }
    
    if (this.slFluxoDoppler != undefined && this.slFluxoDoppler.value != undefined) {
      const objeto = {elemento: this.slFluxoDoppler, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }
  }

  nodulosSemelhantes() {
    this.countLesoes++;

    let posicao = 0;
    this.linha = 0;
    let isRetroAre = false;

    // POG - Ajustar isso e pegar pelo form. Ass: Gabs
    if (this.slEcoginicidade != undefined) {
      if (this.slEcoginicidade.value != undefined) {
        const objeto = {elemento: this.slEcoginicidade, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 4631, value: this.ecogenicidadeSelected }
        const objeto = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    // POG - Ajustar isso e pegar pelo form. Ass: Gabs
    if (this.slForma != undefined) {
      if (this.slForma.value != undefined) {
        const objeto = {elemento: this.slForma, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 4632, value: this.formaPluralSelected }
        const objeto = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    // POG - Ajustar isso e pegar pelo form. Ass: Gabs
    if (this.slMargem != undefined) {
      if (this.slMargem.value != undefined) {
        const objeto = {elemento: this.slMargem, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 4633, value: this.margemSelected }
        const objeto = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    // POG - Ajustar isso e pegar pelo form. Ass: Gabs
    if (this.slEixo != undefined) {
      if (this.slEixo.value != undefined) {
        const objeto = {elemento: this.slEixo, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 4634, value: this.eixoSelected }
        const objeto = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    // POG - Ajustar isso e pegar pelo form. Ass: Gabs
    if (this.slSombra != undefined) {
      if (this.slSombra.value != undefined) {
        const objeto = {elemento: this.slSombra, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 4635, value: this.sombraSelected }
        const objeto = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.noduloSemChildren.forEach(obj => {

      if (obj.nativeElement != undefined) {

        if (obj.nativeElement.id == 4636) {
          if (posicao < 3) {
            posicao++;
          } else {
            posicao = 1;
          }
        } else {
          posicao = 0;
        }
        // Desabilitando o input de distancia da papila
        if(obj.nativeElement.id == 4639 && isRetroAre) {
          obj.nativeElement.value = '';
          obj.nativeElement.disabled = true;
        } else {
          obj.nativeElement.disabled = false;
        }

        if (obj.nativeElement.value != '') {
        this.objetofilho = {id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes};
        this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true};
        this.lesoesDinamicas.emit(this.objeto);
        }
      } else {

        if (obj.id == 4640) {
            this.linha++;
        }
        
        this.objetofilho = {id: obj.id, value: obj.value, cenario: this.countLesoes};
        this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true};
        this.lesoesDinamicas.emit(this.objeto);

        if(obj.id == 4637) {
          if(obj.value === 'Na região retroareolar'){
            isRetroAre = true;
            const elem = { id: '4654',value:'4654',  cenario: this.countLesoes}
            const retroareolar = {elemento: elem, linha: this.linha, posicao: 0, isPrimeiraLesao: true};
            this.lesoesDinamicas.emit(retroareolar);
            console.log('emitiu nodulosSemelhantes');
          } else {
            isRetroAre = false;
          }
        }
        
      }
    });

  }


  nodulosNaoSemelhantesSimples() {
    this.termoSelecionado.emit(this.ckDoisNodulosNaoSem.id);
    this.countLesoes++;

    let posicao = 0;
    this.linha = 0;
    let isRetroAre = false;
    // if (this.slFluxoDoppler != undefined && this.slFluxoDoppler.value != undefined) {
    //   const objeto = {elemento: this.slFluxoDoppler, isPrimeiraLesao: true};
    //   this.termosGenericos.emit(objeto);
    // }
    this.noduloNaoSemChildren.forEach(obj => {

      if (obj.nativeElement != undefined) {

        if (obj.nativeElement.id == 4647) {
          if (posicao < 3) {
            posicao++;
          } else {
            posicao = 1;
          }
        } else {
          posicao = 0;
        }
        // Desabilitando o input de distancia da papila
        if (obj.nativeElement.id == 4650 && isRetroAre) {
          obj.nativeElement.value = '';
          obj.nativeElement.disabled = true;
        } else {
          obj.nativeElement.disabled = false;
        }
        
        if (obj.nativeElement.value != '') {
        this.objetofilho = {id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes};
        this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true};
        this.lesoesDinamicas.emit(this.objeto);
        }
      } else {

        if (obj.id == 4642) {
          this.linha++;
        }

        this.objetofilho = {id: obj.id, value: obj.value, cenario: this.countLesoes};
        this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true};
        this.lesoesDinamicas.emit(this.objeto);

        if(obj.id == 4648) {
          if(obj.value === 'na região retroareolar'){
            isRetroAre = true;
            const elem = { id: '4655',value:'4655',  cenario: this.countLesoes}
            const retroareolar = {elemento: elem, linha: this.linha, posicao: 0, isPrimeiraLesao: true};
            this.lesoesDinamicas.emit(retroareolar);
            console.log('emitiu nodulosNaoSemelhantesSimples');
          } else {
            isRetroAre = false;
          }
        }
      }
    });
  }

  nodulosNaoSemelhantesLesao() {
    this.countLesoes++;
    const objeto1 = { id: this.rdDoisNodulos.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto1);
    const objeto2 = { id: this.ckDoisNodulosNaoSem.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto2);

    let posicao = 0;
    this.linha = 0;
    let isRetroAre = false;
    // if (this.slFluxoDoppler != undefined && this.slFluxoDoppler.value != undefined) {
    //   const objeto = {elemento: this.slFluxoDoppler, isPrimeiraLesao: true};
    //   this.termosGenericos.emit(objeto);
    // }
    this.noduloNaoSemChildren.forEach(obj => {

      if (obj.nativeElement != undefined) {

        if (obj.nativeElement.id == 4647) {
          if (posicao < 3) {
            posicao++;
          } else {
            posicao = 1;
          }
        } else {
          posicao = 0;
        }
        // Desabilitando o input de distancia da papila
        if (obj.nativeElement.id == 4650 && isRetroAre) {
          obj.nativeElement.value = '';
          obj.nativeElement.disabled = true;
        } else {
          obj.nativeElement.disabled = false;
        }

        if (obj.nativeElement.value != '') {
        this.objetofilho = {id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes};
        this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false};
        this.lesoesDinamicas.emit(this.objeto);
        }
      } else {

        if (obj.id == 4642) {
          this.linha++;
        }

        this.objetofilho = {id: obj.id, value: obj.value, cenario: this.countLesoes};
        this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false};
        this.lesoesDinamicas.emit(this.objeto);

        if(obj.id == 4648) {
          if(obj.value === 'na região retroareolar'){
            isRetroAre = true;
            const elem = { id: '4655',value:'4655',  cenario: this.countLesoes}
            const retroareolar = {elemento: elem, linha: this.linha, posicao: 0, isPrimeiraLesao: false};
            this.lesoesDinamicas.emit(retroareolar);
            console.log('emitiu nodulosNaoSemelhantesLesao');
          } else {
            isRetroAre = false;
          }
        }
      }
    });
  }

  formataMedindo() {
    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inMedida1 != undefined &&
      this.inMedida1.nativeElement.id != undefined &&
      this.inMedida1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);
    }

    if (this.inMedida2 != undefined &&
      this.inMedida2.nativeElement.id != undefined &&
      this.inMedida2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
      }
    }

    if (this.inMedida3 != undefined &&
      this.inMedida3.nativeElement.id != undefined &&
      this.inMedida3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = {id: 4623, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(objetoLesao);
    }
  }

  ajustaArrayDinamico() {

    if ((this.ckDoisNoduloSem !== undefined && !this.ckDoisNoduloSem.checked) || !this.rdDoisNodulos.checked) {
      // this.showAndHideTerms.sahDoisNoduloSem = false;

      const arrayTotal = this.noduloSemArray.length;

      for (let i  = 0; i <= arrayTotal; i++) {
        const index = this.noduloSemArray.length - 1

        if (index != 0) {
          this.noduloSemArray.removeAt(index);
        }
      }
      this.noduloSemArray.reset();
    }

    if ((this.ckDoisNodulosNaoSem !== undefined && !this.ckDoisNodulosNaoSem.checked) || !this.rdDoisNodulos.checked) {
      // this.showAndHideTerms.sahDoisNodulosNaoSem = false;

      const arrayTotal = this.noduloNaoSemArray.length;

      for (let i  = 0; i <= arrayTotal; i++) {
        const index = this.noduloNaoSemArray.length - 1

        if (index != 0) {
          this.noduloNaoSemArray.removeAt(index);
        }
      }
      this.noduloNaoSemArray.reset();
    }

  }

}
