import { Component, OnInit, ViewChild } from '@angular/core';
import { MedicalReportService } from '../services/medicalReport.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-lista-laudo',
  templateUrl: './lista-laudo.component.html',
  styleUrls: ['./lista-laudo.component.css', '../app.component.css']
})
export class ListaLaudoComponent implements OnInit {

  displayedColumns: string[] = ['title', 'reportCode', 'finishedOn', 'url'];
  dataSource: any;
  dataSourceTemp: any;
  selectedDataSource: any;
  title: string;
  description: string;
  createdOn: string;
  watcher: Subscription;
  mostrarLaudo: boolean;
  dataControl_inicial: FormControl;
  dataControl_final: FormControl;
  dateNow: Date = new Date();
  formBusca: FormGroup;
  laudos: any[] = [];
  laudosOr: any[] = [];
  public searchForm: FormGroup;
  public busca = "";
  public dataInicialExame = "";
  public dataFinalExame = "";
  public loading: boolean = true;
  minDate: Date;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router,
    private _service: MedicalReportService,
    public sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.carregarlaudo();
    this.searchFormInit();
  }

  searchFormInit() {
    this.searchForm = new FormGroup({
      busca: new FormControl('', Validators.pattern('^[a-zA-Z ]+$')),
      dataInicialExame: new FormControl('',),
      dataFinalExame: new FormControl('')
    });
    this.searchForm.get('dataFinalExame').disable();
  }

  carregarlaudo() {
    const userID = localStorage.getItem('userID');
    this._service.buscaLaudo(userID)
      .pipe(take(1))
      .subscribe(
        (laudo: any) => {
          if (laudo) {
            this.dataSource = new MatTableDataSource(laudo);
            this.selectedDataSource = this.dataSource.data;
            this.dataSourceTemp = this.dataSource.data;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;

            this.dataSource.filterPredicate = (data:{title: string, reportCode:string}, filterValue: string) =>{
              let titlefilter = data.title.trim().toLowerCase().indexOf(filterValue) !== -1;
              let reportCodeFilter = data.reportCode.trim().toLowerCase().indexOf(filterValue) !== -1;

              if(titlefilter){
                return titlefilter;
              } else{
                return reportCodeFilter;
              }
            }
            this.paginator._intl.itemsPerPageLabel = 'Registros por página';
            this.loading = false;

          }
        },
        erro => {
          console.log(erro);
        }
      );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  resetPesquisa(){    
    this.searchForm.get('busca').reset();
    this.searchForm.get('dataInicialExame').reset();
    this.searchForm.get('dataFinalExame').reset();
    this.searchForm.get('dataFinalExame').disable();
    this.dataSource.filter = '';
    this.dataSource.data = this.dataSourceTemp;
  }

  resetPesquisaPorData(){
    this.dataSource.data = this.selectedDataSource;
    this.filterByDate();
  }

  filterByDate() {
    let selectedDataSource: any[] = [];
    const datai = new Date(this.searchForm.get('dataInicialExame').value).setUTCHours(0);
    const dataf = new Date(this.searchForm.get('dataFinalExame').value).setUTCHours(0);
    this.minDate = new Date(datai);

    if(datai != 0 && !isNaN(datai)){
      this.searchForm.get('dataFinalExame').enable();
    }else{
      this.searchForm.get('dataFinalExame').reset();
      this.searchForm.get('dataFinalExame').disable();
    }

    if (!isNaN(datai) && !isNaN(dataf) && datai != 0 && dataf != 0) {
      this.dataSource.data.forEach((item, index) => {
        console.log(item.finishedOn)
        const filterArray = item.finishedOn.split('T');
        const busca = filterArray[0];
        const dataLaudo = new Date(busca).getTime();
        if (dataLaudo >= datai && dataLaudo <= dataf) {
          selectedDataSource.push(item);
        }
      });
      this.dataSource.data = selectedDataSource;
    } else{
      this.dataSource.data = this.dataSourceTemp;
    }
  }

  abrirLaudo(laudo: any) {
    this._service.emitLaudo(laudo);
    this.goto('lista/detalhar');
  }

  goto(url: string) {
    this.router.navigateByUrl(url);
  }

}
