import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-superficie-ossea-quadril',
  templateUrl: './superficie-ossea-quadril.component.html',
  styleUrls: ['./superficie-ossea-quadril.component.css']
})
export class SuperficieOsseaQuadrilComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckEntesofitos') ckEntesofitos: MatCheckbox;
  @ViewChild('slLocalizacaoEnte') slLocalizacaoEnte: MatSelect;

  @ViewChild('ckOsteofitos') ckOsteofitos: MatCheckbox;
  @ViewChild('slLocalizacaoOste') slLocalizacaoOste: MatSelect;

  localizacoesEnte: ArrayDropDown[] = [
    {value: 'no trocânter maior do fêmur', viewValue: 'No trocânter maior'},
    {value: 'no trocânter menor do fêmur', viewValue: 'No trocânter menor'},
    {value: 'na crista ilíaca', viewValue: 'Na crista ilíaca'},
    {value: 'na espinha ilíaca anterossuperior', viewValue: 'Ilíaca anterossuperior'},
    {value: 'na espinha ilíaca anteroinferior', viewValue: 'Ilíaca anteroinferior'}
  ];

  localizacoesOste: ArrayDropDown[] = [
    {value: 'femoroacetabulares', viewValue: 'Femoroacetabulares'},
    {value: 'no acetábulo', viewValue: 'Acetábulo'},
    {value: 'na cabeça femoral', viewValue: 'Cabeça femoral'}

  ];

  showAndHideTerms: any;
  countLesoes = 0;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahEntesofitos: false,
      sahOsteofitos: false
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahEntesofitos = false;
    this.showAndHideTerms.sahOsteofitos = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahEntesofitos = false;
    this.showAndHideTerms.sahOsteofitos = false;
    this.catchIDs();
  }

  clickEntesofitos() {
    this.verificaLesoes();
    if(this.showAndHideTerms.sahEntesofitos === false) {
      this.showAndHideTerms.sahEntesofitos = true;
    } else {
      this.showAndHideTerms.sahEntesofitos = false;
    }
    this.catchIDs();
  }

  clickOsteofitos() {
    this.verificaLesoes();
    if(this.showAndHideTerms.sahOsteofitos === false) {
      this.showAndHideTerms.sahOsteofitos = true;
    } else {
      this.showAndHideTerms.sahOsteofitos = false;
    }
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.ckEntesofitos && this.ckEntesofitos.checked) || (this.ckOsteofitos && this.ckOsteofitos.checked)){
      this.rdAlterado.checked = true;
    }
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdAlterado.checked) {

      this.emitEntesofitos();

      if(this.countLesoes > 0) {
        this.emitOsteofitosLesao();
      } else {
        this.emitOsteofitos();
      }
    }

    this.listaCompleta.emit();
  }

  emitEntesofitos() {
    if(this.ckEntesofitos && this.ckEntesofitos.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEntesofitos.value);

      if (this.slLocalizacaoEnte && this.slLocalizacaoEnte.value) {
        const objeto = { elemento: this.slLocalizacaoEnte, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitOsteofitos() {
    if(this.ckOsteofitos && this.ckOsteofitos.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckOsteofitos.value);

      if (this.slLocalizacaoOste && this.slLocalizacaoOste.value) {
        const objeto = { elemento: this.slLocalizacaoOste, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitOsteofitosLesao() {
    if (this.ckOsteofitos !== undefined && this.ckOsteofitos.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckOsteofitos.value, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacaoOste && this.slLocalizacaoOste.value) {
        const objeto = {elemento: this.slLocalizacaoOste, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }
    }

  }
}
