import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Term } from '../../../../models/BO/Term';
import { Validacoes } from '../../../../utils/validacoes';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { TermResult } from '../../../../models/BO/TermResult';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-arteria-do-membro-inferior',
  templateUrl: './arteria-do-membro-inferior.component.html',
  styleUrls: ['./arteria-do-membro-inferior.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
})
export class ArteriaDoMembroInferiorComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;


  public htmlContent: string;
  public mask: string;
  public Subtitulo: string;
  public subtituloBloco: string;
  public ArteriaFemoralComum: Boolean = false;
  public ArteriaFemoralSuperficial: Boolean = false;
  public ArteriaFemoralProfunda: Boolean = false;
  public ArteriaPoplitea: Boolean = false;
  public ArteriaTibialAnterior: Boolean = false;
  public ArteriaTibialPosterior: Boolean = false;
  public ArteriaFibular: Boolean = false;
  public Ateromatose: Boolean = false;
  public achadosTexto: Boolean = false;
  public AchadosAdicionais: Boolean = false;
  public rodape = false;
  public rodapeTexto: any = '';
  public indicacao: Boolean = false;
  public cabecalhoTexto = '';
  public cabecalho: Boolean = false;
  public lateralidade: Boolean = true;
  public generoLateralidade: string;
  public indicacaoTexto: any = '';
  public achadosAdTexto: any = '';
  public Lado: String = '';

  public ArteriaFemoralComumID: Number = 228;
  public ArteriaFemoralSuperficialID: Number = 229;
  public ArteriaFemoralProfundaID: Number = 230;
  public ArteriaPopliteaID: Number = 231;
  public ArteriaTibialAnteriorID: Number = 232;
  public ArteriaTibialPosteriorID: Number = 233;
  public ArteriaFibularID: Number = 234;
  public AteromatoseId: Number = 235;
  public achadosTextoID: Number = 236;
  public medicalReportTypeID: number = 43;

  public term: any = new Term();
  public termResultsAPI: Array<TermResult>;
  public termConclusionAPI: Array<TermResult>;
  public showForm: Boolean = true;
  public unidadeMedida: string;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  editLaudoMode: boolean = false;

  public blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },

    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Lateralidade',
      id: 'lateralidade',
      name: 'lateralidade',
      selected: true,
    },
    {
      id: this.ArteriaFemoralComumID,
      name: 'Artéria Femoral Comum',
      titulo: 'Artéria Femoral Comum',
      selected: false,
    },
    {
      id: this.ArteriaFemoralSuperficialID,
      name: 'Artéria Femoral Superficial',
      titulo: 'Artéria Femoral Superficial',
      selected: false,
    },
    {
      id: this.ArteriaFemoralProfundaID,
      name: 'Artéria Femoral Profunda',
      titulo: 'Artéria Femoral Profunda',
      selected: false,
    },
    {
      titulo: 'Artéria Poplítea',
      id: this.ArteriaPopliteaID,
      name: 'Artéria Poplítea',
      selected: false,
    },
    {
      titulo: 'Artéria Tibial Anterior',
      id: this.ArteriaTibialAnteriorID,
      name: 'Artéria Tibial Anterior',
      selected: false,
    },
    {
      titulo: 'Artéria Tibial Posterior',
      id: this.ArteriaTibialPosteriorID,
      name: 'Artéria Tibial Posterior',
      selected: false,
    },
    {
      titulo: 'Artéria Fibular',
      id: this.ArteriaFibularID,
      name: 'Artéria Fibular',
      selected: false,
    },
    {
      titulo: 'Ateromatose',
      id: this.AteromatoseId,
      name: 'Ateromatose',
      selected: false,
    },
    {
      titulo: 'Achados adicionais',
      id: this.achadosTextoID,
      name: 'Achados adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  listTermDefault = [
    { listTerm: [3600], blockID: 228 },
    { listTerm: [3620], blockID: 229 },
    { listTerm: [3640], blockID: 230 },
    { listTerm: [3658], blockID: 231 },
    { listTerm: [3676], blockID: 232 },
    { listTerm: [3692], blockID: 233 },
    { listTerm: [3708], blockID: 234 },
    { listTerm: [3746], blockID: 235 },
    { listTerm: [3727], blockID: 236 },
  ]
  public Descricoes: DescricaoConclusao[] = [
    {
      value: 'Foram avaliados os seguintes segmentos arteriais.',
      blockID: 0, textTermID: 0, visivel: true
    },
    {
      value: '<b>Artéria femoral comum</b> pérvia, de trajeto e calibre preservados.',
      blockID: 228, textTermID: 0, visivel: true
    },
    {
      value: '<b>Artéria femoral superficial</b> pérvia, de trajeto e calibre preservados.',
      blockID: 229, textTermID: 0, visivel: true
    },
    {
      value: '<b>Artéria femoral profunda proximal</b> pérvia, de trajeto e calibre preservados.',
      blockID: 230, textTermID: 0, visivel: true
    },
    {
      value: '<b>Artéria poplítea</b> pérvia, de trajeto e calibre preservados.',
      blockID: 231, textTermID: 0, visivel: true
    },
    {
      value: '<b>Artéria tibial anterior</b> pérvia, de trajeto e calibre preservados.',
      blockID: 232, textTermID: 0, visivel: true
    },
    {
      value: '<b>Artéria tibial posterior</b> pérvia, de trajeto e calibre preservados.',
      blockID: 233, textTermID: 0, visivel: true
    },
    {
      value: '<b>Artéria fibular</b> pérvia, de trajeto e calibre preservados.',
      blockID: 234, textTermID: 0, visivel: true
    },
    {
      value: 'Ausência de placa ateromatosa ou espessamento intimal nos segmentos vasculares avaliados.',
      blockID: 235, textTermID: 0, visivel: true
    },
    {
      value: 'Não há sinais de estenose hemodinamicamente significativa.',
      blockID: 0, textTermID: 0, visivel: true
    },
    {
      value: 'A análise espectral evidenciou fluxo trifásico com velocidades de pico sistólico normais em todos os vasos estudados.',
      blockID: 0, textTermID: 0, visivel: true
    },
    {
      value: '',
      blockID: 236, textTermID: 0, visivel: true
    },

  ];



  public conclusoes: DescricaoConclusao[] = [
    {
      value: 'Ultrassonografia com Doppler colorido das artérias analisadas dentro dos limites da normalidade.',
      blockID: 0, textTermID: 0, visivel: true
    },
    { value: '', blockID: 228, textTermID: 0, visivel: true },
    { value: '', blockID: 229, textTermID: 0, visivel: true },
    { value: '', blockID: 230, textTermID: 0, visivel: true },
    { value: '', blockID: 231, textTermID: 0, visivel: true },
    { value: '', blockID: 232, textTermID: 0, visivel: true },
    { value: '', blockID: 233, textTermID: 0, visivel: true },
    { value: '', blockID: 234, textTermID: 0, visivel: true },
    { value: '', blockID: 235, textTermID: 0, visivel: true },
    { value: '', blockID: 236, textTermID: 0, visivel: true },
  ];

  genericTermsArteriaFemoralComum: TermosGenericos[] = [
    { value: '', selected: false, termID: 3602, term: '[Motivo]' },
    { value: '', selected: false, termID: 3604, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3605, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3606, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 3608, term: '[Superficie]' },
    { value: '', selected: false, termID: 3609, term: '[Espessura]' },
    { value: '', selected: false, termID: 3610, term: '[Estenose]' },
    { value: '', selected: false, termID: 3613, term: '[FluxoDistal]' },
    { value: '', selected: false, termID: 3736, term: '[Localizacaos]' },
    { value: '', selected: false, termID: 3615, term: '[Extensao]' },
    { value: '', selected: false, termID: 3619, term: '[Calibre]' },
    { value: '', selected: false, termID: 3618, term: '[Trombo]' },
  ];

  genericTermsArteriaSuperficialFemoral: TermosGenericos[] = [
    { value: '', selected: false, termID: 3622, term: '[Motivo]' },
    { value: '', selected: false, termID: 3624, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3625, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3626, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 3627, term: '[Superficie]' },
    { value: '', selected: false, termID: 3628, term: '[Espessura]' },
    { value: '', selected: false, termID: 3629, term: '[Estenose]' },
    { value: '', selected: false, termID: 3631, term: '[FluxoDistall]' },
    { value: '', selected: false, termID: 3737, term: '[Localizacaos]' },
    { value: '', selected: false, termID: 3634, term: '[Extensao]' },
    { value: '', selected: false, termID: 3638, term: '[Trombo]' },
    { value: '', selected: false, termID: 3639, term: '[Calibre]' },
  ];

  genericTermsArteriaFemoralProfunda: TermosGenericos[] = [
    { value: '', selected: false, termID: 3642, term: '[Motivo]' },
    { value: '', selected: false, termID: 3644, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3645, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 3646, term: '[Superficie]' },
    { value: '', selected: false, termID: 3647, term: '[Espessura]' },
    { value: '', selected: false, termID: 3648, term: '[Estenose]' },
    { value: '', selected: false, termID: 3650, term: '[FluxoDistal]' },
    { value: '', selected: false, termID: 3652, term: '[Extensao]' },
    { value: '', selected: false, termID: 3654, term: '[Estenose]' },
    { value: '', selected: false, termID: 3657, term: '[Calibre]' },
    { value: '', selected: false, termID: 3656, term: '[Trombo]' },
  ];

  genericTermsArteriaPoplitea: TermosGenericos[] = [
    { value: '', selected: false, termID: 3660, term: '[Motivo]' },
    { value: '', selected: false, termID: 3662, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3663, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3664, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 3665, term: '[Superficie]' },
    { value: '', selected: false, termID: 3666, term: '[Espessura]' },
    { value: '', selected: false, termID: 3667, term: '[Estenose]' },
    { value: '', selected: false, termID: 3669, term: '[FluxoDistal]' },
    { value: '', selected: false, termID: 3738, term: '[Localizacaos]' },
    { value: '', selected: false, termID: 3671, term: '[Extensao]' },
    { value: '', selected: false, termID: 3674, term: '[Trombo]' },
    { value: '', selected: false, termID: 3675, term: '[Calibre]' },
  ];

  genericTermsArteriaTibialAnterior: TermosGenericos[] = [
    { value: '', selected: false, termID: 3678, term: '[Motivo]' },
    { value: '', selected: false, termID: 3680, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3681, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3682, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 3683, term: '[Superficie]' },
    { value: '', selected: false, termID: 3684, term: '[Espessura]' },
    { value: '', selected: false, termID: 3685, term: '[Estenose]' },
    { value: '', selected: false, termID: 3742, term: '[FluxoDistal]' },
    { value: '', selected: false, termID: 3687, term: '[FluxoDistal]' },
    { value: '', selected: false, termID: 3739, term: '[Localizacaos]' },
    { value: '', selected: false, termID: 3690, term: '[Extensao]' },
  ];

  genericTermsArteriaTibialPosterior: TermosGenericos[] = [
    { value: '', selected: false, termID: 3694, term: '[Motivo]' },
    { value: '', selected: false, termID: 3696, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3697, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3698, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 3699, term: '[Superficie]' },
    { value: '', selected: false, termID: 3700, term: '[Espessura]' },
    { value: '', selected: false, termID: 3701, term: '[Estenose]' },
    { value: '', selected: false, termID: 3743, term: '[FluxoDistal]' },
    { value: '', selected: false, termID: 3703, term: '[FluxoDistal]' },
    { value: '', selected: false, termID: 3706, term: '[Extensao]' },
    { value: '', selected: false, termID: 3740, term: '[Localizacaos]' },
  ];

  genericTermsArteriaFibular: TermosGenericos[] = [
    { value: '', selected: false, termID: 3710, term: '[Motivo]' },
    { value: '', selected: false, termID: 3712, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3713, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3714, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 3715, term: '[Superficie]' },
    { value: '', selected: false, termID: 3716, term: '[Espessura]' },
    { value: '', selected: false, termID: 3717, term: '[Estenose]' },
    { value: '', selected: false, termID: 3744, term: '[FluxoDistal]' },
    { value: '', selected: false, termID: 3719, term: '[FluxoDistal]' },
    { value: '', selected: false, termID: 3722, term: '[Extensao]' },
    { value: '', selected: false, termID: 3741, term: '[Localizacaos]' },

  ];


  genericAchadosAdicionais: TermosGenericos[] = [
    { value: '', selected: false, termID: 3728, term: '[Laminas Liquidas]' },
    { value: '', selected: false, termID: 3729, term: '[Localizacao Face]' },
    { value: '', selected: false, termID: 3730, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3732, term: '[Paredes]' },
    { value: '', selected: false, termID: 3733, term: '[Conteudo]' },
    { value: '', selected: false, termID: 3734, term: '[Medida]' },
    { value: '', selected: false, termID: 3735, term: '[Extensao Na Perna]' },
  ];

  public switchFormReport = true;

  constructor(private readonly _selecteditememiter: SelectedItemEmiter,
    private readonly _toastr: ToastrManager,
    private readonly _copyPaste: CopyPaste,
    private _validacoes: Validacoes,
    private readonly _laudoservice: LaudosService,
    private readonly _serviceMedicalReportLog: MedicalReportLogService,
    private readonly _serviceMedicalReport: MedicalReportService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public readonly dialog: MatDialog,
    public _addText: AddText,
    private _config: ConfigLaudoService,
    private dataService: DataTermService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("arteria-do-membro-inferior-aortaAnalise", this.Descricoes, this.conclusoes);
    this.resetaDialog();
    this.generoLateralidade = 'masculino';
    this.Subtitulo = 'Lateralidade';
    this.subtituloBloco = 'Lateralidade';

    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto(this.newEditor);
    // });
    const listBlocks = [
      this.ArteriaFemoralComumID,
      this.ArteriaFemoralSuperficialID,
      this.ArteriaFemoralProfundaID,
      this.ArteriaPopliteaID,
      this.ArteriaTibialAnteriorID,
      this.ArteriaTibialPosteriorID,
      this.ArteriaFibularID,
      this.AteromatoseId,
      this.achadosTextoID,
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "arteria-do-membro-inferior-aortaMask").then((obj: any) => {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "arterialInferior");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-arteria-membro-inferior-doppler');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-arteria-membro-inferior-doppler');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo() {
    this._pouchService.getAll("arteria-do-membro-inferior-aortaAnalise").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.Descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.Lado = "";
    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.achadosAdTexto = "";
    this.rodapeTexto = "";
    this.showForm = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  public selecionar(itemSelecionado): any {
    const arrayAtualizado = this._selecteditememiter.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if (itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if (this.editLaudoMode && Number.isInteger(itemSelecionado.id)) {
      let seachTerm = this.listTermDefault.filter(item => item.blockID === itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm, seachTerm[0].blockID);
    }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.lateralidade = (itemSelecionado.id === 'lateralidade');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === this.achadosTextoID);
    this.rodape = (itemSelecionado.id === 'rodape');
    this.ArteriaFemoralComum = (itemSelecionado.id === this.ArteriaFemoralComumID);
    this.ArteriaFemoralSuperficial = (itemSelecionado.id === this.ArteriaFemoralSuperficialID);
    this.ArteriaFemoralProfunda = (itemSelecionado.id === this.ArteriaFemoralProfundaID);
    this.ArteriaPoplitea = (itemSelecionado.id === this.ArteriaPopliteaID);
    this.ArteriaTibialAnterior = (itemSelecionado.id === this.ArteriaTibialAnteriorID);
    this.ArteriaTibialPosterior = (itemSelecionado.id === this.ArteriaTibialPosteriorID);
    this.ArteriaFibular = (itemSelecionado.id === this.ArteriaFibularID);
    this.Ateromatose = (itemSelecionado.id === this.AteromatoseId);
  }

  public limparTermos(): any {
    this.term.listTermID = new Array<number>();

    this.genericTermsArteriaFemoralComum = [
      { value: '', selected: false, termID: 3602, term: '[Motivo]' },
      { value: '', selected: false, termID: 3604, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3605, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3606, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 3608, term: '[Superficie]' },
      { value: '', selected: false, termID: 3609, term: '[Espessura]' },
      { value: '', selected: false, termID: 3610, term: '[Estenose]' },
      { value: '', selected: false, termID: 3613, term: '[FluxoDistal]' },
      { value: '', selected: false, termID: 3736, term: '[Localizacaos]' },
      { value: '', selected: false, termID: 3615, term: '[Extensao]' },
      { value: '', selected: false, termID: 3619, term: '[Calibre]' },
      { value: '', selected: false, termID: 3618, term: '[Trombo]' },
    ];

    this.genericTermsArteriaSuperficialFemoral = [
      { value: '', selected: false, termID: 3622, term: '[Motivo]' },
      { value: '', selected: false, termID: 3624, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3625, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3626, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 3627, term: '[Superficie]' },
      { value: '', selected: false, termID: 3628, term: '[Espessura]' },
      { value: '', selected: false, termID: 3629, term: '[Estenose]' },
      { value: '', selected: false, termID: 3631, term: '[FluxoDistall]' },
      { value: '', selected: false, termID: 3737, term: '[Localizacaos]' },
      { value: '', selected: false, termID: 3634, term: '[Extensao]' },
      { value: '', selected: false, termID: 3638, term: '[Trombo]' },
      { value: '', selected: false, termID: 3639, term: '[Calibre]' },
    ];

    this.genericTermsArteriaFemoralProfunda = [
      { value: '', selected: false, termID: 3642, term: '[Motivo]' },
      { value: '', selected: false, termID: 3644, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3645, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 3646, term: '[Superficie]' },
      { value: '', selected: false, termID: 3647, term: '[Espessura]' },
      { value: '', selected: false, termID: 3648, term: '[Estenose]' },
      { value: '', selected: false, termID: 3650, term: '[FluxoDistal]' },
      { value: '', selected: false, termID: 3652, term: '[Extensao]' },
      { value: '', selected: false, termID: 3654, term: '[Estenose]' },
      { value: '', selected: false, termID: 3657, term: '[Calibre]' },
      { value: '', selected: false, termID: 3656, term: '[Trombo]' },
    ];

    this.genericTermsArteriaPoplitea = [
      { value: '', selected: false, termID: 3660, term: '[Motivo]' },
      { value: '', selected: false, termID: 3662, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3663, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3664, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 3665, term: '[Superficie]' },
      { value: '', selected: false, termID: 3666, term: '[Espessura]' },
      { value: '', selected: false, termID: 3667, term: '[Estenose]' },
      { value: '', selected: false, termID: 3669, term: '[FluxoDistal]' },
      { value: '', selected: false, termID: 3738, term: '[Localizacaos]' },
      { value: '', selected: false, termID: 3671, term: '[Extensao]' },
      { value: '', selected: false, termID: 3675, term: '[Calibre]' },
      { value: '', selected: false, termID: 3674, term: '[Trombo]' },
    ];

    this.genericTermsArteriaTibialAnterior = [
      { value: '', selected: false, termID: 3678, term: '[Motivo]' },
      { value: '', selected: false, termID: 3680, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3681, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3682, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 3683, term: '[Superficie]' },
      { value: '', selected: false, termID: 3684, term: '[Espessura]' },
      { value: '', selected: false, termID: 3685, term: '[Estenose]' },
      { value: '', selected: false, termID: 3742, term: '[FluxoDistal]' },
      { value: '', selected: false, termID: 3687, term: '[FluxoDistal]' },
      { value: '', selected: false, termID: 3739, term: '[Localizacaos]' },
      { value: '', selected: false, termID: 3690, term: '[Extensao]' },
    ];

    this.genericTermsArteriaTibialPosterior = [
      { value: '', selected: false, termID: 3694, term: '[Motivo]' },
      { value: '', selected: false, termID: 3696, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3697, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3698, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 3699, term: '[Superficie]' },
      { value: '', selected: false, termID: 3700, term: '[Espessura]' },
      { value: '', selected: false, termID: 3701, term: '[Estenose]' },
      { value: '', selected: false, termID: 3743, term: '[FluxoDistal]' },
      { value: '', selected: false, termID: 3703, term: '[FluxoDistal]' },
      { value: '', selected: false, termID: 3706, term: '[Extensao]' },
      { value: '', selected: false, termID: 3740, term: '[Localizacaos]' },
    ];

    this.genericTermsArteriaFibular = [
      { value: '', selected: false, termID: 3710, term: '[Motivo]' },
      { value: '', selected: false, termID: 3712, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3713, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3714, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 3715, term: '[Superficie]' },
      { value: '', selected: false, termID: 3716, term: '[Espessura]' },
      { value: '', selected: false, termID: 3717, term: '[Estenose]' },
      { value: '', selected: false, termID: 3744, term: '[FluxoDistal]' },
      { value: '', selected: false, termID: 3719, term: '[FluxoDistal]' },
      { value: '', selected: false, termID: 3722, term: '[Extensao]' },
      { value: '', selected: false, termID: 3741, term: '[Localizacaos]' },
    ];


    this.genericAchadosAdicionais = [
      { value: '', selected: false, termID: 3728, term: '[Laminas Liquidas]' },
      { value: '', selected: false, termID: 3729, term: '[Localizacao Face]' },
      { value: '', selected: false, termID: 3730, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3732, term: '[Paredes]' },
      { value: '', selected: false, termID: 3733, term: '[Conteudo]' },
      { value: '', selected: false, termID: 3734, term: '[Medida]' },
      { value: '', selected: false, termID: 3735, term: '[Extensao Na Perna]' },
    ];
  }
  public MontaDescricaoArteriaFemoralComum(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3602 || +term === 3604 || +term === 3605 || +term === 3606
        || +term === 3608 || +term === 3609 || +term === 3610 || +term === 3613 ||
        +term === 3736 || +term === 3615 || +term === 3618 || +term === 3619 || +term === 3612) {
        this.genericTermsArteriaFemoralComum.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 3602) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            } else if (+generic.termID === 3618) {
              if (generic.value === 'trombo mural') {
                texto = texto.replace(generic.term, 'Nota-se trombo mural no seu interior, sem determinar oclusão');
              } else if (generic.value === 'trombose') {
                texto = texto.replace(generic.term, 'Ausência de fluxo vascular detectável no seu interior, inferindo trombose');
              }

            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }

      this.Descricoes.forEach(obj => {
        var termsPlaca = ["3603", "3604", "3605", "3606", "3608", "3609", "3610", "3615"];
        var termsMultiplasPlacas = ["3612", "3613"];
        var termsTrombose = ["3614", "3736", "3615"];
        var termsTrombose2 = ["3614", "3736"];

        this.esconderDescricaoArterias(obj, termsPlaca, termsMultiplasPlacas, termsTrombose, termsTrombose2);
      });
    });

    return texto;
  }

  public MontaConclussaoArteriaFemoralComum(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3602 || +term === 3604 || +term === 3605 || +term === 3606
        || +term === 3608 || +term === 3609 || +term === 3610 || +term === 3613 ||
        +term === 3736 || +term === 3615 || +term === 3618 || +term === 3619) {
        this.genericTermsArteriaFemoralComum.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 3618) {
              if (generic.value === 'trombo mural') {
                texto = texto.replace(generic.term, ', com trombo mural');
              } else if (generic.value === 'trombose') {
                texto = texto.replace(generic.term, ', com sinais de trombose');
              }

            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoArteriaSuperficialFemoral(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3622 || +term === 3624 || +term === 3625 || +term === 3626
        || +term === 3627 || +term === 3628 || +term === 3629 || +term === 3631 || +term === 3737 ||
        +term === 3634 || +term === 3638 || +term === 3639 || +term === 3673) {
        this.genericTermsArteriaSuperficialFemoral.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 3622) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            } else if (+generic.termID === 3638) {
              if (generic.value === 'trombo mural') {
                texto = texto.replace(generic.term, 'Nota-se trombo mural no seu interior, sem determinar oclusão');
              } else if (generic.value === 'trombose') {
                texto = texto.replace(generic.term, 'Ausência de fluxo vascular detectável no seu interior, inferindo trombose');
              }
            } else if (+generic.termID === 3737) {
              texto = texto.replace(generic.term, generic.value);
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }

      this.Descricoes.forEach(obj => {
        var termsPlaca = ["3623", "3624", "3625", "3626", "3627", "3628", "3629", "3634"];
        var termsMultiplasPlacas = ["3630", "3631"];
        var termsTrombose = ["3633", "3737", "3634"];
        var termsTrombose2 = ["3633", "3737"];

        this.esconderDescricaoArterias(obj, termsPlaca, termsMultiplasPlacas, termsTrombose, termsTrombose2);

      });

    });

    return texto;
  }

  public MontaConclussaoArteriaSuperficialFemoral(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3622 || +term === 3624 || +term === 3625 || +term === 24261
        || +term === 24282 || +term === 3628 || +term === 3629 || +term === 3631 || +term === 3737 ||
        +term === 3634 || +term === 3638 || +term === 3639 || +term === 3673) {
        this.genericTermsArteriaSuperficialFemoral.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 3638) {
              if (generic.value === 'trombo mural') {
                texto = texto.replace(generic.term, ', com trombo mural');
              } else if (generic.value === 'trombose') {
                texto = texto.replace(generic.term, ', com sinais de trombose');
              }

            } else if (+generic.termID === 3737) {
              texto = texto.replace(generic.term, generic.value);
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoArteriaFemoralProfunda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3642 || +term === 3644 || +term === 3645 || +term === 3646
        || +term === 3647 || +term === 3648 || +term === 3650 || +term === 3652 || +term === 3654
        || +term === 3656 || +term === 3657) {
        this.genericTermsArteriaFemoralProfunda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 3642) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            } else if (+generic.termID === 3656) {
              if (generic.value === 'trombo mural') {
                texto = texto.replace(generic.term, 'Nota-se trombo mural no seu interior, sem determinar oclusão');
              } else if (generic.value === 'trombose') {
                texto = texto.replace(generic.term, 'Ausência de fluxo vascular detectável no seu interior, inferindo trombose');
              }

            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }

      this.Descricoes.forEach(obj => {
        var termsPlaca = ["3643", "3644", "3645", "3646", "3647", "3648", "3652"];
        var termsMultiplasPlacas = ["3649", "3650"];
        var termsTrombose = ["3651"];
        var termsTrombose2 = ["3651", "3652"];

        this.esconderDescricaoArterias(obj, termsPlaca, termsMultiplasPlacas, termsTrombose, termsTrombose2);
      });

    });

    return texto;
  }

  public MontaConclussaoArteriaFemoralProfunda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3642 || +term === 3644 || +term === 3645 || +term === 3646
        || +term === 3647 || +term === 3648 || +term === 3650 || +term === 3652 || +term === 3654
        || +term === 3656 || +term === 3657) {
        this.genericTermsArteriaFemoralProfunda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 3656) {
              if (generic.value === 'trombo mural') {
                texto = texto.replace(generic.term, ', com trombo mural');
              } else if (generic.value === 'trombose') {
                texto = texto.replace(generic.term, ', com sinais de trombose');
              }

            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoArteriaPoplitea(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3660 || +term === 3662 || +term === 3663 || +term === 3664
        || +term === 3665 || +term === 3666 || +term === 3667 || +term === 3669 || +term === 3738 ||
        +term === 3687 || +term === 3671 || +term === 3674 || +term === 3675) {
        this.genericTermsArteriaPoplitea.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 3660) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            } else if (+generic.termID === 3674) {
              if (generic.value === 'trombo mural') {
                texto = texto.replace(generic.term, 'Nota-se trombo mural no seu interior, sem determinar oclusão');
              } else if (generic.value === 'trombose') {
                texto = texto.replace(generic.term, 'Ausência de fluxo vascular detectável no seu interior, inferindo trombose');
              }

            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }

      this.Descricoes.forEach(obj => {
        var termsPlaca = ["3661", "3662", "3663", "3664", "3665", "3666", "3667", "3671"];
        var termsMultiplasPlacas = ["3668", "3669"];
        var termsTrombose = ["3670", "3738"];
        var termsTrombose2 = ["3670", "3738", "3671"];

        this.esconderDescricaoArterias(obj, termsPlaca, termsMultiplasPlacas, termsTrombose, termsTrombose2);
      });

    });

    return texto;
  }

  public MontaConclussaoArteriaPoplitea(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3660 || +term === 3662 || +term === 3663 || +term === 3664
        || +term === 3665 || +term === 3666 || +term === 3667 || +term === 3669 || +term === 3738
        || +term === 3687 || +term === 3671 || +term === 3674 || +term === 3675) {
        this.genericTermsArteriaPoplitea.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 3674) {
              if (generic.value === 'trombo mural') {
                texto = texto.replace(generic.term, ', com trombo mural');
              } else if (generic.value === 'trombose') {
                texto = texto.replace(generic.term, ', com sinais de trombose');
              }

            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoArteriaTibialAnterior(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3678 || +term === 3680 || +term === 3681 || +term === 3682
        || +term === 3683 || +term === 3684 || +term === 3685 || +term === 3742 || +term === 3687 || +term === 3690 || +term === 3739) {
        this.genericTermsArteriaTibialAnterior.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 3678) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }

      this.Descricoes.forEach(obj => {
        var termsPlaca = ["3679", "3680", "3681", "3682", "3683", "3684", "3685", "3742", "3690"];
        var termsMultiplasPlacas = ["3686", "3687"];
        var termsMultiplasPlacasSemFluxo = ["3688"];
        var termsTrombose = ["3689", "3739"];
        var termsTrombose2 = ["3689", "3739", "3690"];

        this.esconderDescricaoArterias(obj, termsPlaca, termsMultiplasPlacas, termsTrombose, termsTrombose2, termsMultiplasPlacasSemFluxo);
      });

    });

    return texto;
  }

  public MontaConclussaoArteriaTibialAnterior(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3678 || +term === 3680 || +term === 3681 || +term === 3682
        || +term === 3683 || +term === 3684 || +term === 3685 || +term === 3742 || +term === 3687 || +term === 3690 || +term === 3739) {
        this.genericTermsArteriaTibialAnterior.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoArteriaTibialPosterior(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3694 || +term === 3696 || +term === 3697 || +term === 3698
        || +term === 3699 || +term === 3700 || +term === 3701 || +term === 3743 || +term === 3703 || +term === 3706 || +term === 3740) {
        this.genericTermsArteriaTibialPosterior.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 3694) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }

      this.Descricoes.forEach(obj => {
        var termsPlaca = ["3695", "3696", "3697", "3698", "3699", "3700", "3701", "3743", "3706"];
        var termsMultiplasPlacas = ["3702", "3703"];
        var termsMultiplasPlacasSemFluxo = ["3704"];
        var termsTrombose = ["3705", "3740"];
        var termsTrombose2 = ["3705", "3740", "3706"];

        this.esconderDescricaoArterias(obj, termsPlaca, termsMultiplasPlacas, termsTrombose, termsTrombose2, termsMultiplasPlacasSemFluxo);
      });

    });

    return texto;
  }


  public MontaConclussaoArteriaTibialPosterior(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3694 || +term === 3696 || +term === 3697 || +term === 3698
        || +term === 3699 || +term === 3700 || +term === 3701 || +term === 3743 || +term === 3703 || +term === 3706 || +term === 3740) {
        this.genericTermsArteriaTibialPosterior.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoArteriaFibular(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3710 || +term === 3712 || +term === 3713 || +term === 3714
        || +term === 3715 || +term === 3716 || +term === 3717 || +term === 3744 || +term === 3719 || +term === 3722 || +term === 3741) {
        this.genericTermsArteriaFibular.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 3710) {
              if (generic.value === 'edema') {
                texto = texto.replace(generic.term, 'pelo acentuado edema');
              } else if (generic.value === 'atenuação do feixe acústico') {
                texto = texto.replace(generic.term, 'pela intensa atenuação do feixe acústico posterior');
              }
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }

      this.Descricoes.forEach(obj => {
        var termsPlaca = ["3711", "3712", "3713", "3714", "3715", "3716", "3717", "3744", "3722"];
        var termsMultiplasPlacas = ["3718", "3719"];
        var termsMultiplasPlacasSemFluxo = ["3720"];
        var termsTrombose = ["3721", "3741"];
        var termsTrombose2 = ["3721", "3741", "3722"];

        this.esconderDescricaoArterias(obj, termsPlaca, termsMultiplasPlacas, termsTrombose, termsTrombose2, termsMultiplasPlacasSemFluxo);
      });

    });


    return texto;
  }

  public MontaConclussaoArteriaFibular(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3710 || +term === 3712 || +term === 3713 || +term === 3714
        || +term === 3715 || +term === 3716 || +term === 3717 || +term === 3744 || +term === 3719 || +term === 3722 || +term === 3741) {
        this.genericTermsArteriaFibular.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaDescricaoAchadosAdicionais(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3728 || +term === 3729 || +term === 3730 || +term === 3732 || +term === 3733
        || + term === 3734 || + term === 3735) {
        this.genericAchadosAdicionais.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoAchadosAdicionais(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3728 || +term === 3729 || +term === 3730 || +term === 3732 || +term === 3733
        || + term === 3734 || + term === 3735) {
        this.genericAchadosAdicionais.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public adicionaSelecaoArteriaFemoralComum($event): any {
    this.genericTermsArteriaFemoralComum.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoArteriaSuperficialFemoral($event): any {
    this.genericTermsArteriaSuperficialFemoral.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public adicionaSelecaoArteriaFemoralProfunda($event): any {
    this.genericTermsArteriaFemoralProfunda.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  public adicionaSelecaoArteriaPoplitea($event): any {
    this.genericTermsArteriaPoplitea.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  public adicionaSelecaoArteriaTibialAnterior($event): any {
    this.genericTermsArteriaTibialAnterior.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  public adicionaSelecaoArteriaTibialPosterior($event): any {
    this.genericTermsArteriaTibialPosterior.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  public adicionaSelecaoArteriaFibular($event): any {
    this.genericTermsArteriaFibular.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  public adicionaSelecaoAchadosAdicionais($event): any {
    this.genericAchadosAdicionais.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        achados: this.rodapeTexto
      });
    }
  }

  esconderDescricaoArterias(obj, termsPlaca, termsMultiplasPlacas, termsTrombose, termsTrombose2, termsMultiplasPlacasSemFluxo?) {
    if (!termsMultiplasPlacasSemFluxo) {
      termsMultiplasPlacasSemFluxo = [""];
    }
    if (termsPlaca.every(x => this.term.listTermID.includes(x)) || termsMultiplasPlacas.every(x => this.term.listTermID.includes(x))
      || termsTrombose.every(x => this.term.listTermID.includes(x)) || termsTrombose2.every(x => this.term.listTermID.includes(x))
      || termsMultiplasPlacasSemFluxo.every(x => this.term.listTermID.includes(x))) {
      if (obj.blockID == 235) {
        obj.visivel = false;
        this.Descricoes[9].visivel = false;
        this.Descricoes[10].visivel = false;
      }
    }
    else {
      if (obj.blockID == 235) {
        obj.visivel = true;
        this.Descricoes[9].visivel = true;
        this.Descricoes[10].visivel = true;
      }
    }
  }

  alteraDescricaoNormal() {
    this.term.listTermID.forEach(term => {
      //Arteria Femoral
      if (this.term.blockID === this.ArteriaFemoralComumID) {
        if (+term === 3603 || +term === 3616 || +term === 3617) {
          this.Descricoes[8].visivel = false;
          this.Descricoes[9].visivel = false;
          this.Descricoes[10].visivel = false;
        }
        if (+term === 3600 || +term === 3601) {
          this.Descricoes[8].visivel = true;
          this.Descricoes[9].visivel = true;
          this.Descricoes[10].visivel = true;
        }
      }
      //Arteria Femoral Superficial
      if (this.term.blockID === this.ArteriaFemoralSuperficialID) {
        if (+term === 3623 || +term === 3636 || +term === 3637) {
          this.Descricoes[8].visivel = false;
          this.Descricoes[9].visivel = false;
          this.Descricoes[10].visivel = false;
        }
        if (+term === 3620 || +term === 3621) {
          this.Descricoes[8].visivel = true;
          this.Descricoes[9].visivel = true;
          this.Descricoes[10].visivel = true;
        }
      }
      //Arteria Femoral Profunda
      if (this.term.blockID === this.ArteriaFemoralProfundaID) {
        if (+term === 3643 || +term === 3653 || +term === 3655) {
          this.Descricoes[8].visivel = false;
          this.Descricoes[9].visivel = false;
          this.Descricoes[10].visivel = false;
        }
        if (+term === 3640 || +term === 3641) {
          this.Descricoes[8].visivel = true;
          this.Descricoes[9].visivel = true;
          this.Descricoes[10].visivel = true;
        }
      }
      //Arteria Poplitea
      if (this.term.blockID === this.ArteriaPopliteaID) {
        if (+term === 3661 || +term === 3672 || +term === 3673) {
          this.Descricoes[8].visivel = false;
          this.Descricoes[9].visivel = false;
          this.Descricoes[10].visivel = false;
        }
        if (+term === 3620 || +term === 3621) {
          this.Descricoes[8].visivel = true;
          this.Descricoes[9].visivel = true;
          this.Descricoes[10].visivel = true;
        }
      }
      //Arteria Tibial Anterior
      if (this.term.blockID === this.ArteriaTibialAnteriorID) {
        if (+term === 3679 || +term === 3691) {
          this.Descricoes[8].visivel = false;
          this.Descricoes[9].visivel = false;
          this.Descricoes[10].visivel = false;
        }
        if (+term === 3676 || +term === 3677) {
          this.Descricoes[8].visivel = true;
          this.Descricoes[9].visivel = true;
          this.Descricoes[10].visivel = true;
        }
      }
      //Arteria Tibial Posterior
      if (this.term.blockID === this.ArteriaTibialPosteriorID) {
        if (+term === 3695 || +term === 3707) {
          this.Descricoes[8].visivel = false;
          this.Descricoes[9].visivel = false;
          this.Descricoes[10].visivel = false;
        }
        if (+term === 3692 || +term === 3693) {
          this.Descricoes[8].visivel = true;
          this.Descricoes[9].visivel = true;
          this.Descricoes[10].visivel = true;
        }
      }
      //Arteria Fibular
      if (this.term.blockID === this.ArteriaFibularID) {
        if (+term === 3711) {
          this.Descricoes[8].visivel = false;
          this.Descricoes[9].visivel = false;
          this.Descricoes[10].visivel = false;
        }
        if (+term === 3708 || +term === 3709) {
          this.Descricoes[8].visivel = true;
          this.Descricoes[9].visivel = true;
          this.Descricoes[10].visivel = true;
        }
      }
    });
  }

  public consultarAPI(): any {
    if (this.editLaudoMode) {
      let blocoSelecionado = this.blocos.filter(bloco => {
        return bloco.selected == true
      })
      this.dataService.setTermos(this.term.listTermID, blocoSelecionado[0].id)
      return;
    } else if (!this.editLaudoMode) {
      let desc;
      if (this.ArteriaFemoralComum) {
        this.term.blockID = this.ArteriaFemoralComumID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaFemoralComumID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoArteriaFemoralComum(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaFemoralComumID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaFemoralComumID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoArteriaFemoralComum(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ArteriaFemoralComumID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaFemoralComumID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.alteraDescricaoNormal();
          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ArteriaFemoralSuperficial) {
        this.term.blockID = this.ArteriaFemoralSuperficialID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaFemoralSuperficialID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoArteriaSuperficialFemoral(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaFemoralSuperficialID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaFemoralSuperficialID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoArteriaSuperficialFemoral(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ArteriaFemoralSuperficialID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaFemoralSuperficialID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.alteraDescricaoNormal();
          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ArteriaFemoralProfunda) {
        this.term.blockID = this.ArteriaFemoralProfundaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaFemoralProfundaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoArteriaFemoralProfunda(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaFemoralProfundaID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaFemoralProfundaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoArteriaFemoralProfunda(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ArteriaFemoralProfundaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaFemoralProfundaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.alteraDescricaoNormal();
          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ArteriaPoplitea) {
        this.term.blockID = this.ArteriaPopliteaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaPopliteaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoArteriaPoplitea(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaPopliteaID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaPopliteaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoArteriaPoplitea(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ArteriaPopliteaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaPopliteaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.alteraDescricaoNormal();
          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ArteriaTibialAnterior) {
        this.term.blockID = this.ArteriaTibialAnteriorID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaTibialAnteriorID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoArteriaTibialAnterior(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaTibialAnteriorID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaTibialAnteriorID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoArteriaTibialAnterior(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ArteriaTibialAnteriorID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaTibialAnteriorID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.alteraDescricaoNormal();
          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ArteriaTibialPosterior) {
        this.term.blockID = this.ArteriaTibialPosteriorID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaTibialPosteriorID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoArteriaTibialPosterior(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaTibialPosteriorID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaTibialPosteriorID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoArteriaTibialPosterior(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ArteriaTibialPosteriorID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaTibialPosteriorID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.alteraDescricaoNormal();
          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ArteriaFibular) {
        this.term.blockID = this.ArteriaFibularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaFibularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoArteriaFibular(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaFibularID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaFibularID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoArteriaFibular(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ArteriaFibularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaFibularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          this.alteraDescricaoNormal();
          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.Ateromatose) {
        this.term.blockID = this.AteromatoseId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.AteromatoseId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.AteromatoseId);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.AteromatoseId,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.AteromatoseId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.AteromatoseId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.achadosTexto) {
        this.term.blockID = this.achadosTextoID;


        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.achadosTextoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoAchadosAdicionais(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.achadosTextoID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              if (!this.newEditor) {
                this.AdicionaTexto(this.newEditor);
              } else {
                this.atualizaLinha({
                  blockId: this.term.blockID,
                  descricao: desc,
                  conclusao: '',
                  conclusaoNull: ''
                });

                let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaFemoralComumID);
                if (index > -1) {
                  this.Descricoes[index].value = obj.value;
                  this.Descricoes[index].textTermID = obj.textTermID;
                  desc = obj.value;
                }
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaFemoralComumID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoArteriaFemoralComum(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ArteriaFemoralComumID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaFemoralComumID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ArteriaFemoralSuperficial) {
        this.term.blockID = this.ArteriaFemoralSuperficialID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaFemoralSuperficialID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoArteriaSuperficialFemoral(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaFemoralSuperficialID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaFemoralSuperficialID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoArteriaSuperficialFemoral(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ArteriaFemoralSuperficialID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaFemoralSuperficialID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ArteriaFemoralProfunda) {
        this.term.blockID = this.ArteriaFemoralProfundaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaFemoralProfundaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoArteriaFemoralProfunda(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaFemoralProfundaID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaFemoralProfundaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoArteriaFemoralProfunda(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ArteriaFemoralProfundaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaFemoralProfundaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ArteriaPoplitea) {
        this.term.blockID = this.ArteriaPopliteaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaPopliteaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoArteriaPoplitea(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaPopliteaID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaPopliteaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoArteriaPoplitea(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ArteriaPopliteaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaPopliteaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ArteriaTibialAnterior) {
        this.term.blockID = this.ArteriaTibialAnteriorID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaTibialAnteriorID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoArteriaTibialAnterior(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaTibialAnteriorID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaTibialAnteriorID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoArteriaTibialAnterior(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ArteriaTibialAnteriorID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaTibialAnteriorID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ArteriaTibialPosterior) {
        this.term.blockID = this.ArteriaTibialPosteriorID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaTibialPosteriorID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoArteriaTibialPosterior(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaTibialPosteriorID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaTibialPosteriorID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoArteriaTibialPosterior(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ArteriaTibialPosteriorID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaTibialPosteriorID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ArteriaFibular) {
        this.term.blockID = this.ArteriaFibularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.ArteriaFibularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoArteriaFibular(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.ArteriaFibularID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ArteriaFibularID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoArteriaFibular(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ArteriaFibularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ArteriaFibularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.Ateromatose) {
        this.term.blockID = this.AteromatoseId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.AteromatoseId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.AteromatoseId);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.AteromatoseId,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.AteromatoseId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.AteromatoseId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.achadosTexto) {
        this.term.blockID = this.achadosTextoID;


        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.achadosTextoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoAchadosAdicionais(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.achadosTextoID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              if (!this.newEditor) {
                this.AdicionaTexto(this.newEditor);
              } else {
                this.atualizaLinha({
                  blockId: this.term.blockID,
                  descricao: desc,
                  conclusao: '',
                  conclusaoNull: ''
                });
              }
            }
          });
        });

      }
    }
  }

  public enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';
    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  public enviaLados($event) {
    this.Lado = $event;

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'lateralidade',
        lateralidade: this.Lado
      });
    }
  }

  public RetornaConclusaoNull(Texto): any {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  public auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj) {
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
    }
  }

  AdicionaTexto(isNewEditor: boolean) {
    if (isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  public adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-lateralidade`,
        'name': ''
      },
      'data': {
        'text': `<p>${maskSplit[0]} ${this.Lado.toUpperCase()}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.Descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value && this.Descricoes[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-${this.achadosTextoID}`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });
  }

  public adicionaTextoOldEditor(): any {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.Lado.toUpperCase();
    this.htmlContent += maskSplit[1];
    this.htmlContent += this.indicacaoTexto;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContent += maskSplit[2];

    for (i = 0; i < this.Descricoes.length; i++) {
      var newValue = this.Descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.Descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.Descricoes.length; i++) {
      if (this.Descricoes[i].value && i <= 10 && this.Descricoes[i].visivel) {
        this.htmlContent += '<br/>' + this.Descricoes[i].value + '<br/>';
      }
    }

    this.htmlContent += maskSplit[3];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }

    if (this.Descricoes[11].value) {
      this.htmlContent += "</br><b>Achados adicionais:</b></br>";
      this.htmlContent += this.Descricoes[11].value;
    }

    if (this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
    blockId,
    descricao,
    conclusao,
    conclusaoNull,
    indicacao,
    achados,
    cabecalho,
    rodape,
    lateralidade
  }: IAtualizaLinha): void {
    if (blockId !== undefined) {
      if (indicacao === undefined && achados === undefined) {
        if (descricao === undefined) {
          this.Descricoes.forEach(desc => {
            if (desc.blockID === blockId) {
              descricao = desc.value;
            }
          });
        }

        const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

        if (conclusao !== '' || conclusaoNull !== '') {
          if (conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                  ? conclusaoNull !== ''
                    ? conclusaoNull
                    : ''
                  : conclusao;
              }
            });
          }
        } else {
          if (conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                  ? conclusaoNull !== ''
                    ? conclusaoNull
                    : ''
                  : conclusao;
              }
            });
          }
        }

        if (description) {
          description.innerHTML = descricao;
        } else if (!description) {
          this.AdicionaTexto(true);
        } else if (!description && !conclusion) {
          this.AdicionaTexto(true);
        }
        if (cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if (header) {
            header.innerHTML = cabecalho;
          }
        }

        if (rodape !== undefined) {

          const footer = document.querySelector(`#e-${blockId}`);

          if (footer) {
            footer.innerHTML = rodape;
          }
        }

        if (lateralidade !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);
          let lado = `<div style="text-align: center;"><b>ULTRASSONOGRAFIA DOPPLER ARTERIAL DO MEMBRO INFERIOR ${lateralidade.toUpperCase()}</b></div>`

          if (footer) {
            footer.innerHTML = lado.toUpperCase();
          }
        }
      } else if (indicacao || achados) {
        if (indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if (recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
        } else if (achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if (findings) {
            findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
        }
      }
      this._addText.lineHeight();
    }
  }

  handleSwitchFormReport(formReport: string) {
    if (formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if (config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }
}
