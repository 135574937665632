import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-superficie-ossea',
  templateUrl: './superficie-ossea.component.html',
  styleUrls: ['./superficie-ossea.component.css']
})
export class SuperficieOsseaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdIrregularidades') rdIrregularidades: MatRadioButton;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;

  localizacoes: ArrGenerico[] = [
    { value: 'no rádio distal', viewValue: 'Rádio distal' },
    { value: 'na ulna distal', viewValue: 'Ulna distal' },
    { value: 'na primeira fileira carpal', viewValue: 'Primeira fileira carpal' },
    { value: 'na segunda fileira carpal', viewValue: 'Segunda fileira carpal' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahIrregularidades: true
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahIrregularidades = false;
    this.catchIDs();
  }

  clickIrregularidade() {
    this.showAndHideTerms.sahIrregularidades = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.slLocalizacao && this.slLocalizacao.value)){
      this.rdIrregularidades.checked = true;
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdIrregularidades.checked) {
      this.termoSelecionado.emit(this.rdIrregularidades.value);
      
      if (this.slLocalizacao && this.slLocalizacao.value) {
        const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
