import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {
  icon : string = '';

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ngOnInit() {
    const msgSplit = this.message.split('|');

    if(msgSplit.length > 1){
      this.icon = msgSplit[0];
      this.message = msgSplit[1];
    } else {
      this.message = msgSplit[0];
    }
  }

  clickOK(): void {
    this.dialogRef.close();
  }
}
