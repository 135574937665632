import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-partes-moles',
  templateUrl: './partes-moles.component.html',
  styleUrls: ['./partes-moles.component.css']
})
export class PartesMolesComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('ckAusenciaSombra') ckAusenciaSombra: MatCheckbox;
  @ViewChild('slLateralidade') slLateralidade: MatSelect;

  @ViewChild('ckCateter') ckCateter: MatCheckbox;
  @ViewChild('ckDreno') ckDreno: MatCheckbox;

  @ViewChild('ckTraqueostomia') ckTraqueostomia: MatCheckbox;
  @ViewChild('ckCanula') ckCanula: MatCheckbox;
  @ViewChild('slPosicionamento') slPosicionamento: MatSelect;
  @ViewChild('slLateralidadeDreno') slLateralidadeDreno: MatSelect;
  @ViewChild('ckEnfisema') ckEnfisema: MatCheckbox;
  @ViewChild('slLateralidadeEnfisema') slLateralidadeEnfisema: MatSelect;
  @ViewChild('ckEletrodos') ckEletrodos: MatCheckbox;
  @ViewChild('ckEsternorrafia') ckEsternorrafia: MatCheckbox;
  @ViewChild('ckSonda') ckSonda: MatCheckbox;

  lateralidade: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  posicionamento: ArrayDropDown[] = [
    { value: 'normoposicionada', viewValue: 'Normal' },
    { value: 'em posição alta', viewValue: 'Alto' },
    { value: 'em posição baixa', viewValue: 'Baixo' },
    { value: 'com intubação seletiva do brônquio principal direito', viewValue: 'Seletivo direito' },
    { value: 'com intubação seletiva do brônquio principal esquerdo', viewValue: 'Seletivo esquerdo' }
  ];

  showAndHideTerms: any;
  countLesoes = 0;

  posicaoFormGroup: FormGroup;
  posicaoSonda: any;
  
  constructor(private fb: FormBuilder) { }

  ngOnInit() {

    this.iniciaFormPosicao();

    this.posicaoSonda = [
      {id: 'normoposicionada', value: 'normoposicionada', name: 'Normoposicionada'},
      {id: 'extremidade', value: 'com extremidade distal na projeção da câmara gástrica', name: 'Com extremidade distal na projeção da câmara gástrica'},
      {id: 'dobrada', value: 'dobrada no esôfago', name: 'Dobrada no esôfago'}
    ]

    this.showAndHideTerms = {
      sahAusenciaSombra: false,
      sahDreno: false,
      sahCanula: false,
      sahEnisema: false,
      sahSonda: false,
    }
  }

  clickCheckboxPosicao(event) {
    let posicaoSonda = <FormArray>this.posicaoFormGroup.get('posicaoSonda') as FormArray;
    
    if (event.checked) {
      posicaoSonda.push(new FormControl(event.source.value));
    } else {
      let iEsq = posicaoSonda.controls.findIndex(x => x.value === event.source.value);
      
      posicaoSonda.removeAt(iEsq);
    }

    this.catchIDs();
  }

  clickDefault(){
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  clickAusenciaSombra(){
    this.showAndHideTerms.sahAusenciaSombra = !this.showAndHideTerms.sahAusenciaSombra;
    this.catchIDs();
  }

  clickDreno(){
    this.showAndHideTerms.sahDreno = !this.showAndHideTerms.sahDreno;
    this.catchIDs();
  }

  clickCanula(){
    this.showAndHideTerms.sahCanula = !this.showAndHideTerms.sahCanula;
    this.catchIDs();
  }
  clickEnfisema(){
    this.showAndHideTerms.sahEnfisema = !this.showAndHideTerms.sahEnfisema;
    this.catchIDs();
  }
  clickSonda(){
    this.showAndHideTerms.sahSonda = !this.showAndHideTerms.sahSonda;
    this.catchIDs();
  }

  checkPresente(){
    if(this.ckAusenciaSombra && !this.ckAusenciaSombra.checked &&
      this.ckCateter && !this.ckCateter.checked &&
      this.ckDreno && !this.ckDreno.checked &&
      this.ckTraqueostomia && !this.ckTraqueostomia.checked &&
      this.ckCanula && !this.ckCanula.checked &&
      this.ckEnfisema && !this.ckEnfisema.checked &&
      this.ckEletrodos && !this.ckEletrodos.checked &&
      this.ckEsternorrafia && !this.ckEsternorrafia.checked &&
      this.ckSonda && !this.ckSonda.checked){
        return true;
    }
  }

  iniciaFormPosicao(){
    this.posicaoFormGroup = this.fb.group({
      posicaoSonda: this.fb.array([])
    });
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if(!this.ckSonda.checked){
      this.iniciaFormPosicao();
    }

    this.emitAusenciaSombra();
    
    // Cateter
    if(this.countLesoes > 0){
      this.emitCateterLesao();
    } else {
      this.emitCateterSimples();
    }
    // Dreno
    if(this.countLesoes > 0){
      this.emitDrenoLesao();
    } else {
      this.emitDrenoSimples();
    }
    // Traqueostomia
    if(this.countLesoes > 0){
      this.emitTraqueostomiaLesao();
    } else {
      this.emitTraqueostomiaSimples();
    }
    // Canula
    if(this.countLesoes > 0){
      this.emitCanulaLesao();
    } else {
      this.emitCanulaSimples();
    }
    // Enfisema
    if(this.countLesoes > 0){
      this.emitEnfisemaLesao();
    } else {
      this.emitEnfisemaSimples();
    }
    // Eletrodos
    if(this.countLesoes > 0){
      this.emitEletrodosLesao();
    } else {
      this.emitEletrodosSimples();
    }
    // Esternorrafia
    if(this.countLesoes > 0){
      this.emitEsternorrafiaLesao();
    } else {
      this.emitEsternorrafiaSimples();
    }
    // Sonda
    if(this.countLesoes > 0){
      this.emitSondaLesao();
    } else {
      this.emitSondaSimples();
    }

    if(this.checkPresente()){
      this.termoSelecionado.emit(4906);
    }
    
    this.listaCompleta.emit();
  }

  emitAusenciaSombra(){
    if(this.ckAusenciaSombra.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAusenciaSombra.id);

      if(this.slLateralidade && this.slLateralidade.value){
        const objeto = {elemento: this.slLateralidade, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCateterLesao(){
    if(this.ckCateter.checked){
      this.countLesoes++;
      const objeto = { id: this.ckCateter.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitCateterSimples(){
    if(this.ckCateter.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCateter.id);
    }
  }

  emitDrenoLesao(){
    if(this.ckDreno.checked){
      this.countLesoes++;
      const objeto = { id: this.ckDreno.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if(this.slLateralidadeDreno && this.slLateralidadeDreno.value){
        const objeto = {elemento: this.slLateralidadeDreno, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitDrenoSimples(){
    if(this.ckDreno.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckDreno.id);
      if(this.slLateralidadeDreno && this.slLateralidadeDreno.value){
        const objeto = {elemento: this.slLateralidadeDreno, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitTraqueostomiaLesao(){
    if(this.ckTraqueostomia.checked){
      this.countLesoes++;
      const objeto = { id: this.ckTraqueostomia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitTraqueostomiaSimples(){
    if(this.ckTraqueostomia.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckTraqueostomia.id);
    }
  }

  emitCanulaLesao(){
    if(this.ckCanula.checked){
      this.countLesoes++;
      const objeto = { id: this.ckCanula.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if(this.slPosicionamento && this.slPosicionamento.value){
        const objeto = {elemento: this.slPosicionamento, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCanulaSimples(){
    if(this.ckCanula.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCanula.id);
      if(this.slPosicionamento && this.slPosicionamento.value){
        const objeto = {elemento: this.slPosicionamento, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEnfisemaLesao(){
    if(this.ckEnfisema.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEnfisema.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if(this.slLateralidadeEnfisema && this.slLateralidadeEnfisema.value){
        const objeto = {elemento: this.slLateralidadeEnfisema, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEnfisemaSimples(){
    if(this.ckEnfisema.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEnfisema.id);
      if(this.slLateralidadeEnfisema && this.slLateralidadeEnfisema.value){
        const objeto = {elemento: this.slLateralidadeEnfisema, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEletrodosLesao(){
    if(this.ckEletrodos.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEletrodos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitEletrodosSimples(){
    if(this.ckEletrodos.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEletrodos.id);
    }
  }

  emitEsternorrafiaLesao(){
    if(this.ckEsternorrafia.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEsternorrafia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitEsternorrafiaSimples(){
    if(this.ckEsternorrafia.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEsternorrafia.id);
    }
  }

  emitSondaLesao(){
    if(this.ckSonda.checked){
      this.countLesoes++;
      const objeto = { id: this.ckSonda.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let posicao = this.posicaoFormGroup.value.posicaoSonda;
      if(posicao.length > 0){
        let objeto = {id: 4850, value: posicao};
        let elemento = {elemento: objeto, isPrimeiraLesao: false};
        this.termosGenericos.emit(elemento);
      }
      
    }
  }

  emitSondaSimples(){
    if(this.ckSonda.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckSonda.id);

      let posicao = this.posicaoFormGroup.value.posicaoSonda;
      if(posicao.length > 0){
        let objeto = {id: 4850, value: posicao};
        let elemento = {elemento: objeto, isPrimeiraLesao: true};
        this.termosGenericos.emit(elemento);
      }
    }
  }




}
