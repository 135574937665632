import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatDialog, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-ventriculos',
  templateUrl: './ventriculos.component.html',
  styleUrls: ['./ventriculos.component.css']
})
export class VentriculosComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;
  @ViewChild('rdVariacao') rdVariacao: MatRadioButton;
  @ViewChild('rdEctasia') rdEctasia: MatRadioButton;
  @ViewChild('rdDilatacao') rdDilatacao: MatRadioButton;
  @ViewChild('rdHidrocefalia') rdHidrocefalia: MatRadioButton;
  @ViewChild('rdHiperdrenagem') rdHiperdrenagem: MatRadioButton;
  @ViewChild('rdHemo') rdHemo: MatRadioButton;
  @ViewChild('rdPneumo') rdPneumo: MatRadioButton;
  @ViewChild('rdCisto') rdCisto: MatRadioButton;
  @ViewChild('rdColpo') rdColpo: MatRadioButton;
  @ViewChild('rdEsquiz') rdEsquiz: MatRadioButton;

  @ViewChild('rdDilatSem') rdDilatSem: MatRadioButton;
  @ViewChild('rdDilatCom') rdDilatCom: MatRadioButton;
  @ViewChild('rdDilatDuv') rdDilatDuv: MatRadioButton;

  @ViewChild('ckAssimetria') ckAssimetria: MatCheckbox;
  @ViewChild('ckPersistencia') ckPersistencia: MatCheckbox;
  @ViewChild('ckDeterminando') ckDeterminando: MatCheckbox;
  @ViewChild('ckTrans') ckTrans: MatCheckbox;

  @ViewChild('slLateralidade') slLateralidade: MatSelect;
  @ViewChild('slCavum') slCavum: MatSelect;
  @ViewChild('slVentriculos') slVentriculos: MatSelect;
  @ViewChild('slGraus') slGraus: MatSelect;
  @ViewChild('slVentriHemos') slVentriHemos: MatSelect;
  @ViewChild('slGrausCisto') slGrausCisto: MatSelect;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slLado') slLado: MatSelect;

  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  @ViewChild('inMedida3') inMedida3: ElementRef;


  Lados: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];

  Cavums: ArrayDropDown[] = [
    { value: 'do cavum do septo pelúcido', viewValue: 'Do cavum do septo pelúcido' },
    { value: 'dos cavuns do septo pelúcido e vergae', viewValue: 'Dos cavuns do septo pelúcido e vergae' },
    { value: 'do cavum do velum interpositum', viewValue: 'Do cavum do velum interpositum' },
  ];

  Ventriculos: ArrayDropDown[] = [
    { value: 'ventricular supratentorial', viewValue: 'ventricular supratentorial' },
    { value: 'dos ventrículos laterais', viewValue: 'dos ventrículos laterais' },
    { value: 'ventricular supra e infratentorial', viewValue: 'ventricular supra e infratentorial' },
  ];

  VentriHemos: ArrayDropDown[] = [
    { value: 'nos cornos anteriores', viewValue: 'Cornos anteriores' },
    { value: 'no corno anterior direito', viewValue: 'Corno anterior direito' },
    { value: 'no corno anterior esquerdo', viewValue: 'Corno anterior esquerdo' },
    { value: 'nos cornos temporais', viewValue: 'nos cornos temporais' },
    { value: 'no corno temporal direito', viewValue: 'Corno temporal direito' },
    { value: 'no corno temporal esquerdo', viewValue: 'Corno temporal esquerdo' },
    { value: 'nos cornos occipitais', viewValue: 'nos cornos occipitais' },
    { value: 'no corno occipital direito', viewValue: 'Corno occipital direito' },
    { value: 'no corno occipital esquerdo', viewValue: 'Corno occipital esquerdo' },
    { value: 'no terceiro ventrículo', viewValue: 'Terceiro ventrículo' },
    { value: 'no IV ventrículo', viewValue: 'IV ventrículo' },
  ];


  Graus: ArrayDropDown[] = [
    { value: 'Leve', viewValue: 'Leve' },
    { value: 'Moderada', viewValue: 'Moderada' },
    { value: 'Acentuada', viewValue: 'Acentuada' },
  ];


  countLesoes = 0;
  showAndHideTerms: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahVariacao: false,
      sahAssimetria: false,
      sahPersistencia: false,
      sahDilatacao: false,
      sahDilatSel: false,
      sahHemoPneumo: false,
      sahCisto: false,
      sahEsquiz: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickDefault() {
    this.catchIDs();
  }

  clickVariacao() {
    this.showAndHideTerms.sahVariacao = true;
    this.showAndHideTerms.sahDilatacao = false;
    this.showAndHideTerms.sahHemoPneumo = false;
    this.showAndHideTerms.sahCisto = false;
    this.showAndHideTerms.sahEsquiz = false;
    this.showAndHideTerms.sahDilatSel = false;
    this.mudaAlterado();
    this.catchIDs();
  }

  clickAssimetria() {
    if (this.showAndHideTerms.sahAssimetria === false) {
      this.showAndHideTerms.sahAssimetria = true;
    } else {
      this.showAndHideTerms.sahAssimetria = false;
    }
    this.catchIDs();
  }

  clickPersistencia() {
    if (this.showAndHideTerms.sahPersistencia === false) {
      this.showAndHideTerms.sahPersistencia = true;
    } else {
      this.showAndHideTerms.sahPersistencia = false;
    }
    this.catchIDs();
  }

  clickEctasia() {
    this.showAndHideTerms.sahVariacao = false;
    this.showAndHideTerms.sahDilatacao = false;
    this.showAndHideTerms.sahHemoPneumo = false;
    this.showAndHideTerms.sahCisto = false;
    this.showAndHideTerms.sahEsquiz = false;
    this.showAndHideTerms.sahDilatSel = false;
    this.mudaAlterado();
    this.catchIDs();
  }

  clickDilatacao() {
    this.showAndHideTerms.sahDilatacao = true;
    this.showAndHideTerms.sahVariacao = false;
    this.showAndHideTerms.sahHemoPneumo = false;
    this.showAndHideTerms.sahCisto = false;
    this.showAndHideTerms.sahEsquiz = false;
    this.mudaAlterado();
    this.catchIDs();
  }
  clickDilatSem() {
    this.showAndHideTerms.sahDilatSel = true;
    this.catchIDs();
  }

  clickDilatCom() {
    this.showAndHideTerms.sahDilatSel = true;
    this.catchIDs();
  }
  clickDilatDuv() {
    this.showAndHideTerms.sahDilatSel = true;
    this.catchIDs();
  }

  clickHidrocefalia() {
    this.showAndHideTerms.sahVariacao = false;
    this.showAndHideTerms.sahDilatacao = false;
    this.showAndHideTerms.sahHemoPneumo = false;
    this.showAndHideTerms.sahCisto = false;
    this.showAndHideTerms.sahEsquiz = false;
    this.showAndHideTerms.sahDilatSel = false;
    this.mudaAlterado();
    this.catchIDs();
  }

  clickHiperdrenagem() {
    this.showAndHideTerms.sahVariacao = false;
    this.showAndHideTerms.sahDilatacao = false;
    this.showAndHideTerms.sahHemoPneumo = false;
    this.showAndHideTerms.sahCisto = false;
    this.showAndHideTerms.sahEsquiz = false;
    this.showAndHideTerms.sahDilatSel = false;
    this.mudaAlterado();
    this.catchIDs();
  }

  clickHemo() {
    this.showAndHideTerms.sahHemoPneumo = true;
    this.showAndHideTerms.sahVariacao = false;
    this.showAndHideTerms.sahDilatacao = false;
    this.showAndHideTerms.sahCisto = false;
    this.showAndHideTerms.sahEsquiz = false;
    this.showAndHideTerms.sahDilatSel = false;
    this.mudaAlterado();
    this.catchIDs();
  }

  clickPneumo() {
    this.showAndHideTerms.sahHemoPneumo = true;
    this.showAndHideTerms.sahVariacao = false;
    this.showAndHideTerms.sahDilatacao = false;
    this.showAndHideTerms.sahCisto = false;
    this.showAndHideTerms.sahEsquiz = false;
    this.showAndHideTerms.sahDilatSel = false;
    this.mudaAlterado();
    this.catchIDs();
  }

  clickCisto() {
    this.showAndHideTerms.sahCisto = true;
    this.showAndHideTerms.sahVariacao = false;
    this.showAndHideTerms.sahDilatacao = false;
    this.showAndHideTerms.sahHemoPneumo = false;
    this.showAndHideTerms.sahEsquiz = false;
    this.showAndHideTerms.sahDilatSel = false;
    this.mudaAlterado();
    this.catchIDs();
  }

  clickTrans() {
    this.catchIDs();
  }

  clickDeterminando() {
    this.catchIDs();
  }

  clickColpo() {
    this.showAndHideTerms.sahVariacao = false;
    this.showAndHideTerms.sahDilatacao = false;
    this.showAndHideTerms.sahHemoPneumo = false;
    this.showAndHideTerms.sahCisto = false;
    this.showAndHideTerms.sahEsquiz = false;
    this.showAndHideTerms.sahDilatSel = false;
    this.mudaAlterado();
    this.catchIDs();
  }

  clickEsquiz() {
    this.showAndHideTerms.sahEsquiz = true;
    this.showAndHideTerms.sahVariacao = false;
    this.showAndHideTerms.sahDilatacao = false;
    this.showAndHideTerms.sahHemoPneumo = false;
    this.showAndHideTerms.sahCisto = false;
    this.showAndHideTerms.sahDilatSel = false;
    this.mudaAlterado();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado && this.rdAlterado.checked) {
      //this.termoSelecionado.emit(this.rdAlterado.id);

      if (this.rdVariacao && this.rdVariacao.checked) {
        this.termoSelecionado.emit(this.rdVariacao.id);

        if (this.ckAssimetria && this.ckAssimetria.checked) {
          this.termoSelecionado.emit(this.ckAssimetria.id);
          if (this.slLateralidade && this.slLateralidade.value) {
            const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.ckPersistencia && this.ckPersistencia.checked) {
          this.termoSelecionado.emit(this.ckPersistencia.id);
          if (this.slCavum && this.slCavum.value) {
            const objeto = { elemento: this.slCavum, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }
      }

      if (this.rdEctasia && this.rdEctasia.checked) {
        this.termoSelecionado.emit(this.rdEctasia.id);
      }


      if (this.rdDilatacao && this.rdDilatacao.checked) {
        this.termoSelecionado.emit(this.rdDilatacao.id);

        if (this.rdDilatSem && this.rdDilatSem.checked) {
          this.termoSelecionado.emit(this.rdDilatSem.id);
        }
        if (this.rdDilatCom && this.rdDilatCom.checked) {
          this.termoSelecionado.emit(this.rdDilatCom.id);
        }
        if (this.rdDilatDuv && this.rdDilatDuv.checked) {
          this.termoSelecionado.emit(this.rdDilatDuv.id);
        }

        if (this.slVentriculos && this.slVentriculos.value) {
          const objeto = { elemento: this.slVentriculos, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
        if (this.slGraus && this.slGraus.value) {
          const objeto = { elemento: this.slGraus, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdHidrocefalia && this.rdHidrocefalia.checked) {
        this.termoSelecionado.emit(this.rdHidrocefalia.id);
      }

      if (this.rdHiperdrenagem && this.rdHiperdrenagem.checked) {
        this.termoSelecionado.emit(this.rdHiperdrenagem.id);
      }

      if (this.rdHemo && this.rdHemo.checked) {
        this.termoSelecionado.emit(this.rdHemo.id);

        if (this.slVentriHemos && this.slVentriHemos.value) {
          const objeto = { elemento: this.slVentriHemos, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdPneumo && this.rdPneumo.checked) {
        this.termoSelecionado.emit(this.rdPneumo.id);

        if (this.slVentriHemos && this.slVentriHemos.value) {
          const objeto = { elemento: this.slVentriHemos, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdCisto && this.rdCisto.checked) {
        this.termoSelecionado.emit(this.rdCisto.id);

        if (this.slGrausCisto && this.slGrausCisto.value) {
          const objeto = { elemento: this.slGrausCisto, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.ckDeterminando && this.ckDeterminando.checked) {
          this.termoSelecionado.emit(this.ckDeterminando.id);
        }
        if (this.ckTrans && this.ckTrans.checked) {
          this.termoSelecionado.emit(this.ckTrans.id);
        }

        this.formataMedindo(true);
      }

      if (this.rdColpo && this.rdColpo.checked) {
        this.termoSelecionado.emit(this.rdColpo.id);
      }


      if (this.rdEsquiz && this.rdEsquiz.checked) {
        this.termoSelecionado.emit(this.rdEsquiz.id);

        if (this.slLocalizacao && this.slLocalizacao.value) {
          const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLado && this.slLado.value) {
          const objeto = { elemento: this.slLado, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

    }

    this.listaCompleta.emit(300);
  }

  formataMedindo(isPrimeiraLesao) {
    let medidaFrase = '';
    let med1 = 0;
    let med2 = 0;
    let med3 = 0;

    if (this.inMedida1 !== undefined &&
      this.inMedida1.nativeElement.id !== undefined &&
      this.inMedida1.nativeElement.value !== '') {

      med1 = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);

      if (med1) {
        medidaFrase = med1.toString();
      }
    }

    if (this.inMedida2 != undefined &&
      this.inMedida2.nativeElement.id != undefined &&
      this.inMedida2.nativeElement.value != '') {

      med2 = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);

      if (medidaFrase != '') {
        medidaFrase = medidaFrase + ' x ' + med2;
      } else {
        medidaFrase = med2.toString();
      }
    }

    if (this.inMedida3 != undefined &&
      this.inMedida3.nativeElement.id != undefined &&
      this.inMedida3.nativeElement.value != '') {

      med3 = this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);

      if (medidaFrase != '') {
        medidaFrase = medidaFrase + ' x ' + med3;
      } else {
        medidaFrase = med3.toString();
      }
    }

    const objetoElemento = { id: this.inMedida1.nativeElement.id, value: medidaFrase, cenario: 0 };
    const objeto = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
    this.termosGenericos.emit(objeto);
  }


  mudaAlterado() {
    if ((this.rdVariacao && this.rdVariacao.checked) ||
      (this.rdEctasia && this.rdEctasia.checked) ||
      (this.rdDilatacao && this.rdDilatacao.checked) ||
      (this.rdHidrocefalia && this.rdHidrocefalia.checked) ||
      (this.rdHiperdrenagem && this.rdHiperdrenagem.checked) ||
      (this.rdHemo && this.rdHemo.checked) ||
      (this.rdPneumo && this.rdPneumo.checked) ||
      (this.rdCisto && this.rdCisto.checked) ||
      (this.rdColpo && this.rdColpo.checked) ||
      (this.rdEsquiz && this.rdEsquiz.checked)
    ) {
      this.rdAlterado.checked = true;
    } else {
      this.rdNormal.checked = true;
    }
  }

}
