import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatCardModule, MatCheckboxModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule, MatRadioModule, MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {HttpClientModule} from '@angular/common/http';
import {LayoutModule} from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TextEditorModule} from '../../../../utils/textEditor/text-editor/text-editor.module';
import { PunhoComponent } from './punho.component';
import { PeleTecidoComponent } from './TermosComponents/pele-tecido/pele-tecido.component';
import { SuperficieOsseaComponent } from './TermosComponents/superficie-ossea/superficie-ossea.component';
import { NervoMedianoComponent } from './TermosComponents/nervo-mediano/nervo-mediano.component';
import { TendoesExtensoresComponent } from './TermosComponents/tendoes-extensores/tendoes-extensores.component';
import { TendoesFlexoresComponent } from './TermosComponents/tendoes-flexores/tendoes-flexores.component';
import { DerrameArticularComponent } from './TermosComponents/derrame-articular/derrame-articular.component';
import { CistoArtroComponent } from './TermosComponents/cisto-artro/cisto-artro.component';
import { CanalGuyonComponent } from './TermosComponents/canal-guyon/canal-guyon.component';

import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LateralidadeModule } from 'src/app/utils/lateralidade/lateralidade.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';


@NgModule({
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    LateralidadeModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
    PunhoComponent, 
    PeleTecidoComponent,
    SuperficieOsseaComponent,
    NervoMedianoComponent,
    TendoesExtensoresComponent,
    TendoesFlexoresComponent,
    DerrameArticularComponent,
    CistoArtroComponent,
    CanalGuyonComponent
  ],
  declarations: [
    PunhoComponent,
    PeleTecidoComponent,
    SuperficieOsseaComponent,
    NervoMedianoComponent,
    TendoesExtensoresComponent,
    TendoesFlexoresComponent,
    DerrameArticularComponent,
    CistoArtroComponent,
    CanalGuyonComponent
  ]
})
export class PunhoModule { }
