import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-tendoes-flexores-cotovelo',
  templateUrl: './tendoes-flexores-cotovelo.component.html',
  styleUrls: ['./tendoes-flexores-cotovelo.component.css']
})
export class TendoesFlexoresCotoveloComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTendinopatia') rdTendinopatia: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickNormal() {
    this.catchIDs();
  }

  clickTendinopatia() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdTendinopatia.checked) {
      this.termoSelecionado.emit(this.rdTendinopatia.value);
    }

    this.listaCompleta.emit();
  }

}
