import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-veias-axilar',
  templateUrl: './veias-axilar.component.html',
  styleUrls: ['./veias-axilar.component.css']
})
export class VeiasAxilarComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdNaoCaracterizada') rdNaoCaracterizada: MatRadioButton;
  @ViewChild('rdAguda') rdAguda: MatRadioButton;
  @ViewChild('rdNaoRecanalizada') rdNaoRecanalizada: MatRadioButton;
  @ViewChild('rdRecanalizada') rdRecanalizada: MatRadioButton;

  @ViewChild('slMotivo') slMotivo: MatSelect;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slLocNaoRecanalizada') slLocNaoRecanalizada: MatSelect;
  @ViewChild('slLocRecanalizada') slLocRecanalizada: MatSelect;

  @ViewChild('slCalibre') slCalibre: MatSelect;
  @ViewChild('slCalibreRecanalizada') slCalibreRecanalizada: MatSelect;


  motivo: ArrayDropDown[] = [
    { value: 'pelo acentuado edema', viewValue: 'Edema' },
    { value: 'pela intensa atenuação do feixe acústico posterior', viewValue: 'Atenuação do feixe acústico' }
  ];

  localizacao: ArrayDropDown[] = [
    { value: 'no terço proximal', viewValue: 'Terço proximal' },
    { value: 'no terço médio', viewValue: 'Terço médio' },
    { value: 'no terço distal', viewValue: 'Terço distal' }
  ];
  calibre: ArrayDropDown[] = [
    { value: 'aumentado', viewValue: 'Aumentado' },
    { value: 'reduzido', viewValue: 'Reduzido' }
  ];

  constructor() { }

  ngOnInit() {
  }

  showNaoCaracterizada = false;
  showAguda = false;
  showNaoRecanalizada = false;
  showRecanalizada = false;

  clickNormal() {
    this.showNaoCaracterizada = false;
    this.showAguda = false;
    this.showNaoRecanalizada = false;
    this.showRecanalizada = false;
    this.catchIDs();
  }
  clickNaoCaracterizada() {
    this.showNaoCaracterizada = true;
    this.showAguda = false;
    this.showNaoRecanalizada = false;
    this.showRecanalizada = false;
    this.catchIDs();
  }

  clickAguda(){
    this.showNaoCaracterizada = false;
    this.showAguda = true;
    this.showNaoRecanalizada = false;
    this.showRecanalizada = false;
    this.catchIDs();
  }

  clickNaoRecanalizada(){
    this.showNaoCaracterizada = false;
    this.showAguda = false;
    this.showNaoRecanalizada = true;
    this.showRecanalizada = false;
    this.catchIDs();
  }
  clickRecanalizada(){
    this.showNaoCaracterizada = false;
    this.showAguda = false;
    this.showNaoRecanalizada = false;
    this.showRecanalizada = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurInput(){
    this.catchIDs();
  }


  catchIDs() {
      this.termoSelecionado.emit('Clean');

      if (this.rdNormal.checked) {
        this.termoSelecionado.emit(this.rdNormal.id);
      }

      if (this.rdNaoCaracterizada.checked) {
        this.termoSelecionado.emit(this.rdNaoCaracterizada.id);

        if (this.slMotivo && this.slMotivo.value){
          let objeto = { elemento: this.slMotivo, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdAguda.checked) {
        this.termoSelecionado.emit(this.rdAguda.id);
      }

      if (this.rdNaoRecanalizada.checked) {
        this.termoSelecionado.emit(this.rdNaoRecanalizada.id);

        if ((this.slCalibre && this.slCalibre.value)){

          let objeto = { elemento: this.slCalibre, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);

        }

      }

      if (this.rdRecanalizada.checked) {
        this.termoSelecionado.emit(this.rdRecanalizada.id);

        if ((this.slCalibreRecanalizada && this.slCalibreRecanalizada.value)){

          let objeto = { elemento: this.slCalibreRecanalizada, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }

    this.listaCompleta.emit();
    }

}
