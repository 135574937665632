import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MedicalReportService } from '../../services/medicalReport.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-detalhar-laudo',
  templateUrl: './detalhar-laudo.component.html',
  styleUrls: ['./detalhar-laudo.component.css']
})
export class DetalharLaudoComponent implements OnInit {
  recLaudo: any = {};
  htmlContent: string;
  exame: string;
  codigo: string;
  data: string;

  constructor(private router: Router,
    private _medical: MedicalReportService) { }

  ngOnInit() {
    this._medical.getLaudo()
      .pipe(take(1))
      .subscribe(laudo =>
        this.recLaudo = laudo
      );

    this.htmlContent = this.recLaudo.contentHtml;
    this.exame = this.recLaudo.title;
    this.codigo = this.recLaudo.reportCode;
    this.data = this.recLaudo.createdOn;
  }

  voltar(url: string) {
    this.router.navigateByUrl(url);
  }
}
