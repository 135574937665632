import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-linfonodomegalia-penis',
  templateUrl: './linfonodomegalia-penis.component.html',
  styleUrls: ['./linfonodomegalia-penis.component.css']
})
export class LinfonodomegaliaPenisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
