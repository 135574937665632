import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatButtonToggleModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatButtonModule,
  MatIconModule,
  MatDatepickerModule,
  MatToolbarModule,
  MatTableDataSource,
  MatTableModule,
  MatPaginatorModule,
  MatSidenavModule,
  MatSortModule
} from '@angular/material';
import { ListaLaudoComponent } from './lista-laudo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetalharLaudoComponent } from './detalhar-laudo/detalhar-laudo.component';
import { TextEditorModule } from '../utils/textEditor/text-editor/text-editor.module';
import { MenuLateralModule } from '../menu-lateral/menu-lateral.module';
import { MenuLateralComponent } from '../menu-lateral/menu-lateral.component';
import { ImprimirModule } from '../utils/imprimir/imprimir.module';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatTableModule,
    FilterPipeModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatCardModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    FlexLayoutModule,
    FormsModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    TextEditorModule,
    MenuLateralModule,
    MatSidenavModule,
    MatSortModule,
    ImprimirModule
  ],
  entryComponents:[
    MenuLateralComponent,
  ],
  declarations: [
    ListaLaudoComponent,
    DetalharLaudoComponent
  ],
  exports: [ListaLaudoComponent]
})
export class ListaLaudoModule { }
