import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ObstetricoRelacaoIRValoresService } from 'src/app/services/obstetrico-relacao-ir-valores.service';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-relacao-ir-obs',
  templateUrl: './relacao-ir-obs.component.html',
  styleUrls: ['./relacao-ir-obs.component.css']
})
export class RelacaoIrObsComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdRelacaoIR') rdRelacaoIR: MatRadioButton;
  @ViewChild('rdRelacaoIP') rdRelacaoIP: MatRadioButton;

  @ViewChild('ckInversao') ckInversao: MatCheckbox;

  @ViewChild('inIRAU') inIRAU: ElementRef;
  @ViewChild('inIRACM') inIRACM: ElementRef;
  @ViewChild('inIPAU') inIPAU: ElementRef;
  @ViewChild('inIPACM') inIPACM: ElementRef; 

  showAndHideTerms: any;
  phInAU: string;
  phInACM: string;
  placeIPACM = 'IP ACM';
  placeIPAU = 'IP AU';
  placeIRACM = 'IR ACM';
  placeIRAU = 'IR AU';

  constructor(private _validacoes: Validacoes, 
              private _valoresObstetricosIR: ObstetricoRelacaoIRValoresService) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCerebroPlac: false,
      sahUmbilicoCerebral: false,
      sahUmbilicoCerebralMedidas: false,
    };
  }

  clickNaoCitar() {
    this.showAndHideTerms.sahUmbilicoCerebralMedidas = false;
    this.catchIDs();
  }

  clickNormal() {
    this.showAndHideTerms.sahUmbilicoCerebralMedidas = false;
    this.catchIDs();
  }

  clickInversao() {
    this.showAndHideTerms.sahUmbilicoCerebralMedidas = true;

    /*
    //Para pensar no servico
    console.log('Valor this._valoresObstetricosIR.irACM - '+this._valoresObstetricosIR.irACM);
    if(this._valoresObstetricosIR.irACM != ''){
      this.placeIRACM = this._valoresObstetricosIR.irACM; 
    }
    else{
      this.placeIRACM = 'IR ACM';
    }
    if(this._valoresObstetricosIR.irAU != ''){
      console.log('Valor this._valoresObstetricosIR.irAU - '+this._valoresObstetricosIR.irAU);
      this.placeIRAU = this._valoresObstetricosIR.irAU; 
      
    }
    else{
      this.placeIRAU = 'IR AU';
    }
*/
    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  clickRelacaoIP() {
    if(this.showAndHideTerms.sahCerebroPlac == true){
      this.showAndHideTerms.sahCerebroPlac = false;
    }
    else{
      this.showAndHideTerms.sahCerebroPlac = true;
    }

    /*
    //Para pensar no servico
    if(this._valoresObstetricosIR.ipACM != ''){
      this.placeIPACM = this._valoresObstetricosIR.ipACM; 
    }
    else{
      this.placeIPACM = 'IP ACM';
    }
    if(this._valoresObstetricosIR.ipAU != ''){
      this.placeIPAU = this._valoresObstetricosIR.ipAU; 
    }
    else{
      this.placeIPAU = 'IP AU';
    }
    */
    this.catchIDs();
  }

  verificaPlaceholders(){
    if(this.inIPACM && this.inIPACM.nativeElement.value == '' && this.placeIPACM != 'IP ACM'){
      this.inIPACM.nativeElement.value = this._valoresObstetricosIR.ipACM;
      this.placeIPACM = 'IP ACM';
    }
         
    if(this.inIPAU && this.inIPAU.nativeElement.value == ''  && this.placeIPAU != 'IP AU'){
      this.inIPAU.nativeElement.value = this._valoresObstetricosIR.ipAU; 
      this.placeIPAU = 'IP AU';
    }
      
    if(this.inIRACM && this.inIRACM.nativeElement.value == '' && this.placeIRACM != 'IR ACM'){
      this.inIRACM.nativeElement.value = this._valoresObstetricosIR.irACM; 
      this.placeIRACM = 'IR ACM';
    }
      
    if(this.inIRAU && this.inIRAU.nativeElement.value == '' && this.placeIRAU != 'IR AU'){
      this.inIRAU.nativeElement.value = this._valoresObstetricosIR.irAU; 
      this.placeIRAU = 'IR AU';
    }
      
  }


  clickRelacaoIR() {
    if(this.showAndHideTerms.sahUmbilicoCerebral == true){
      this.showAndHideTerms.sahUmbilicoCerebral = false;
      this.showAndHideTerms.sahUmbilicoCerebralMedidas = false;
    }
    else{
      this.showAndHideTerms.sahUmbilicoCerebral = true;
    }
    this.catchIDs();
  }

  blurParametros() {
    this.catchIDs();
  }


  catchIDs() {
    let countLesao = 0;
    this.termoSelecionado.emit('Clean');
    //this.verificaPlaceholders();

    if (this.rdRelacaoIP.checked) {
      this.termoSelecionado.emit(this.rdRelacaoIP.id);
      countLesao++;
      if (this.inIPAU && this.inIPACM && this.inIPAU.nativeElement.value && this.inIPACM.nativeElement.value) {
        const calcIR = +this.inIPACM.nativeElement.value / +this.inIPAU.nativeElement.value;

        const elemento = { id: '2057', value: calcIR.toFixed(1) };
        const objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
        
      }
    }


    if (this.rdRelacaoIR.checked) {
      if(countLesao === 0){
        if (this.rdNormal && this.rdNormal.checked) {
          this.termoSelecionado.emit(this.rdNormal.id);
        } else {
          this.termoSelecionado.emit(this.rdRelacaoIR.id);
  
          this.termoSelecionado.emit(2056);//inversao
  
          if (this.inIRAU && this.inIRACM && this.inIRAU.nativeElement.value && this.inIRACM.nativeElement.value) {
            const calcIR = +this.inIRAU.nativeElement.value / +this.inIRACM.nativeElement.value;
  
            const elemento = { id: '2058', value: calcIR.toFixed(1) };
            const objeto = { elemento: elemento, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }
      } else { // envia como lesao
        if (this.rdNormal && this.rdNormal.checked) {
          const objeto = { id: this.rdNormal.id, cenario: countLesao };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          const objeto = { id: this.rdRelacaoIR.id, cenario: countLesao };
          this.lesoesSelecionadas.emit(objeto);

          const inversao = { id: "2056", cenario: countLesao };
          this.lesoesSelecionadas.emit(inversao);//inversao
  
          if (this.inIRAU && this.inIRACM && this.inIRAU.nativeElement.value && this.inIRACM.nativeElement.value) {
            const calcIR = +this.inIRAU.nativeElement.value / +this.inIRACM.nativeElement.value;
  
            const elemento = { id: '2058', value: calcIR.toFixed(1) };
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }
      }
      
    }
    this.listaCompleta.emit();
  }
}
