import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatExpansionModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatTableDataSource,
  MatDialogModule,
  MatTooltipModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatSlideToggleModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {HttpClientModule} from '@angular/common/http';
import {LayoutModule} from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TextEditorModule} from '../../../../utils/textEditor/text-editor/text-editor.module';
import { AbdomeDopplerComponent } from './abdome-doppler.component';
import { AbdomeTotalComponent } from '../../modo-b/abdome-total/abdome-total.component';
import { AbdomeTotalModule } from '../../modo-b/abdome-total/abdome-total.module';
import { ArteriaHepaticaComponent } from './TermosComponents/arteria-hepatica/arteria-hepatica.component';
import { ColateraisComponent } from './TermosComponents/colaterais/colaterais.component';
import { VeiaPortaDopplerComponent } from './TermosComponents/veia-porta-doppler/veia-porta-doppler.component';
import { VeiaEsplenicaComponent } from './TermosComponents/veia-esplenica/veia-esplenica.component';
import { VeiasHepaticasComponent } from './TermosComponents/veias-hepaticas/veias-hepaticas.component';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from '../../../../utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CommonModule,
    AbdomeTotalModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
    AbdomeDopplerComponent,
    ArteriaHepaticaComponent,
    ColateraisComponent,
    VeiaPortaDopplerComponent,
    VeiaEsplenicaComponent,
    VeiasHepaticasComponent
  ],
  declarations: [
    AbdomeDopplerComponent,
    ArteriaHepaticaComponent,
    ColateraisComponent,
    VeiaPortaDopplerComponent,
    VeiaEsplenicaComponent,
    VeiasHepaticasComponent,
  ],
  providers: [
    AbdomeTotalComponent
  ]
})
export class AbdomeDopplerModule { }
