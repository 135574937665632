import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterPipe } from 'ngx-filter-pipe';
import { TermoUsoDialogComponent } from 'src/app/utils/dialog/termo-uso-dialog/termo-uso-dialog.component';
import { MatDialog, MatPaginator, MatTableDataSource, PageEvent } from '@angular/material';
import { ListaPlanosService } from '../../../services/lista-planos.service';
import { Speciality } from '../../../models/BO/Speciality';
import { ListaPlanosFavoritosService } from 'src/app/services/lista-planos-favoritos.service';


@Component({
  selector: 'app-menu-us',
  templateUrl: './menu-us.component.html',
  styleUrls: ['./menu-us.component.css', '../../../app.component.css']
})

export class MenuUsComponent implements OnInit,AfterViewInit {
  nomeUsuario: string;
  showAndHide: any;
  showAndHideListView:boolean;
  temExames: boolean;

  displayedColumns: string[] = ['title','speciality','favorite','actions'];
  dataSource:any;

  todosExames:Speciality[] = [];
  examesModoB: Speciality[] = [];
  examesDoppler: Speciality[] = [];
  examesMusculo: Speciality[] = [];
  examesObstetrico: Speciality[] = [];

  todosExamesOriginal:Speciality[] = [];
  examesOriginalModoB: Speciality[] = [];
  examesOriginalDoppler: Speciality[] = [];
  examesOriginalMusculo: Speciality[] = [];
  examesOriginalObstetrico: Speciality[] = [];

  public loading: boolean = true;

  exameFilter: Speciality = {
    id: 0,
    description: '',
    parentMedicalReportTypeID: 0,
    specialityID: 0,
    title: '',
    image: '',
    url: '',
    visible: false,
    favorite: false
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit(){
      const interval =setInterval(()=>{
        if(this.todosExames.length>1){
          let sliceTodosExames = this.todosExames.slice(0,20);
          this.dataSource = sliceTodosExames;
          this.dataSource.paginator = this.paginator;
          clearInterval(interval);
        }
      },700);
  }

  constructor(private router: Router,
    private _dialog: MatDialog,
    private filter: FilterPipe,
    private _planoService: ListaPlanosService,
    private _favoritosService: ListaPlanosFavoritosService){
  }


  ngOnInit() {
    this.showAndHide = {
      sahModob: true,
      sahDoppler: true,
      sahMusculo: true,
      sahObstetrico: true,
    };
    const userID = parseInt(localStorage.getItem('userID'));

    this.checkViewDisplay();

    //Busca Favorito
    this._favoritosService.buscarFavsPorUsuario(userID).subscribe(
      favs => {
        // Busca exame
        const exames = JSON.parse(localStorage.getItem('exames'));
        if (exames && exames.length > 0) {
          exames.forEach(item => {
            item.forEach(exame => {
              favs.forEach(fav => {
                if (exame.url == fav.url) // Favoritos
                  exame.favorite = true;
              });

              if (exame.specialityID === 1){
                this.examesModoB.push(exame);
                this.todosExames.push(exame);
              } // Modo B
              else if (exame.specialityID === 2){
                this.examesDoppler.push(exame);
                this.todosExames.push(exame);
              } // Doppler

              else if (exame.specialityID === 3){
                this.examesMusculo.push(exame);
                this.todosExames.push(exame);
              } // Doppler

              else if (exame.specialityID === 4){
                this.examesObstetrico.push(exame);
                this.todosExames.push(exame);
              } // Obstetrico
            });

            // CASO NÃO TENHA US
            if (exames.length > 2)
              this.temExames = true;
            else
              this.temExames = false;
          });
          this.loading = false;
        } else {
          const userID = parseInt(localStorage.getItem('userID'));
          this._planoService.buscarPlanosPorUsuario(userID).subscribe(
            exames => {
              if (exames) {
                if (exames.length > 0)
                  localStorage.setItem('exames', JSON.stringify(exames));

                // CASO NÃO TENHA US
                if (exames.length > 2)
                  this.temExames = true;
                else
                  this.temExames = false;

                exames.forEach(item => {
                  item.forEach(exame => {
                    favs.forEach(fav => {
                      if (exame.url == fav.url) // Favoritos
                        exame.favorite = true;
                    });

                    if (exame.specialityID === 1){
                      this.examesModoB.push(exame);
                      this.todosExames.push(exame);
                    } // Modo B
                    else if (exame.specialityID === 2){
                      this.examesDoppler.push(exame);
                      this.todosExames.push(exame);
                    } // Doppler

                    else if (exame.specialityID === 3){
                      this.examesMusculo.push(exame);
                      this.todosExames.push(exame);
                    } // Musculo

                    else if (exame.specialityID === 4){
                      this.examesObstetrico.push(exame);
                      this.todosExames.push(exame);
                    } // Obstetrico
                  });
                });
              }
            },
            erro => {
              console.log(erro);
            }
          );
          this.loading = false;
        }
        this.todosExamesOriginal = this.todosExames;
        this.examesOriginalModoB = this.examesModoB;
        this.examesOriginalDoppler = this.examesDoppler;
        this.examesOriginalMusculo = this.examesMusculo;
        this.examesOriginalObstetrico = this.examesObstetrico;

        this.dataSource = new MatTableDataSource(this.todosExames);
        this.dataSource.paginator = this.paginator;
    });
  }

  goTo(where: string) {
    this.router.navigate([`/laudos/${where}`]);
  }

  goToNewTab(where: string){
    window.open(`/laudos/${where}`,'_blank','noopener')
  }

  goToUrl(where: string) {
    this.router.navigate([`${where}`]);
  }

  clickFiltro(event: string) {
    if (event === 'modob') {
      this.dataSource=this.examesModoB.slice(0,20);
      this.showAndHide.sahModob = true;
      this.showAndHide.sahDoppler = false;
      this.showAndHide.sahMusculo = false;
      this.showAndHide.sahObstetrico = false;
    } else if (event === 'doppler') {
      this.dataSource=this.examesDoppler;
      this.showAndHide.sahDoppler = true;
      this.showAndHide.sahModob = false;
      this.showAndHide.sahMusculo = false;
      this.showAndHide.sahObstetrico = false;
    } else if (event === 'musculo') {
      this.dataSource=this.examesMusculo;
      this.showAndHide.sahMusculo = true;
      this.showAndHide.sahDoppler = false;
      this.showAndHide.sahModob = false;
      this.showAndHide.sahObstetrico = false;
    } else if (event === 'obstetrico') {

      this.dataSource=this.examesObstetrico;
      this.showAndHide.sahObstetrico = true;
      this.showAndHide.sahMusculo = false;
      this.showAndHide.sahDoppler = false;
      this.showAndHide.sahModob = false;
    } else if (event === 'limpar') {
      this.dataSource = this.todosExames.slice(0,20);
      this.showAndHide.sahMusculo = true;
      this.showAndHide.sahDoppler = true;
      this.showAndHide.sahModob = true;
      this.showAndHide.sahObstetrico = true;
    }
  }

  filterPesquisa() {
    this.temExames = true;
    this.examesModoB = this.examesOriginalModoB;
    this.examesDoppler = this.examesOriginalDoppler;
    this.examesMusculo = this.examesOriginalMusculo;
    this.examesObstetrico = this.examesOriginalObstetrico;
    this.todosExames= this.todosExamesOriginal;

    if (this.exameFilter.title.length >= 0) {
      this.todosExames = this.todosExames.filter(x => x.title.normalize('NFD').replace(/[\u0300-\u936f]/g, '').toUpperCase().includes(
        this.exameFilter.title.normalize('NFD').replace(/[\u0300-\u936f]/g, '').toUpperCase()));
      this.dataSource = this.todosExames;
      this.dataSource.paginator= this.paginator;
      this.examesModoB = this.examesModoB.filter(x => x.title.normalize('NFD').replace(/[\u0300-\u936f]/g, '').toUpperCase().includes(
        this.exameFilter.title.normalize('NFD').replace(/[\u0300-\u936f]/g, '').toUpperCase()));
      this.examesDoppler = this.examesDoppler.filter(x => x.title.normalize('NFD').replace(/[\u0300-\u936f]/g, '').toUpperCase().includes(
        this.exameFilter.title.normalize('NFD').replace(/[\u0300-\u936f]/g, '').toUpperCase()));
      this.examesMusculo = this.examesMusculo.filter(x => x.title.normalize('NFD').replace(/[\u0300-\u936f]/g, '').toUpperCase().includes(
        this.exameFilter.title.normalize('NFD').replace(/[\u0300-\u936f]/g, '').toUpperCase()));
      this.examesObstetrico = this.examesObstetrico.filter(x => x.title.normalize('NFD').replace(/[\u0300-\u936f]/g, '').toUpperCase().includes(
        this.exameFilter.title.normalize('NFD').replace(/[\u0300-\u936f]/g, '').toUpperCase()));
    }
    if (this.exameFilter.title.length == 0){
      this.clickFiltro('limpar');
      this.dataSource = this.todosExames.slice(0,20);
      this.dataSource.paginator= this.paginator;
      if(this.paginator){
        this.paginator.firstPage();
      }
    }
  }
  loadDefaultTable(){
    this.dataSource = this.todosExames.slice(0,20);
    this.dataSource.paginator= this.paginator;
    this.paginator.firstPage();
  }
  changeFavorito(exame: Speciality) {
    const userId = localStorage.getItem('userID');
    if (this._favoritosService.isFavorito(exame)) {
      exame.favorite = false;
      this._favoritosService.apagarFavoritoUsuario(+userId, exame)
    } else {
      exame.favorite = true;
      this._favoritosService.insereFavoritoUsuario(+userId, exame)
    }
  }

  isFavorito(exame: Speciality): boolean {
    return this._favoritosService.isFavorito(exame);
  }

  checkViewDisplay(){
    const viewDisplay = localStorage.getItem('viewDisplay');
    if(viewDisplay=='true'){
      this.showAndHideListView = true;
    }else
      this.showAndHideListView = false;
  }

  changeViewDisplay(){
    this.showAndHideListView = !this.showAndHideListView;
    if(this.showAndHideListView){
      localStorage.setItem('viewDisplay','true');
    }else
      localStorage.setItem('viewDisplay','false');
  }

  getSpecialityClass(exame){
    if(exame.specialityID === 1){
      return 'modob-list';
    }
    if(exame.specialityID === 2){
      return 'doppler-list';
    }
    if(exame.specialityID === 3){
      return 'musculo-list';
    }
    if(exame.specialityID === 4){
      return 'obstetrico-list';
    }
  }

  getSpecialityName(exame){
    if(exame.specialityID === 1){
      return 'MODO B';
    }else if(exame.specialityID === 2){
      return 'DOPPLER';
    }else if(exame.specialityID === 3){
      return 'MÚSCULO';
    }else if(exame.specialityID === 4){
      return 'OBSTÉTRICO';
    }
  }

  onPageChange(event?: PageEvent){
    const startIndex = event.pageIndex *event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if(endIndex>this.todosExames.length){
      endIndex = this.todosExames.length;
    }
    this.dataSource = this.todosExames.slice(startIndex,endIndex);
  }

  onPageChangeModoB(event? : PageEvent){
    const startIndex = event.pageIndex *event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if(endIndex>this.examesModoB.length){
      endIndex = this.examesModoB.length
    }
    this.dataSource = this.examesModoB.slice(startIndex,endIndex);
  }
}
