import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Pagamento } from '../models/BO/Pagamento';
import { StatusPagamentoService } from './status-pagamento.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { PagamentoReturn } from '../models/BO/PagamentoReturn';
import { ListPayment } from '../models/BO/ListPayment';


@Injectable({
  providedIn: 'root'
})
export class PagamentoService {

  private pagamento$ = new BehaviorSubject<number>(0);

  emitPgto(status: number) {
    this.pagamento$.next(status);
  }

  getPagamento() {
    return this.pagamento$.asObservable();
  }

  constructor(private http: HttpClient,
    private _router: Router,
    private _dialog: MatDialog,
    private _staPgto: StatusPagamentoService) { }

  getCartaoCriptografado(numeroCartao: string): Observable<any> {
    return this.http.get<string>(environment.nLaudoApi + 'payment/CriptografaCartao/' + numeroCartao);
  }

  registrarPagamento(pagamento: any): Observable<PagamentoReturn> {
    const url = environment.nLaudoApi + 'payment/RegistrarPagamento';
    const pag: Pagamento = pagamento;

    const obj = {
      'UserID': pag.UserID,
      'CardHash': pag.CardHash,
      'CardName': pag.CardName,
      'ExpMonth': pag.ExpMonth,
      'ExpYear': pag.ExpYear,
      'CVV': pag.CVV,
      'Parcel': pag.Parcel,
      'AmountValue': pag.AmountValue,
      "CPF": pag.CPF,
      'ListPlanID': pag.ListPlanID,
      "Address": pag.AddressUser,
      "Voucher": pag.Voucher
    };

    return this.http.post<PagamentoReturn>(url, obj);
  }

  registrarTrial(pagamento: any): Observable<PagamentoReturn> {
    const url = environment.nLaudoApi + 'payment/RegistrarPamentoTrial';
    const _pagamento: Pagamento = pagamento;
    let cartaoHash;

    const obj = {
      'UserID': _pagamento.UserID,
      'CardHash': _pagamento.CardHash, // _pagamento.CardHash,
      'CardName': _pagamento.CardName,
      'ExpMonth': _pagamento.ExpMonth,
      'ExpYear': _pagamento.ExpYear,
      'CVV': _pagamento.CVV,
      'Parcel': _pagamento.Parcel,
      'AmountValue': _pagamento.AmountValue,
      "CPF": _pagamento.CPF,
      'ListPlanID': _pagamento.ListPlanID,
      "Address": _pagamento.AddressUser
    };

    return this.http.post<PagamentoReturn>(url, obj);

  }

  cancelarPlano(pagamento: Pagamento): Observable<Pagamento> {
    const url = environment.nLaudoApi + 'payment/CancelarSubscricao';
    const _pagamento: Pagamento = pagamento;

    const obj = {
      'UserID': _pagamento.UserID,
      'ListPlanID': _pagamento.ListPlanID
    };

    return this.http.post<Pagamento>(url, obj);
  }

  alterarStatus() {
    const num = Math.floor(Math.random() * 3) + 1;
    this._staPgto.emitStatusPgto(num);
  }

  enviarPgto(): Observable<any> {
    return this.http.get('app/teste.json');

  }

  pagamentosDoUsuario(userID: number) {
    const url = `${environment.nLaudoApi}Payment/ListaDePagamento/${userID}`;

    return this.http.get<ListPayment>(url);
  }

  AlteraPagamentoSafeBox(pagamento: any): Observable<PagamentoReturn> {
    const url = environment.nLaudoApi + 'payment/AlteraPagamentoSafeBox';
    const pag: Pagamento = pagamento;

    const obj = {
      'UserID': pag.UserID,
      'CardHash': pag.CardHash,
      'CardName': pag.CardName,
      'ExpMonth': pag.ExpMonth,
      'ExpYear': pag.ExpYear,
      'CVV': pag.CVV,
      "CPF": pag.CPF,
      "Address": pag.AddressUser
    };

    return this.http.post<PagamentoReturn>(url, obj);
  }

}
