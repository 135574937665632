import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material';
import { Injuries } from 'src/app/models/BO/Term';
import { TransvSegTercDopplerComponent } from '../../transv-seg-terc-doppler.component';

@Component({
  selector: 'app-doppler-velocimetria',
  templateUrl: './doppler-velocimetria.component.html',
  styleUrls: ['./doppler-velocimetria.component.css']
})
export class DopplerVelocimetriaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @Output() showBlock = new EventEmitter();

  @ViewChild('ckArteriasUmbilicais') ckArteriasUmbilicais: MatCheckbox;
  @ViewChild('ckArteriasCerebrais') ckArteriasCerebrais: MatCheckbox;
  @ViewChild('ckDuctoVenoso') ckDuctoVenoso: MatCheckbox;
  @ViewChild('ckRelacaoCerebro') ckRelacaoCerebro: MatCheckbox;

  showAndHideBlocks: any;

  constructor(private transv: TransvSegTercDopplerComponent) { }

  ngOnInit() {
    this.showAndHideBlocks = {
      sahArtUmb: false,
      sahArtCer: false,
      sahDuctoVen: false,
      sahRelCer: false,
    };
  }

  clickArteriasUmbilicais() {
    if (this.ckArteriasUmbilicais.checked) {
      this.showAndHideBlocks.sahArtUmb = true;
      this.transv.showArtUmbilical = true;
    } else {
      this.showAndHideBlocks.sahArtUmb = false;
    }
    this.consultarAPI();
  }

  clickArteriasCerebrais() {
    if (this.ckArteriasCerebrais.checked) {
      this.showAndHideBlocks.sahArtCer = true;
      this.transv.showArtCerebral = true;
    } else {
      this.showAndHideBlocks.sahArtCer = false;
    }
    this.consultarAPI();
  }

  clickDuctoVenoso() {
    if (this.ckDuctoVenoso.checked) {
      this.showAndHideBlocks.sahDuctoVen = true;
      this.transv.showDuctoVen = true;
    } else {
      this.showAndHideBlocks.sahDuctoVen = false;
    }
    this.consultarAPI();
  }

  clickRelacaoCerebro() {
    if (this.ckRelacaoCerebro.checked) {
      this.showAndHideBlocks.sahRelCer = true;
      this.transv.showRelacaoIR = true;
    } else {
      this.showAndHideBlocks.sahRelCer = false;
    }
    this.consultarAPI();
  }

  enviaLesoes($event) {
    if (this.transv.cenarioCorrente == 0) {
      this.transv.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.transv.cenarioCorrente) {
      //Adiciona ao objeto
      this.transv.injuries.listInjuries.push($event.id);

    } else {
      //adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.transv.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.transv.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.transv.injuries.listInjuries = new Array<number>();
      this.transv.injuries.listInjuries.push($event.id);

      this.transv.cenarioCorrente = $event.cenario;
    }
  }

  enviaTermos($event) {
    this.transv.enviaTermos($event);
  }

  adicionaSelecaoArteriasUmbilicais($event) {
    this.transv.adicionaSelecaoArtUmbilical($event);
  }

  adicionaSelecaoArteriasCerebrais($event) {
    this.transv.adicionaSelecaoArtCerebral($event);
  }

  adicionaSelecaoDuctoVenoso($event) {
    this.transv.adicionaSelecaoDuctoVen($event);
  }

  adicionaSelecaoRelacaoCerebro($event) {
    this.transv.adicionaSelecaoRelacaoIR($event);
  }

  consultarAPI(block?) {
    if (block == 'arteriasUmbilicais') {
      this.transv.showArtUmbilical = true;
      this.transv.showArtCerebral = false;
      this.transv.showDuctoVen = false;
      this.transv.showRelacaoIR = false;
    } else if (block == 'arteriasCerebrais') {
      this.transv.showArtUmbilical = false;
      this.transv.showArtCerebral = true;
      this.transv.showDuctoVen = false;
      this.transv.showRelacaoIR = false;
    } else if (block == 'ductoVenoso') {
      this.transv.showArtUmbilical = false;
      this.transv.showArtCerebral = false;
      this.transv.showDuctoVen = true;
      this.transv.showRelacaoIR = false;
    } else if (block == 'relacaoCerebro') {
      this.transv.showArtUmbilical = false;
      this.transv.showArtCerebral = false;
      this.transv.showDuctoVen = false;
      this.transv.showRelacaoIR = true;
    }
    this.transv.consultarAPI();
  }
}