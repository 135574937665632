import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-svg-esquerdo',
  templateUrl: './svg-esquerdo.component.html',
  styleUrls: ['./svg-esquerdo.component.css']
})
export class SvgEsquerdoComponent implements OnInit {
  @Output() segmentoSelecionado = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  selecionar(clickedID) {
    const clicado = document.getElementById(clickedID);
    clicado.classList.toggle('selecionado');

    this.segmentoSelecionado.emit(clickedID);
  }
}
