import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-pos-cirurgico',
  templateUrl: './pos-cirurgico.component.html',
  styleUrls: ['./pos-cirurgico.component.css']
})
export class PosCirurgicoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('slLateralidadeArtrodeseSoldura') slLateralidadeArtrodeseSoldura: MatSelect;
  @ViewChild('slNivelArtrodeseSoldura') slNivelArtrodeseSoldura: MatSelect;

  @ViewChild('slLateralidadeArtrodeseParafuso') slLateralidadeArtrodeseParafuso: MatSelect;
  @ViewChild('slNivelArtrodeseParafuso') slNivelArtrodeseParafuso: MatSelect;

  @ViewChild('slLateralidadeArtrodeseHaste') slLateralidadeArtrodeseHaste: MatSelect;
  @ViewChild('slNivelArtrodeseHaste') slNivelArtrodeseHaste: MatSelect;

  @ViewChild('slNivelEspacadorDiscal') slNivelEspacadorDiscal: MatSelect;

  @ViewChild('slNivelEspacadorDiscalAncora') slNivelEspacadorDiscalAncora: MatSelect;

  @ViewChild('slNivelProteseDiscal') slNivelProteseDiscal: MatSelect;

  @ViewChild('ckArtrodese') ckArtrodese: MatCheckbox;
  @ViewChild('ckArtrodeseSoldura') ckArtrodeseSoldura: MatCheckbox;
  @ViewChild('ckArtrodeseFraturaParafuso') ckArtrodeseFraturaParafuso: MatCheckbox;
  @ViewChild('ckArtrodeseFraturaHaste') ckArtrodeseFraturaHaste: MatCheckbox;
  @ViewChild('ckEspacadorDiscal') ckEspacadorDiscal: MatCheckbox;
  @ViewChild('ckEspacadorDiscalAncora') ckEspacadorDiscalAncora: MatCheckbox;
  @ViewChild('ckProteseDiscal') ckProteseDiscal: MatCheckbox;

  @ViewChild('ckFraturaParafuso') ckFraturaParafuso: MatCheckbox;
  @ViewChild('ckFraturaHaste') ckFraturaHaste: MatCheckbox;

  nivelArtrodeseSoldura: ArrayDropDown[]=[
    { value: 'T1-T2', viewValue: 'T1-T2' },
    { value: 'T2-T3', viewValue: 'T2-T3' },
    { value: 'T3-T4', viewValue: 'T3-T4' },
    { value: 'T4-T5', viewValue: 'T4-T5' },
    { value: 'T5-T6', viewValue: 'T5-T6' },
    { value: 'T6-T7', viewValue: 'T6-T7' },
    { value: 'T7-T8', viewValue: 'T7-T8' },
    { value: 'T8-T9', viewValue: 'T8-T9' },
    { value: 'T9-T10', viewValue: 'T9-T10' },
    { value: 'T10-T11', viewValue: 'T10-T11'},
    { value: 'T11-T12', viewValue: 'T11-T12'},
    { value: 'T12-L1', viewValue: 'T12-L1' }
  ]
  lateralidadeArtrodeseSoldura: ArrayDropDown[]=[
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ]
  nivelArtrodeseParafuso: ArrayDropDown[]=[
    { value: 'T1-T2', viewValue: 'T1-T2' },
    { value: 'T2-T3', viewValue: 'T2-T3' },
    { value: 'T3-T4', viewValue: 'T3-T4' },
    { value: 'T4-T5', viewValue: 'T4-T5' },
    { value: 'T5-T6', viewValue: 'T5-T6' },
    { value: 'T6-T7', viewValue: 'T6-T7' },
    { value: 'T7-T8', viewValue: 'T7-T8' },
    { value: 'T8-T9', viewValue: 'T8-T9' },
    { value: 'T9-T10', viewValue: 'T9-T10' },
    { value: 'T10-T11', viewValue: 'T10-T11'},
    { value: 'T11-T12', viewValue: 'T11-T12'},
    { value: 'T12-L1', viewValue: 'T12-L1' }
  ]

  lateralidadeArtrodeseParafuso: ArrayDropDown[]=[
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ]
  nivelArtrodeseHaste: ArrayDropDown[]=[
    { value: 'T1-T2', viewValue: 'T1-T2' },
    { value: 'T2-T3', viewValue: 'T2-T3' },
    { value: 'T3-T4', viewValue: 'T3-T4' },
    { value: 'T4-T5', viewValue: 'T4-T5' },
    { value: 'T5-T6', viewValue: 'T5-T6' },
    { value: 'T6-T7', viewValue: 'T6-T7' },
    { value: 'T7-T8', viewValue: 'T7-T8' },
    { value: 'T8-T9', viewValue: 'T8-T9' },
    { value: 'T9-T10', viewValue: 'T9-T10' },
    { value: 'T10-T11', viewValue: 'T10-T11'},
    { value: 'T11-T12', viewValue: 'T11-T12'},
    { value: 'T12-L1', viewValue: 'T12-L1' }
  ]

  lateralidadeArtrodeseHaste: ArrayDropDown[]=[
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ]

  nivelEspacadorDiscal: ArrayDropDown[]=[
    { value: 'T1-T2', viewValue: 'T1-T2' },
    { value: 'T2-T3', viewValue: 'T2-T3' },
    { value: 'T3-T4', viewValue: 'T3-T4' },
    { value: 'T4-T5', viewValue: 'T4-T5' },
    { value: 'T5-T6', viewValue: 'T5-T6' },
    { value: 'T6-T7', viewValue: 'T6-T7' },
    { value: 'T7-T8', viewValue: 'T7-T8' },
    { value: 'T8-T9', viewValue: 'T8-T9' },
    { value: 'T9-T10', viewValue: 'T9-T10' },
    { value: 'T10-T11', viewValue: 'T10-T11'},
    { value: 'T11-T12', viewValue: 'T11-T12'},
    { value: 'T12-L1', viewValue: 'T12-L1' }
  ]

  nivelEspacadorDiscalAncora: ArrayDropDown[]=[
    { value: 'T1-T2', viewValue: 'T1-T2' },
    { value: 'T2-T3', viewValue: 'T2-T3' },
    { value: 'T3-T4', viewValue: 'T3-T4' },
    { value: 'T4-T5', viewValue: 'T4-T5' },
    { value: 'T5-T6', viewValue: 'T5-T6' },
    { value: 'T6-T7', viewValue: 'T6-T7' },
    { value: 'T7-T8', viewValue: 'T7-T8' },
    { value: 'T8-T9', viewValue: 'T8-T9' },
    { value: 'T9-T10', viewValue: 'T9-T10' },
    { value: 'T10-T11', viewValue: 'T10-T11'},
    { value: 'T11-T12', viewValue: 'T11-T12'},
    { value: 'T12-L1', viewValue: 'T12-L1' }
  ]

  nivelProteseDiscal: ArrayDropDown[]=[
    { value: 'T1-T2', viewValue: 'T1-T2' },
    { value: 'T2-T3', viewValue: 'T2-T3' },
    { value: 'T3-T4', viewValue: 'T3-T4' },
    { value: 'T4-T5', viewValue: 'T4-T5' },
    { value: 'T5-T6', viewValue: 'T5-T6' },
    { value: 'T6-T7', viewValue: 'T6-T7' },
    { value: 'T7-T8', viewValue: 'T7-T8' },
    { value: 'T8-T9', viewValue: 'T8-T9' },
    { value: 'T9-T10', viewValue: 'T9-T10' },
    { value: 'T10-T11', viewValue: 'T10-T11'},
    { value: 'T11-T12', viewValue: 'T11-T12'},
    { value: 'T12-L1', viewValue: 'T12-L1' }
  ]

  countLesoes;
  showAndHideTerms;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahArtrodeseSoldura: false,
      sahArtrodeseFraturaParafuso:false,
      sahArtrodeseFraturaHaste:false,
      sahEspacadorDiscal:false,
      sahEspacadorDiscalAncora:false,
      sahProteseDiscal:false
    }
  }
  clickArtrodese(){
    this.showAndHideTerms.sahArtrodeseSoldura = !this.showAndHideTerms.sahArtrodeseSoldura;
    this.catchIDs();
  }

  clickArtrodeseParafuso(){
    this.showAndHideTerms.sahArtrodeseFraturaParafuso = !this.showAndHideTerms.sahArtrodeseFraturaParafuso;
    this.catchIDs();
  }

  clickArtrodeseHaste(){
    this.showAndHideTerms.sahArtrodeseFraturaHaste = !this.showAndHideTerms.sahArtrodeseFraturaHaste;
    this.catchIDs();
  }

  clickEspacadorDiscal(){
    this.showAndHideTerms.sahEspacadorDiscal = !this.showAndHideTerms.sahEspacadorDiscal;
    this.catchIDs();
  }
  clickEspacadorDiscalAncora(){
    this.showAndHideTerms.sahEspacadorDiscalAncora = !this.showAndHideTerms.sahEspacadorDiscalAncora;
    this.catchIDs();
  }
  clickProteseDiscal(){
    this.showAndHideTerms.sahProteseDiscal = !this.showAndHideTerms.sahProteseDiscal;
    this.catchIDs();
  }

  clickDefault(){
    this.catchIDs();
  }
  changeSelect(){
    this.catchIDs();
  }
  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if(this.countLesoes > 0){
      this.emitArtrodeseLesao();
    } else {
      this.emitArtrodeseSimples();
    }

    if(this.countLesoes > 0){
      this.emitArtrodeseSolduraLesao();
    } else {
      this.emitArtrodeseSolduraSimples();
    }

    if(this.countLesoes > 0){
      this.emitArtrodeseFraturaParafusoLesao();
    } else {
      this.emitArtrodeseFraturaParafusoSimples();
    }

    if(this.countLesoes > 0){
      this.emitArtrodeseFraturaHasteLesao();
    } else {
      this.emitArtrodeseFraturaHasteSimples();
    }

    if(this.countLesoes > 0){
      this.emitEspacadorDiscalLesao();
    } else {
      this.emitEspacadorDiscalSimples();
    }

    if(this.countLesoes > 0){
      this.emitEspacadorDiscalAncoraLesao();
    } else {
      this.emitEspacadorDiscalAncoraSimples();
    }

    if(this.countLesoes > 0){
      this.emitProteseLesao();
    } else {
      this.emitProteseSimples();
    }

    if( !this.ckArtrodese.checked && !this.ckArtrodeseSoldura.checked &&
      !this.ckArtrodeseFraturaParafuso.checked && !this.ckProteseDiscal.checked &&
      !this.ckArtrodeseFraturaHaste.checked && !this.ckEspacadorDiscal.checked && !this.ckEspacadorDiscalAncora.checked){
        this.termoSelecionado.emit(5094);
      }

    this.listaCompleta.emit();
  }

  emitProteseLesao(){
    if(this.ckProteseDiscal.checked){
      this.countLesoes++;
      const objeto = { id: this.ckProteseDiscal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slNivelProteseDiscal && this.slNivelProteseDiscal.value){
        const objeto = {elemento: this.slNivelProteseDiscal, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitProteseSimples(){
    if(this.ckProteseDiscal.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckProteseDiscal.id);

      if(this.slNivelProteseDiscal && this.slNivelProteseDiscal.value){
        const objeto = {elemento: this.slNivelProteseDiscal, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEspacadorDiscalAncoraLesao(){
    if(this.ckEspacadorDiscalAncora.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEspacadorDiscalAncora.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slNivelEspacadorDiscalAncora && this.slNivelEspacadorDiscalAncora.value){
        const objeto = {elemento: this.slNivelEspacadorDiscalAncora, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEspacadorDiscalAncoraSimples(){
    if(this.ckEspacadorDiscalAncora.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEspacadorDiscalAncora.id);

      if(this.slNivelEspacadorDiscalAncora && this.slNivelEspacadorDiscalAncora.value){
        const objeto = {elemento: this.slNivelEspacadorDiscalAncora, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEspacadorDiscalLesao() {
    if(this.ckEspacadorDiscal.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEspacadorDiscal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slNivelEspacadorDiscal && this.slNivelEspacadorDiscal.value){
        const objeto = {elemento: this.slNivelEspacadorDiscal, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEspacadorDiscalSimples() {
    if(this.ckEspacadorDiscal.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEspacadorDiscal.id);

      if(this.slNivelEspacadorDiscal && this.slNivelEspacadorDiscal.value){
        const objeto = {elemento: this.slNivelEspacadorDiscal, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitArtrodeseFraturaHasteSimples() {
    if(this.ckArtrodeseFraturaHaste.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckArtrodeseFraturaHaste.id);

      if(this.slLateralidadeArtrodeseHaste && this.slLateralidadeArtrodeseHaste.value){
        const objeto = {elemento: this.slLateralidadeArtrodeseHaste, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
        }
      if(this.slNivelArtrodeseHaste && this.slNivelArtrodeseHaste.value){
        const objeto = {elemento: this.slNivelArtrodeseHaste, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
      let obj = {id: 5111, value: 5111};
      let elemento = {elemento: obj, isPrimeiraLesao: true};
      this.termosGenericos.emit(elemento);
    }
  }

  emitArtrodeseFraturaHasteLesao() {
    if(this.ckArtrodeseFraturaHaste.checked){
      this.countLesoes++;
      const objeto = { id: this.ckArtrodeseFraturaHaste.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLateralidadeArtrodeseHaste && this.slLateralidadeArtrodeseHaste.value){
        const objeto = {elemento: this.slLateralidadeArtrodeseHaste, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelArtrodeseHaste && this.slNivelArtrodeseHaste.value){
        const objeto = {elemento: this.slNivelArtrodeseHaste, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }

      let obj = {id: 5111, value: 5111};
      let elemento = {elemento: obj, isPrimeiraLesao: false};
      this.termosGenericos.emit(elemento);
    }
  }

  emitArtrodeseFraturaParafusoSimples() {
    if(this.ckArtrodeseFraturaParafuso.checked){
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckArtrodeseFraturaParafuso.id);

    if(this.slLateralidadeArtrodeseParafuso && this.slLateralidadeArtrodeseParafuso.value){
      const objeto = {elemento: this.slLateralidadeArtrodeseParafuso, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
      }
      if(this.slNivelArtrodeseParafuso && this.slNivelArtrodeseParafuso.value){
        const objeto = {elemento: this.slNivelArtrodeseParafuso, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
      let obj = {id: 5107, value: 5107};
      let elemento = {elemento: obj, isPrimeiraLesao: true};
      this.termosGenericos.emit(elemento);
    }
  }

  emitArtrodeseFraturaParafusoLesao() {
    if(this.ckArtrodeseFraturaParafuso.checked){
      this.countLesoes++;
      const objeto = { id: this.ckArtrodeseFraturaParafuso.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLateralidadeArtrodeseParafuso && this.slLateralidadeArtrodeseParafuso.value){
        const objeto = {elemento: this.slLateralidadeArtrodeseParafuso, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelArtrodeseParafuso && this.slNivelArtrodeseParafuso.value){
        const objeto = {elemento: this.slNivelArtrodeseParafuso, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
      let obj = {id: 5107, value: 5107};
      let elemento = {elemento: obj, isPrimeiraLesao: false};
      this.termosGenericos.emit(elemento);
    }
  }

  emitArtrodeseSolduraSimples() {
    if(this.ckArtrodeseSoldura.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckArtrodeseSoldura.id);

      if(this.slLateralidadeArtrodeseSoldura && this.slLateralidadeArtrodeseSoldura.value){
        const objeto = {elemento: this.slLateralidadeArtrodeseSoldura, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelArtrodeseSoldura && this.slNivelArtrodeseSoldura.value){
        const objeto = {elemento: this.slNivelArtrodeseSoldura, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitArtrodeseSolduraLesao() {
    if(this.ckArtrodeseSoldura.checked){
      this.countLesoes++;
      const objeto = { id: this.ckArtrodeseSoldura.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLateralidadeArtrodeseSoldura && this.slLateralidadeArtrodeseSoldura.value){
        const objeto = {elemento: this.slLateralidadeArtrodeseSoldura, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelArtrodeseSoldura && this.slNivelArtrodeseSoldura.value){
        const objeto = {elemento: this.slNivelArtrodeseSoldura, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitArtrodeseLesao() {
    if(this.ckArtrodese.checked){
      this.countLesoes++;
      const objeto = { id: this.ckArtrodese.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitArtrodeseSimples() {
    if(this.ckArtrodese.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckArtrodese.id);
    }
  }
}

