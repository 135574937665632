import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-dispositivos',
  templateUrl: './dispositivos.component.html',
  styleUrls: ['./dispositivos.component.css']
})
export class DispositivosComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdTraqueostomia') rdTraqueostomia: MatRadioButton;
  @ViewChild('rdTuboEndotraqueal') rdTuboEndotraqueal: MatRadioButton;
  @ViewChild('rdNenhum') rdNenhum: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickDefault(){
    this.rdNenhum.checked=false;
    this.catchIDs();
  }

  clickNenhum(){
    this.rdNenhum.checked=true;
    this.rdTraqueostomia.checked=false;
    this.rdTuboEndotraqueal.checked=false;
    this.catchIDs();
  }

  catchIDs(){
    this.termoSelecionado.emit('Clean');

    if(this.rdTuboEndotraqueal.checked){
      this.termoSelecionado.emit(this.rdTuboEndotraqueal.id);
    }

    if(this.rdTraqueostomia.checked){
      this.termoSelecionado.emit(this.rdTraqueostomia.id);
    }

    if(this.rdNenhum.checked){
      this.termoSelecionado.emit(this.rdNenhum.id);
    }

    this.listaCompleta.emit();
  }
}
