import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../auth/user';
import { environment } from '../../environments/environment';
import { ProtocoloResult } from '../models/BO/ProtocoloResult';
import { Suporte } from '../models/BO/Suporte';

@Injectable({
    providedIn: 'root'
})

export class UserService {
    constructor(private _http: HttpClient, private _user: User) { }
    gravarUsuario(usuario: User) {
        let _url = environment.nLaudoApi + 'User/CadastraUsuario';
        let profissaoText;
        if (usuario.Clinica) {
            profissaoText = usuario.Clinica;
        } else if (usuario.ProfissaoText) {
            profissaoText = usuario.ProfissaoText;
        }
        
        let objPost = {
            "Name": usuario.Name,
            "Email": usuario.Email,
            "Phone": usuario.Celular,
            "Passwordmain": usuario.Senha,
            "ProfileID": 1,
            "CompanyID": 1,
            "Document": usuario.CPF,
            "CRM": usuario.CRM,
            "City": usuario.Cidade,
            "State": usuario.Estado,
            "HowDidMeet": usuario.ComoConheceu,
            "Profession": usuario.Profissao,
            "ProfessionText": profissaoText,
            "Token": usuario.Token
        };

        return this._http.post(_url, objPost);
    }

    buscarUsuario(usuarioID: number): Observable<User> {
        let userId = usuarioID;
        let _url: string = environment.nLaudoApi + 'User/BuscaUsuario/' + userId;
        return this._http.get<User>(_url);      
    }

    buscarUsuarioPorCPF(userCPF: any): Observable<User> {
        let _url: string = environment.nLaudoApi + 'usuario/CPF/' + userCPF
        return this._http.get<User>(_url);
    }

    buscarProfissaoPorUsuario(userId: number): Observable<User> {
        const _url: string = `${ environment.nLaudoApi }User/BuscarProfissaoPorUsuario/${ userId }`;

        return this._http.get<User>(_url);
    }

    atualizaUsuario(user: User): Observable<User> {
        const url = environment.nLaudoApi + 'User/AtualizaUsuario';
        const _user: User = user;

        const obj = {
            'ID': _user.id,
            'Name': _user.Name,
            'Email': _user.Email,
            'Phone': _user.Celular,
            'PasswordMain': _user.Senha,
            'ZipCode': _user.CEP,
            'Address': _user.Endereco,
            'Number': _user.Numero,
            'Complement': _user.Complemento,
            'City': _user.Cidade,
            'State': _user.Estado,
            'Country': _user.Pais,
            "Profession": _user.Profissao,
            "ProfessionText": _user.ProfissaoText,
        };

        return this._http.put<User>(url, obj);
    }

    enviaContato(sup: Suporte) {
        let _url = environment.nLaudoApi + 'User/EnviaContato';
        let objPost = {
            "UserID": sup.userId,
            "Subject": sup.subject,
            "Description": sup.description
        };

        return this._http.post(_url, objPost);
    }

    apenasNumeros(val: any) {
        let _val = val.toString();
        let res = _val.replace(/[^0-9]/g, '');
        return res;
    }

    enviaCodigoConfirmacao(usuarioID: number) {
        let userId = usuarioID;
        let _url = environment.nLaudoApi + 'User/EnviaCodigoConfirmacao';
        let objPost = {
            "ID": userId
        };
        return this._http.post(_url, objPost);
    }

    verificaConfirmationCode(usuario: User) {
        let objPost = {
            "ID": usuario.id,
            "ConfirmationCode": usuario.confirmationCode
        };
        let _url: string = environment.nLaudoApi + 'User/VerificaCodigoConfirmacao';
        return this._http.post(_url, objPost);
    }
}