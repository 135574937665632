import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MemberGetMember } from '../models/BO/MemberGetMember';
import { UserHasMemberGetMemberWallet, UserMemberGetMember } from '../models/BO/UserMemberGetMember';

@Injectable({
  providedIn: 'root'
})
export class MemberGetMemberService {

  constructor(private _http: HttpClient) { }

  gerarBuscarToken(usuarioID: number): Observable<UserMemberGetMember> {
    let _url: string = environment.nLaudoApi + 'MemberGetMember/GerarSalvarToken/' + usuarioID
    return this._http.get<UserMemberGetMember>(_url);
  }

  enviarEmail(MgMMail: MemberGetMember) {
    let _url: string = environment.nLaudoApi + 'MemberGetMember/EnviaMailConvite/';
    let objPost = {
      "UserName": MgMMail.UserName,
      "GuestName": MgMMail.GuestName,
      "MailGuest": MgMMail.MailGuest,
      "LinkToken": MgMMail.LinkToken,
    };
    return this._http.post(_url, objPost);
  }

  buscarSaldo(usuarioID: number): Observable<UserHasMemberGetMemberWallet> {
    let _url: string = environment.nLaudoApi + 'MemberGetMember/ExibirSaldoHistoricoMgM/' + usuarioID
    return this._http.get<UserHasMemberGetMemberWallet>(_url);
  }

}
