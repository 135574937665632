import { trigger, transition, query, stagger, animate, style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Injuries, Term } from 'src/app/models/BO/Term';
import { TermResult } from 'src/app/models/BO/TermResult';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { TermosGenericos } from 'src/app/models/Helper/TermosGenericos';
import { CommonService } from 'src/app/services/common.service';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';
import { LaudosService } from 'src/app/services/laudos.service.spec';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-rx-torax',
  templateUrl: './rx-torax.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./rx-torax.component.css']
})
export class RxToraxComponent implements OnInit {

  listTermDefault = [
    { listTerm: [4834], blockID: 343 },
    { listTerm: [0], blockID: 344 },
    { listTerm: [0], blockID: 345 },
    { listTerm: [0], blockID: 346 },
    { listTerm: [0], blockID: 347 },
    { listTerm: [0], blockID: 348 }
  ]

  descricoes: DescricaoConclusao[] = [
    {
      value: '',//tecnica
      blockID: 343, textTermID: 0, visivel: true
    },
    {
      value: 'Transparência normal dos campos pulmonares.',
      blockID: 346, textTermID: 0, visivel: true
    },
    {
      value: 'Seios costofrênicos livres.',
      blockID: 347, textTermID: 0, visivel: true
    },
    {
      value: 'Área cardíaca dentro dos limites da normalidade.',
      blockID: 348, textTermID: 0, visivel: true
    },
    {
      value: 'Arcabouço ósseo conservado.',
      blockID: 345, textTermID: 0, visivel: true
    },
    {
      value: 'Partes moles sem alterações.',
      blockID: 344, textTermID: 0, visivel: true
    },
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: '', blockID: 343, textTermID: 0, visivel: true },
    { value: '', blockID: 344, textTermID: 0, visivel: true },
    { value: '', blockID: 345, textTermID: 0, visivel: true },
    { value: '', blockID: 346, textTermID: 0, visivel: true },
    { value: '', blockID: 347, textTermID: 0, visivel: true },
    { value: '', blockID: 348, textTermID: 0, visivel: true },
  ];

  genericTecnica: TermosGenericos[] = [];

  genericPartesMoles: TermosGenericos[] = [
    { value: '', selected: false, termID: 4846, term: '[Lateralidade]' },
    { value: '', selected: false, termID: 4847, term: '[LateralidadeDreno]' },
    { value: '', selected: false, termID: 4848, term: '[LateralidadeEnfisema]' },
    { value: '', selected: false, termID: 4849, term: '[Posicionamento]' },
    { value: '', selected: false, termID: 4850, term: '[PosicaoSonda]' },
  ];

  genericEstruturasOsseas: TermosGenericos[] = [
    { value: '', selected: false, termID: 4884, term: '[Lateralidade]' },
    { value: '', selected: false, termID: 4885, term: '[Convexidade]' },
    { value: '', selected: false, termID: 4886, term: '[VertebraUnica]' },
    { value: '', selected: false, termID: 4887, term: '[VertebraNaoEspecifica]' },
    { value: '', selected: false, termID: 4888, term: '[FraturaCostelaDir]' },
    { value: '', selected: false, termID: 4889, term: '[FraturaCostelaEsq]' },
    { value: '', selected: false, termID: 4890, term: '[Clavicula]' },
    { value: '', selected: false, termID: 4891, term: '[Espacador]' },
    { value: '', selected: false, termID: 4892, term: '[CaloCostelaDir]' },
    { value: '', selected: false, termID: 4893, term: '[CaloCostelaEsq]' },
  ];

  genericMediastino: TermosGenericos[] = [
    { value: '', selected: false, termID: 4897, term: '[Indice]' },
    { value: '', selected: false, termID: 4901, term: '[Achado]' },
  ];

  genericSeiosCostofrenicos: TermosGenericos[] = [
    { value: '', selected: false, termID: 4908, term: '[LocalizacaoDerrame]' },
    { value: '', selected: false, termID: 4909, term: '[Graduacao]' },
    { value: '', selected: false, termID: 4911, term: '[LocalizacaoVelamento]' },
    { value: '', selected: false, termID: 4913, term: '[LocalizacaoElevacao]' },
  ];

  genericPulmoes: TermosGenericos[] = [
    { value: '', selected: false, termID: 4946, term: '[AlveolarDir]' },
    { value: '', selected: false, termID: 4947, term: '[AlveolarEsq]' },
    { value: '', selected: false, termID: 4950, term: '[ConsolidacaoDir]' },
    { value: '', selected: false, termID: 4951, term: '[ConsolidacaoEsq]' },
    { value: '', selected: false, termID: 4954, term: '[Lobo]' },
    { value: '', selected: false, termID: 4956, term: '[LocalizacaoAtelectasiaLaminar]' },
    { value: '', selected: false, termID: 4960, term: '[LocalizacaoOvaladoDir]' },
    { value: '', selected: false, termID: 4961, term: '[LocalizacaoOvaladoEsq]' },
    { value: '', selected: false, termID: 4962, term: '[MedidaOvalado]' },
    { value: '', selected: false, termID: 4963, term: '[LocalizacaoEspiculadoDir]' },
    { value: '', selected: false, termID: 4964, term: '[LocalizacaoEspiculadoEsq]' },
    { value: '', selected: false, termID: 4965, term: '[MedidaEspiculado]' },
    { value: '', selected: false, termID: 4967, term: '[LateralidadePneumotorax]' },
    { value: '', selected: false, termID: 4968, term: '[GraduacaoPneumotorax]' },
    { value: '', selected: false, termID: 4970, term: '[LateralidadeHidropneumotorax]' },
    { value: '', selected: false, termID: 4971, term: '[GraduacaoHidropneumotorax]' },
    { value: '', selected: false, termID: 4980, term: '[ReticularDir]' },
    { value: '', selected: false, termID: 4981, term: '[ReticularEsq]' },
  ];

  constructor(
    private _selected: SelectedItemEmiter,
    private _copyPaste: CopyPaste,
    public _dialog: MatDialog,
    private _toastr: ToastrManager,
    private _laudoService: LaudosService,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _serviceMedicalReport: MedicalReportService,
    private _config: ConfigLaudoService,
    public _addText: AddText,
    private dataService: DataTermService
  ) { }

  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();
  cenarioCorrente = 0;
  objetoGrupoLesao: any;

  Subtitulo: string;
  subtituloBloco: string;
  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  indicacaoTexto = '';
  achadosAdTexto = '';
  cabecalhoTexto = '';
  rodapeTexto = '';

  cabecalho = false;
  indicacao = false;
  tecnica = true;
  partesMoles = false;
  estruturasOsseas = false;
  pulmoes = false;
  seiosCostofrenicos = false;
  mediastino = false;
  achados = false;
  rodape = false;

  public showForm: Boolean = true;
  public unidadeMedida: string;
  public switchFormReport = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  editLaudoMode: boolean = false;

  medicalReportTypeID = 76;

  tecnicaId = 343;
  pulmoesId = 346;
  seiosCostofrenicosId = 347;
  mediastinoId = 348;
  estruturasOsseasId = 345;
  partesMolesId = 344;

  ngOnInit() {

    this.utilizarEditor();
    this._pouchService.loadDatabase("rxToraxAnalise", this.descricoes, this.conclusoes);
    this.subtituloBloco = this.Subtitulo;
    this.Subtitulo = 'Técnica';
    this.term.listTermID = new Array<number>();
    this.resetaDialog();

    const listBlocks = [
      this.tecnicaId,
      this.pulmoesId,
      this.seiosCostofrenicosId,
      this.mediastinoId,
      this.estruturasOsseasId,
      this.partesMolesId
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "rxToraxMask").then((obj: any) => {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "torax");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'rx-torax');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'rx-torax');
      }
    });

    let cmValidator = localStorage.getItem('cmRule');
    if (cmValidator == undefined || cmValidator == null) {
      this.unidadeMedida = 'cm';
    } else {
      let cmRule = JSON.parse(localStorage.getItem('cmRule'));
      this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
    }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if (config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  blocos = [
    {
      titulo: 'Informações',
      id: 'cabecalho',
      name: 'cabecalho',
      selected: false,
    },
    {
      titulo: 'Indicação',
      id: 'indicacao',
      name: 'indicacao',
      subtitulo: 'preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Técnica',
      id: this.tecnicaId,
      name: 'tecnica',
      selected: true
    },
    {
      titulo: 'Pulmões',
      id: this.pulmoesId,
      name: 'pulmoes',
      selected: false
    },
    {
      titulo: 'Seios costofrênicos',
      id: this.seiosCostofrenicosId,
      name: 'seiosCostofrenicos',
      selected: false
    },
    {
      titulo: 'Mediastino',
      id: this.mediastinoId,
      name: 'mediastino',
      selected: false
    },
    {
      titulo: 'Estruturas ósseas',
      id: this.estruturasOsseasId,
      name: 'estruturasOsseas',
      selected: false
    },
    {
      titulo: 'Partes moles',
      id: this.partesMolesId,
      name: 'partesMoles',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if (itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if (this.editLaudoMode && Number.isInteger(itemSelecionado.id)) {
      let seachTerm = this.listTermDefault.filter(item => item.blockID === itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm, seachTerm[0].blockID);
    }


    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.tecnica = (itemSelecionado.id === this.tecnicaId);
    this.partesMoles = (itemSelecionado.id === this.partesMolesId);
    this.estruturasOsseas = (itemSelecionado.id === this.estruturasOsseasId);
    this.pulmoes = (itemSelecionado.id === this.pulmoesId);
    this.seiosCostofrenicos = (itemSelecionado.id === this.seiosCostofrenicosId);
    this.mediastino = (itemSelecionado.id === this.mediastinoId);
    this.achados = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.genericPartesMoles = [
      { value: '', selected: false, termID: 4846, term: '[Lateralidade]' },
      { value: '', selected: false, termID: 4847, term: '[LateralidadeDreno]' },
      { value: '', selected: false, termID: 4848, term: '[LateralidadeEnfisema]' },
      { value: '', selected: false, termID: 4849, term: '[Posicionamento]' },
      { value: '', selected: false, termID: 4850, term: '[PosicaoSonda]' },
    ];

    this.genericEstruturasOsseas = [
      { value: '', selected: false, termID: 4884, term: '[Lateralidade]' },
      { value: '', selected: false, termID: 4885, term: '[Convexidade]' },
      { value: '', selected: false, termID: 4886, term: '[VertebraUnica]' },
      { value: '', selected: false, termID: 4887, term: '[VertebraNaoEspecifica]' },
      { value: '', selected: false, termID: 4888, term: '[FraturaCostelaDir]' },
      { value: '', selected: false, termID: 4889, term: '[FraturaCostelaEsq]' },
      { value: '', selected: false, termID: 4890, term: '[Clavicula]' },
      { value: '', selected: false, termID: 4891, term: '[Espacador]' },
      { value: '', selected: false, termID: 4892, term: '[CaloCostelaDir]' },
      { value: '', selected: false, termID: 4893, term: '[CaloCostelaEsq]' },
    ];

    this.genericMediastino = [
      { value: '', selected: false, termID: 4897, term: '[Indice]' },
      { value: '', selected: false, termID: 4901, term: '[Achado]' },
    ];

    this.genericSeiosCostofrenicos = [
      { value: '', selected: false, termID: 4908, term: '[LocalizacaoDerrame]' },
      { value: '', selected: false, termID: 4909, term: '[Graduacao]' },
      { value: '', selected: false, termID: 4911, term: '[LocalizacaoVelamento]' },
      { value: '', selected: false, termID: 4913, term: '[LocalizacaoElevacao]' },
    ];

    this.genericPulmoes = [
      { value: '', selected: false, termID: 4946, term: '[AlveolarDir]' },
      { value: '', selected: false, termID: 4947, term: '[AlveolarEsq]' },
      { value: '', selected: false, termID: 4950, term: '[ConsolidacaoDir]' },
      { value: '', selected: false, termID: 4951, term: '[ConsolidacaoEsq]' },
      { value: '', selected: false, termID: 4954, term: '[Lobo]' },
      { value: '', selected: false, termID: 4956, term: '[LocalizacaoAtelectasiaLaminar]' },
      { value: '', selected: false, termID: 4960, term: '[LocalizacaoOvaladoDir]' },
      { value: '', selected: false, termID: 4961, term: '[LocalizacaoOvaladoEsq]' },
      { value: '', selected: false, termID: 4962, term: '[MedidaOvalado]' },
      { value: '', selected: false, termID: 4963, term: '[LocalizacaoEspiculadoDir]' },
      { value: '', selected: false, termID: 4964, term: '[LocalizacaoEspiculadoEsq]' },
      { value: '', selected: false, termID: 4965, term: '[MedidaEspiculado]' },
      { value: '', selected: false, termID: 4967, term: '[LateralidadePneumotorax]' },
      { value: '', selected: false, termID: 4968, term: '[GraduacaoPneumotorax]' },
      { value: '', selected: false, termID: 4970, term: '[LateralidadeHidropneumotorax]' },
      { value: '', selected: false, termID: 4971, term: '[GraduacaoHidropneumotorax]' },
      { value: '', selected: false, termID: 4980, term: '[ReticularDir]' },
      { value: '', selected: false, termID: 4981, term: '[ReticularEsq]' },
    ];

    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/>';
    else
      this.indicacaoTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    debugger;
    if (this.cenarioCorrente === 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario === 0 || $event.cenario === this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  adicionaSelecaoPartesMoles($event) {
    this.genericPartesMoles.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoEstruturasOsseas($event) {
    this.genericEstruturasOsseas.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoMediastino($event) {
    this.genericMediastino.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoSeiosCostofrenicos($event) {
    this.genericSeiosCostofrenicos.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoPulmoes($event) {
    this.genericPulmoes.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }


  consultarAPI() {
    let desc;
    if (this.editLaudoMode) {
      let blocoSelecionado = this.blocos.filter(bloco => {
        return bloco.selected == true
      })
      this.dataService.setTermos(this.term.listTermID, blocoSelecionado[0].id)
      return;
    } else if (!this.editLaudoMode) {
      if (this.tecnica) {
        this.term.blockID = this.tecnicaId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.tecnicaId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + resultado.text;
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.tecnicaId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.tecnicaId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + resultado.conclusion
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.tecnicaId);

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.partesMoles) {
        this.term.blockID = this.partesMolesId;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.partesMolesId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoPartesMoles(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.partesMolesId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.partesMolesId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoPartesMoles(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.partesMolesId);

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.estruturasOsseas) {
        this.term.blockID = this.estruturasOsseasId;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.estruturasOsseasId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoEstruturasOsseas(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.estruturasOsseasId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });
          let obj = {
            value: '',
            blockID: this.estruturasOsseasId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoEstruturasOsseas(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });
        });
      } else if (this.mediastino) {
        this.term.blockID = this.mediastinoId;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.mediastinoId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoMediastino(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.mediastinoId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.mediastinoId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoMediastino(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (indexConclusion.length <= 0) {
                  obj.blockID = 0;
                  obj.value = this.RetornaConclusaoNull(resultado.conclusion);
                } else {
                  if (!obj.value) {
                    obj.blockID = 0;
                    obj.value = '';
                  }
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.mediastinoId);

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.seiosCostofrenicos) {
        this.term.blockID = this.seiosCostofrenicosId;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.seiosCostofrenicosId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                debugger
                obj.value = obj.value + espaco + this.MontaDescricaoSeiosCostofrenicos(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.seiosCostofrenicosId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.seiosCostofrenicosId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoSeiosCostofrenicos(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.seiosCostofrenicosId);

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.pulmoes) {
        this.term.blockID = this.pulmoesId;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.pulmoesId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                debugger
                obj.value = obj.value + espaco + this.MontaDescricaoPulmoes(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.pulmoesId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.pulmoesId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoPulmoes(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.pulmoesId);

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  MontaDescricaoTecnica(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 4797 || +term === 4798) {
        this.genericTecnica.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoPartesMoles(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4846 || +term === 4847 || +term === 4848 || +term === 4849 || +term === 4850) {
        this.genericPartesMoles.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4846 || +term === 4847 || +term === 4848 || +term === 4849 || +term === 4850) {
          this.genericPartesMoles.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoEstruturasOsseas(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4884 || +term === 4885 || +term === 4886 || +term === 4887 || +term === 4888
        || +term === 4889 || +term === 4890 || +term === 4891 || +term === 4892 || +term === 4893) {
        this.genericEstruturasOsseas.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4884 || +term === 4885 || +term === 4886 || +term === 4887 || +term === 4888
          || +term === 4889 || +term === 4890 || +term === 4891 || +term === 4892 || +term === 4893) {
          this.genericEstruturasOsseas.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }
  MontaDescricaoMediastino(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4897 || +term === 4901) {
        this.genericMediastino.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4897 || +term === 4901) {
          this.genericMediastino.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoSeiosCostofrenicos(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4908 || +term === 4909 || +term === 4911 || +term === 4913) {
        this.genericSeiosCostofrenicos.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4908 || +term === 4909 || +term === 4911 || +term === 4913) {
          this.genericSeiosCostofrenicos.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoPulmoes(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4946 || +term === 4947 || +term === 4950 || +term === 4951
        || +term === 4954 || +term === 4956 || +term === 4960 || +term === 4961
        || +term === 4962 || +term === 4963 || +term === 4964 || +term === 4965
        || +term === 4967 || +term === 4968 || +term === 4970 || +term === 4971
        || +term === 4980 || +term === 4981) {
        this.genericPulmoes.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4946 || +term === 4947 || +term === 4950 || +term === 4951
          || +term === 4954 || +term === 4956 || +term === 4960 || +term === 4961
          || +term === 4962 || +term === 4963 || +term === 4964 || +term === 4965
          || +term === 4967 || +term === 4968 || +term === 4970 || +term === 4971
          || +term === 4980 || +term === 4981) {
          this.genericPulmoes.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if (isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });


    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      if (descricao.value && descricao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }
    //Lógica Rules
    // if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
    //   this.htmlContent += '<br/><b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';
    // }

    for (i = 0; i < this.descricoes.length; i++) {
      let newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      let newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }

    this.htmlContent += this.indicacaoTexto + ' </br>';
    this.htmlContent += maskSplit[1];
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
    }

    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }

    this.htmlContent += this.achadosAdTexto;

    if (this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
    blockId,
    descricao,
    conclusao,
    conclusaoNull,
    indicacao,
    achados,
    cabecalho,
    rodape,
    calculadora,
  }: IAtualizaLinha): void {
    if (blockId !== undefined) {
      if (indicacao === undefined && achados === undefined) {
        if (descricao === undefined) {
          this.descricoes.forEach(desc => {
            if (desc.blockID === blockId) {
              descricao = desc.value;
            }
          });
        }

        const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

        if (conclusao !== '' || conclusaoNull !== '') {
          if (conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                  ? conclusaoNull !== ''
                    ? conclusaoNull
                    : ''
                  : conclusao;
              }
            });
          }
        } else {
          if (conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                  ? conclusaoNull !== ''
                    ? conclusaoNull
                    : ''
                  : conclusao;
              }
            });
          }
        }

        if (description) {
          description.innerHTML = descricao;
        } else if (!description) {
          this.AdicionaTexto(true);
        } else if (!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if (cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if (header) {
            header.innerHTML = cabecalho;
          }
        }

        if (rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if (footer) {
            footer.innerHTML = rodape;
          }
        }

        if (calculadora !== undefined) {
          const calculo = document.querySelector(`#e-${blockId}`);

          if (calculo) {
            calculo.innerHTML = calculadora;
          }
        }

      } else if (indicacao !== undefined || achados !== undefined) {
        if (indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if (recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
        } else if (achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if (findings) {
            findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
        }
      }
      this._addText.lineHeight();
    }
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
          }
        });
      }
    });
  };

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = '<p>' + Texto + '</p>';
    return texto;
  }

  public resetaLaudo() {
    this._pouchService.getAll("rxSeiosDaFaceAnalise").then((obj) => {
      console.log(obj)
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.showForm = false;
    this.indicacaoTexto = "";
    this.achadosAdTexto = "";
    this.cabecalhoTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  handleSwitchFormReport(formReport: string) {
    if (formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
