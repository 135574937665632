import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatCheckbox, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-estruturas-osseas',
  templateUrl: './estruturas-osseas.component.html',
  styleUrls: ['./estruturas-osseas.component.css']
})
export class EstruturasOsseasComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('ckCostelaCervical') ckCostelaCervical: MatCheckbox;
  @ViewChild('slLateralidade') slLateralidade: MatSelect;

  @ViewChild('ckEspondilose') ckEspondilose: MatCheckbox;

  @ViewChild('ckAcentuacaoCifose') ckAcentuacaoCifose: MatCheckbox;

  @ViewChild('ckEscoliose') ckEscoliose: MatCheckbox;
  @ViewChild('slConvexidade') slConvexidade: MatSelect;


  @ViewChild('ckFraturaCorpoUnica') ckFraturaCorpoUnica: MatCheckbox;
  @ViewChild('slVertebra') slVertebra: MatSelect;

  @ViewChild('ckFraturaCorpoNaoEspecifica') ckFraturaCorpoNaoEspecifica: MatCheckbox;
  @ViewChild('slVertebraNaoEspecifica') slVertebraNaoEspecifica: MatSelect;

  @ViewChild('ckFraturaCostela') ckFraturaCostela: MatCheckbox;
  @ViewChild('slCostelaDir') slCostelaDir: MatSelect;
  @ViewChild('slCostelaEsq') slCostelaEsq: MatSelect;

  @ViewChild('ckFraturaClavicula') ckFraturaClavicula: MatCheckbox;
  @ViewChild('slClavicula') slClavicula: MatSelect;

  @ViewChild('ckColunaOperada') ckColunaOperada: MatCheckbox;

  @ViewChild('ckEspacadorDiscal') ckEspacadorDiscal: MatCheckbox;
  @ViewChild('slEspacadorDiscal') slEspacadorDiscal: MatSelect;

  @ViewChild('ckCaloOsseo') ckCaloOsseo: MatCheckbox;
  @ViewChild('slCaloOsseoDir') slCaloOsseoDir: MatSelect;
  @ViewChild('slCaloOsseoEsq') slCaloOsseoEsq: MatSelect;

  lateralidade: ArrayDropDown[] = [
    { value: ' à direita', viewValue: 'Direita' },
    { value: ' à esquerda', viewValue: 'Esquerda' },
    { value: ' bilateral', viewValue: 'Bilateral' }
  ];

  convexidade: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Para a direita' },
    { value: 'esquerda', viewValue: 'Para a esquerda' }
  ]

  vertebraUnica: ArrayDropDown[] = [
    { value: ' T1', viewValue: 'T1' },
    { value: ' T2', viewValue: 'T2' },
    { value: ' T3', viewValue: 'T3' },
    { value: ' T4', viewValue: 'T4' },
    { value: ' T5', viewValue: 'T5' },
    { value: ' T6', viewValue: 'T6' },
    { value: ' T7', viewValue: 'T7' },
    { value: ' T8', viewValue: 'T8' },
    { value: ' T9', viewValue: 'T9' },
    { value: ' T10', viewValue: 'T10' },
    { value: ' T11', viewValue: 'T11' },
    { value: ' T12', viewValue: 'T12' }
  ];

  vertebraNaoEspecifica: ArrayDropDown[] = [
    { value: ' dorsal alta', viewValue: 'Dorsal alta' },
    { value: ' dorsal média', viewValue: 'Dorsal média' },
    { value: ' dorsal baixa', viewValue: 'Dorsal baixa' },
    { value: ' na transição toracolombar', viewValue: 'Na transição toracolombar' },
  ];

  costela: ArrayDropDown[] = [
    { value: ' 1ª', viewValue: '1ª' },
    { value: ' 2ª', viewValue: '2ª' },
    { value: ' 3ª', viewValue: '3ª' },
    { value: ' 4ª', viewValue: '4ª' },
    { value: ' 5ª', viewValue: '5ª' },
    { value: ' 6ª', viewValue: '6ª' },
    { value: ' 7ª', viewValue: '7ª' },
    { value: ' 8ª', viewValue: '8ª' },
    { value: ' 9ª', viewValue: '9ª' },
    { value: ' 10ª', viewValue: '10ª' },
    { value: ' 11ª', viewValue: '11ª' },
    { value: ' 12ª', viewValue: '12ª' },
  ];

  localizacao: ArrayDropDown[] = [
    { value: ' alta', viewValue: 'Alta' },
    { value: ' média', viewValue: 'Média' },
    { value: ' baixa', viewValue: 'Baixa' },
  ]

  showAndHideTerms: any;
  countLesoes = 0;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {

    this.showAndHideTerms = {
      sahCostelaCervical: false,
      sahEscoliose: false,
      sahFraturaCorpoUnica: false,
      sahFraturaCorpoNaoEspecifica: false,
      sahCostela: false,
      sahClavicula: false,
      sahEspacadorDiscal: false,
      sahCaloOsseo: false
    };
  }

  changeSelect(){
    this.catchIDs();
  }

  clickDefault(){
    this.catchIDs();
  }

  clickCostelaCervical() {
    if (this.ckCostelaCervical && this.ckCostelaCervical.checked) {
      this.showAndHideTerms.sahCostelaCervical = true;
    } else {
      this.showAndHideTerms.sahCostelaCervical = false;
    }
    this.catchIDs();
  }

  clickEscoliose() {
    if (this.ckEscoliose && this.ckEscoliose.checked) {
      this.showAndHideTerms.sahEscoliose = true;
    } else {
      this.showAndHideTerms.sahEscoliose = false;
    }
    this.catchIDs();
  }

  clickFraturaCorpoUnica() {
    if (this.ckFraturaCorpoUnica && this.ckFraturaCorpoUnica.checked) {
      this.showAndHideTerms.sahFraturaCorpoUnica = true;
    } else {
      this.showAndHideTerms.sahFraturaCorpoUnica = false;
    }
    this.catchIDs();
  }

  clickFraturaCorpoNaoEspecifica() {
    if (this.ckFraturaCorpoNaoEspecifica && this.ckFraturaCorpoNaoEspecifica.checked) {
      this.showAndHideTerms.sahFraturaCorpoNaoEspecifica = true;
    } else {
      this.showAndHideTerms.sahFraturaCorpoNaoEspecifica = false;
    }
    this.catchIDs();
  }

  clickFraturaCostela(){
    if (this.ckFraturaCostela && this.ckFraturaCostela.checked) {
      this.showAndHideTerms.sahCostela = true;
    } else {
      this.showAndHideTerms.sahCostela = false;
    }
    this.catchIDs();
  }

  clickFraturaClavicula(){
    if (this.ckFraturaClavicula && this.ckFraturaClavicula.checked) {
      this.showAndHideTerms.sahClavicula = true;
    } else {
      this.showAndHideTerms.sahClavicula = false;
    }
    this.catchIDs();
  }

  clickEspacadorDiscal(){
    if (this.ckEspacadorDiscal && this.ckEspacadorDiscal.checked) {
      this.showAndHideTerms.sahEspacadorDiscal = true;
    } else {
      this.showAndHideTerms.sahEspacadorDiscal = false;
    }
    this.catchIDs();
  }

  clickCaloOsseo(){
    if (this.ckCaloOsseo && this.ckCaloOsseo.checked) {
      this.showAndHideTerms.sahCaloOsseo = true;
    } else {
      this.showAndHideTerms.sahCaloOsseo = false;
    }
    this.catchIDs();
  }

  checkPresente(){
    if(this.ckCostelaCervical && !this.ckCostelaCervical.checked &&
      this.ckEspondilose && !this.ckEspondilose.checked &&
      this.ckAcentuacaoCifose && !this.ckAcentuacaoCifose.checked &&
      this.ckEscoliose && !this.ckEscoliose.checked &&
      this.ckFraturaCorpoUnica && !this.ckFraturaCorpoUnica.checked &&
      this.ckFraturaCorpoNaoEspecifica && !this.ckFraturaCorpoNaoEspecifica.checked &&
      this.ckFraturaCostela && !this.ckFraturaCostela.checked &&
      this.ckFraturaClavicula && !this.ckFraturaClavicula.checked &&
      this.ckColunaOperada && !this.ckColunaOperada.checked &&
      this.ckEspacadorDiscal && !this.ckEspacadorDiscal.checked &&
      this.ckCaloOsseo && !this.ckCaloOsseo.checked){
        return true;
    }
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    this.emitCostelaCervical();

    //Espondilose
    if(this.countLesoes > 0){
      this.emitEspondiloseLesao();
    } else {
      this.emitEspondiloseSimples();
    }

    //Acentuação
    if(this.countLesoes > 0){
      this.emitAcentuacaoLesao();
    } else {
      this.emitAcentuacaoSimples();
    }

    //Escoliose
    if(this.countLesoes > 0){
      this.emitEscolioseLesao();
    } else {
      this.emitEscolioseSimples();
    }

    //Fratura corpo unica
    if(this.countLesoes > 0){
      this.emitFraturaCorpoUnicaLesao();
    } else {
      this.emitFraturaCorpoUnicaSimples();
    }

    //Fratura corpo nao especifica
    if(this.countLesoes > 0){
      this.emitFraturaCorpoNaoEspecificaLesao();
    } else {
      this.emitFraturaCorpoNaoEspecificaSimples();
    }

    //Fratura costela
    if(this.countLesoes > 0){
      this.emitFraturaCostelaLesao();
    } else {
      this.emitFraturaCostelaSimples();
    }

    //Fratura clavicula
    if(this.countLesoes > 0){
      this.emitFraturaClaviculaLesao();
    } else {
      this.emitFraturaClaviculaSimples();
    }

    //Coluna Operada
    if(this.countLesoes > 0){
      this.emitColunaOperadaLesao();
    } else {
      this.emitColunaOperadaSimples();
    }

    //Espacador discal
    if(this.countLesoes > 0){
      this.emitEspacadorDiscalLesao();
    } else {
      this.emitEspacadorDiscalSimples();
    }

    //Calo Osseo
    if(this.countLesoes > 0){
      this.emitCaloOsseoLesao();
    } else {
      this.emitCaloOsseoSimples();
    }

    if(this.checkPresente()){
      this.termoSelecionado.emit(4905);
    }

    this.listaCompleta.emit();
  }

  emitCostelaCervical() {
    if (this.ckCostelaCervical && this.ckCostelaCervical.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCostelaCervical.id);

      if(this.slLateralidade && this.slLateralidade.value){
        const objeto = {elemento: this.slLateralidade, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEspondiloseLesao() {
    if (this.ckEspondilose && this.ckEspondilose.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckEspondilose.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitEspondiloseSimples() {
    if (this.ckEspondilose && this.ckEspondilose.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEspondilose.id);
    }
  }

  emitAcentuacaoLesao() {
    if(this.ckAcentuacaoCifose && this.ckAcentuacaoCifose.checked){
      this.countLesoes++;
      const objeto = { id: this.ckAcentuacaoCifose.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitAcentuacaoSimples() {
    if(this.ckAcentuacaoCifose && this.ckAcentuacaoCifose.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAcentuacaoCifose.id);
    }
  }

  emitEscolioseLesao() {
    if (this.ckEscoliose && this.ckEscoliose.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckEscoliose.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slConvexidade && this.slConvexidade.value){
        const objeto = {elemento: this.slConvexidade, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEscolioseSimples() {
    if (this.ckEscoliose && this.ckEscoliose.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEscoliose.id);

      if(this.slConvexidade && this.slConvexidade.value){
        const objeto = {elemento: this.slConvexidade, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitFraturaCorpoUnicaLesao() {
    if (this.ckFraturaCorpoUnica && this.ckFraturaCorpoUnica.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckFraturaCorpoUnica.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slVertebra && this.slVertebra.value ){
        if(this.slVertebra.value.length > 1){
          let elem = {id:this.slVertebra.id , value: this.replaceVirgulaMultSelect(this.slVertebra.value.toString())}
          const objeto = {elemento: elem, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }else if(this.slVertebra.value.length==1){

          const objeto = {elemento: this.slVertebra, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitFraturaCorpoUnicaSimples() {
    if (this.ckFraturaCorpoUnica && this.ckFraturaCorpoUnica.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFraturaCorpoUnica.id);

      if(this.slVertebra && this.slVertebra.value){
        if(this.slVertebra.value.length > 1){
          let elem = {id:this.slVertebra.id , value: this.replaceVirgulaMultSelect(this.slVertebra.value.toString())}
          const objeto = {elemento: elem, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);

        }else if(this.slVertebra.value.length==1){

          const objeto = {elemento: this.slVertebra, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitFraturaCorpoNaoEspecificaLesao() {
    if (this.ckFraturaCorpoNaoEspecifica && this.ckFraturaCorpoNaoEspecifica.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckFraturaCorpoNaoEspecifica.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slVertebraNaoEspecifica && this.slVertebraNaoEspecifica.value ){
        if(this.slVertebraNaoEspecifica.value.length > 1){
          let elem = {id:this.slVertebraNaoEspecifica.id , value: this.replaceVirgulaMultSelect(this.slVertebraNaoEspecifica.value.toString())}
          const objeto = {elemento: elem, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }else if(this.slVertebraNaoEspecifica.value.length==1){

          const objeto = {elemento: this.slVertebraNaoEspecifica, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitFraturaCorpoNaoEspecificaSimples() {
    if (this.ckFraturaCorpoNaoEspecifica && this.ckFraturaCorpoNaoEspecifica.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFraturaCorpoNaoEspecifica.id);

      if(this.slVertebraNaoEspecifica && this.slVertebraNaoEspecifica.value){
        if(this.slVertebraNaoEspecifica.value.length > 1){
          let elem = {id:this.slVertebraNaoEspecifica.id , value: this.replaceVirgulaMultSelect(this.slVertebraNaoEspecifica.value.toString())}
          const objeto = {elemento: elem, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);

        }else if(this.slVertebraNaoEspecifica.value.length==1){

          const objeto = {elemento: this.slVertebraNaoEspecifica, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitFraturaCostelaLesao(){
    if(this.ckFraturaCostela && this.ckFraturaCostela.checked){
      this.countLesoes++;
      const objeto = { id: this.ckFraturaCostela.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slCostelaDir && this.slCostelaDir.value){
        if(this.slCostelaDir.value.length >1){
          let elem = {id: this.slCostelaDir.id, value: this.replaceVirgulaMultSelect(this.slCostelaDir.value.toString())};
          const objeto = {elemento: elem, isPrimeiraLesao: false};
          this.termosGenericos.emit(objeto);

        }else if(this.slCostelaDir.value.length==1){

          const objeto = {elemento: this.slCostelaDir, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);
        }
      }

      if(this.slCostelaEsq && this.slCostelaEsq.value){
        if(this.slCostelaEsq.value.length >1){
          let elem = {id: this.slCostelaEsq.id, value: this.replaceVirgulaMultSelect(this.slCostelaEsq.value.toString())};
          const objeto = {elemento: elem, isPrimeiraLesao: false};
          this.termosGenericos.emit(objeto);

        }else if(this.slCostelaEsq.value.length==1){

          const objeto = {elemento: this.slCostelaEsq, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitFraturaCostelaSimples(){
    if(this.ckFraturaCostela && this.ckFraturaCostela.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFraturaCostela.id);

      if(this.slCostelaDir && this.slCostelaDir.value){
        if(this.slCostelaDir.value.length > 1){

          let elem = {id:this.slCostelaDir.id, value: this.replaceVirgulaMultSelect(this.slCostelaDir.value.toString())};
          const objeto = {elemento: elem, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);

        }else if(this.slCostelaDir.value.length==1){
          const objeto = {elemento: this.slCostelaDir, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }

      if(this.slCostelaEsq && this.slCostelaEsq.value){
        if(this.slCostelaEsq.value.length > 1){
          let elem = {id:this.slCostelaEsq.id, value: this.replaceVirgulaMultSelect(this.slCostelaEsq.value.toString())};
          const objeto = {elemento: elem, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);

        }else if(this.slCostelaEsq.value.length==1){
          const objeto = {elemento: this.slCostelaEsq, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitFraturaClaviculaLesao(){
    if(this.ckFraturaClavicula && this.ckFraturaClavicula.checked){
      this.countLesoes++;
      const objeto = { id: this.ckFraturaClavicula.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slClavicula && this.slClavicula.value){
        const objeto = {elemento: this.slClavicula, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitFraturaClaviculaSimples(){
    if(this.ckFraturaClavicula && this.ckFraturaClavicula.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFraturaClavicula.id);

      if(this.slClavicula && this.slClavicula.value){
        const objeto = {elemento: this.slClavicula, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitColunaOperadaLesao(){
    if(this.ckColunaOperada && this.ckColunaOperada.checked){
      this.countLesoes++;
      const objeto = { id: this.ckColunaOperada.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitColunaOperadaSimples(){
    if(this.ckColunaOperada && this.ckColunaOperada.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckColunaOperada.id);
    }
  }

  emitEspacadorDiscalLesao(){
    if(this.ckEspacadorDiscal && this.ckEspacadorDiscal.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEspacadorDiscal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slEspacadorDiscal && this.slEspacadorDiscal.value){
        if(this.slEspacadorDiscal.value.length>1){
            let elem = {id: this.slEspacadorDiscal.id , value:this.replaceVirgulaMultSelect(this.slEspacadorDiscal.value.toString())}
            const objeto = {elemento: elem, isPrimeiraLesao: false}
            this.termosGenericos.emit(objeto);
        }else if(this.slEspacadorDiscal.value.length==1){
          const objeto = {elemento: this.slEspacadorDiscal, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitEspacadorDiscalSimples(){
    if(this.ckEspacadorDiscal && this.ckEspacadorDiscal.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEspacadorDiscal.id);

      if(this.slEspacadorDiscal && this.slEspacadorDiscal.value){
        if(this.slEspacadorDiscal.value.length>1){
            let elem = {id: this.slEspacadorDiscal.id , value:this.replaceVirgulaMultSelect(this.slEspacadorDiscal.value.toString())}
            const objeto = {elemento: elem, isPrimeiraLesao: true}
            this.termosGenericos.emit(objeto);
        }else if(this.slEspacadorDiscal.value.length==1){
          const objeto = {elemento: this.slEspacadorDiscal, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitCaloOsseoLesao(){
    if(this.ckCaloOsseo && this.ckCaloOsseo.checked){
      this.countLesoes++;
      const objeto = { id: this.ckCaloOsseo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slCaloOsseoDir && this.slCaloOsseoDir.value){
        if(this.slCaloOsseoDir.value.length >1){
          let elem = {id:this.slCaloOsseoDir.id, value:this.replaceVirgulaMultSelect(this.slCaloOsseoDir.value.toString())};
          const objeto = {elemento: elem, isPrimeiraLesao: false};
          this.termosGenericos.emit(objeto);

        }else if(this.slCaloOsseoDir.value.length==1){

          const objeto = {elemento: this.slCaloOsseoDir, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);
        }
      }

      if(this.slCaloOsseoEsq && this.slCaloOsseoEsq.value){
        if(this.slCaloOsseoEsq.value.length >1){
          let elem = {id:this.slCaloOsseoEsq.id, value:this.replaceVirgulaMultSelect(this.slCaloOsseoEsq.value.toString())};
          const objeto = {elemento: elem, isPrimeiraLesao: false};
          this.termosGenericos.emit(objeto);

        }else if(this.slCaloOsseoEsq.value.length==1){
          const objeto = {elemento: this.slCaloOsseoEsq, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitCaloOsseoSimples(){
    if(this.ckCaloOsseo && this.ckCaloOsseo.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCaloOsseo.id);

      if(this.slCaloOsseoDir && this.slCaloOsseoDir.value){
        if(this.slCaloOsseoDir.value.length > 1){
          console.log(this.slCaloOsseoDir.value)
          let elem = {id:this.slCaloOsseoDir.id, value: this.replaceVirgulaMultSelect(this.slCaloOsseoDir.value.toString())};
          const objeto = {elemento: elem, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);

        }else if(this.slCaloOsseoDir.value.length==1){
          const objeto = {elemento: this.slCaloOsseoDir, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }

      if(this.slCaloOsseoEsq && this.slCaloOsseoEsq.value){
        if(this.slCaloOsseoEsq.value.length > 1){
          let elem = {id:this.slCaloOsseoEsq.id, value: this.replaceVirgulaMultSelect(this.slCaloOsseoEsq.value.toString())};
          const objeto = {elemento: elem, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);

        }else if(this.slCaloOsseoEsq.value.length==1){
          const objeto = {elemento: this.slCaloOsseoEsq, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  replaceVirgulaMultSelect(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }
}
