import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-processos-espinhosos',
  templateUrl: './processos-espinhosos.component.html',
  styleUrls: ['./processos-espinhosos.component.css']
})
export class ProcessosEspinhososComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdHipertrofiaUnica') rdHipertrofiaUnica: MatRadioButton;
  @ViewChild('rdHipertrofiasMultiplas') rdHipertrofiasMultiplas: MatRadioButton;
  @ViewChild('rdHipertrofiaDifusa') rdHipertrofiaDifusa: MatRadioButton;

  @ViewChild('slHipertrofiaUnica') slHipertrofiaUnica: MatSelect;
  @ViewChild('slHipertrofiasMultiplas') slHipertrofiasMultiplas: MatSelect;

  nivelHipertrofiaUnica: ArrayDropDown[] = [
    { value: 'T12', viewValue: 'T12' },
    { value: 'L1', viewValue: 'L1' },
    { value: 'L2', viewValue: 'L2' },
    { value: 'L3', viewValue: 'L3' },
    { value: 'L4', viewValue: 'L4' },
    { value: 'L5', viewValue: 'L5' },
  ];

  nivelHipertrofiasMultiplas: ArrayDropDown[] = [
    { value: 'T12', viewValue: 'T12' },
    { value: 'L1', viewValue: 'L1' },
    { value: 'L2', viewValue: 'L2' },
    { value: 'L3', viewValue: 'L3' },
    { value: 'L4', viewValue: 'L4' },
    { value: 'L5', viewValue: 'L5' },
  ];

  constructor() { }

  showAndHideTerms: any;

  ngOnInit() {
    this.showAndHideTerms = {
      sahHipertrofiaUnica: false,
      sahHipertrofiasMultiplas: false,
    };
  }
  
  clickNormal(){
    this.showAndHideTerms.sahHipertrofiaUnica = false;
    this.showAndHideTerms.sahHipertrofiasMultiplas = false;
    this.catchIDs();
  }

  clickHipertrofiaUnica() {
    this.showAndHideTerms.sahHipertrofiaUnica = true;
    this.showAndHideTerms.sahHipertrofiasMultiplas = false;
    this.catchIDs();
  }

  clickHipertrofiaMultiplas() {
    this.showAndHideTerms.sahHipertrofiaUnica = false;
    this.showAndHideTerms.sahHipertrofiasMultiplas = true;
    this.catchIDs();
  }

  clickHipertrofiaDifusa() {
    this.showAndHideTerms.sahHipertrofiaUnica = false;
    this.showAndHideTerms.sahHipertrofiasMultiplas = false;
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }
  
  catchIDs(){
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdHipertrofiaUnica.checked) {
      this.termoSelecionado.emit(this.rdHipertrofiaUnica.id);

      if(this.slHipertrofiaUnica && this.slHipertrofiaUnica.value){
        const objeto = {elemento: this.slHipertrofiaUnica, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdHipertrofiasMultiplas.checked) {
      this.termoSelecionado.emit(this.rdHipertrofiasMultiplas.id);

      if(this.slHipertrofiasMultiplas && this.slHipertrofiasMultiplas.value){
        let objetoMultiplo= { id:this.slHipertrofiasMultiplas.id, value:this.slHipertrofiasMultiplas.value };
        let elementoMultiplo ={elemento:objetoMultiplo, isPrimeiraLesao: true};
        this.termosGenericos.emit(elementoMultiplo);
      }
    }

    if (this.rdHipertrofiaDifusa.checked) {
      this.termoSelecionado.emit(this.rdHipertrofiaDifusa.id);
    }

    this.listaCompleta.emit();
  }
}