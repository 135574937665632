import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../models/Helper/ArrayDropDown';
import { Component, OnInit, EventEmitter, ViewChild, Output, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-tubo-cateter',
  templateUrl: './tubo-cateter.component.html',
  styleUrls: ['./tubo-cateter.component.css']
})
export class TuboCateterComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('ckEndotraqueal') ckEndotraqueal: MatCheckbox;
  @ViewChild('inMedindoEndo') inMedindoEndo: ElementRef;

  @ViewChild('ckTraque') ckTraque: MatCheckbox;

  @ViewChild('ckCateter') ckCateter: MatCheckbox;
  // @ViewChild('slLateralidadeCate') slLateralidadeCate: MatSelect;
  // @ViewChild('slVeiaCate') slVeiaCate: MatSelect;
  // @ViewChild('slExtremidade') slExtremidade: MatSelect;

  @ViewChildren('cateterChildren') cateterChildren: QueryList<any>;



  @ViewChild('ckDispositivo') ckDispositivo: MatCheckbox;
  @ViewChild('slLateralidadeDisp') slLateralidadeDisp: MatSelect;

  @ViewChild('ckMarcapasso') ckMarcapasso: MatCheckbox;
  @ViewChild('slLateralidadeMarc') slLateralidadeMarc: MatSelect;
  @ViewChild('slCamaraMarc') slCamaraMarc: MatSelect;

  @ViewChild('ckDreno') ckDreno: MatCheckbox;
  @ViewChild('ckDTDireito') ckDTDireito: MatCheckbox;
  @ViewChild('slLateralidadeDir') slLateralidadeDir: MatSelect;
  @ViewChild('slLocalizacaoDir') slLocalizacaoDir: MatSelect;

  @ViewChild('ckDTEsquerdo') ckDTEsquerdo: MatCheckbox;
  @ViewChild('slLateralidadeEsq') slLateralidadeEsq: MatSelect;
  @ViewChild('slLocalizacaoEsq') slLocalizacaoEsq: MatSelect;

  lateralidades: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerdo' },
    { value: 'direita', viewValue: 'Direito' }
  ];

  extremidades: ArrayDropDown[] = [
    { value: 'na veia cava superior', viewValue: 'Veia cava superior' },
    { value: 'no átrio direito', viewValue: 'Átrio direito' },
    { value: 'na transição da veia cava superior com o átrio direito', viewValue: 'Transição da cava superior com átrio direito' }
  ];

  veias: ArrayDropDown[] = [
    { value: 'veia subclávia', viewValue: 'Veia subclávia' },
    { value: 'veia jugular interna', viewValue: 'Veia jugular interna' }
  ];

  camaras: ArrayDropDown[] = [
    { value: 'esquerdas', viewValue: 'Esquerdas' },
    { value: 'direitas', viewValue: 'Direitas' }
  ];

  localizacoes: ArrayDropDown[] = [
    { value: 'na região apical do espaço pleural', viewValue: 'Região apical' },
    { value: 'no recesso cardiofrênico', viewValue: 'Recesso cardiofrênico' },
    { value: 'junto à base pulmonar', viewValue: 'Junto à base pulmonar' }
  ];

  espacoIntercostais: ArrayDropDown[] = [
    { value: '1°', viewValue: '1°' },
    { value: '2°', viewValue: '2°' },
    { value: '3°', viewValue: '3°' },
    { value: '4°', viewValue: '4°' },
    { value: '5°', viewValue: '5°' },
    { value: '6°', viewValue: '6°' },
    { value: '7°', viewValue: '7°' },
    { value: '8°', viewValue: '8°' },
    { value: '9°', viewValue: '9°' },
    { value: '10°', viewValue: '10°' },
    { value: '11°', viewValue: '11°' }
  ];

  lesoesForm: FormGroup;
  linha = 0;
  objeto: any;
  obj: any;
  objetofilho: any;

  showAndHideTerms: any;
  countLesoes = 0;
  unidadeMedida: string;

  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahEndo: false,
      sahCateter: false,
      sahDispositivo: false,
      sahMarcapasso: false,
      sahDreno: false,
      sahDTDireito: false,
      sahDTEsquerdo: false
    };
    this.ajustaArrayDinamico();
    this.lesoesForm = this._fb.group({
      cateter: this._fb.array([this.addCateterGrup()]),
    });
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  addCateter() {
    this.cateterArray.push(this.addCateterGrup());
    this.catchIDs();
  }

  removeCateter(index) {
    this.cateterArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get cateterArray() {
    return <FormArray>this.lesoesForm.get('cateter');
  }

  //Dinamico
  addCateterGrup() {
    return this._fb.group({
      lateralidade: [],
      veia: [],
      extremidade: []
    });
  }

  ajustaArrayDinamico() {

    if (this.ckCateter && !this.ckCateter.checked) {

      const arrayTotal = this.cateterArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.cateterArray.length - 1

        if (index !== 0) {
          this.cateterArray.removeAt(index);
        }
      }
      this.cateterArray.reset();
    }

  }

  clickAusente() {
    this.ajustaArrayDinamico();
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahEndo = false;
    this.showAndHideTerms.sahCateter = false;
    this.showAndHideTerms.sahDispositivo = false;
    this.showAndHideTerms.sahMarcapasso = false;
    this.showAndHideTerms.sahDreno = false;
    this.showAndHideTerms.sahDTDireito = false;
    this.showAndHideTerms.sahDTEsquerdo = false;
    this.catchIDs();
  }

  ClickPresente() {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahEndo = false;
    this.showAndHideTerms.sahCateter = false;
    this.showAndHideTerms.sahDispositivo = false;
    this.showAndHideTerms.sahMarcapasso = false;
    this.showAndHideTerms.sahDreno = false;
    this.showAndHideTerms.sahDTDireito = false;
    this.showAndHideTerms.sahDTEsquerdo = false;
    this.catchIDs();
  }

  clickEndo() {
    if (this.showAndHideTerms.sahEndo === false)
      this.showAndHideTerms.sahEndo = true;
    else
      this.showAndHideTerms.sahEndo = false;

    this.rdPresente.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickTraqui() {
    this.rdPresente.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickCateter() {
    if (this.showAndHideTerms.sahCateter === false)
      this.showAndHideTerms.sahCateter = true;
    else{
      this.showAndHideTerms.sahCateter = false;
      this.ajustaArrayDinamico();
    }

    this.rdPresente.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickDispositivo() {
    if (this.showAndHideTerms.sahDispositivo === false)
      this.showAndHideTerms.sahDispositivo = true;
    else
      this.showAndHideTerms.sahDispositivo = false;

    this.rdPresente.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickMarcapasso() {
    if (this.showAndHideTerms.sahMarcapasso === false)
      this.showAndHideTerms.sahMarcapasso = true;
    else
      this.showAndHideTerms.sahMarcapasso = false;

    this.rdPresente.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickDreno() {
    if (this.showAndHideTerms.sahDreno === false)
      this.showAndHideTerms.sahDreno = true;
    else
      this.showAndHideTerms.sahDreno = false;

    this.rdPresente.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickDTDireito() {
    if (this.showAndHideTerms.sahDTDireito === false)
      this.showAndHideTerms.sahDTDireito = true;
    else
      this.showAndHideTerms.sahDTDireito = false;

    this.catchIDs();
  }

  clickDTEsquerdo() {
    if (this.showAndHideTerms.sahDTEsquerdo === false)
      this.showAndHideTerms.sahDTEsquerdo = true;
    else
      this.showAndHideTerms.sahDTEsquerdo = false;

    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');
    this.linha = 0;

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente.checked) {

      if (this.ckEndotraqueal && this.ckEndotraqueal.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckEndotraqueal.id);
        if (this.inMedindoEndo && this.inMedindoEndo.nativeElement.value) {
          const objeto = { elemento: this.inMedindoEndo.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.ckTraque && this.ckTraque.checked) {
        if (this.countLesoes > 0) {
          this.countLesoes++;
          const objeto = { id: this.ckTraque.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          this.countLesoes++;
          this.termoSelecionado.emit(this.ckTraque.id);
        }
      }

      if (this.countLesoes > 0)
        this.emitCateterLesao();
      else
        this.emitCateter();

      if (this.countLesoes > 0)
        this.emitDispLesao();
      else
        this.emitDisp();

      if (this.countLesoes > 0)
        this.emitMarcapassoLesao();
      else
        this.emitMarcapasso();

      if (this.countLesoes > 0)
        this.emitDrenoDirLesao();
      else
        this.emitDrenoDir();

      if (this.countLesoes > 0)
        this.emitDrenoEsqLesao();
      else
        this.emitDrenoEsq();
    }

    this.listaCompleta.emit();
  }

  emitCateter() {
    let posicao = 0;

    if (this.ckCateter && this.ckCateter.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCateter.id);
      let primLesao = ((this.ckEndotraqueal && this.ckEndotraqueal.checked)||
                        (this.ckTraque && this.ckTraque.checked));
      this.cateterChildren.forEach(obj => {
        if (obj.nativeElement) {
          if (posicao < 3) {
            posicao++;
          } else {
            posicao = 1;
          }

          if (obj.nativeElement.value) {
            this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.obj);
          }
        } else {

          if (obj.id == 1483) {
            this.linha++;
          }
          if (obj.value) {
            this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.obj);
          }
        }
      });

      // if (this.slLateralidadeCate && this.slLateralidadeCate.value) {
      //   const objeto = { elemento: this.slLateralidadeCate, isPrimeiraLesao: true };
      //   this.termosGenericos.emit(objeto);
      // }
      // if (this.slVeiaCate && this.slVeiaCate.value) {
      //   const objeto = { elemento: this.slVeiaCate, isPrimeiraLesao: true };
      //   this.termosGenericos.emit(objeto);
      // }
    }
  }

  emitCateterLesao() {
    let posicao = 0;
    if (this.ckCateter && this.ckCateter.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCateter.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let primLesao = ((this.ckEndotraqueal && this.ckEndotraqueal.checked)||
                        (this.ckTraque && this.ckTraque.checked));

      this.cateterChildren.forEach(obj => {
        if (obj.nativeElement) {
          if (posicao < 3) {
            posicao++;
          } else {
            posicao = 1;
          }

          if (obj.nativeElement.value) {
            this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.obj);
          }
        } else {

          if (obj.id == 1483) {
            this.linha++;
          }

          if (obj.value) {
            this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.obj);
          }
        }
      });
    }
  }

  emitDisp() {
    if (this.ckDispositivo && this.ckDispositivo.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckDispositivo.id);

      if (this.slLateralidadeDisp && this.slLateralidadeDisp.value) {
        const objeto = { elemento: this.slLateralidadeDisp, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitDispLesao() {
    if (this.ckDispositivo && this.ckDispositivo.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckDispositivo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLateralidadeDisp && this.slLateralidadeDisp.value) {
        const objeto = { elemento: this.slLateralidadeDisp, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitMarcapasso() {
    if (this.ckMarcapasso && this.ckMarcapasso.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckMarcapasso.id);

      if (this.slLateralidadeMarc && this.slLateralidadeMarc.value) {
        const objeto = { elemento: this.slLateralidadeMarc, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slCamaraMarc && this.slCamaraMarc.value) {
        const objeto = { elemento: this.slCamaraMarc, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitMarcapassoLesao() {
    if (this.ckMarcapasso && this.ckMarcapasso.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckMarcapasso.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLateralidadeMarc && this.slLateralidadeMarc.value) {
        const objeto = { elemento: this.slLateralidadeMarc, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slCamaraMarc && this.slCamaraMarc.value) {
        const objeto = { elemento: this.slCamaraMarc, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitDrenoDir() {
    if (this.ckDreno && this.ckDreno.checked) {
      this.termoSelecionado.emit(this.ckDreno.id);

      if (this.ckDTDireito && this.ckDTDireito.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckDTDireito.id);

        if (this.slLateralidadeDir && this.slLateralidadeDir.value) {
          const objeto = { elemento: this.slLateralidadeDir, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLocalizacaoDir && this.slLocalizacaoDir.value) {
          const objeto = { elemento: this.slLocalizacaoDir, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitDrenoDirLesao() {
    if (this.ckDreno && this.ckDreno.checked) {
      const objeto = { id: this.ckDreno.id, cenario: this.countLesoes + 1};
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckDTDireito && this.ckDTDireito.checked) {
        this.countLesoes++;
        const objeto = { id: this.ckDTDireito.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.slLateralidadeDir && this.slLateralidadeDir.value) {
          const objeto = { elemento: this.slLateralidadeDir, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLocalizacaoDir && this.slLocalizacaoDir.value) {
          const objeto = { elemento: this.slLocalizacaoDir, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitDrenoEsq() {
    if (this.ckDreno && this.ckDreno.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckDreno.id);

      if (this.ckDTEsquerdo && this.ckDTEsquerdo.checked) {
        this.termoSelecionado.emit(this.ckDTEsquerdo.id);

        if (this.slLateralidadeEsq && this.slLateralidadeEsq.value) {
          const objeto = { elemento: this.slLateralidadeEsq, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLocalizacaoEsq && this.slLocalizacaoEsq.value) {
          const objeto = { elemento: this.slLocalizacaoEsq, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitDrenoEsqLesao() {
    if (this.ckDreno && this.ckDreno.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckDreno.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckDTEsquerdo && this.ckDTEsquerdo.checked) {
        const objeto = { id: this.ckDTEsquerdo.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.slLateralidadeEsq && this.slLateralidadeEsq.value) {
          const objeto = { elemento: this.slLateralidadeEsq, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLocalizacaoEsq && this.slLocalizacaoEsq.value) {
          const objeto = { elemento: this.slLocalizacaoEsq, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  verificaAlterado() {
    if (!this.ckEndotraqueal.checked &&
      !this.ckTraque.checked &&
      !this.ckCateter.checked &&
      !this.ckDispositivo.checked &&
      !this.ckMarcapasso.checked &&
      !this.ckDreno.checked) {
      // Marca normal
      this.rdAusente.checked = true;
    }
  }
}
