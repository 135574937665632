import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ObstetricoRelacaoIRValoresService {

  ipACM: string = '';
  ipAU: string = '';
  irACM: string = '';
  irAU: string = '';

  constructor() {
  }

  
}
