import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatCheckbox, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Calculadora } from 'src/app/utils/calculadora';

@Component({
  selector: 'app-mst-crescimento-fetal',
  templateUrl: './mst-crescimento-fetal.component.html',
  styleUrls: ['./mst-crescimento-fetal.component.css']
})
export class MstCrescimentoFetalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('inDBP') inDBP: ElementRef;
  @ViewChild('inDOF') inDOF: ElementRef;
  @ViewChild('inCC') inCC: ElementRef;
  @ViewChild('inCA') inCA: ElementRef;
  @ViewChild('inCUM') inCUM: ElementRef;
  @ViewChild('inCF') inCF: ElementRef;
  @ViewChild('inPeso') inPeso: ElementRef;

  showAndHideTerms: any;
  peso;
  ca;
  cf;
  dbp;
  cc;
  unidadeMedida: string;

  constructor(private _calc: Calculadora) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahFormulario: true
    }
    let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }
  clickSemPreenchimento(){
    if (this.showAndHideTerms.sahFormulario == false) {
      this.showAndHideTerms.sahFormulario = true;
    } else {
      this.showAndHideTerms.sahFormulario = false;
      this.termosGenericos.emit('');
      this.listaCompleta.emit();
    }
    this.catchIDs();
  }

  blurParametros() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if ((this.inDBP && this.inDBP.nativeElement.value) || (this.inCC && this.inCC.nativeElement.value)
      || (this.inCF && this.inCF.nativeElement.value) || (this.inCA && this.inCA.nativeElement.value)) {
      if (this.inPeso) {
        this.inPeso.nativeElement.value = this.calculaPeso().toFixed(2);
      }

    }

    if(this.inDBP && this.inDBP.nativeElement.value){
      const objeto = { elemento: this.inDBP.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if(this.inDOF && this.inDOF.nativeElement.value){
      const objeto = { elemento: this.inDOF.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if(this.inCC && this.inCC.nativeElement.value){
      const objeto = { elemento: this.inCC.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if(this.inCA && this.inCA.nativeElement.value){
      const objeto = { elemento: this.inCA.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if(this.inCUM && this.inCUM.nativeElement.value){
      const objeto = { elemento: this.inCUM.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if(this.inCF && this.inCF.nativeElement.value){
      const objeto = { elemento: this.inCF.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if(this.inPeso && this.inPeso.nativeElement.value){
      const objeto = { elemento: this.inPeso.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    //Logica placeholder peso
    if (this.inPeso.nativeElement.value) {
      const objetoElemento = { id: this.inPeso.nativeElement.id , value: this.inPeso.nativeElement.value };
      const objetoCriado = { elemento: objetoElemento };
      this.termosGenericos.emit(objetoCriado);
    } else {
      const objetoElemento = { id: this.inPeso.nativeElement.id, value: this.peso };
      const objetoCriado = { elemento: objetoElemento };
      this.termosGenericos.emit(objetoCriado);
    }

    let idadeBiometria = this._calc.calculadoraIdadeBiometria(this.cc, this.cf, this.dbp, this.ca);
    console.log(idadeBiometria);
    const objeto = { elemento: { id: '4777', value: idadeBiometria }, isPrimeiraLesao: true };
    this.termosGenericos.emit(objeto);


    this.listaCompleta.emit();
  }

  calculaPeso(): number {
    this.ca = this.inCA.nativeElement.value ? this.inCA.nativeElement.value : '0';
    this.cf = this.inCF.nativeElement.value ? this.inCF.nativeElement.value : '0';
    this.dbp = this.inDBP.nativeElement.value ? this.inDBP.nativeElement.value : '0';
    this.cc = this.inCC.nativeElement.value ? this.inCC.nativeElement.value : '0';
    return 10 ** (1.511 +
      (0.0436 * parseFloat(this.ca) / 10) +
      (0.1517 * parseFloat(this.cf) / 10) -
      (0.00321 * (parseFloat(this.ca) / 10 * parseFloat(this.cf) / 10)) +
      (0.0006923 * (parseFloat(this.dbp) / 10 * (parseFloat(this.cc) / 10))));
  }

  placeholderPeso(): string {
    if ((this.inDBP && this.inDBP.nativeElement.value) || (this.inCC && this.inCC.nativeElement.value)
      || (this.inCF && this.inCF.nativeElement.value) || (this.inCA && this.inCA.nativeElement.value)) {
      this.peso = this.calculaPeso().toFixed(2);
      return this.peso;
    } else
      this.peso = 'Peso Fetal';
    return this.peso;
  }
}
