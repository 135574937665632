import { RxAbdomeComponent } from './rx-abdome.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatTooltipModule,
  MatSlideToggleModule} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from 'src/app/utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';
import { TecnicaComponent } from './TermosComponents/tecnica/tecnica.component';
import { ArcaboucoOsseoComponent } from './TermosComponents/arcabouco-osseo/arcabouco-osseo.component';
import { AlcasIntestinaisComponent } from './TermosComponents/alcas-intestinais/alcas-intestinais.component';
import { CalcificacoesComponent } from './TermosComponents/calcificacoes/calcificacoes.component';
import { LinhasPeritoneaisComponent } from './TermosComponents/linhas-peritoneais/linhas-peritoneais.component';
import { DispositivosComponent } from './TermosComponents/dispositivos/dispositivos.component';
import { PneumonperitonioComponent } from './TermosComponents/pneumonperitonio/pneumonperitonio.component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule
  ],
  exports: [
    RxAbdomeComponent
  ],
  declarations: [
    RxAbdomeComponent,
    TecnicaComponent,
    ArcaboucoOsseoComponent,
    AlcasIntestinaisComponent,
    CalcificacoesComponent,
    LinhasPeritoneaisComponent,
    DispositivosComponent,
    PneumonperitonioComponent
  ],
  entryComponents: [
  ]
})
export class RxAbdomeModule { }
