import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-fratura-costela',
  templateUrl: './fratura-costela.component.html',
  styleUrls: ['./fratura-costela.component.css']
})
export class FraturaCostelaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;
  @ViewChild('ckFraturaDir') ckFraturaDir: MatCheckbox;
  @ViewChild('ckFraturaEsq') ckFraturaEsq: MatCheckbox;
  @ViewChild('slCostelaDir') slCostelaDir: MatSelect;
  @ViewChild('slCostelaEsq') slCostelaEsq: MatSelect;


  costelas: ArrayDropDown[] = [
    { value: '1ª', viewValue: '1ª' },
    { value: '2ª', viewValue: '2ª' },
    { value: '3ª', viewValue: '3ª' },
    { value: '4ª', viewValue: '4ª' },
    { value: '5ª', viewValue: '5ª' },
    { value: '6ª', viewValue: '6ª' },
    { value: '7ª', viewValue: '7ª' },
    { value: '8ª', viewValue: '8ª' },
    { value: '9ª', viewValue: '9ª' },
    { value: '10ª', viewValue: '10ª' },
    { value: '11ª', viewValue: '11ª' },
    { value: '12ª', viewValue: '12ª' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahFraturaDir: false,
      sahFraturaEsq: false
    };
  }

  changeSelect(){
    this.catchIDs();
  }


  clickNormal(){
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahFraturaDir = false;
    this.showAndHideTerms.sahFraturaEsq = false;

    this.catchIDs();
  }

  clickAlterado(){
    this.showAndHideTerms.sahAlterado = true;

    this.catchIDs();
  }

  clickFraturaDir(){
    if (this.ckFraturaDir && this.ckFraturaDir.checked) {
      this.showAndHideTerms.sahFraturaDir = true;
    } else {
      this.showAndHideTerms.sahFraturaDir = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickFraturaEsq(){
    if (this.ckFraturaEsq && this.ckFraturaEsq.checked) {
      this.showAndHideTerms.sahFraturaEsq = true;
    } else {
      this.showAndHideTerms.sahFraturaEsq = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado && this.rdAlterado.checked) {
      if (this.ckFraturaDir && this.ckFraturaDir.checked) {
        this.termoSelecionado.emit(this.ckFraturaDir.id);

        if (this.slCostelaDir && this.slCostelaDir.value) {
          const objeto = { elemento: this.slCostelaDir, isPrimeiraLesao: true }
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.ckFraturaEsq && this.ckFraturaEsq.checked) {
        this.termoSelecionado.emit(this.ckFraturaEsq.id);

        if (this.slCostelaEsq && this.slCostelaEsq.value) {
          const objeto = { elemento: this.slCostelaEsq, isPrimeiraLesao: true }
          this.termosGenericos.emit(objeto);
        }
      }

    }



    this.listaCompleta.emit();
  }

  mudaAlterado() {
    if (
       (this.ckFraturaDir && this.ckFraturaDir.checked) ||
       (this.ckFraturaEsq && this.ckFraturaEsq.checked)
    ) {
      this.rdAlterado.checked = true;
      this.rdNormal.checked = false;
    } else {
      this.rdNormal.checked = true;
      this.rdAlterado.checked = false;
    }
  } 


}
