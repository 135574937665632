import { ArrayDropDown } from './../../../../../models/Helper/ArrayDropDown';
import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-corpos-vertebrais',
  templateUrl: './corpos-vertebrais.component.html',
  styleUrls: ['./corpos-vertebrais.component.css']
})
export class CorposVertebraisComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;


  @ViewChild('ckOsteofito') ckOsteofito: MatCheckbox;
  @ViewChild('rdOsteofitoMulti') rdOsteofitoMulti: MatRadioButton;

  @ViewChild('ckFratura') ckFratura: MatCheckbox;
  @ViewChild('ckAcunhamento') ckAcunhamento: MatCheckbox;

  @ViewChild('rdFraturaAltura') rdFraturaAltura: MatRadioButton;
  @ViewChild('slNivelFratura') slNivelFratura: MatSelect;
  @ViewChild('inMedida') inMedida: ElementRef;

  nivelFratura: ArrayDropDown[] = [
    { value: 'T12', viewValue: 'T12' },
    { value: 'L1', viewValue: 'L1' },
    { value: 'L2', viewValue: 'L2' },
    { value: 'L3', viewValue: 'L3' },
    { value: 'L4', viewValue: 'L4' },
    { value: 'L5', viewValue: 'L5' },
  ];


  showAndHideTerms = {
    sahOsteofito: false,
    sahFratura: false,
    sahFraturaAltura: false,
  };

  countLesoes = 0;

  osteofitoFormGroup: FormGroup;
  fraturaFormGroup: FormGroup;

  osteofito: any;
  fratura: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {

    this.iniciaFormOsteofito();
    this.iniciaFormFratura();

    this.osteofito = [
      { id: 'T12', value: 'T12', name: 'T12' },
      { id: 'L1', value: 'L1', name: 'L1' },
      { id: 'L2', value: 'L2', name: 'L2' },
      { id: 'L3', value: 'L3', name: 'L3' },
      { id: 'L4', value: 'L4', name: 'L4' },
      { id: 'L5', value: 'L5', name: 'L5' },
    ];

    this.fratura = [
      { id: 'T12', value: 'T12', name: 'T12' },
      { id: 'L1', value: 'L1', name: 'L1' },
      { id: 'L2', value: 'L2', name: 'L2' },
      { id: 'L3', value: 'L3', name: 'L3' },
      { id: 'L4', value: 'L4', name: 'L4' },
      { id: 'L5', value: 'L5', name: 'L5' },
    ];
  }

  clickNormal(){
    this.showAndHideTerms.sahOsteofito = false;
    this.showAndHideTerms.sahFratura = false;
    this.showAndHideTerms.sahFraturaAltura = false;
    this.ckOsteofito.checked = false;
    this.rdOsteofitoMulti.checked = false;
    this.ckFratura.checked = false;
    this.rdFraturaAltura.checked = false;
    this.catchIDs()
  }

  clickOsteofito(){
    if (this.ckOsteofito && this.ckOsteofito.checked) {
      this.showAndHideTerms.sahOsteofito = true;
    } else {
      this.showAndHideTerms.sahOsteofito = false;
    }
    this.rdOsteofitoMulti.checked = false;
    this.rdNormal.checked = false;
    this.checkNormal();
    this.catchIDs();
  }

  clickOsteofitoMulti(){
    this.ckOsteofito.checked = false;
    this.showAndHideTerms.sahOsteofito = false;
    this.rdNormal.checked = false;
    this.checkNormal();
    this.catchIDs();
  }

  clickFratura(){
    if (this.ckFratura && this.ckFratura.checked) {
      this.showAndHideTerms.sahFratura = true;
    } else {
      this.showAndHideTerms.sahFratura = false;
    }
    this.rdFraturaAltura.checked = false;
    this.showAndHideTerms.sahFraturaAltura = false;
    this.rdNormal.checked = false;
    this.checkNormal();
    this.catchIDs();
  }

  clickAcunhamento(){
    this.catchIDs();
  }

  clickFraturaAltura(){
    if (this.rdFraturaAltura && this.rdFraturaAltura.checked) {
      this.showAndHideTerms.sahFraturaAltura = true;
    } else {
      this.showAndHideTerms.sahFraturaAltura = false;
    }
    this.ckFratura.checked = false;
    this.showAndHideTerms.sahFratura = false
    this.rdNormal.checked = false;
    this.checkNormal();
    this.catchIDs();
  }

  clickCheckboxOsteofito(event){
    let osteofito= <FormArray>this.osteofitoFormGroup.get('osteofito') as FormArray;

    if (event.checked) {
      osteofito.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iOsteofito = osteofito.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      osteofito.removeAt(iOsteofito);
    }

    this.catchIDs();
  }

  clickCheckboxFratura(event){
    let fratura= <FormArray>this.fraturaFormGroup.get('fratura') as FormArray;

    if (event.checked) {
      fratura.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iFratura = fratura.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      fratura.removeAt(iFratura);
    }

    this.catchIDs();
  }

  checkNormal(){
    if (this.ckOsteofito.checked || this.rdOsteofitoMulti.checked || this.ckFratura.checked || this.rdFraturaAltura.checked) {
      this.rdNormal.checked = false;
    } else {
      this.rdNormal.checked = true;
    }
    this.catchIDs();
  }

  iniciaFormOsteofito(){
    this.osteofitoFormGroup = this.fb.group({
      osteofito: this.fb.array([])
    });
  }

  iniciaFormFratura(){
    this.fraturaFormGroup = this.fb.group({
      fratura: this.fb.array([])
    });
  }

  blurMedindo(){
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (!this.ckOsteofito.checked) {
      this.iniciaFormOsteofito();
    }
    if (!this.ckFratura.checked) {
      this.iniciaFormFratura();
    }

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }  else if (!this.rdNormal.checked) {
      this.emitOsteofito();

      // Fratura
      if (this.countLesoes > 0) {
        this.emitFraturaLesao();
      } else {
        this.emitFraturaSimples();
      }

      // Fratura com Redução de Altura
      if (this.countLesoes > 0) {
        this.emitFraturaReducaoLesao();
      } else {
        this.emitFraturaReducaoSimples();
      }

    }
    this.listaCompleta.emit();
  }

  emitOsteofito(){
    if(this.ckOsteofito && this.ckOsteofito.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckOsteofito.id);

      let posicao = this.osteofitoFormGroup.value.osteofito;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5292, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5292, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }

    if(this.rdOsteofitoMulti.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdOsteofitoMulti.id);
    }
  }

  emitFraturaLesao(){
    if(this.ckFratura.checked){
      this.countLesoes++;
      const objeto = { id: this.ckFratura.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let posicao: any = this.fraturaFormGroup.value.fratura;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if(posicao.length > 0){
        if(posicao.length == 1){
          let objeto = {id: 5295, value: posicao};
          let elemento = {elemento: objeto, isPrimeiraLesao: false};
          this.termosGenericos.emit(elemento);

          if(this.ckAcunhamento.checked){
            let objetoMultiplo= { id:this.ckAcunhamento.id, value:this.ckAcunhamento.value };
            let elementoMultiplo ={elemento:objetoMultiplo, isPrimeiraLesao: false};
            this.termosGenericos.emit(elementoMultiplo);
          }
        }else if(posicao.length > 1){
          let objeto = {id: 5296, value: posicaoTratada};
          let elemento = {elemento: objeto, isPrimeiraLesao: false};
          this.termosGenericos.emit(elemento);

          if(this.ckAcunhamento.checked){
            let objetoMultiplo= {id:this.ckAcunhamento.id, value:this.ckAcunhamento.value};
            let elementoMultiplo ={elemento:objetoMultiplo, isPrimeiraLesao: false};
            this.termosGenericos.emit(elementoMultiplo);
          }
        }
      }
    }
  }

  emitFraturaSimples(){
    if(this.ckFratura.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFratura.id);

      let posicao: any = this.fraturaFormGroup.value.fratura;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if(posicao.length > 0){
        if(posicao.length == 1){

          let objeto = {id: 5295, value: posicao};
          let elemento = {elemento: objeto, isPrimeiraLesao: true};
          this.termosGenericos.emit(elemento);

          if(this.ckAcunhamento.checked){
            let objetoMultiplo= {id:this.ckAcunhamento.id, value:this.ckAcunhamento.value };
            let elementoMultiplo ={elemento:objetoMultiplo, isPrimeiraLesao: true};
            this.termosGenericos.emit(elementoMultiplo);
          }
        }else if(posicao.length > 1){
          let objeto = {id: 5296, value: posicaoTratada};
          let elemento = {elemento: objeto, isPrimeiraLesao: true};
          this.termosGenericos.emit(elemento);

          if(this.ckAcunhamento.checked){
            let objetoMultiplo= {id:this.ckAcunhamento.id, value:this.ckAcunhamento.value };
            let elementoMultiplo ={elemento:objetoMultiplo, isPrimeiraLesao: true};
            this.termosGenericos.emit(elementoMultiplo);
          }
        }
      }
    }
  }

  emitFraturaReducaoLesao(){
    if(this.rdFraturaAltura.checked){
      this.countLesoes++;
      const objeto = { id: this.rdFraturaAltura.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

    if(this.slNivelFratura && this.slNivelFratura.value){
      const objeto = {elemento: this.slNivelFratura, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }
    if (this.inMedida && this.inMedida.nativeElement.value) {
      const objeto = { elemento: this.inMedida.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
    }
  }
  }

  emitFraturaReducaoSimples(){
    if(this.rdFraturaAltura.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdFraturaAltura.id);

      if(this.slNivelFratura && this.slNivelFratura.value){
        const objeto = {elemento: this.slNivelFratura, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
      if (this.inMedida && this.inMedida.nativeElement.value != undefined) {
        const objeto = { elemento: this.inMedida.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  replaceVirgula(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e ';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }

}
