import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton } from '@angular/material';


@Component({
  selector: 'app-tabela-indireto',
  templateUrl: './tabela-indireto.component.html',
  styleUrls: ['./tabela-indireto.component.css']
})
export class TabelaIndiretoComponent implements OnInit {

  public showAndHideTerms: any;


  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaMedida = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();


  @ViewChild('rdnaocitar') rdnaocitar: MatRadioButton;
  @ViewChild('rdcitar') rdcitar: MatRadioButton;
  @ViewChild('ACCRRSDir') ACCRRSDir: ElementRef;
  @ViewChild('ACCRRSEsq') ACCRRSEsq: ElementRef;
  @ViewChild('ACCAceleracaoSegmentarDir') ACCAceleracaoSegmentarDir: ElementRef;
  @ViewChild('ACCAceleracaoSegmentarEsq') ACCAceleracaoSegmentarEsq: ElementRef;
  @ViewChild('ACCTempoDeAceleracaoDir') ACCTempoDeAceleracaoDir: ElementRef;
  @ViewChild('ACCTempoDeAceleracaoEsq') ACCTempoDeAceleracaoEsq: ElementRef;
  @ViewChild('ACCIrDir') ACCIrDir: ElementRef;
  @ViewChild('ACCIrEsq') ACCIrEsq: ElementRef;

  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCitar: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }


  public ClickNaoCitar(): any {
    this.showAndHideTerms.sahCitar = false;
    this.catchIDs();
  }

  public ClickCitar(): any {
    this.showAndHideTerms.sahCitar = true;
    this.catchIDs();
  }

  public blurMedida(): any {
    this.catchIDs();
  }

  public vericaMedidas(): any {

    if (this.ACCRRSDir !== undefined &&
      this.ACCRRSDir.nativeElement.value && this.rdcitar.checked) {

      const element = { value: this.ACCRRSDir.nativeElement.value, id: this.ACCRRSDir.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (this.rdnaocitar.checked && this.ACCRRSDir.nativeElement.value) {
      const element = { value: 0, id: this.ACCRRSDir.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (!this.ACCRRSDir.nativeElement.value) {
      const element = { value: 0, id: this.ACCRRSDir.nativeElement.id };
      this.enviaMedida.emit(element);
    }

    if (this.ACCRRSEsq !== undefined &&
      this.ACCRRSEsq.nativeElement.value && this.rdcitar.checked) {

      const element = { value: this.ACCRRSEsq.nativeElement.value, id: this.ACCRRSEsq.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (this.rdnaocitar.checked && this.ACCRRSEsq.nativeElement.value) {
      const element = { value: 0, id: this.ACCRRSEsq.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (!this.ACCRRSEsq.nativeElement.value) {
      const element = { value: 0, id: this.ACCRRSEsq.nativeElement.id };
      this.enviaMedida.emit(element);
    }

    if (this.ACCAceleracaoSegmentarDir !== undefined &&
      this.ACCAceleracaoSegmentarDir.nativeElement.value && this.rdcitar.checked) {

      const element = { value: this.ACCAceleracaoSegmentarDir.nativeElement.value, id: this.ACCAceleracaoSegmentarDir.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (this.rdnaocitar.checked && this.ACCAceleracaoSegmentarDir.nativeElement.value) {
      const element = { value: 0, id: this.ACCAceleracaoSegmentarDir.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (!this.ACCAceleracaoSegmentarDir.nativeElement.value) {
      const element = { value: 0, id: this.ACCAceleracaoSegmentarDir.nativeElement.id };
      this.enviaMedida.emit(element);
    }

    if (this.ACCAceleracaoSegmentarEsq !== undefined &&
      this.ACCAceleracaoSegmentarEsq.nativeElement.value && this.rdcitar.checked) {

      const element = { value: this.ACCAceleracaoSegmentarEsq.nativeElement.value, id: this.ACCAceleracaoSegmentarEsq.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (this.rdnaocitar.checked && this.ACCAceleracaoSegmentarEsq.nativeElement.value) {
      const element = { value: 0, id: this.ACCAceleracaoSegmentarEsq.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (!this.ACCAceleracaoSegmentarEsq.nativeElement.value) {
      const element = { value: 0, id: this.ACCAceleracaoSegmentarEsq.nativeElement.id };
      this.enviaMedida.emit(element);
    }

    if (this.ACCTempoDeAceleracaoDir !== undefined &&
      this.ACCTempoDeAceleracaoDir.nativeElement.value && this.rdcitar.checked) {

      const element = { value: this.ACCTempoDeAceleracaoDir.nativeElement.value, id: this.ACCTempoDeAceleracaoDir.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (this.rdnaocitar.checked && this.ACCTempoDeAceleracaoDir.nativeElement.value) {
      const element = { value: 0, id: this.ACCTempoDeAceleracaoDir.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (!this.ACCTempoDeAceleracaoDir.nativeElement.value) {
      const element = { value: 0, id: this.ACCTempoDeAceleracaoDir.nativeElement.id };
      this.enviaMedida.emit(element);
    }

    if (this.ACCTempoDeAceleracaoEsq !== undefined &&
      this.ACCTempoDeAceleracaoEsq.nativeElement.value && this.rdcitar.checked) {

      const element = { value: this.ACCTempoDeAceleracaoEsq.nativeElement.value, id: this.ACCTempoDeAceleracaoEsq.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (this.rdnaocitar.checked && this.ACCTempoDeAceleracaoEsq.nativeElement.value) {
      const element = { value: 0, id: this.ACCTempoDeAceleracaoEsq.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (this.rdnaocitar.checked && !this.ACCTempoDeAceleracaoEsq.nativeElement.value) {
      const element = { value: 0, id: this.ACCTempoDeAceleracaoEsq.nativeElement.id };
      this.enviaMedida.emit(element);
    }  else if (!this.ACCTempoDeAceleracaoEsq.nativeElement.value) {
      const element = { value: 0, id: this.ACCTempoDeAceleracaoEsq.nativeElement.id };
      this.enviaMedida.emit(element);
    }

    if (this.ACCIrDir !== undefined &&
      this.ACCIrDir.nativeElement.value && this.rdcitar.checked) {

      const element = { value: this.ACCIrDir.nativeElement.value, id: this.ACCIrDir.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (this.rdnaocitar.checked && this.ACCIrDir.nativeElement.value) {
      const element = { value: 0, id: this.ACCIrDir.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (this.rdnaocitar.checked && !this.ACCIrDir.nativeElement.value) {
      const element = { value: 0, id: this.ACCIrDir.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (!this.ACCIrDir.nativeElement.value) {
      const element = { value: 0, id: this.ACCIrDir.nativeElement.id };
      this.enviaMedida.emit(element);
    }


    if (this.ACCIrEsq !== undefined &&
      this.ACCIrEsq.nativeElement.value && this.rdcitar.checked) {

      const element = { value: this.ACCIrEsq.nativeElement.value, id: this.ACCIrEsq.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (this.rdnaocitar.checked && this.ACCIrEsq.nativeElement.value) {
      const element = { value: 0, id: this.ACCIrEsq.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (this.rdnaocitar.checked && !this.ACCIrEsq.nativeElement.value) {
      const element = { value: 0, id: this.ACCIrEsq.nativeElement.id };
      this.enviaMedida.emit(element);
    }  else if (!this.ACCIrEsq.nativeElement.value) {
      const element = { value: 0, id: this.ACCIrEsq.nativeElement.id };
      this.enviaMedida.emit(element);
    }

  }

  public catchIDs(): any {
    // this.termoSelecionado.emit('Clean');

    if (this.rdnaocitar.checked) {
      // this.termoSelecionado.emit(this.rdnaocitar.id);
    }

    this.atualizaTexto.emit();
    this.vericaMedidas();
    this.listaCompleta.emit();

  }
}
