import { Component, ComponentFactoryResolver, ComponentRef, ElementRef, Input, OnInit, ViewChild,ViewContainerRef } from '@angular/core';
import { MatDialog, MatSelect } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { UserService } from '../services/user.service';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { ListaExamesService } from 'src/app/services/lista-exames.service';
import { LaudosService } from '../services/laudos.service.spec';
import { Term } from '../models/BO/Term';
import { UserHasTextTermService } from '../services/userHasTextTerm-service.service';
import { UserHasTextTerm } from '../models/BO/UserHasTextTerm';
import { Router } from '@angular/router';
import { PenisComponent } from '../laudos/us/modo-b/penis/penis.component';
import { DataTermService } from '../services/dataTerm.service';
import { AbdomeTotalComponent } from '../laudos/us/modo-b/abdome-total/abdome-total.component';
import { MamaComponent } from '../laudos/us/modo-b/mama/mama.component';
import { TransvaginalComponent } from '../laudos/us/modo-b/transvaginal/transvaginal.component';
import { CervicalComponent } from '../laudos/us/modo-b/cervical/cervical.component';
import { ProstataComponent } from '../laudos/us/modo-b/prostata/prostata.component';
import { TesticuloComponent } from '../laudos/us/modo-b/testiculo/testiculo.component';
import { TesticuloDopplerComponent } from '../laudos/us/doppler/testiculo-doppler/testiculo-doppler.component';
import { ParedeAbdominalComponent } from '../laudos/us/modo-b/parede-abdominal/parede-abdominal.component';
import { PartesMolesComponent } from '../laudos/us/modo-b/partes-moles/partes-moles.component';
import { TireoideDopplerComponent } from '../laudos/us/doppler/tireoide-doppler/tireoide-doppler.component';
import { CarotidasComponent } from '../laudos/us/doppler/carotidas/carotidas.component';
import { AbdomeDopplerComponent } from '../laudos/us/doppler/abdome-doppler/abdome-doppler.component';
import { PunhoComponent } from '../laudos/us/musculoesqueletico/punho/punho.component';
import { OmbroComponent } from '../laudos/us/musculoesqueletico/ombro/ombro.component';
import { JoelhoComponent } from '../laudos/us/musculoesqueletico/joelho/joelho.component';
import { CotoveloComponent } from '../laudos/us/musculoesqueletico/cotovelo/cotovelo.component';
import { QuadrilComponent } from '../laudos/us/musculoesqueletico/quadril/quadril.component';
import { MusculosTendoesComponent } from '../laudos/us/musculoesqueletico/musculos-tendoes/musculos-tendoes.component';
import { TornozeloComponent } from '../laudos/us/musculoesqueletico/tornozelo/tornozelo.component';
import { ToraxComponent } from '../laudos/tc/torax/torax.component';
import { PeComponent } from '../laudos/us/musculoesqueletico/pe/pe.component';
import { MaoComponent } from '../laudos/us/musculoesqueletico/mao/mao.component';
import { ToraxUsComponent } from '../laudos/us/modo-b/torax-us/torax-us.component';
import { TransfontanelarComponent } from '../laudos/us/modo-b/transfontanelar/transfontanelar.component';
import { HipocondrioDireitoComponent } from '../laudos/us/modo-b/hipocondrio-direito/hipocondrio-direito.component';
import { RinsViasComponent } from '../laudos/us/modo-b/rins-vias/rins-vias.component';
import { AbdomeSuperiorComponent } from '../laudos/us/modo-b/abdome-superior/abdome-superior.component';
import { PelvicoComponent } from '../laudos/us/modo-b/pelvico/pelvico.component';
import { GlandulasSalivaresComponent } from '../laudos/us/modo-b/glandulas-salivares/glandulas-salivares.component';
import { TireoideComponent } from '../laudos/us/modo-b/tireoide/tireoide.component';
import { RegiaoInguinalComponent } from '../laudos/us/modo-b/regiao-inguinal/regiao-inguinal.component';
import { AbdomeSuperiorDopplerComponent } from '../laudos/us/doppler/abdome-superior-doppler/abdome-superior-doppler.component';
import { ToraxRsnaComponent } from '../laudos/tc/torax-rsna/torax-rsna.component';
import { ArteriAortaComponent } from '../laudos/us/doppler/arteri-aorta/arteri-aorta.component';
import { TransvPrimeiroTrimestreComponent } from '../laudos/us/obstetrico/transv-primeiro-trimestre/transv-primeiro-trimestre.component';
import { TransvSegTercTrimestreComponent } from '../laudos/us/obstetrico/transv-seg-terc-trimestre/transv-seg-terc-trimestre.component';
import { TransvSegTercDopplerComponent } from '../laudos/us/obstetrico/transv-seg-terc-doppler/transv-seg-terc-doppler.component';
import { VenosoMembroComponent } from '../laudos/us/doppler/venoso-membro/venoso-membro.component';
import { AortaIliacaComponent } from '../laudos/us/doppler/aorta-iliaca/aorta-iliaca.component';
import { ArterialMembroSuperiorComponent } from '../laudos/us/doppler/arterial-membro-superior/arterial-membro-superior.component';
import { VarizesComponent } from '../laudos/us/doppler/varizes/varizes.component';
import { VenosoMembroSuperiorComponent } from '../laudos/us/doppler/venoso-membro-superior/venoso-membro-superior.component';
import { ArteriaDoMembroInferiorComponent } from '../laudos/us/doppler/arteria-do-membro-inferior/arteria-do-membro-inferior.component';
import { TransvDopplerComponent } from '../laudos/us/doppler/transv-doppler/transv-doppler.component';
import { CervicalDopplerComponent } from '../laudos/us/doppler/cervical-doppler/cervical-doppler.component';
import { MamografiaComponent } from '../laudos/mg/mamografico/mamografia/mamografia.component';
import { QuadrilInfantilComponent } from '../laudos/us/musculoesqueletico/quadril-infantil/quadril-infantil.component';
import { CervicometriaComponent } from '../laudos/us/obstetrico/cervicometria/cervicometria.component';
import { AxilasComponent } from '../laudos/us/modo-b/axilas/axilas.component';
import { CranioComponent } from '../laudos/tc/cranio/cranio.component';
import { UsgMorfologicaPrimeiroTrimestreComponent } from '../laudos/us/obstetrico/usg-morfologica-primeiro-trimestre/usg-morfologica-primeiro-trimestre.component';
import { MorfologicoSegundoTrimestreComponent } from '../laudos/us/obstetrico/morfologico-segundo-trimestre/morfologico-segundo-trimestre.component';
import { UsgMorfoPrimDopplerComponent } from '../laudos/us/obstetrico/usg-morfo-prim-doppler/usg-morfo-prim-doppler.component';
import { OmbroBilateralComponent } from '../laudos/us/musculoesqueletico/ombro-bilateral/ombro-bilateral.component';
import { PunhoBilateralComponent } from '../laudos/us/musculoesqueletico/punho-bilateral/punho-bilateral.component';
import { PeBilateralComponent } from '../laudos/us/musculoesqueletico/pe-bilateral/pe-bilateral.component';
import { JoelhoBilateralComponent } from '../laudos/us/musculoesqueletico/joelho-bilateral/joelho-bilateral.component';
import { MaoBilateralComponent } from '../laudos/us/musculoesqueletico/mao-bilateral/mao-bilateral.component';
import { TornozeloBilateralComponent } from '../laudos/us/musculoesqueletico/tornozelo-bilateral/tornozelo-bilateral.component';
import { MusculosTendoesBilateralComponent } from '../laudos/us/musculoesqueletico/musculos-tendoes-bilateral/musculos-tendoes-bilateral.component';
import { CotoveloBilateralComponent } from '../laudos/us/musculoesqueletico/cotovelo-bilateral/cotovelo-bilateral.component';
import { QuadrilBilateralComponent } from '../laudos/us/musculoesqueletico/quadril-bilateral/quadril-bilateral.component';
import { QuadrilInfantilBilateralComponent } from '../laudos/us/musculoesqueletico/quadril-infantil-bilateral/quadril-infantil-bilateral.component';
import { MamaDopplerComponent } from '../laudos/us/doppler/mama-doppler/mama-doppler.component';
import { AbdomeTotalPelvicoComponent } from '../laudos/us/modo-b/abdome-total-pelvico/abdome-total-pelvico.component';
import { AbdomeTotalProstataComponent } from '../laudos/us/modo-b/abdome-total-prostata/abdome-total-prostata.component';
import { RinsPelvicoComponent } from '../laudos/us/modo-b/rins-pelvico/rins-pelvico.component';
import { RinsProstataComponent } from '../laudos/us/modo-b/rins-prostata/rins-prostata.component';
import { MamaMasculinaComponent } from '../laudos/us/modo-b/mama-masculina/mama-masculina.component';
import { AbdomeTotalRefatoradoComponent } from '../laudos/us/modo-b/abdome-total-refatorado/abdome-total-refatorado.component';
import { MorfologicoSegundoTrimestreDopplerComponent } from '../laudos/us/doppler/morfologico-segundo-trimestre-doppler/morfologico-segundo-trimestre-doppler.component';
import { RxArcosComponent } from '../laudos/rx/rx-arcos/rx-arcos.component';
import { RxAtmComponent } from '../laudos/rx/rx-atm/rx-atm.component';
import { RxColunaCervicalComponent } from '../laudos/rx/rx-coluna-cervical/rx-coluna-cervical.component';
import { RxColunaToracicaComponent } from '../laudos/rx/rx-coluna-toracica/rx-coluna-toracica.component';
import { RxCranioComponent } from '../laudos/rx/rx-cranio/rx-cranio.component';
import { RxToraxComponent } from '../laudos/rx/rx-torax/rx-torax.component';
import { RxSacroComponent } from '../laudos/rx/rx-sacro/rx-sacro.component';
import { SeiosDaFaceComponent } from '../laudos/rx/seios-da-face/seios-da-face.component';
import { PenisDopplerComponent } from '../laudos/us/doppler/penis-doppler/penis-doppler.component';
import { environment } from 'src/environments/environment';
import { User } from '../auth/user';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-altera-laudo',
  templateUrl: './altera-laudo.component.html',
  styleUrls: ['./altera-laudo.component.css', '../app.component.css']
})

export class AlteraLaudoComponent implements OnInit {
  @ViewChild('textTermo') textTermo: ElementRef;
  @ViewChild('textConclusao') textConclusao: ElementRef;
  @ViewChild('slLaudo') slLaudo: ElementRef;
  @ViewChild('slBloco') slBloco: ElementRef;
  @ViewChild('slTermo') slTermo: MatSelect;
  @ViewChild('inInputFilter') inInputFilter: ElementRef;
  @ViewChild('dynamicInsert', { read: ViewContainerRef }) dynamicInsert: ViewContainerRef;

  termSelected: string[] = [];

  allExames: ArrayDropDown[];
  allBlocos: ArrayDropDown[];
  allTermos: ArrayDropDown[];
  allComponents:any=[
    {id: 1,  component: AbdomeTotalComponent},
    {id: 2,  component: MamaComponent},
    {id: 3, component: TransvaginalComponent},
    {id: 4, component: CervicalComponent},
    {id: 5, component: ProstataComponent},
    {id: 6, component: TesticuloComponent},
    {id: 7, component: TesticuloDopplerComponent},
    {id: 8, component: ParedeAbdominalComponent},
    {id: 9, component: PartesMolesComponent},
    {id: 10, component: TireoideDopplerComponent},
    {id: 11, component: CarotidasComponent},
    {id: 12, component: AbdomeDopplerComponent},
    {id: 13, component: PunhoComponent},
    {id: 14, component: OmbroComponent},
    {id: 15, component: JoelhoComponent},
    {id: 16, component: CotoveloComponent},
    {id: 17, component: QuadrilComponent},
    {id: 18, component: MusculosTendoesComponent},
    {id: 19, component: TornozeloComponent},
    {id: 20, component: ToraxComponent},
    {id: 21, component: PeComponent},
    {id: 22, component: MaoComponent},
    {id: 23, component: ToraxUsComponent},
    {id: 24, component: TransfontanelarComponent},
    {id: 25, component: HipocondrioDireitoComponent},
    {id: 26, component: RinsViasComponent},
    {id: 27, component: AbdomeSuperiorComponent},
    {id: 28, component: PelvicoComponent},
    {id: 29, component: GlandulasSalivaresComponent},
    {id: 30, component: TireoideComponent},
    {id: 31, component: RegiaoInguinalComponent},
    {id: 32, component: AbdomeSuperiorDopplerComponent},
    {id: 33, component: ToraxRsnaComponent},
    {id: 34, component: ArteriAortaComponent},
    {id: 35, component: TransvPrimeiroTrimestreComponent},
    {id: 36, component: TransvSegTercTrimestreComponent},
    {id: 37, component: TransvSegTercDopplerComponent},
    {id: 38, component: VenosoMembroComponent},
    {id: 39, component: AortaIliacaComponent},
    {id: 41, component: ArterialMembroSuperiorComponent},
    {id: 42, component: VenosoMembroSuperiorComponent},
    {id: 43, component: ArteriaDoMembroInferiorComponent},
    {id: 44, component: VarizesComponent},
    {id: 45, component: TransvDopplerComponent},
    {id: 46, component: CervicalDopplerComponent},
    {id: 47, component: MamografiaComponent},
    {id: 48, component: QuadrilInfantilComponent},
    {id: 49, component: PenisComponent},
    {id: 50, component: CervicometriaComponent},
    {id: 51, component: AxilasComponent},
    {id: 52, component: CranioComponent},
    {id: 53, component: UsgMorfologicaPrimeiroTrimestreComponent},
    {id: 54, component: MorfologicoSegundoTrimestreComponent},
    {id: 55, component: UsgMorfoPrimDopplerComponent},
    {id: 56, component: OmbroBilateralComponent},
    {id: 57, component: PunhoBilateralComponent},
    {id: 58, component: PeBilateralComponent},
    {id: 59, component: JoelhoBilateralComponent},
    {id: 60, component: MaoBilateralComponent},
    {id: 61, component: TornozeloBilateralComponent},
    {id: 62, component: MusculosTendoesBilateralComponent},
    {id: 63, component: CotoveloBilateralComponent},
    {id: 64, component: QuadrilBilateralComponent},
    {id: 65, component: QuadrilInfantilBilateralComponent},
    {id: 66, component: MamaDopplerComponent},
    {id: 67, component: AbdomeTotalPelvicoComponent},
    {id: 68, component: AbdomeTotalProstataComponent},
    {id: 69, component: RinsPelvicoComponent},
    {id: 70, component: RinsProstataComponent},
    {id: 71, component: MamaMasculinaComponent},
    {id: 72, component: AbdomeTotalRefatoradoComponent},
    {id: 73, component: MorfologicoSegundoTrimestreDopplerComponent},
    {id: 74, component: SeiosDaFaceComponent},
    {id: 75, component: RxCranioComponent},
    {id: 76, component: RxToraxComponent},
    {id: 82, component: RxSacroComponent},
    {id: 78, component: RxArcosComponent},
    {id: 83, component: RxAtmComponent},
    {id: 81, component: RxColunaCervicalComponent},
    {id: 80, component: RxColunaToracicaComponent},
    {id: 79, component: PenisDopplerComponent}
  ]

  exames: ArrayDropDown[] = [];

  blocos: ArrayDropDown[] = [];

  termos: ArrayDropDown[] = [];

  selectedOptions: any;
  typingTimer;
  doneTypingInterval = 5000;
  componentRef: ComponentRef<any>;
  termosOriginal;
  conclusaoOriginal;
  userId;
  companieId;
  textTermID;
  textTermoLaudoOriginal;
  textTermoConclusaoOriginal;
  cenariosUser: UserHasTextTerm[] = [];
  clicked: boolean = false;
  disableBloco: boolean = true;
  disableTermo: boolean = true;
  textsConclusion: Array<UserHasTextTerm>;
  public loading: boolean = true;
  sahPenis=false;
  container!: ViewContainerRef;
  componentFactory:any;
  lastTermAndBlock: any;
  lastLaudoOpened: any;
  editarMascara:boolean = false;
  editarTexto:boolean = true;
  maskAtual;
  medicalReportAtual:number=0;
  htmlContent: any;
  hasMaskRegistered: boolean;
  maskAtualId: any;
  laudoSelecionado: any;

  constructor(private toastr: ToastrManager,
    private _userService: UserService,
    private route: Router,
    private _listaExames: ListaExamesService,
    private _laudoService: LaudosService,
    private _userHasTextTermService: UserHasTextTermService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private dataTerm:DataTermService,
    private _user: User,
    private _http:HttpClient
    )
    { }
  createComponent(component){
      this.dynamicInsert.clear();
      this.componentFactory=this.componentFactoryResolver.resolveComponentFactory(component);
      this.componentRef = this.dynamicInsert.createComponent(this.componentFactory,0);
      this.componentRef.instance.editLaudoMode=true;
      let bloco = this.componentRef.instance.blocos[0];
      this.componentRef.instance.selecionar(bloco);
      this.componentRef.instance.subtituloBloco=bloco.titulo;
      this.componentRef.instance.subtitulo=bloco.titulo;
  }

  changeLaudo(event) {
    this.lastLaudoOpened=event;
    let idDoExame= event.value.value;
    let componentView = this.allComponents.find(item=> item.id===idDoExame);
    if(componentView)
    this.createComponent(componentView.component)
  }

  changeLaudoMask(event){
    this.laudoSelecionado=event;
    this.hasMaskRegistered= false;
    this.medicalReportAtual = event.value.value;
    const userId = +localStorage.getItem('userID');
    const companyID = this._user.getUserCompanyID();
    const _urlGetByUser = environment.nLaudoApi + "UserHasMask/GetMaskByUser/" + +userId + '/' + +this.medicalReportAtual;
    const _url = environment.nLaudoApi + "MedicalReport/BuscaMedicalReportMascara/" + this.medicalReportAtual + '/' + companyID + '/' + userId + '/' + 1;

    this._http.get<any>(_urlGetByUser).toPromise().then((result)=>{
      if(result[0] !== undefined){
        this.maskAtualId=result[0].id;
        this.hasMaskRegistered= true;
        this.maskAtual = result[0].userMask;
        this.htmlContent= this.maskAtual;

      }else{

        this.hasMaskRegistered= false;
        this._http.get<any>(_url).toPromise().then((result) =>{
        this.maskAtual = result.mask;
        this.htmlContent= this.maskAtual;
        })

      }
    })
  }

  searchByResearch() {
    this._laudoService
      .buscaConclusaoPorPesquisa(this.inInputFilter.nativeElement.value)
      .subscribe((result: UserHasTextTerm[] | any) => {
        this.textsConclusion = result;
      }, error => {
        console.log(error);
      });

    this.loading = false;

    var complete:HTMLElement = document.querySelector('.mat-autocomplete-panel.mat-autocomplete-visible');
    if (complete != null)
      complete.style.display = 'block';
    else
      document.getElementById("autoComplete").click();
  }

  compareByObjectValueInsertOnArray(option: { value: string }, insertedTerm: { value: string }) {
    return option === insertedTerm;
  }

  changeTermo(termAndBlock) {
    this.lastTermAndBlock = termAndBlock;
    let arrOfNum = termAndBlock.listTerm.map(str => {
      return Number(str);
    })
    let term: Term;
    term = new Term();
    term.userID = this.userId;
    term.companyID = this.companieId;
    term.blockID = termAndBlock.blockID;
    term.listTermID = arrOfNum;
    term.injuries = [];
    this._laudoService.buscaConclusaoPorTermo(term).subscribe(res => {
      if (res.length > 0) {
        this.textTermID = res[0].id
        this.textTermoConclusaoOriginal = res[0].conclusion;
        this.textTermoLaudoOriginal = res[0].text;
        this.textTermo.nativeElement.value = res[0].text;
        this.textConclusao.nativeElement.value = res[0].conclusion;
        if (this.cenariosUser.map(r => r.textTermID).includes(res[0].id)) {
          const term = this.cenariosUser.filter(r => r.textTermID == res[0].id);
          this.textTermID = term[0].textTermID
          this.textTermoConclusaoOriginal = term[0].conclusion;
          this.textTermoLaudoOriginal = term[0].text;
          this.textTermo.nativeElement.value = term[0].text;
          this.textConclusao.nativeElement.value = term[0].conclusion;
        }
      }
      else {
        this.textTermo.nativeElement.value = '';
        this.textConclusao.nativeElement.value = '';
      }
    });
  }

  ngOnInit() {
    this.userId = +localStorage.getItem('userID');
    this.companieId = +localStorage.getItem('companyID');
    this.dataTerm.termAndBlock.subscribe(termAndBlock=>{
      this.changeTermo(termAndBlock);
    },)
    this._userHasTextTermService.getUserHasTextTermByUser(this.userId).subscribe(
      resp => {
        this.cenariosUser = resp;
      }
    );
    this._listaExames.getLaudos().subscribe(res => {
      if (res.length > 0) {
        this.exames = [];
        res.forEach(element => {
          this.exames.push({ value: element.id, viewValue: element.title })
        });
      }
      this.exames.sort((a,b)=>a.viewValue.localeCompare(b.viewValue));
      this.allExames = this.exames;
    });
    const str = "teste de qualquer[CoisA] para tentar The [rain] in SPAIN stays mainly in the [plain] depois";
    this.termosOriginal = this.getTermosSubstituir(str);
    this.loading = false;
  }

  getTermosSubstituir(texto: string) {
    let termos = null;
    if(texto){
      termos = texto.match(/\[(.*?)\]/g);
    }
    return termos;
  }

  hasPipe(texto: string) {
    return (texto.indexOf('|') > -1);
  }

  validaTermos(termoLaudoOriginal: Array<string>,
    termoLaudoAlterado: Array<string>,
    termoConclusaoOriginal: Array<string>,
    termoConclusaoAlterado: Array<string>) {
    //Valida o pipe |
    if(termoLaudoOriginal){
      if (this.hasPipe(this.textTermoLaudoOriginal) && !this.hasPipe(this.textTermo.nativeElement.value))
        return false;
    }
    if(termoConclusaoOriginal){
      if (this.hasPipe(this.textTermoConclusaoOriginal) && !this.hasPipe(this.textConclusao.nativeElement.value))
      return false;
    }
    //Valida os termos
    let count = 0
    if (termoLaudoOriginal != null && termoLaudoOriginal != undefined) {
      if(termoLaudoAlterado != null && termoLaudoAlterado != undefined){
        for (let i = 0; i < termoLaudoOriginal.length; i++) {
          if (termoLaudoOriginal[i] == termoLaudoAlterado[i]) {
            count++
          }
        }
      }
    }
    if (termoConclusaoOriginal != null && termoConclusaoOriginal != undefined) {
      for (let i = 0; i < termoConclusaoOriginal.length; i++) {
        if (termoConclusaoOriginal[i] == termoConclusaoAlterado[i]) {
          count++
        }
      }
    }
    if (termoConclusaoOriginal && termoLaudoOriginal) {
      if ((termoLaudoOriginal.length + termoConclusaoOriginal.length) == count) {
        return true;
      } else {
        return false;
      }
    } else if (termoLaudoOriginal) {
      if ((termoLaudoOriginal.length) == count) {
        return true;
      } else {
        return false;
      }
    } else if (termoConclusaoOriginal) {
      if ((termoConclusaoOriginal.length) == count) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  onSubmit() {
    this.clicked=true;
    let termoLaudoAlterado;
    if(this.textTermo.nativeElement.value){
      termoLaudoAlterado = this.getTermosSubstituir(this.textTermo.nativeElement.value);
    }

    let termoLaudoOriginal;
    if(this.textTermoLaudoOriginal){
      termoLaudoOriginal= this.getTermosSubstituir(this.textTermoLaudoOriginal);
    }

    let termoConclusaoAlterado;
    if(this.textConclusao.nativeElement.value){
      termoConclusaoAlterado = this.getTermosSubstituir(this.textConclusao.nativeElement.value);
    }

    let termoConclusaoOriginal;
    if(this.textTermoConclusaoOriginal){
      termoConclusaoOriginal = this.getTermosSubstituir(this.textTermoConclusaoOriginal);
    }

    let result = this.validaTermos(termoLaudoOriginal, termoLaudoAlterado, termoConclusaoOriginal, termoConclusaoAlterado);
    if (result) {
      this.gravaUserHasTextTerm();
    }
    else {
      this.toastr.errorToastr('Você não pode modificar os dados entre [Colchetes] nem retirar os pipes "|"', 'Falhou!',
        {
          position: 'top-right',
          toastTimeout: 5000
        });
      this.clicked=false;
    }
  }

  onSubmitMask(){
    let laudoCompleto = document.getElementById('editor');

    let convertToString = laudoCompleto.outerHTML.toString();
    let laudoCompletoSlice=convertToString.slice(163,-6);

    const userId = +localStorage.getItem('userID');
    const _urlPost = environment.nLaudoApi + 'UserHasMask/AddMaskByUser';
    const _urlPut = environment.nLaudoApi + 'UserHasMask/UpdateMaskByUser'+ '/' + userId;

    let bodyToPost = {
    MedicalReportTypeID: this.medicalReportAtual,
    UserID: userId,
    UserMask:laudoCompletoSlice
    }

    let bodyToPut ={
    UserMask:laudoCompletoSlice,
    ID:this.maskAtualId
    }

    //Verifica se ele deletou algum espaçamento e não corrigiu
    if(this.verificarAlteracaoEspacamento(laudoCompletoSlice)){
      if(!this.hasMaskRegistered){
         this._http.post(_urlPost, bodyToPost).toPromise().then(()=>
         this.toastr.successToastr('Máscara adicionada com sucesso!','Sucesso!',{
        position: 'top-right',
         toastTimeout: 5000
         }));

      }else{

         this._http.put(_urlPut, bodyToPut).toPromise().then(()=>
         this.toastr.successToastr('Máscara atualizada com sucesso!','Sucesso!',{
         position: 'top-right',
         toastTimeout: 5000
         }));
      }
    }
  }

  gravaUserHasTextTerm() {
    let usuario = {
      "textTermID": this.textTermID,
      "userID": Number(localStorage.getItem('userID')),
      "text": this.textTermo.nativeElement.value,
      "conclusion": this.textConclusao.nativeElement.value,
      "active": null,
      "createdOn": null,
      "updatedOn": null,
      "removedOn": null,
    }
    if (this.cenariosUser.map(r => r.textTermID).includes(this.textTermID)) {
      let term = this.cenariosUser.filter(r => r.textTermID == this.textTermID)[0]
      term.text = this.textTermo.nativeElement.value;
      term.conclusion = this.textConclusao.nativeElement.value;
      this._userHasTextTermService.updateUserHasTextTerm(localStorage.getItem('userID'), term);

    } else {
      this._userHasTextTermService.gravarUserHasTextTerm(usuario);
    }

    this.toastr.successToastr('Cenário salvo, as alterações realizadas serão aplicadas no próximo login!', 'Sucesso!',
      {
        position: 'top-right',
        toastTimeout: 5000
      })
      this.clicked=false;
  }

  habilitarEdicaoMascara(){
    if(this.editarTexto){
      this.editarTexto=false;
      this.editarMascara=true;
    }
  }

  habilitarEdicaoCenario(){
    if(this.editarMascara){
      this.editarTexto=true;
      this.editarMascara=false;
    }
  }

  verificarAlteracaoEspacamento(string){
    let mask = this.maskAtual.split('');
    let maskModificada = string.split('');
    let countMaskAlterada=0;
    let countMaskOriginal=0;
    let countPipeAlterada=0
    let countPipeOriginal=0
    mask.forEach((letra)=>{
      if(letra === '<' || letra === '>'){
        countMaskOriginal++
      }else
      if(letra == '|'){
        countPipeOriginal++
      }
    })
    maskModificada.forEach((letra)=>{
      if(letra === '<' || letra === '>'){
        countMaskAlterada++
      }else
      if(letra == '|'){
        countPipeAlterada++
      }
    })
    if(countMaskAlterada < countMaskOriginal){
      this.toastr.errorToastr('Não exclua nenhum espaçamento, ou caracteres especiais.','Falhou!',{
        position: 'top-right',
        toastTimeout: 5000
      });
      this.htmlContent = '';
      this.changeLaudoMask(this.laudoSelecionado);

    }else if(countPipeAlterada !== countPipeOriginal){
      this.toastr.errorToastr('Não exclua nenhum espaçamento, ou caracteres especiais.','Falhou!',{
        position: 'top-right',
        toastTimeout: 5000
      });
      this.htmlContent = '';
      this.changeLaudoMask(this.laudoSelecionado);
    }else{
      return true
    }
  }
}
