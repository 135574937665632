import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validacoes } from '../../../../utils/validacoes';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { TermosDinamicos } from 'src/app/models/Helper/TermosDinamicos';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { DataTermService } from 'src/app/services/dataTerm.service';
import { ControladorFrasesLaudoService } from 'src/app/services/ControladorFrasesLaudo.service';

export interface DescricaoConclusao {
  value: string;
  textTermID: number;
  blockID: number;
}

export interface TermosGenericos {
  value: string;
  selected: boolean;
  termID: number;
  term: string;
}


@Component({
  selector: 'app-parede-abdominal',
  templateUrl: './parede-abdominal.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./parede-abdominal.component.css']
})
export class ParedeAbdominalComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[872],blockID:47},
    {listTerm:[876],blockID:48},
    {listTerm:[931],blockID:49},
    {listTerm:[834],blockID:46},
    {listTerm:[964],blockID:50},
    {listTerm:[965],blockID:51},
    {listTerm:[4182],blockID:275},
  ]

  descricoes: DescricaoConclusao[] = [
    { value: 'Avaliação de abaulamento.', blockID: 47, textTermID: 0 },
    { value: 'Pele e tecido celular subcutâneo de espessura e ecogenicidade preservadas.', blockID: 48, textTermID: 0 },
    { value: '', blockID: 51, textTermID: 0 },
    { value: 'Planos musculares sem alterações significativas.', blockID: 49, textTermID: 0 },
    { value: 'Não há sinais hérnias ou protrusão de conteúdo abdominal no repouso ou durante manobras de Valsalva.', blockID: 46, textTermID: 0 },
    { value: '', blockID: 50, textTermID: 0 },
    { value: '', blockID: 275, textTermID: 0 },
    { value: 'Não foram caracterizadas coleções organizadas.', blockID: 0, textTermID: 0 },
    { value: 'Ausência de coleções organizadas ou formações expansivas no presente estudo.</br>Ausência de coleções organizadas ou formações expansivas no presente estudo.', blockID: 0, textTermID: 0 }
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Exame sem alterações significativas.', blockID: 0, textTermID: 0 },
    { value: '', blockID: 46, textTermID: 0 },
    { value: '', blockID: 47, textTermID: 0 },
    { value: '', blockID: 48, textTermID: 0 },
    { value: '', blockID: 49, textTermID: 0 },
    { value: '', blockID: 50, textTermID: 0 },
    { value: '', blockID: 51, textTermID: 0 },
    { value: '', blockID: 52, textTermID: 0 },
    { value: '', blockID: 275, textTermID: 0 },
  ];

  genericTermsTecidoSubcutaneo: TermosGenericos[] = [
    { value: '', selected: false, termID: 880, term: '[Medida]' },
    { value: '', selected: false, termID: 881, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 883, term: '[Plano]' },
    { value: '', selected: false, termID: 884, term: '[Contornos]' },
    { value: '', selected: false, termID: 885, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 886, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 887, term: '[Medida]' },
    { value: '', selected: false, termID: 888, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 891, term: '[Paredes]' },
    { value: '', selected: false, termID: 892, term: '[Conteúdo]' },
    { value: '', selected: false, termID: 893, term: '[Medidas]' },
    { value: '', selected: false, termID: 894, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 896, term: '[Plano]' },
    { value: '', selected: false, termID: 897, term: '[Paredes]' },
    { value: '', selected: false, termID: 898, term: '[Conteúdo]' },
    { value: '', selected: false, termID: 899, term: '[Medidas]' },
    { value: '', selected: false, termID: 900, term: '[Volume]' },
    { value: '', selected: false, termID: 901, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 904, term: '[Plano]' },
    { value: '', selected: false, termID: 905, term: '[Medidas]' },
    { value: '', selected: false, termID: 906, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 908, term: '[Plano]' },
    { value: '', selected: false, termID: 909, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 910, term: '[Limites]' },
    { value: '', selected: false, termID: 911, term: '[Sombra acústica posterior]' },
    { value: '', selected: false, termID: 912, term: '[Medidas]' },
    { value: '', selected: false, termID: 913, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 915, term: '[Plano]' },
    { value: '', selected: false, termID: 916, term: '[Loculação]' },
    { value: '', selected: false, termID: 917, term: '[Paredes]' },
    { value: '', selected: false, termID: 918, term: '[Conteúdo]' },
    { value: '', selected: false, termID: 919, term: '[Septos]' },
    { value: '', selected: false, termID: 920, term: '[Medidas]' },
    { value: '', selected: false, termID: 921, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 922, term: '[Fluxo vascular]' }
  ];

  genericTermsPlanoMuscular: TermosGenericos[] = [
    { value: '', selected: false, termID: 933, term: '[Medida da diástase]' },
    { value: '', selected: false, termID: 934, term: '[Distância da cicatriz umbilical]' },
    { value: '', selected: false, termID: 935, term: '[Localização da cicatriz umbilical]' },
    { value: '', selected: false, termID: 937, term: '[Paredes]' },
    { value: '', selected: false, termID: 938, term: '[Conteúdo]' },
    { value: '', selected: false, termID: 939, term: '[Medidas]' },
    { value: '', selected: false, termID: 940, term: '[Volume]' },
    { value: '', selected: false, termID: 941, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 944, term: '[Medidas]' },
    { value: '', selected: false, termID: 945, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 4170, term: '[Distancia reto]' }
  ];

  genericTermsHerniaInguinal: TermosGenericos[] = [
    { value: '', selected: false, termID: 947, term: '[Lado]' },
    { value: '', selected: false, termID: 948, term: '[Conteúdo abdominal]' },
    { value: '', selected: false, termID: 949, term: '[Medida do conteúdo herniário]' },
    { value: '', selected: false, termID: 950, term: '[Vasos epigástricos inferiores]' },
    { value: '', selected: false, termID: 954, term: '[Lado Esc]' },
    { value: '', selected: false, termID: 955, term: '[Conteúdo abdominal Esc]' },
    { value: '', selected: false, termID: 956, term: '[Anel]' },
    { value: '', selected: false, termID: 959, term: '[Lado Fem]' },
    { value: '', selected: false, termID: 960, term: '[Conteúdo abdominal Fem]' },
    { value: '', selected: false, termID: 961, term: '[Medida do conteúdo herniário]' },
    { value: '', selected: false, termID: 962, term: '[Colo]' }
  ];

  genericTermsPreOperatorio: TermosGenericos[] = [
    { value: '', selected: false, termID: 967, term: '[Espessura subcutâneo acima]' },
    { value: '', selected: false, termID: 968, term: '[Distância subcutâneo acima]' },
    { value: '', selected: false, termID: 969, term: '[Espessura subcutâneo abaixo]' },
    { value: '', selected: false, termID: 970, term: '[Distância subcutâneo abaixo]' },
    { value: '', selected: false, termID: 971, term: '[Espessura pré-peritoneal acima]' },
    { value: '', selected: false, termID: 972, term: '[Distância pré-peritoneal acima]' },
    { value: '', selected: false, termID: 973, term: '[Espessura pré-peritoneal abaixo]' },
    { value: '', selected: false, termID: 974, term: '[Distância pré-peritoneal abaixo]' }
  ];

  genericTermsHerniaParede: TermosGenericos[] = [
    { value: '', selected: false, termID: 836, term: '[Colo umbilical]' },
    { value: '', selected: false, termID: 837, term: '[Conteúdo abdominal umbilical]' },
    { value: '', selected: false, termID: 838, term: '[Observado umbilical]' },
    { value: '', selected: false, termID: 840, term: '[Medida do conteúdo herniário umbilical]' },
    { value: '', selected: false, termID: 843, term: '[Colo paramediana]' },
    { value: '', selected: false, termID: 844, term: '[Lado paramediana]' },
    { value: '', selected: false, termID: 845, term: '[Conteúdo abdominal paramediana]' },
    { value: '', selected: false, termID: 848, term: '[Medida do conteúdo herniário paramediana]' },
    { value: '', selected: false, termID: 846, term: '[Observado paramediana]' },
    { value: '', selected: false, termID: 851, term: '[Colo epigástrica]' },
    { value: '', selected: false, termID: 852, term: '[Conteúdo abdominal epigástrica]' },
    { value: '', selected: false, termID: 855, term: '[Medida do conteúdo herniário epigástrica]' },
    { value: '', selected: false, termID: 853, term: '[Observado epigástrica]' },
    { value: '', selected: false, termID: 858, term: '[Lado semilunar]' },
    { value: '', selected: false, termID: 859, term: '[Colo semilunar]' },
    { value: '', selected: false, termID: 860, term: '[Conteúdo abdominal semilunar]' },
    { value: '', selected: false, termID: 863, term: '[Medida do conteúdo herniário semilunar]' },
    { value: '', selected: false, termID: 861, term: '[Observado semilunar]' },
    { value: '', selected: false, termID: 866, term: '[Colo incisional]' },
    { value: '', selected: false, termID: 867, term: '[Conteúdo abdominal incisional]' },
    { value: '', selected: false, termID: 870, term: '[Medida do conteúdo herniário incisional]' },
    { value: '', selected: false, termID: 868, term: '[Observado incisional]' },
    { value: '', selected: false, termID: 4177, term: '[Colo Supra-umbilical]' },
    { value: '', selected: false, termID: 4178, term: '[Distancia cicatriz umbilical]' },
    { value: '', selected: false, termID: 4179, term: '[Conteudo abdominal]' },
    { value: '', selected: false, termID: 4180, term: '[Observado]' },
    { value: '', selected: false, termID: 4181, term: '[Medida conteudo herniario]' }
  ];

  genericLinfonodo: TermosGenericos[] = [
    { value: '', selected: false, termID: 4184, term: '[Lado]' },
    { value: '', selected: false, termID: 4185, term: '[Medidas]' },
    { value: '', selected: false, termID: 4190, term: '[Lado]' },
    { value: '', selected: false, termID: 4191, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 4192, term: '[Medidas]' }
  ];

  achadosAdTexto = '';
  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();

  cabecalhoTexto = '';
  rodapeTexto = '';
  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  public dinamicLesions: TermosDinamicos[] = [];
  public dinamicLesion: any;
  public lesionRow = 1;
  public dinamicconclussion: TermosDinamicos[] = this.dinamicLesions;

  cabecalho = false;
  indicacao = true;
  pelePlanoSubcutaneo = false;
  planoMuscular = false;
  hernia = false;
  herniaInguinal = false;
  preOperatorio = false;
  achadosTexto = false;
  linfonodo = false;
  rodape = false;

  medicalReportTypeID = 8;
  indicacaoID = 47;
  pelePlanoSubcutaneoID = 48;
  planoMuscularID = 49;
  herniaID = 46;
  herniaInguinalID = 50;
  preOperatorioID = 51;
  linfonodoID = 275;
  public showForm: Boolean = true;
  public switchFormReport = true;
  unidadeMedida: string;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  editLaudoMode: boolean = false;
  naoContemColecaoPeleTecido: boolean = true;
  naoContemColecaoPlano: boolean = true;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _dialog: MatDialog,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _config: ConfigLaudoService,
    public _addText: AddText,
    private dataService:DataTermService,
    private _copyPaste: CopyPaste,
    private _controladorFrases:ControladorFrasesLaudoService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("parede-abdominalAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Indicação'
    this.subtituloBloco = 'Preencha o campo indicação';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    //  });
    const listBlocks = [
      this.indicacaoID,
      this.pelePlanoSubcutaneoID,
      this.planoMuscularID,
      this.herniaID,
      this.herniaInguinalID,
      this.preOperatorioID,
      this.linfonodoID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "parede-abdominalMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "paradeAbdominal");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-parede-abdominal');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-parede-abdominal');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("parede-abdominalAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.showForm = false;
    this.cabecalhoTexto = "";
    this.achadosAdTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  blocos = [
    {
      titulo: 'Informações',
      id: 'cabecalho',
      name: 'cabecalho',
      selected: false,
    },
    {
      titulo: 'Indicação',
      id: this.indicacaoID,
      name: 'indicacao',
      subtitulo: 'Preencha o campo indicação',
      selected: true,
    },
    {
      titulo: 'Pele e tecido subcutâneo',
      id: this.pelePlanoSubcutaneoID,
      name: 'tecido-subcutaneo',
      selected: false
    },
    {
      titulo: 'Pré-operatório',
      id: this.preOperatorioID,
      name: 'pre-operatorio',
      selected: false
    },
    {
      titulo: 'Plano muscular',
      id: this.planoMuscularID,
      name: 'plano-muscular',
      selected: false
    },
    {
      titulo: 'Hérnia da parede abdominal',
      id: this.herniaID,
      name: 'hernia',
      selected: false
    },
    {
      titulo: 'Hérnia da região inguinal',
      id: this.herniaInguinalID,
      name: 'hernia-inguinal',
      selected: false
    },
    {
      titulo: 'Linfonodos',
      id: this.linfonodoID,
      name: 'linfonodos',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados_adicionais',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }


    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === this.indicacaoID);
    this.pelePlanoSubcutaneo = (itemSelecionado.id === this.pelePlanoSubcutaneoID);
    this.planoMuscular = (itemSelecionado.id === this.planoMuscularID);
    this.hernia = (itemSelecionado.id === this.herniaID);
    this.herniaInguinal = (itemSelecionado.id === this.herniaInguinalID);
    this.preOperatorio = (itemSelecionado.id === this.preOperatorioID);
    this.linfonodo = (itemSelecionado.id === this.linfonodoID);
    this.achadosTexto = (itemSelecionado.id === 'achados_adicionais');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;
    this.lesionRow = 1;

    this.genericTermsTecidoSubcutaneo = [
      { value: '', selected: false, termID: 880, term: '[Medida]' },
      { value: '', selected: false, termID: 881, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 883, term: '[Plano]' },
      { value: '', selected: false, termID: 884, term: '[Contornos]' },
      { value: '', selected: false, termID: 885, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 886, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 887, term: '[Medida]' },
      { value: '', selected: false, termID: 888, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 891, term: '[Paredes]' },
      { value: '', selected: false, termID: 892, term: '[Conteúdo]' },
      { value: '', selected: false, termID: 893, term: '[Medidas]' },
      { value: '', selected: false, termID: 894, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 896, term: '[Plano]' },
      { value: '', selected: false, termID: 897, term: '[Paredes]' },
      { value: '', selected: false, termID: 898, term: '[Conteúdo]' },
      { value: '', selected: false, termID: 899, term: '[Medidas]' },
      { value: '', selected: false, termID: 900, term: '[Volume]' },
      { value: '', selected: false, termID: 901, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 904, term: '[Plano]' },
      { value: '', selected: false, termID: 905, term: '[Medidas]' },
      { value: '', selected: false, termID: 906, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 908, term: '[Plano]' },
      { value: '', selected: false, termID: 909, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 910, term: '[Limites]' },
      { value: '', selected: false, termID: 911, term: '[Sombra acústica posterior]' },
      { value: '', selected: false, termID: 912, term: '[Medidas]' },
      { value: '', selected: false, termID: 913, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 915, term: '[Plano]' },
      { value: '', selected: false, termID: 916, term: '[Loculação]' },
      { value: '', selected: false, termID: 917, term: '[Paredes]' },
      { value: '', selected: false, termID: 918, term: '[Conteúdo]' },
      { value: '', selected: false, termID: 919, term: '[Septos]' },
      { value: '', selected: false, termID: 920, term: '[Medidas]' },
      { value: '', selected: false, termID: 921, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 922, term: '[Fluxo vascular]' }
    ];

    this.genericTermsPlanoMuscular = [
      { value: '', selected: false, termID: 933, term: '[Medida da diástase]' },
      { value: '', selected: false, termID: 934, term: '[Distância da cicatriz umbilical]' },
      { value: '', selected: false, termID: 935, term: '[Localização da cicatriz umbilical]' },
      { value: '', selected: false, termID: 937, term: '[Paredes]' },
      { value: '', selected: false, termID: 938, term: '[Conteúdo]' },
      { value: '', selected: false, termID: 939, term: '[Medidas]' },
      { value: '', selected: false, termID: 940, term: '[Volume]' },
      { value: '', selected: false, termID: 941, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 944, term: '[Medidas]' },
      { value: '', selected: false, termID: 945, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 4170, term: '[Distancia reto]' }
    ];

    this.genericTermsHerniaInguinal = [
      { value: '', selected: false, termID: 947, term: '[Lado]' },
      { value: '', selected: false, termID: 948, term: '[Conteúdo abdominal]' },
      { value: '', selected: false, termID: 949, term: '[Medida do conteúdo herniário]' },
      { value: '', selected: false, termID: 950, term: '[Vasos epigástricos inferiores]' },
      { value: '', selected: false, termID: 954, term: '[Lado Esc]' },
      { value: '', selected: false, termID: 955, term: '[Conteúdo abdominal Esc]' },
      { value: '', selected: false, termID: 956, term: '[Anel]' },
      { value: '', selected: false, termID: 959, term: '[Lado Fem]' },
      { value: '', selected: false, termID: 960, term: '[Conteúdo abdominal Fem]' },
      { value: '', selected: false, termID: 961, term: '[Medida do conteúdo herniário]' },
      { value: '', selected: false, termID: 962, term: '[Colo]' }
    ];

    this.genericTermsPreOperatorio = [
      { value: '', selected: false, termID: 967, term: '[Espessura subcutâneo acima]' },
      { value: '', selected: false, termID: 968, term: '[Distância subcutâneo acima]' },
      { value: '', selected: false, termID: 969, term: '[Espessura subcutâneo abaixo]' },
      { value: '', selected: false, termID: 970, term: '[Distância subcutâneo abaixo]' },
      { value: '', selected: false, termID: 971, term: '[Espessura pré-peritoneal acima]' },
      { value: '', selected: false, termID: 972, term: '[Distância pré-peritoneal acima]' },
      { value: '', selected: false, termID: 973, term: '[Espessura pré-peritoneal abaixo]' },
      { value: '', selected: false, termID: 974, term: '[Distância pré-peritoneal abaixo]' }
    ];

    this.genericTermsHerniaParede = [
      { value: '', selected: false, termID: 836, term: '[Colo umbilical]' },
      { value: '', selected: false, termID: 837, term: '[Conteúdo abdominal umbilical]' },
      { value: '', selected: false, termID: 838, term: '[Observado umbilical]' },
      { value: '', selected: false, termID: 840, term: '[Medida do conteúdo herniário umbilical]' },
      { value: '', selected: false, termID: 843, term: '[Colo paramediana]' },
      { value: '', selected: false, termID: 844, term: '[Lado paramediana]' },
      { value: '', selected: false, termID: 845, term: '[Conteúdo abdominal paramediana]' },
      { value: '', selected: false, termID: 848, term: '[Medida do conteúdo herniário paramediana]' },
      { value: '', selected: false, termID: 846, term: '[Observado paramediana]' },
      { value: '', selected: false, termID: 851, term: '[Colo epigástrica]' },
      { value: '', selected: false, termID: 852, term: '[Conteúdo abdominal epigástrica]' },
      { value: '', selected: false, termID: 855, term: '[Medida do conteúdo herniário epigástrica]' },
      { value: '', selected: false, termID: 853, term: '[Observado epigástrica]' },
      { value: '', selected: false, termID: 858, term: '[Lado semilunar]' },
      { value: '', selected: false, termID: 859, term: '[Colo semilunar]' },
      { value: '', selected: false, termID: 860, term: '[Conteúdo abdominal semilunar]' },
      { value: '', selected: false, termID: 863, term: '[Medida do conteúdo herniário semilunar]' },
      { value: '', selected: false, termID: 861, term: '[Observado semilunar]' },
      { value: '', selected: false, termID: 866, term: '[Colo incisional]' },
      { value: '', selected: false, termID: 867, term: '[Conteúdo abdominal incisional]' },
      { value: '', selected: false, termID: 870, term: '[Medida do conteúdo herniário incisional]' },
      { value: '', selected: false, termID: 868, term: '[Observado incisional]' },
      { value: '', selected: false, termID: 4177, term: '[Colo Supra-umbilical]' },
      { value: '', selected: false, termID: 4178, term: '[Distancia cicatriz umbilical]' },
      { value: '', selected: false, termID: 4179, term: '[Conteudo abdominal]' },
      { value: '', selected: false, termID: 4180, term: '[Observado]' },
      { value: '', selected: false, termID: 4181, term: '[Medida conteudo herniario]' }
    ];

    this.genericLinfonodo = [
      { value: '', selected: false, termID: 4184, term: '[Lado]' },
      { value: '', selected: false, termID: 4185, term: '[Medidas]' },
      { value: '', selected: false, termID: 4190, term: '[Lado]' },
      { value: '', selected: false, termID: 4191, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 4192, term: '[Medidas]' }
    ];
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados_adicionais',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  incluirLesoesDinamicas($event) {
    const lesoesValue = $event.elemento.value;
    if ($event.linha === 1 && ($event.posicao === 0 || $event.posicao === 1)) {
      if ($event.isPrimeiraLesao === true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaTermos($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      // Hérnia inguinal
      case '947':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 947,
          lesionID: 946,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lado]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '948':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 948,
          lesionID: 946,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Conteúdo abdominal]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '949':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 949,
          lesionID: 946,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida do conteúdo herniário]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '950':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 950,
          lesionID: 946,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Vasos epigástricos inferiores]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4184':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4184,
          lesionID: 4183,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lado]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4185':
          this.dinamicLesion = {
            value: lesoesValue,
            selected: true,
            termID: 4185,
            lesionID: 4183,
            linha: $event.linha,
            posicao: 0,
            cenario: $event.elemento.cenario,
            term: '[Medidas]'
          };
          this.dinamicLesions.push(this.dinamicLesion);
          break;
        case '4190':
          this.dinamicLesion = {
            value: lesoesValue,
            selected: true,
            termID: 4190,
            lesionID: 4187,
            linha: $event.linha,
            posicao: 0,
            cenario: $event.elemento.cenario,
            term: '[Lado]'
          };
          this.dinamicLesions.push(this.dinamicLesion);
          break;
        case '4191':
          this.dinamicLesion = {
            value: lesoesValue,
            selected: true,
            termID: 4191,
            lesionID: 4187,
            linha: $event.linha,
            posicao: 0,
            cenario: $event.elemento.cenario,
            term: '[Ecogenicidade]'
          };
          this.dinamicLesions.push(this.dinamicLesion);
          break;
        case '4192':
          this.dinamicLesion = {
            value: lesoesValue,
            selected: true,
            termID: 4192,
            lesionID: 4187,
            linha: $event.linha,
            posicao: 0,
            cenario: $event.elemento.cenario,
            term: '[Medidas]'
          };
          this.dinamicLesions.push(this.dinamicLesion);
          break;
    }
    this.dinamicconclussion = this.dinamicLesions;
    this.lesionRow = $event.linha;

  }

  public TextoHerniaInguinal(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 947 || +item.termID === 948 || +item.termID === 949 || +item.termID === 950) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = '</br>' + textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (+item.termID === 947 || +item.termID === 948 || +item.termID === 949 || +item.termID === 950) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }


  enviaLesoes($event) {
    if (this.cenarioCorrente === 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario === 0 || $event.cenario === this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  adicionaSelecaoTecidoSubcutaneo($event) {
    this.genericTermsTecidoSubcutaneo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoPlanoMuscular($event) {
    this.genericTermsPlanoMuscular.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoHerniaInguinal($event) {
    this.genericTermsHerniaInguinal.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
    // this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoPreOperatorio($event) {
    this.genericTermsPreOperatorio.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoHerniaParede($event) {
    this.genericTermsHerniaParede.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
    //this.term.listTermID.push($event.elemento.id);

  }

  adicionaSelecaoLinfonodo($event) {
    this.genericLinfonodo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      if (this.indicacao) {
        this.term.blockID = this.indicacaoID;
        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.indicacaoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              const index = this.descricoes.findIndex(x => x.blockID === this.indicacaoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

              if(!this.newEditor) {
                this.AdicionaTexto(this.newEditor);
              } else {
                this.atualizaLinha({
                  blockId: this.indicacaoID,
                  descricao: desc,
                });
              }
            }
          });
        });

      } else if (this.pelePlanoSubcutaneo) {
        this.term.blockID = this.pelePlanoSubcutaneoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.pelePlanoSubcutaneoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;

              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTecidoSubcutaneo(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.pelePlanoSubcutaneoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          this.naoContemColecaoPeleTecido = this._controladorFrases.verificarSeContemTermoSelecionado(this.term, 895);
          this.handleValidaSeAdicionaFrase();

          let obj = {
            value: '',
            blockID: this.pelePlanoSubcutaneoID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoTecidoSubcutaneo(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.pelePlanoSubcutaneoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.pelePlanoSubcutaneoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.pelePlanoSubcutaneoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.planoMuscular) {
        this.term.blockID = this.planoMuscularID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.planoMuscularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoPlanoMuscular(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.planoMuscularID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          this.naoContemColecaoPlano = this._controladorFrases.verificarSeContemTermoSelecionado(this.term, 936);
          this.handleValidaSeAdicionaFrase();

          let obj = {
            value: '',
            blockID: this.planoMuscularID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.planoMuscularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.planoMuscularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.planoMuscularID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.herniaInguinal) {
        this.term.blockID = this.herniaInguinalID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.herniaInguinalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaHerniaInguinal(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.herniaInguinalID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

              // Lógica para remover frase de hernia
              const indexHernia = this.descricoes.findIndex(x => x.blockID === this.herniaID);
              if (this.descricoes[indexHernia].value === 'Não há sinais hérnias ou protrusão de conteúdo abdominal no repouso ou durante manobras de Valsalva.' && obj.value) {
                this.descricoes[indexHernia].value = '';
              } else if (!obj.value) {
                this.descricoes[indexHernia].value = 'Não há sinais hérnias ou protrusão de conteúdo abdominal no repouso ou durante manobras de Valsalva.';
                desc = obj.value;
              }

              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.herniaInguinalID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaHerniaInguinal(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.herniaInguinalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.herniaInguinalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.herniaInguinalID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.preOperatorio) {
        this.term.blockID = this.preOperatorioID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.preOperatorioID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoPreOperatorio(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.preOperatorioID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

              if(!this.newEditor) {
                this.AdicionaTexto(this.newEditor);
              } else {
                this.atualizaLinha({
                  blockId: this.preOperatorioID,
                  descricao: desc,
                });
              }
            }
          });
        });
      } else if (this.hernia) {
        this.term.blockID = this.herniaID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.herniaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoHerniaParede(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.herniaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.herniaID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoHerniaParede(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.herniaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.herniaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.herniaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if(this.linfonodo){
        this.term.blockID = this.linfonodoID;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.linfonodoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoLinfonodo(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.linfonodoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.linfonodoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoLinfonodo(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.linfonodoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.linfonodoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.linfonodoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  MontaDescricaoTecidoSubcutaneo(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 880 || +term === 881 || +term === 883 || +term === 884 || +term === 885 ||
        +term === 886 || +term === 887 || +term === 888 || +term === 891 || +term === 892 ||
        +term === 893 || +term === 894 || +term === 895 || +term === 896 || +term === 897 ||
        +term === 898 || +term === 899 || +term === 900 || +term === 901 || +term === 904 ||
        +term === 905 || +term === 906 || +term === 907 || +term === 908 || +term === 909 ||
        +term === 910 || +term === 911 || +term === 912 || +term === 913 || +term === 915 ||
        +term === 916 || +term === 917 || +term === 918 || +term === 919 || +term === 920 ||
        +term === 921 || +term === 922 || +term === 923 || +term === 924 || +term === 925 ||
        +term === 926 || +term === 927 || +term === 928 || +term === 929 || +term === 930) {
        this.genericTermsTecidoSubcutaneo.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 880 || +term === 881 || +term === 883 || +term === 884 || +term === 885 ||
          +term === 886 || +term === 887 || +term === 888 || +term === 891 || +term === 892 ||
          +term === 893 || +term === 894 || +term === 895 || +term === 896 || +term === 897 ||
          +term === 898 || +term === 899 || +term === 900 || +term === 901 || +term === 904 ||
          +term === 905 || +term === 906 || +term === 907 || +term === 908 || +term === 909 ||
          +term === 910 || +term === 911 || +term === 912 || +term === 913 || +term === 915 ||
          +term === 916 || +term === 917 || +term === 918 || +term === 919 || +term === 920 ||
          +term === 921 || +term === 922 || +term === 923 || +term === 924 || +term === 925 ||
          +term === 926 || +term === 927 || +term === 928 || +term === 929 || +term === 930) {
          this.genericTermsTecidoSubcutaneo.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaConclusaoTecidoSubcutaneo(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 880 || +term === 881 || +term === 883 || +term === 884 || +term === 885 ||
        +term === 886 || +term === 887 || +term === 888 || +term === 891 || +term === 892 ||
        +term === 893 || +term === 894 || +term === 895 || +term === 896 || +term === 897 ||
        +term === 898 || +term === 899 || +term === 900 || +term === 901 || +term === 904 ||
        +term === 905 || +term === 906 || +term === 907 || +term === 908 || +term === 909 ||
        +term === 910 || +term === 911 || +term === 912 || +term === 913 || +term === 915 ||
        +term === 916 || +term === 917 || +term === 918 || +term === 919 || +term === 920 ||
        +term === 921 || +term === 922 || +term === 923 || +term === 924 || +term === 925 ||
        +term === 926 || +term === 927 || +term === 928 || +term === 929 || +term === 930) {
        this.genericTermsTecidoSubcutaneo.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 880 || +term === 881 || +term === 883 || +term === 884 || +term === 885 ||
          +term === 886 || +term === 887 || +term === 888 || +term === 891 || +term === 892 ||
          +term === 893 || +term === 894 || +term === 895 || +term === 896 || +term === 897 ||
          +term === 898 || +term === 899 || +term === 900 || +term === 901 || +term === 904 ||
          +term === 905 || +term === 906 || +term === 907 || +term === 908 || +term === 909 ||
          +term === 910 || +term === 911 || +term === 912 || +term === 913 || +term === 915 ||
          +term === 916 || +term === 917 || +term === 918 || +term === 919 || +term === 920 ||
          +term === 921 || +term === 922 || +term === 923 || +term === 924 || +term === 925 ||
          +term === 926 || +term === 927 || +term === 928 || +term === 929 || +term === 930) {
          this.genericTermsTecidoSubcutaneo.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoPlanoMuscular(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 933 || +term === 934 || +term === 935 || +term === 937 || +term === 938 ||
        +term === 939 || +term === 940 || +term === 941 || +term === 944 || +term === 945 ||
        +term === 4170) {
        this.genericTermsPlanoMuscular.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 933 || +term === 934 || +term === 935 || +term === 937 || +term === 938 ||
          +term === 939 || +term === 940 || +term === 941 || +term === 944 || +term === 945 ||
          +term === 4170) {
          this.genericTermsPlanoMuscular.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoHerniaInguinal(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 954 ||
        +term === 955 || +term === 956 || +term === 959 || +term === 960 || +term === 961 || +term === 962) {
        this.genericTermsHerniaInguinal.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 954 || +term === 955 || +term === 956 || +term === 959 || +term === 960 || +term === 961 || +term === 962) {
          this.genericTermsHerniaInguinal.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaHerniaInguinal(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });
    const RowDinamicConclussion = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) === contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoHerniaInguinal(texto, contadorTexto, lesaoID);
    } else if (RowDinamicConclussion !== undefined && RowDinamicConclussion.length > 0) {
      const lesaoID = RowDinamicConclussion[0].lesionID;
      texto = this.MontaHerniaInguinalConclusion(texto, contadorTexto, lesaoID);
    } else {
      texto = this.MontaDescricaoHerniaInguinal(texto, contadorTexto);
    }
    return texto;
  }

  public MontaHerniaInguinalConclusion(texto, contadorTexto, lesaoID): any {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length === 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    this.dinamicconclussion.forEach(item => {
      if (lesaoID === item.lesionID) {
        if (item.linha === linha) {
          if (+item.termID === 947 || +item.termID === 948 || +item.termID === 949 || +item.termID === 950) {
            if (+item.termID === 950) {
              if (item.value === 'medialmente') {
                textoDinamico = textoDinamico.replace(item.term, 'direta');
              } else if (item.value === 'lateralmente') {
                textoDinamico = textoDinamico.replace(item.term, 'indireta');
              }
            } else {
              textoDinamico = textoDinamico.replace(item.term, item.value);
            }
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {

          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length === 1) {
            textoDinamico = '</br>' + textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (+item.termID === 947 || +item.termID === 948 || +item.termID === 949 || +item.termID === 950) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicconclussion = this.dinamicconclussion.filter((din) => {
      return lesaoID !== din.lesionID;
    });

    return texto;
  }

  MontaDescricaoHerniaParede(texto, contadorTexto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 836 || +term === 837 || +term === 840 || +term === 843 || +term === 844 || +term === 838 ||
        +term === 845 || +term === 848 || +term === 851 || +term === 852 || +term === 855 || +term === 858 ||
        +term === 859 || +term === 860 || +term === 863 || +term === 866 || +term === 867 || +term === 870 ||
        +term === 846 || +term === 853 || +term === 861 || +term === 868 || +term === 4177 || +term === 4178 ||
        +term === 4179 || +term === 4180 || +term === 4181 ) {
        this.genericTermsHerniaParede.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 836 || +term === 837 || +term === 840 || +term === 843 || +term === 844 || +term === 838 ||
          +term === 845 || +term === 848 || +term === 851 || +term === 852 || +term === 855 || +term === 858 ||
          +term === 859 || +term === 860 || +term === 863 || +term === 866 || +term === 867 || +term === 870 ||
          +term === 846 || +term === 853 || +term === 861 || +term === 868 || +term === 4177 || +term === 4178 ||
          +term === 4179 || +term === 4180 || +term === 4181) {
          this.genericTermsHerniaParede.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaConclusaoHerniaParede(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 844 || +term === 858) {
        this.genericTermsHerniaParede.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries !== undefined
      && this.term.injuries[0].listInjuries.length !== 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 844 || +term === 858) {
          this.genericTermsHerniaParede.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoPreOperatorio(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 967 || +term === 968 || +term === 969 || +term === 970 || +term === 971 ||
        +term === 972 || +term === 973 || +term === 974) {
        this.genericTermsPreOperatorio.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoLinfonodo(texto, contadorTexto) {
    let xpto = this.dinamicLesions;
    const rowDinamic = xpto.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoLinfonodoDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoLinfonodoDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  MontaConclusaoLinfonodo(texto, contadorTexto){
    const rowDinamic = this.dinamicconclussion.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoConclusaoLinfonodoDinamic(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoLinfonodoDescNormal(texto, contadorTexto);
    }
    return texto;

  }

  TextoLinfonodoDescNormal(texto, contadorTexto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 4184 || +term === 4185 || +term === 4190 || +term === 4191 || +term === 4192) {
        this.genericLinfonodo.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  TextoLinfonodoDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    // Lista term
    this.term.listTermID.forEach(term => {
      if (+term === 4184 || +term === 4185 || +term === 4190 || +term === 4191 || +term === 4192) {
        this.genericLinfonodo.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
          }
        });
      }
    });

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 4184 || item.termID == 4185 || item.termID == 4190 || item.termID == 4191 || item.termID == 4192) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (item.termID == 4184 || item.termID == 4185 || item.termID == 4190 || item.termID == 4191 || item.termID == 4192) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }


  TextoConclusaoLinfonodoDinamic(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = "";//textoSplit[0];
      textoDinamico = textoSplit[1] + '</br>';
    }

    // this.term.listTermID.forEach(term => {
    //   if (+term === 4184 || +term === 4185 || +term === 4190 || +term === 4191 || +term === 4192) {
    //     this.genericLinfonodo.forEach(generic => {
    //       if (+generic.termID === +term) {
    //         generic.selected = true;
    //         textoRetorno = texto.replace(generic.term, generic.value);
    //         texto = textoRetorno;
    //       }
    //     });
    //   }
    // });

    this.dinamicconclussion.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 4184 || item.termID == 4185 || item.termID == 4190 || item.termID == 4191 || item.termID == 4192) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1] + '</br>';
          }

          if (item.termID == 4184 || item.termID == 4185 || item.termID == 4190 || item.termID == 4191 || item.termID == 4192) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });

    //lógica de se os lados forem iguais a frase na conclusão tmb será.
    //colocando uma unica frase na conclusão.
    if (textoRetorno === textoDinamico ) {
      texto = textoRetorno;
    } else {
      textoRetorno = textoRetorno + textoDinamico;
      texto = textoRetorno;
    }

    this.dinamicconclussion = this.dinamicconclussion.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];
    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<div style="text-align: center;"><b>ULTRASSONOGRAFIA DA PAREDE ABDOMINAL</br><br></b></div>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-${this.indicacaoID}`,
        'name': ''
      },
      'data': {
        'text': `<p><b>INDICAÇÃO:</b></br>${this.descricoes[0].value}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    let i;
    this.descricoes.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        if (i > 0 && i < this.descricoes.length - 1) {
          if(this.descricoes[i].blockID == 275){
            this.laudoCompleto.push({
              'type': 'paragraph',
              'blockMenu': {
                'id': `e-${this.descricoes[i].blockID}`,
                'name': ''
              },
              'data': {
                'text': `<p>${this.descricoes[i].value}</p>`,
              }
            });
          } else{
            this.laudoCompleto.push({
              'type': 'paragraph',
              'blockMenu': {
                'id': `e-${this.descricoes[i].blockID}`,
                'name': ''
              },
              'data': {
                'text': `<p>${this.descricoes[i].value}</br></p>`,
              }
            });
          }
        }
      }
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados_adicionais`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.descricoes[0].value;
      //Lógica Rules
      if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '</br></br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.';

    this.htmlContent += maskSplit[1];

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        if (i > 0 && i < this.descricoes.length - 1) {
          if(this.descricoes[i].blockID == 275){
            this.htmlContent += '<br/>' + this.descricoes[i].value;
          } else{
            this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
          }
        }
      }
    }
    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }
    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    // if (this.conclusoes.length <= 1) {
    texto = 'Exame sem alterações significativas.';
    // }
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
  handleValidaSeAdicionaFrase() {
    if(this.naoContemColecaoPeleTecido && this.naoContemColecaoPlano){
      this.descricoes[this.descricoes.length-2].value = 'Não foram caracterizadas coleções organizadas.';
    }else{
      this.descricoes[this.descricoes.length-2].value = '';
    }
  }
}
