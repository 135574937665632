import { EventEmitter, Injectable, Output } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { BehaviorSubject } from 'rxjs';
declare var webkitSpeechRecognition: any;

@Injectable({
  providedIn: 'root'
})
export class SpeechToTextService {

  @Output() TextoFinal = new EventEmitter();

  window: Window;
  gravando: boolean = false;
  transcricao = new BehaviorSubject<string>('');
  finalTranscription: string = '';
  tempTranscription: string;
  speechRecognition;
  textHtml: string;

  constructor(private toastr: ToastrManager) {
  }

  async audioConfig(click) {
    if (!click) {
      if (!('webkitSpeechRecognition' in window)) {
        this.showtoastr('Seu navegador não suporta a API de reconhecimento de voz. Utilize os navegadores: Chrome, Edge ou Safari.');
        return;
      } else {
        this.speechRecognition = new webkitSpeechRecognition();
        this.speechRecognition.continuous = true;
        this.speechRecognition.interimResults = true;
        this.speechRecognition.lang = 'pt-BR';
        this.speechRecognition.onstart = (e) => {
          this.gravando = true;
        };

        this.speechRecognition.onerror = (event) => {
          if (event.error == 'no-speech') {
            this.showtoastr('Nenhuma fala foi detectada.');
          }
          if (event.error == 'audio-capture') {
            this.showtoastr('Não foi possível capturar a fala.');
          }
          if (event.error == 'not-allowed') {
            this.showtoastr('Não foi possível capturar a fala.');
          }
          this.gravando = false;
        };

        this.speechRecognition.onend = () => {
          this.gravando = false;
        };
      }
    }
  }

  async gravadorDeVoz(click) {
    this.finalTranscription = '';
    let promise = new Promise(resolve => {
      this.audioConfig(click).then(() => {
        if (click) {
          this.speechRecognition.stop();
          resolve(this.gravando = false);
        } else {
          this.gravando = true;
          this.speechRecognition.lang = 'pt-BR';
          this.speechRecognition.start();
          this.speechRecognition.onresult = (event) => {
            this.tempTranscription = '';
            this.finalTranscription = '';
            for (var i = event.resultIndex; i < event.results.length; ++i) {
              if (event.results[i].isFinal) {

                console.log("Final: ", event.results[i][0].transcript);
                this.comandoVoz(event.results[i][0].transcript)

              } else {
                console.log("Temp: ",event.results[i][0].transcript);

                this.tempTranscription += event.results[i][0].transcript;
              }
            }
          };
        }
      });
    })

    let result: any = await promise;
    return result;
  }

  async verificaGravando() {
    let promise = new Promise(resolve => {
      resolve(this.gravando);
    });
    let result: any = await promise;
    return result;
  }

  capitalize(frase: string) {
    return frase.charAt(0).toUpperCase() + frase.slice(1);
  }

  showtoastr(mensagem: string) {
    this.toastr.errorToastr(mensagem, 'Erro',
      {
        position: 'top-right',
        toastTimeout: 4000,
        maxShown: 1,
        showCloseButton: true
      }
    );
  }

  comandoVoz(comando: string) {
    var fraseTrim = this.finalTranscription.trim();

    if (comando.toLowerCase() == 'ação pular linha') {
      this.finalTranscription += '<br>';
    } else if (comando.toLowerCase() == 'ação ponto') {
      this.finalTranscription += '.';
    } else if (fraseTrim.charAt(fraseTrim.length - 1) == '.') {
      this.finalTranscription += this.capitalize(comando + ' ');
    } else if (comando.toLowerCase() == 'ação parar') {
      this.gravando = false;
      this.speechRecognition.stop();
    } else if (comando.toLowerCase() == 'ação apagar') {
      this.finalTranscription = '';
    } else if (comando.toLowerCase() == 'ação novo bloco') {
      this.finalTranscription += '\n\n';
    } else {
      this.finalTranscription += comando;
    }
    this.transcricao.next(this.finalTranscription);
    this.finalTranscription = '';
    // this.TextoFinal.emit(this.finalTranscription);
    return this.finalTranscription;
  }

  alteraHtml(htmlContent: string): string {
    console.log(htmlContent);
    this.textHtml = htmlContent;
    return htmlContent;
    // throw new Error('Method not implemented.');
  }
}
