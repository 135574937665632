import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-septo-nasal',
  templateUrl: './septo-nasal.component.html',
  styleUrls: ['./septo-nasal.component.css']
})
export class SeptoNasalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdCentrado') rdCentrado: MatRadioButton;
  @ViewChild('rdDesviado') rdDesviado: MatRadioButton;
  
  @ViewChild('slLateralidade') slLateralidade: MatSelect;

  lateralidade: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];
  
  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahDesviado: true
    };
  }

  clickCentrado() {
    this.showAndHideTerms.sahDesviado = false;
    this.catchIDs();
  }

  clickDesviado() {
    this.showAndHideTerms.sahDesviado = true;
    this.catchIDs();
  }

  changeSelect() {
    this.alteraCentrado();
    this.catchIDs();
  }

  catchIDs() {

    this.termoSelecionado.emit('Clean');

    if (this.rdCentrado && this.rdCentrado.checked) {
      this.termoSelecionado.emit(this.rdCentrado.id);
    }

    if (this.rdDesviado && this.rdDesviado.checked) {
      this.termoSelecionado.emit(this.rdDesviado.id);

      if(this.slLateralidade && this.slLateralidade.value) {
        const objeto = { elemento: this.slLateralidade };
        this.termosGenericos.emit(objeto);
      }

    }

    this.listaCompleta.emit();
  }

  alteraCentrado() {
    if ((this.slLateralidade && this.slLateralidade.selected)        
    ) {
      this.rdDesviado.checked = true;
    } else {
      this.rdCentrado.checked = true;
      this.rdDesviado.checked = false;
    }
  }

}
