import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-cabeca-femoral',
  templateUrl: './cabeca-femoral.component.html',
  styleUrls: ['./cabeca-femoral.component.css']
})
export class CabecaFemoralComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdSubluxacao') rdSubluxacao: MatRadioButton;
  @ViewChild('rdLuxacao') rdLuxacao: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickDefault() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked)
      this.termoSelecionado.emit(this.rdNormal.value);

    if (this.rdSubluxacao.checked)
      this.termoSelecionado.emit(this.rdSubluxacao.value);

    if (this.rdLuxacao.checked)
      this.termoSelecionado.emit(this.rdLuxacao.value);

    this.listaCompleta.emit();
  }

}
