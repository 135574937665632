import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-nervo-ulnar',
  templateUrl: './nervo-ulnar.component.html',
  styleUrls: ['./nervo-ulnar.component.css']
})
export class NervoUlnarComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdNeuropatia') rdNeuropatia: MatRadioButton;
  @ViewChild('rdSubluxacao') rdSubluxacao: MatRadioButton;

  @ViewChild('inArea') inArea: ElementRef;
  @ViewChild('ckNeuropatia') ckNeuropatia: MatCheckbox;

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahNeuropatia: false,
      sahSubluxacao: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickDefault() {
    this.showAndHideTerms.sahNeuropatia = false;
    this.showAndHideTerms.sahSubluxacao = false;
    this.catchIDs();
  }

  clickNeuropatia() {
    this.showAndHideTerms.sahNeuropatia = true;
    this.showAndHideTerms.sahSubluxacao = false;
    this.catchIDs();
  }
  clickSubluxacao(){
    this.showAndHideTerms.sahNeuropatia = false;
    this.showAndHideTerms.sahSubluxacao = true;
    this.catchIDs();
  }

  blurDistancia(){
    this.catchIDs();
  }

  clickCk() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdNeuropatia.checked) {
      this.termoSelecionado.emit(this.rdNeuropatia.value);
      if (this.inArea && this.inArea.nativeElement.value){
        const objetoElemento = {id: "1341", value: this.inArea.nativeElement.value};

        const objeto = { elemento: objetoElemento};
        this.termosGenericos.emit(objeto);

      }
    }

    if (this.rdSubluxacao.checked) {
      this.termoSelecionado.emit(this.rdSubluxacao.value);
      if (this.ckNeuropatia && this.ckNeuropatia.checked){

        this.termoSelecionado.emit(this.ckNeuropatia.value);
        if (this.inArea && this.inArea.nativeElement.value){
          const objetoElemento = {id: "1341", value: this.inArea.nativeElement.value};
          const objeto = { elemento: objetoElemento};
          this.termosGenericos.emit(objeto);

        }
      }
    }

    this.listaCompleta.emit();
  }
}
