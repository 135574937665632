import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Validacoes} from '../../../../../../utils/validacoes';
import {MatCheckbox, MatRadioButton, MatSelect} from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-hernia-parede',
  templateUrl: './hernia-parede.component.html',
  styleUrls: ['./hernia-parede.component.css']
})
export class HerniaParedeComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  // Hérnia Supra Umbilical
  @ViewChild('ckHernialSupraUmbilical') ckHernialSupraUmbilical: MatCheckbox;
  @ViewChild('inColoSupraUmbilical1') inColoSupraUmbilical1: ElementRef;
  @ViewChild('inColoSupraUmbilical2') inColoSupraUmbilical2: ElementRef;
  @ViewChild('inDistCicatrizUmbilical') inDistCicatrizUmbilical: ElementRef;
  @ViewChild('slConteudoAbdominal') slConteudoAbdominal: MatSelect;
  @ViewChild('slOservadoSupraUmbilical') slOservadoSupraUmbilical: MatSelect;
  @ViewChild('inMedidaConteudoSupra1') inMedidaConteudoSupra1: ElementRef;
  @ViewChild('inMedidaConteudoSupra2') inMedidaConteudoSupra2: ElementRef;
  @ViewChild('inMedidaConteudoSupra3') inMedidaConteudoSupra3: ElementRef;
  
  
  // Hérnia Umbilical
  @ViewChild('ckHernialUmbilical') ckHernialUmbilical: MatCheckbox;
  // @ViewChild('ckSinaisInflamatoriosUmbilical') ckSinaisInflamatoriosUmbilical: MatCheckbox;
  @ViewChild('inColoUmbilical1') inColoUmbilical1: ElementRef;
  @ViewChild('inColoUmbilical2') inColoUmbilical2: ElementRef;
  @ViewChild('slConteudoUmbilical') slConteudoUmbilical: MatSelect;
  @ViewChild('slOservadoUmbilical') slOservadoUmbilical: MatSelect;
  // @ViewChild('ckAoRepousoUmbilical') ckAoRepousoUmbilical: MatCheckbox;
  // @ViewChild('ckManobrasUmbilical') ckManobrasUmbilical: MatCheckbox;
  @ViewChild('inMedidaConteudo1') inMedidaConteudo1: ElementRef;
  @ViewChild('inMedidaConteudo2') inMedidaConteudo2: ElementRef;
  @ViewChild('inMedidaConteudo3') inMedidaConteudo3: ElementRef;

  // Hérnia Paramediana
  @ViewChild('ckHerniaParamediana') ckHerniaParamediana: MatCheckbox;
  // @ViewChild('ckSinaisInflamatoriosParamediana') ckSinaisInflamatoriosParamediana: MatCheckbox;
  @ViewChild('slLadoParamediana') slLadoParamediana: MatSelect;
  @ViewChild('inColoParamediana1') inColoParamediana1: ElementRef;
  @ViewChild('inColoParamediana2') inColoParamediana2: ElementRef;
  @ViewChild('slConteudoParamediana') slConteudoParamediana: MatSelect;
  @ViewChild('slOservadoParamediana') slOservadoParamediana: MatSelect;
  // @ViewChild('ckAoRepousoParamediana') ckAoRepousoParamediana: MatCheckbox;
  // @ViewChild('ckManobrasParamediana') ckManobrasParamediana: MatCheckbox;
  @ViewChild('inMedidaConteudoParamediana1') inMedidaConteudoParamediana1: ElementRef;
  @ViewChild('inMedidaConteudoParamediana2') inMedidaConteudoParamediana2: ElementRef;
  @ViewChild('inMedidaConteudoParamediana3') inMedidaConteudoParamediana3: ElementRef;

  // Hérnia Epigastrica
  @ViewChild('ckHerniaEpigastrica') ckHerniaEpigastrica: MatCheckbox;
  // @ViewChild('ckSinaisInflamatoriosEpigastrica') ckSinaisInflamatoriosEpigastrica: MatCheckbox;
  @ViewChild('inColoEpigastrica1') inColoEpigastrica1: ElementRef;
  @ViewChild('inColoEpigastrica2') inColoEpigastrica2: ElementRef;
  @ViewChild('slConteudoEpigastrica') slConteudoEpigastrica: MatSelect;
  @ViewChild('slOservadoEpigastrica') slOservadoEpigastrica: MatSelect;
  // @ViewChild('ckAoRepousoEpigastrica') ckAoRepousoEpigastrica: MatCheckbox;
  // @ViewChild('ckManobrasEpigastrica') ckManobrasEpigastrica: MatCheckbox;
  @ViewChild('inMedidaConteudoEpigastrica1') inMedidaConteudoEpigastrica1: ElementRef;
  @ViewChild('inMedidaConteudoEpigastrica2') inMedidaConteudoEpigastrica2: ElementRef;
  @ViewChild('inMedidaConteudoEpigastrica3') inMedidaConteudoEpigastrica3: ElementRef;

  // Hérnia Semilunar
  @ViewChild('ckHerniaSemilunar') ckHerniaSemilunar: MatCheckbox;
  // @ViewChild('ckSinaisInflamatoriosSemilunar') ckSinaisInflamatoriosSemilunar: MatCheckbox;
  @ViewChild('slLadoSemilunar') slLadoSemilunar: MatSelect;
  @ViewChild('inColoSemilunar1') inColoSemilunar1: ElementRef;
  @ViewChild('inColoSemilunar2') inColoSemilunar2: ElementRef;
  @ViewChild('slConteudoSemilunar') slConteudoSemilunar: MatSelect;
  @ViewChild('slOservadoSemilunar') slOservadoSemilunar: MatSelect;
  // @ViewChild('ckAoRepousoSemilunar') ckAoRepousoSemilunar: MatCheckbox;
  // @ViewChild('ckManobrasSemilunar') ckManobrasSemilunar: MatCheckbox;
  @ViewChild('inMedidaConteudoSemilunar') inMedidaConteudoSemilunar: ElementRef;

  // Hérnia Incisional
  @ViewChild('ckHerniaIncisional') ckHerniaIncisional: MatCheckbox;
  // @ViewChild('ckSinaisInflamatoriosIncisional') ckSinaisInflamatoriosIncisional: MatCheckbox;
  @ViewChild('inColoIncisional1') inColoIncisional1: ElementRef;
  @ViewChild('inColoIncisional2') inColoIncisional2: ElementRef;
  @ViewChild('slConteudoIncisional') slConteudoIncisional: MatSelect;
  @ViewChild('slOservadoIncisional') slOservadoIncisional: MatSelect;
  // @ViewChild('ckAoRepousoIncisional') ckAoRepousoIncisional: MatCheckbox;
  // @ViewChild('ckManobrasIncisional') ckManobrasIncisional: MatCheckbox;
  @ViewChild('inMedidaConteudoIncisional') inMedidaConteudoIncisional: ElementRef;

  conteudos: ArrGenerico[] = [
    {value: 'adiposo', viewValue: 'Adiposo'},
    {value: 'alças intestinais', viewValue: 'Alças intestinais'},
    {value: 'gordura e alças intestinais', viewValue: 'Gordura e alças intestinais'}
  ];

  conteudosSupra: ArrGenerico[] = [
    {value: 'adiposo', viewValue: 'Adiposo'},
    {value: 'alças intestinais', viewValue: 'Alças intestinais'},
    {value: 'adiposo e alças intestinais', viewValue: 'Adiposo e alças intestinais'}
  ];

  lados: ArrGenerico[] = [
    {value: 'à direita', viewValue: 'Direita'},
    {value: 'à esquerda', viewValue: 'Esquerda'}
  ];

  observados: ArrGenerico[] = [
    {value: 'ao repouso que se intensifica às manobras de Valsalva', viewValue: 'Ao repouso'},
    {value: 'à manobra de Valsalva', viewValue: 'Manobras de Valsalva'}
  ];

  showAndHideTerms: any;
  countLesoes = 0;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahLesao: true,
      sahSupraUmbilical: false,
      sahUmbilical: false,
      sahParamediana: false,
      sahEpigastrica: false,
      sahSemilunar: false,
      sahIncisional: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickAusente() {
    this.showAndHideTerms.sahLesao = false;
    this.showAndHideTerms.sahSupraUmbilical = false;
    this.showAndHideTerms.sahUmbilical = false;
    this.showAndHideTerms.sahParamediana = false;
    this.showAndHideTerms.sahEpigastrica = false;
    this.showAndHideTerms.sahSemilunar = false;
    this.showAndHideTerms.sahIncisional = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahLesao = true;
    this.catchIDs();
  }

  clickSupraUmbilical() {
    this.rdPresente.checked = true;
    if (this.showAndHideTerms.sahSupraUmbilical == false) {
      this.showAndHideTerms.sahSupraUmbilical = true;
    } else {
      this.showAndHideTerms.sahSupraUmbilical = false;
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickUmbilical() {
    this.rdPresente.checked = true;
    if (this.showAndHideTerms.sahUmbilical == false) {
      this.showAndHideTerms.sahUmbilical = true;
    } else {
      this.showAndHideTerms.sahUmbilical = false;
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickParamediana() {
    this.rdPresente.checked = true;
    if (this.showAndHideTerms.sahParamediana == false) {
      this.showAndHideTerms.sahParamediana = true;
    } else {
      this.showAndHideTerms.sahParamediana = false;
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickEpigastrica() {
    this.rdPresente.checked = true;
    if (this.showAndHideTerms.sahEpigastrica == false) {
      this.showAndHideTerms.sahEpigastrica = true;
    } else {
      this.showAndHideTerms.sahEpigastrica = false;
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickSemilunar() {
    this.rdPresente.checked = true;
    if (this.showAndHideTerms.sahSemilunar == false) {
      this.showAndHideTerms.sahSemilunar = true;
    } else {
      this.showAndHideTerms.sahSemilunar = false;
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickIncisional() {
    this.rdPresente.checked = true;
    if (this.showAndHideTerms.sahIncisional == false) {
      this.showAndHideTerms.sahIncisional = true;
    } else {
      this.showAndHideTerms.sahIncisional = false;
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente.checked) {
      
      // Hérnia Umbilical
      this.emitSupraUmbilical()

      // Hérnia Umbilical
      if (this.countLesoes > 0) {
        this.emitUmbilicalLesao();
      } else {
        this.emitUmbilical();
      }

      // Hérnia Paramediana
      if (this.countLesoes > 0) {
        this.emitParamedianaLesao();
      } else {
        this.emitParamedianaSimples();
      }

      // Hérnia Epigastrica
      if (this.countLesoes > 0) {
        this.emitEpigastricaLesao();
      } else {
        this.emitEpigastricaSimples();
      }

      // Hérnia Semilunar
      if (this.countLesoes > 0) {
        this.emitSemilunarLesao();
      } else {
        this.emitSemilunarSimples();
      }

      // Hérnia Incisional
      if (this.countLesoes > 0) {
        this.emitIncisionalLesao();
      } else {
        this.emitIncisionalSimples();
      }
    }

    //this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  emitSupraUmbilical() {
    if (this.ckHernialSupraUmbilical !== undefined && this.ckHernialSupraUmbilical.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHernialSupraUmbilical.id);

      if (this.slConteudoAbdominal != undefined && this.slConteudoAbdominal.value != undefined) {
        const objeto = {elemento: this.slConteudoAbdominal, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slOservadoSupraUmbilical != undefined && this.slOservadoSupraUmbilical.value != undefined) {
        const objeto = {elemento: this.slOservadoSupraUmbilical, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoSupraUmbilical(true);
    }
  }

  emitUmbilical() {
    if (this.ckHernialUmbilical !== undefined && this.ckHernialUmbilical.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHernialUmbilical.id);

      if (this.slConteudoUmbilical != undefined && this.slConteudoUmbilical.value != undefined) {
        const objeto = {elemento: this.slConteudoUmbilical, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slOservadoUmbilical != undefined && this.slOservadoUmbilical.value != undefined) {
        const objeto = {elemento: this.slOservadoUmbilical, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      // if (this.ckAoRepousoUmbilical !== undefined && this.ckAoRepousoUmbilical.checked) {
      //   this.termoSelecionado.emit(this.ckAoRepousoUmbilical.id);
      // }
      //
      // if (this.ckManobrasUmbilical !== undefined && this.ckManobrasUmbilical.checked) {
      //   this.termoSelecionado.emit(this.ckManobrasUmbilical.id);
      // }

      // if (this.ckSinaisInflamatoriosUmbilical !== undefined && this.ckSinaisInflamatoriosUmbilical.checked) {
      //   this.termoSelecionado.emit(this.ckSinaisInflamatoriosUmbilical.id);
      // }

      this.formataMedindoUmbilical(true);
    }
  }

  emitUmbilicalLesao() {
    if (this.ckHernialUmbilical !== undefined && this.ckHernialUmbilical.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckHernialUmbilical.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slConteudoUmbilical != undefined && this.slConteudoUmbilical.value != undefined) {
        const objeto = {elemento: this.slConteudoUmbilical, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      if (this.slOservadoUmbilical != undefined && this.slOservadoUmbilical.value != undefined) {
        const objeto = {elemento: this.slOservadoUmbilical, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      // if (this.ckAoRepousoUmbilical !== undefined && this.ckAoRepousoUmbilical.checked) {
      //   this.termoSelecionado.emit(this.ckAoRepousoUmbilical.id);
      // }
      //
      // if (this.ckManobrasUmbilical !== undefined && this.ckManobrasUmbilical.checked) {
      //   this.termoSelecionado.emit(this.ckManobrasUmbilical.id);
      // }

      // if (this.ckSinaisInflamatoriosUmbilical !== undefined && this.ckSinaisInflamatoriosUmbilical.checked) {
      //   this.termoSelecionado.emit(this.ckSinaisInflamatoriosUmbilical.id);
      // }

      this.formataMedindoUmbilical(false);
    }
  }

  emitParamedianaSimples() {
    if (this.ckHerniaParamediana !== undefined && this.ckHerniaParamediana.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHerniaParamediana.id);

      if (this.slLadoParamediana != undefined && this.slLadoParamediana.value != undefined) {
        const objeto = {elemento: this.slLadoParamediana, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoParamediana != undefined && this.slConteudoParamediana.value != undefined) {
        const objeto = {elemento: this.slConteudoParamediana, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slOservadoParamediana != undefined && this.slOservadoParamediana.value != undefined) {
        const objeto = {elemento: this.slOservadoParamediana, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      // if (this.ckSinaisInflamatoriosParamediana !== undefined && this.ckSinaisInflamatoriosParamediana.checked) {
      //   this.termoSelecionado.emit(this.ckSinaisInflamatoriosParamediana.id);
      // }

      this.formataMedindoParamediana(true);
    }
  }

  emitParamedianaLesao() {
    if (this.ckHerniaParamediana !== undefined && this.ckHerniaParamediana.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckHerniaParamediana.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLadoParamediana != undefined && this.slLadoParamediana.value != undefined) {
        const objeto = {elemento: this.slLadoParamediana, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoParamediana != undefined && this.slConteudoParamediana.value != undefined) {
        const objeto = {elemento: this.slConteudoParamediana, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      if (this.slOservadoParamediana != undefined && this.slOservadoParamediana.value != undefined) {
        const objeto = {elemento: this.slOservadoParamediana, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      // if (this.ckAoRepousoParamediana !== undefined && this.ckAoRepousoParamediana.checked) {
      //   const objeto = {id: this.ckAoRepousoParamediana.id, cenario: this.countLesoes};
      //   this.lesoesSelecionadas.emit(objeto);
      // }
      //
      // if (this.ckManobrasParamediana !== undefined && this.ckManobrasParamediana.checked) {
      //   const objeto = {id: this.ckManobrasParamediana.id, cenario: this.countLesoes};
      //   this.lesoesSelecionadas.emit(objeto);
      // }
      //
      // if (this.ckSinaisInflamatoriosParamediana !== undefined && this.ckSinaisInflamatoriosParamediana.checked) {
      //   const objeto = {id: this.ckSinaisInflamatoriosParamediana.id, cenario: this.countLesoes};
      //   this.lesoesSelecionadas.emit(objeto);
      // }

      this.formataMedindoParamediana(false);
    }
  }

  emitEpigastricaSimples() {
    if (this.ckHerniaEpigastrica !== undefined && this.ckHerniaEpigastrica.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHerniaEpigastrica.id);

      if (this.slConteudoEpigastrica != undefined && this.slConteudoEpigastrica.value != undefined) {
        const objeto = {elemento: this.slConteudoEpigastrica, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slOservadoEpigastrica != undefined && this.slOservadoEpigastrica.value != undefined) {
        const objeto = {elemento: this.slOservadoEpigastrica, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      // if (this.ckAoRepousoEpigastrica !== undefined && this.ckAoRepousoEpigastrica.checked) {
      //   this.termoSelecionado.emit(this.ckAoRepousoEpigastrica.id);
      // }
      //
      // if (this.ckManobrasEpigastrica !== undefined && this.ckManobrasEpigastrica.checked) {
      //   this.termoSelecionado.emit(this.ckManobrasEpigastrica.id);
      // }
      //
      // if (this.ckSinaisInflamatoriosEpigastrica !== undefined && this.ckSinaisInflamatoriosEpigastrica.checked) {
      //   this.termoSelecionado.emit(this.ckSinaisInflamatoriosEpigastrica.id);
      // }

      this.formataMedindoEpigastrica(true);
    }
  }

  emitEpigastricaLesao() {
    if (this.ckHerniaEpigastrica !== undefined && this.ckHerniaEpigastrica.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckHerniaEpigastrica.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slConteudoEpigastrica != undefined && this.slConteudoEpigastrica.value != undefined) {
        const objeto = {elemento: this.slConteudoEpigastrica, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoEpigastrica != undefined && this.slConteudoEpigastrica.value != undefined) {
        const objeto = {elemento: this.slConteudoEpigastrica, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      if (this.slOservadoEpigastrica != undefined && this.slOservadoEpigastrica.value != undefined) {
        const objeto = {elemento: this.slOservadoEpigastrica, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      // if (this.ckAoRepousoEpigastrica !== undefined && this.ckAoRepousoEpigastrica.checked) {
      //   const objeto = {id: this.ckAoRepousoEpigastrica.id, cenario: this.countLesoes};
      //   this.lesoesSelecionadas.emit(objeto);
      // }
      //
      // if (this.ckManobrasEpigastrica !== undefined && this.ckManobrasEpigastrica.checked) {
      //   const objeto = {id: this.ckManobrasEpigastrica.id, cenario: this.countLesoes};
      //   this.lesoesSelecionadas.emit(objeto);
      // }
      //
      // if (this.ckSinaisInflamatoriosEpigastrica !== undefined && this.ckSinaisInflamatoriosEpigastrica.checked) {
      //   const objeto = {id: this.ckSinaisInflamatoriosEpigastrica.id, cenario: this.countLesoes};
      //   this.lesoesSelecionadas.emit(objeto);
      // }

      this.formataMedindoEpigastrica(false);
    }
  }

  emitSemilunarSimples() {
    if (this.ckHerniaSemilunar !== undefined && this.ckHerniaSemilunar.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHerniaSemilunar.id);

      if (this.slLadoSemilunar != undefined && this.slLadoSemilunar.value != undefined) {
        const objeto = {elemento: this.slLadoSemilunar, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoSemilunar != undefined && this.slConteudoSemilunar.value != undefined) {
        const objeto = {elemento: this.slConteudoSemilunar, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slOservadoSemilunar != undefined && this.slOservadoSemilunar.value != undefined) {
        const objeto = {elemento: this.slOservadoSemilunar, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      // if (this.ckAoRepousoSemilunar !== undefined && this.ckAoRepousoSemilunar.checked) {
      //   this.termoSelecionado.emit(this.ckAoRepousoSemilunar.id);
      // }
      //
      // if (this.ckManobrasSemilunar !== undefined && this.ckManobrasSemilunar.checked) {
      //   this.termoSelecionado.emit(this.ckManobrasSemilunar.id);
      // }
      //
      // if (this.ckSinaisInflamatoriosSemilunar !== undefined && this.ckSinaisInflamatoriosSemilunar.checked) {
      //   this.termoSelecionado.emit(this.ckSinaisInflamatoriosSemilunar.id);
      // }

      if (this.inMedidaConteudoSemilunar !== undefined &&
        this.inMedidaConteudoSemilunar.nativeElement.id !== undefined &&
        this.inMedidaConteudoSemilunar.nativeElement.value != '') {

        const objeto = {elemento: this.inMedidaConteudoSemilunar.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoSemilunar(true);
    }
  }

  emitSemilunarLesao() {
    if (this.ckHerniaSemilunar !== undefined && this.ckHerniaSemilunar.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckHerniaSemilunar.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLadoSemilunar != undefined && this.slLadoSemilunar.value != undefined) {
        const objeto = {elemento: this.slLadoSemilunar, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoSemilunar != undefined && this.slConteudoSemilunar.value != undefined) {
        const objeto = {elemento: this.slConteudoSemilunar, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      if (this.slOservadoSemilunar != undefined && this.slOservadoSemilunar.value != undefined) {
        const objeto = {elemento: this.slOservadoSemilunar, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      // if (this.ckAoRepousoSemilunar !== undefined && this.ckAoRepousoSemilunar.checked) {
      //   const objeto = {id: this.ckAoRepousoSemilunar.id, cenario: this.countLesoes};
      //   this.lesoesSelecionadas.emit(objeto);
      // }
      //
      // if (this.ckManobrasSemilunar !== undefined && this.ckManobrasSemilunar.checked) {
      //   const objeto = {id: this.ckManobrasSemilunar.id, cenario: this.countLesoes};
      //   this.lesoesSelecionadas.emit(objeto);
      // }
      //
      // if (this.ckSinaisInflamatoriosSemilunar !== undefined && this.ckSinaisInflamatoriosSemilunar.checked) {
      //   const objeto = {id: this.ckSinaisInflamatoriosSemilunar.id, cenario: this.countLesoes};
      //   this.lesoesSelecionadas.emit(objeto);
      // }

      if (this.inMedidaConteudoSemilunar !== undefined &&
        this.inMedidaConteudoSemilunar.nativeElement.id !== undefined &&
        this.inMedidaConteudoSemilunar.nativeElement.value != '') {

        const objeto = {elemento: this.inMedidaConteudoSemilunar.nativeElement, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoSemilunar(false);
    }
  }

  emitIncisionalSimples() {
    if (this.ckHerniaIncisional !== undefined && this.ckHerniaIncisional.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHerniaIncisional.id);

      if (this.slConteudoIncisional != undefined && this.slConteudoIncisional.value != undefined) {
        const objeto = {elemento: this.slConteudoIncisional, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slOservadoIncisional != undefined && this.slOservadoIncisional.value != undefined) {
        const objeto = {elemento: this.slOservadoIncisional, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      // if (this.ckAoRepousoIncisional !== undefined && this.ckAoRepousoIncisional.checked) {
      //   this.termoSelecionado.emit(this.ckAoRepousoIncisional.id);
      // }
      //
      // if (this.ckManobrasIncisional !== undefined && this.ckManobrasIncisional.checked) {
      //   this.termoSelecionado.emit(this.ckManobrasIncisional.id);
      // }
      //
      // if (this.ckSinaisInflamatoriosIncisional !== undefined && this.ckSinaisInflamatoriosIncisional.checked) {
      //   this.termoSelecionado.emit(this.ckSinaisInflamatoriosIncisional.id);
      // }

      if (this.inMedidaConteudoIncisional !== undefined &&
        this.inMedidaConteudoIncisional.nativeElement.id !== undefined &&
        this.inMedidaConteudoIncisional.nativeElement.value != '') {

        const objeto = {elemento: this.inMedidaConteudoIncisional.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoIncisional(true);
    }
  }

  emitIncisionalLesao() {
    if (this.ckHerniaIncisional !== undefined && this.ckHerniaIncisional.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckHerniaIncisional.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slConteudoIncisional != undefined && this.slConteudoIncisional.value != undefined) {
        const objeto = {elemento: this.slConteudoIncisional, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      if (this.slOservadoIncisional != undefined && this.slOservadoIncisional.value != undefined) {
        const objeto = {elemento: this.slOservadoIncisional, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      // if (this.ckAoRepousoIncisional !== undefined && this.ckAoRepousoIncisional.checked) {
      //   const objeto = {id: this.ckAoRepousoIncisional.id, cenario: this.countLesoes};
      //   this.lesoesSelecionadas.emit(objeto);
      // }
      //
      // if (this.ckManobrasIncisional !== undefined && this.ckManobrasIncisional.checked) {
      //   const objeto = {id: this.ckManobrasIncisional.id, cenario: this.countLesoes};
      //   this.lesoesSelecionadas.emit(objeto);
      // }
      //
      // if (this.ckSinaisInflamatoriosIncisional !== undefined && this.ckSinaisInflamatoriosIncisional.checked) {
      //   const objeto = {id: this.ckSinaisInflamatoriosIncisional.id, cenario: this.countLesoes};
      //   this.lesoesSelecionadas.emit(objeto);
      // }

      if (this.inMedidaConteudoIncisional !== undefined &&
        this.inMedidaConteudoIncisional.nativeElement.id !== undefined &&
        this.inMedidaConteudoIncisional.nativeElement.value != '') {

        const objeto = {elemento: this.inMedidaConteudoIncisional.nativeElement, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoIncisional(false);
    }
  }

  formataMedindoSupraUmbilical(isPrimeiraLesao) {
    let medindoValor = '';

    // COLO Supra-Umbilical**********************************
    if (this.inColoSupraUmbilical1 != undefined &&
      this.inColoSupraUmbilical1.nativeElement.id != undefined &&
      this.inColoSupraUmbilical1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inColoSupraUmbilical1.nativeElement.value);
    }

    if (this.inColoSupraUmbilical2 != undefined &&
      this.inColoSupraUmbilical2.nativeElement.id != undefined &&
      this.inColoSupraUmbilical2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inColoSupraUmbilical2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inColoSupraUmbilical2.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = {id: 4177, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao};
      this.termosGenericos.emit(objetoLesao);
    }


   // DISTANCIA DA CICATRIZ UMBILICAL*********************************
    medindoValor = '';
    if (this.inDistCicatrizUmbilical != undefined &&
      this.inDistCicatrizUmbilical.nativeElement.id != undefined &&
      this.inDistCicatrizUmbilical.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inDistCicatrizUmbilical.nativeElement.value);
      const objetoElemento = {id: 4178, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao};
      this.termosGenericos.emit(objetoLesao);    
    }


    // MEDIDA CONTEUDO herniario **********************************
    medindoValor = '';

    if (this.inMedidaConteudoSupra1 != undefined &&
      this.inMedidaConteudoSupra1.nativeElement.id != undefined &&
      this.inMedidaConteudoSupra1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaConteudoSupra1.nativeElement.value);
    }

    if (this.inMedidaConteudoSupra2 != undefined &&
      this.inMedidaConteudoSupra2.nativeElement.id != undefined &&
      this.inMedidaConteudoSupra2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaConteudoSupra2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaConteudoSupra2.nativeElement.value);
      }
    }

    if (this.inMedidaConteudoSupra3 != undefined &&
      this.inMedidaConteudoSupra3.nativeElement.id != undefined &&
      this.inMedidaConteudoSupra3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaConteudoSupra3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaConteudoSupra3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = {id: 4181, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao};
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoUmbilical(isPrimeiraLesao) {
    let medindoValor = '';

    // COLO **********************************
    if (this.inColoUmbilical1 != undefined &&
      this.inColoUmbilical1.nativeElement.id != undefined &&
      this.inColoUmbilical1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inColoUmbilical1.nativeElement.value);
    }

    if (this.inColoUmbilical2 != undefined &&
      this.inColoUmbilical2.nativeElement.id != undefined &&
      this.inColoUmbilical2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inColoUmbilical2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inColoUmbilical2.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = {id: 836, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao};
      this.termosGenericos.emit(objetoLesao);
    }

    // MEDIDA CONTEUDO **********************************
    medindoValor = '';

    if (this.inMedidaConteudo1 != undefined &&
      this.inMedidaConteudo1.nativeElement.id != undefined &&
      this.inMedidaConteudo1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaConteudo1.nativeElement.value);
    }

    if (this.inMedidaConteudo2 != undefined &&
      this.inMedidaConteudo2.nativeElement.id != undefined &&
      this.inMedidaConteudo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaConteudo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaConteudo2.nativeElement.value);
      }
    }

    if (this.inMedidaConteudo3 != undefined &&
      this.inMedidaConteudo3.nativeElement.id != undefined &&
      this.inMedidaConteudo3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaConteudo3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaConteudo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = {id: 840, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao};
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoParamediana(isPrimeiraLesao) {
    let medindoValor = '';

    // COLO **********************************
    if (this.inColoParamediana1 != undefined &&
      this.inColoParamediana1.nativeElement.id != undefined &&
      this.inColoParamediana1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inColoParamediana1.nativeElement.value);
    }

    if (this.inColoParamediana2 != undefined &&
      this.inColoParamediana2.nativeElement.id != undefined &&
      this.inColoParamediana2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inColoParamediana2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inColoParamediana2.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = {id: 843, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao};
      this.termosGenericos.emit(objetoLesao);
    }

    // MEDIDA CONTEUDO **********************************
    medindoValor = '';

    if (this.inMedidaConteudoParamediana1 != undefined &&
      this.inMedidaConteudoParamediana1.nativeElement.id != undefined &&
      this.inMedidaConteudoParamediana1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaConteudoParamediana1.nativeElement.value);
    }

    if (this.inMedidaConteudoParamediana2 != undefined &&
      this.inMedidaConteudoParamediana2.nativeElement.id != undefined &&
      this.inMedidaConteudoParamediana2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaConteudoParamediana2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaConteudoParamediana2.nativeElement.value);
      }
    }

    if (this.inMedidaConteudoParamediana3 != undefined &&
      this.inMedidaConteudoParamediana3.nativeElement.id != undefined &&
      this.inMedidaConteudoParamediana3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaConteudoParamediana3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaConteudoParamediana3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = {id: 848, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao};
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoEpigastrica(isPrimeiraLesao) {
    let medindoValor = '';

    // COLO **********************************
    if (this.inColoEpigastrica1 != undefined &&
      this.inColoEpigastrica1.nativeElement.id != undefined &&
      this.inColoEpigastrica1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inColoEpigastrica1.nativeElement.value);
    }

    if (this.inColoEpigastrica2 != undefined &&
      this.inColoEpigastrica2.nativeElement.id != undefined &&
      this.inColoEpigastrica2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inColoEpigastrica2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inColoEpigastrica2.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = {id: 851, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao};
      this.termosGenericos.emit(objetoLesao);
    }

    // MEDIDA CONTEUDO **********************************
    medindoValor = '';

    if (this.inMedidaConteudoEpigastrica1 != undefined &&
      this.inMedidaConteudoEpigastrica1.nativeElement.id != undefined &&
      this.inMedidaConteudoEpigastrica1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaConteudoEpigastrica1.nativeElement.value);
    }

    if (this.inMedidaConteudoEpigastrica2 != undefined &&
      this.inMedidaConteudoEpigastrica2.nativeElement.id != undefined &&
      this.inMedidaConteudoEpigastrica2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaConteudoEpigastrica2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaConteudoEpigastrica2.nativeElement.value);
      }
    }

    if (this.inMedidaConteudoEpigastrica3 != undefined &&
      this.inMedidaConteudoEpigastrica3.nativeElement.id != undefined &&
      this.inMedidaConteudoEpigastrica3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaConteudoEpigastrica3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaConteudoEpigastrica3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = {id: 855, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao};
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoSemilunar(isPrimeiraLesao) {
    let medindoValor = '';

    // COLO **********************************
    if (this.inColoSemilunar1 != undefined &&
      this.inColoSemilunar1.nativeElement.id != undefined &&
      this.inColoSemilunar1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inColoSemilunar1.nativeElement.value);
    }

    if (this.inColoSemilunar2 != undefined &&
      this.inColoSemilunar2.nativeElement.id != undefined &&
      this.inColoSemilunar2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inColoSemilunar2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inColoSemilunar2.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = {id: 859, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao};
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoIncisional(isPrimeiraLesao) {
    let medindoValor = '';

    // COLO **********************************
    if (this.inColoIncisional1 != undefined &&
      this.inColoIncisional1.nativeElement.id != undefined &&
      this.inColoIncisional1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inColoIncisional1.nativeElement.value);
    }

    if (this.inColoIncisional2 != undefined &&
      this.inColoIncisional2.nativeElement.id != undefined &&
      this.inColoIncisional2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inColoIncisional2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inColoIncisional2.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = {id: 866, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao};
      this.termosGenericos.emit(objetoLesao);
    }
  }

  verificaAlterado() {
    if (!this.ckHernialUmbilical.checked &&
      !this.ckHerniaParamediana.checked &&
      !this.ckHerniaSemilunar.checked &&
      !this.ckHerniaIncisional.checked &&
      !this.ckHerniaEpigastrica.checked) {
      // Marca normal
      this.rdAusente.checked = true;
    }
  }

}
