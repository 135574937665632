import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validacoes } from '../../../../utils/validacoes';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { CommonService } from 'src/app/services/common.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-joelho-bilateral',
  templateUrl: './joelho-bilateral.component.html',
  styleUrls: ['./joelho-bilateral.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
})
export class JoelhoBilateralComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[1258],blockID:88},
    {listTerm:[1260],blockID:89},
    {listTerm:[1264],blockID:90},
    {listTerm:[1270],blockID:91},
    {listTerm:[1272],blockID:92},
    {listTerm:[1274],blockID:93},
    {listTerm:[4261],blockID:286},
    {listTerm:[1282],blockID:94},
    {listTerm:[1284],blockID:95},
    {listTerm:[1287],blockID:96},
  ]

  descricoesDireita: DescricaoConclusao[] = [
    { value: 'Pele e tecido subcutâneo preservados.', blockID: 88, textTermID: 0, visivel: true },
    { value: 'Superfícies ósseas regulares ao método.', blockID: 89, textTermID: 0, visivel: true },
    { value: 'Tendões patelar e quadricipital com espessura e ecogenicidade preservadas.', blockID: 90, textTermID: 0, visivel: true },
    { value: 'Tendões da pata anserina com espessura e ecogenicidade preservadas.', blockID: 91, textTermID: 0, visivel: true },
    { value: 'Trato iliotibial sem alterações aparentes.', blockID: 92, textTermID: 0, visivel: true },
    { value: 'Complexos ligamentares colaterais (medial) sem anormalidades.', blockID: 93, textTermID: 0, visivel: true },
    { value: 'Complexos ligamentares colaterais (lateral) sem anormalidades.', blockID: 286, textTermID: 0, visivel: true },
    { value: 'Gordura de Hoffa com ecogenicidade preservada.', blockID: 94, textTermID: 0, visivel: true },
    { value: 'Ausência de derrame articular significativo.', blockID: 95, textTermID: 0, visivel: true },
    { value: 'Fossa poplítea sem particularidades.', blockID: 96, textTermID: 0, visivel: true }
  ];

  descricoesEsquerda: DescricaoConclusao[] = [
    { value: 'Pele e tecido subcutâneo preservados.', blockID: 88, textTermID: 0, visivel: true },
    { value: 'Superfícies ósseas regulares ao método.', blockID: 89, textTermID: 0, visivel: true },
    { value: 'Tendões patelar e quadricipital com espessura e ecogenicidade preservadas.', blockID: 90, textTermID: 0, visivel: true },
    { value: 'Tendões da pata anserina com espessura e ecogenicidade preservadas.', blockID: 91, textTermID: 0, visivel: true },
    { value: 'Trato iliotibial sem alterações aparentes.', blockID: 92, textTermID: 0, visivel: true },
    { value: 'Complexos ligamentares colaterais (medial) sem anormalidades.', blockID: 93, textTermID: 0, visivel: true },
    { value: 'Complexos ligamentares colaterais (lateral) sem anormalidades.', blockID: 286, textTermID: 0, visivel: true },
    { value: 'Gordura de Hoffa com ecogenicidade preservada.', blockID: 94, textTermID: 0, visivel: true },
    { value: 'Ausência de derrame articular significativo.', blockID: 95, textTermID: 0, visivel: true },
    { value: 'Fossa poplítea sem particularidades.', blockID: 96, textTermID: 0, visivel: true }
  ];

  conclusoesDireita: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 88, textTermID: 0, visivel: true },
    { value: '', blockID: 89, textTermID: 0, visivel: true },
    { value: '', blockID: 90, textTermID: 0, visivel: true },
    { value: '', blockID: 91, textTermID: 0, visivel: true },
    { value: '', blockID: 92, textTermID: 0, visivel: true },
    { value: '', blockID: 93, textTermID: 0, visivel: true },
    { value: '', blockID: 286, textTermID: 0, visivel: true },
    { value: '', blockID: 94, textTermID: 0, visivel: true },
    { value: '', blockID: 95, textTermID: 0, visivel: true },
    { value: '', blockID: 96, textTermID: 0, visivel: true }
  ];

  conclusoesEsquerda: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 88, textTermID: 0, visivel: true },
    { value: '', blockID: 89, textTermID: 0, visivel: true },
    { value: '', blockID: 90, textTermID: 0, visivel: true },
    { value: '', blockID: 91, textTermID: 0, visivel: true },
    { value: '', blockID: 92, textTermID: 0, visivel: true },
    { value: '', blockID: 93, textTermID: 0, visivel: true },
    { value: '', blockID: 286, textTermID: 0, visivel: true },
    { value: '', blockID: 94, textTermID: 0, visivel: true },
    { value: '', blockID: 95, textTermID: 0, visivel: true },
    { value: '', blockID: 96, textTermID: 0, visivel: true }
  ];

  genericSuperficieOsseaDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1262, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1265, term: '[Localizacao osteofitos]' }
  ];

  genericSuperficieOsseaEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1262, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1265, term: '[Localizacao osteofitos]' }
  ];

  genericDerrameArticularDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1286, term: '[Volume]' }
  ];

  genericDerrameArticularEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1286, term: '[Volume]' }
  ];

  genericFossaPopliteaDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1291, term: '[Medida]' }
  ];

  genericFossaPopliteaEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1291, term: '[Medida]' }
  ];

  genericTendoesPatelarDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1267, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1269, term: '[Localizacao quadricipital]' }
  ];

  genericTendoesPatelarEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1267, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1269, term: '[Localizacao quadricipital]' }
  ];

  genericLigColateralMedialDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1276, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1279, term: '[Localizacao]' }
  ];

  genericLigColateralMedialEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1276, term: '[Localizacao]' },
    { value: '', selected: false, termID: 1279, term: '[Localizacao]' }
  ];

  genericLigColateralLateralDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 4262, term: '[Localizacao]' },
    { value: '', selected: false, termID: 4263, term: '[Localizacao]' }
  ];

  genericLigColateralLateralEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 4262, term: '[Localizacao]' },
    { value: '', selected: false, termID: 4263, term: '[Localizacao]' }
  ];

  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  htmlContentDireita: string;
  htmlContentEsquerda: string;
  maskDireita: string;
  maskEsquerda: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  cabecalhoTexto = '';
  indicacaoTextoDireita = '';
  indicacaoTextoEsquerda = '';
  achadosAdTextoEsquerda = '';
  achadosAdTextoDireita = '';
  Lado = '';
  rodapeTexto = '';

  cabecalho = false;
  lateralidade = false;
  peleTecido = false;
  superficieOssea = false;
  tendoesPatelar = false;
  tendoesPata = false;
  tratoIliotibial = false;
  LigamentoColateraMedial = false;
  LigamentoColateraLateral = false;
  gorduraHoffa = false;
  derrameArticular
  fossePoplitea = false;
  indicacao = true;
  achadosTexto = false;
  rodape = false;
  editLaudoMode: boolean = false;

  medicalReportTypeID = 59;
  peleTecidoID = 88;
  superficieOsseaID = 89;
  tendoesPatelarID = 90;
  tendoesPataID = 91;
  tratoIliotibialID = 92;
  LigamentoColateraMedialID = 93;
  LigamentoColateraLateralID = 286;
  gorduraHoffaID = 94;
  derrameArticularID = 95;
  fossePopliteaID = 96;
  public switchFormReport = true;
  public generoLateralidade: string;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  unidadeMedida: string;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _copyPaste: CopyPaste,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _config: ConfigLaudoService,
    public _addText: AddText,
    private dataService:DataTermService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("joelhoAnalise", this.descricoesDireita, this.conclusoesDireita);
    this._pouchService.loadDatabase("joelhoAnalise", this.descricoesEsquerda, this.conclusoesEsquerda);
    this.resetaDialog();
    this.generoLateralidade = 'masculino';
    this.Subtitulo = 'Indicação'
    this.subtituloBloco = 'Preencha o campo indicação';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    //  });
    const listBlocks = [
      this.peleTecidoID,
      this.superficieOsseaID,
      this.tendoesPatelarID,
      this.tendoesPataID,
      this.tratoIliotibialID,
      this.LigamentoColateraLateralID,
      this.LigamentoColateraMedialID,
      this.gorduraHoffaID,
      this.derrameArticularID,
      this.fossePopliteaID
    ];
    this._pouchService.loadMask(this.medicalReportTypeID, "joelhoBilateralMask").then((obj: any) => {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.maskDireita = obj.mask.replace('{0}', 'DIREITO');
      this.maskEsquerda = obj.mask.replace('{0}', 'ESQUERDO');
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "joelho");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-joelho');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-joelho');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
          }
        });
      }
    });
  }

  public resetaLaudo() {
    this._pouchService.getAll("joelhoAnalise").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoesDireita[i].value = obj[i].doc.value;
            this.descricoesEsquerda[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoesDireita[concCalc].value = obj[i].doc.value;
            this.conclusoesEsquerda[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.Lado = "";
    this.cabecalhoTexto = "";
    this.indicacaoTextoDireita = "";
    this.indicacaoTextoEsquerda = "";
    this.achadosAdTextoEsquerda = '';
    this.achadosAdTextoDireita = '';
    this.showForm = false;

    setTimeout(() => {
      this.showForm = true;
      this.AdicionaTexto(this.newEditor);
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: true
    },
    {
      titulo: 'Pele e tecido subcutâneo',
      id: this.peleTecidoID,
      name: 'pele-tecido',
      selected: false,
    },
    {
      titulo: 'Superfície óssea',
      id: this.superficieOsseaID,
      name: 'superficie-ossea',
      selected: false
    },
    {
      titulo: 'Tendões Patelar e Quadricipital',
      id: this.tendoesPatelarID,
      name: 'tendoes-patelar',
      selected: false
    },
    {
      titulo: 'Tendões da Pata Anserina',
      id: this.tendoesPataID,
      name: 'tendoes-pata',
      selected: false
    },
    {
      titulo: 'Trato iliotibial',
      id: this.tratoIliotibialID,
      name: 'trato-iliotibial',
      selected: false
    },
    {
      titulo: 'Ligamento Colateral Medial',
      id: this.LigamentoColateraMedialID,
      name: 'lig-colateral-medial',
      selected: false
    },
    {
      titulo: 'Ligamento Colateral Lateral',
      id: this.LigamentoColateraLateralID,
      name: 'lig-colateral-lateral',
      selected: false
    },
    {
      titulo: 'Gordura de Hoffa',
      id: this.gorduraHoffaID,
      name: 'gordura-hoffa',
      selected: false
    },
    {
      titulo: 'Derrame articular',
      id: this.derrameArticularID,
      name: 'derrame-articular',
      selected: false
    },
    {
      titulo: 'Fossa Poplítea',
      id: this.fossePopliteaID,
      name: 'fossa-poplitea',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.lateralidade = (itemSelecionado.id === 'lateralidade');
    this.peleTecido = (itemSelecionado.id === this.peleTecidoID);
    this.superficieOssea = (itemSelecionado.id === this.superficieOsseaID);
    this.tendoesPatelar = (itemSelecionado.id === this.tendoesPatelarID);
    this.tendoesPata = (itemSelecionado.id === this.tendoesPataID);
    this.tratoIliotibial = (itemSelecionado.id === this.tratoIliotibialID);
    this.LigamentoColateraMedial = (itemSelecionado.id === this.LigamentoColateraMedialID);
    this.LigamentoColateraLateral = (itemSelecionado.id === this.LigamentoColateraLateralID);
    this.gorduraHoffa = (itemSelecionado.id === this.gorduraHoffaID);
    this.derrameArticular = (itemSelecionado.id === this.derrameArticularID);
    this.fossePoplitea = (itemSelecionado.id === this.fossePopliteaID);
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;


    this.genericSuperficieOsseaDireita = [
      { value: '', selected: false, termID: 1262, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1265, term: '[Localizacao osteofitos]' }
    ];

    this.genericSuperficieOsseaEsquerda = [
      { value: '', selected: false, termID: 1262, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1265, term: '[Localizacao osteofitos]' }
    ];

    this.genericDerrameArticularDireita = [
      { value: '', selected: false, termID: 1286, term: '[Volume]' }
    ];

    this.genericDerrameArticularEsquerda = [
      { value: '', selected: false, termID: 1286, term: '[Volume]' }
    ];

    this.genericFossaPopliteaDireita = [
      { value: '', selected: false, termID: 1291, term: '[Medida]' }
    ];

    this.genericFossaPopliteaEsquerda = [
      { value: '', selected: false, termID: 1291, term: '[Medida]' }
    ];

    this.genericTendoesPatelarDireita = [
      { value: '', selected: false, termID: 1267, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1269, term: '[Localizacao quadricipital]' }
    ];

    this.genericTendoesPatelarEsquerda = [
      { value: '', selected: false, termID: 1267, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1269, term: '[Localizacao quadricipital]' }
    ];

    this.genericLigColateralMedialDireita = [
      { value: '', selected: false, termID: 1276, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1279, term: '[Localizacao]' }
    ];

    this.genericLigColateralMedialEsquerda = [
      { value: '', selected: false, termID: 1276, term: '[Localizacao]' },
      { value: '', selected: false, termID: 1279, term: '[Localizacao]' }
    ];

    this.genericLigColateralLateralDireita = [
      { value: '', selected: false, termID: 4262, term: '[Localizacao]' },
      { value: '', selected: false, termID: 4263, term: '[Localizacao]' }
    ];

    this.genericLigColateralLateralEsquerda = [
      { value: '', selected: false, termID: 4262, term: '[Localizacao]' },
      { value: '', selected: false, termID: 4263, term: '[Localizacao]' }
    ];
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaRodapeCabecalho({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaTermosDireita($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaTermosEsquerda($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaLadoDireita($event) {
    this.Lado = $event;
    this.AdicionaTextoDireita();
  }

  enviaLadosEsquerda($event) {
    this.Lado = $event;
    this.AdicionaTextoEsquerda();
  }

  enviaIndicacaoDireita(texto) {
    if (texto)
      this.indicacaoTextoDireita = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTextoDireita = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaDireita({
        blockId: 'indicacao',
        indicacao: this.indicacaoTextoDireita
      });
    }
  }

  enviaIndicacaoEsquerda(texto) {
    if (texto)
      this.indicacaoTextoEsquerda = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTextoEsquerda = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaEsquerda({
        blockId: 'indicacao',
        indicacao: this.indicacaoTextoEsquerda
      });
    }
  }

  enviaAchadosDireita(texto) {
    if (texto)
      this.achadosAdTextoDireita = '<br/><br/><b>Achados adicionais:</b><br/>' + texto  + '<br/><br/>';
    else
      this.achadosAdTextoDireita = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaDireita({
        blockId: 'achados',
        achados: this.achadosAdTextoDireita
      });
    }
  }

  enviaAchadosEsquerda(texto) {
    if (texto)
      this.achadosAdTextoEsquerda = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTextoEsquerda = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinhaEsquerda({
        blockId: 'achados',
        achados: this.achadosAdTextoEsquerda
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaRodapeCabecalho({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  adicionaSelecaoSuperficieOsseaDireita($event) {
    this.genericSuperficieOsseaDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = $event.elemento.value;
        }
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoSuperficieOsseaEsquerda($event) {
    this.genericSuperficieOsseaEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = $event.elemento.value;
        }
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoDerrameArticularDireita($event) {
    this.genericDerrameArticularDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoDerrameArticularEsquerda($event) {
    this.genericDerrameArticularEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoFossaPopliteaDireita($event) {
    this.genericFossaPopliteaDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoFossaPopliteaEsquerda($event) {
    this.genericFossaPopliteaEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoTendoesPatelarDireita($event) {
    this.genericTendoesPatelarDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoTendoesPatelarEsquerda($event) {
    this.genericTendoesPatelarEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoLigColateralMedialDireita($event) {
    this.genericLigColateralMedialDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoLigColateralMedialEsquerda($event) {
    this.genericLigColateralMedialEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoLigColateralLateralDireita($event) {
    this.genericLigColateralLateralDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoLigColateralLateralEsquerda($event) {
    this.genericLigColateralLateralEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  consultarAPIDireita() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.peleTecido) {
        this.term.blockID = this.peleTecidoID;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.peleTecidoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.peleTecidoID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.peleTecidoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.peleTecidoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.peleTecidoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.superficieOssea) {
        this.term.blockID = this.superficieOsseaID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.superficieOsseaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoSuperficieOsseaDireita(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.superficieOsseaID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.superficieOsseaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoSuperficieOsseaDireita(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.superficieOsseaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.superficieOsseaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendoesPata) {
        this.term.blockID = this.tendoesPataID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.tendoesPataID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.tendoesPataID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendoesPataID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.tendoesPataID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendoesPataID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tratoIliotibial) {
        this.term.blockID = this.tratoIliotibialID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.tratoIliotibialID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.tratoIliotibialID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.tratoIliotibialID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.tratoIliotibialID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tratoIliotibialID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.gorduraHoffa) {
        this.term.blockID = this.gorduraHoffaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.gorduraHoffaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.gorduraHoffaID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.gorduraHoffaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.gorduraHoffaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.gorduraHoffaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.derrameArticular) {
        this.term.blockID = this.derrameArticularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.derrameArticularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoDerrameArtircularDireita(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.derrameArticularID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.derrameArticularID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDescricaoDerrameArtircularDireita(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.derrameArticularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.derrameArticularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.fossePoplitea) {
        this.term.blockID = this.fossePopliteaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.fossePopliteaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoFossaPopliteaDireita(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.fossePopliteaID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.fossePopliteaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDescricaoFossaPopliteaDireita(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.fossePopliteaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.fossePopliteaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendoesPatelar) {
        this.term.blockID = this.tendoesPatelarID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.tendoesPatelarID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTendoesPatelarDireita(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.tendoesPatelarID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendoesPatelarID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoTendoesPatelarDireita(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.tendoesPatelarID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendoesPatelarID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.LigamentoColateraMedial) {
        this.term.blockID = this.LigamentoColateraMedialID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.LigamentoColateraMedialID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoLigColateralMedialDireita(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.LigamentoColateraMedialID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.LigamentoColateraMedialID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDescricaoLigColateralMedialDireita(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.LigamentoColateraMedialID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.LigamentoColateraMedialID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.LigamentoColateraLateral) {
        this.term.blockID = this.LigamentoColateraLateralID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesDireita.forEach(obj => {
            if (obj.blockID === this.LigamentoColateraLateralID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoLigColateralLateralDireita(resultado.text);
              });

              let index = this.descricoesDireita.findIndex(x => x.blockID === this.LigamentoColateraLateralID);
              if (index > -1) {
                this.descricoesDireita[index].value = obj.value;
                this.descricoesDireita[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoDireita();
            }
          });

          let obj = {
            value: '',
            blockID: this.LigamentoColateraLateralID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDescricaoLigColateralLateralDireita(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesDireita.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesDireita.find(x => x.blockID === 0);
          const conclusao = this.conclusoesDireita.find(x => x.blockID === this.LigamentoColateraLateralID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.LigamentoColateraLateralID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaDireita({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      }
    }
  }

  consultarAPIEsquerda() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.peleTecido) {
        this.term.blockID = this.peleTecidoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.peleTecidoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.peleTecidoID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.peleTecidoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.peleTecidoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.peleTecidoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.superficieOssea) {
        this.term.blockID = this.superficieOsseaID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.superficieOsseaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoSuperficieOsseaEsquerda(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.superficieOsseaID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.superficieOsseaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoSuperficieOsseaEsquerda(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.superficieOsseaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.superficieOsseaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendoesPata) {
        this.term.blockID = this.tendoesPataID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.tendoesPataID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.tendoesPataID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendoesPataID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.tendoesPataID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendoesPataID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tratoIliotibial) {
        this.term.blockID = this.tratoIliotibialID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.tratoIliotibialID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.tratoIliotibialID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.tratoIliotibialID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.tratoIliotibialID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tratoIliotibialID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.gorduraHoffa) {
        this.term.blockID = this.gorduraHoffaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.gorduraHoffaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.gorduraHoffaID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.gorduraHoffaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.gorduraHoffaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.gorduraHoffaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.derrameArticular) {
        this.term.blockID = this.derrameArticularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.derrameArticularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoDerrameArtircularEsquerda(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.derrameArticularID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.derrameArticularID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDescricaoDerrameArtircularEsquerda(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.derrameArticularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.derrameArticularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.fossePoplitea) {
        this.term.blockID = this.fossePopliteaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.fossePopliteaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoFossaPopliteaEsquerda(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.fossePopliteaID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.fossePopliteaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDescricaoFossaPopliteaEsquerda(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.fossePopliteaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.fossePopliteaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendoesPatelar) {
        this.term.blockID = this.tendoesPatelarID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.tendoesPatelarID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTendoesPatelarEsquerda(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.tendoesPatelarID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.tendoesPatelarID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoTendoesPatelarEsquerda(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.tendoesPatelarID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendoesPatelarID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.LigamentoColateraMedial) {
        this.term.blockID = this.LigamentoColateraMedialID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.LigamentoColateraMedialID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoLigColateralMedialEsquerda(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.LigamentoColateraMedialID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.LigamentoColateraMedialID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDescricaoLigColateralMedialEsquerda(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.LigamentoColateraMedialID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.LigamentoColateraMedialID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.LigamentoColateraLateral) {
        this.term.blockID = this.LigamentoColateraLateralID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoesEsquerda.forEach(obj => {
            if (obj.blockID === this.LigamentoColateraLateralID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoLigColateralLateralEsquerda(resultado.text);
              });

              let index = this.descricoesEsquerda.findIndex(x => x.blockID === this.LigamentoColateraLateralID);
              if (index > -1) {
                this.descricoesEsquerda[index].value = obj.value;
                this.descricoesEsquerda[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTextoEsquerda();
            }
          });

          let obj = {
            value: '',
            blockID: this.LigamentoColateraLateralID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDescricaoLigColateralLateralEsquerda(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoesEsquerda.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoesEsquerda.find(x => x.blockID === 0);
          const conclusao = this.conclusoesEsquerda.find(x => x.blockID === this.LigamentoColateraLateralID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.LigamentoColateraLateralID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinhaEsquerda({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      }
    }
  }

  MontaDescricaoSuperficieOsseaDireita(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1262 || +term === 1265) {
          this.genericSuperficieOsseaDireita.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1262 || +term === 1265) {
          this.genericSuperficieOsseaDireita.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoSuperficieOsseaEsquerda(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1262 || +term === 1265) {
          this.genericSuperficieOsseaEsquerda.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1262 || +term === 1265) {
          this.genericSuperficieOsseaEsquerda.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoDerrameArtircularDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1286) {
        this.genericDerrameArticularDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoDerrameArtircularEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1286) {
        this.genericDerrameArticularEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoFossaPopliteaDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1291) {
        this.genericFossaPopliteaDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoFossaPopliteaEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1291) {
        this.genericFossaPopliteaEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoTendoesPatelarDireita(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1267 || +term === 1269) {
          this.genericTendoesPatelarDireita.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1269) {
          this.genericTendoesPatelarDireita.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoTendoesPatelarEsquerda(texto, contadorTexto) {
    let textoRetorno = '';
    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1267 || +term === 1269) {
          this.genericTendoesPatelarEsquerda.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1269) {
          this.genericTendoesPatelarEsquerda.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoLigColateralMedialDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1276 || +term === 1279) {
        this.genericLigColateralMedialDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoLigColateralMedialEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1276 || +term === 1279) {
        this.genericLigColateralMedialEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoLigColateralLateralDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4262 || +term === 4263) {
        this.genericLigColateralLateralDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoLigColateralLateralEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4262 || +term === 4263) {
        this.genericLigColateralLateralEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else {
      this.AdicionaTextoDireita();
      this.AdicionaTextoEsquerda();
    }

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskDireita = this.maskDireita.split('|');
    let maskEsquerda = this.maskEsquerda.split('|');

    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    //lado direito -- lado direito -- lado direito

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskDireita[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskDireita[1]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao-D`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTextoDireita}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskDireita[2]}</p>`,
      }
    });

    this.descricoesDireita.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    this.descricoesDireita.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}-D`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskDireita[3]}</p>`,
      }
    });

    this.conclusoesDireita.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao-D`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados-D`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTextoDireita}</p>`,
      }
    });

    //lado esquedo -- lado esquedo -- lado esquedo

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskEsquerda[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskEsquerda[1]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao-E`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTextoEsquerda}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskEsquerda[2]}</p>`,
      }
    });

    this.descricoesEsquerda.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    this.descricoesEsquerda.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}-E`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskEsquerda[3]}</p>`,
      }
    });

    this.conclusoesEsquerda.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao-E`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados-E`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTextoEsquerda}</p>`,
      }
    });

    //Rodape
    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  AdicionaTextoDireita() {
    let i;
    let maskSplit = this.maskDireita.split('|');
    let cabecalho = this.cabecalhoTexto;
    let rodape = this.rodapeTexto;

    this.htmlContentDireita = maskSplit[0];
    this.htmlContentDireita += this.Lado.toUpperCase();
    this.htmlContentDireita += maskSplit[1];
    this.htmlContentDireita += this.indicacaoTextoDireita;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContentDireita += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContentDireita += maskSplit[2];

    for (i = 0; i < this.descricoesDireita.length; i++) {
      var newValue = this.descricoesDireita[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoesDireita[i].value = newValue2;
    }
    for (i = 0; i < this.descricoesDireita.length; i++) {
      if (this.descricoesDireita[i].value) {
        this.htmlContentDireita += '<br/>' + this.descricoesDireita[i].value + '<br/>';
      }
    }
    this.htmlContentDireita += maskSplit[3];
    for (i = 0; i < this.conclusoesDireita.length; i++) {
      if (this.conclusoesDireita[i].value) {
        this.htmlContentDireita += '<br/>' + this.conclusoesDireita[i].value + '<br/>';
      }
    }
    this.htmlContentDireita += this.achadosAdTextoDireita;

    if (this.cabecalhoTexto !== '' && this.rodapeTexto === '')
      this.htmlContent = cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    if (this.cabecalhoTexto === '' && this.rodapeTexto !== '')
      this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    if (this.cabecalhoTexto !== '' && this.rodapeTexto !== '')
      this.htmlContent = cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    if (this.cabecalhoTexto === '' && this.rodapeTexto === '')
      this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
  }

  AdicionaTextoEsquerda() {
    let i;
    let maskSplit = this.maskEsquerda.split('|');
    let cabecalho = this.cabecalhoTexto;
    let rodape = this.rodapeTexto;

    this.htmlContentEsquerda = maskSplit[0];
    this.htmlContentEsquerda += this.Lado.toUpperCase();
    this.htmlContentEsquerda += maskSplit[1];
    this.htmlContentEsquerda += this.indicacaoTextoEsquerda;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContentEsquerda += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContentEsquerda += maskSplit[2];

    for (i = 0; i < this.descricoesEsquerda.length; i++) {
      var newValue = this.descricoesEsquerda[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoesEsquerda[i].value = newValue2;
    }
    for (i = 0; i < this.descricoesEsquerda.length; i++) {
      if (this.descricoesEsquerda[i].value) {
        this.htmlContentEsquerda += '<br/>' + this.descricoesEsquerda[i].value + '<br/>';
      }
    }
    this.htmlContentEsquerda += maskSplit[3];
    for (i = 0; i < this.conclusoesEsquerda.length; i++) {
      if (this.conclusoesEsquerda[i].value) {
        this.htmlContentEsquerda += '<br/>' + this.conclusoesEsquerda[i].value + '<br/>';
      }
    }
    this.htmlContentEsquerda += this.achadosAdTextoEsquerda;

    if (this.cabecalhoTexto !== '' && this.rodapeTexto === '')
      this.htmlContent = cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
    if (this.cabecalhoTexto === '' && this.rodapeTexto !== '')
      this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    if (this.cabecalhoTexto !== '' && this.rodapeTexto !== '')
      this.htmlContent = cabecalho + this.htmlContentDireita + '<br>' + this.htmlContentEsquerda + rodape;
    if (this.cabecalhoTexto === '' && this.rodapeTexto === '')
      this.htmlContent = this.htmlContentDireita + '<br>' + this.htmlContentEsquerda;
  }

  atualizaLinhaDireita({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoesDireita.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId}-D p`);
        const conclusion = document.querySelector('#e-conclusao-D p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesDireita.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesDireita.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

			} else if(indicacao !== undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const indicacaoTxt = document.querySelector(`#e-${blockId}-D p`);

          if(indicacaoTxt) {
            indicacaoTxt.innerHTML = indicacao;
          }
				}

        if(achados !== undefined) {
          const achadosTxt = document.querySelector(`#e-${blockId}-D p`);

          if(achadosTxt) {
						achadosTxt.innerHTML = achados;
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  atualizaLinhaEsquerda({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoesEsquerda.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId}-E p`);
        const conclusion = document.querySelector('#e-conclusao-E p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesEsquerda.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoesEsquerda.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
			} else if(indicacao !== undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const indicacaoTxt = document.querySelector(`#e-${blockId}-E`);

          if(indicacaoTxt) {
            indicacaoTxt.innerHTML = indicacao;
          }
				}

        if(achados !== undefined) {
          const achadosTxt = document.querySelector(`#e-${blockId}-E`);

          if(achadosTxt) {
						achadosTxt.innerHTML = achados;
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  atualizaRodapeCabecalho({
		blockId,
    cabecalho,
    rodape,
	}: IAtualizaLinha): void {
    if(cabecalho !== undefined) {
      const header = document.querySelector(`#e-${blockId}`);

      if(header) {
        header.innerHTML = cabecalho;
      }
    }

    if(rodape !== undefined) {
      const footer = document.querySelector(`#e-${blockId}`);

      if(footer) {
        footer.innerHTML = rodape;
      }
    }
    this._addText.lineHeight();
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

}
