import { Component, OnInit, Inject, SecurityContext } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from "@angular/platform-browser";


@Component({
  selector: 'app-laudo',
  templateUrl: './laudo.component.html',
  styleUrls: ['./laudo.component.css']
})
export class LaudoComponent implements OnInit {
  safeURL: SafeResourceUrl;
  unidade: any;
  constructor(
    private dialog: MatDialogRef<LaudoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer

  ) {
    this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.url)
    this.unidade = data.unidade
  }

  ngOnInit() {
  }


}
