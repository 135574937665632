import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import {Validacoes} from '../../../../../../utils/validacoes';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-canal-guyon',
  templateUrl: './canal-guyon.component.html',
  styleUrls: ['./canal-guyon.component.css']
})
export class CanalGuyonComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdFormacao') rdFormacao: MatRadioButton;
  @ViewChild('slFluxo') slFluxo: MatSelect;
  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  @ViewChild('inMedida3') inMedida3: ElementRef;

  fluxos: ArrGenerico[] = [
    { value: 'sem fluxo vascular ao estudo Doppler', viewValue: 'Sem fluxo vascular' },
    { value: 'com fluxo vascular ao estudo Doppler', viewValue: 'Com fluxo vascular' }
  ];

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahFormacao: true
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahFormacao = false;
    this.catchIDs();
  }

  clickFormacao() {
    this.showAndHideTerms.sahFormacao = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.slFluxo && this.slFluxo.value)
    || (this.inMedida1 && this.inMedida1.nativeElement.value || (this.inMedida2 && this.inMedida2.nativeElement.value)
    || (this.inMedida3 && this.inMedida3.nativeElement.value))){
      this.rdFormacao.checked = true;
    }
  }

  blurMedindo() {
    this.verificaLesoes();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdFormacao.checked) {
      this.termoSelecionado.emit(this.rdFormacao.value);
      
      if (this.slFluxo && this.slFluxo.value) {
        const objeto = { elemento: this.slFluxo, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      this.formataMedida();
    }

    this.listaCompleta.emit();
  }

  formataMedida() {
    let medindoValor = '';

    if (this.inMedida1 && this.inMedida1.nativeElement.value) {

      medindoValor = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);
    }

    if (this.inMedida2 && this.inMedida2.nativeElement.value) {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
      }
    }

    if (this.inMedida3 && this.inMedida3.nativeElement.value) {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = {id: 1167, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(objetoLesao);
    }
  }
}
