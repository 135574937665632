import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-tendao-calcaneo',
  templateUrl: './tendao-calcaneo.component.html',
  styleUrls: ['./tendao-calcaneo.component.css']
})
export class TendaoCalcaneoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTendinopatiaSem') rdTendinopatiaSem: MatRadioButton;
  @ViewChild('rdTendinopatiaParcial') rdTendinopatiaParcial: MatRadioButton;
  @ViewChild('rdTendinopatiaCompleta') rdTendinopatiaCompleta: MatRadioButton;
  @ViewChild('slSegmento') slSegmento: MatSelect;
  // Parcial
  @ViewChild('slAreaTransversa') slAreaTransversa: MatSelect;
  @ViewChild('inDistancia') inDistancia: ElementRef;
  // Completa
  @ViewChild('inDistCalcaneana') inDistCalcaneana: ElementRef;
  @ViewChild('inMedida') inMedida: ElementRef;

  segmentos: ArrayDropDown[] = [
    { value: 'corpo', viewValue: 'Corpo' },
    { value: 'segmento proximal', viewValue: 'Segmento proximal' },
    { value: 'segmento insercional', viewValue: 'Segmento insercional' }
  ];

  areasTransversas: ArrayDropDown[] = [
    { value: 'menos que 50%', viewValue: 'Menos que 50%' },
    { value: 'cerca de 50%', viewValue: 'Cerca de 50%' },
    { value: 'mais que 50%', viewValue: 'Mais que 50%' },
  ];

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahSegmento: false,
      sahParcial: false,
      sahCompleta: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahSegmento = false;
    this.showAndHideTerms.sahParcial = false;
    this.showAndHideTerms.sahCompleta = false;
    this.catchIDs();
  }

  clickTendiSem() {
    this.showAndHideTerms.sahSegmento = true;
    this.showAndHideTerms.sahParcial = false;
    this.showAndHideTerms.sahCompleta = false;
    this.catchIDs();
  }

  clickTendiParcial() {
    this.showAndHideTerms.sahSegmento = true;
    this.showAndHideTerms.sahParcial = true;
    this.showAndHideTerms.sahCompleta = false;
    this.catchIDs();
  }

  clickTendiCompleta() {
    this.showAndHideTerms.sahSegmento = true;
    this.showAndHideTerms.sahParcial = false;
    this.showAndHideTerms.sahCompleta = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdTendinopatiaSem.checked) {
      this.termoSelecionado.emit(this.rdTendinopatiaSem.value);

      if (this.slSegmento && this.slSegmento.value) {
        const objeto = { elemento: this.slSegmento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdTendinopatiaParcial.checked) {
      this.termoSelecionado.emit(this.rdTendinopatiaParcial.value);

      if (this.slAreaTransversa && this.slAreaTransversa.value) {
        const objeto = { elemento: this.slAreaTransversa, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistancia && this.inDistancia.nativeElement.value) {
        const objeto = { elemento: this.inDistancia.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slSegmento && this.slSegmento.value) {
        const objeto = { elemento: this.slSegmento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdTendinopatiaCompleta.checked) {
      this.termoSelecionado.emit(this.rdTendinopatiaCompleta.value);

      if (this.inDistCalcaneana && this.inDistCalcaneana.nativeElement.value) {
        const objeto = { elemento: this.inDistCalcaneana.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedida && this.inMedida.nativeElement.value) {
        const objeto = { elemento: this.inMedida.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slSegmento && this.slSegmento.value) {
        const objeto = { elemento: this.slSegmento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}