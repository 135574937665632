import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatCheckbox, MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-tendao-cabeca-longa',
  templateUrl: './tendao-cabeca-longa.component.html',
  styleUrls: ['./tendao-cabeca-longa.component.css']
})
export class TendaoCabecaLongaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTendinopatia') rdTendinopatia: MatRadioButton;
  @ViewChild('rdDistensao') rdDistensao: MatRadioButton;
  @ViewChild('rdSubluxado') rdSubluxado: MatRadioButton;

  @ViewChild('ckDistensaoLiquida') ckDistensaoLiquida: MatCheckbox;
  @ViewChild('ckSubluxado') ckSubluxado: MatCheckbox;

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahTendinopatia: false,
    };
  }

  clickDefault() {
    this.showAndHideTerms.sahTendinopatia = false;
    this.catchIDs();
  }
  clickTendinopatia() {
    this.showAndHideTerms.sahTendinopatia = true;
    this.catchIDs();
  }

  clickDistensaoLiquida() {
    if(this.ckSubluxado.checked) {
      this.ckSubluxado.checked = false;
    }
    this.catchIDs();
  }
  clickSubluxado() {
    if(this.ckDistensaoLiquida.checked) {
      this.ckDistensaoLiquida.checked = false;
    }
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdTendinopatia.checked) {
      if (this.ckDistensaoLiquida && this.ckDistensaoLiquida.checked
        && this.ckSubluxado && this.ckSubluxado.checked) {
        this.termoSelecionado.emit(this.ckDistensaoLiquida.value);
        this.termoSelecionado.emit(this.ckSubluxado.id);
      } else if (this.ckDistensaoLiquida && this.ckDistensaoLiquida.checked) {
        this.termoSelecionado.emit(this.ckDistensaoLiquida.value);
      } else if (this.ckSubluxado && this.ckSubluxado.checked) {
        this.termoSelecionado.emit(this.ckSubluxado.value);
      } else {
      this.termoSelecionado.emit(this.rdTendinopatia.value);
    }
  }

  if(this.rdDistensao.checked) {
    this.termoSelecionado.emit(this.rdDistensao.value);
  }

  if(this.rdSubluxado.checked) {
    this.termoSelecionado.emit(this.rdSubluxado.value);
  }

    this.listaCompleta.emit();
  }
}
