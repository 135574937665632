import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { Validacoes } from '../../../../utils/validacoes';
import { Injuries, Term } from '../../../../models/BO/Term';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { TermResult } from 'src/app/models/BO/TermResult';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { TermosGenericos } from 'src/app/models/Helper/TermosGenericos';
import { TermosDinamicos } from 'src/app/models/Helper/TermosDinamicos';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';
@Component({
  selector: 'app-transv-doppler',
  templateUrl: './transv-doppler.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./transv-doppler.component.css']
})
export class TransvDopplerComponent implements OnInit {

  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;


  listTermDefault=[
    {listTerm:[3835],blockID:245},
    {listTerm:[3752],blockID:242},
    {listTerm:[3904],blockID:243},
    {listTerm:[3922],blockID:246},
    {listTerm:[417],blockID:21},
    {listTerm:[3874],blockID:244},
  ]

  descricoes: DescricaoConclusao[] = [
    {
      value: 'Útero em anteversoflexão, de forma típica, contornos regulares e dimensões normais para a idade e paridade.', blockID: 245, textTermID: 0, visivel: true
    },

    { value: 'Endométrio linear e homogêneo, com espessura de.', blockID: 242, textTermID: 0, visivel: true },
    {
      value: 'Ovário direito tópico, com dimensões normais, contornos regulares e ecotextura preservada.</br>' +
        'Ovário direito:', blockID: 243, textTermID: 0, visivel: true
    },
    {
      value: 'Ovário esquerdo tópico, com dimensões normais, contornos regulares e ecotextura preservada.</br>' +
        'Ovário esquerdo:', blockID: 246, textTermID: 0, visivel: true
    },
    { value: 'Ausência de líquido livre na cavidade pélvica.', blockID: 21, textTermID: 0, visivel: true },
    { value: 'Regiões anexiais livres.', blockID: 244, textTermID: 0, visivel: true },
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Estruturas analisadas sem alterações significativas.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 245, textTermID: 0, visivel: true },
    { value: '', blockID: 242, textTermID: 0, visivel: true },
    { value: '', blockID: 243, textTermID: 0, visivel: true },
    { value: '', blockID: 246, textTermID: 0, visivel: true },
    { value: '', blockID: 21, textTermID: 0, visivel: true },
    { value: '', blockID: 244, textTermID: 0, visivel: true }
  ];

  genericTermsLiquidoLivre: TermosGenericos[] = [
    { value: '', selected: false, termID: 419, term: '[Quantidade]' },
    { value: '', selected: false, termID: 420, term: '[Conteúdo]' },
    { value: '', selected: false, termID: 421, term: '[Local]' }
  ];

  genericTermsEndometrio: TermosGenericos[] = [
    { value: '', selected: false, termID: 3757, term: '[Espessura]' },
    { value: '', selected: false, termID: 3756, term: '[Aspecto]' },
    { value: '', selected: false, termID: 3759, term: '[Medida1]' },
    { value: '', selected: false, termID: 3760, term: '[Medida2]' },
    { value: '', selected: false, termID: 3764, term: '[Parede]' },
    { value: '', selected: false, termID: 3767, term: '[Segmento]' },
    { value: '', selected: false, termID: 3768, term: '[Medida]' }
  ];

  genericTermsRegiaoAnexial: TermosGenericos[] = [
    { value: '', selected: false, termID: 3877, term: '[Calibre direito]' },
    { value: '', selected: false, termID: 3879, term: '[Calibre esquerdo]' },
    { value: '', selected: false, termID: 3882, term: '[Calibre direito]' },
    { value: '', selected: false, termID: 3884, term: '[Calibre esquerdo]' },
    { value: '', selected: false, termID: 3887, term: '[Calibre direito]' },
    { value: '', selected: false, termID: 3889, term: '[Calibre esquerdo]' },
    { value: '', selected: false, termID: 3892, term: '[Medindo direito]' },
    { value: '', selected: false, termID: 3894, term: '[Medindo esquerdo]' },
    { value: '', selected: false, termID: 3897, term: '[Medindo direito]' },
    { value: '', selected: false, termID: 3899, term: '[Medindo esquerdo]' },
    { value: '', selected: false, termID: 3941, term: '[Contornos]' },
    { value: '', selected: false, termID: 3942, term: '[Medindo Direita]' },
    { value: '', selected: false, termID: 3943, term: '[Parede]' },
    { value: '', selected: false, termID: 3944, term: '[Componente]' },
    { value: '', selected: false, termID: 3945, term: '[Medindo Esquerda]' },
    { value: '', selected: false, termID: 3946, term: '[Doppler SemCom]' },
    { value: '', selected: false, termID: 4288, term: '[Doppler SemCom]' },
    { value: '', selected: false, termID: 4289, term: '[Contornos]' },
    { value: '', selected: false, termID: 4290, term: '[Parede]' },
    { value: '', selected: false, termID: 4291, term: '[Componente]' }
  ];

  genericTermsUtero: TermosGenericos[] = [
    { value: '', selected: false, termID: 3837, term: '[Posicao Utero]' },
    { value: '', selected: false, termID: 3838, term: '[Contornos]' },
    { value: '', selected: false, termID: 3839, term: '[MedidasUt]' },
    { value: '', selected: false, termID: 3842, term: '[MedidasHi]' },
    { value: '', selected: false, termID: 3850, term: '[MedidaCol]' },
    { value: '', selected: false, termID: 3853, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3854, term: '[Contornos]' },
    { value: '', selected: false, termID: 3855, term: '[Subtipo]' },
    { value: '', selected: false, termID: 3856, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3857, term: '[Medindo]' },
    { value: '', selected: false, termID: 3862, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3863, term: '[Contornos]' },
    { value: '', selected: false, termID: 3867, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3868, term: '[Contornos]' },
    // { value: '', selected: false, termID: 3869, term: '[Subtipo]' },
    // { value: '', selected: false, termID: 3870, term: '[Localizacao]' },
    // { value: '', selected: false, termID: 3871, term: '[Medindo]' },
    { value: '', selected: false, termID: 3873, term: '[Doppler]' }
  ];

  genericTermsOvarioDireito: TermosGenericos[] = [
    { value: '', selected: false, termID: 3908, term: '[Medindo]' },
    { value: '', selected: false, termID: 3910, term: '[Medindo Simples]' },
    { value: '', selected: false, termID: 3912, term: '[Medindo Lúteo]' },
    { value: '', selected: false, termID: 3914, term: '[Medindo Funcional]' },
    { value: '', selected: false, termID: 3916, term: '[Medindo Hemorrágico]' },
    { value: '', selected: false, termID: 3918, term: '[Medindo Endometrioma]' }
  ];

  genericTermsOvarioEsquerdo: TermosGenericos[] = [
    { value: '', selected: false, termID: 3926, term: '[Medindo]' },
    { value: '', selected: false, termID: 3928, term: '[Medindo Simples]' },
    { value: '', selected: false, termID: 3930, term: '[Medindo Lúteo]' },
    { value: '', selected: false, termID: 3932, term: '[Medindo Funcional]' },
    { value: '', selected: false, termID: 3934, term: '[Medindo Hemorrágico]' },
    { value: '', selected: false, termID: 3936, term: '[Medindo Endometrioma]' }
  ];

  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  dinamicLesions: TermosDinamicos[] = [];
  dinamicLesion: any;
  lesionRow = 1;
  cabecalhoTexto = '';
  medicaoNodulo = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  rodapeTexto = '';

  cabecalho = false;
  utero = true;
  endometrio = false;
  ovarioDireito = false;
  ovarioEsquerdo = false;
  liquidoLivre = false;
  regiaoAnexial = false;
  indicacao = false;
  achadosTexto = false;
  rodape = false;

  medicalReportTypeID = 45;
  uteroID = 245;
  endometrioID = 242;
  ovarioDireitoID = 243;
  ovarioEsquerdoID = 246;
  liquidoLivreID = 21;
  regiaoAnexialID = 244;

  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  liqLivreSemConclusao = false;
  public switchFormReport = true;
  public showForm: Boolean = true;
  unidadeMedida: string;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  editLaudoMode: boolean = false;

  constructor(public _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    public dialog: MatDialog,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _copyPaste: CopyPaste,
    public _addText: AddText,
    private dataService:DataTermService,
    private _config: ConfigLaudoService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("transvaginal-dopplerAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Útero';
    this.subtituloBloco = 'Útero';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [
      this.uteroID,
      this.endometrioID,
      this.ovarioDireitoID,
      this.ovarioEsquerdoID,
      this.liquidoLivreID,
      this.regiaoAnexialID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "transvaginal-dopplerMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "transv-doppler");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-transvaginal-doppler');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-transvaginal-doppler');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }


  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("transvaginal-dopplerAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };


  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Útero',
      id: this.uteroID,
      name: 'utero',
      selected: true,
    },
    {
      titulo: 'Endométrio',
      id: this.endometrioID,
      name: 'endometrio',
      selected: false
    },
    {
      titulo: 'Ovário direito',
      id: this.ovarioDireitoID,
      name: 'ovario-direito',
      selected: false
    },
    {
      titulo: 'Ovário esquerdo',
      id: this.ovarioEsquerdoID,
      name: 'ovario-esquerdo',
      selected: false
    },
    {
      titulo: 'Líquido Livre',
      id: this.liquidoLivreID,
      name: 'liquido-livre',
      selected: false
    },
    {
      titulo: 'Região Anexial',
      id: this.regiaoAnexialID,
      name: 'regiao-anexial',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho =  (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape =  (itemSelecionado.id === 'rodape');
    this.utero = (itemSelecionado.id === this.uteroID);
    this.endometrio = (itemSelecionado.id === this.endometrioID);
    this.ovarioDireito = (itemSelecionado.id === this.ovarioDireitoID);
    this.ovarioEsquerdo = (itemSelecionado.id === this.ovarioEsquerdoID);
    this.regiaoAnexial = (itemSelecionado.id === this.regiaoAnexialID);
    this.liquidoLivre = (itemSelecionado.id === this.liquidoLivreID);
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;
    this.dinamicLesions = [];
    this.lesionRow = 1;
    this.medicaoNodulo = '';

    this.genericTermsLiquidoLivre = [
      { value: '', selected: false, termID: 419, term: '[Quantidade]' },
      { value: '', selected: false, termID: 420, term: '[Conteúdo]' },
      { value: '', selected: false, termID: 421, term: '[Local]' }
    ];

    this.genericTermsEndometrio = [
      { value: '', selected: false, termID: 3757, term: '[Espessura]' },
      { value: '', selected: false, termID: 3756, term: '[Aspecto]' },
      { value: '', selected: false, termID: 3759, term: '[Medida1]' },
      { value: '', selected: false, termID: 3760, term: '[Medida2]' },
      { value: '', selected: false, termID: 3764, term: '[Parede]' },
      { value: '', selected: false, termID: 3767, term: '[Segmento]' },
      { value: '', selected: false, termID: 3768, term: '[Medida]' }
    ];

    this.genericTermsRegiaoAnexial = [
      { value: '', selected: false, termID: 3877, term: '[Calibre direito]' },
      { value: '', selected: false, termID: 3879, term: '[Calibre esquerdo]' },
      { value: '', selected: false, termID: 3882, term: '[Calibre direito]' },
      { value: '', selected: false, termID: 3884, term: '[Calibre esquerdo]' },
      { value: '', selected: false, termID: 3887, term: '[Calibre direito]' },
      { value: '', selected: false, termID: 3889, term: '[Calibre esquerdo]' },
      { value: '', selected: false, termID: 3892, term: '[Medindo direito]' },
      { value: '', selected: false, termID: 3894, term: '[Medindo esquerdo]' },
      { value: '', selected: false, termID: 3897, term: '[Medindo direito]' },
      { value: '', selected: false, termID: 3899, term: '[Medindo esquerdo]' },
      { value: '', selected: false, termID: 3941, term: '[Contornos]' },
      { value: '', selected: false, termID: 3942, term: '[Medindo Direita]' },
      { value: '', selected: false, termID: 3943, term: '[Parede]' },
      { value: '', selected: false, termID: 3944, term: '[Componente]' },
      { value: '', selected: false, termID: 3945, term: '[Medindo Esquerda]' },
      { value: '', selected: false, termID: 3946, term: '[Doppler SemCom]' },
      { value: '', selected: false, termID: 4288, term: '[Doppler SemCom]' },
      { value: '', selected: false, termID: 4289, term: '[Contornos]' },
      { value: '', selected: false, termID: 4290, term: '[Parede]' },
      { value: '', selected: false, termID: 4291, term: '[Componente]' }
    ];

    this.genericTermsUtero = [
      { value: '', selected: false, termID: 3837, term: '[Posicao Utero]' },
      { value: '', selected: false, termID: 3838, term: '[Contornos]' },
      { value: '', selected: false, termID: 3839, term: '[MedidasUt]' },
      { value: '', selected: false, termID: 3842, term: '[MedidasHi]' },
      { value: '', selected: false, termID: 3850, term: '[MedidaCol]' },
      { value: '', selected: false, termID: 3853, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3854, term: '[Contornos]' },
      { value: '', selected: false, termID: 3855, term: '[Subtipo]' },
      { value: '', selected: false, termID: 3856, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3857, term: '[Medindo]' },
      { value: '', selected: false, termID: 3862, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3863, term: '[Contornos]' },
      { value: '', selected: false, termID: 3867, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3868, term: '[Contornos]' },
      { value: '', selected: false, termID: 3869, term: '[Subtipo]' },
      { value: '', selected: false, termID: 3870, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3871, term: '[Medindo]' },
      { value: '', selected: false, termID: 3873, term: '[Doppler]' }
    ];

    this.genericTermsOvarioDireito = [
      { value: '', selected: false, termID: 3908, term: '[Medindo]' },
      { value: '', selected: false, termID: 3910, term: '[Medindo Simples]' },
      { value: '', selected: false, termID: 3912, term: '[Medindo Lúteo]' },
      { value: '', selected: false, termID: 3914, term: '[Medindo Funcional]' },
      { value: '', selected: false, termID: 3916, term: '[Medindo Hemorrágico]' },
      { value: '', selected: false, termID: 3918, term: '[Medindo Endometrioma]' }
    ];

    this.genericTermsOvarioEsquerdo = [
      { value: '', selected: false, termID: 3926, term: '[Medindo]' },
      { value: '', selected: false, termID: 3928, term: '[Medindo Simples]' },
      { value: '', selected: false, termID: 3930, term: '[Medindo Lúteo]' },
      { value: '', selected: false, termID: 3932, term: '[Medindo Funcional]' },
      { value: '', selected: false, termID: 3934, term: '[Medindo Hemorrágico]' },
      { value: '', selected: false, termID: 3936, term: '[Medindo Endometrioma]' }
    ];
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      //Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      //adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  incluirLesoesDinamicas($event) {
    const lesoesValue = this._validacoes.formataDecimal($event.elemento.value);

    if ($event.elemento.id == 3866 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 3866,
          lesionID: 3858,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medindo]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 3866;
            obj.lesionID = 3858;
            obj.cenario = $event.elemento.cenario;
            obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medindo]';

          }
        });
      }
    } else if ($event.elemento.id == 3871 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 3871,
          lesionID: 3861,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medindo]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 3871;
            obj.lesionID = 3861;
            obj.cenario = $event.elemento.cenario;
            obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medindo]';

          }
        });
      }
    }

    if ($event.linha == 1 && ($event.posicao == 0 || $event.posicao == 1)) {
      this.genericTermsUtero.forEach(obj => {
        if (obj.termID == $event.elemento.id) {
          obj.selected = true;
          obj.value = lesoesValue;
        }
      });
      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaLesoes($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      //NODULO
      case '3862':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3862,
          lesionID: 3858,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3863':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3863,
          lesionID: 3858,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Contornos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3864':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3864,
          lesionID: 3858,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Subtipo]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3865':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3865,
          lesionID: 3858,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3867':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3867,
          lesionID: 3861,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3868':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3868,
          lesionID: 3861,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Contornos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3869':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3869,
          lesionID: 3861,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Subtipo]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3870':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3870,
          lesionID: 3861,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
        case '3873':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3873,
          lesionID: 3858,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Doppler]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }

    this.lesionRow = $event.linha;
  }

  adicionaSelecaoLiquidoLivre($event) {
    this.genericTermsLiquidoLivre.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);

        if (this.genericTermsLiquidoLivre[0].value === 'Mínima' &&
          this.genericTermsLiquidoLivre[1].value === 'anecóico' &&
          this.genericTermsLiquidoLivre[2].value === 'no fundo de saco') {
          this.liqLivreSemConclusao = true;
        } else {
          this.liqLivreSemConclusao = false;
        }
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoEndometrio($event) {
    this.genericTermsEndometrio.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoRegiaoAnexial($event) {
    this.genericTermsRegiaoAnexial.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoUtero($event) {
    this.genericTermsUtero.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoOvarioDireito($event) {
    this.genericTermsOvarioDireito.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoOvarioEsquerdo($event) {
    this.genericTermsOvarioEsquerdo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.liquidoLivre) {
        this.term.blockID = this.liquidoLivreID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.liquidoLivreID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoLiquidoLivre(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.liquidoLivreID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.liquidoLivreID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion && this.liqLivreSemConclusao === false) {
              obj.blockID = this.liquidoLivreID;
              obj.value = obj.value + espaco + this.MontaConclusaoLiquidoLivre(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 1) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.liquidoLivreID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.liquidoLivreID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          //this.AdicionaTexto();
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.liquidoLivreID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.endometrio) {
        this.term.blockID = this.endometrioID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.endometrioID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaEndometrio(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.endometrioID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.endometrioID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.endometrioID;
              obj.value = obj.value + espaco + this.MontaEndometrio(resultado.conclusion, contadorTexto);
              contadorTexto++;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.endometrioID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.endometrioID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          //this.AdicionaTexto();
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.endometrioID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.regiaoAnexial) {
        this.term.blockID = this.regiaoAnexialID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.regiaoAnexialID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoRegiaoAnexial(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.regiaoAnexialID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.regiaoAnexialID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.regiaoAnexialID;
              obj.value = obj.value + espaco + this.MontaConclusaoRegiaoAnexial(resultado.conclusion);
              espaco = '</br>';
            } else if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              obj.blockID = 0;
              obj.value = '';
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.regiaoAnexialID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.regiaoAnexialID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          //this.AdicionaTexto();
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.regiaoAnexialID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.utero) {
        this.term.blockID = this.uteroID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.uteroID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoUtero(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.uteroID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.uteroID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.uteroID;
              obj.value = obj.value + espaco + this.MontaConclusaoUtero(resultado.conclusion);
              espaco = '</br>';
            } else if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const consclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.uteroID);

          if (obj.blockID === 0) {
            if (consclusaoAll.length <= 1) {
              if (consclusaoAll[0].blockID === 0 || consclusaoAll[0].blockID === this.uteroID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          //this.AdicionaTexto();
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.uteroID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ovarioDireito) {
        this.term.blockID = this.ovarioDireitoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.ovarioDireitoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoOvarioDireito(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.ovarioDireitoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.ovarioDireitoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.ovarioDireitoID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
            } else if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              obj.blockID = 0;
              obj.value = '';
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ovarioDireitoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ovarioDireitoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          //this.AdicionaTexto();
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.ovarioDireitoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ovarioEsquerdo) {
        this.term.blockID = this.ovarioEsquerdoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.ovarioEsquerdoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoOvarioEsquerdo(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.ovarioEsquerdoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.ovarioEsquerdoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.ovarioEsquerdoID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
            } else if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              obj.blockID = 0;
              obj.value = '';
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ovarioEsquerdoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ovarioEsquerdoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          //this.AdicionaTexto();
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.ovarioEsquerdoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  MontaDescricaoLiquidoLivre(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 419 || +term === 420 || +term === 421) {
        this.genericTermsLiquidoLivre.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoLiquidoLivre(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 419 || +term === 420 || +term === 421) {
        this.genericTermsLiquidoLivre.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaEndometrio(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 3756 || +term === 3757) {
          this.genericTermsEndometrio.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 3756 || +term === 3757 || +term === 3759 || +term === 3760 ||
          +term === 3764 || +term === 3767 || +term === 3768) {
          this.genericTermsEndometrio.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaConclusaoEndometrio(texto) {
    let textoRetorno = '';
    let contadorTexto = 0;

    this.term.injuries[contadorTexto].listInjuries.forEach(term => {
      if (+term === 3756 || +term === 3757 || +term === 3759 || +term === 3760 ||
        +term === 3764 || +term === 3767 || +term === 3768) {
        this.genericTermsEndometrio.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoRegiaoAnexial(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 3877 || +term === 3879 || +term === 3882 || +term === 3884 || +term === 3887 ||
          +term === 3889 || +term === 3892 || +term === 3894 || +term === 3897 || +term === 3899 ||
          +term === 3941 || +term === 3942 || +term === 3943 || +term === 3944 || +term === 3945 ||
          +term === 3946 || +term === 4288 || +term === 4289 || +term === 4290 || +term === 4291) {
          this.genericTermsRegiaoAnexial.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 3877 || +term === 3879 || +term === 3882 || +term === 3884 || +term === 3887 ||
          +term === 3889 || +term === 3892 || +term === 3894 || +term === 3897 || +term === 3899 ||
          +term === 3941 || +term === 3942 || +term === 3943 || +term === 3944 || +term === 3945 ||
          +term === 3946 || +term === 4288 || +term === 4289 || +term === 4290 || +term === 4291) {
          this.genericTermsRegiaoAnexial.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaConclusaoRegiaoAnexial(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3877 || +term === 3879 || +term === 3882 || +term === 3884 || +term === 3887 ||
        +term === 3889 || +term === 3892 || +term === 3894 || +term === 3897 || +term === 3899 ||
        +term === 3941 || +term === 3942 || +term === 3943 || +term === 3944 || +term === 3945 ||
        +term === 3946 || +term === 4288 || +term === 4289 || +term === 4290 || +term === 4291) {
        this.genericTermsRegiaoAnexial.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoUtero(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoUteroDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoUteroDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoUteroDescNormal(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        // Remove conclusão de endometrio para Histerectomia Parcial
        if (+term === 3841 || +term === 3840) {
          const index = this.descricoes.findIndex(x => x.blockID === this.endometrioID);
          if (index > -1) {
            this.descricoes[index].value = '';
          }
        }
        if (+term === 3838 || +term === 3839 || +term === 3842 || +term === 3850 || +term === 3853 || +term === 3837 ||
          +term === 3854 || +term === 3855 || +term === 3856 || +term === 3857 || +term === 3862 || +term === 3863 ||
          +term === 3873) {
          this.genericTermsUtero.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 3838 || +term === 3839 || +term === 3842 || +term === 3850 || +term === 3853 || +term === 3837 ||
          +term === 3854 || +term === 3855 || +term === 3856 || +term === 3857 || +term === 3862 || +term === 3863 ||
          +term === 3873) {
          this.genericTermsUtero.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  TextoUteroDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0]; // + '\n';
      textoDinamico = textoSplit[1];
    }

    // Genérico
    if (this.term.injuries[0].listInjuries.length > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 3862 || +term === 3863 || +term === 3867 || +term === 3868 || +term === 3873) {
          this.genericTermsUtero.forEach(generic => {
            if (generic.termID == term) {
              textoRetorno = textoRetorno.replace(generic.term, generic.value);
            }
          });
        }
      });
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 3870 || item.termID == 3871 || item.termID == 3864 || item.termID == 3865 ||
            item.termID == 3866 || item.termID == 3867 || item.termID == 3868 || item.termID == 3869 ||
            item.termID == 3862 || item.termID == 3863 || item.termID === 3873) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (item.termID == 3870 || item.termID == 3871 || item.termID == 3864 || item.termID == 3865 ||
            item.termID == 3866 || item.termID == 3867 || item.termID == 3868 || item.termID == 3869 ||
            item.termID == 3862 || item.termID == 3863 || item.termID === 3873) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  MontaConclusaoUtero(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3838 || +term === 3839 || +term === 3842 || +term === 3850 || +term === 3853 ||
        +term === 3854 || +term === 3855 || +term === 3856 || +term === 3857 || +term === 3837) {
        this.genericTermsUtero.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoOvarioDireito(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 3908 || +term === 3910 || +term === 3912 || +term === 3914 || +term === 3916 || +term === 3918) {
          this.genericTermsOvarioDireito.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 3908 || +term === 3910 || +term === 3912 || +term === 3914 || +term === 3916 || +term === 3918) {
          this.genericTermsOvarioDireito.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoOvarioEsquerdo(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 3926 || +term === 3928 || +term === 3930 || +term === 3932 || +term === 3934 || +term === 3936) {
          this.genericTermsOvarioEsquerdo.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 3926 || +term === 3928 || +term === 3930 || +term === 3932 || +term === 3934 || +term === 3936) {
          this.genericTermsOvarioEsquerdo.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    // if (this.conclusoes.length <= 1) {
    texto = 'Estruturas analisadas sem alterações significativas.';
    // }
    return texto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
            header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {

          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
            footer.innerHTML = rodape;
          }
        }

			} else if (indicacao || achados) {
				if(indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor(){
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoes[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.indicacaoTexto;
     //Lógica Rules
     if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
     this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor endocavitário multifrequencial.</br></br>';

    this.htmlContent += maskSplit[1];

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
    }
    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }
    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }
}
