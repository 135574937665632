import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-derrame-articular-tornozelo',
  templateUrl: './derrame-articular-tornozelo.component.html',
  styleUrls: ['./derrame-articular-tornozelo.component.css']
})
export class DerrameArticularTornozeloComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;
  @ViewChild('slVolume') slVolume: MatSelect;
  @ViewChild('slArticulacao') slArticulacao: MatSelect;

  volumes: ArrayDropDown[] = [
    { value: 'Pequeno', viewValue: 'Pequeno' },
    { value: 'Moderado', viewValue: 'Moderado' },
    { value: 'Acentuado', viewValue: 'Acentuado' }
  ];

  articulacoes: ArrayDropDown[] = [
    { value: 'tibiotalar', viewValue: 'Tibiotalar' },
    { value: 'subtalar posterior', viewValue: 'Subtalar posterior' },
    { value: 'tibiotalar e subtalar posterior', viewValue: 'Tibiotalar e subtalar posterior' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true
    };
  }

  clickAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.slVolume && this.slVolume.value) || (this.slArticulacao && this.slArticulacao.value)){
      this.rdPresente.checked = true;
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.value);
    }

    if (this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.value);
     
      if (this.slArticulacao && this.slArticulacao.value) {
        const objeto = { elemento: this.slArticulacao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slVolume && this.slVolume.value) {
        const objeto = { elemento: this.slVolume, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
