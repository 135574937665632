import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-feto-dialog',
  templateUrl: './feto-dialog.component.html',
  styleUrls: ['./feto-dialog.component.css']
})
export class FetoDialogComponent {
  displayedColumns =
  ['diametro','ccn', 'ig25', 'ig50', 'ig97'];
  // ['diametro','ccn', 'ig25', 'ig50', 'ig97', 'p25','p50', 'p97'];
dataSource = ELEMENT_DATA;

constructor(
  public dialogRef: MatDialogRef<FetoDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public message: string) { }

ClickNao(): void {
  this.dialogRef.close();
}
}

export interface FetoRefElement {
diametro: string;
ccn: string;
ig25: number;
ig50: number;
ig97: number;
p25: number;
p50: number;
p97: number;
}

const ELEMENT_DATA: FetoRefElement[] = [
  {diametro: '-', ccn: '-', ig25: 2.5, ig50: 50, ig97: 97.5, p25: 2.5, p50: 50, p97:97.5},
  {diametro: '2', ccn: '-', ig25: 4.5, ig50:5.0, ig97:5.5, p25: 629, p50:1164, p97:2188},
  {diametro: '3', ccn: '-', ig25: 4.6, ig50:5.1, ig97:5.6, p25: 771, p50:1377, p97:2589},
  {diametro: '4', ccn: '-', ig25: 4.8, ig50:5.2, ig97:5.7, p25: 863, p50:1629, p97:3036},
  {diametro: '5', ccn: '-', ig25: 4.9, ig50:5.4, ig97:5.8, p25: 1026, p50:1932, p97:3636},
  {diametro: '6', ccn: '-', ig25: 5.0, ig50:5.5, ig97:6.0, p25: 1226, p50:2165, p97:4256},
  {diametro: '7', ccn: '-', ig25: 5.1, ig50:5.6, ig97:6.1, p25: 1465, p50:2704, p97:4990},
  {diametro: '8', ccn: '2', ig25: 5.3, ig50:5.7, ig97:6.2, p25:1749, p50:3199, p97:5852},
  {diametro: '9', ccn: '3', ig25: 5.4, ig50:5.9, ig97:6.3, p25: 2085, p50:3785, p97:6870},
  {diametro: '10', ccn: '3', ig25: 5.5, ig50:6.0, ig97:6.5, p25: 2483, p50:4478, p97:8075},
  {diametro: '11', ccn: '4', ig25: 5.6, ig50:6.1, ig97:6.6, p25: 2952, p50:5297, p97:9058},
  {diametro: '12', ccn: '5', ig25: 5.8, ig50:6.2, ig97:6.7, p25: 3502, p50:6267, p97:11218},
  {diametro: '13', ccn: '6', ig25: 5.9, ig50:6.4, ig97:6.8, p25: 4145, p50:7415, p97:13266},
  {diametro: '14', ccn: '6', ig25: 6.0, ig50:6.5, ig97:7.0, p25: 4894, p50:8773, p97:15726},
  {diametro: '15', ccn: '7', ig25: 6.2, ig50:6.6, ig97:7.1, p25: 5766, p50:10379, p97:18682},
  {diametro: '16', ccn: '8', ig25: 6.3, ig50:6.7, ig97:7.2, p25: 6776, p50:12270, p97:22235},
  {diametro: '17', ccn: '9', ig25: 6.4, ig50:6.9, ig97:7.3, p25: 7964, p50:14528, p97:26501},
  {diametro: '18', ccn: '10', ig25: 6.5, ig50:7.0, ig97:7.5, p25: 9343, p50:17188, p97:31621},
  {diametro: '19', ccn: '10', ig25: 6.6, ig50:7.1, ig97:7.6, p25: 10951, p50:20337, p97:37760},
  {diametro: '20', ccn: '11', ig25: 6.8, ig50:7.3, ig97:7.7, p25: 12820, p50:24060, p97:45130},
  {diametro: '21', ccn: '12', ig25: 6.9, ig50:7.4, ig97:7.8, p25: 15020, p50:28464, p97:53970},
  {diametro: '22', ccn: '13', ig25: 7.0, ig50:7.5, ig97:8.0, p25: 17560, p50:33675, p97:64570},
  {diametro: '23', ccn: '14', ig25: 7.2, ig50:7.6, ig97:8.1, p25: 20573, p50:39843, p97:77164},
  {diametro: '24', ccn: '15', ig25: 7.3, ig50:7.8, ig97:8.2, p25: 24067, p50:47138, p97:93325},
];


