import { Component, ElementRef, EventEmitter, Input, IterableDiffers, OnInit, Output, ViewChild } from '@angular/core';

import Interpretador from 'src/app/utils/interpretador-de-voz/interpretador';

@Component({
  selector: 'app-indicacao-exame',
  templateUrl: './indicacao-exame.component.html',
  styleUrls: ['./indicacao-exame.component.css']
})
export class IndicacaoExameComponent implements OnInit {
  @Output() enviaIndicacao = new EventEmitter();
  @Input() template: string = 'false';

  @ViewChild('inIndicacao') inIndicacao: ElementRef;

  constructor() { }

  ngOnInit() { }

  blur() {
    if (this.inIndicacao)
      this.enviaIndicacao.emit(this.inIndicacao.nativeElement.value)
  }

  async gravadorDeVoz() {
    Interpretador.gravadorDeVoz(
      Interpretador.gravandoVoz === 'Stop'
        ? 'Stop'
        : 'Start'
    ).then(() => {
      this.enviaTranscricao(Interpretador.transcrito);
    });
  }

  enviaTranscricao(transcricao: string): void  {
    this.enviaIndicacao.emit(transcricao);
  }

  indicacaoBlur(): void  {
    this.inIndicacao.nativeElement.value;
  }
}
