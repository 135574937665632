import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-estruturas-osseas',
  templateUrl: './estruturas-osseas.component.html',
  styleUrls: ['./estruturas-osseas.component.css']
})
export class EstruturasOsseasComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('ckFraturaDir') ckFraturaDir: MatCheckbox;
  @ViewChild('ckFraturaEsq') ckFraturaEsq: MatCheckbox;
  @ViewChild('ckFraturaNasal') ckFraturaNasal: MatCheckbox;
  @ViewChild('ckFraturaMult') ckFraturaMult: MatCheckbox;
  @ViewChild('rdNasal') rdNasal: MatSelect;
  @ViewChild('ckFraturaNasalMulti') ckFraturaNasalMulti: MatRadioButton;

  @ViewChild('slLateralidadeDir') slLateralidadeDir: MatSelect;
  @ViewChild('slLateralidadeEsq') slLateralidadeEsq: MatSelect;


  showAndHideTerms: any;
  countLesoes = 0;

  fraturaEsqFormGroup: FormGroup;
  fraturaDirFormGroup: FormGroup;
  fraturaMultiplaEsqFormGroup: FormGroup;
  fraturaMultiplaDirFormGroup: FormGroup;

  ossoEsquerdo: any;
  ossoDireito: any;
  ossoDireitoMult: any;
  ossoEsquerdoMult: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {

    this.iniciaFormFraturaEsquerda();
    this.iniciaFormFraturaDireita();
    this.iniciaFormFraturaMultipla();

    this.ossoDireito = [
      { id: 'frontalDir', value: 'frontal direito', name: 'Frontal Direito' },
      { id: 'maxilarDir', value: 'maxilar direito', name: 'Maxilar Direito' },
      { id: 'zigomaticoDir', value: 'zigomático direito', name: 'Zigomático Direito' }
    ];

    this.ossoEsquerdo = [
      { id: 'frontalEsq', value: 'frontal esquerdo', name: 'Frontal Esquerdo' },
      { id: 'maxilarEsq', value: 'maxilar esquerdo', name: 'Maxilar Esquerdo' },
      { id: 'zigomaticoEsq', value: 'zigomático esquerdo', name: 'Zigomático Esquerdo' }
    ]

    this.ossoDireitoMult = [
      { id: 'frontalDirMult', value: 'frontal direito', name: 'Frontal Direito' },
      { id: 'maxilarDirMult', value: 'maxilar direito', name: 'Maxilar Direito' },
      { id: 'zigomaticoDirMult', value: 'zigomático direito', name: 'Zigomático Direito' }
    ];

    this.ossoEsquerdoMult = [
      { id: 'frontalEsqMult', value: 'frontal esquerdo', name: 'Frontal Esquerdo' },
      { id: 'maxilarEsqMult', value: 'maxilar esquerdo', name: 'Maxilar Esquerdo' },
      { id: 'zigomaticoEsqMult', value: 'zigomático esquerdo', name: 'Zigomático Esquerdo' }
    ]

    this.showAndHideTerms = {
      sahFraturaEsquerda: false,
      sahFraturaDireita: false,
      sahFraturaBilateral: false,
      sahFraturaMultipla: false,
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahFraturaEsquerda = false;
    this.showAndHideTerms.sahFraturaDireita = false;
    this.showAndHideTerms.sahFraturaBilateral = false;
    this.showAndHideTerms.sahFraturaMultipla = false;
    this.ckFraturaDir.checked = false;
    this.ckFraturaEsq.checked = false;
    this.ckFraturaMult.checked = false;
    this.ckFraturaNasal.checked = false;
    this.checkNormal();
    this.catchIDs()
  }

  clickCheckbox() {
    this.catchIDs();
  }

  clickFraturaDir() {
    if (this.ckFraturaDir && this.ckFraturaDir.checked) {
      this.showAndHideTerms.sahFraturaDireita = true;
    } else {
      this.showAndHideTerms.sahFraturaDireita = false;
    }
    this.checkNormal();
    this.catchIDs();
  }

  clickFraturaEsq() {
    if (this.ckFraturaEsq && this.ckFraturaEsq.checked) {
      this.showAndHideTerms.sahFraturaEsquerda = true;
    } else {
      this.showAndHideTerms.sahFraturaEsquerda = false;
    }
    this.checkNormal();
    this.catchIDs();
  }

  clickFraturaMult() {
    if (this.ckFraturaMult && this.ckFraturaMult.checked) {
      this.showAndHideTerms.sahFraturaMultipla = true;
    } else {
      this.showAndHideTerms.sahFraturaMultipla = false;
    }
    this.checkNormal();
    this.catchIDs();
  }

  clickFraturaNasal() {
    this.checkNormal();
    this.catchIDs();
  }

  clickCheckboxFraturaDir(event) {
    let fraturaDireita = <FormArray>this.fraturaDirFormGroup.get('ossoDireito') as FormArray;

    if (event.checked) {
      fraturaDireita.push(new FormControl(' '+ event.source.value.split(' ')[0]));
    } else {
      let iDir = fraturaDireita.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      fraturaDireita.removeAt(iDir);
    }

    this.catchIDs();
  }

  clickCheckboxFraturaEsq(event) {
    let fraturaEsquerda = <FormArray>this.fraturaEsqFormGroup.get('ossoEsquerdo') as FormArray;

    if (event.checked) {
      fraturaEsquerda.push(new FormControl(' '+event.source.value.split(' ')[0]));
    } else {
      let iEsq = fraturaEsquerda.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      fraturaEsquerda.removeAt(iEsq);
    }

    this.catchIDs();
  }

  clickCheckboxFraturaMultDir(event) {
    let fraturaMultiplaDireito = <FormArray>this.fraturaMultiplaDirFormGroup.get('ossoDireitoMult') as FormArray;

    if (event.checked) {
      fraturaMultiplaDireito.push(new FormControl(' '+event.source.value.split(' ')[0]));
    } else {
      let iMultMult = fraturaMultiplaDireito.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      fraturaMultiplaDireito.removeAt(iMultMult);
    }

    this.catchIDs();

  }

  clickCheckboxFraturaMultEsq(event) {
    let fraturaMultiplaEsquerdo = <FormArray>this.fraturaMultiplaEsqFormGroup.get('ossoEsquerdoMult') as FormArray;

    if (event.checked) {
      fraturaMultiplaEsquerdo.push(new FormControl(' '+event.source.value.split(' ')[0]));
    } else {
      let iMult = fraturaMultiplaEsquerdo.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      fraturaMultiplaEsquerdo.removeAt(iMult);
    }

    this.catchIDs();

  }

  iniciaFormFraturaDireita() {
    this.fraturaDirFormGroup = this.fb.group({
      ossoDireito: this.fb.array([])
    });
  }

  iniciaFormFraturaEsquerda() {
    this.fraturaEsqFormGroup = this.fb.group({
      ossoEsquerdo: this.fb.array([])
    });
  }

  iniciaFormFraturaMultipla() {
    this.fraturaMultiplaDirFormGroup = this.fb.group({
      ossoDireitoMult: this.fb.array([])
    });
    this.fraturaMultiplaEsqFormGroup = this.fb.group({
      ossoEsquerdoMult: this.fb.array([]),
    });
  }

  checkNormal() {
    if (this.ckFraturaDir.checked || this.ckFraturaEsq.checked || this.ckFraturaMult.checked || this.ckFraturaNasal.checked) {
      this.rdNormal.checked = false;
    } else {
      this.rdNormal.checked = true;
    }
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (!this.ckFraturaDir.checked) {
      this.iniciaFormFraturaDireita();
    }
    if (!this.ckFraturaEsq.checked) {
      this.iniciaFormFraturaEsquerda();
    }
    if (!this.ckFraturaMult.checked) {
      this.iniciaFormFraturaMultipla();
    }

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    } else if (!this.rdNormal.checked) {
      this.emitFraturaDireita();

      // Fratura Esquerda
      if (this.countLesoes > 0) {
        this.emitFraturaEsquerdaLesao();
      } else {
        this.emitFraturaEsquerdaSimples();
      }

      // Fratura Multipla
      if (this.countLesoes > 0) {
        this.emitFraturaMultiplaLesao();
      } else {
        this.emitFraturaMultiplaSimples();
      }

      // Fratura Nasal
      if (this.countLesoes > 0) {
        this.emitFraturaNasalLesao();
      } else {
        this.emitFraturaNasalSimples();
      }

    }
    this.listaCompleta.emit();
  }

  emitFraturaDireita() {
    if (this.ckFraturaDir && this.ckFraturaDir.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFraturaDir.id);

      let fraturaDir = this.fraturaDirFormGroup.value.ossoDireito;

      if (fraturaDir.length > 1) {
        let objeto = { id: 4856, value: this.tratamentoVirgula(fraturaDir.toString()) };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
        //termo multiplo direito
        this.termoSelecionado.emit(4858);

      } else if (fraturaDir.length == 1) {
        let objeto = { id: 4856, value: fraturaDir };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }

  }

  emitFraturaEsquerdaLesao() {
    if (this.ckFraturaEsq && this.ckFraturaEsq.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckFraturaEsq.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let fraturaEsq = this.fraturaEsqFormGroup.value.ossoEsquerdo;

      if (fraturaEsq.length > 1) {
        let objeto = { id: 4857, value: this.tratamentoVirgula(fraturaEsq.toString()) };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
        //termo multiplo esquerdo
        let objeto2 = { id: 4858, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto2);

      } else if (fraturaEsq.length == 1) {
        let objeto = { id: 4857, value: fraturaEsq };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }
    }
  }

  emitFraturaEsquerdaSimples() {
    if (this.ckFraturaEsq && this.ckFraturaEsq.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFraturaEsq.id);

      let fraturaEsq = this.fraturaEsqFormGroup.value.ossoEsquerdo;

      if (fraturaEsq.length > 1) {
        let objeto = { id: 4857, value: this.tratamentoVirgula(fraturaEsq.toString()) };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
        //termo multiplo esquerdo
        this.termoSelecionado.emit(4858);

      } else if (fraturaEsq.length == 1) {
        let objeto = { id: 4857, value: fraturaEsq };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }
  }

  emitFraturaMultiplaLesao() {
    if (this.ckFraturaMult && this.ckFraturaMult.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckFraturaMult.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let fraturaMultEsq = this.fraturaMultiplaEsqFormGroup.value.ossoEsquerdoMult;
      let fraturaMultDir = this.fraturaMultiplaDirFormGroup.value.ossoDireitoMult;

      if (fraturaMultEsq.length == 1) {
        let objeto = { id: 4861, value: fraturaMultEsq };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }else if (fraturaMultEsq.length > 1) {
        let objeto = { id: 4861, value: this.tratamentoVirgula(fraturaMultEsq.toString()) };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }

      if (fraturaMultDir.length == 1) {
        let objeto = { id: 4860, value: fraturaMultDir };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }else if (fraturaMultEsq.length > 1) {
        let objeto = { id: 4861, value: this.tratamentoVirgula(fraturaMultDir.toString()) };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }
      if (this.ckFraturaNasalMulti && this.ckFraturaNasalMulti.checked) {
        const objeto = { id: this.ckFraturaNasalMulti.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }

  }

  emitFraturaMultiplaSimples() {
    if (this.ckFraturaMult && this.ckFraturaMult.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFraturaMult.id);

      let fraturaMultEsq = this.fraturaMultiplaEsqFormGroup.value.ossoEsquerdoMult;
      let fraturaMultDir = this.fraturaMultiplaDirFormGroup.value.ossoDireitoMult;

      if (fraturaMultEsq.length == 1) {
        let objeto = { id: 4861, value: fraturaMultEsq };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }else if (fraturaMultEsq.length > 1) {
        let objeto = { id: 4861, value: this.tratamentoVirgula(fraturaMultEsq.toString()) };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }

      if (fraturaMultDir.length == 1) {
        let objeto = { id: 4860, value: fraturaMultDir };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }else if (fraturaMultDir.length > 1) {
        let objeto = { id: 4860, value: this.tratamentoVirgula(fraturaMultDir.toString()) };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
      if (this.ckFraturaNasalMulti && this.ckFraturaNasalMulti.checked) {
        this.termoSelecionado.emit(this.ckFraturaNasalMulti.id);
      }
    }
  }

  emitFraturaNasalLesao() {
    if (this.ckFraturaNasal && this.ckFraturaNasal.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckFraturaNasal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitFraturaNasalSimples() {
    if (this.ckFraturaNasal && this.ckFraturaNasal.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFraturaNasal.id);
    }
  }

  tratamentoVirgula(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }
}
