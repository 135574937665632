import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatCheckbox, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-historia-clinica',
  templateUrl: './historia-clinica.component.html',
  styleUrls: ['./historia-clinica.component.css']
})
export class HistoriaClinicaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('inIdade') inIdade: ElementRef;
  @ViewChild('inPeso') inPeso: ElementRef;
  @ViewChild('slEtnia') slEtnia: MatSelect;
  @ViewChild('slConcepcao') slConcepcao: MatSelect;
  @ViewChild('slFumante') slFumante: MatSelect;
  @ViewChild('slHistoricoTrissomia') slHistoricoTrissomia: MatSelect;
  @ViewChild('slTrissomia') slTrissomia: MatSelect;
  @ViewChild('slDiabetes1') slDiabetes1: MatSelect;
  @ViewChild('slDiabetes2') slDiabetes2: MatSelect;
  @ViewChild('slHistoria') slHistoria: MatSelect;
  @ViewChild('ckSem') ckSem: MatCheckbox;

  showAndHideTerms: any;

  etnias: ArrayDropDown[] = [
    { value: 'branca', viewValue: 'Branca' },
    { value: 'indiana', viewValue: 'Indiana' },
    { value: 'afro brasileira', viewValue: 'Afro brasileira' },
    { value: 'parda', viewValue: 'Parda' },
    { value: 'asiática', viewValue: 'Asiática' },
    { value: 'oriente médio', viewValue: 'Oriente médio' }
  ];

  concepcoes: ArrayDropDown[] = [
    { value: 'espontânea', viewValue: 'Espontânea' },
    { value: 'indução de ovulação', viewValue: 'Indução de ovulação' },
    { value: 'fertilização in vitro', viewValue: 'Fertilização in vitro' }
  ]

  historias: ArrayDropDown[] = [
    { value: 'nulípara', viewValue: 'Nulípara' },
    { value: 'multípara', viewValue: 'Multípara' }
  ]
  defaultSelect: ArrayDropDown[] = [
    { value: 'sim', viewValue: 'Sim' },
    { value: 'não', viewValue: 'Não' }
  ]

  trissomias: ArrayDropDown[] = [
    { value: 'T21', viewValue: 'T21' },
    { value: 'T18', viewValue: 'T18' },
    { value: 'T13', viewValue: 'T13' },
  ]
  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahTrissomia: false,
      sahSem: true,
    }
  }

  blurParametros() {
    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  clickTrissomia() {
    if(this.slHistoricoTrissomia && this.slHistoricoTrissomia.value == 'sim'){
      this.showAndHideTerms.sahTrissomia = true;
    }else{
      this.showAndHideTerms.sahTrissomia = false;
    }
    this.catchIDs();
    
  }

  changeSelect() {
    this.catchIDs();
  }

  clickSemPreenchimento() {
    if (this.showAndHideTerms.sahSem === false) {
      this.showAndHideTerms.sahSem = true;
    } else {
      this.showAndHideTerms.sahSem = false;
    }    

    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    
    if(this.ckSem && this.ckSem.checked){
      this.termoSelecionado.emit(this.ckSem.id);
    } else {
      if(this.inIdade && this.inIdade.nativeElement.value){
        const objeto = { elemento: this.inIdade.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      
      if(this.inPeso && this.inPeso.nativeElement.value){
        const objeto = { elemento: this.inPeso.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if(this.slEtnia && this.slEtnia.value){
        const objeto = { elemento: this.slEtnia, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
  
      if(this.slConcepcao && this.slConcepcao.value){
        const objeto = { elemento: this.slConcepcao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if(this.slFumante && this.slFumante.value){
        const objeto = { elemento: this.slFumante, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      
      if(this.slHistoricoTrissomia && this.slHistoricoTrissomia.value){
        const objeto = { elemento: this.slHistoricoTrissomia, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if(this.slTrissomia && this.slTrissomia.value){
        const objeto = { elemento: this.slTrissomia, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if(this.slDiabetes1 && this.slDiabetes1.value){
        const objeto = { elemento: this.slDiabetes1, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
  
      if(this.slDiabetes2 && this.slDiabetes2.value){
        const objeto = { elemento: this.slDiabetes2, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
  
      if(this.slHistoria && this.slHistoria.value){
        const objeto = { elemento: this.slHistoria, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
