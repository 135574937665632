import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-tendoes-compartimento-anterior',
  templateUrl: './tendoes-compartimento-anterior.component.html',
  styleUrls: ['./tendoes-compartimento-anterior.component.css']
})
export class TendoesCompartimentoAnteriorComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTendinopatia') rdTendinopatia: MatRadioButton;
  @ViewChild('rdTenossinovite') rdTenossinovite: MatRadioButton;
  @ViewChild('rdTendiTenossi') rdTendiTenossi: MatRadioButton;
  @ViewChild('slTendao') slTendao: MatSelect;

  tendoes: ArrayDropDown[] = [
    { value: 'tibial anterior', viewValue: 'Tibial anterior' },
    { value: 'extensor longo dos dedos', viewValue: 'Extensor longo dos dedos' },
    { value: 'extensor longo do hálux', viewValue: 'Extensor longo do hálux' },
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCompartimento: false
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahCompartimento = false;
    this.catchIDs();
  }

  clickDefault() {
    this.showAndHideTerms.sahCompartimento = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdTendinopatia.checked) {
      this.termoSelecionado.emit(this.rdTendinopatia.value);
      
      if (this.slTendao && this.slTendao.value) {
        const objeto = { elemento: this.slTendao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdTenossinovite.checked) {
      this.termoSelecionado.emit(this.rdTenossinovite.value);
      
      if (this.slTendao && this.slTendao.value) {
        const objeto = { elemento: this.slTendao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdTendiTenossi.checked) {
      this.termoSelecionado.emit(this.rdTendiTenossi.value);
      
      if (this.slTendao && this.slTendao.value) {
        const objeto = { elemento: this.slTendao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
