import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox, MatInputModule } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-tendao-triceps',
  templateUrl: './tendao-triceps.component.html',
  styleUrls: ['./tendao-triceps.component.css']
})
export class TendaoTricepsComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdSemRotura') rdSemRotura: MatRadioButton;

  @ViewChild('rdRoturaCompleta') rdRoturaCompleta: MatRadioButton;
  @ViewChild('ckCotoProximal') ckCotoProximal: MatCheckbox;
  @ViewChild('inDistancia') inDistancia: ElementRef;

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahRoturaCompleta: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickDefault() {
    this.showAndHideTerms.sahRoturaCompleta = false;
    this.catchIDs();
  }

  clickRoturaCompleta() {
    this.showAndHideTerms.sahRoturaCompleta = true;
    this.catchIDs();
  }

  blurDistancia(){
    this.catchIDs();
  }

  clickCk() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdSemRotura.checked) {
      this.termoSelecionado.emit(this.rdSemRotura.value);
    }

    if (this.rdRoturaCompleta.checked) {
      this.termoSelecionado.emit(this.rdRoturaCompleta.value);

      if (this.ckCotoProximal && this.ckCotoProximal.checked) {
        this.termoSelecionado.emit(this.ckCotoProximal.value);

        if (this.inDistancia && this.inDistancia.nativeElement.value) {
            const objeto = { elemento: this.inDistancia.nativeElement};
            this.termosGenericos.emit(objeto);
        }

      }

    }


    this.listaCompleta.emit();
  }

}
