import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-derrame-articular',
  templateUrl: './derrame-articular.component.html',
  styleUrls: ['./derrame-articular.component.css']
})
export class DerrameArticularComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;
  @ViewChild('slVolume') slVolume: MatSelect;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;

  volumes: ArrGenerico[] = [
    { value: 'Pequeno', viewValue: 'Pequeno' },
    { value: 'Moderado', viewValue: 'Moderado' },
    { value: 'Acentuado', viewValue: 'Acentuado' }
  ];

  localizacoes: ArrGenerico[] = [
    { value: 'radiocárpico', viewValue: 'Radiocárpico' },
    { value: 'ulnocárpico', viewValue: 'Ulnocárpico' },
    { value: 'intercárpico', viewValue: 'Intercárpico' },
    { value: 'carpometacárpico', viewValue: 'Carpometacárpico' },
    { value: 'radioulnar distal', viewValue: 'Radioulnar distal' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true
    };
  }

  clickAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.slVolume && this.slVolume.value) || (this.slLocalizacao && this.slLocalizacao.value)){
      this.rdPresente.checked = true;
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.value);
    }

    if (this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.value);
      
      if (this.slVolume && this.slVolume.value) {
        const objeto = { elemento: this.slVolume, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacao && this.slLocalizacao.value) {
        const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
