import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';



@Component({
  selector: 'app-mg-tecnica',
  templateUrl: './mg-tecnica.component.html',
  styleUrls: ['./mg-tecnica.component.css']
})
export class MgTecnicaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('ckPadrao') ckPadrao: MatCheckbox;
  @ViewChild('ckComplemento') ckComplemento: MatCheckbox;

  @ViewChild('slComplemento') slComplemento: MatSelect;
  @ViewChild('slMama') slMama: MatSelect;

  complementos: ArrayDropDown[] = [
    { value: 'magnificação', viewValue: 'Magnificação' },
    { value: 'compressão', viewValue: 'Compressão' }
  ];

  mamas: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];
  
  showAndHideTerms: any;
  slId: string;
  
  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahComplemento: false
    };
  }

  clickDefault(){
    this.catchIDs();
  }

  clickComplemento(){
    this.showAndHideTerms.sahComplemento = this.ckComplemento.checked;
    this.catchIDs();
  }
  changeSelect(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.ckPadrao.checked) {
      this.termoSelecionado.emit(this.ckPadrao.id);
    }

    if (this.ckComplemento.checked) {
      this.termoSelecionado.emit(this.ckComplemento.id);

      if (this.slComplemento && this.slComplemento.value) {
        const objeto = { elemento: this.slComplemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      
      if (this.slMama && this.slMama.value) {
        const objeto = { elemento: this.slMama, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
