import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-tireoide-dialog',
  templateUrl: './tireoide-dialog.component.html',
  styleUrls: ['./tireoide-dialog.component.css']
})
export class TireoideDialogComponent {
  displayedColumns =
    ['altura', 'volume', 'desvio'];
  dataSource = ELEMENT_DATA;

  constructor(
    public dialogRef: MatDialogRef<TireoideDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }
}

export interface RinsRefElement {
  altura: string;
  volume: number;
  desvio: number;
}

const ELEMENT_DATA: RinsRefElement[] = [
  {altura: '< 99', volume: 2.5, desvio: 0.6},
  {altura: '100 - 109', volume: 3.8, desvio: 1.2},
  {altura: '110 - 119', volume: 4.3, desvio: 1.1},
  {altura: '120 - 129', volume: 5.4, desvio: 1.1},
  {altura: '130 - 139', volume: 8.1, desvio: 1.2},
  {altura: '140 - 149', volume: 8.5, desvio: 2.3},
  {altura: '150 - 159', volume: 8.6, desvio: 2.4},
  {altura: '> 160', volume: 12.4, desvio: 2.3}
];
