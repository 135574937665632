import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validacoes } from '../../../../utils/validacoes';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { AbdomeTotalComponent, Conclusao, Descricao } from '../abdome-total/abdome-total.component';
import { MatDialog } from '@angular/material';
import { User } from 'src/app/auth/user';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { TranslateService } from '@ngx-translate/core';
import { DataTermService } from 'src/app/services/dataTerm.service';
// import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';

export interface DescricaoConclusao {
  value: string;
  textTermID: number;
  blockID: number;
}

export interface TermosGenericos {
  value: string;
  selected: boolean;
  term: string;
  termView: string;
  termID: number;
  linha: number;
  cenario: number;
  posicao: number;
  lesionID: number;
}

@Component({
  selector: 'app-rins-vias',
  templateUrl: './rins-vias.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./rins-vias.component.css']
})
export class RinsViasComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;
  medicaoNodulo = '';

  listTermDefault=[
    {listTerm:[236],blockID:10},
    {listTerm:[271],blockID:11},
    {listTerm:[185],blockID: 9},
  ]

  descricoes: Descricao[] = [
    {
      value: 'Rins em topografia habitual, com forma e contornos preservados, de dimensões normais.</br>Parênquima renal com espessura e ecogenicidade preservadas. Relação córtico-medular preservada.' +
        '</br>Ausência de imagens sugestivas de cálculos renais. Não há sinais de hidronefrose.', blockID: 10, textTermID: 0
    },
    { value: '', blockID: 11, textTermID: 0 },
    { value: 'Bexiga com adequada repleção, paredes regulares e finas. O conteúdo vesical é anecoico e homogêneo.', blockID: 9, textTermID: 104 },
  ];

  conclusoes: Conclusao[] = [
    { value: 'Estudo ultrassonográfico sem alterações significativas.', blockID: 0, textTermID: 0 },
    { value: '', blockID: 10, textTermID: 0 },
    { value: '', blockID: 11, textTermID: 0 },
    { value: '', blockID: 9, textTermID: 0 }
  ];

  // genericTermsRimDireito: TermosGenericos[] = [
  //   { value: '', selected: false, lesionID: 237, termID: 237, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoNormal' },
  //   { value: '', selected: false, lesionID: 238, termID: 238, posicao: 0, cenario: 0, linha: 0, term: '[Medida Parenquima]', termView: 'inEspessuraNormal' },
  //   { value: '', selected: false, lesionID: 241, termID: 241, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoNefrectomia' },
  //   { value: '', selected: false, lesionID: 242, termID: 242, posicao: 0, cenario: 0, linha: 0, term: '[Medida Parenquima]', termView: 'inEspessuraNefrectomia' },
  //   { value: '', selected: false, lesionID: 245, termID: 245, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoCalcNaoObstrutivo' },
  //   { value: '', selected: false, lesionID: 246, termID: 246, posicao: 0, cenario: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacoCalcNaoObstrutivo' },
  //   { value: '', selected: false, lesionID: 248, termID: 248, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoCalcMultiplos' },
  //   { value: '', selected: false, lesionID: 249, termID: 249, posicao: 0, cenario: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacoCalcMultiplos' },
  //   { value: '', selected: false, lesionID: 252, termID: 252, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoCalcObstrutivo' },
  //   { value: '', selected: false, lesionID: 253, termID: 253, posicao: 0, cenario: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacoCalcObstrutivo' },
  //   { value: '', selected: false, lesionID: 254, termID: 254, posicao: 0, cenario: 0, linha: 0, term: '[Grau dilatação]', termView: 'slGrauCalcObstrutivo' },
  //   { value: '', selected: false, lesionID: 255, termID: 259, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensao1Cisto' },
  //   { value: '', selected: false, lesionID: 255, termID: 260, posicao: 0, cenario: 0, linha: 0, term: '[Localização]', termView: 'slLocalizaco1Cisto' },
  //   { value: '', selected: false, lesionID: 262, termID: 265, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoNodulo' },
  //   { value: '', selected: false, lesionID: 262, termID: 266, posicao: 0, cenario: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcogenicidadeNodulo' },
  //   { value: '', selected: false, lesionID: 262, termID: 267, posicao: 0, cenario: 0, linha: 0, term: '[Margens]', termView: 'slMargensNodulo' },
  //   { value: '', selected: false, lesionID: 262, termID: 268, posicao: 0, cenario: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacaoNodulo' },
  //   { value: '', selected: false, lesionID: 270, termID: 270, posicao: 0, cenario: 0, linha: 0, term: '[Grau]', termView: 'slGrauDilatacao' }
  // ];

  // genericTermsRimEsquerdo: TermosGenericos[] = [
  //   { value: '', selected: false, lesionID: 272, termID: 272, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoNormal' },
  //   { value: '', selected: false, lesionID: 273, termID: 273, posicao: 0, cenario: 0, linha: 0, term: '[Medida Parenquima]', termView: 'inEspessuraNormal' },
  //   { value: '', selected: false, lesionID: 276, termID: 276, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoNefrectomia' },
  //   { value: '', selected: false, lesionID: 277, termID: 277, posicao: 0, cenario: 0, linha: 0, term: '[Medida Parenquima]', termView: 'inEspessuraNefrectomia' },
  //   { value: '', selected: false, lesionID: 280, termID: 280, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoCalcNaoObstrutivo' },
  //   { value: '', selected: false, lesionID: 281, termID: 281, posicao: 0, cenario: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacoCalcNaoObstrutivo' },
  //   { value: '', selected: false, lesionID: 283, termID: 283, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoCalcMultiplos' },
  //   { value: '', selected: false, lesionID: 284, termID: 284, posicao: 0, cenario: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacoCalcMultiplos' },
  //   { value: '', selected: false, lesionID: 286, termID: 286, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoCalcObstrutivo' },
  //   { value: '', selected: false, lesionID: 287, termID: 287, posicao: 0, cenario: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacoCalcObstrutivo' },
  //   { value: '', selected: false, lesionID: 288, termID: 288, posicao: 0, cenario: 0, linha: 0, term: '[Grau dilatação]', termView: 'slGrauCalcObstrutivo' },
  //   { value: '', selected: false, lesionID: 289, termID: 293, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensao1Cisto' },
  //   { value: '', selected: false, lesionID: 289, termID: 294, posicao: 0, cenario: 0, linha: 0, term: '[Localização]', termView: 'slLocalizaco1Cisto' },
  //   { value: '', selected: false, lesionID: 295, termID: 298, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoNodulo' },
  //   { value: '', selected: false, lesionID: 295, termID: 299, posicao: 0, cenario: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcogenicidadeNodulo' },
  //   { value: '', selected: false, lesionID: 295, termID: 300, posicao: 0, cenario: 0, linha: 0, term: '[Margens]', termView: 'slMargensNodulo' },
  //   { value: '', selected: false, lesionID: 295, termID: 301, posicao: 0, cenario: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacaoNodulo' },
  //   { value: '', selected: false, lesionID: 303, termID: 303, posicao: 0, cenario: 0, linha: 0, term: '[Grau]', termView: 'slGrauDilatacao' }
  // ];

  // dinamicLesions: TermosGenericos[] = [];
  // dinamicLesion: any;
  // lesionRow = 1;
  // termResultsAPI: Array<TermResult>;
  // termConclusionAPI: Array<TermResult>;

  htmlContent: string;
  mask: string;
  Subtitulo: string;
  subtituloBloco:string;
  blockID: number;
  term = new Term();
  injuries = new Injuries();
  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  companyID = 0;
  cabecalhoTexto = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  rodapeTexto = '';

  cabecalho = false;
  bexiga = false;
  rimdireito = true;
  rimesquerdo = false;
  indicacao = false;
  achadosTexto = false;
  rodape = false;

  medicalReportTypeID = 26;
  bexigaID = 9;
  rimdireitoID = 10;
  rimesquerdoID = 11;
  public switchFormReport = true;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  unidadeMedida: string;
  editLaudoMode: boolean = false;


  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _copyPaste: CopyPaste,
    private _dialog: MatDialog,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _user: User,
    public abdome: AbdomeTotalComponent,
    public _addText: AddText,
    private _config: ConfigLaudoService,
    private _translate: TranslateService,
    private dataService:DataTermService) {
      abdome = new AbdomeTotalComponent(
        _laudoService,
        _toastr,
        _serviceMedicalReportLog,
        _serviceMedicalReport,
        _copyPaste,
        _dialog,
        _common,
        _addText,
        _config,
        _pouchService,
        _translate,
        dataService
      );
  }

  ngOnInit() {
    this._pouchService.loadDatabase("rins-viasAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Rim Direito';
    this.subtituloBloco='Rim Direito';
    //Recupera o companyID
    this.companyID = this._user.getUserCompanyID();
    if (this.companyID === 2) {
      this.conclusoes[0].value = 'Estudo ultrassonográfico do aparelho urinário sem evidências de anormalidades.';
    }
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [
      this.bexigaID,
      this.rimdireitoID,
      this.rimesquerdoID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "rins-viasMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "rinsVias");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-rins-vias');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-rins-vias');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public resetaDialog() {
    // this.abdome.resetaDialog();
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("rins-viasAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo:'Preencha o campo indicação',
      selected: false
    },
    {
      id: this.rimdireitoID,
      name: 'rim-direito',
      titulo: 'Rim Direito',
      selected: true
    },
    {
      id: this.rimesquerdoID,
      name: 'rim-esquerdo',
      titulo: 'Rim Esquerdo',
      selected: false
    },
    {
      id: this.bexigaID,
      name: 'bexiga',
      titulo: 'Bexiga',
      selected: false
    },
    {
      id: 'achados',
      name: 'achados',
      titulo: 'Achados Adicionais',
      subtitulo:'Preencha o campo achados adicionais',
      selected: false
    },
    {
      id: 'rodape',
      name: 'rodape',
      titulo: 'Assinatura',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if (itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.rimdireito = (itemSelecionado.id === this.rimdireitoID);
    this.rimesquerdo = (itemSelecionado.id === this.rimesquerdoID);
    this.bexiga = (itemSelecionado.id === this.bexigaID);
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');

    this.AdicionaTexto(this.newEditor);
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';
    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  // enviaTermos($event) {
  //   this.abdome.enviaTermos($event);
    // if ($event == 'Clean') {
    //   this.limparTermos();
    // } else {
    //   this.term.listTermID.push($event);
    // }
  // }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';
    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';
    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';
    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    this.abdome.enviaLesoes($event);
    this.AdicionaTexto(this.newEditor);
    // if (this.cenarioCorrente == 0) {
    //   this.cenarioCorrente = $event.cenario;
    // }

    // if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {

    //   //Adiciona ao objeto
    //   this.injuries.listInjuries.push($event.id);

    // } else {
    //   //adiciona objeto à lista
    //   const injuryToAdd = new Injuries();
    //   injuryToAdd.listInjuries = new Array<number>();

    //   this.injuries.listInjuries.forEach(obj => {
    //     injuryToAdd.listInjuries.push(obj);
    //   });

    //   this.term.injuries.push(injuryToAdd);

    //   // cria outro objeto e adiciona na lista
    //   this.injuries.listInjuries = new Array<number>();
    //   this.injuries.listInjuries.push($event.id);

    //   this.cenarioCorrente = $event.cenario;
    // }
  }

  // adicionaSelecaoRimDireito($event) {
  //   this.abdome.adicionaSelecaoRimDireito($event);
  //   this.AdicionaTexto();
    // this.genericTermsRimDireito.forEach(obj => {
    //   if (obj.termID == $event.elemento.id) {
    //     obj.selected = true;
    //     obj.value = this._validacoes.formataDecimal($event.elemento.value);
    //   }
    // });
    // if ($event.isPrimeiraLesao == true) {
    //   this.term.listTermID.push($event.elemento.id);
    // } else {
    //   this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
    //   this.enviaLesoes(this.objetoGrupoLesao);
    // }
  // }

  // adicionaSelecaoRimEsquerdo($event) {
  //   this.genericTermsRimEsquerdo.forEach(obj => {
  //     if (obj.termID == $event.elemento.id) {
  //       obj.selected = true;
  //       obj.value = this._validacoes.formataDecimal($event.elemento.value);
  //     }
  //   });
  //   if ($event.isPrimeiraLesao == true) {
  //     this.term.listTermID.push($event.elemento.id);
  //   } else {
  //     this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
  //     this.enviaLesoes(this.objetoGrupoLesao);
  //   }
  // }

  // limparTermos() {
  //   this.term.listTermID = new Array<number>();
  //   this.term.injuries = new Array<Injuries>();
  //   this.injuries.listInjuries = new Array<number>();
  //   this.cenarioCorrente = 0;

  //   this.genericTermsRimDireito = [
  //     { value: '', selected: false, lesionID: 237, termID: 237, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoNormal' },
  //     { value: '', selected: false, lesionID: 238, termID: 238, cenario: 0, posicao: 0, linha: 0, term: '[Medida Parenquima]', termView: 'inEspessuraNormal' },
  //     { value: '', selected: false, lesionID: 241, termID: 241, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoNefrectomia' },
  //     { value: '', selected: false, lesionID: 242, termID: 242, cenario: 0, posicao: 0, linha: 0, term: '[Medida Parenquima]', termView: 'inEspessuraNefrectomia' },
  //     { value: '', selected: false, lesionID: 245, termID: 245, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoCalcNaoObstrutivo' },
  //     { value: '', selected: false, lesionID: 246, termID: 246, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacoCalcNaoObstrutivo' },
  //     { value: '', selected: false, lesionID: 248, termID: 248, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoCalcMultiplos' },
  //     { value: '', selected: false, lesionID: 249, termID: 249, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacoCalcMultiplos' },
  //     { value: '', selected: false, lesionID: 252, termID: 252, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoCalcObstrutivo' },
  //     { value: '', selected: false, lesionID: 253, termID: 253, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacoCalcObstrutivo' },
  //     { value: '', selected: false, lesionID: 254, termID: 254, cenario: 0, posicao: 0, linha: 0, term: '[Grau dilatação]', termView: 'slGrauCalcObstrutivo' },
  //     { value: '', selected: false, lesionID: 255, termID: 259, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensao1Cisto' },
  //     { value: '', selected: false, lesionID: 255, termID: 260, posicao: 0, cenario: 0, linha: 0, term: '[Localização]', termView: 'slLocalizaco1Cisto' },
  //     { value: '', selected: false, lesionID: 262, termID: 265, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoNodulo' },
  //     { value: '', selected: false, lesionID: 262, termID: 266, posicao: 0, cenario: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcogenicidadeNodulo' },
  //     { value: '', selected: false, lesionID: 262, termID: 267, posicao: 0, cenario: 0, linha: 0, term: '[Margens]', termView: 'slMargensNodulo' },
  //     { value: '', selected: false, lesionID: 262, termID: 268, posicao: 0, cenario: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacaoNodulo' },
  //     { value: '', selected: false, lesionID: 270, termID: 270, cenario: 0, posicao: 0, linha: 0, term: '[Grau]', termView: 'slGrauDilatacao' }
  //   ];

  //   this.genericTermsRimEsquerdo = [
  //     { value: '', selected: false, lesionID: 272, termID: 272, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoNormal' },
  //     { value: '', selected: false, lesionID: 273, termID: 273, cenario: 0, posicao: 0, linha: 0, term: '[Medida Parenquima]', termView: 'inEspessuraNormal' },
  //     { value: '', selected: false, lesionID: 276, termID: 276, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoNefrectomia' },
  //     { value: '', selected: false, lesionID: 277, termID: 277, cenario: 0, posicao: 0, linha: 0, term: '[Medida Parenquima]', termView: 'inEspessuraNefrectomia' },
  //     { value: '', selected: false, lesionID: 280, termID: 280, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoCalcNaoObstrutivo' },
  //     { value: '', selected: false, lesionID: 281, termID: 281, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacoCalcNaoObstrutivo' },
  //     { value: '', selected: false, lesionID: 283, termID: 283, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoCalcMultiplos' },
  //     { value: '', selected: false, lesionID: 284, termID: 284, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacoCalcMultiplos' },
  //     { value: '', selected: false, lesionID: 286, termID: 286, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoCalcObstrutivo' },
  //     { value: '', selected: false, lesionID: 287, termID: 287, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacoCalcObstrutivo' },
  //     { value: '', selected: false, lesionID: 288, termID: 288, cenario: 0, posicao: 0, linha: 0, term: '[Grau dilatação]', termView: 'slGrauCalcObstrutivo' },
  //     { value: '', selected: false, lesionID: 289, termID: 293, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensao1Cisto' },
  //     { value: '', selected: false, lesionID: 289, termID: 294, posicao: 0, cenario: 0, linha: 0, term: '[Localização]', termView: 'slLocalizaco1Cisto' },
  //     { value: '', selected: false, lesionID: 295, termID: 298, posicao: 0, cenario: 0, linha: 0, term: '[Medida]', termView: 'inDimensaoNodulo' },
  //     { value: '', selected: false, lesionID: 295, termID: 299, posicao: 0, cenario: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcogenicidadeNodulo' },
  //     { value: '', selected: false, lesionID: 295, termID: 300, posicao: 0, cenario: 0, linha: 0, term: '[Margens]', termView: 'slMargensNodulo' },
  //     { value: '', selected: false, lesionID: 295, termID: 301, posicao: 0, cenario: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacaoNodulo' },
  //     { value: '', selected: false, lesionID: 303, termID: 303, cenario: 0, posicao: 0, linha: 0, term: '[Grau]', termView: 'slGrauDilatacao' }
  //   ];

  //   this.dinamicLesions = [];
  //   this.lesionRow = 1;
  //   this.medicaoNodulo = '';
  // }

  // incluirLesoesDinamicas($event) {
  //   const lesoesValue = this._validacoes.formataDecimal($event.elemento.value);

  //   if ($event.linha == 1 && ($event.posicao == 0 || $event.posicao == 1)) {
  //     if ($event.isPrimeiraLesao == true) {
  //       this.term.listTermID.push($event.elemento.id);
  //     } else {
  //       this.enviaLesoes($event.elemento);
  //     }
  //   }

  //   switch ($event.elemento.id) {
  //     case '209':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 209,
  //         lesionID: 20,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Ecogenicidade]',
  //         termView: 'slNoduloEcogenicidade'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '198':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 198,
  //         lesionID: 20,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Segmento]',
  //         termView: 'slNoduloSegmento'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '210':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 210,
  //         lesionID: 20,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Contorno]',
  //         termView: 'slNoduloContorno'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;

  //     case '43':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 43,
  //         lesionID: 40,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Medindo]',
  //         termView: 'inCistoMedindo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '207':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 207,
  //         lesionID: 40,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Segmento]',
  //         termView: 'slCistoSegmento'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;

  //     case '59':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 59,
  //         lesionID: 58,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Medindo]',
  //         termView: 'inCalcificacaoMedindo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '208':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 208,
  //         lesionID: 58,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Segmento]',
  //         termView: 'slCalcificacaoSegmento'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;

  //     case '202':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 202,
  //         lesionID: 191,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Medindo]',
  //         termView: 'inColecaoMedindo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '203':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 203,
  //         lesionID: 191,
  //         linha: $event.linha,
  //         posicao: 0,
  //         term: '[Segmento]',
  //         termView: 'slColecaoSegmento'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '195':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 195,
  //         lesionID: 193,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Medindo]',
  //         termView: 'inHemangiomasMedindo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '197':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 197,
  //         lesionID: 193,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Segmento]',
  //         termView: 'slHemangiomasSegmento'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '43':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 43,
  //         lesionID: 184,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Medindo]',
  //         termView: 'inCistoMedindo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '207':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 207,
  //         lesionID: 184,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Segmento]',
  //         termView: 'slCistoSegmento'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '259':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 259,
  //         lesionID: 255,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Medida]',
  //         termView: 'inDimensao1Cisto'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '260':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 260,
  //         lesionID: 255,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Localização]',
  //         termView: 'slLocalizaco1Cisto'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '293':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 293,
  //         lesionID: 289,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Medida]',
  //         termView: 'inDimensao1Cisto'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '294':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 294,
  //         lesionID: 289,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Localização]',
  //         termView: 'slLocalizaco1Cisto'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '265':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 265,
  //         lesionID: 262,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Medida]',
  //         termView: 'inDimensaoNodulo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '266':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 266,
  //         lesionID: 262,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Ecogenicidade]',
  //         termView: 'slEcogenicidadeNodulo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '267':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 267,
  //         lesionID: 262,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Margens]',
  //         termView: 'slMargensNodulo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '268':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 268,
  //         lesionID: 262,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Localização]',
  //         termView: 'slLocalizacaoNodulo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '293':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 293,
  //         lesionID: 289,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Medida]',
  //         termView: 'inDimensao1Cisto'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '294':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 294,
  //         lesionID: 289,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Localização]',
  //         termView: 'slLocalizaco1Cisto'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '298':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 298,
  //         lesionID: 295,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Medida]',
  //         termView: 'inDimensaoNodulo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '299':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 299,
  //         lesionID: 295,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Ecogenicidade]',
  //         termView: 'slEcogenicidadeNodulo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '300':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 300,
  //         lesionID: 295,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Margens]',
  //         termView: 'slMargensNodulo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '301':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 301,
  //         lesionID: 295,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Localização]',
  //         termView: 'slLocalizacaoNodulo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '245':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 245,
  //         lesionID: 244,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Medida]',
  //         termView: 'inDimensaoCalcNaoObstrutivo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '246':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 246,
  //         lesionID: 244,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Localização]',
  //         termView: 'slLocalizacoCalcNaoObstrutivo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '280':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 280,
  //         lesionID: 279,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Medida]',
  //         termView: 'inDimensaoCalcNaoObstrutivo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //     case '281':
  //       this.dinamicLesion = {
  //         value: lesoesValue,
  //         selected: true,
  //         termID: 281,
  //         lesionID: 279,
  //         linha: $event.linha,
  //         posicao: 0,
  //         cenario: $event.elemento.cenario,
  //         term: '[Localização]',
  //         termView: 'slLocalizacoCalcNaoObstrutivo'
  //       };
  //       this.dinamicLesions.push(this.dinamicLesion);
  //       break;
  //   }

  //   this.lesionRow = $event.linha;
  // }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.rimdireito) {
        // this.term.blockID = this.rimdireitoID;

        // this.term.injuries.push(this.injuries);
        // this.cenarioCorrente++;

        this.abdome.blockID = this.abdome.rimdireitoID;
        this.abdome.term.injuries.push(this.abdome.injuries);
        this.abdome.cenarioCorrente++;

        this._pouchService.getLaudos(this.abdome.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.abdome.rimdireitoID && termResults[0] !== undefined) {
              this.abdome.termResultsAPI = termResults;
              obj.textTermID = this.abdome.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.abdome.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.abdome.MontaDescricaoRimDireito(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.abdome.descricoes.findIndex(x => x.blockID == this.abdome.rimdireitoID);
              if (index > -1) {
                this.abdome.descricoes[index].value = obj.value;
                this.abdome.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.abdome.rimdireitoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.abdome.termConclusionAPI = termResults;
          let espaco = '';
          let contadorTexto = 0;
          this.abdome.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.abdome.rimesquerdoID;
              obj.value = obj.value + espaco + this.abdome.MontarConclusaoRimDireito(resultado.conclusion, contadorTexto);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.abdome.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }

            contadorTexto++;
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.abdome.rimdireitoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.abdome.rimdireitoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.rimdireitoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            })
          }
        });

      } else if (this.rimesquerdo) {
        this.abdome.term.blockID = this.rimesquerdoID;

        this.abdome.term.injuries.push(this.abdome.injuries);
        this.abdome.cenarioCorrente++;

        this._pouchService.getLaudos(this.abdome.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.rimesquerdoID && termResults[0] !== undefined) {
              this.abdome.termResultsAPI = termResults;
              obj.textTermID = this.abdome.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.abdome.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.abdome.MontaDescricaoRimEsquerdo(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.abdome.descricoes.findIndex(x => x.blockID == this.rimesquerdoID);
              if (index > -1) {
                this.abdome.descricoes[index].value = obj.value;
                this.abdome.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.rimesquerdoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.abdome.termConclusionAPI = termResults;
          let espaco = '';
          let contadorTexto = 0;
          this.abdome.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.rimesquerdoID;
              obj.value = obj.value + espaco + this.abdome.MontarConclusaoRimEsquerdo(resultado.conclusion, contadorTexto);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }

            contadorTexto++;
            // if (resultado.conclusion) {
            //   obj.blockID = this.rimesquerdoID;
            //   if (obj.value === 'Estudo ultrassonográfico sem alterações significativas.' || obj.value === undefined) {
            //     obj.value = this.MontarConclusaoRimEsquerdo(resultado.conclusion, contadorTexto);
            //     contadorTexto++;
            //   } else {
            //     obj.value = obj.value + espaco + this.MontarConclusaoRimEsquerdo(resultado.conclusion, contadorTexto);
            //     contadorTexto++;
            //   }
            // } else {
            //   obj.blockID = 0;
            //   obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            //   contadorTexto++;
            // }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.rimesquerdoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.rimesquerdoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.rimesquerdoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            })
          }
        });
      } else if (this.bexiga) {
        this.abdome.term.blockID = this.bexigaID;

        this._pouchService.getLaudos(this.abdome.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.bexigaID && termResults[0] !== undefined) {
              this.abdome.termResultsAPI = termResults;
              obj.textTermID = this.abdome.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.abdome.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.abdome.MontaDescricaoBexiga(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.bexigaID);
              if (index > -1) {
                this.abdome.descricoes[index].value = obj.value;
                this.abdome.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.bexigaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.abdome.termConclusionAPI = termResults;
          this.abdome.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.bexigaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.bexigaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.bexigaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            })
          }
        });
        //Por que essa chamada esta aqui?
        //this._laudoService.buscaConclusaoPorTermo(this.term);
        this._pouchService.getLaudos(this.abdome.term);
      }
    }
  }

  // MontaDescricaoRimEsquerdo(texto, contadorTexto) {
  //   const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
  //     return (din.cenario - 1) == contadorTexto;
  //   });

  //   if (rowDinamic != undefined && rowDinamic.length > 0) {
  //     const lesaoID = rowDinamic[0].lesionID;
  //     texto = this.TextoRimEsquerdoDescDinamica(texto, contadorTexto, lesaoID);
  //   } else {
  //     texto = this.TextoRimEsquerdoDescNormal(texto, contadorTexto);
  //   }
  //   return texto;
  // }

  // TextoRimEsquerdoDescNormal(texto, contadorTexto) {
  //   // Descrição para lesões normais (1 ou múltiplas)
  //   const textoSplit = texto.split('|', 2);
  //   let textoRetorno = '';

  //   if (textoSplit.length > 1) {
  //     texto = textoSplit[0] + '\n' + textoSplit[1];
  //   }

  //   if (contadorTexto == 0) { // Caso seja a primeira lesão
  //     this.term.listTermID.forEach(term => {
  //       if (term >= 271 && term <= 303) {
  //         this.genericTermsRimEsquerdo.forEach(generic => {
  //           if (generic.termID == term) {
  //             generic.selected = true;
  //             textoRetorno = texto.replace(generic.term, generic.value);
  //             texto = textoRetorno;
  //           }
  //         });
  //       }
  //     });
  //   } else { // Caso seja uma lesão adicional (da lista de injuries)

  //     this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
  //       if (term >= 271 && term <= 303) {
  //         this.genericTermsRimEsquerdo.forEach(generic => {
  //           if (generic.termID == term) {
  //             generic.selected = true;
  //             textoRetorno = texto.replace(generic.term, generic.value);
  //             texto = textoRetorno;
  //           }
  //         });
  //       }
  //     });

  //   }
  //   return texto;
  // }

  // TextoRimEsquerdoDescDinamica(texto, contadorTexto, lesaoID) {
  //   const textoSplit = texto.split('|', 2);
  //   let textoRetorno = '';
  //   let textoDinamico = '';
  //   let linha = 1;

  //   if (textoSplit.length == 1) {
  //     textoDinamico = textoSplit[0];
  //   } else {
  //     textoRetorno = textoSplit[0] + '\n';
  //     textoDinamico = textoSplit[1];
  //   }

  //   // Lista term
  //   this.term.listTermID.forEach(term => {
  //     if (+term === 272 || +term === 273 || +term === 276 || +term === 277 || +term === 303) {
  //       this.genericTermsRimEsquerdo.forEach(generic => {
  //         if (+generic.termID === +term) {
  //           textoRetorno = textoRetorno.replace(generic.term, generic.value);
  //         }
  //       });
  //     }
  //   });

  //   this.dinamicLesions.forEach(item => {
  //     if (lesaoID == item.lesionID) {
  //       if (item.linha == linha) {
  //         if (item.termID >= 271 && item.termID <= 303) {
  //           textoDinamico = textoDinamico.replace(item.term, item.value);
  //         }
  //       } else {
  //         linha++;
  //         textoRetorno = textoRetorno + textoDinamico + '\n';

  //         if (textoSplit.length == 1) {
  //           textoDinamico = textoSplit[0];
  //         } else {
  //           textoDinamico = textoSplit[1];
  //         }

  //         if (item.termID >= 271 && item.termID <= 303) {
  //           textoDinamico = textoDinamico.replace(item.term, item.value);
  //         }
  //       }
  //     }
  //   });
  //   textoRetorno = textoRetorno + textoDinamico;
  //   texto = textoRetorno;

  //   // this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
  //   //   return lesaoID != din.lesionID;
  //   // });

  //   return texto;
  // }

  // MontarConclusaoRimEsquerdo(texto, contadorTexto) {
  //   let textoSplit = '';
  //   let textoRetorno = '';

  //   if (contadorTexto == 0) { // Caso seja a primeira lesão
  //     textoSplit = texto.split('|', 2);
  //     if (textoSplit.length > 1) {
  //       texto = textoSplit[0] + '\n' + textoSplit[1];
  //     }
  //     this.term.listTermID.forEach(term => {
  //       if (term == 272 || term == 273 || term == 276 || term == 277
  //         || term == 280 || term == 281 || term == 283 || term == 284
  //         || term == 286 || term == 287 || term == 288 || term == 293
  //         || term == 294 || term == 298 || term == 299 || term == 300
  //         || term == 301 || term == 303) {
  //         this.genericTermsRimEsquerdo.forEach(generic => {
  //           if (generic.termID == term) {
  //             generic.selected = true;
  //             textoRetorno = texto.replace(generic.term, generic.value);
  //             texto = textoRetorno;
  //           }
  //         });
  //       }
  //     });
  //   } else { // Caso seja uma lesão adicional (da lista de injuries)
  //     this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
  //       if (term == 272 || term == 273 || term == 276 || term == 277
  //         || term == 280 || term == 281 || term == 283 || term == 284
  //         || term == 286 || term == 287 || term == 288 || term == 293
  //         || term == 294 || term == 298 || term == 299 || term == 300
  //         || term == 301 || term == 303) {
  //         this.genericTermsRimEsquerdo.forEach(generic => {
  //           if (generic.termID == term) {
  //             generic.selected = true;
  //             textoRetorno = texto.replace(generic.term, generic.value);
  //             texto = textoRetorno;
  //           }
  //         });
  //       }
  //     });
  //   }

  //   return texto;
  // }

  // MontaDescricaoRimDireito(texto, contadorTexto) {
  //   const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
  //     return (din.cenario - 1) == contadorTexto;
  //   });

  //   if (rowDinamic != undefined && rowDinamic.length > 0) {
  //     const lesaoID = rowDinamic[0].lesionID;
  //     texto = this.TextoRimDireitoDescDinamica(texto, contadorTexto, lesaoID);
  //   } else {
  //     texto = this.TextoRimDireitoDescNormal(texto, contadorTexto);
  //   }
  //   return texto;
  // }

  // TextoRimDireitoDescNormal(texto, contadorTexto) {
  //   // Descrição para lesões normais (1 ou múltiplas)
  //   const textoSplit = texto.split('|', 2);
  //   let textoRetorno = '';

  //   if (textoSplit.length > 1) {
  //     texto = textoSplit[0] + '\n' + textoSplit[1];
  //   }

  //   if (contadorTexto == 0) { // Caso seja a primeira lesão
  //     this.term.listTermID.forEach(term => {
  //       if (term == 237 || term == 238 || term == 241 || term == 242
  //         || term == 265 || term == 266 || term == 267 || term == 268
  //         || term == 245 || term == 246 || term == 248 || term == 249
  //         || term == 252 || term == 253 || term == 254 || term == 259
  //         || term == 260 || term == 270) {
  //         this.genericTermsRimDireito.forEach(generic => {
  //           if (generic.termID == term) {
  //             generic.selected = true;
  //             textoRetorno = texto.replace(generic.term, generic.value);
  //             texto = textoRetorno;
  //           }
  //         });
  //       }
  //     });
  //   } else { // Caso seja uma lesão adicional (da lista de injuries)

  //     this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
  //       if (term == 237 || term == 238 || term == 241 || term == 242
  //         || term == 265 || term == 266 || term == 267 || term == 268
  //         || term == 245 || term == 246 || term == 248 || term == 249
  //         || term == 252 || term == 253 || term == 254 || term == 259
  //         || term == 260 || term == 270) {
  //         this.genericTermsRimDireito.forEach(generic => {
  //           if (generic.termID == term) {
  //             generic.selected = true;
  //             textoRetorno = texto.replace(generic.term, generic.value);
  //             texto = textoRetorno;
  //           }
  //         });
  //       }
  //     });

  //   }
  //   return texto;
  // }

  // TextoRimDireitoDescDinamica(texto, contadorTexto, lesaoID) {
  //   const textoSplit = texto.split('|', 2);
  //   let textoRetorno = '';
  //   let textoDinamico = '';
  //   let linha = 1;

  //   if (textoSplit.length == 1) {
  //     textoDinamico = textoSplit[0];
  //   } else {
  //     textoRetorno = textoSplit[0] + '\n';
  //     textoDinamico = textoSplit[1];
  //   }

  //   // Lista term
  //   this.term.listTermID.forEach(term => {
  //     if (+term == 237 || +term == 238 || +term == 241 || +term == 242
  //       || +term == 265 || +term == 266 || +term == 267 || +term == 268
  //       || +term == 245 || +term == 246 || +term == 248 || +term == 249
  //       || +term == 252 || +term == 253 || +term == 254 || +term == 259
  //       || +term == 260 || +term == 270) {
  //       this.genericTermsRimDireito.forEach(generic => {
  //         if (+generic.termID === +term) {
  //           textoRetorno = textoRetorno.replace(generic.term, generic.value);
  //         }
  //       });
  //     }
  //   });

  //   this.dinamicLesions.forEach(item => {
  //     if (lesaoID == item.lesionID) {
  //       if (item.linha == linha) {
  //         if (item.termID == 237 || item.termID == 238 || item.termID == 241 || item.termID == 242
  //           || item.termID == 265 || item.termID == 266 || item.termID == 267 || item.termID == 268
  //           || item.termID == 245 || item.termID == 246 || item.termID == 248 || item.termID == 249
  //           || item.termID == 252 || item.termID == 253 || item.termID == 254 || item.termID == 259
  //           || item.termID == 260 || item.termID == 270) {
  //           textoDinamico = textoDinamico.replace(item.term, item.value);
  //         }
  //       } else {
  //         linha++;
  //         textoRetorno = textoRetorno + textoDinamico + '\n';

  //         if (textoSplit.length == 1) {
  //           textoDinamico = textoSplit[0];
  //         } else {
  //           textoDinamico = textoSplit[1];
  //         }

  //         if (item.termID == 237 || item.termID == 238 || item.termID == 241 || item.termID == 242
  //           || item.termID == 265 || item.termID == 266 || item.termID == 267 || item.termID == 268
  //           || item.termID == 245 || item.termID == 246 || item.termID == 248 || item.termID == 249
  //           || item.termID == 252 || item.termID == 253 || item.termID == 254 || item.termID == 259
  //           || item.termID == 260 || item.termID == 270) {
  //           textoDinamico = textoDinamico.replace(item.term, item.value);
  //         }
  //       }
  //     }
  //   });
  //   textoRetorno = textoRetorno + textoDinamico;
  //   texto = textoRetorno;

  //   // this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
  //   //   return lesaoID != din.lesionID;
  //   // });

  //   return texto;
  // }

  // MontarConclusaoRimDireito(texto, contadorTexto) {
  //   let textoSplit = '';
  //   let textoRetorno = '';

  //   // if (contadorTexto === 0 && this.conclusoes.length <= 1) { // Caso seja a primeira lesão
  //   if (contadorTexto === 0) { // Caso seja a primeira lesão
  //     textoSplit = texto.split('|', 2);
  //     if (textoSplit.length > 1) {
  //       texto = textoSplit[0] + '\n' + textoSplit[1];
  //     }
  //     this.term.listTermID.forEach(term => {
  //       if (term == 237 || term == 238 || term == 241 || term == 242
  //         || term == 265 || term == 266 || term == 267 || term == 268
  //         || term == 245 || term == 246 || term == 248 || term == 249
  //         || term == 252 || term == 253 || term == 254 || term == 259
  //         || term == 260 || term == 270) {
  //         this.genericTermsRimDireito.forEach(generic => {
  //           if (generic.termID == term) {
  //             generic.selected = true;
  //             textoRetorno = texto.replace(generic.term, generic.value);
  //             texto = textoRetorno;
  //           }
  //         });
  //       }
  //     });
  //   } else { // Caso seja uma lesão adicional (da lista de injuries)
  //     this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
  //       if (term == 237 || term == 238 || term == 241 || term == 242
  //         || term == 265 || term == 266 || term == 267 || term == 268
  //         || term == 245 || term == 246 || term == 248 || term == 249
  //         || term == 252 || term == 253 || term == 254 || term == 259
  //         || term == 260 || term == 270) {
  //         this.genericTermsRimDireito.forEach(generic => {
  //           if (generic.termID == term) {
  //             generic.selected = true;
  //             textoRetorno = texto.replace(generic.term, generic.value);
  //             texto = textoRetorno;
  //           }
  //         });
  //       }
  //     });
  //   }
  //   return texto;
  // }

  // MontaDescricaoBexiga(texto, contadorTexto) {
  //   return texto;
  // }

  // MontarConclusaoBexiga(texto) {
  //   let textoSplit = '';

  //   textoSplit = texto.split('|', 2);
  //   if (textoSplit.length > 1) {
  //     texto = textoSplit[0] + '\n' + textoSplit[1];
  //   }
  //   return texto;
  // }
  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
            header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {

          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
            footer.innerHTML = rodape;
          }
        }

			} else if (indicacao || achados) {
				if(indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor(){
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    if(this.indicacaoTexto){
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-indicacao`,
          'name': ''
        },
        'data': {
          'text': `<p>${this.indicacaoTexto}</p>`,
        }
      });
    }

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });

    this.descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoes[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

  }

  adicionaTextoOldEditor() {
    let i;

    if(this.mask !== undefined) {
      let maskSplit = this.mask.split('|');

      if (this.cabecalhoTexto !== '') {
        this.htmlContent = this.cabecalhoTexto;
        this.htmlContent += maskSplit[0];
      } else {
        this.htmlContent = maskSplit[0];
      }

      this.htmlContent += this.indicacaoTexto;
       //Lógica Rules
       if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
       this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';

      this.htmlContent += maskSplit[1];

      // POG - AJUSTAR ISSO ass.:Gabs
      if (this.descricoes[0].textTermID != 0 && this.descricoes[1].textTermID == 0) {
        this.descricoes[1].value = 'Rim esquerdo em topografia habitual, com forma e contornos preservados, de dimensões normais.</br>Parênquima renal esquerdo com espessura e ecogenicidade preservadas. Relação córtico-medular preservada.</br>Ausência de imagens sugestivas de cálculos no rim esquerdo. Não há sinais de hidronefrose à esquerda.'.toString();
        this.descricoes[1].textTermID = 187;
      } else if (this.descricoes[0].textTermID == 0 && this.descricoes[1].textTermID != 0) {
        this.descricoes[0].value = 'Rim direito em topografia habitual, com forma e contornos preservados, de dimensões normais.</br>Parênquima renal direito com espessura e ecogenicidade preservadas. Relação córtico-medular preservada.</br>Ausência de imagens sugestivas de cálculos no rim direito. Não há sinais de hidronefrose à direita.'.toString();
        this.descricoes[0].textTermID = 186;
      }
      for (i = 0; i < this.descricoes.length; i++) {
        var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
        var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
        this.descricoes[i].value = newValue2;
      }
      for (i = 0; i < this.descricoes.length; i++) {
        if (this.descricoes[i].value) {
          this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
        }
      }
      this.htmlContent += maskSplit[2];
      for (i = 0; i < this.conclusoes.length; i++) {
        if (this.conclusoes[i].value) {
          this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
        }
      }
      this.htmlContent += this.achadosAdTexto;

      if(this.rodapeTexto != '')
        this.htmlContent += this.rodapeTexto;
    } else {
      console.log('Adiciona texto undefined');
    }
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    if (this.companyID === 2) {
      texto = 'Estudo ultrassonográfico do aparelho urinário sem evidências de anormalidades.';
    } else {
      texto = 'Estudo ultrassonográfico sem alterações significativas.';
    }
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    this.abdome.medicalReportTypeID = this.medicalReportTypeID;
    this.abdome.auditoriaUsuario();
    // const obj = {
    //   UserID: +localStorage.getItem('userID'),
    //   MedicalReportTypeID: this.medicalReportTypeID,
    //   ContentReport: this._copyPaste.getTextFromId('editor'),
    //   MedicalReportLogTypeID: 1
    // };

    // if (obj)
    //   this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }
}
