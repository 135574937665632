import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckbox, MatRadioButton, MatSelect} from '@angular/material';
import {Validacoes} from '../../../../../../utils/validacoes';

@Component({
  selector: 'app-veias-jugulares',
  templateUrl: './veias-jugulares.component.html',
  styleUrls: ['./veias-jugulares.component.css']
})
export class VeiasJugularesComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdTrombose') rdTrombose: MatRadioButton;

  @ViewChild('ckDireita') ckDireita: MatCheckbox;
  @ViewChild('ckEsquerda') ckEsquerda: MatCheckbox;

  showAndHideTerms: any;
  countLesoes = 0;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahTrombose: false,
    };
  }


  clickNormal() {
    this.showAndHideTerms.sahTrombose = false;
    this.catchIDs();
  }

  clickAusente() {
    this.showAndHideTerms.sahTrombose = false;
    this.catchIDs();
  }

  clickTrombose() {
    this.showAndHideTerms.sahTrombose = true;
    this.catchIDs();
  }


  clickCheckBox() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.countLesoes = 0;

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }
    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }
    if (this.rdTrombose.checked) {
      this.termoSelecionado.emit(this.rdTrombose.id);

      if (this.ckDireita != undefined && this.ckDireita.checked) {
        const objeto = {elemento: this.ckDireita, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.ckEsquerda != undefined && this.ckEsquerda.checked) {
        const objeto = {elemento: this.ckEsquerda, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }
    this.listaCompleta.emit();
  }

}
