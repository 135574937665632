import {NgModule} from '@angular/core';
import {AbdomeTotalComponent} from './abdome-total.component';
import {AortaComponent} from './TermosComponents/aorta/aorta.component';
import {AsciteComponent} from './TermosComponents/ascite/ascite.component';
import {BacoComponent} from './TermosComponents/baco/baco.component';
import {BexigaComponent} from './TermosComponents/bexiga/bexiga.component';
import {FigadoComponent} from './TermosComponents/figado/figado.component';
import {PancreasComponent} from './TermosComponents/pancreas/pancreas.component';
import {RinDireitoComponent} from './TermosComponents/rin-direito/rin-direito.component';
import {RinEsquerdoComponent} from './TermosComponents/rin-esquerdo/rin-esquerdo.component';
import {VeiaPortaComponent} from './TermosComponents/veia-porta/veia-porta.component';
import {VesiculaBiliarComponent} from './TermosComponents/vesicula-biliar/vesicula-biliar.component';
import {ViasBiliaresComponent} from './TermosComponents/vias-biliares/vias-biliares.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';

import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import {
          MatExpansionModule,
          MatFormFieldModule,
          MatToolbarModule,
          MatButtonModule,
          MatSidenavModule,
          MatIconModule,
          MatListModule,
          MatInputModule,
          MatTableModule,
          MatPaginatorModule,
          MatSortModule,
          MatGridListModule,
          MatCardModule,
          MatMenuModule,
          MatTabsModule,
          MatDatepickerModule,
          MatProgressBarModule,
          MatProgressSpinnerModule,
          MatNativeDateModule,
          MatTooltipModule,
          MatRadioModule,
          MatCheckboxModule,
          MatSelectModule,
          MatSlideToggleModule
        } from '@angular/material';
import {SvgAbdomeTotalComponent} from './svg-abdome-total/svg-abdome-total.component';
import {TextEditorModule} from '../../../../utils/textEditor/text-editor/text-editor.module';
import {ClipboardModule} from 'ngx-clipboard';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { AbLinfonodosComponent } from './TermosComponents/ab-linfonodos/ab-linfonodos.component';
import { AlcaIntestinalComponent } from './TermosComponents/alca-intestinal/alca-intestinal.component';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { LimitacoesTecnicasComponent } from './TermosComponents/limitacoes-tecnicas/limitacoes-tecnicas.component';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    ClipboardModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
    TranslateModule
  ],
  exports: [
    AbdomeTotalComponent,
    DragDropModule,
    AortaComponent,
    AsciteComponent,
    BacoComponent,
    BexigaComponent,
    FigadoComponent,
    PancreasComponent,
    RinDireitoComponent,
    RinEsquerdoComponent,
    VeiaPortaComponent,
    VesiculaBiliarComponent,
    ViasBiliaresComponent,
    AbLinfonodosComponent,
    AlcaIntestinalComponent,
    LimitacoesTecnicasComponent,
  ],
  declarations: [
    AbdomeTotalComponent,
    AortaComponent,
    AsciteComponent,
    BacoComponent,
    BexigaComponent,
    FigadoComponent,
    PancreasComponent,
    RinDireitoComponent,
    RinEsquerdoComponent,
    VeiaPortaComponent,
    VesiculaBiliarComponent,
    ViasBiliaresComponent,
    SvgAbdomeTotalComponent,
    AbLinfonodosComponent,
    AlcaIntestinalComponent,
    LimitacoesTecnicasComponent
  ]
})
export class AbdomeTotalModule { }
