import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Voucher } from '../models/BO/Voucher';
import { UserHasVoucher } from '../models/BO/UserHasVoucher';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor(private http: HttpClient) { }

  getVoucher(): Observable<Voucher>{
    return this.http.get<Voucher>(environment.nLaudoApi + "Manual/BuscaManual/");
  }

  getVoucherByCode(code): Observable<Voucher>{
    return this.http.get<Voucher>(environment.nLaudoApi + "Voucher/BuscaVoucherPorCodigo/" + code );
  }

  salvaVoucherUsuario(voucherUsuario: any): Observable<UserHasVoucher> {
    const url = environment.nLaudoApi + 'Voucher/SalvaVoucherUsuario';
    const _voucherUsuario: UserHasVoucher = voucherUsuario;

    const obj = {
      'UserID': +localStorage.getItem('userID'),
      'VoucherID': _voucherUsuario.id
    };

    return this.http.post<UserHasVoucher>(url, obj);
  }

}
