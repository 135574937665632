import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-arteria-inferior-tireoide',
  templateUrl: './arteria-inferior-tireoide.component.html',
  styleUrls: ['./arteria-inferior-tireoide.component.css']
})
export class ArteriaInferiorTireoideComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdCitar') rdCitar: MatRadioButton;
  @ViewChild('inMedidaDir') inMedidaDir: ElementRef;
  @ViewChild('slPadraoDir') slPadraoDir: MatSelect;
  @ViewChild('inMedidaEsq') inMedidaEsq: ElementRef;
  @ViewChild('slPadraoEsq') slPadraoEsq: MatSelect;


  padroes: ArrGenerico[] = [
    { value: 'normal', viewValue: 'Normal' },
    { value: 'aumentada', viewValue: 'Aumentada' }
  ];


  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCitar: true
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNaoCitar() {
    this.showAndHideTerms.sahCitar = false;
    this.catchIDs();
  }

  clickCitar() {
    this.showAndHideTerms.sahCitar = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.slPadraoDir && this.slPadraoDir.value) || (this.slPadraoEsq && this.slPadraoEsq.value) 
    || (this.inMedidaDir && this.inMedidaDir.nativeElement.value) || (this.inMedidaEsq && this.inMedidaEsq.nativeElement.value)){
      this.rdCitar.checked = true;
    }
  }

  blurMedindo() {
    this.verificaLesoes();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }

    if (this.rdCitar.checked) {
      this.termoSelecionado.emit(this.rdCitar.id);

      //Direirta
      if (this.inMedidaDir && this.inMedidaDir.nativeElement.value
        && this.slPadraoDir && this.slPadraoDir.value) {
        //Medida
        let objeto = { elemento: this.inMedidaDir.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
        //Padrao
        objeto = { elemento: this.slPadraoDir, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      //Esquerda
      if (this.inMedidaEsq && this.inMedidaEsq.nativeElement.value
        && this.slPadraoEsq && this.slPadraoEsq.value) {
        //Medida
        let objeto = { elemento: this.inMedidaEsq.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
        //Padrao
        objeto = { elemento: this.slPadraoEsq, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
