import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SpeechToTextService } from 'src/app/services/speech-to-text.service';

@Component({
  selector: 'app-achados-exame',
  templateUrl: './achados-exame.component.html',
  styleUrls: ['./achados-exame.component.css']
})
export class AchadosExameComponent implements OnInit {
  @Output() enviaAchados = new EventEmitter();

  @ViewChild('inAchados') inAchados: ElementRef;
  @ViewChild("gSearch") formSearch;
  @ViewChild('inFinalTranscript') inFinalTranscript: any;
  @ViewChild('inTempTranscript') inTempTranscript: any;
  @ViewChild('micButton') micButton: ElementRef;
  @ViewChild('pulseRing') pulseRing: ElementRef;
  @ViewChild('iconMic') iconMic: ElementRef;
  @ViewChild('iconStop') iconStop: ElementRef;


  constructor(public _speech: SpeechToTextService) {
  }

  ngOnInit() {
  }

  blur() {
    this._speech.finalTranscription = this.inAchados.nativeElement.value;
    debugger
    if (this.inAchados.nativeElement.value.includes('\n')) {
      var textoInput = this.inAchados.nativeElement.value.replace(/\n/gi, '<br>');
      this.enviaAchados.emit(textoInput);
    } else if (this.inAchados) {
      this.enviaAchados.emit(this.inAchados.nativeElement.value);
    }
  }

  async gravadorDeVoz() {
    this._speech.gravadorDeVoz(
      this._speech.gravando === false
        ? false
        : true
    ).then(() => {
      var texto = this._speech.capitalize(this._speech.finalTranscription);
      if (texto.includes('<br>')) {
        var textoInput = texto.replace(/\<br>/gi, '\n');
        this.inAchados.nativeElement.value = textoInput;
      } else {
        this.inAchados.nativeElement.value = texto;
      }
      this.inFinalTranscript = texto;
      this.enviaAchados.emit(texto);
    }).then(() => {
      if (this._speech.gravando) {
        this.pulseRing.nativeElement.style.display = 'block';
        this.micButton.nativeElement.classList.add('mic-button-gravando');
        this.micButton.nativeElement.classList.remove('mic-button');
      } else {
        this.pulseRing.nativeElement.style.display = 'none';
        this.micButton.nativeElement.classList.remove('mic-button-gravando');
        this.micButton.nativeElement.classList.add('mic-button');
      }
    });
  }
}
