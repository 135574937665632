import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-ducto-venoso',
  templateUrl: './ducto-venoso.component.html',
  styleUrls: ['./ducto-venoso.component.css']
})
export class DuctoVenosoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNaocitar') rdNaocitar: MatRadioButton;
  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('rdReduzida') rdReduzida: MatRadioButton;
  @ViewChild('rdNegativa') rdNegativa: MatRadioButton;

  @ViewChild('inIP') inIP: ElementRef;

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: false,
      sahMedida: false,
    };
  }

  clickNaoCitar() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahMedida = false;
    this.catchIDs();
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahMedida = true;
    this.catchIDs();
  }


  clickDefault() {
    this.showAndHideTerms.sahMedida = true;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahMedida = true;
    this.catchIDs();
  }

  blurParametros() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaocitar.checked) {
      this.termoSelecionado.emit(this.rdNaocitar.id);
    }

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.inIP && this.inIP.nativeElement.value) {
        const objeto = { elemento: this.inIP.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdAlterado.checked) {
      this.termoSelecionado.emit(this.rdAlterado.id);

      if (this.rdReduzida && this.rdReduzida.checked)
        this.termoSelecionado.emit(this.rdReduzida.id);
      else if (this.rdNegativa && this.rdNegativa.checked)
        this.termoSelecionado.emit(this.rdNegativa.id);
      else
        this.termoSelecionado.emit(3997);//rdReduzida.id

      if (this.inIP && this.inIP.nativeElement.value) {
        const objeto = { elemento: this.inIP.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
