import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Term, Injuries } from 'src/app/models/BO/Term';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { LaudosService } from 'src/app/services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { Validacoes } from 'src/app/utils/validacoes';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { TermosGenericos } from 'src/app/models/Helper/TermosGenericos';
import { TermResult } from 'src/app/models/BO/TermResult';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';
import { ControladorFrasesLaudoService } from 'src/app/services/ControladorFrasesLaudo.service';

@Component({
  selector: 'app-aorta-iliaca',
  templateUrl: './aorta-iliaca.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./aorta-iliaca.component.css']
})
export class AortaIliacaComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;
  listTermDefault=[
    {listTerm:[2060],blockID:199},
    {listTerm:[2268],blockID:200},
    {listTerm:[2299],blockID:201},
    {listTerm:[2324],blockID:202},
    {listTerm:[2349],blockID:203},
    {listTerm:[2374],blockID:204},
    {listTerm:[2399],blockID:205},
    {listTerm:[2283],blockID:206},
    {listTerm:[2287],blockID:207},
  ]

  descricoes: DescricaoConclusao[] = [
    {
      value: 'Foram avaliadas os seguintes segmentos arteriais.',
      blockID: 0, textTermID: 0, visivel: true
    },
    {
      value: '<b>Aorta</b> pérvia, de trajeto e calibre preservados.',
      blockID: 199, textTermID: 0, visivel: true
    },
    {
      value: '<b>Artéria ilíaca comum direita</b> pérvia, de trajeto e calibre preservados.',
      blockID: 200, textTermID: 0, visivel: true
    },
    {
      value: '<b>Artéria ilíaca comum esquerda</b> pérvia, de trajeto e calibre preservados.',
      blockID: 201, textTermID: 0, visivel: true
    },
    {
      value: '<b>Artéria ilíaca externa direita</b> pérvia, de trajeto e calibre preservados.',
      blockID: 202, textTermID: 0, visivel: true
    },
    {
      value: '<b>Artéria ilíaca externa esquerda</b> pérvia, de trajeto e calibre preservados.',
      blockID: 203, textTermID: 0, visivel: true
    },
    {
      value: '<b>Artéria ilíaca interna direita</b> pérvia, de trajeto e calibre preservados.',
      blockID: 204, textTermID: 0, visivel: true
    },
    {
      value: '<b>Artéria ilíaca interna esquerda</b> pérvia, de trajeto e calibre preservados.',
      blockID: 205, textTermID: 0, visivel: true
    },
    {
      value: 'Ausência de placas ateromatosas.',
      blockID: 206, textTermID: 0, visivel: true
    },
    {
      value: 'Não há evidências de lesões estenosantes.',
      blockID: 207, textTermID: 0, visivel: true
    },
    {
      value: 'Os vasos estudados apresentam padrão espectral e velocidades de pico sistólico normais ao estudo Doppler.',
      blockID: 0, textTermID: 0, visivel: true
    }

  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Ultrassom Doppler do sistema aortoilíaco dentro dos limites da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 199, textTermID: 0, visivel: true },
    { value: '', blockID: 200, textTermID: 0, visivel: true },
    { value: '', blockID: 201, textTermID: 0, visivel: true },
    { value: '', blockID: 202, textTermID: 0, visivel: true },
    { value: '', blockID: 203, textTermID: 0, visivel: true },
    { value: '', blockID: 204, textTermID: 0, visivel: true },
    { value: '', blockID: 205, textTermID: 0, visivel: true },
    { value: '', blockID: 206, textTermID: 0, visivel: true },
    { value: '', blockID: 207, textTermID: 0, visivel: true },
  ];

  genericAorta: TermosGenericos[] = [
    { value: '', selected: false, termID: 2078, term: '[Calibre]' },
    { value: '', selected: false, termID: 2084, term: '[Colo Distal]' },
    { value: '', selected: false, termID: 2083, term: '[Colo Proximal]' },
    { value: '', selected: false, termID: 2087, term: '[DiametroAn]' },
    { value: '', selected: false, termID: 2085, term: '[Dist. Arterias Renais]' },
    { value: '', selected: false, termID: 2067, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 2068, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 2070, term: '[Espessura]' },
    { value: '', selected: false, termID: 2073, term: '[Estenose]' },
    { value: '', selected: false, termID: 2082, term: '[ExtensaoAn]' },
    { value: '', selected: false, termID: 2076, term: '[ExtensaoTro]' },
    { value: '', selected: false, termID: 2072, term: '[Extensao]' },
    { value: '', selected: false, termID: 2086, term: '[LocalizacaoAn]' },
    { value: '', selected: false, termID: 2075, term: '[LocalizacaoTro]' },
    { value: '', selected: false, termID: 2066, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2062, term: '[Motivo]' },
    { value: '', selected: false, termID: 2069, term: '[Superficie]' },
    { value: '', selected: false, termID: 2080, term: '[Trombo]' }
  ];

  genericArteriaIliDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 2270, term: '[Motivo]' },
    { value: '', selected: false, termID: 2272, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 2273, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 2274, term: '[Superficie]' },
    { value: '', selected: false, termID: 2275, term: '[Espessura]' },
    { value: '', selected: false, termID: 2276, term: '[Extensao]' },
    { value: '', selected: false, termID: 2277, term: '[Estenose]' },
    { value: '', selected: false, termID: 2279, term: '[ExtensaoTro]' },
    { value: '', selected: false, termID: 2281, term: '[Calibre]' },
    { value: '', selected: false, termID: 2291, term: '[Trombo]' },
    { value: '', selected: false, termID: 2292, term: '[CalibreAn]' },
    { value: '', selected: false, termID: 2293, term: '[ExtensaoAn]' },
    { value: '', selected: false, termID: 2294, term: '[Colo Proximal]' },
    { value: '', selected: false, termID: 2295, term: '[Colo Distal]' },
    { value: '', selected: false, termID: 2296, term: '[Dist. Arterias Renais]' },
    { value: '', selected: false, termID: 2298, term: '[DiametroAn]' }
  ];

  genericArteriaIliEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 2301, term: '[Motivo]' },
    { value: '', selected: false, termID: 2305, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 2306, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 2307, term: '[Superficie]' },
    { value: '', selected: false, termID: 2308, term: '[Espessura]' },
    { value: '', selected: false, termID: 2309, term: '[Extensao]' },
    { value: '', selected: false, termID: 2310, term: '[Estenose]' },
    { value: '', selected: false, termID: 2312, term: '[ExtensaoTro]' },
    { value: '', selected: false, termID: 2314, term: '[Calibre]' },
    { value: '', selected: false, termID: 2316, term: '[Trombo]' },
    { value: '', selected: false, termID: 2317, term: '[CalibreAn]' },
    { value: '', selected: false, termID: 2318, term: '[ExtensaoAn]' },
    { value: '', selected: false, termID: 2319, term: '[Colo Proximal]' },
    { value: '', selected: false, termID: 2320, term: '[Colo Distal]' },
    { value: '', selected: false, termID: 2321, term: '[Dist. Arterias Renais]' },
    { value: '', selected: false, termID: 2323, term: '[DiametroAn]' }
  ];

  genericArtIliacaExterDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 2326, term: '[Motivo]' },
    { value: '', selected: false, termID: 2330, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 2331, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 2332, term: '[Superficie]' },
    { value: '', selected: false, termID: 2333, term: '[Espessura]' },
    { value: '', selected: false, termID: 2334, term: '[Extensao]' },
    { value: '', selected: false, termID: 2335, term: '[Estenose]' },
    { value: '', selected: false, termID: 2337, term: '[ExtensaoTro]' },
    { value: '', selected: false, termID: 2339, term: '[Calibre]' },
    { value: '', selected: false, termID: 2341, term: '[Trombo]' },
    { value: '', selected: false, termID: 2342, term: '[CalibreAn]' },
    { value: '', selected: false, termID: 2343, term: '[ExtensaoAn]' },
    { value: '', selected: false, termID: 2344, term: '[Colo Proximal]' },
    { value: '', selected: false, termID: 2345, term: '[Colo Distal]' },
    { value: '', selected: false, termID: 2346, term: '[Dist. Arterias Renais]' },
    { value: '', selected: false, termID: 2348, term: '[DiametroAn]' }
  ];

  genericArtIliacaExterEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 2351, term: '[Motivo]' },
    { value: '', selected: false, termID: 2355, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 2356, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 2357, term: '[Superficie]' },
    { value: '', selected: false, termID: 2358, term: '[Espessura]' },
    { value: '', selected: false, termID: 2359, term: '[Extensao]' },
    { value: '', selected: false, termID: 2360, term: '[Estenose]' },
    { value: '', selected: false, termID: 2362, term: '[ExtensaoTro]' },
    { value: '', selected: false, termID: 2364, term: '[Calibre]' },
    { value: '', selected: false, termID: 2366, term: '[Trombo]' },
    { value: '', selected: false, termID: 2367, term: '[CalibreAn]' },
    { value: '', selected: false, termID: 2368, term: '[ExtensaoAn]' },
    { value: '', selected: false, termID: 2369, term: '[Colo Proximal]' },
    { value: '', selected: false, termID: 2370, term: '[Colo Distal]' },
    { value: '', selected: false, termID: 2371, term: '[Dist. Arterias Renais]' },
    { value: '', selected: false, termID: 2373, term: '[DiametroAn]' }
  ];

  genericArtIliacaInterDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 2376, term: '[Motivo]' },
    { value: '', selected: false, termID: 2380, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 2381, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 2382, term: '[Superficie]' },
    { value: '', selected: false, termID: 2383, term: '[Espessura]' },
    { value: '', selected: false, termID: 2384, term: '[Extensao]' },
    { value: '', selected: false, termID: 2385, term: '[Estenose]' },
    { value: '', selected: false, termID: 2387, term: '[ExtensaoTro]' },
    { value: '', selected: false, termID: 2389, term: '[Calibre]' },
    { value: '', selected: false, termID: 2391, term: '[Trombo]' },
    { value: '', selected: false, termID: 2392, term: '[CalibreAn]' },
    { value: '', selected: false, termID: 2393, term: '[ExtensaoAn]' },
    { value: '', selected: false, termID: 2394, term: '[Colo Proximal]' },
    { value: '', selected: false, termID: 2395, term: '[Colo Distal]' },
    { value: '', selected: false, termID: 2396, term: '[Dist. Arterias Renais]' },
    { value: '', selected: false, termID: 2398, term: '[DiametroAn]' }
  ];

  genericArtIliacaInterEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 2401, term: '[Motivo]' },
    { value: '', selected: false, termID: 2405, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 2406, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 2407, term: '[Superficie]' },
    { value: '', selected: false, termID: 2408, term: '[Espessura]' },
    { value: '', selected: false, termID: 2409, term: '[Extensao]' },
    { value: '', selected: false, termID: 2410, term: '[Estenose]' },
    { value: '', selected: false, termID: 2412, term: '[ExtensaoTro]' },
    { value: '', selected: false, termID: 2414, term: '[Calibre]' },
    { value: '', selected: false, termID: 2416, term: '[Trombo]' },
    { value: '', selected: false, termID: 2417, term: '[CalibreAn]' },
    { value: '', selected: false, termID: 2418, term: '[ExtensaoAn]' },
    { value: '', selected: false, termID: 2419, term: '[Colo Proximal]' },
    { value: '', selected: false, termID: 2420, term: '[Colo Distal]' },
    { value: '', selected: false, termID: 2421, term: '[Dist. Arterias Renais]' },
    { value: '', selected: false, termID: 2423, term: '[DiametroAn]' }
  ];

  genericEndoprotese: TermosGenericos[] = [
    { value: '', selected: false, termID: 2289, term: '[Localizacao]' },
    { value: '', selected: false, termID: 2290, term: '[Estenose]' }
  ];

  Subtitulo: string;
  subtituloBloco: string;
  mask: string;
  htmlContent: string;
  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  term = new Term();
  injuries = new Injuries();
  cabecalhoTexto = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  lesionRow = 1;
  exibeTabela = false;
  tabelaValor = new Array<string>();
  rodapeTexto = '';
  tabela = '';

  cabecalho = false;
  indicacao = false;
  aorta = true;
  artIliacaDireita = false;
  artIliacaEsquerda = false;
  artIliacaExterDireita = false;
  artIliacaExterEsquerda = false;
  artIliacaInterDireita = false;
  artIliacaInterEsquerda = false;
  endoprotese = false;
  ateromatose = false;
  tabelaAortaIliacas = false;
  achadosTexto = false;
  rodape = false;
  editLaudoMode: boolean = false;

  medicalReportTypeID = 39;
  aortaID = 199;
  artIliacaDireitaID = 200;
  artIliacaEsquerdaID = 201;
  artIliacaExterDireitaID = 202;
  artIliacaExterEsquerdaID = 203;
  artIliacaInterDireitaID = 204;
  artIliacaInterEsquerdaID = 205;
  ateromatoseID = 206;
  endoproteseID = 207;

  naoContemPlacaAorta = true;
  naoContemPlacaArtIliacaDireita = true;
  naoContemPlacaArtIliacaEsquerda = true;
  naoContemPlacaArtIliacaExterDireita = true;
  naoContemPlacaArtIliacaExterEsquerda = true;
  naoContemPlacaArtIliacaInterDireita = true;
  naoContemPlacaArtIliacaInterEsquerda=true;

  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  public switchFormReport = true;
  public showForm: Boolean = true;
  unidadeMedida: string;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      id: this.aortaID,
      name: 'aorta',
      titulo: 'Aorta',
      selected: true
    },
    {
      id: this.artIliacaDireitaID,
      name: 'arteria-direita',
      titulo: 'Arteria Iliaca Comum Direita',
      selected: false
    },
    {
      id: this.artIliacaEsquerdaID,
      name: 'arteria-esquerda',
      titulo: 'Arteria Iliaca Comum Esquerda',
      selected: false
    },
    {
      id: this.artIliacaExterDireitaID,
      name: 'externa-direita',
      titulo: 'Arteria Iliaca Externa Direita',
      selected: false
    },
    {
      id: this.artIliacaExterEsquerdaID,
      name: 'externa-esquerda',
      titulo: 'Arteria Iliaca Externa Esquerda',
      selected: false
    },
    {
      id: this.artIliacaInterDireitaID,
      name: 'interna-direita',
      titulo: 'Arteria Iliaca Interna Direita',
      selected: false
    },
    {
      id: this.artIliacaInterEsquerdaID,
      name: 'interna-esquerda',
      titulo: 'Arteria Iliaca Interna Esquerda',
      selected: false
    },
    {
      id: this.ateromatoseID,
      name: 'ateromatose',
      titulo: 'Ateromatose',
      selected: false
    },
    {
      id: this.endoproteseID,
      name: 'endoprotese',
      titulo: 'Endoprotese',
      selected: false
    },
    {
      id: 'tabela',
      name: 'tabela',
      titulo: 'Tabela',
      selected: false
    },
    {
      id: 'achados',
      name: 'achados-adicionais',
      titulo: 'Achados adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      id: 'rodape',
      name: 'rodape',
      titulo: 'Assinatura',
      selected: false,
    }
  ];


  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _validacoes: Validacoes,
    private _common: CommonService,
    private _dialog: MatDialog,
    private _pouchService: PouchDBService,
    private _copyPaste: CopyPaste,
    public _addText: AddText,
    private _config: ConfigLaudoService,
    private dataService:DataTermService,
    private _controlFrases: ControladorFrasesLaudoService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("aorta-iliacaAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Aorta';
    this.subtituloBloco = 'Aorta';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [
      this.aortaID,
      this.artIliacaDireitaID,
      this.artIliacaEsquerdaID,
      this.artIliacaExterDireitaID,
      this.artIliacaExterEsquerdaID,
      this.artIliacaInterDireitaID,
      this.artIliacaInterEsquerdaID,
      this.ateromatoseID,
      this.endoproteseID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "aorta-iliacaMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase( listBlocks, "aortaIliacas");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-aorta-iliaca-doppler');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-aorta-iliaca-doppler');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo() {
    this._pouchService.getAll("aorta-iliacaAnalise").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.achadosAdTexto = "";
    this.rodapeTexto = "";
    this.showForm = false;
    this.exibeTabela = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  selecionar(itemSelecionado): any {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.aorta = (itemSelecionado.id === this.aortaID);
    this.artIliacaDireita = (itemSelecionado.id === this.artIliacaDireitaID);
    this.artIliacaEsquerda = (itemSelecionado.id === this.artIliacaEsquerdaID);
    this.artIliacaExterDireita = (itemSelecionado.id === this.artIliacaExterDireitaID);
    this.artIliacaExterEsquerda = (itemSelecionado.id === this.artIliacaExterEsquerdaID);
    this.artIliacaInterDireita = (itemSelecionado.id === this.artIliacaInterDireitaID);
    this.artIliacaInterEsquerda = (itemSelecionado.id === this.artIliacaInterEsquerdaID);
    this.ateromatose = (itemSelecionado.id === this.ateromatoseID);
    this.endoprotese = (itemSelecionado.id === this.endoproteseID);
    this.tabelaAortaIliacas = (itemSelecionado.id === 'tabela');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;

    this.genericAorta = [
      { value: '', selected: false, termID: 2078, term: '[Calibre]' },
      { value: '', selected: false, termID: 2084, term: '[Colo Distal]' },
      { value: '', selected: false, termID: 2083, term: '[Colo Proximal]' },
      { value: '', selected: false, termID: 2087, term: '[DiametroAn]' },
      { value: '', selected: false, termID: 2085, term: '[Dist. Arterias Renais]' },
      { value: '', selected: false, termID: 2067, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 2068, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 2070, term: '[Espessura]' },
      { value: '', selected: false, termID: 2073, term: '[Estenose]' },
      { value: '', selected: false, termID: 2082, term: '[ExtensaoAn]' },
      { value: '', selected: false, termID: 2076, term: '[ExtensaoTro]' },
      { value: '', selected: false, termID: 2072, term: '[Extensao]' },
      { value: '', selected: false, termID: 2086, term: '[LocalizacaoAn]' },
      { value: '', selected: false, termID: 2075, term: '[LocalizacaoTro]' },
      { value: '', selected: false, termID: 2066, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2062, term: '[Motivo]' },
      { value: '', selected: false, termID: 2069, term: '[Superficie]' },
      { value: '', selected: false, termID: 2080, term: '[Trombo]' }
    ];

    this.genericArteriaIliDireita = [
      { value: '', selected: false, termID: 2270, term: '[Motivo]' },
      { value: '', selected: false, termID: 2272, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 2273, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 2274, term: '[Superficie]' },
      { value: '', selected: false, termID: 2275, term: '[Espessura]' },
      { value: '', selected: false, termID: 2276, term: '[Extensao]' },
      { value: '', selected: false, termID: 2277, term: '[Estenose]' },
      { value: '', selected: false, termID: 2279, term: '[ExtensaoTro]' },
      { value: '', selected: false, termID: 2281, term: '[Calibre]' },
      { value: '', selected: false, termID: 2291, term: '[Trombo]' },
      { value: '', selected: false, termID: 2292, term: '[CalibreAn]' },
      { value: '', selected: false, termID: 2293, term: '[ExtensaoAn]' },
      { value: '', selected: false, termID: 2294, term: '[Colo Proximal]' },
      { value: '', selected: false, termID: 2295, term: '[Colo Distal]' },
      { value: '', selected: false, termID: 2296, term: '[Dist. Arterias Renais]' },
      { value: '', selected: false, termID: 2298, term: '[DiametroAn]' }
    ];

    this.genericArteriaIliEsquerda = [
      { value: '', selected: false, termID: 2301, term: '[Motivo]' },
      { value: '', selected: false, termID: 2305, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 2306, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 2307, term: '[Superficie]' },
      { value: '', selected: false, termID: 2308, term: '[Espessura]' },
      { value: '', selected: false, termID: 2309, term: '[Extensao]' },
      { value: '', selected: false, termID: 2310, term: '[Estenose]' },
      { value: '', selected: false, termID: 2312, term: '[ExtensaoTro]' },
      { value: '', selected: false, termID: 2314, term: '[Calibre]' },
      { value: '', selected: false, termID: 2316, term: '[Trombo]' },
      { value: '', selected: false, termID: 2317, term: '[CalibreAn]' },
      { value: '', selected: false, termID: 2318, term: '[ExtensaoAn]' },
      { value: '', selected: false, termID: 2319, term: '[Colo Proximal]' },
      { value: '', selected: false, termID: 2320, term: '[Colo Distal]' },
      { value: '', selected: false, termID: 2321, term: '[Dist. Arterias Renais]' },
      { value: '', selected: false, termID: 2323, term: '[DiametroAn]' }
    ];

    this.genericArtIliacaExterDireita = [
      { value: '', selected: false, termID: 2326, term: '[Motivo]' },
      { value: '', selected: false, termID: 2330, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 2331, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 2332, term: '[Superficie]' },
      { value: '', selected: false, termID: 2333, term: '[Espessura]' },
      { value: '', selected: false, termID: 2334, term: '[Extensao]' },
      { value: '', selected: false, termID: 2335, term: '[Estenose]' },
      { value: '', selected: false, termID: 2337, term: '[ExtensaoTro]' },
      { value: '', selected: false, termID: 2339, term: '[Calibre]' },
      { value: '', selected: false, termID: 2341, term: '[Trombo]' },
      { value: '', selected: false, termID: 2342, term: '[CalibreAn]' },
      { value: '', selected: false, termID: 2343, term: '[ExtensaoAn]' },
      { value: '', selected: false, termID: 2344, term: '[Colo Proximal]' },
      { value: '', selected: false, termID: 2345, term: '[Colo Distal]' },
      { value: '', selected: false, termID: 2346, term: '[Dist. Arterias Renais]' },
      { value: '', selected: false, termID: 2348, term: '[DiametroAn]' }
    ];

    this.genericArtIliacaExterEsquerda = [
      { value: '', selected: false, termID: 2351, term: '[Motivo]' },
      { value: '', selected: false, termID: 2355, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 2356, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 2357, term: '[Superficie]' },
      { value: '', selected: false, termID: 2358, term: '[Espessura]' },
      { value: '', selected: false, termID: 2359, term: '[Extensao]' },
      { value: '', selected: false, termID: 2360, term: '[Estenose]' },
      { value: '', selected: false, termID: 2362, term: '[ExtensaoTro]' },
      { value: '', selected: false, termID: 2364, term: '[Calibre]' },
      { value: '', selected: false, termID: 2366, term: '[Trombo]' },
      { value: '', selected: false, termID: 2367, term: '[CalibreAn]' },
      { value: '', selected: false, termID: 2368, term: '[ExtensaoAn]' },
      { value: '', selected: false, termID: 2369, term: '[Colo Proximal]' },
      { value: '', selected: false, termID: 2370, term: '[Colo Distal]' },
      { value: '', selected: false, termID: 2371, term: '[Dist. Arterias Renais]' },
      { value: '', selected: false, termID: 2373, term: '[DiametroAn]' }
    ];

    this.genericArtIliacaInterDireita = [
      { value: '', selected: false, termID: 2376, term: '[Motivo]' },
      { value: '', selected: false, termID: 2380, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 2381, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 2382, term: '[Superficie]' },
      { value: '', selected: false, termID: 2383, term: '[Espessura]' },
      { value: '', selected: false, termID: 2384, term: '[Extensao]' },
      { value: '', selected: false, termID: 2385, term: '[Estenose]' },
      { value: '', selected: false, termID: 2387, term: '[ExtensaoTro]' },
      { value: '', selected: false, termID: 2389, term: '[Calibre]' },
      { value: '', selected: false, termID: 2391, term: '[Trombo]' },
      { value: '', selected: false, termID: 2392, term: '[CalibreAn]' },
      { value: '', selected: false, termID: 2393, term: '[ExtensaoAn]' },
      { value: '', selected: false, termID: 2394, term: '[Colo Proximal]' },
      { value: '', selected: false, termID: 2395, term: '[Colo Distal]' },
      { value: '', selected: false, termID: 2396, term: '[Dist. Arterias Renais]' },
      { value: '', selected: false, termID: 2398, term: '[DiametroAn]' }
    ];

    this.genericArtIliacaInterEsquerda = [
      { value: '', selected: false, termID: 2401, term: '[Motivo]' },
      { value: '', selected: false, termID: 2405, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 2406, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 2407, term: '[Superficie]' },
      { value: '', selected: false, termID: 2408, term: '[Espessura]' },
      { value: '', selected: false, termID: 2409, term: '[Extensao]' },
      { value: '', selected: false, termID: 2410, term: '[Estenose]' },
      { value: '', selected: false, termID: 2412, term: '[ExtensaoTro]' },
      { value: '', selected: false, termID: 2414, term: '[Calibre]' },
      { value: '', selected: false, termID: 2416, term: '[Trombo]' },
      { value: '', selected: false, termID: 2417, term: '[CalibreAn]' },
      { value: '', selected: false, termID: 2418, term: '[ExtensaoAn]' },
      { value: '', selected: false, termID: 2419, term: '[Colo Proximal]' },
      { value: '', selected: false, termID: 2420, term: '[Colo Distal]' },
      { value: '', selected: false, termID: 2421, term: '[Dist. Arterias Renais]' },
      { value: '', selected: false, termID: 2423, term: '[DiametroAn]' }
    ];

    this.genericEndoprotese = [
      { value: '', selected: false, termID: 2289, term: '[Localizacao]' },
      { value: '', selected: false, termID: 2290, term: '[Estenose]' }
    ];
  }

  enviaTermos($event) {
    if ($event === 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  adicionaSelecaoAorta($event) {
    this.genericAorta.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoArteriaIliDireita($event) {
    this.genericArteriaIliDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoArteriaIliEsquerda($event) {
    this.genericArteriaIliEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoArtIliacaExterDireita($event) {
    this.genericArtIliacaExterDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoArtIliacaExterEsquerda($event) {
    this.genericArtIliacaExterEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoArtIliacaInterDireita($event) {
    this.genericArtIliacaInterDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoArtIliacaInterEsquerda($event) {
    this.genericArtIliacaInterEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoEndoprotese($event) {
    this.genericEndoprotese.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  atribuiValorTabela($element) {
    // Preenchendo as medidas
    // let accDir, aciDir, accEsq, aciEsq: number;
    const value = this._validacoes.formataDecimal($element.value);
    this.exibeTabela = true;


    if ($element.id === 'Aorta') {
      this.tabelaValor[0] = value;
    } else if ($element.id === 'Aorta') {
      this.tabelaValor[0] = value;
    } else if ($element.id === 'ArtIliComumDireita') {
      this.tabelaValor[1] = value;
    } else if ($element.id === 'ArtIliComumDireita') {
      this.tabelaValor[1] = value;
    } else if ($element.id === 'ArtIliComumEsquerda') {
      this.tabelaValor[2] = value;
    } else if ($element.id === 'ArtIliComumEsquerda') {
      this.tabelaValor[2] = value;
    } else if ($element.id === 'ArtIliExterDireita') {
      this.tabelaValor[3] = value;
    } else if ($element.id === 'ArtIliExterDireita') {
      this.tabelaValor[3] = value;
    } else if ($element.id === 'ArtIliExterEsquerda') {
      this.tabelaValor[4] = value;
    } else if ($element.id === 'ArtIliExterEsquerda') {
      this.tabelaValor[4] = value;
    } else if ($element.id === 'ArtIliInterDireita') {
      this.tabelaValor[5] = value;
    } else if ($element.id === 'ArtIliInterDireita') {
      this.tabelaValor[5] = value;
    } else if ($element.id === 'ArtIliInterEsquerda') {
      this.tabelaValor[6] = value;
    } else if ($element.id === 'ArtIliInterEsquerda') {
      this.tabelaValor[6] = value;
    } else if ($element.id === 'NaoCitar') {
      this.exibeTabela = false;
    }

    this.tabela = this.Tabela();

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'tabela',
        tabela: this.tabela,
      });
    }

  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
    let desc;
    if (this.aorta) {
      this.term.blockID = this.aortaID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        if (termResults[0].text !== undefined) {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.aortaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoAorta(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.aortaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          this.naoContemPlacaAorta = this._controlFrases.verificarSeContemTermoSelecionado(this.term, 2065);
          this.handleValidaSeAdicionaFrase();

          let obj = {
            value: '',
            blockID: this.aortaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoAorta(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.aortaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.aortaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.aortaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        }
      });
    } else if (this.artIliacaDireita) {

      this.term.blockID = this.artIliacaDireitaID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.artIliacaDireitaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoArteriaIliDireita(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.artIliacaDireitaID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        this.naoContemPlacaArtIliacaDireita = this._controlFrases.verificarSeContemTermoSelecionado(this.term,2271);
        this.handleValidaSeAdicionaFrase();

        let obj = {
          value: '',
          blockID: this.artIliacaDireitaID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclussaoArteriaIliDireita(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.artIliacaDireitaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.artIliacaDireitaID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.artIliacaDireitaID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.artIliacaEsquerda) {

      this.term.blockID = this.artIliacaEsquerdaID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.artIliacaEsquerdaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoArteriaIliEsquerda(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.artIliacaEsquerdaID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        this.naoContemPlacaArtIliacaEsquerda = this._controlFrases.verificarSeContemTermoSelecionado(this.term, 2303);
        this.handleValidaSeAdicionaFrase();

        let obj = {
          value: '',
          blockID: this.artIliacaEsquerdaID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclussaoArteriaIliEsquerda(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.artIliacaEsquerdaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.artIliacaEsquerdaID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.artIliacaEsquerdaID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.artIliacaExterDireita) {

      this.term.blockID = this.artIliacaExterDireitaID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.artIliacaExterDireitaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoArtIliacaExterDireita(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.artIliacaExterDireitaID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        this.naoContemPlacaArtIliacaExterDireita = this._controlFrases.verificarSeContemTermoSelecionado(this.term, 2328);
        this.handleValidaSeAdicionaFrase();

        let obj = {
          value: '',
          blockID: this.artIliacaExterDireitaID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclussaoArtIliacaExterDireita(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.artIliacaExterDireitaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.artIliacaExterDireitaID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.artIliacaExterDireitaID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.artIliacaExterEsquerda) {

      this.term.blockID = this.artIliacaExterEsquerdaID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.artIliacaExterEsquerdaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoArtIliacaExterEsquerda(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.artIliacaExterEsquerdaID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc =obj.value;
            }
          }
        });

        this.naoContemPlacaArtIliacaExterEsquerda = this._controlFrases.verificarSeContemTermoSelecionado(this.term, 2353);
        this.handleValidaSeAdicionaFrase();

        let obj = {
          value: '',
          blockID: this.artIliacaExterEsquerdaID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclussaoArtIliacaExterEsquerda(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.artIliacaExterEsquerdaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.artIliacaExterEsquerdaID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.artIliacaExterEsquerdaID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.artIliacaInterDireita) {

      this.term.blockID = this.artIliacaInterDireitaID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.artIliacaInterDireitaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoArtIliacaInterDireita(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.artIliacaInterDireitaID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }

          }
        });

        this.naoContemPlacaArtIliacaInterDireita = this._controlFrases.verificarSeContemTermoSelecionado(this.term, 2378);
        this.handleValidaSeAdicionaFrase();

        let obj = {
          value: '',
          blockID: this.artIliacaInterDireitaID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclussaoArtIliacaInterDireita(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.artIliacaInterDireitaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.artIliacaInterDireitaID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.artIliacaInterDireitaID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.artIliacaInterEsquerda) {

      this.term.blockID = this.artIliacaInterEsquerdaID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.artIliacaInterEsquerdaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoArtIliacaInterEsquerda(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.artIliacaInterEsquerdaID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }

          }
        });

        this.naoContemPlacaArtIliacaInterEsquerda = this._controlFrases.verificarSeContemTermoSelecionado(this.term, 2403);
        this.handleValidaSeAdicionaFrase();

        let obj = {
          value: '',
          blockID: this.artIliacaInterEsquerdaID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclussaoArtIliacaInterEsquerda(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.artIliacaInterEsquerdaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.artIliacaInterEsquerdaID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.artIliacaInterEsquerdaID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.endoprotese) {
      this.term.blockID = this.endoproteseID;

      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.endoproteseID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoEndoprotese(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.endoproteseID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.endoproteseID,
          textTermID: 0
        };
        let espaco = '';
        let contadorTexto = 0;
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclusaoEndoprotese(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.endoproteseID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.endoproteseID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.endoproteseID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });

    } else if (this.ateromatose) {
      this.term.blockID = this.ateromatoseID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.ateromatoseID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let contadorTexto = 0;
            let espaco = '';
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + resultado.text;
              espaco = '</br>';
              contadorTexto++;
            });
            let index = this.descricoes.findIndex(x => x.blockID === this.ateromatoseID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.ateromatoseID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let contadorTexto = 0;
        let espaco = '';
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + resultado.conclusion;
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.ateromatoseID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ateromatoseID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.ateromatoseID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });

    }
    }
  }

  MontaDescricaoAorta(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2062 || +term === 2066 || +term === 2067 || +term === 2068 ||
        +term === 2069 || +term === 2070 || +term === 2072 || +term === 2073 ||
        +term === 2075 || +term === 2076 || +term === 2078 || +term === 2080 ||
        +term === 2082 || +term === 2083 || +term === 2084 || +term === 2085 ||
        +term === 2086 || +term === 2087) {
        this.genericAorta.forEach(generic => {
          if (+generic.termID === +term) {
            const value = generic.value.split('|', 2);
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, value[0]);
            texto = textoRetorno;
          }
        });
      }

      this.descricoes.forEach(obj => {
        if(+term === 2074 || +term === 2075 || +term === 2076 || +term === 2080
          || +term === 2065 || +term === 2066 || +term === 2067 || +term === 2068
          || +term === 2069 || +term === 2073 || +term === 2070 || +term === 2072) {
          if(obj.blockID == 206 || obj.blockID == 207 ){
            obj.visivel = false;
          }
        } else {
          if(obj.blockID == 206 || obj.blockID == 207 ){
            obj.visivel = true;
          }
        }
      });
    });
    return texto;
  }

  MontaConclussaoAorta(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2062 || +term === 2066 || +term === 2067 || +term === 2068 ||
        +term === 2069 || +term === 2070 || +term === 2072 || +term === 2073 ||
        +term === 2075 || +term === 2076 || +term === 2078 || +term === 2080 ||
        +term === 2082 || +term === 2083 || +term === 2084 || +term === 2085 ||
        +term === 2086 || +term === 2087) {
        this.genericAorta.forEach(generic => {
          if (+generic.termID === +term) {
            const value = generic.value.split('|', 2);
            generic.selected = true;
            if (value.length > 1)
              textoRetorno = texto.replace(generic.term, value[1]);
            else
              textoRetorno = texto.replace(generic.term, value[0]);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoArteriaIliDireita(texto) {
    let textoRetorno = '';


    this.term.listTermID.forEach(term => {
      if (+term === 2270 || +term === 2272 || +term === 2273 || +term === 2274 ||
        +term === 2275 || +term === 2276 || +term === 2277 || +term === 2279 ||
        +term === 2281 || +term === 2291 || +term === 2292 || +term === 2293 ||
        +term === 2294 || +term === 2295 || +term === 2296 || +term === 2298) {
        this.genericArteriaIliDireita.forEach(generic => {
          if (+generic.termID === +term) {
            const value = generic.value.split('|', 2);
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, value[0]);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclussaoArteriaIliDireita(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2270 || +term === 2272 || +term === 2273 || +term === 2274 ||
        +term === 2275 || +term === 2276 || +term === 2277 || +term === 2279 ||
        +term === 2281 || +term === 2291 || +term === 2292 || +term === 2293 ||
        +term === 2294 || +term === 2295 || +term === 2296 || +term === 2298) {
        this.genericArteriaIliDireita.forEach(generic => {
          if (+generic.termID === +term) {
            const value = generic.value.split('|', 2);
            generic.selected = true;
            if (value.length > 1)
              textoRetorno = texto.replace(generic.term, value[1]);
            else
              textoRetorno = texto.replace(generic.term, value[0]);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoArteriaIliEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2301 || +term === 2305 || +term === 2306 || +term === 2307 ||
        +term === 2308 || +term === 2309 || +term === 2310 || +term === 2312 ||
        +term === 2314 || +term === 2316 || +term === 2317 || +term === 2318 ||
        +term === 2319 || +term === 2320 || +term === 2321 || +term === 2323) {
        this.genericArteriaIliEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            const value = generic.value.split('|', 2);
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, value[0]);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclussaoArteriaIliEsquerda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2301 || +term === 2305 || +term === 2306 || +term === 2307 ||
        +term === 2308 || +term === 2309 || +term === 2310 || +term === 2312 ||
        +term === 2314 || +term === 2316 || +term === 2317 || +term === 2318 ||
        +term === 2319 || +term === 2320 || +term === 2321 || +term === 2323) {
        this.genericArteriaIliEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            const value = generic.value.split('|', 2);
            generic.selected = true;
            if (value.length > 1)
              textoRetorno = texto.replace(generic.term, value[1]);
            else
              textoRetorno = texto.replace(generic.term, value[0]);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoArtIliacaExterDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2326 || +term === 2330 || +term === 2331 || +term === 2332 ||
        +term === 2333 || +term === 2334 || +term === 2335 || +term === 2337 ||
        +term === 2339 || +term === 2341 || +term === 2342 || +term === 2343 ||
        +term === 2344 || +term === 2345 || +term === 2346 || +term === 2348) {
        this.genericArtIliacaExterDireita.forEach(generic => {
          if (+generic.termID === +term) {
            const value = generic.value.split('|', 2);
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, value[0]);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclussaoArtIliacaExterDireita(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2326 || +term === 2330 || +term === 2331 || +term === 2332 ||
        +term === 2333 || +term === 2334 || +term === 2335 || +term === 2337 ||
        +term === 2339 || +term === 2341 || +term === 2342 || +term === 2343 ||
        +term === 2344 || +term === 2345 || +term === 2346 || +term === 2348) {
        this.genericArtIliacaExterDireita.forEach(generic => {
          if (+generic.termID === +term) {
            const value = generic.value.split('|', 2);
            generic.selected = true;
            if (value.length > 1)
              textoRetorno = texto.replace(generic.term, value[1]);
            else
              textoRetorno = texto.replace(generic.term, value[0]);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoArtIliacaExterEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2351 || +term === 2355 || +term === 2356 || +term === 2357 ||
        +term === 2358 || +term === 2359 || +term === 2360 || +term === 2362 ||
        +term === 2364 || +term === 2366 || +term === 2367 || +term === 2368 ||
        +term === 2369 || +term === 2370 || +term === 2371 || +term === 2373) {
        this.genericArtIliacaExterEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            const value = generic.value.split('|', 2);
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, value[0]);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclussaoArtIliacaExterEsquerda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2351 || +term === 2355 || +term === 2356 || +term === 2357 ||
        +term === 2358 || +term === 2359 || +term === 2360 || +term === 2362 ||
        +term === 2364 || +term === 2366 || +term === 2367 || +term === 2368 ||
        +term === 2369 || +term === 2370 || +term === 2371 || +term === 2373) {
        this.genericArtIliacaExterEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            const value = generic.value.split('|', 2);
            generic.selected = true;
            if (value.length > 1)
              textoRetorno = texto.replace(generic.term, value[1]);
            else
              textoRetorno = texto.replace(generic.term, value[0]);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  MontaDescricaoArtIliacaInterDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2376 || +term === 2380 || +term === 2381 || +term === 2382 ||
        +term === 2383 || +term === 2384 || +term === 2385 || +term === 2387 ||
        +term === 2389 || +term === 2391 || +term === 2392 || +term === 2393 ||
        +term === 2394 || +term === 2395 || +term === 2396 || +term === 2398) {
        this.genericArtIliacaInterDireita.forEach(generic => {
          if (+generic.termID === +term) {
            const value = generic.value.split('|', 2);
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, value[0]);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }


  MontaConclussaoArtIliacaInterDireita(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2376 || +term === 2380 || +term === 2381 || +term === 2382 ||
        +term === 2383 || +term === 2384 || +term === 2385 || +term === 2387 ||
        +term === 2389 || +term === 2391 || +term === 2392 || +term === 2393 ||
        +term === 2394 || +term === 2395 || +term === 2396 || +term === 2398) {
        this.genericArtIliacaInterDireita.forEach(generic => {
          if (+generic.termID === +term) {
            const value = generic.value.split('|', 2);
            generic.selected = true;
            if (value.length > 1)
              textoRetorno = texto.replace(generic.term, value[1]);
            else
              textoRetorno = texto.replace(generic.term, value[0]);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  MontaDescricaoArtIliacaInterEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2401 || +term === 2405 || +term === 2406 || +term === 2407 ||
        +term === 2408 || +term === 2409 || +term === 2410 || +term === 2412 ||
        +term === 2414 || +term === 2416 || +term === 2417 || +term === 2418 ||
        +term === 2419 || +term === 2420 || +term === 2421 || +term === 2423) {
        this.genericArtIliacaInterEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            const value = generic.value.split('|', 2);
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, value[0]);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclussaoArtIliacaInterEsquerda(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2401 || +term === 2405 || +term === 2406 || +term === 2407 ||
        +term === 2408 || +term === 2409 || +term === 2410 || +term === 2412 ||
        +term === 2414 || +term === 2416 || +term === 2417 || +term === 2418 ||
        +term === 2419 || +term === 2420 || +term === 2421 || +term === 2423) {
        this.genericArtIliacaInterEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            const value = generic.value.split('|', 2);
            generic.selected = true;
            if (value.length > 1)
              textoRetorno = texto.replace(generic.term, value[1]);
            else
              textoRetorno = texto.replace(generic.term, value[0]);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  MontaDescricaoEndoprotese(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2289 || +term === 2290) {
        this.genericEndoprotese.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoEndoprotese(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2289 || +term === 2290) {
        this.genericEndoprotese.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    tabela,
    cabecalho,
    rodape
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
            header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {

          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
            footer.innerHTML = rodape;
          }
        }

        //tabela
        if (tabela !== undefined) {
          const table = document.querySelector(`#e-${blockId} p`);

          if (table) {
            table.innerHTML = `</br> ${tabela}`;
          }
        }
			} else if(indicacao || achados) {
				if(indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  Tabela(): any {
    let strTable = '<style>' +
      'table {' +
      'width: 80%;' +
      '}' +
      'table, th, td {' +
      'border: 1px solid #c2c0c2;' +
      'border-collapse: collapse;' +
      'th, td {' +
      '  padding: 5px;' +
      '  text-align: left;' +
      '}' +
      '</style>' +
      '<table>';
    if (this.tabelaValor[0]) {
      strTable += '  <tr>';
      strTable += '    <th><b>Vaso</b></th>';
      strTable += '    <th><b>Calibre ('+ this.unidadeMedida +')</b></th>';
      strTable += '  </tr>';

    }
    if (this.tabelaValor[0] || this.tabelaValor[0]) {

      strTable += '  <tr>';
      strTable += '    <td><b>Aorta</b></td>';
      strTable += '    <td>' + this.tabelaValor[0] + ' '+ this.unidadeMedida +'</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[1] || this.tabelaValor[1]) {

      strTable += '  <tr>';
      strTable += '    <td><b>Artéria ilíaca comum direita</b></td>';
      strTable += '    <td>' + this.tabelaValor[1] + ' '+ this.unidadeMedida +'</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[2] || this.tabelaValor[2]) {

      strTable += '  <tr>' +
        '    <td><b>Artéria ilíaca comum esquerda</b></b></td>';
      strTable += '    <td>' + this.tabelaValor[2] + ' '+ this.unidadeMedida +'</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[3] || this.tabelaValor[3]) {

      strTable += '  <tr>' +
        '    <td><b>Artéria ilíaca externa direita</b></td>';
      strTable += '    <td>' + this.tabelaValor[3] + ' '+ this.unidadeMedida +'</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[4] || this.tabelaValor[4]) {

      strTable += '  <tr>' +
        '    <td><b>Artéria ilíaca externa esquerda</b></td>';
      strTable += '    <td>' + this.tabelaValor[4] + ' '+ this.unidadeMedida +'</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[5] || this.tabelaValor[5]) {

      strTable += '  <tr>' +
        '    <td><b>Artéria ilíaca interna direita</b></td>';
      strTable += '    <td>' + this.tabelaValor[5] + ' '+ this.unidadeMedida +'</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[6] || this.tabelaValor[6]) {

      strTable += '  <tr>' +
        '    <td><Artéria><b>Artéria ilíaca interna esquerda</b></td>';
      strTable += '    <td>' + this.tabelaValor[6] + ' '+ this.unidadeMedida +'</td>';
      strTable += '  </tr>';
    }
    strTable += '</tr>' +
      '</table>';

    return strTable;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'data': {
          'text': '<b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-tabela`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.tabela}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoes[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });



    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.indicacaoTexto;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';

    this.htmlContent += maskSplit[1];

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value && this.descricoes[i].visivel) {
        // if (i > 1 && i < 8) {
        //   this.htmlContent += this.descricoes[i].value + '<br/>';
        // } else {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
        // }
      }
    }

    // Tabela
    if (this.exibeTabela) {
      this.htmlContent += '</br>' + this.Tabela();
    }

    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }
    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    // if (this.conclusoes.length <= 1) {
    texto = 'Ultrassom Doppler do sistema aortoilíaco dentro dos limites da normalidade.';
    // }
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }
  handleValidaSeAdicionaFrase() {
    if(this.naoContemPlacaAorta && this.naoContemPlacaArtIliacaDireita && this.naoContemPlacaArtIliacaEsquerda
      && this.naoContemPlacaArtIliacaExterDireita && this.naoContemPlacaArtIliacaExterEsquerda
      && this.naoContemPlacaArtIliacaInterDireita && this.naoContemPlacaArtIliacaInterEsquerda){
      this.descricoes[this.descricoes.length-2].visivel = true;
      this.descricoes[this.descricoes.length-3].visivel = true;
    }else{
      this.descricoes[this.descricoes.length-2].visivel = false;
      this.descricoes[this.descricoes.length-3].visivel = false;
    }
  }
}
