import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-pulmoes',
  templateUrl: './pulmoes.component.html',
  styleUrls: ['./pulmoes.component.css']
})
export class PulmoesComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('ckOpacidadePulmonar') ckOpacidadePulmonar: MatCheckbox;
  @ViewChild('ckOpacidadeAlveolar') ckOpacidadeAlveolar: MatCheckbox;
  @ViewChild('slLocalizacaoDirAlveolar') slLocalizacaoDirAlveolar: MatSelect;
  @ViewChild('slLocalizacaoEsqAlveolar') slLocalizacaoEsqAlveolar: MatSelect;
  @ViewChild('ckOpacidadeReticular') ckOpacidadeReticular: MatCheckbox;
  @ViewChild('slLocalizacaoDirReticular') slLocalizacaoDirReticular: MatSelect;
  @ViewChild('slLocalizacaoEsqReticular') slLocalizacaoEsqReticular: MatSelect;
  @ViewChild('ckConsolidacao') ckConsolidacao: MatCheckbox;
  @ViewChild('slLocalizacaoDirConsolidacao') slLocalizacaoDirConsolidacao: MatSelect;
  @ViewChild('slLocalizacaoEsqConsolidacao') slLocalizacaoEsqConsolidacao: MatSelect;
  @ViewChild('ckComBroncograma') ckComBroncograma: MatCheckbox;
  @ViewChild('ckAtelectasiaLobar') ckAtelectasiaLobar: MatCheckbox;
  @ViewChild('slLoboAtelectasiaLobar') slLoboAtelectasiaLobar: MatSelect;
  @ViewChild('ckAtelectasiaLaminares') ckAtelectasiaLaminares: MatCheckbox;
  @ViewChild('slLocalizacaoAtelectasiaLaminares') slLocalizacaoAtelectasiaLaminares: MatSelect;
  @ViewChild('ckNodulo') ckNodulo: MatCheckbox;
  @ViewChild('ckNoduloOvalado') ckNoduloOvalado: MatCheckbox;
  @ViewChild('inMedidaNoduloOvalado') inMedidaNoduloOvalado: ElementRef;
  @ViewChild('ckNoduloEspiculado') ckNoduloEspiculado: MatCheckbox;
  @ViewChild('inMedidaNoduloEspiculado') inMedidaNoduloEspiculado: ElementRef;

  @ViewChild('ckPneumotorax') ckPneumotorax: MatCheckbox;
  @ViewChild('slLocalizacaoPneumotorax') slLocalizacaoPneumotorax: MatSelect;
  @ViewChild('slGraduacaoPneumotorax') slGraduacaoPneumotorax: MatSelect;
  @ViewChild('ckHidropneumotorax') ckHidropneumotorax: MatCheckbox;
  @ViewChild('slLocalizacaoHidropneumotorax') slLocalizacaoHidropneumotorax: MatSelect;
  @ViewChild('slGraduacaoHidropneumotorax') slGraduacaoHidropneumotorax: MatSelect;
  @ViewChild('ckHiperinsuflacao') ckHiperinsuflacao: MatCheckbox;

  localizacaoDirOpacidade: ArrayDropDown[] = [
    { value: 'superior direito', viewValue: 'Superior Direito' },
    { value: 'médio direito', viewValue: 'Médio Direito' },
    { value: 'inferior direito', viewValue: 'Inferior Direito' }
  ];

  localizacaoEsqOpacidade: ArrayDropDown[] = [
    { value: 'superior esquerdo', viewValue: 'Superior Esquerdo' },
    { value: 'médio esquerdo', viewValue: 'Médio Esquerdo' },
    { value: 'inferior esquerdo', viewValue: 'Inferior Esquerdo' }
  ];

  lobo: ArrayDropDown[] = [
    { value: 'superior direito', viewValue: 'Superior direito' },
    { value: 'inferior direito', viewValue: 'Inferior direito' },
    { value: 'médio', viewValue: 'Médio' },
    { value: 'superior esquerdo', viewValue: 'Superior esquerdo' },
    { value: 'inferior esquerdo', viewValue: 'Inferior esquerdo' },
    { value: 'língula', viewValue: 'Língula' },
  ];

  localizacaoAtelectasia: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' },
  ];

  localizacaoPneumotorax: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' },
  ];

  graduacaoPneumotorax: ArrayDropDown[] = [
    { value: 'Pequeno', viewValue: 'Pequeno' },
    { value: 'Moderado', viewValue: 'Moderado' },
    { value: 'Acentuado', viewValue: 'Acentuado' },
  ];

  showAndHideTerms: any;
  countLesoes = 0;

  ovaladoDirFormGroup: FormGroup;
  ovaladoEsqFormGroup: FormGroup;
  espiculadoDirFormGroup: FormGroup;
  espiculadoEsqFormGroup: FormGroup;
  alveolarDirFormGroup: FormGroup;
  alveolarEsqFormGroup: FormGroup;
  reticularDirFormGroup: FormGroup;
  reticularEsqFormGroup: FormGroup;
  consolidacaoDirFormGroup: FormGroup;
  consolidacaoEsqFormGroup: FormGroup;

  localizacaoDireitaOvalado: any;
  localizacaoEsquerdaOvalado: any;
  localizacaoDireitaEspiculado: any;
  localizacaoEsquerdaEspiculado: any;
  localizacaoDireitaAlveolar: any;
  localizacaoEsquerdaAlveolar: any;
  localizacaoDireitaReticular: any;
  localizacaoEsquerdaReticular: any;
  localizacaoDireitaConsolidacao: any;
  localizacaoEsquerdaConsolidacao: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {

    this.iniciaFormOvalado();
    this.iniciaFormEspiculado();
    this.iniciaFormAlveolar();
    this.iniciaFormReticular();
    this.iniciaFormConsolidacao();

    this.localizacaoDireitaOvalado = [
      { id: 'superiorDirOvalado', value: 'superior direito', name: 'Superior Direito' },
      { id: 'medioDirOvalado', value: 'médio direito', name: 'Médio Direito' },
      { id: 'inferiorDirOvalado', value: 'inferior direito', name: 'Inferior Direito' }
    ];

    this.localizacaoEsquerdaOvalado = [
      { id: 'superiorEsqOvalado', value: 'superior esquerdo', name: 'Superior Esquerdo' },
      { id: 'medioEsqOvalado', value: 'médio esquerdo', name: 'Médio Esquerdo' },
      { id: 'inferiorEsqOvalado', value: 'inferior esquerdo', name: 'Inferior Esquerdo' }
    ];

    this.localizacaoDireitaEspiculado = [
      { id: 'superiorDirEspiculado', value: 'superior direito', name: 'Superior Direito' },
      { id: 'medioDirEspiculado', value: 'médio direito', name: 'Médio Direito' },
      { id: 'inferiorDirEspiculado', value: 'inferior direito', name: 'Inferior Direito' }
    ];

    this.localizacaoEsquerdaEspiculado = [
      { id: 'superiorEsqEspiculado', value: 'superior esquerdo', name: 'Superior Esquerdo' },
      { id: 'medioEsqEspiculado', value: 'médio esquerdo', name: 'Médio Esquerdo' },
      { id: 'inferiorEsqEspiculado', value: 'inferior esquerdo', name: 'Inferior Esquerdo' }
    ];

    this.localizacaoDireitaAlveolar = [
      { id: 'superiorDirAlveolar', value: 'superior direito', name: 'Superior Direito' },
      { id: 'medioDirAlveolar', value: 'médio direito', name: 'Médio Direito' },
      { id: 'inferiorDirAlveolar', value: 'inferior direito', name: 'Inferior Direito' }
    ];

    this.localizacaoEsquerdaAlveolar = [
      { id: 'superiorEsqAlveolar', value: 'superior esquerdo', name: 'Superior Esquerdo' },
      { id: 'medioEsqAlveolar', value: 'médio esquerdo', name: 'Médio Esquerdo' },
      { id: 'inferiorEsqAlveolar', value: 'inferior esquerdo', name: 'Inferior Esquerdo' }
    ];

    this.localizacaoDireitaReticular = [
      { id: 'superiorDirReticular', value: 'superior direito', name: 'Superior Direito' },
      { id: 'medioDirReticular', value: 'médio direito', name: 'Médio Direito' },
      { id: 'inferiorDirReticular', value: 'inferior direito', name: 'Inferior Direito' }
    ];

    this.localizacaoEsquerdaReticular = [
      { id: 'superiorEsqReticular', value: 'superior esquerdo', name: 'Superior Esquerdo' },
      { id: 'medioEsqReticular', value: 'médio esquerdo', name: 'Médio Esquerdo' },
      { id: 'inferiorEsqReticular', value: 'inferior esquerdo', name: 'Inferior Esquerdo' }
    ];

    this.localizacaoDireitaConsolidacao = [
      { id: 'superiorDirConsolidacao', value: 'superior direito', name: 'Superior Direito' },
      { id: 'medioDirConsolidacao', value: 'médio direito', name: 'Médio Direito' },
      { id: 'inferiorDirConsolidacao', value: 'inferior direito', name: 'Inferior Direito' }
    ];

    this.localizacaoEsquerdaConsolidacao = [
      { id: 'superiorEsqConsolidacao', value: 'superior esquerdo', name: 'Superior Esquerdo' },
      { id: 'medioEsqConsolidacao', value: 'médio esquerdo', name: 'Médio Esquerdo' },
      { id: 'inferiorEsqConsolidacao', value: 'inferior esquerdo', name: 'Inferior Esquerdo' }
    ];

    this.showAndHideTerms = {
      sahOpacidadePulmonar: false,
      sahOpacidadeAlveolar: false,
      sahOpacidadeReticular: false,
      sahConsolidacao: false,
      sahAtelectasiaLobar: false,
      sahAtelectasiaLaminares: false,
      sahNodulo: false,
      sahNoduloOvalado: false,
      sahNoduloEspiculado: false,
      sahPneumotorax: false,
      sahHidropneumotorax: false,
    }
  }

  checkPresente() {
    if (this.ckOpacidadePulmonar && !this.ckOpacidadePulmonar.checked &&
      this.ckConsolidacao && !this.ckConsolidacao.checked &&
      this.ckAtelectasiaLobar && !this.ckAtelectasiaLobar.checked &&
      this.ckAtelectasiaLaminares && !this.ckAtelectasiaLaminares.checked &&
      this.ckNodulo && !this.ckNodulo.checked &&
      this.ckPneumotorax && !this.ckPneumotorax.checked &&
      this.ckHidropneumotorax && !this.ckHidropneumotorax.checked &&
      this.ckHiperinsuflacao && !this.ckHiperinsuflacao.checked) {
      return true;
    }
  }

  clickDefault() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  clickOpacidadePulmonar() {
    this.showAndHideTerms.sahOpacidadePulmonar = !this.showAndHideTerms.sahOpacidadePulmonar;
    if (!this.showAndHideTerms.sahOpacidadePulmonar) {
      this.ckOpacidadeReticular.checked = false;
      this.ckOpacidadeAlveolar.checked = false;
    }
    this.catchIDs();
  }

  clickOpacidadeAlveolar() {
    this.showAndHideTerms.sahOpacidadeAlveolar = !this.showAndHideTerms.sahOpacidadeAlveolar;
    this.catchIDs();
  }

  clickOpacidadeReticular() {
    this.showAndHideTerms.sahOpacidadeReticular = !this.showAndHideTerms.sahOpacidadeReticular;
    this.catchIDs();
  }

  clickConsolidacao() {
    this.showAndHideTerms.sahConsolidacao = !this.showAndHideTerms.sahConsolidacao;
    this.catchIDs();
  }

  clickAtelectasiaLobar() {
    this.showAndHideTerms.sahAtelectasiaLobar = !this.showAndHideTerms.sahAtelectasiaLobar;
    this.catchIDs();
  }

  clickAtelectasiaLaminares() {
    this.showAndHideTerms.sahAtelectasiaLaminares = !this.showAndHideTerms.sahAtelectasiaLaminares;
    this.catchIDs();
  }

  clickNodulo() {
    this.showAndHideTerms.sahNodulo = !this.showAndHideTerms.sahNodulo;
    this.catchIDs();
  }

  clickNoduloOvalado() {
    this.showAndHideTerms.sahNoduloOvalado = !this.showAndHideTerms.sahNoduloOvalado;
    this.catchIDs();
  }

  clickNoduloEspiculado() {
    this.showAndHideTerms.sahNoduloEspiculado = !this.showAndHideTerms.sahNoduloEspiculado;
    this.catchIDs();
  }

  clickPneumotorax() {
    this.showAndHideTerms.sahPneumotorax = !this.showAndHideTerms.sahPneumotorax;
    this.catchIDs();
  }

  clickHidropneumotorax() {
    this.showAndHideTerms.sahHidropneumotorax = !this.showAndHideTerms.sahHidropneumotorax;
    this.catchIDs();
  }



  clickCheckboxOvaladoDir(event) {
    let localizacaoDireita = <FormArray>this.ovaladoDirFormGroup.get('localizacaoDireitaOvalado') as FormArray;
    let localizacaoDireitaMult = <FormArray>this.ovaladoDirFormGroup.get('localizacaoDireitaMultOvalado') as FormArray;

    if (event.checked) {
      localizacaoDireita.push(new FormControl(event.source.value));
      localizacaoDireitaMult.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iDir = localizacaoDireita.controls.findIndex(x => x.value === event.source.value);
      let iDirMult = localizacaoDireitaMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      localizacaoDireita.removeAt(iDir);
      localizacaoDireitaMult.removeAt(iDirMult);
    }

    this.catchIDs();
  }

  clickCheckboxOvaladoEsq(event) {
    let localizacaoEsquerda = <FormArray>this.ovaladoEsqFormGroup.get('localizacaoEsquerdaOvalado') as FormArray;
    let localizacaoEsquerdaMult = <FormArray>this.ovaladoEsqFormGroup.get('localizacaoEsquerdaMultOvalado') as FormArray;

    if (event.checked) {
      localizacaoEsquerda.push(new FormControl(event.source.value));
      localizacaoEsquerdaMult.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iEsq = localizacaoEsquerda.controls.findIndex(x => x.value === event.source.value);
      let iEsqMult = localizacaoEsquerdaMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      localizacaoEsquerda.removeAt(iEsq);
      localizacaoEsquerdaMult.removeAt(iEsqMult);
    }

    this.catchIDs();
  }

  clickCheckboxEspiculadoDir(event) {
    let localizacaoDireita = <FormArray>this.espiculadoDirFormGroup.get('localizacaoDireitaEspiculado') as FormArray;
    let localizacaoDireitaMult = <FormArray>this.espiculadoDirFormGroup.get('localizacaoDireitaMultEspiculado') as FormArray;

    if (event.checked) {
      localizacaoDireita.push(new FormControl(event.source.value));
      localizacaoDireitaMult.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iDir = localizacaoDireita.controls.findIndex(x => x.value === event.source.value);
      let iDirMult = localizacaoDireitaMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      localizacaoDireita.removeAt(iDir);
      localizacaoDireitaMult.removeAt(iDirMult);
    }

    this.catchIDs();
  }

  clickCheckboxEspiculadoEsq(event) {
    let localizacaoEsquerda = <FormArray>this.espiculadoEsqFormGroup.get('localizacaoEsquerdaEspiculado') as FormArray;
    let localizacaoEsquerdaMult = <FormArray>this.espiculadoEsqFormGroup.get('localizacaoEsquerdaMultEspiculado') as FormArray;

    if (event.checked) {
      localizacaoEsquerda.push(new FormControl(event.source.value));
      localizacaoEsquerdaMult.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iEsq = localizacaoEsquerda.controls.findIndex(x => x.value === event.source.value);
      let iEsqMult = localizacaoEsquerdaMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      localizacaoEsquerda.removeAt(iEsq);
      localizacaoEsquerdaMult.removeAt(iEsqMult);
    }

    this.catchIDs();
  }

  clickCheckboxAlveolarDir(event) {
    let localizacaoDireita = <FormArray>this.alveolarDirFormGroup.get('localizacaoDireitaAlveolar') as FormArray;
    let localizacaoDireitaMult = <FormArray>this.alveolarDirFormGroup.get('localizacaoDireitaMultAlveolar') as FormArray;

    if (event.checked) {
      localizacaoDireita.push(new FormControl(event.source.value));
      localizacaoDireitaMult.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iDir = localizacaoDireita.controls.findIndex(x => x.value === event.source.value);
      let iDirMult = localizacaoDireitaMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      localizacaoDireita.removeAt(iDir);
      localizacaoDireitaMult.removeAt(iDirMult);
    }

    this.catchIDs();
  }

  clickCheckboxAlveolarEsq(event) {
    let localizacaoEsquerda = <FormArray>this.alveolarEsqFormGroup.get('localizacaoEsquerdaAlveolar') as FormArray;
    let localizacaoEsquerdaMult = <FormArray>this.alveolarEsqFormGroup.get('localizacaoEsquerdaMultAlveolar') as FormArray;

    if (event.checked) {
      localizacaoEsquerda.push(new FormControl(event.source.value));
      localizacaoEsquerdaMult.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iEsq = localizacaoEsquerda.controls.findIndex(x => x.value === event.source.value);
      let iEsqMult = localizacaoEsquerdaMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      localizacaoEsquerda.removeAt(iEsq);
      localizacaoEsquerdaMult.removeAt(iEsqMult);
    }

    this.catchIDs();
  }

  clickCheckboxReticularDir(event) {
    let localizacaoDireita = <FormArray>this.reticularDirFormGroup.get('localizacaoDireitaReticular') as FormArray;
    let localizacaoDireitaMult = <FormArray>this.reticularDirFormGroup.get('localizacaoDireitaMultReticular') as FormArray;

    if (event.checked) {
      localizacaoDireita.push(new FormControl(event.source.value));
      localizacaoDireitaMult.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iDir = localizacaoDireita.controls.findIndex(x => x.value === event.source.value);
      let iDirMult = localizacaoDireitaMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      localizacaoDireita.removeAt(iDir);
      localizacaoDireitaMult.removeAt(iDirMult);
    }

    this.catchIDs();
  }

  clickCheckboxReticularEsq(event) {
    let localizacaoEsquerda = <FormArray>this.reticularEsqFormGroup.get('localizacaoEsquerdaReticular') as FormArray;
    let localizacaoEsquerdaMult = <FormArray>this.reticularEsqFormGroup.get('localizacaoEsquerdaMultReticular') as FormArray;

    if (event.checked) {
      localizacaoEsquerda.push(new FormControl(event.source.value));
      localizacaoEsquerdaMult.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iEsq = localizacaoEsquerda.controls.findIndex(x => x.value === event.source.value);
      let iEsqMult = localizacaoEsquerdaMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      localizacaoEsquerda.removeAt(iEsq);
      localizacaoEsquerdaMult.removeAt(iEsqMult);
    }

    this.catchIDs();
  }

  clickCheckboxConsolidacaoDir(event) {
    let localizacaoDireita = <FormArray>this.consolidacaoDirFormGroup.get('localizacaoDireitaConsolidacao') as FormArray;
    let localizacaoDireitaMult = <FormArray>this.consolidacaoDirFormGroup.get('localizacaoDireitaMultConsolidacao') as FormArray;

    if (event.checked) {
      localizacaoDireita.push(new FormControl(event.source.value));
      localizacaoDireitaMult.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iDir = localizacaoDireita.controls.findIndex(x => x.value === event.source.value);
      let iDirMult = localizacaoDireitaMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      localizacaoDireita.removeAt(iDir);
      localizacaoDireitaMult.removeAt(iDirMult);
    }

    this.catchIDs();
  }

  clickCheckboxConsolidacaoEsq(event) {
    let localizacaoEsquerda = <FormArray>this.consolidacaoEsqFormGroup.get('localizacaoEsquerdaConsolidacao') as FormArray;
    let localizacaoEsquerdaMult = <FormArray>this.consolidacaoEsqFormGroup.get('localizacaoEsquerdaMultConsolidacao') as FormArray;

    if (event.checked) {
      localizacaoEsquerda.push(new FormControl(event.source.value));
      localizacaoEsquerdaMult.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iEsq = localizacaoEsquerda.controls.findIndex(x => x.value === event.source.value);
      let iEsqMult = localizacaoEsquerdaMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      localizacaoEsquerda.removeAt(iEsq);
      localizacaoEsquerdaMult.removeAt(iEsqMult);
    }

    this.catchIDs();
  }

  iniciaFormOvalado() {
    this.ovaladoDirFormGroup = this.fb.group({
      localizacaoDireitaOvalado: this.fb.array([]),
      localizacaoDireitaMultOvalado: this.fb.array([])
    });

    this.ovaladoEsqFormGroup = this.fb.group({
      localizacaoEsquerdaOvalado: this.fb.array([]),
      localizacaoEsquerdaMultOvalado: this.fb.array([])
    });
  }

  iniciaFormEspiculado() {
    this.espiculadoDirFormGroup = this.fb.group({
      localizacaoDireitaEspiculado: this.fb.array([]),
      localizacaoDireitaMultEspiculado: this.fb.array([])
    });

    this.espiculadoEsqFormGroup = this.fb.group({
      localizacaoEsquerdaEspiculado: this.fb.array([]),
      localizacaoEsquerdaMultEspiculado: this.fb.array([])
    });
  }

  iniciaFormAlveolar() {
    this.alveolarDirFormGroup = this.fb.group({
      localizacaoDireitaAlveolar: this.fb.array([]),
      localizacaoDireitaMultAlveolar: this.fb.array([])
    });

    this.alveolarEsqFormGroup = this.fb.group({
      localizacaoEsquerdaAlveolar: this.fb.array([]),
      localizacaoEsquerdaMultAlveolar: this.fb.array([])
    });
  }

  iniciaFormReticular() {
    this.reticularDirFormGroup = this.fb.group({
      localizacaoDireitaReticular: this.fb.array([]),
      localizacaoDireitaMultReticular: this.fb.array([])
    });

    this.reticularEsqFormGroup = this.fb.group({
      localizacaoEsquerdaReticular: this.fb.array([]),
      localizacaoEsquerdaMultReticular: this.fb.array([])
    });
  }

  iniciaFormConsolidacao() {
    this.consolidacaoDirFormGroup = this.fb.group({
      localizacaoDireitaConsolidacao: this.fb.array([]),
      localizacaoDireitaMultConsolidacao: this.fb.array([])
    });

    this.consolidacaoEsqFormGroup = this.fb.group({
      localizacaoEsquerdaConsolidacao: this.fb.array([]),
      localizacaoEsquerdaMultConsolidacao: this.fb.array([])
    });
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');


    if (this.ckNoduloOvalado && !this.ckNoduloOvalado.checked) {
      this.iniciaFormOvalado();
    }
    if (this.ckNoduloEspiculado && !this.ckNoduloEspiculado.checked) {
      this.iniciaFormEspiculado();
    }
    if (this.ckOpacidadeAlveolar && !this.ckOpacidadeAlveolar.checked) {
      this.iniciaFormAlveolar();
    }
    if (this.ckOpacidadeReticular && !this.ckOpacidadeReticular.checked) {
      this.iniciaFormReticular();
    }
    if (this.ckConsolidacao && !this.ckConsolidacao.checked) {
      this.iniciaFormConsolidacao();
    }

    this.emitOpacidadePulmonar();

    //Consolidacao
    if (this.countLesoes > 0) {
      this.emitConsolidacaoLesao();
    } else {
      this.emitConsolidacaoSimples();
    }

    //Alectasia Lobar
    if (this.countLesoes > 0) {
      this.emitAlectasiaLobarLesao();
    } else {
      this.emitAlectasiaLobarSimples();
    }

    //Alectasia Laminar
    if (this.countLesoes > 0) {
      this.emitAlectasiaLaminarLesao();
    } else {
      this.emitAlectasiaLaminarSimples();
    }

    //Nodulo
    if (this.countLesoes > 0) {
      this.emitNoduloLesao();
    } else {
      this.emitNoduloSimples();
    }

    //Pneumotorax
    if (this.countLesoes > 0) {
      this.emitPneumotoraxLesao();
    } else {
      this.emitPneumotoraxSimples();
    }

    //Hidropneumotorax
    if (this.countLesoes > 0) {
      this.emitHidropneumotoraxLesao();
    } else {
      this.emitHidropneumotoraxSimples();
    }

    //hiperinsuflacao
    if (this.countLesoes > 0) {
      this.emitHiperinsuflacaoLesao();
    } else {
      this.emitHiperinsuflacaoSimples();
    }

    if (this.checkPresente()) {
      this.termoSelecionado.emit(4906);
    }

    this.listaCompleta.emit();
  }

  emitOpacidadePulmonar() {
    if (this.ckOpacidadePulmonar.checked) {
      

      if (this.ckOpacidadeAlveolar && this.ckOpacidadeAlveolar.checked) {
        if (this.countLesoes > 0) {
          this.countLesoes++;

          const objeto = { id: this.ckOpacidadePulmonar.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);

          const objeto2 = { id: this.ckOpacidadeAlveolar.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto2);

          let alveolarDir = this.alveolarDirFormGroup.value.localizacaoDireitaAlveolar;
          let alveolarDirMult = this.alveolarDirFormGroup.value.localizacaoDireitaMultAlveolar;
          let alveolarEsq = this.alveolarEsqFormGroup.value.localizacaoEsquerdaAlveolar;
          let alveolarEsqMult = this.alveolarEsqFormGroup.value.localizacaoEsquerdaMultAlveolar;

          if ((alveolarDir.length >= 1 && alveolarEsq.length >= 1) || alveolarEsq.length > 1 || alveolarDir.length > 1) {
            if (alveolarDir && alveolarDir.length == 1) {
              let objeto = { id: 4946, value: alveolarDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);

            } else if (alveolarDir && alveolarDir.length > 1) {
              let objeto = { id: 4946, value: alveolarDirMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
              if (alveolarEsq.length == 0) {
                //termo multiplo direito
                const objeto = { id: 4948, cenario: this.countLesoes };
                this.lesoesSelecionadas.emit(objeto);
              }
            }

            if (alveolarEsq && alveolarEsq.length == 1) {
              let objeto = { id: 4947, value: alveolarEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
            } else if (alveolarEsq && alveolarEsq.length > 1) {
              let objeto = { id: 4947, value: alveolarEsqMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
              //termo multiplo esquerdo
              if (alveolarDir.length == 0) {
                //termo multiplo esquerdo
                const objeto = { id: 4948, cenario: this.countLesoes };
                this.lesoesSelecionadas.emit(objeto);
              }
            }
          } else {
            if (alveolarDir && alveolarDir.length == 1) {
              let objeto = { id: 4946, value: alveolarDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
            }

            if (alveolarEsq && alveolarEsq.length == 1) {
              let objeto = { id: 4947, value: alveolarEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
            }
          }
        } else {
          this.countLesoes++;
          this.termoSelecionado.emit(this.ckOpacidadePulmonar.id);
          this.termoSelecionado.emit(this.ckOpacidadeAlveolar.id);

          let alveolarDir = this.alveolarDirFormGroup.value.localizacaoDireitaAlveolar;
          let alveolarDirMult = this.alveolarDirFormGroup.value.localizacaoDireitaMultAlveolar;
          let alveolarEsq = this.alveolarEsqFormGroup.value.localizacaoEsquerdaAlveolar;
          let alveolarEsqMult = this.alveolarEsqFormGroup.value.localizacaoEsquerdaMultAlveolar;

          if ((alveolarDir.length >= 1 && alveolarDirMult.length >= 1) || (alveolarEsq.length > 1 && alveolarEsqMult.length > 1)) {
            if (alveolarDir && alveolarDir.length == 1) {
              let objeto = { id: 4946, value: alveolarDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);

            } else if (alveolarDir && alveolarDir.length > 1) {
              let objeto = { id: 4946, value: alveolarDirMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
              if (alveolarEsq.length == 0) {
                //termo multiplo direito
                this.termoSelecionado.emit(4948);
              }
            }

            if (alveolarEsq && alveolarEsq.length == 1) {
              let objeto = { id: 4947, value: alveolarEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
            } else if (alveolarEsq && alveolarEsq.length > 1) {
              let objeto = { id: 4947, value: alveolarEsqMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
              //termo multiplo esquerdo
              if (alveolarDir.length == 0) {
                //termo multiplo esquerdo
                this.termoSelecionado.emit(4948);
              }
            }
          } else {
            if (alveolarDir && alveolarDir.length == 1) {
              let objeto = { id: 4946, value: alveolarDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
            }

            if (alveolarEsq && alveolarEsq.length == 1) {
              let objeto = { id: 4947, value: alveolarEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
            }
          }
        }
      }

      if (this.ckOpacidadeReticular && this.ckOpacidadeReticular.checked) {
        if (this.countLesoes > 0) {
          this.countLesoes++;

          const objeto = { id: this.ckOpacidadePulmonar.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);

          const objeto2 = { id: this.ckOpacidadeReticular.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto2);

          let reticularDir = this.reticularDirFormGroup.value.localizacaoDireitaReticular;
          let reticularDirMult = this.reticularDirFormGroup.value.localizacaoDireitaMultReticular;
          let reticularEsq = this.reticularEsqFormGroup.value.localizacaoEsquerdaReticular;
          let reticularEsqMult = this.reticularEsqFormGroup.value.localizacaoEsquerdaMultReticular;

          if ((reticularDir.length >= 1 && reticularEsq.length >= 1) || reticularEsq.length > 1 || reticularDir.length > 1) {
            if (reticularDir && reticularDir.length == 1) {
              let objeto = { id: 4980, value: reticularDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
            } else if (reticularDir && reticularDir.length > 1) {
              let objeto = { id: 4980, value: reticularDirMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
              if (reticularEsq.length == 0) {
                //termo multiplo direito
                const objeto = { id: 4948, cenario: this.countLesoes };
                this.lesoesSelecionadas.emit(objeto);
              }
            }

            if (reticularEsq && reticularEsq.length == 1) {
              let objeto = { id: 4981, value: reticularEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
            } else if (reticularEsq && reticularEsq.length > 1) {
              let objeto = { id: 4981, value: reticularEsqMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
              //termo multiplo esquerdo
              if (reticularDir.length == 0) {
                //termo multiplo esquerdo
                const objeto = { id: 4948, cenario: this.countLesoes };
                this.lesoesSelecionadas.emit(objeto);
              }
            }
          } else {
            if (reticularDir && reticularDir.length == 1) {
              let objeto = { id: 4980, value: reticularDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
            }

            if (reticularEsq && reticularEsq.length == 1) {
              let objeto = { id: 4981, value: reticularEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
            }
          }
        } else {
          this.termoSelecionado.emit(this.ckOpacidadeReticular.id);

          let reticularDir = this.reticularDirFormGroup.value.localizacaoDireitaReticular;
          let reticularDirMult = this.reticularDirFormGroup.value.localizacaoDireitaMultReticular;
          let reticularEsq = this.reticularEsqFormGroup.value.localizacaoEsquerdaReticular;
          let reticularEsqMult = this.reticularEsqFormGroup.value.localizacaoEsquerdaMultReticular;

          if ((reticularDir.length >= 1 && reticularDirMult.length >= 1) || (reticularEsq.length > 1 && reticularEsqMult.length > 1)) {
            if (reticularDir && reticularDir.length == 1) {
              let objeto = { id: 4980, value: reticularDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
            } else if (reticularDir && reticularDir.length > 1) {
              let objeto = { id: 4980, value: reticularDirMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
              if (reticularEsq.length == 0) {
                //termo multiplo direito
                this.termoSelecionado.emit(4948);
              }
            }

            if (reticularEsq && reticularEsq.length == 1) {
              let objeto = { id: 4981, value: reticularEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
            } else if (reticularEsq && reticularEsq.length > 1) {
              let objeto = { id: 4981, value: reticularEsqMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
              //termo multiplo esquerdo
              if (reticularDir.length == 0) {
                //termo multiplo esquerdo
                this.termoSelecionado.emit(4948);
              }
            }
          } else {
            if (reticularDir && reticularDir.length == 1) {
              let objeto = { id: 4980, value: reticularDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
            }

            if (reticularEsq && reticularEsq.length == 1) {
              let objeto = { id: 4981, value: reticularEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
            }
          }
        }
      }
    }
  }

  emitConsolidacaoLesao() {
    if (this.ckConsolidacao.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckConsolidacao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let consolidacaoDir = this.consolidacaoDirFormGroup.value.localizacaoDireitaConsolidacao;
      let consolidacaoDirMult = this.consolidacaoDirFormGroup.value.localizacaoDireitaMultConsolidacao;
      let consolidacaoEsq = this.consolidacaoEsqFormGroup.value.localizacaoEsquerdaConsolidacao;
      let consolidacaoEsqMult = this.consolidacaoEsqFormGroup.value.localizacaoEsquerdaMultConsolidacao;

      if ((consolidacaoDir.length >= 1 && consolidacaoEsq.length >= 1) || consolidacaoEsq.length > 1 || consolidacaoDir.length > 1) {
        if (consolidacaoDir && consolidacaoDir.length == 1) {
          let objeto = { id: 4950, value: consolidacaoDir };
          let elemento = { elemento: objeto, isPrimeiraLesao: false };
          this.termosGenericos.emit(elemento);
        } else if (consolidacaoDir && consolidacaoDir.length > 1) {
          let objeto = { id: 4950, value: consolidacaoDirMult };
          let elemento = { elemento: objeto, isPrimeiraLesao: false };
          this.termosGenericos.emit(elemento);
          if (consolidacaoEsq.length == 0) {
            //termo multiplo direito
            const objeto = { id: 4948, cenario: this.countLesoes };
            this.lesoesSelecionadas.emit(objeto);
          }
        }

        if (consolidacaoEsq && consolidacaoEsq.length == 1) {
          let objeto = { id: 4951, value: consolidacaoEsq };
          let elemento = { elemento: objeto, isPrimeiraLesao: false };
          this.termosGenericos.emit(elemento);
        } else if (consolidacaoEsq && consolidacaoEsq.length > 1) {
          let objeto = { id: 4951, value: consolidacaoEsqMult };
          let elemento = { elemento: objeto, isPrimeiraLesao: false };
          this.termosGenericos.emit(elemento);
          //termo multiplo esquerdo
          if (consolidacaoDir.length == 0) {
            //termo multiplo esquerdo
            const objeto = { id: 4948, cenario: this.countLesoes };
            this.lesoesSelecionadas.emit(objeto);
          }
        }
      } else {
        if (consolidacaoDir && consolidacaoDir.length == 1) {
          let objeto = { id: 4950, value: consolidacaoDir };
          let elemento = { elemento: objeto, isPrimeiraLesao: false };
          this.termosGenericos.emit(elemento);
        }

        if (consolidacaoEsq && consolidacaoEsq.length == 1) {
          let objeto = { id: 4951, value: consolidacaoEsq };
          let elemento = { elemento: objeto, isPrimeiraLesao: false };
          this.termosGenericos.emit(elemento);
        }
      }
      if (this.ckComBroncograma && this.ckComBroncograma.checked) {
        const objeto = { id: this.ckComBroncograma.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  emitConsolidacaoSimples() {
    if (this.ckConsolidacao.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckConsolidacao.id);

      let consolidacaoDir = this.consolidacaoDirFormGroup.value.localizacaoDireitaConsolidacao;
      let consolidacaoDirMult = this.consolidacaoDirFormGroup.value.localizacaoDireitaMultConsolidacao;
      let consolidacaoEsq = this.consolidacaoEsqFormGroup.value.localizacaoEsquerdaConsolidacao;
      let consolidacaoEsqMult = this.consolidacaoEsqFormGroup.value.localizacaoEsquerdaMultConsolidacao;

      if ((consolidacaoDir.length >= 1 && consolidacaoEsq.length >= 1) || consolidacaoEsq.length > 1 || consolidacaoDir.length > 1) {
        if (consolidacaoDir && consolidacaoDir.length == 1) {
          let objeto = { id: 4950, value: consolidacaoDir };
          let elemento = { elemento: objeto, isPrimeiraLesao: true };
          this.termosGenericos.emit(elemento);
        } else if (consolidacaoDir && consolidacaoDir.length > 1) {
          let objeto = { id: 4950, value: consolidacaoDirMult };
          let elemento = { elemento: objeto, isPrimeiraLesao: true };
          this.termosGenericos.emit(elemento);
          if (consolidacaoEsq.length == 0) {
            //termo multiplo direito
            this.termoSelecionado.emit(4948);
          }
        }

        if (consolidacaoEsq && consolidacaoEsq.length == 1) {
          let objeto = { id: 4951, value: consolidacaoEsq };
          let elemento = { elemento: objeto, isPrimeiraLesao: true };
          this.termosGenericos.emit(elemento);
        } else if (consolidacaoEsq && consolidacaoEsq.length > 1) {
          let objeto = { id: 4951, value: consolidacaoEsqMult };
          let elemento = { elemento: objeto, isPrimeiraLesao: true };
          this.termosGenericos.emit(elemento);
          //termo multiplo esquerdo
          if (consolidacaoDir.length == 0) {
            //termo multiplo esquerdo
            this.termoSelecionado.emit(4948);
          }
        }
      } else {
        if (consolidacaoDir && consolidacaoDir.length == 1) {
          let objeto = { id: 4950, value: consolidacaoDir };
          let elemento = { elemento: objeto, isPrimeiraLesao: true };
          this.termosGenericos.emit(elemento);
        }

        if (consolidacaoEsq && consolidacaoEsq.length == 1) {
          let objeto = { id: 4951, value: consolidacaoEsq };
          let elemento = { elemento: objeto, isPrimeiraLesao: true };
          this.termosGenericos.emit(elemento);
        }
      }
      if (this.ckComBroncograma && this.ckComBroncograma.checked) {
        this.termoSelecionado.emit(this.ckComBroncograma.id);
      }
    }
  }

  emitAlectasiaLobarLesao() {
    if (this.ckAtelectasiaLobar.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckAtelectasiaLobar.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLoboAtelectasiaLobar && this.slLoboAtelectasiaLobar.value) {
        if (this.slLoboAtelectasiaLobar.value == 'língula') {
          const objeto = { id: 4978, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          const objeto = { elemento: this.slLoboAtelectasiaLobar, isPrimeiraLesao: false }
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitAlectasiaLobarSimples() {
    if (this.ckAtelectasiaLobar.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAtelectasiaLobar.id);

      if (this.slLoboAtelectasiaLobar && this.slLoboAtelectasiaLobar.value) {
        if (this.slLoboAtelectasiaLobar.value == 'língula') {
          this.termoSelecionado.emit(4978);
        } else {
          const objeto = { elemento: this.slLoboAtelectasiaLobar, isPrimeiraLesao: true }
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitAlectasiaLaminarLesao() {
    if (this.ckAtelectasiaLaminares.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckAtelectasiaLaminares.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacaoAtelectasiaLaminares && this.slLocalizacaoAtelectasiaLaminares.value) {
        if (this.slLocalizacaoAtelectasiaLaminares.value == 'bilateral') {
          const objeto = { id: 4979, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          const objeto = { elemento: this.slLocalizacaoAtelectasiaLaminares, isPrimeiraLesao: false }
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitAlectasiaLaminarSimples() {
    if (this.ckAtelectasiaLaminares.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAtelectasiaLaminares.id);

      if (this.slLocalizacaoAtelectasiaLaminares && this.slLocalizacaoAtelectasiaLaminares.value) {
        if (this.slLocalizacaoAtelectasiaLaminares.value == 'bilateral') {
          this.termoSelecionado.emit(4979);
        } else {
          const objeto = { elemento: this.slLocalizacaoAtelectasiaLaminares, isPrimeiraLesao: true }
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitNoduloLesao() {
    if (this.ckNodulo && this.ckNodulo.checked) {
      if (this.ckNoduloOvalado && this.ckNoduloOvalado.checked) {
        this.countLesoes++;
        const objeto = { id: this.ckNodulo.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        const objeto2 = { id: this.ckNoduloOvalado.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto2);

        let noduloDir = this.ovaladoDirFormGroup.value.localizacaoDireitaOvalado;
        let noduloDirMult = this.ovaladoDirFormGroup.value.localizacaoDireitaMultOvalado;
        let noduloEsq = this.ovaladoEsqFormGroup.value.localizacaoEsquerdaOvalado;
        let noduloEsqMult = this.ovaladoEsqFormGroup.value.localizacaoEsquerdaMultOvalado;

        if ((noduloDir.length >= 1 && noduloEsq.length >= 1) || noduloDir.length > 1 || noduloEsq.length > 1) {
          if (noduloDir && noduloDir.length == 1) {
            let objeto = { id: 4960, value: noduloDir };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);

          } else if (noduloDir && noduloDir.length > 1) {
            let objeto = { id: 4960, value: noduloDirMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
            if (noduloEsq.length == 0) {
              //termo multiplo direito
              const objeto = { id: 4948, cenario: this.countLesoes };
              this.lesoesSelecionadas.emit(objeto);
            }
          }

          if (noduloEsq && noduloEsq.length == 1) {
            let objeto = { id: 4961, value: noduloEsq };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          } else if (noduloEsq && noduloEsq.length > 1) {
            let objeto = { id: 4961, value: noduloEsqMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
            if (noduloDir.length == 0) {
              //termo multiplo esquerdo
              const objeto = { id: 4948, cenario: this.countLesoes };
              this.lesoesSelecionadas.emit(objeto);
            }
          }
        } else {
          if (noduloDir && noduloDir.length == 1) {
            let objeto = { id: 4960, value: noduloDir };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }

          if (noduloEsq && noduloEsq.length == 1) {
            let objeto = { id: 4961, value: noduloEsq };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
        }
        if (this.inMedidaNoduloOvalado && this.inMedidaNoduloOvalado.nativeElement.value) {
          let elemento = { elemento: this.inMedidaNoduloOvalado.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(elemento);
        }
      }

      if (this.ckNoduloEspiculado && this.ckNoduloEspiculado.checked) {
        this.countLesoes++;
        const objeto = { id: this.ckNodulo.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        const objeto2 = { id: this.ckNoduloEspiculado.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto2);

        let noduloDir = this.espiculadoDirFormGroup.value.localizacaoDireitaEspiculado;
        let noduloDirMult = this.espiculadoDirFormGroup.value.localizacaoDireitaMultEspiculado;
        let noduloEsq = this.espiculadoEsqFormGroup.value.localizacaoEsquerdaEspiculado;
        let noduloEsqMult = this.espiculadoEsqFormGroup.value.localizacaoEsquerdaMultEspiculado;

        if ((noduloDir.length >= 1 && noduloEsq.length >= 1) || noduloDir.length > 1 || noduloEsq.length > 1) {
          if (noduloDir && noduloDir.length == 1) {
            let objeto = { id: 4963, value: noduloDir };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);

          } else if (noduloDir && noduloDir.length > 1) {
            let objeto = { id: 4963, value: noduloDirMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
            if (noduloEsq.length == 0) {
              //termo multiplo direito
              const objeto = { id: 4948, cenario: this.countLesoes };
              this.lesoesSelecionadas.emit(objeto);
            }
          }

          if (noduloEsq && noduloEsq.length == 1) {
            let objeto = { id: 4964, value: noduloEsq };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          } else if (noduloEsq && noduloEsq.length > 1) {
            let objeto = { id: 4964, value: noduloEsqMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
            if (noduloDir.length == 0) {
              //termo multiplo esquerdo
              const objeto = { id: 4948, cenario: this.countLesoes };
              this.lesoesSelecionadas.emit(objeto);
            }
          }
        } else {
          if (noduloDir && noduloDir.length == 1) {
            let objeto = { id: 4963, value: noduloDir };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }

          if (noduloEsq && noduloEsq.length == 1) {
            let objeto = { id: 4964, value: noduloEsq };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
        }
        if (this.inMedidaNoduloEspiculado && this.inMedidaNoduloEspiculado.nativeElement.value) {
          let elemento = { elemento: this.inMedidaNoduloEspiculado.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(elemento);
        }
      }
    }
  }

  emitNoduloSimples() {
    if (this.ckNodulo && this.ckNodulo.checked) {
      if (this.ckNoduloOvalado && this.ckNoduloOvalado.checked) {
        if(this.countLesoes > 0){
          this.countLesoes++;
          const objeto = { id: this.ckNodulo.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
  
          const objeto2 = { id: this.ckNoduloOvalado.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto2);
  
          let noduloDir = this.ovaladoDirFormGroup.value.localizacaoDireitaOvalado;
          let noduloDirMult = this.ovaladoDirFormGroup.value.localizacaoDireitaMultOvalado;
          let noduloEsq = this.ovaladoEsqFormGroup.value.localizacaoEsquerdaOvalado;
          let noduloEsqMult = this.ovaladoEsqFormGroup.value.localizacaoEsquerdaMultOvalado;
  
          if ((noduloDir.length >= 1 && noduloEsq.length >= 1) || noduloDir.length > 1 || noduloEsq.length > 1) {
            if (noduloDir && noduloDir.length == 1) {
              let objeto = { id: 4960, value: noduloDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
  
            } else if (noduloDir && noduloDir.length > 1) {
              let objeto = { id: 4960, value: noduloDirMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
              if (noduloEsq.length == 0) {
                //termo multiplo direito
                const objeto = { id: 4948, cenario: this.countLesoes };
                this.lesoesSelecionadas.emit(objeto);
              }
            }
  
            if (noduloEsq && noduloEsq.length == 1) {
              let objeto = { id: 4961, value: noduloEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
            } else if (noduloEsq && noduloEsq.length > 1) {
              let objeto = { id: 4961, value: noduloEsqMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
              if (noduloDir.length == 0) {
                //termo multiplo esquerdo
                const objeto = { id: 4948, cenario: this.countLesoes };
                this.lesoesSelecionadas.emit(objeto);
              }
            }
          } else {
            if (noduloDir && noduloDir.length == 1) {
              let objeto = { id: 4960, value: noduloDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
            }
  
            if (noduloEsq && noduloEsq.length == 1) {
              let objeto = { id: 4961, value: noduloEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
            }
          }
          if (this.inMedidaNoduloOvalado && this.inMedidaNoduloOvalado.nativeElement.value) {
            let elemento = { elemento: this.inMedidaNoduloOvalado.nativeElement, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
        } else{
          this.countLesoes++;
          this.termoSelecionado.emit(this.ckNodulo.id);
          this.termoSelecionado.emit(this.ckNoduloOvalado.id);
  
          let noduloDir = this.ovaladoDirFormGroup.value.localizacaoDireitaOvalado;
          let noduloDirMult = this.ovaladoDirFormGroup.value.localizacaoDireitaMultOvalado;
          let noduloEsq = this.ovaladoEsqFormGroup.value.localizacaoEsquerdaOvalado;
          let noduloEsqMult = this.ovaladoEsqFormGroup.value.localizacaoEsquerdaMultOvalado;
  
          if ((noduloDir.length >= 1 && noduloEsq.length >= 1) || noduloDir.length > 1 || noduloEsq.length > 1) {
            if (noduloDir && noduloDir.length == 1) {
              let objeto = { id: 4960, value: noduloDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
  
            } else if (noduloDir && noduloDir.length > 1) {
              let objeto = { id: 4960, value: noduloDirMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
              if (noduloEsq.length == 0) {
                //termo multiplo direito
                this.termoSelecionado.emit(4948);
              }
            }
  
            if (noduloEsq && noduloEsq.length == 1) {
              let objeto = { id: 4961, value: noduloEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
            } else if (noduloEsq && noduloEsq.length > 1) {
              let objeto = { id: 4961, value: noduloEsqMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
              if (noduloDir.length == 0) {
                //termo multiplo esquerdo
                this.termoSelecionado.emit(4948);
              }
            }
          } else {
            if (noduloDir && noduloDir.length == 1) {
              let objeto = { id: 4960, value: noduloDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
            }
  
            if (noduloEsq && noduloEsq.length == 1) {
              let objeto = { id: 4961, value: noduloEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
            }
          }
          if (this.inMedidaNoduloOvalado && this.inMedidaNoduloOvalado.nativeElement.value) {
            let elemento = { elemento: this.inMedidaNoduloOvalado.nativeElement, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }
        }
        
      }

      if (this.ckNoduloEspiculado && this.ckNoduloEspiculado.checked) {
        if(this.countLesoes > 0){
          this.countLesoes++;
          const objeto = { id: this.ckNodulo.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
  
          const objeto2 = { id: this.ckNoduloEspiculado.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto2);
  
          let noduloDir = this.espiculadoDirFormGroup.value.localizacaoDireitaEspiculado;
          let noduloDirMult = this.espiculadoDirFormGroup.value.localizacaoDireitaMultEspiculado;
          let noduloEsq = this.espiculadoEsqFormGroup.value.localizacaoEsquerdaEspiculado;
          let noduloEsqMult = this.espiculadoEsqFormGroup.value.localizacaoEsquerdaMultEspiculado;
  
          if ((noduloDir.length >= 1 && noduloEsq.length >= 1) || noduloDir.length > 1 || noduloEsq.length > 1) {
            if (noduloDir && noduloDir.length == 1) {
              let objeto = { id: 4963, value: noduloDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
  
            } else if (noduloDir && noduloDir.length > 1) {
              let objeto = { id: 4963, value: noduloDirMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
              if (noduloEsq.length == 0) {
                //termo multiplo direito
                const objeto = { id: 4948, cenario: this.countLesoes };
                this.lesoesSelecionadas.emit(objeto);
              }
            }
  
            if (noduloEsq && noduloEsq.length == 1) {
              let objeto = { id: 4964, value: noduloEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
            } else if (noduloEsq && noduloEsq.length > 1) {
              let objeto = { id: 4964, value: noduloEsqMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
              if (noduloDir.length == 0) {
                //termo multiplo esquerdo
                const objeto = { id: 4948, cenario: this.countLesoes };
                this.lesoesSelecionadas.emit(objeto);
              }
            }
          } else {
            if (noduloDir && noduloDir.length == 1) {
              let objeto = { id: 4963, value: noduloDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
            }
  
            if (noduloEsq && noduloEsq.length == 1) {
              let objeto = { id: 4964, value: noduloEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: false };
              this.termosGenericos.emit(elemento);
            }
          }
          if (this.inMedidaNoduloEspiculado && this.inMedidaNoduloEspiculado.nativeElement.value) {
            let elemento = { elemento: this.inMedidaNoduloEspiculado.nativeElement, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
        } else{
          this.countLesoes++;
          this.termoSelecionado.emit(this.ckNodulo.id);
          this.termoSelecionado.emit(this.ckNoduloEspiculado.id);
  
          let noduloDir = this.espiculadoDirFormGroup.value.localizacaoDireitaEspiculado;
          let noduloDirMult = this.espiculadoDirFormGroup.value.localizacaoDireitaMultEspiculado;
          let noduloEsq = this.espiculadoEsqFormGroup.value.localizacaoEsquerdaEspiculado;
          let noduloEsqMult = this.espiculadoEsqFormGroup.value.localizacaoEsquerdaMultEspiculado;
  
          if ((noduloDir.length >= 1 && noduloEsq.length >= 1) || noduloDir.length > 1 || noduloEsq.length > 1) {
            if (noduloDir && noduloDir.length == 1) {
              let objeto = { id: 4963, value: noduloDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
  
            } else if (noduloDir && noduloDir.length > 1) {
              let objeto = { id: 4963, value: noduloDirMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
              if (noduloEsq.length == 0) {
                //termo multiplo direito
                this.termoSelecionado.emit(4948);
              }
            }
  
            if (noduloEsq && noduloEsq.length == 1) {
              let objeto = { id: 4964, value: noduloEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
            } else if (noduloEsq && noduloEsq.length > 1) {
              let objeto = { id: 4964, value: noduloEsqMult };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
              if (noduloDir.length == 0) {
                //termo multiplo esquerdo
                this.termoSelecionado.emit(4948);
              }
            }
          } else {
            if (noduloDir && noduloDir.length == 1) {
              let objeto = { id: 4963, value: noduloDir };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
            }
  
            if (noduloEsq && noduloEsq.length == 1) {
              let objeto = { id: 4964, value: noduloEsq };
              let elemento = { elemento: objeto, isPrimeiraLesao: true };
              this.termosGenericos.emit(elemento);
            }
          }
          if (this.inMedidaNoduloEspiculado && this.inMedidaNoduloEspiculado.nativeElement.value) {
            let elemento = { elemento: this.inMedidaNoduloEspiculado.nativeElement, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }
        }
        

      }
    }
  }

  emitPneumotoraxLesao() {
    if (this.ckPneumotorax && this.ckPneumotorax.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckPneumotorax.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacaoPneumotorax && this.slLocalizacaoPneumotorax.value) {
        let elemento = { elemento: this.slLocalizacaoPneumotorax, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }
      if (this.slGraduacaoPneumotorax && this.slGraduacaoPneumotorax.value) {
        let elemento = { elemento: this.slGraduacaoPneumotorax, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }
    }
  }

  emitPneumotoraxSimples() {
    if (this.ckPneumotorax && this.ckPneumotorax.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckPneumotorax.id);

      if (this.slLocalizacaoPneumotorax && this.slLocalizacaoPneumotorax.value) {
        let elemento = { elemento: this.slLocalizacaoPneumotorax, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
      if (this.slGraduacaoPneumotorax && this.slGraduacaoPneumotorax.value) {
        let elemento = { elemento: this.slGraduacaoPneumotorax, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }
  }

  emitHidropneumotoraxLesao() {
    if (this.ckHidropneumotorax && this.ckHidropneumotorax.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckHidropneumotorax.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacaoHidropneumotorax && this.slLocalizacaoHidropneumotorax.value) {
        let elemento = { elemento: this.slLocalizacaoHidropneumotorax, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }
      if (this.slGraduacaoHidropneumotorax && this.slGraduacaoHidropneumotorax.value) {
        let elemento = { elemento: this.slGraduacaoHidropneumotorax, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }
    }
  }

  emitHidropneumotoraxSimples() {
    if (this.ckHidropneumotorax && this.ckHidropneumotorax.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHidropneumotorax.id);

      if (this.slLocalizacaoHidropneumotorax && this.slLocalizacaoHidropneumotorax.value) {
        let elemento = { elemento: this.slLocalizacaoHidropneumotorax, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
      if (this.slGraduacaoHidropneumotorax && this.slGraduacaoHidropneumotorax.value) {
        let elemento = { elemento: this.slGraduacaoHidropneumotorax, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }
  }

  emitHiperinsuflacaoLesao() {
    if (this.ckHiperinsuflacao && this.ckHiperinsuflacao.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckHiperinsuflacao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitHiperinsuflacaoSimples() {
    if (this.ckHiperinsuflacao && this.ckHiperinsuflacao.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHiperinsuflacao.id);
    }
  }

}
