import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class IbgeServiceService {

  constructor(private _http: HttpClient) { }

  getEstados(): Observable<any>{
    return this._http.get<any>(`${environment.ibgeApi}/v1/localidades/estados?orderBy=nome`);
  }

  getCidades(uf: string): Observable<any>{
    return this._http.get<any>(`${environment.ibgeApi}/v1/localidades/estados/${uf}/municipios?orderBy=nome`);
  }
}
