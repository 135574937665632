import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-densidade-ossea',
  templateUrl: './densidade-ossea.component.html',
  styleUrls: ['./densidade-ossea.component.css']
})
export class DensidadeOsseaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdRarefacao') rdRarefacao: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickDefault(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }
    if (this.rdRarefacao.checked) {
      this.termoSelecionado.emit(this.rdRarefacao.id);
    }

    this.listaCompleta.emit();
  }

}
