import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-achados-adicionais-inferior',
  templateUrl: './achados-adicionais-inferior.component.html',
  styleUrls: ['./achados-adicionais-inferior.component.css']
})
export class AchadosAdicionaisInferiorComponent implements OnInit {

  public showAndHideTerms: any;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();


  @ViewChild('rdEdema') rdEdema: MatRadioButton;
  @ViewChild('rdCistopopliteaSemrotura') rdCistopopliteaSemrotura: MatRadioButton;
  @ViewChild('sllaminasliquidas') sllaminasliquidas: MatSelect;
  @ViewChild('sllocalizacaoface') sllocalizacaoface: MatSelect;
  @ViewChild('sllocalizacao') sllocalizacao: MatSelect;
  @ViewChild('slparedes') slparedes: MatSelect;
  @ViewChild('slconteudo') slconteudo: MatSelect;
  @ViewChild('Inmedida') Inmedida: ElementRef;
  @ViewChild('slextensaonaperna') slextensaonaperna: MatSelect;

  public laminasliquidas: ArrayDropDown[] = [
    { value: 'com lâminas líquidas de permeio', viewValue: 'Com lâminas líquidas de permeio' },
    { value: 'sem lâminas líquidas de permeio', viewValue: 'Sem lâminas líquidas de permeio' },
  ];

  public localizacaoface: ArrayDropDown[] = [
    { value: 'na face anterior', viewValue: 'Na face anterior' },
    { value: 'na face posterior', viewValue: 'Na face posterior' },
    { value: 'na face lateral', viewValue: 'Na face lateral' },
    { value: 'na face medial', viewValue: 'Na face medial' },
    { value: 'difusa', viewValue: 'Difusa' },
  ];

  public localizacao: ArrayDropDown[] = [
    { value: 'coxa', viewValue: 'Coxa' },
    { value: 'perna', viewValue: 'Perna' },
  ];

  public paredes: ArrayDropDown[] = [
    { value: 'finas', viewValue: 'Finas' },
    { value: 'espessadas', viewValue: 'Espessadas' },
  ];

  public conteudo: ArrayDropDown[] = [
    { value: 'anecoico', viewValue: 'Anecoico' },
    { value: 'anecoico com septações internas, hipoecoico', viewValue: 'Anecoico com septações internas, hipoecoico' },
  ];

  public extensaonaperna: ArrayDropDown[] = [
    { value: 'proximal', viewValue: 'Proximal' },
    { value: 'médio', viewValue: 'Médio' },
    { value: 'distal', viewValue: 'Distal' },

  ];

  public unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahEdema: true,
      sahCistopopliteaSemrotura: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public clickEdema(): any {
    this.showAndHideTerms.sahEdema = true;
    this.showAndHideTerms.sahCistopopliteaSemrotura = false;
    this.catchIDs();
  }

  public clickCistopopliteaSemrotura(): any {
    this.showAndHideTerms.sahEdema = false;
    this.showAndHideTerms.sahCistopopliteaSemrotura = true;
    this.catchIDs();
  }


  public changeSelect(): any {
    this.catchIDs();
  }


  public blurMedindo(): any {
    this.catchIDs();
  }


  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdEdema.checked) {
      this.termoSelecionado.emit(this.rdEdema.id);

      if (this.sllaminasliquidas && this.sllaminasliquidas.value) {
        const objeto = { elemento: this.sllaminasliquidas };
        this.termosGenericos.emit(objeto);
      }

      if (this.sllocalizacaoface && this.sllocalizacaoface.value) {
        const objeto = { elemento: this.sllocalizacaoface };
        this.termosGenericos.emit(objeto);
      }

      if (this.sllocalizacao && this.sllocalizacao.value) {
        const objeto = { elemento: this.sllocalizacao };
        this.termosGenericos.emit(objeto);
      }
    }

    // Cisto poplíteo + Sem rotura
    if (this.rdCistopopliteaSemrotura && this.rdCistopopliteaSemrotura.checked) {
      this.termoSelecionado.emit(this.rdCistopopliteaSemrotura.id);

      if (this.slparedes && this.slparedes.value) {
        const objeto = { elemento: this.slparedes };
        this.termosGenericos.emit(objeto);
      }

      if (this.slconteudo && this.slconteudo.value) {
        const objeto = { elemento: this.slconteudo };
        this.termosGenericos.emit(objeto);
      }

      if (this.Inmedida && this.Inmedida.nativeElement.value) {
        const objeto = { elemento: this.Inmedida.nativeElement };
        this.termosGenericos.emit(objeto);
      }

      if (this.slextensaonaperna && this.slextensaonaperna.value) {
        const objeto = { elemento: this.slextensaonaperna };
        this.termosGenericos.emit(objeto);
      }



    }


    this.listaCompleta.emit();

  }

}

