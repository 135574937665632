import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-derrame-articular-cotovelo',
  templateUrl: './derrame-articular-cotovelo.component.html',
  styleUrls: ['./derrame-articular-cotovelo.component.css']
})
export class DerrameArticularCotoveloComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;
  @ViewChild('slVolume') slVolume: MatSelect;

  volumes: ArrayDropDown[] = [
    { value: 'Pequeno', viewValue: 'Pequeno' },
    { value: 'Moderado', viewValue: 'Moderado' },
    { value: 'Acentuado', viewValue: 'Acentuado' }
  ];

  showVolume = true;
  constructor() { }

  ngOnInit() {

  }
  clickAusente() {
    this.showVolume = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showVolume = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.slVolume && this.slVolume.value)){
      this.rdPresente.checked = true;
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.value);
    }

    if (this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.value);

      if (this.slVolume && this.slVolume.value) {
        const objeto = { elemento: this.slVolume};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
