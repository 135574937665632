import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Versao } from '../models/BO/Versao';

@Injectable({
  providedIn: 'root'
})
export class VersaoService {


  constructor(private http: HttpClient) { }

  getVersao(): Observable<Versao>{
    //return this.http.get<Versao>('https://localhost:5001/api/NReportRelease/LastRelease')
    console.log(environment.nLaudoApi + "NReportRelease/LastRelease/");
    return this.http.get<Versao>(environment.nLaudoApi + "NReportRelease/LastRelease/");
  }

}
