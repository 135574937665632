import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatCardModule, MatCheckboxModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule, MatRadioModule, MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {HttpClientModule} from '@angular/common/http';
import {LayoutModule} from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TextEditorModule} from '../../../../utils/textEditor/text-editor/text-editor.module';
import { PeleTecidoJoelhoComponent } from './TermosComponents/pele-tecido-joelho/pele-tecido-joelho.component';
import { SuperficieOsseaJoelhoComponent } from './TermosComponents/superficie-ossea-joelho/superficie-ossea-joelho.component';
import { TendoesPatelarComponent } from './TermosComponents/tendoes-patelar/tendoes-patelar.component';
import { TendoesPataComponent } from './TermosComponents/tendoes-pata/tendoes-pata.component';
import { TratoIliotibialComponent } from './TermosComponents/trato-iliotibial/trato-iliotibial.component';
import { ComplexosLigamentaresComponent } from './TermosComponents/complexos-ligamentares/complexos-ligamentares.component';
import { GorduraHoffaComponent } from './TermosComponents/gordura-hoffa/gordura-hoffa.component';
import { DerrameArticularJoelhoComponent } from './TermosComponents/derrame-articular-joelho/derrame-articular-joelho.component';
import { FossaPopliteaComponent } from './TermosComponents/fossa-poplitea/fossa-poplitea.component';
import { JoelhoComponent } from './joelho.component';

import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LateralidadeModule } from 'src/app/utils/lateralidade/lateralidade.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { LigColateralMedialComponent } from './TermosComponents/lig-colateral-medial/lig-colateral-medial.component';
import { LigColateralLateralComponent } from './TermosComponents/lig-colateral-lateral/lig-colateral-lateral.component';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    LateralidadeModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
    JoelhoComponent,
    PeleTecidoJoelhoComponent,
    SuperficieOsseaJoelhoComponent,
    TendoesPatelarComponent,
    TendoesPataComponent,
    TratoIliotibialComponent,
    ComplexosLigamentaresComponent,
    GorduraHoffaComponent,
    DerrameArticularJoelhoComponent,
    FossaPopliteaComponent,
    LigColateralMedialComponent,
    LigColateralLateralComponent
  ],
  declarations: [
    JoelhoComponent,
    PeleTecidoJoelhoComponent,
    SuperficieOsseaJoelhoComponent,
    TendoesPatelarComponent,
    TendoesPataComponent,
    TratoIliotibialComponent,
    ComplexosLigamentaresComponent,
    GorduraHoffaComponent,
    DerrameArticularJoelhoComponent,
    FossaPopliteaComponent,
    LigColateralMedialComponent,
    LigColateralLateralComponent
  ]
})
export class JoelhoModule { }
