import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatDialog } from '@angular/material';
import { Validacoes } from 'src/app/utils/validacoes';
import { ResiduoDialogComponent } from '../../../../../../utils/dialog/table/residuo-dialog/residuo-dialog.component';

@Component({
  selector: 'app-residuo-miccional',
  templateUrl: './residuo-miccional.component.html',
  styleUrls: ['./residuo-miccional.component.css']
})
export class ResiduoMiccionalComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();


  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  @ViewChild('inMedida3') inMedida3: ElementRef;
  @ViewChild('inVolume') inVolume: ElementRef;
  @ViewChild('ckAusente') ckAusente: MatCheckbox;

  showAndHideTerms: any;
  phVolume = 'Volume';
  unidadeMedida: string;

  constructor(public _dialog: MatDialog,
    private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true,
    }
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  blurMedindo() {
    this.catchIDs();
  }

  clickTabelaReferencia() {
    this._dialog.open(ResiduoDialogComponent, {});
  }

  clickGeneric() {
    if (this.showAndHideTerms.sahPresente === true) {
      this.showAndHideTerms.sahPresente = false;
    } else {
      this.showAndHideTerms.sahPresente = true;
    }

    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.phVolume = 'Volume';

    if (this.ckAusente.checked) {
      this.termoSelecionado.emit(this.ckAusente.id);
    } else {
      this.formataMedindo();
    }



    this.listaCompleta.emit();
  }

  formataMedindo() {
    let medindoValor = '';
    let resultado = 0;

    if (this.inMedida1 && this.inMedida1.nativeElement.value != '') {
      resultado = this.inMedida1.nativeElement.value;
    }

    if (this.inMedida2 && this.inMedida2.nativeElement.value != '') {

      if (resultado > 0) {
        resultado = resultado * this.inMedida2.nativeElement.value;
      } else {
        resultado = this.inMedida2.nativeElement.value;
      }
    }

    if (this.inMedida3 && this.inMedida3.nativeElement.value != '') {

      if (resultado > 0) {
        resultado = resultado * this.inMedida3.nativeElement.value;
      } else {
        resultado = this.inMedida3.nativeElement.value;
      }
    }

    if (resultado != 0 || this.inVolume.nativeElement.value) {
      let volume = resultado * 0.523;
      // Volume
      if (this.inVolume.nativeElement.value)
        resultado = +this.inVolume.nativeElement.value;
      else
        resultado = resultado * 0.523;
        
      this.phVolume = volume.toFixed(2).toString();

      if (this.inVolume.nativeElement.value) {
        medindoValor = this._validacoes.formataDecimal(this.inVolume.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(+resultado.toFixed(2));
      }

      if (+resultado < 30) {
        this.inVolume.nativeElement.id = 708;
      } else {
        this.inVolume.nativeElement.id = 709;
      }

      const objetoElemento = { id: this.inVolume.nativeElement.id, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objetoLesao);
    }
  }

}
