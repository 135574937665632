import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckbox, MatRadioButton, MatSelect} from '@angular/material';
import {count} from 'rxjs/operators';

@Component({
  selector: 'app-pancreas',
  templateUrl: './pancreas.component.html',
  styleUrls: ['./pancreas.component.css']
})
export class PancreasComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdHeterogeneo') rdHeterogeneo: MatRadioButton;
  @ViewChild('rdLipossubstituido') rdLipossubstituido: MatRadioButton;
  @ViewChild('rdNaoCaracterizado') rdNaoCaracterizado: MatRadioButton;

  @ViewChild('rdAdequadamente') rdAdequadamente: MatRadioButton;
  @ViewChild('rdParcialmente') rdParcialmente: MatRadioButton;

  @ViewChild('ckLocalizacaoCabeca') ckLocalizacaoCabeca: MatCheckbox;
  @ViewChild('ckLocalizacaoCorpo') ckLocalizacaoCorpo: MatCheckbox;
  @ViewChild('ckLocalizacaoCauda') ckLocalizacaoCauda: MatCheckbox;

  @ViewChild('ckDilatacao') ckDilatacao: MatCheckbox;
  @ViewChild('inMedindo') inMedindo: ElementRef;

  showAndHideTerms: any;
  objetoLocalizacao: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahLocalizacao: false,
      sahNaoCaraterizado: true,
      sahDilatacao: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal($event) {
    this.showAndHideTerms.sahNaoCaraterizado = true;
    this.catchIDs();
  }


  clickHeterogeneo($event) {
    this.showAndHideTerms.sahNaoCaraterizado = true;
    this.catchIDs();
  }


  clickLipossubstituido($event) {
    this.showAndHideTerms.sahNaoCaraterizado = true;
    this.catchIDs();
  }

  clickNaoCaracterizado($event) {
    this.showAndHideTerms.sahLocalizacao = false;
    this.showAndHideTerms.sahNaoCaraterizado = false;
    this.showAndHideTerms.sahDilatacao = false;
    this.ckDilatacao.checked = false;
    this.catchIDs();
  }

  clickAdequadamente($event) {
    this.showAndHideTerms.sahLocalizacao = false;
    this.catchIDs();
  }

  clickParcialmente($event) {
    if (this.showAndHideTerms.sahLocalizacao === false) {
      this.showAndHideTerms.sahLocalizacao = true;
    } else {
      this.showAndHideTerms.sahLocalizacao = false;
    }
    this.catchIDs();
  }

  clickDiatacao() {
    if (this.showAndHideTerms.sahDilatacao === false) {
      this.showAndHideTerms.sahDilatacao = true;
    } else {
      this.showAndHideTerms.sahDilatacao = false;
    }

    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  clickLocalizado() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    let countLocalizacao = 0;

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.rdAdequadamente != undefined && this.rdAdequadamente.checked) {
        this.termoSelecionado.emit(this.rdAdequadamente.id);

      } else  if (this.rdParcialmente != undefined && this.rdParcialmente.checked) {
        this.termoSelecionado.emit(this.rdParcialmente.id);
        this.verificaLocalizacao(countLocalizacao);

      } else {
        //ADEQUANDAMENTE - assumo minha gambe, procurando solucao
        this.termoSelecionado.emit(159);
      }
    }

    if (this.rdHeterogeneo.checked) {
      this.termoSelecionado.emit(this.rdHeterogeneo.id);

      if (this.rdAdequadamente != undefined && this.rdAdequadamente.checked) {
        this.termoSelecionado.emit(this.rdAdequadamente.id);

      } else  if (this.rdParcialmente != undefined && this.rdParcialmente.checked) {
        this.termoSelecionado.emit(this.rdParcialmente.id);
        this.verificaLocalizacao(countLocalizacao);

      } else {
        //ADEQUANDAMENTE - assumo minha gambe, procurando solucao
        this.termoSelecionado.emit(159);
      }
    }

    if (this.rdLipossubstituido.checked) {
      this.termoSelecionado.emit(this.rdLipossubstituido.id);

      if (this.rdAdequadamente != undefined && this.rdAdequadamente.checked) {
        this.termoSelecionado.emit(this.rdAdequadamente.id);

      } else  if (this.rdParcialmente != undefined && this.rdParcialmente.checked) {
        this.termoSelecionado.emit(this.rdParcialmente.id);
        this.verificaLocalizacao(countLocalizacao);

      } else {
        //ADEQUANDAMENTE - assumo minha gambe, procurando solucao
        this.termoSelecionado.emit(159);
      }
    }

    if (this.rdNaoCaracterizado.checked) {
      this.termoSelecionado.emit(this.rdNaoCaracterizado.id);
    }

    if (this.ckDilatacao != undefined && this.ckDilatacao.checked) {
      const objetoGrupoLesao = {id: this.ckDilatacao.id, cenario: 1};
      this.lesoesSelecionadas.emit(objetoGrupoLesao);

      if (this.inMedindo != undefined && this.inMedindo.nativeElement.id != undefined) {
        const objetoLesao = {elemento: this.inMedindo.nativeElement, isPrimeiraLesao: false};
        this.termosGenericos.emit(objetoLesao);
      }
    }

    this.listaCompleta.emit();
  }

  verificaLocalizacao(countLoc) {
    let localizacao = '';

    if (this.ckLocalizacaoCabeca != undefined && this.ckLocalizacaoCabeca.checked) {
      if (localizacao != '' && countLoc < 2) {
        localizacao = localizacao + ' e ' + this.ckLocalizacaoCabeca.value;
        countLoc++;
      } else if (countLoc >= 2) {
        localizacao = localizacao.replace(' e ', ', ');
        localizacao =  localizacao + ' e ' + this.ckLocalizacaoCabeca.value;
        countLoc++;
      } else {
        localizacao =  this.ckLocalizacaoCabeca.value;
        countLoc++;
      }
    }

    if (this.ckLocalizacaoCorpo != undefined && this.ckLocalizacaoCorpo.checked) {
      if (localizacao != '' && countLoc < 2) {
        localizacao = localizacao + ' e ' + this.ckLocalizacaoCorpo.value;
        countLoc++;
      } else if (countLoc >= 2) {
        localizacao = localizacao.replace(' e ', ', ');
        localizacao =  localizacao + ' e ' + this.ckLocalizacaoCorpo.value;
        countLoc++;
      } else {
        localizacao = this.ckLocalizacaoCorpo.value;
        countLoc++;
      }
    }

    if (this.ckLocalizacaoCauda != undefined && this.ckLocalizacaoCauda.checked) {
      if (localizacao != '' && countLoc < 2) {
        localizacao = localizacao + ' e ' + this.ckLocalizacaoCauda.value;
        countLoc++;
      } else if (countLoc >= 2) {
        localizacao = localizacao.replace(' e ', ', ');
        localizacao =  localizacao + ' e ' + this.ckLocalizacaoCauda.value;
        countLoc++;
      } else {
        localizacao =  this.ckLocalizacaoCauda.value;
        countLoc++;
      }
    }

    if (localizacao != '') {
      const objetoElemento = {id: 165, value: localizacao};
      this.objetoLocalizacao = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(this.objetoLocalizacao);
    }
  }


}
