import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import * as PouchDB from 'pouchdb/dist/pouchdb';
import { Router } from '@angular/router';
import { FilterPipe } from 'ngx-filter-pipe';
import { ConfirmDialogComponent } from '../utils/dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatCheckbox } from '@angular/material';
import { PesquisaComponent } from '../pesquisa/pesquisa.component';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PagamentoService } from '../services/pagamento.service';
import { StatusPagamentoService } from '../services/status-pagamento.service';
import { tap, take } from 'rxjs/operators';
import { ConfigLaudoService } from '../services/config-laudo.service';
import { MedicalReportMask } from '../models/BO/MedicalReportMask';
import { PouchDBService } from '../services/pouchdb.service';
import { AlertDialogComponent } from '../utils/dialog/alert-dialog/alert-dialog.component';
import { PagamentoDialogComponent } from '../utils/dialog/pagamento-dialog/pagamento-dialog.component';
import { UserService } from '../services/user.service';
import { ListaExamesService } from '../services/lista-exames.service';
import { CircleProgressOptions } from 'ng-circle-progress';
import { HeaderComponent } from '../header/header.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NPSService } from '../services/nps.service';

@Component({
  selector: 'app-laudos',
  templateUrl: './laudos.component.html',
  styleUrls: ['./laudos.component.css']
})

export class LaudosComponent implements OnInit {
  nomeUsuario: string;
  showAndHide: any;
  BindsURL: SafeResourceUrl;
  status: number;
  tipoExame: number;
  laudos: any;
  listBlocks: number[];
  blocosPorLaudo: any;
  blocos: any;
  loadingProgress: number = 0;
  options = new CircleProgressOptions();
  showloading: boolean = false;
  respondeu: boolean = false;

  constructor(
    private router: Router,
    private _userService: UserService,
    private filter: FilterPipe,
    private _configLaudoService: ConfigLaudoService,
    private _dialog: MatDialog,
    public sanitizer: DomSanitizer,
    private _pgto: PagamentoService,
    private _statusPgto: StatusPagamentoService,
    private _pouchdb: PouchDBService,
    private _header: HeaderComponent,
    private _listaExames: ListaExamesService,
    private toastr: ToastrManager,
    private _nps: NPSService,
  ) { }

  async ngOnInit() {
    //Remove o item clicado
    localStorage.removeItem('updateUserClick');

    const errorMessage = JSON.parse(localStorage.getItem('errorMessage'));
    if (errorMessage !== undefined && errorMessage !== null) {
      this.toastr.errorToastr(errorMessage.message, 'Desculpe',
        {
          position: 'top-right',
          toastTimeout: 4000
        }
      );

      localStorage.removeItem('errorMessage');
    }

    const userID = +localStorage.getItem('userID');
    this._header.carregarFavoritos();

    this.options.subtitle = [
      'Carregando',
      'laudos'
    ]
    if (userID) {
      if (localStorage.getItem('updatedUser') == null || localStorage.getItem('updatedUser') == 'false') {
        this._userService.buscarUsuario(userID)
          .subscribe(usuario => {
            localStorage.setItem('updatedUser', usuario.updatedUser.toString());
            if (usuario.updatedUser == false) {
              const dialogRef = this._dialog.open(AlertDialogComponent, {
                width: '450px',
                data: 'info|Olá ' + localStorage.getItem('nomeUsuario') + ', matenha seus dados cadastrais atualizados. Clique no botão continuar para atualiza-los!'
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  localStorage.setItem('updateUserClick', 'true');
                  this.router.navigate(['/perfil']);
                }
              });
            }
          });
      }

      this.verificarStatus();
      this.showAndHide = {
        sahSelecionado: false,
        sahTomografia: false,
        sahUltrassom: false,
        sahMamografia: false,
        sahRadiografia: false,
      };

      this.nomeUsuario = localStorage.getItem('nomeUsuario');

      const tipoImg = localStorage.getItem('TipoImagem');
      if (tipoImg) {
        if (tipoImg === 'RX')
          this.goTo(4);
        if (tipoImg === 'MG')
          this.goTo(3);
        if (tipoImg === 'TC')
          this.goTo(2);
        else if (tipoImg === 'US')
          this.goTo(1);
      }
      setTimeout(() => this.verificaSeUsuarioRespondeu());

      this.buscaConfig()

      // if (localStorage.getItem('payment') === 'true') {
      //   const dialogRef = this._dialog.open(PagamentoDialogComponent, {
      //     width: '450px',
      //   });
      // }
    }
  }

  buscaConfig() {
    const userID = +localStorage.getItem('userID');
    this._configLaudoService.buscaConfig(userID.toString())
      .subscribe((config) => {

        if (config && config.length > 0) {
          localStorage.setItem('tecnicaRule', JSON.stringify(config[0]));
          localStorage.setItem('cmRule', JSON.stringify(config[1]));
          localStorage.setItem('copyRule', JSON.stringify(config[2]));
          localStorage.setItem('loadLaudosRule', JSON.stringify(config[3]));

          let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

          if (loadLaudosRule.active && localStorage.getItem('laudosLoaded') == null || localStorage.getItem('laudosLoaded') == 'false') {
            this.showloading = true;
            this.carregaBlocosdelaudo().then((res) => {
              this.blocos = res;
            }).then(() => {
              setTimeout(() => {
                this.carregaLaudoTemp();
              }, 5000);

            });
          }
          
          let reloadLaudos = JSON.parse(localStorage.getItem('reloadlaudos'));
    
          if (reloadLaudos == true) {
            this.showloading = true;
            this.carregaBlocosdelaudo().then((res) => {
              this.blocos = res;
            }).then(() => {
              setTimeout(() => {
                this.carregaLaudoTemp();
              }, 5000);
    
            });
            
            localStorage.setItem('reloadlaudos', 'false');
          }
        }
      });
  }

  goTo(where: number) {
    if (where === 4){
      localStorage.setItem('TipoImagem', 'RX');
      this.showAndHide.sahMamografia = false;
      this.showAndHide.sahSelecionado = true;
      this.showAndHide.sahUltrassom = false;
      this.showAndHide.sahTomografia = false;
      this.showAndHide.sahRadiografia = true;
      this.tipoExame = 4;
    } else if (where === 3) {
      localStorage.setItem('TipoImagem', 'MG');
      this.showAndHide.sahMamografia = true;
      this.showAndHide.sahSelecionado = true;
      this.showAndHide.sahUltrassom = false;
      this.showAndHide.sahTomografia = false;
      this.showAndHide.sahRadiografia = false;
      this.tipoExame = 3;
    } else if (where === 2) {
      localStorage.setItem('TipoImagem', 'TC');
      this.showAndHide.sahTomografia = true;
      this.showAndHide.sahSelecionado = true;
      this.showAndHide.sahUltrassom = false;
      this.showAndHide.sahMamografia = false;
      this.showAndHide.sahRadiografia = false;
      this.tipoExame = 2;
    } else if (where === 1) {
      localStorage.setItem('TipoImagem', 'US');
      this.showAndHide.sahUltrassom = true;
      this.showAndHide.sahSelecionado = true;
      this.showAndHide.sahTomografia = false;
      this.showAndHide.sahMamografia = false;
      this.showAndHide.sahRadiografia = false;
      this.tipoExame = 1;
    }
  }

  abrirAvaliacao() {
    const userid = +localStorage.getItem('userID');
    const companyid = +localStorage.getItem('companyID');
    this._nps.getNps(userid).subscribe((nps) => {
      const modal = this._dialog.open(PesquisaComponent, {
        data: {
          url: nps.link +
            '?fields=metadata.usuarioid,false,' + userid,
          userID: userid,
        },
        width: '80%',
        minHeight: '90%'
      });
    });
  }

  verificaSeUsuarioRespondeu() {
    const userid = localStorage.getItem('userID');
    this._nps.getListaNps().subscribe(res => {
      if (res.length > 0) {
        res.forEach(element => {
          if (element.metadata != undefined) {
            if (element.metadata.usuarioid == userid) {
              this.respondeu = true;
            }
          }
        });

        if (!this.respondeu) {
          this.respondeu = false;
          this.abrirAvaliacao()
        }
      }
    });
  }

  verificarStatus() {
    this._statusPgto.getStatusPagamento()
      .pipe(
        take(1)
      )
      .subscribe((novoStatus) => this.status = novoStatus);

    if (this.status === 3) {
      setTimeout(() => this._pgto.alterarStatus(), 5000); //para efeito de Testes
      // } else if (this.status === 1 || this.status === 2) {
      //   this._statusPgto.resolverStatusPgto();
    }
  }

  async carregaBlocosdelaudo() {
    let resultado = [];
    this._listaExames.getLaudos().subscribe((laudos) => {
      this.listBlocks = [];
      laudos.forEach(laudo => {
        this._listaExames.getBlocoPorLaudo(laudo.id).subscribe((blocos) => {
          if (blocos.length > 0) {
            blocos.forEach(bloco => {
              resultado.push(bloco)
            });
          }
        });
      })
    });
    return resultado;
  }

  async carregaLaudoTemp() {
    this._listaExames.getLaudos().subscribe((laudos) => {
      this.listBlocks = [];
      laudos.forEach(laudo => {
        if (laudo.parentMedicalReportTypeID == 0) {
          this.blocos.forEach(bloco => {
            if (bloco.medicalReportTypeID == laudo.id) {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 1 && laudo.url == "abdomeDoppler") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 6 && laudo.url == "testiculoDoppler") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 38 && laudo.url == "varizes") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 36 && laudo.url == "obsTransSegTercDoppler") {
              this.listBlocks.push(
                bloco.id, 316 //Artérias uterinas - Morfo doppler 1
              );
            }
            if (bloco.medicalReportTypeID == 36 && laudo.url == "morfoPrim") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 53 && laudo.url == "morfoPrimDoppler") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 14 && laudo.url == "ombroBilateral") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 13 && laudo.url == "punhoBilateral") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 21 && laudo.url == "peBilateral") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 15 && laudo.url == "joelhoBilateral") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 22 && laudo.url == "maoBilateral") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 19 && laudo.url == "tornozeloBilateral") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 18 && laudo.url == "musculosTendoesBilateral") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 16 && laudo.url == "cotoveloBilateral") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 17 && laudo.url == "quadrilBilateral") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 48 && laudo.url == "quadrilInfantilBilateral") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 2 && laudo.url == "mamaDoppler") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 1 && laudo.url == "abdomeTotalPelvico") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if ((bloco.medicalReportTypeID == 1 || bloco.medicalReportTypeID == 5) && laudo.url == "abdomeTotalProstata") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 5 && laudo.url == "rinsProstata") {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 73 && laudo.url == "morfoSegDoppler") {
              this.listBlocks.push(
                309, 310, 311, 312, 324, 325, 326, 327, 328, 329, 330, 313, 306, 315, 316
              );
            }
          });

        } else {
          this.blocos.forEach(bloco => {
            if (bloco.medicalReportTypeID == laudo.parentMedicalReportTypeID) {
              this.listBlocks.push(
                bloco.id
              );
            }
            if (bloco.medicalReportTypeID == 1 && laudo.url == "abdomeSuperiorDoppler") {
              this.listBlocks.push(
                bloco.id
              );
            }
          });
        }
        if (laudo.url == "cervical-doppler") {
          this.listBlocks.push(
            27, 53
          );
        }
        if (laudo.url == "tireoideDoppler") {
          this.listBlocks.push(
            27
          );
        }
        if (laudo.url == "transv-doppler") {
          this.listBlocks.push(
            21
          );
        }
        if (laudo.url == "partesMoles") {
          this.listBlocks.push(
            48, 49
          );
        }
        if (laudo.url == "pelvico") {
          this.listBlocks.push(
            9
          );
        }
        if (laudo.url == "penis") {
          this.listBlocks.push(
            275
          );
        }
        if (laudo.url == "arteriasRenais") {
          this.listBlocks.push(
            9, 10, 11
          );
        }
        if (laudo.url == "cranio") {
          this.listBlocks.push(
            140
          );
        }
        if (laudo.url == "morfoPrimDoppler") {
          this.listBlocks.push(
            181, 182
          );
        }
        if (laudo.url == "mamaDoppler") {
          this.listBlocks.push(
            321
          );
        }
        if (laudo.url == "abdomeTotalProstata") {
          this.listBlocks.push(
            33, 34, 35, 36, 37
          );
        }
        if (laudo.url == "abdomeTotalPelvico") {
          this.listBlocks.push(
            20, 21, 22, 23, 24, 26
          );
        }
        if (laudo.url == "rinsPelvico") {
          this.listBlocks.push(
            9, 10, 11, 20, 21, 22, 23, 24, 26
          );
        }
        if (laudo.url == "rinsProstata") {
          this.listBlocks.push(
            9, 10, 11, 33, 34, 35, 36, 37
          );
        }
        this._pouchdb.loadLaudosDatabaseByBlocks(this.listBlocks, laudo.url);
        this.listBlocks = [];

        this.loadingProgress += 1;
        this.options.percent = Math.floor((this.loadingProgress / laudos.length) * 100);
        if (this.options.percent == 100) {
          setTimeout(() => {
            this.showloading = false
          }, 500);
        }

      })
    })

    localStorage.setItem('laudosLoaded', 'true');
  }
}
