import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from './../../environments/environment';
import { Feedback } from '../models/BO/Feedback';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private _url = environment.nLaudoApi + 'Feedback';

  constructor(private _http: HttpClient) { }

  rating(feedback: any, rate: number, userID: number): Observable<Feedback> {
    const feedbackRate = {
      subject: feedback.assunto,
      description: feedback.descricao,
      userId: userID,
      rating: rate
    };

    return this._http.post<Feedback>(`${this._url}/Avaliar`, feedbackRate);
  }
}
