import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckbox, MatRadioButton, MatSelect} from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-orquiectomia',
  templateUrl: './orquiectomia.component.html',
  styleUrls: ['./orquiectomia.component.css']
})
export class OrquiectomiaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('ckOrqDireita') ckOrqDireita: MatCheckbox;
  @ViewChild('ckCriptoDireita') ckCriptoDireita: MatCheckbox;
  @ViewChild('ckCanalInguinalDir') ckCanalInguinalDir: MatCheckbox;
  @ViewChild('ckOrqEsquerda') ckOrqEsquerda: MatCheckbox;  
  @ViewChild('ckCriptoEsquerda') ckCriptoEsquerda: MatCheckbox;
  @ViewChild('ckCanalInguinalEsq') ckCanalInguinalEsq: MatCheckbox;

  @ViewChild('inMedidaDir1') inMedidaDir1: ElementRef;
  @ViewChild('inMedidaDir2') inMedidaDir2: ElementRef;
  @ViewChild('inMedidaDir3') inMedidaDir3: ElementRef;
  @ViewChild('inVolumeDir') inVolumeDir: ElementRef;

  @ViewChild('slContornoDir') slContornoDir: MatSelect;
  @ViewChild('slDimensaoDir') slDimensaoDir: MatSelect;
  @ViewChild('slEcotexturaDir') slEcotexturaDir: MatSelect;

  @ViewChild('inMedidaEsq1') inMedidaEsq1: ElementRef;
  @ViewChild('inMedidaEsq2') inMedidaEsq2: ElementRef;
  @ViewChild('inMedidaEsq3') inMedidaEsq3: ElementRef;
  @ViewChild('inVolumeEsq') inVolumeEsq: ElementRef;

  @ViewChild('slContornoEsq') slContornoEsq: MatSelect;
  @ViewChild('slDimensaoEsq') slDimensaoEsq: MatSelect;
  @ViewChild('slEcotexturaEsq') slEcotexturaEsq: MatSelect;

  contornos: ArrayDropDown[] = [
    { value: 'regulares', viewValue: 'Regulares' },
    { value: 'irregulares', viewValue: 'Irregulares' }
  ];

  dimensoes: ArrayDropDown[] = [
    { value: 'preservadas', viewValue: 'Preservadas' },
    { value: 'reduzidas', viewValue: 'reduzidas' },
    { value: 'aumentadas', viewValue: 'Aumentadas' }
  ];

  ecotexturas: ArrayDropDown[] = [
    { value: 'homogênea', viewValue: 'Homogênea' },
    { value: 'heterogênea', viewValue: 'Heterogênea' }
  ];

  volumeDir = 'Volume';
  volumeEsq = 'Volume';
  showAndHideTerms: any;
  countLesoes = 0;
  idAusente = '764';
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) {}

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true,
      sahCriptoDireita: false,
      sahCriptoEsquerda: false,
      sahCanalInguinalDir: false,
      sahCanalInguinalEsq: false,
      sahSomeDir: true,
      sahSomeEsq: true,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }
  clickOrqDireita() {
    if (!this.ckOrqDireita.checked && !this.ckOrqEsquerda.checked) {
      this.showAndHideTerms.sahPresente = true;
    }
    if (this.showAndHideTerms.sahSomeDir == false) {
      this.showAndHideTerms.sahSomeDir = true;
    } else {
      this.showAndHideTerms.sahSomeDir = false;
    }
    this.catchIDs();
  }

  clickOrqEsquerda() {
    if (!this.ckOrqDireita.checked && !this.ckOrqEsquerda.checked) {
      this.showAndHideTerms.sahPresente = true;
    }
    if (this.showAndHideTerms.sahSomeEsq == false) {
      this.showAndHideTerms.sahSomeEsq = true;
    } else {
      this.showAndHideTerms.sahSomeEsq = false;
    }
    this.catchIDs();
  }

  clickDefault() {

    if (!this.ckOrqDireita.checked && !this.ckOrqEsquerda.checked) {
      this.showAndHideTerms.sahPresente = true;
    }

    this.catchIDs();
  }

  clickCriptoDir() {
    if (this.showAndHideTerms.sahCriptoDireita == false) {
      this.showAndHideTerms.sahCriptoDireita = true;
      this.showAndHideTerms.sahCanalInguinalDir = false;
    } else {
      this.showAndHideTerms.sahCriptoDireita = false;
    }
    this.catchIDs();
  }

  clickCanalDir() {
    if (this.showAndHideTerms.sahCanalInguinalDir == false) {
      this.showAndHideTerms.sahCanalInguinalDir = true;
    } else {
      this.showAndHideTerms.sahCanalInguinalDir = false;
    }
    this.catchIDs();
  }
  clickCriptoEsq() {
    if (this.showAndHideTerms.sahCriptoEsquerda == false) {
      this.showAndHideTerms.sahCriptoEsquerda = true;
      this.showAndHideTerms.sahCanalInguinalEsq = false;
    } else {
      this.showAndHideTerms.sahCriptoEsquerda = false;
    }
    this.catchIDs();
  }
  clickCanalEsq() {
    if (this.showAndHideTerms.sahCanalInguinalEsq == false) {
      this.showAndHideTerms.sahCanalInguinalEsq = true;
    } else {
      this.showAndHideTerms.sahCanalInguinalEsq = false;
    }
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if ((!this.ckOrqDireita.checked && !this.ckOrqEsquerda.checked) &&
        (!this.ckCriptoDireita.checked && !this.ckCriptoEsquerda.checked)
       ) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.idAusente);
    }

    if (this.ckOrqDireita !== undefined && this.ckOrqDireita.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckOrqDireita.id);
    }

    if (this.ckOrqEsquerda !== undefined && this.ckOrqEsquerda.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckOrqEsquerda.id);
    }

    if (this.countLesoes > 0) {
      this.emitCripDirLesao();
    } else {
      this.emitCripDir();
    }

    if (this.countLesoes > 0) {
      this.emitCripEsqLesao();
    } else {
      this.emitCripEsq();
    }

    this.listaCompleta.emit();
  }

  emitCripDirLesao() {
    if (this.ckCriptoDireita && this.ckCriptoDireita.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCriptoDireita.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckCanalInguinalDir && this.ckCanalInguinalDir.checked) {
        const objeto = { id: this.ckCanalInguinalDir.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.slContornoDir && this.slContornoDir.value) {
          const objeto = { elemento: this.slContornoDir, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slDimensaoDir && this.slDimensaoDir.value) {
          const objeto = { elemento: this.slDimensaoDir, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slEcotexturaDir && this.slEcotexturaDir.value) {
          const objeto = { elemento: this.slEcotexturaDir, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoDir(false);
      }
    }

  }

  emitCripDir() {
    if (this.ckCriptoDireita && this.ckCriptoDireita.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCriptoDireita.id);

      if (this.ckCanalInguinalDir && this.ckCanalInguinalDir.checked) {
        this.termoSelecionado.emit(this.ckCanalInguinalDir.id);

        if (this.slContornoDir && this.slContornoDir.value) {
          const objeto = { elemento: this.slContornoDir, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slDimensaoDir && this.slDimensaoDir.value) {
          const objeto = { elemento: this.slDimensaoDir, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slEcotexturaDir && this.slEcotexturaDir.value) {
          const objeto = { elemento: this.slEcotexturaDir, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoDir(true);
      }
    }

  }

  emitCripEsqLesao() {
    if (this.ckCriptoEsquerda && this.ckCriptoEsquerda.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCriptoEsquerda.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckCanalInguinalEsq && this.ckCanalInguinalEsq.checked) {
        const objeto = { id: this.ckCanalInguinalEsq.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.slContornoEsq && this.slContornoEsq.value) {
          const objeto = { elemento: this.slContornoEsq, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slDimensaoEsq && this.slDimensaoEsq.value) {
          const objeto = { elemento: this.slDimensaoEsq, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slEcotexturaEsq && this.slEcotexturaEsq.value) {
          const objeto = { elemento: this.slEcotexturaEsq, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoEsq(false);
      }
    }
  }

  emitCripEsq() {
    if (this.ckCriptoEsquerda && this.ckCriptoEsquerda.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCriptoEsquerda.id);

      if (this.ckCanalInguinalEsq && this.ckCanalInguinalEsq.checked) {
        this.termoSelecionado.emit(this.ckCanalInguinalEsq.id);

        if (this.slContornoEsq && this.slContornoEsq.value) {
          const objeto = { elemento: this.slContornoEsq, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slDimensaoEsq && this.slDimensaoEsq.value) {
          const objeto = { elemento: this.slDimensaoEsq, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slEcotexturaEsq && this.slEcotexturaEsq.value) {
          const objeto = { elemento: this.slEcotexturaEsq, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoEsq(true);
      }
    }
  }

  formataMedindoDir(isPrimeiraLesao) {
    if ((this.inMedidaDir1 && this.inMedidaDir2 && this.inMedidaDir3) &&
        (this.inMedidaDir1.nativeElement.value || this.inMedidaDir2.nativeElement.value || this.inMedidaDir3.nativeElement.value)
       ) {
      this.countLesoes++;
      let medindoValor = '';
      let resultado = 0;

      if (this.inMedidaDir1 != undefined &&
        this.inMedidaDir1.nativeElement.id != undefined &&
        this.inMedidaDir1.nativeElement.value != '') {

        medindoValor = this._validacoes.formataDecimal(this.inMedidaDir1.nativeElement.value);
        resultado = +this.inMedidaDir1.nativeElement.value;
      }

      if (this.inMedidaDir2 != undefined &&
        this.inMedidaDir2.nativeElement.id != undefined &&
        this.inMedidaDir2.nativeElement.value != '') {

        if (medindoValor != '') {
          medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaDir2.nativeElement.value);
          resultado = +resultado * +this.inMedidaDir2.nativeElement.value;
        } else {
          medindoValor = this._validacoes.formataDecimal(this.inMedidaDir2.nativeElement.value);
          resultado = +this.inMedidaDir2.nativeElement.value;
        }
      }

      if (this.inMedidaDir3 != undefined &&
        this.inMedidaDir3.nativeElement.id != undefined &&
        this.inMedidaDir3.nativeElement.value != '') {

        if (medindoValor != '') {
          medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaDir3.nativeElement.value);
          resultado = +resultado * +this.inMedidaDir3.nativeElement.value;
        } else {
          medindoValor = this._validacoes.formataDecimal(this.inMedidaDir3.nativeElement.value);
          resultado = +this.inMedidaDir3.nativeElement.value;
        }
      }

      if (medindoValor != '') {
        // Volume
        resultado = resultado * 0.523;
        this.volumeDir = resultado.toFixed(1).toString();
  
        if (this.inVolumeDir.nativeElement.value) {
          medindoValor = medindoValor + ' cm (volume = ' + this._validacoes.formataDecimal(this.inVolumeDir.nativeElement.value) + ' cm³)';
        } else {
          medindoValor = medindoValor + ' cm (volume = ' + this._validacoes.formataDecimal(+resultado.toFixed(1)) + ' cm³)';
        }
  
        const objetoElemento = { id: "4610" , value: medindoValor };
        const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
        this.termosGenericos.emit(objetoLesao);
      }
    }
  }

  formataMedindoEsq(isPrimeiraLesao) {
    if ((this.inMedidaEsq1 && this.inMedidaEsq2 && this.inMedidaEsq3) &&
        (this.inMedidaEsq1.nativeElement.value || this.inMedidaEsq2.nativeElement.value || this.inMedidaEsq3.nativeElement.value)
       ) {
      this.countLesoes++;
      let medindoValor = '';
      let resultado = 0;

      if (this.inMedidaEsq1 != undefined &&
        this.inMedidaEsq1.nativeElement.id != undefined &&
        this.inMedidaEsq1.nativeElement.value != '') {

        medindoValor = this._validacoes.formataDecimal(this.inMedidaEsq1.nativeElement.value);
        resultado = +this.inMedidaEsq1.nativeElement.value;
      }

      if (this.inMedidaEsq2 != undefined &&
        this.inMedidaEsq2.nativeElement.id != undefined &&
        this.inMedidaEsq2.nativeElement.value != '') {

        if (medindoValor != '') {
          medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaEsq2.nativeElement.value);
          resultado = +resultado * +this.inMedidaEsq2.nativeElement.value;
        } else {
          medindoValor = this._validacoes.formataDecimal(this.inMedidaEsq2.nativeElement.value);
          resultado = +this.inMedidaEsq2.nativeElement.value;
        }
      }

      if (this.inMedidaEsq3 != undefined &&
        this.inMedidaEsq3.nativeElement.id != undefined &&
        this.inMedidaEsq3.nativeElement.value != '') {

        if (medindoValor != '') {
          medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaEsq3.nativeElement.value);
          resultado = +resultado * +this.inMedidaEsq3.nativeElement.value;
        } else {
          medindoValor = this._validacoes.formataDecimal(this.inMedidaEsq3.nativeElement.value);
          resultado = +this.inMedidaEsq3.nativeElement.value;
        }
      }

      if (medindoValor != '') {
        // Volume
        resultado = resultado * 0.523;
        this.volumeEsq = resultado.toFixed(1).toString();
  
        if (this.inVolumeEsq.nativeElement.value) {
          medindoValor = medindoValor + ' cm (volume = ' + this._validacoes.formataDecimal(this.inVolumeEsq.nativeElement.value) + ' cm³)';
        } else {
          medindoValor = medindoValor + ' cm (volume = ' + this._validacoes.formataDecimal(+resultado.toFixed(1)) + ' cm³)';
        }
  
        const objetoElemento = { id: "4614", value: medindoValor };
        const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
        this.termosGenericos.emit(objetoLesao);
      }
    }
  }

}
