import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-derrame-pleural-torax',
  templateUrl: './derrame-pleural-torax.component.html',
  styleUrls: ['./derrame-pleural-torax.component.css']
})
export class DerramePleuralToraxComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  // @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('ckDerrPleuDireito') ckDerrPleuDireito: MatCheckbox;
  @ViewChild('ckMedidaDir') ckMedidaDir: MatCheckbox;
  @ViewChild('ckDerrPleuEsq') ckDerrPleuEsq: MatCheckbox;
  @ViewChild('ckMedidaEsq') ckMedidaEsq: MatCheckbox;

  @ViewChild('slQuantidadesDir') slQuantidadesDir: MatSelect;
  @ViewChild('slConteudoDir') slConteudoDir: MatSelect;
  @ViewChild('slQuantidadesEsq') slQuantidadesEsq: MatSelect;
  @ViewChild('slConteudoEsq') slConteudoEsq: MatSelect;

  @ViewChild('inEspessuraDir') inEspessuraDir: ElementRef;
  @ViewChild('inDistanciaDir') inDistanciaDir: ElementRef;
  @ViewChild('inEspessuraEsq') inEspessuraEsq: ElementRef;
  @ViewChild('inDistanciaEsq') inDistanciaEsq: ElementRef;

  quantidades: ArrayDropDown[] = [
    { value: 'Pequeno', viewValue: 'Pequeno' },
    { value: 'Moderado', viewValue: 'Moderado' },
    { value: 'Acentuado', viewValue: 'Acentuado' }
  ];

  conteudos: ArrayDropDown[] = [
    { value: 'anecoico', viewValue: 'Anecoico' },
    { value: 'anecoico e septações de permeio', viewValue: 'Anecoico e septações de permeio' },
    { value: 'hipoecoico', viewValue: 'Hipoecoico' },
    { value: 'anecoico e debris', viewValue: 'Anecoico e debris' }
  ];

  showAndHideTerms: any;
  countLesoes: number = 0;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true,
      sahDerrPleuDir: false,
      sahComMedidaDir: false,
      sahDerrPleuEsq: false,
      sahComMedidaEsq: false
    }
    let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.showAndHideTerms.sahDerrPleuDir = false;
    this.showAndHideTerms.sahComMedidaDir = false;
    this.showAndHideTerms.sahDerrPleuEsq = false;
    this.showAndHideTerms.sahComMedidaEsq = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.showAndHideTerms.sahDerrPleuDir = false;
    this.showAndHideTerms.sahComMedidaDir = false;
    this.showAndHideTerms.sahDerrPleuEsq = false;
    this.showAndHideTerms.sahComMedidaEsq = false;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickDerrPleuDir() {
    if (this.showAndHideTerms.sahDerrPleuDir === false) {
      this.showAndHideTerms.sahDerrPleuDir = true;
    } else {
      this.showAndHideTerms.sahDerrPleuDir = false;
    }
    this.verificaAlterado();
    this.catchIDs();
  }


  clickDerrPleuEsq() {
    if (this.showAndHideTerms.sahDerrPleuEsq === false) {
      this.showAndHideTerms.sahDerrPleuEsq = true;
    } else {
      this.showAndHideTerms.sahDerrPleuEsq = false;
    }
    this.verificaAlterado();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente.checked) {
      if (this.ckDerrPleuDireito.checked) {
        this.emitDerrPleuDir();
      } else if (this.ckDerrPleuEsq.checked) {
        this.emitDerrPleuEsq();
      }
      if (this.ckDerrPleuDireito.checked && this.ckDerrPleuEsq.checked) {
        this.emitDerrPleuDir();
        this.emitDerrPleuEsq();
      }
    }

    // this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  emitDerrPleuDir() {
    if (this.ckDerrPleuDireito && this.ckDerrPleuDireito.checked) {
      this.countLesoes++;
      // Direito
      this.termoSelecionado.emit(this.ckDerrPleuDireito.id);

      if (this.slQuantidadesDir && this.slQuantidadesDir.value) {
        const objeto = { elemento: this.slQuantidadesDir, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slConteudoDir && this.slConteudoDir.value) {
        const objeto = { elemento: this.slConteudoDir, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if ((this.inEspessuraDir && this.inEspessuraDir.nativeElement.value) &&
        (this.inDistanciaDir && this.inDistanciaDir.nativeElement.value)) {
        const MedidaDir = "1905";
        this.termoSelecionado.emit(MedidaDir);
        const obj1 = { elemento: this.inEspessuraDir.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(obj1);
        const obj2 = { elemento: this.inDistanciaDir.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(obj2);
      }
    }
  }

  emitDerrPleuEsq() {
    if (this.ckDerrPleuEsq && this.ckDerrPleuEsq.checked) {
      this.countLesoes++;
      // Direito
      this.termoSelecionado.emit(this.ckDerrPleuEsq.id);

      if (this.slQuantidadesEsq && this.slQuantidadesEsq.value) {
        const objeto = { elemento: this.slQuantidadesEsq, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slConteudoEsq && this.slConteudoEsq.value) {
        const objeto = { elemento: this.slConteudoEsq, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if ((this.inEspessuraEsq && this.inEspessuraEsq.nativeElement.value) &&
        (this.inDistanciaEsq && this.inDistanciaEsq.nativeElement.value)) {
        const MedidaEsq = "1910";
        this.termoSelecionado.emit(MedidaEsq);
        const obj3 = { elemento: this.inEspessuraEsq.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(obj3);
        const obj4 = { elemento: this.inDistanciaEsq.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(obj4);
      }

    }
  }

  verificaAlterado() {
    if (this.ckDerrPleuDireito.checked ||
      this.ckDerrPleuEsq.checked) {
      // Marca normal
      this.rdPresente.checked = true;
    } else if (!this.ckDerrPleuDireito.checked &&
      !this.ckDerrPleuEsq.checked) {
      // Marca normal
      this.rdAusente.checked = true;
    }
  }

}
