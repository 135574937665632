import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { Validacoes } from '../../../../../../utils/validacoes';

@Component({
  selector: 'app-pele-tecido-subcutaneo',
  templateUrl: './pele-tecido-subcutaneo.component.html',
  styleUrls: ['./pele-tecido-subcutaneo.component.css']
})
export class PeleTecidoSubcutaneoComponent implements OnInit {

  public showAndHideTerms: any;
  public LipomaId = 1371;
  public countLesoes = 0;
  public unidadeMedida: string;


  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;
  @ViewChild('ckEdema') ckEdema: MatCheckbox;
  @ViewChild('ckLipoma') ckLipoma: MatCheckbox;
  @ViewChild('ckCotornoDefindo') ckCotornoDefindo: MatCheckbox;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  @ViewChild('inMedida3') inMedida3: ElementRef;
  @ViewChild('inDistpele') inDistpele: ElementRef;


  localizacoes: ArrayDropDown[] = [
    { value: 'anterior', viewValue: 'Anterior' },
    { value: 'posterior', viewValue: 'Posterior' },
    { value: 'medial', viewValue: 'Medial' },
    { value: 'lateral', viewValue: 'Lateral' }
  ];


  constructor(public readonly _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahEdema: false,
      sahDefinido: false,
      sahNaoDefinido: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public clickNormal(): any {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahEdema = false;
    this.showAndHideTerms.sahDefinido = false;
    this.showAndHideTerms.sahNaoDefinido = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.catchIDs();
  }

  clickEdema() {
    this.verificaLesoes();

    if (this.showAndHideTerms.sahEdema === false) {
      this.showAndHideTerms.sahEdema = true;
    } else {
      this.showAndHideTerms.sahEdema = false;
    }
    this.catchIDs();
  }

  public clickDefinido(): any {
    this.verificaLesoes();

    if (this.showAndHideTerms.sahDefinido === false) {
      this.showAndHideTerms.sahDefinido = true;
    } else {
      this.showAndHideTerms.sahDefinido = false;
    }
    this.catchIDs();
  }

  public clickNaoDefinido(): any {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.ckEdema && this.ckEdema.checked) || (this.ckLipoma && this.ckLipoma.checked)){
      this.rdAlterado.checked = true;
    }
  }

  public blurMedindo(): any {
    this.catchIDs();
  }

  formataMedindoLipoma(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedida1 != undefined &&
      this.inMedida1.nativeElement.id != undefined &&
      this.inMedida1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);
    }

    if (this.inMedida2 != undefined &&
      this.inMedida2.nativeElement.id != undefined &&
      this.inMedida2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
      }
    }

    if (this.inMedida3 != undefined &&
      this.inMedida3.nativeElement.id != undefined &&
      this.inMedida3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = { id: 1373, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdAlterado.checked) {

      this.emitEdema();


      if (this.countLesoes > 0) {
        this.emitkDefinidoLesao();
      } else {
        this.emitckDefinido();
      }

    }
    this.listaCompleta.emit();

  }


  emitEdema() {
    if (this.ckEdema && this.ckEdema.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEdema.value);

      if (this.slLocalizacao && this.slLocalizacao.value) {
        const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitckDefinido() {
    if (this.ckLipoma && this.ckLipoma.checked) {
      this.termoSelecionado.emit(this.LipomaId);
      if (this.ckCotornoDefindo && this.ckCotornoDefindo.checked) {
        this.termoSelecionado.emit(this.ckCotornoDefindo.id);
      }

      if (this.inDistpele && this.inDistpele.nativeElement.value) {
        const objeto = { elemento: this.inDistpele.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
    this.formataMedindoLipoma(true);

  }

  emitkDefinidoLesao() {
    if (this.ckLipoma !== undefined && this.ckLipoma.checked) {
      this.countLesoes++;
      const objeto = { id: this.LipomaId, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      
      if (this.ckCotornoDefindo && this.ckCotornoDefindo.checked) {
        const objeto = { id: this.ckCotornoDefindo.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
  
      }

      if (this.inDistpele && this.inDistpele.nativeElement.value) {
        const objeto = { elemento: this.inDistpele.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
    this.formataMedindoLipoma(false);
  }

}
