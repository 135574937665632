import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SelectedItemEmiter {
  Selected(item: string, blockList: any[]) {
    blockList.forEach(x => {
      if (x.id === item) {
        x.selected = true;
      } else {
        x.selected = false;
      }
    });

    return blockList;
  }
}
