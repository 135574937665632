import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatRadioButton, MatSelect} from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-varicocele',
  templateUrl: './varicocele.component.html',
  styleUrls: ['./varicocele.component.css']
})
export class VaricoceleComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('slLado') slLado: MatSelect;
  @ViewChild('inCalibre') inCalibre: ElementRef;

  lados: ArrGenerico[] = [
    {value: 'direita', viewValue: 'Direita'},
    {value: 'esquerda', viewValue: 'Esquerda'},
    {value: 'bilateral', viewValue: 'Bilateral'}
  ];

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.slLado && this.slLado.value) || (this.inCalibre && this.inCalibre.nativeElement.value)){
      this.rdPresente.checked = true;
    }
  }

  blurMedindo() {
    this.verificaLesoes();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);

      if (this.slLado != undefined && this.slLado.value != undefined) {
        const objeto = {elemento: this.slLado, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inCalibre != undefined &&
        this.inCalibre.nativeElement.id != undefined &&
        this.inCalibre.nativeElement.value != '') {

        const objeto = {elemento: this.inCalibre.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

}
