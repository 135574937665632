import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckbox, MatDialog, MatRadioButton, MatSelect } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { BacoDialogComponent } from 'src/app/utils/dialog/table/baco-dialog/baco-dialog.component';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-abd-baco',
  templateUrl: './abd-baco.component.html',
  styleUrls: ['./abd-baco.component.css']
})
export class AbdBacoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdHeterogeneo') rdHeterogeneo: MatRadioButton;
  @ViewChild('rdEsplenomegalia') rdEsplenomegalia: MatRadioButton;
  @ViewChild('rdAusente') rdAusente: MatRadioButton;

  @ViewChild('rdHomogoneo') rdHomogoneo: MatRadioButton;
  @ViewChild('rdHeterogeneoEsp') rdHeterogeneoEsp: MatRadioButton;


  @ViewChild('inIndEsplenico1') inIndEsplenico1: ElementRef;
  @ViewChild('inIndEsplenico2') inIndEsplenico2: ElementRef;

  @ViewChild('ckEsplenose') ckEsplenose: MatCheckbox;
  @ViewChild('inMaiorMede') inMaiorMede: ElementRef;

  @ViewChild('ckBacoAcessorio') ckBacoAcessorio: MatCheckbox;
  @ViewChild('inBacoMedindo1') inBacoMedindo1: ElementRef;
  @ViewChild('inBacoMedindo2') inBacoMedindo2: ElementRef;
  @ViewChild('inBacoMedindo3') inBacoMedindo3: ElementRef;

  @ViewChild('ckHemangioma') ckHemangioma: MatCheckbox;
  @ViewChild('rd1Hemangioma') rd1Hemangioma: MatRadioButton;
  @ViewChild('rd2Hemangioma') rd2Hemangioma: MatRadioButton;
  @ViewChild('inHemangiomaMedindo1') inHemangiomaMedindo1: ElementRef;
  @ViewChild('inHemangiomaMedindo2') inHemangiomaMedindo2: ElementRef;
  @ViewChild('inHemangiomaMedindo3') inHemangiomaMedindo3: ElementRef;
  @ViewChild('slHemangiomaMargem') slHemangiomaMargem: MatSelect;
  @ViewChild('slHemangiomaLocal') slHemangiomaLocal: MatSelect;

  @ViewChild('ckNodulo') ckNodulo: MatCheckbox;
  @ViewChild('rd1Nodulo') rd1Nodulo: MatRadioButton;
  @ViewChild('rd2Nodulo') rd2Nodulo: MatRadioButton;
  @ViewChild('inNoduloMedindo1') inNoduloMedindo1: ElementRef;
  @ViewChild('inNoduloMedindo2') inNoduloMedindo2: ElementRef;
  @ViewChild('inNoduloMedindo3') inNoduloMedindo3: ElementRef;
  @ViewChild('slNoduloMargem') slNoduloMargem: MatSelect;
  @ViewChild('slNoduloEcogenicidade') slNoduloEcogenicidade: MatSelect;
  @ViewChild('slNoduloLocal') slNoduloLocal: MatSelect;

  @ViewChild('ckCalcificacao') ckCalcificacao: MatCheckbox;
  @ViewChild('inCalcificacaoMedindo') inCalcificacaoMedindo: ElementRef;
  @ViewChild('slCalcificacaoLocal') slCalcificacaoLocal: MatSelect;

  estados: ArrayDropDown[] = [
    {value: 'homogeneo', viewValue: 'Homogêneo'},
    {value: 'heterogeneo', viewValue: 'Heterogênio'}
  ];
  locais: ArrayDropDown[] = [
    {value: 'no polo superior', viewValue: 'Polo superior'},
    {value: 'no polo inferior', viewValue: 'Polo inferior'},
    {value: 'na superfície diafragmatica', viewValue: 'Superfície diafragmática'},
    {value: 'na margem hilar', viewValue: 'Margem hilar'}
  ];
  // nodulos
  nodulosMargem: ArrayDropDown[] = [
    {value: 'regulares', viewValue: 'Regulares'},
    {value: 'lobuladas', viewValue: 'Lobuladas'},
    {value: 'indefinidas', viewValue: 'Indefinidas'}
  ];
  nodulosLocal: ArrayDropDown[] = [
    {value: 'no polo superior', viewValue: 'Polo superior'},
    {value: 'no polo inferior', viewValue: 'Polo inferior'},
    {value: 'na superfície diafragmática', viewValue: 'Superfície diafragmática'},
    {value: 'na margem hilar', viewValue: 'Margem hilar'}
  ];
  nodulosEcogenicidade: ArrayDropDown[] = [
    {value: 'hipoecogênica', viewValue: 'Hipoecogênica'},
    {value: 'isoecogênica', viewValue: 'Isoecogênica'},
    {value: 'hiperecogênica', viewValue: 'Hiperecogênica'}
  ];
  nodulosEcogenicidades: ArrayDropDown[] = [
    {value: 'hipoecogênicas', viewValue: 'Hipoecogênicas'},
    {value: 'isoecogênicas', viewValue: 'Isoecogênicas'},
    {value: 'hiperecogênicas', viewValue: 'Hiperecogênicas'}
  ];

  objetoLesao: any;
  options: FormGroup;
  showAndHideTerms: any;
  countLesoes = 0;
  objetoGrupoLesao: any;
  unidadeMedida: string;

  constructor(fb: FormBuilder,
              private _validacoes: Validacoes,
              private _toastr: ToastrManager,
              public dialog: MatDialog) {
    this.options = fb.group({
      floatLabel: 'auto',
    });
  }

  ngOnInit() {
    this.showAndHideTerms = {
      sahIndiceEsp: true,
      sahAusente: false,
      sahEsplenomegalia: false,
      sahEsplenose: false,
      sahLesoes: true,
      sahBacoAcessorio: false,
      sahHemangioma: false,
      sahCalsificacao: false,
      sahNodulo: false,
      sah1Nodulo: true,
      sah2Nodulo: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickTabelaReferencia() {
    this.dialog.open(BacoDialogComponent, {});
  }

  clickNormal() {
    if(!this.showAndHideTerms.sahAusente){
      this.inIndEsplenico1.nativeElement.value = '';
      this.inIndEsplenico2.nativeElement.value = '';
    }
    this.showAndHideTerms.sahIndiceEsp = true;
    this.showAndHideTerms.sahAusente = false;
    this.showAndHideTerms.sahEsplenose = false;
    this.showAndHideTerms.sahEsplenomegalia = false;
    this.showAndHideTerms.sahLesoes = true;
    this.catchIDs();
  }

  clickHeterogeneo() {
    this.showAndHideTerms.sahIndiceEsp = true;
    this.showAndHideTerms.sahAusente = false;
    this.showAndHideTerms.sahEsplenose = false;
    this.showAndHideTerms.sahEsplenomegalia = false;
    this.showAndHideTerms.sahLesoes = true;

    if (this.inIndEsplenico1 && this.inIndEsplenico2) {
      this.inIndEsplenico1.nativeElement.value = '';
      this.inIndEsplenico2.nativeElement.value = '';
    }
    this.catchIDs();
  }

  clickEsplenomegalia() {
    this.showAndHideTerms.sahIndiceEsp = true;
    this.showAndHideTerms.sahAusente = false;
    this.showAndHideTerms.sahEsplenose = false;
    this.showAndHideTerms.sahEsplenomegalia = true;
    this.showAndHideTerms.sahLesoes = true;

    if (this.inIndEsplenico1 && this.inIndEsplenico2) {
      this.inIndEsplenico1.nativeElement.value = '';
      this.inIndEsplenico2.nativeElement.value = '';
    }
    this.catchIDs();
  }

  clickAusente() {
    this.showAndHideTerms.sahIndiceEsp = false;
    this.showAndHideTerms.sahAusente = true;
    this.showAndHideTerms.sahEsplenose = false;
    this.showAndHideTerms.sahEsplenomegalia = false;
    this.showAndHideTerms.sahLesoes = false;
    this.showAndHideTerms.sahBacoAcessorio = false;
    this.showAndHideTerms.sahNodulo = false;
    this.showAndHideTerms.sahHemangioma = false;
    this.showAndHideTerms.sahCalsificacao = false;
    this.catchIDs();
  }

  clickBacoAcessorio() {
    if (this.showAndHideTerms.sahBacoAcessorio === false) {
      this.showAndHideTerms.sahBacoAcessorio = true;
    } else {
      this.showAndHideTerms.sahBacoAcessorio = false;
    }
    this.catchIDs();
  }

  clickNodulos() {
    if (this.showAndHideTerms.sahNodulo === false) {
      this.showAndHideTerms.sahNodulo = true;
      this.showAndHideTerms.sahHemangioma = false;
      this.showAndHideTerms.sahCalsificacao = false;
      this.ckHemangioma.checked = false;
      this.ckCalcificacao.checked = false;
    } else {
      this.showAndHideTerms.sahNodulo = false;
    }
    this.catchIDs();
  }

  clickCalcificacoes() {
    if (this.showAndHideTerms.sahCalsificacao === false) {
      this.showAndHideTerms.sahCalsificacao = true;
      this.showAndHideTerms.sahHemangioma = false;
      this.showAndHideTerms.sahNodulo = false;
      this.ckNodulo.checked = false;
      this.ckHemangioma.checked = false;
    } else {
      this.showAndHideTerms.sahCalsificacao = false;
    }
    this.catchIDs();
  }

  clickHemangiomas() {
    if (this.showAndHideTerms.sahHemangioma === false) {
      this.showAndHideTerms.sahHemangioma = true;
      this.showAndHideTerms.sahNodulo = false;
      this.showAndHideTerms.sahCalsificacao = false;
      this.ckNodulo.checked = false;
      this.ckCalcificacao.checked = false;
    } else {
      this.showAndHideTerms.sahHemangioma = false;
    }
    this.catchIDs();
  }

  clickEsplenose() {
    if(this.showAndHideTerms.sahEsplenose === false) {
        this.showAndHideTerms.sahEsplenose = true;
      } else {
        this.showAndHideTerms.sahEsplenose = false;
      }
    this.showAndHideTerms.sahEsplenomegalia = false;
    this.showAndHideTerms.sahLesoes = false;
    this.catchIDs();
  }

  clickHomogeno() {
    this.catchIDs();
  }

  clickHeterogeneoEsp() {
    this.catchIDs();
  }

  changeMargem() {
    this.catchIDs();
  }

  changeEcogenicidade() {
    this.catchIDs();
  }

  changeLocal() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  click1Hemangioma() {
    this.catchIDs();
  }
  click2Hemangioma() {
    this.catchIDs();
  }

  click1Nodulo() {
    this.showAndHideTerms.sah1Nodulo = true;
    this.showAndHideTerms.sah2Nodulo = false;
    this.catchIDs();
  }
  click2Nodulo() {
    this.showAndHideTerms.sah1Nodulo = false;
    this.showAndHideTerms.sah2Nodulo = true;
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    let indice1 = '';
    let indice2 = '';

    if (this.rdNormal.checked) {
        this.termoSelecionado.emit(this.rdNormal.id);

      if (this.inIndEsplenico1 != undefined &&
        this.inIndEsplenico1.nativeElement.id != undefined &&
        this.inIndEsplenico1.nativeElement.value != '') {
        indice1 = this.inIndEsplenico1.nativeElement.value;
      }

      if (this.inIndEsplenico2 != undefined &&
        this.inIndEsplenico2.nativeElement.id != undefined &&
        this.inIndEsplenico2.nativeElement.value != '') {
        indice2 = this.inIndEsplenico2.nativeElement.value;
      }

      if (indice1 != '' && indice2 != '') {
        const numIndice = +indice1 * +indice2
        if (numIndice >= 60.00) {
          this._toastr.infoToastr('Índice esplênico é maior que 60');
        }
        const objeto = {id: this.inIndEsplenico1.nativeElement.id, value: +numIndice.toFixed(1)};
        this.objetoLesao = {elemento: objeto, isPrimeiraLesao: true};
        this.termosGenericos.emit(this.objetoLesao);
      }
    }
    if (this.rdHeterogeneo.checked) {
      this.termoSelecionado.emit(this.rdHeterogeneo.id);

      if (this.inIndEsplenico1 != undefined &&
        this.inIndEsplenico1.nativeElement.id != undefined &&
        this.inIndEsplenico1.nativeElement.value != '') {
        indice1 = this.inIndEsplenico1.nativeElement.value;
      }

      if (this.inIndEsplenico2 != undefined &&
        this.inIndEsplenico2.nativeElement.id != undefined &&
        this.inIndEsplenico2.nativeElement.value != '') {
        indice2 = this.inIndEsplenico2.nativeElement.value;
      }

      if (indice1 != '' && indice2 != '') {
        const numIndice = +indice1 * +indice2
        if (numIndice >= 60.00) {
          this._toastr.infoToastr('Índice esplênico é maior que 60');
        }
        const objeto = {id: this.inIndEsplenico1.nativeElement.id, value: +numIndice.toFixed(1)};
        this.objetoLesao = {elemento: objeto, isPrimeiraLesao: true};
        this.termosGenericos.emit(this.objetoLesao);
      }
    }

    if (this.rdEsplenomegalia.checked) {
      this.termoSelecionado.emit(this.rdEsplenomegalia.id);

      if (this.rdHomogoneo != undefined && this.rdHomogoneo.checked) {
        this.termoSelecionado.emit(this.rdHomogoneo.id);
      }

      if (this.rdHeterogeneoEsp != undefined && this.rdHeterogeneoEsp.checked) {
        this.termoSelecionado.emit(this.rdHeterogeneoEsp.id);
      }

      if (this.inIndEsplenico1 != undefined &&
        this.inIndEsplenico1.nativeElement.id != undefined &&
        this.inIndEsplenico1.nativeElement.value != '') {
        indice1 = this.inIndEsplenico1.nativeElement.value;
      }

      if (this.inIndEsplenico2 != undefined &&
        this.inIndEsplenico2.nativeElement.id != undefined &&
        this.inIndEsplenico2.nativeElement.value != '') {
        indice2 = this.inIndEsplenico2.nativeElement.value;
      }

      if (indice1 != '' && indice2 != '') {
        const numIndice = +indice1 * +indice2
        if (numIndice < 60.00) {
          this._toastr.infoToastr('Índice esplênico é menor que 60');
        }
        const objeto = {id: this.inIndEsplenico1.nativeElement.id, value: +numIndice.toFixed(1)};
        this.objetoLesao = {elemento: objeto, isPrimeiraLesao: true};
        this.termosGenericos.emit(this.objetoLesao);
      }
    }

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);

      if (this.ckEsplenose != undefined && this.ckEsplenose.checked) {
        this.termoSelecionado.emit(this.ckEsplenose.id);
      }

      if (this.inMaiorMede != undefined &&
        this.inMaiorMede.nativeElement.id != undefined &&
        this.inMaiorMede.nativeElement.value != '') {
        this.objetoLesao = {elemento: this.inMaiorMede.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(this.objetoLesao);
      }
    }

    if (this.ckHemangioma != undefined && this.ckHemangioma.checked) {
      if (this.countLesoes == 0) {
          this.EmitPrimeiroHemangioma();
        } else {
          this.EmitOutroHemangioma();
        }
    }

    if (this.ckNodulo != undefined && this.ckNodulo.checked) {
      if (this.countLesoes == 0) {
          this.EmitPrimeiroNodulo();
        } else {
          this.EmitOutroNodulo();
        }
    }

    if (this.ckCalcificacao != undefined && this.ckCalcificacao.checked) {
      if (this.countLesoes == 0) {
        this.EmitPrimeiraCalcificacao();
      } else {
        this.EmitOutroCalcificacao();
      }
    }

    if (this.ckBacoAcessorio != undefined && this.ckBacoAcessorio.checked) {
      if (this.countLesoes == 0) {
        this.EmitPrimeiroBacoAcessorio();
      } else {
        this.EmitOutroBacoAcessorio();
      }

    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  EmitPrimeiroBacoAcessorio() {
    let medindoValor = '';
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckBacoAcessorio.id);

    if (this.inBacoMedindo1 != undefined && this.inBacoMedindo1.nativeElement.id != undefined
        && this.inBacoMedindo1.nativeElement.value !== '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inBacoMedindo1.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inBacoMedindo1.nativeElement.value);
      }
    }
    if (this.inBacoMedindo2 != undefined && this.inBacoMedindo2.nativeElement.id != undefined
        && this.inBacoMedindo2.nativeElement.value !== '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inBacoMedindo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inBacoMedindo2.nativeElement.value);
      }
    }
    if (this.inBacoMedindo3 != undefined && this.inBacoMedindo3.nativeElement.id != undefined
        && this.inBacoMedindo3.nativeElement.value !== '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inBacoMedindo3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inBacoMedindo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = {id: 145, value: medindoValor};
      this.objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(this.objetoLesao);
    }
  }

  EmitOutroBacoAcessorio() {
    let medindoValor = '';
    this.countLesoes++;
    const objetoGrupoLesao = {id: this.ckBacoAcessorio.id, cenario: this.countLesoes};
    this.lesoesSelecionadas.emit(objetoGrupoLesao);

    if (this.inBacoMedindo1 != undefined && this.inBacoMedindo1.nativeElement.id != undefined
        && this.inBacoMedindo1.nativeElement.value !== '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inBacoMedindo1.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inBacoMedindo1.nativeElement.value);
      }
    }
    if (this.inBacoMedindo2 != undefined && this.inBacoMedindo2.nativeElement.id != undefined
        && this.inBacoMedindo2.nativeElement.value !== '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inBacoMedindo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inBacoMedindo2.nativeElement.value);
      }
    }
    if (this.inBacoMedindo3 != undefined && this.inBacoMedindo3.nativeElement.id != undefined
        && this.inBacoMedindo3.nativeElement.value !== '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inBacoMedindo3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inBacoMedindo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = {id: 145, value: medindoValor};
      this.objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: false};
      this.termosGenericos.emit(this.objetoLesao);
    }
  }

  EmitPrimeiroHemangioma() {
    this.countLesoes++;
    let medindoValor = '';
    this.termoSelecionado.emit(this.ckHemangioma.id);

    if (this.rd1Hemangioma != undefined && this.rd1Hemangioma.checked) {
      this.termoSelecionado.emit(this.rd1Hemangioma.value);
    }

    if (this.rd2Hemangioma != undefined && this.rd2Hemangioma.checked) {
      this.termoSelecionado.emit(this.rd2Hemangioma.value);
    }

    if (this.inHemangiomaMedindo1 != undefined && this.inHemangiomaMedindo1.nativeElement.id != undefined) {
      this.objetoLesao = {elemento: this.inHemangiomaMedindo1.nativeElement, isPrimeiraLesao: true};

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inHemangiomaMedindo1.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inHemangiomaMedindo1.nativeElement.value);
      }
    }
    if (this.inHemangiomaMedindo2 != undefined && this.inHemangiomaMedindo2.nativeElement.id != undefined) {
      this.objetoLesao = {elemento: this.inHemangiomaMedindo2.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(this.objetoLesao);

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inHemangiomaMedindo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inHemangiomaMedindo2.nativeElement.value);
      }
    }
    if (this.inHemangiomaMedindo3 != undefined && this.inHemangiomaMedindo3.nativeElement.id != undefined) {
      this.objetoLesao = {elemento: this.inHemangiomaMedindo3.nativeElement, isPrimeiraLesao: true};

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inHemangiomaMedindo3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inHemangiomaMedindo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = {id: this.inHemangiomaMedindo1.nativeElement.id, value: medindoValor};
      this.objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(this.objetoLesao);
    }

    if (this.slHemangiomaMargem != undefined && this.slHemangiomaMargem.value != undefined) {
      this.objetoLesao = {elemento: this.slHemangiomaMargem, isPrimeiraLesao: true};
      this.termosGenericos.emit(this.objetoLesao);
    }

    if (this.slHemangiomaLocal != undefined && this.slHemangiomaLocal.value != undefined) {
      this.objetoLesao = {elemento: this.slHemangiomaLocal, isPrimeiraLesao: true};
      this.termosGenericos.emit(this.objetoLesao);
    }
  }

  EmitOutroHemangioma() {
    this.countLesoes++;
    let medindoValor = '';

    const objetoGrupoLesao = {id: this.ckHemangioma.id, cenario: this.countLesoes};
    this.lesoesSelecionadas.emit(objetoGrupoLesao);

    if (this.rd1Hemangioma != undefined && this.rd1Hemangioma.checked) {
      this.objetoGrupoLesao = {id: this.rd1Hemangioma.value, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(this.objetoGrupoLesao);
    }

    if (this.rd2Hemangioma != undefined && this.rd2Hemangioma.checked) {
      this.objetoGrupoLesao = {id: this.rd2Hemangioma.value, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(this.objetoGrupoLesao);
    }

    if (this.inHemangiomaMedindo1 != undefined &&
      this.inHemangiomaMedindo1.nativeElement.id != undefined &&
      this.inHemangiomaMedindo1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inHemangiomaMedindo1.nativeElement.value);
    }

    if (this.inHemangiomaMedindo2 != undefined &&
      this.inHemangiomaMedindo2.nativeElement.id != undefined &&
      this.inHemangiomaMedindo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inHemangiomaMedindo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inHemangiomaMedindo2.nativeElement.value);
      }
    }

    if (this.inHemangiomaMedindo3 != undefined &&
      this.inHemangiomaMedindo3.nativeElement.id != undefined &&
      this.inHemangiomaMedindo3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inHemangiomaMedindo3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inHemangiomaMedindo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = {id: this.inHemangiomaMedindo1.nativeElement.id, value: medindoValor};
      this.objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: false};
      this.termosGenericos.emit(this.objetoLesao);
    }

    if (this.slHemangiomaMargem != undefined && this.slHemangiomaMargem.value != undefined) {
      this.objetoLesao = {elemento: this.slHemangiomaMargem, isPrimeiraLesao: false};
      this.termosGenericos.emit(this.objetoLesao);
    }
    if (this.slHemangiomaLocal != undefined && this.slHemangiomaLocal.value != undefined) {
      this.objetoLesao = {elemento: this.slHemangiomaLocal, isPrimeiraLesao: false};
      this.termosGenericos.emit(this.objetoLesao);
    }
  }

  EmitPrimeiroNodulo() {
      this.countLesoes++;
      let medindoValor = '';
      this.termoSelecionado.emit(this.ckNodulo.id);

      if (this.rd1Nodulo != undefined && this.rd1Nodulo.checked) {
        this.termoSelecionado.emit(this.rd1Nodulo.value);
      }

      if (this.rd2Nodulo != undefined && this.rd2Nodulo.checked) {
        this.termoSelecionado.emit(this.rd2Nodulo.value);
      }

      if (this.inNoduloMedindo1 != undefined && this.inNoduloMedindo1.nativeElement.id != undefined) {
        this.objetoLesao = {elemento: this.inNoduloMedindo1.nativeElement, isPrimeiraLesao: true};

        if (medindoValor != '') {
          medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inNoduloMedindo1.nativeElement.value);
        } else {
          medindoValor = this._validacoes.formataDecimal(this.inNoduloMedindo1.nativeElement.value);
        }
      }
      if (this.inNoduloMedindo2 != undefined && this.inNoduloMedindo2.nativeElement.id != undefined) {
        this.objetoLesao = {elemento: this.inNoduloMedindo2.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(this.objetoLesao);

        if (medindoValor != '') {
          medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inNoduloMedindo2.nativeElement.value);
        } else {
          medindoValor = this._validacoes.formataDecimal(this.inNoduloMedindo2.nativeElement.value);
        }
      }
      if (this.inNoduloMedindo3 != undefined && this.inNoduloMedindo3.nativeElement.id != undefined) {
        this.objetoLesao = {elemento: this.inNoduloMedindo3.nativeElement, isPrimeiraLesao: true};

        if (medindoValor != '') {
          medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inNoduloMedindo3.nativeElement.value);
        } else {
          medindoValor = this._validacoes.formataDecimal(this.inNoduloMedindo3.nativeElement.value);
        }
      }

      if (medindoValor != '') {
        const objetoElemento = {id: this.inNoduloMedindo1.nativeElement.id, value: medindoValor};
        this.objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(this.objetoLesao);
      }

      if (this.slNoduloLocal != undefined && this.slNoduloLocal.value != undefined) {
        this.objetoLesao = {elemento: this.slNoduloLocal, isPrimeiraLesao: true};
        this.termosGenericos.emit(this.objetoLesao);
      }

      if (this.slNoduloEcogenicidade != undefined && this.slNoduloEcogenicidade.value != undefined) {
        this.objetoLesao = {elemento: this.slNoduloEcogenicidade, isPrimeiraLesao: true};
        this.termosGenericos.emit(this.objetoLesao);
      }

      if (this.slNoduloMargem != undefined && this.slNoduloMargem.value != undefined) {
        this.objetoLesao = {elemento: this.slNoduloMargem, isPrimeiraLesao: true};
        this.termosGenericos.emit(this.objetoLesao);
      }
  }

  EmitOutroNodulo() {
    this.countLesoes++;
    let medindoValor = '';

    const objetoGrupoLesao = {id: this.ckNodulo.id, cenario: this.countLesoes};
    this.lesoesSelecionadas.emit(objetoGrupoLesao);

    if (this.rd1Nodulo != undefined && this.rd1Nodulo.checked) {
      this.objetoGrupoLesao = {id: this.rd1Nodulo.value, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(this.objetoGrupoLesao);
    }

    if (this.rd2Nodulo != undefined && this.rd2Nodulo.checked) {
      this.objetoGrupoLesao = {id: this.rd2Nodulo.value, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(this.objetoGrupoLesao);
    }

    if (this.inNoduloMedindo1 != undefined &&
      this.inNoduloMedindo1.nativeElement.id != undefined &&
      this.inNoduloMedindo1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inNoduloMedindo1.nativeElement.value);
    }

    if (this.inNoduloMedindo2 != undefined &&
      this.inNoduloMedindo2.nativeElement.id != undefined &&
      this.inNoduloMedindo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inNoduloMedindo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inNoduloMedindo2.nativeElement.value);
      }
    }

    if (this.inNoduloMedindo3 != undefined &&
      this.inNoduloMedindo3.nativeElement.id != undefined &&
      this.inNoduloMedindo3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inNoduloMedindo3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inNoduloMedindo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = {id: this.inNoduloMedindo1.nativeElement.id, value: medindoValor};
      this.objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: false};
      this.termosGenericos.emit(this.objetoLesao);
    }

    if (this.slNoduloLocal != undefined && this.slNoduloLocal.value != undefined) {
      this.objetoLesao = {elemento: this.slNoduloLocal, isPrimeiraLesao: false};
      this.termosGenericos.emit(this.objetoLesao);
    }

    if (this.slNoduloEcogenicidade != undefined && this.slNoduloEcogenicidade.value != undefined) {
      this.objetoLesao = {elemento: this.slNoduloEcogenicidade, isPrimeiraLesao: false};
      this.termosGenericos.emit(this.objetoLesao);
    }

    if (this.slNoduloMargem != undefined && this.slNoduloMargem.value != undefined) {
      this.objetoLesao = {elemento: this.slNoduloMargem, isPrimeiraLesao: false};
      this.termosGenericos.emit(this.objetoLesao);
    }
  }

  EmitPrimeiraCalcificacao() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckCalcificacao.id);

    if (this.inCalcificacaoMedindo != undefined && this.inCalcificacaoMedindo.nativeElement.id != undefined) {
      this.objetoLesao = {elemento: this.inCalcificacaoMedindo.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(this.objetoLesao);
    }

    if (this.slCalcificacaoLocal != undefined && this.slCalcificacaoLocal.value != undefined) {
      this.objetoLesao = {elemento: this.slCalcificacaoLocal, isPrimeiraLesao: true};
      this.termosGenericos.emit(this.objetoLesao);
    }
  }


  EmitOutroCalcificacao() {
    this.countLesoes++;

    const objetoGrupoLesao = {id: this.ckCalcificacao.id, cenario: this.countLesoes};
    this.lesoesSelecionadas.emit(objetoGrupoLesao);

    if (this.inCalcificacaoMedindo != undefined && this.inCalcificacaoMedindo.nativeElement.id != undefined) {
      const objetoLesao = {elemento: this.inCalcificacaoMedindo.nativeElement, isPrimeiraLesao: false};
      this.termosGenericos.emit(objetoLesao);
    }

    if (this.slCalcificacaoLocal != undefined && this.slCalcificacaoLocal.value != undefined) {
      this.objetoLesao = {elemento: this.slCalcificacaoLocal, isPrimeiraLesao: false};
      this.termosGenericos.emit(this.objetoLesao);
    }
  }

}
