import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransvSegTercTrimestreComponent } from './transv-seg-terc-trimestre.component';
import {
  MatExpansionModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatTooltipModule,
  MatSlideToggleModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from 'src/app/utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { UteroComponent } from './TermosComponents/utero/utero.component';
import { FetoComponent } from './TermosComponents/feto/feto.component';
import { ParametrosBiometricosComponent } from './TermosComponents/parametros-biometricos/parametros-biometricos.component';
import { PlacentaComponent } from './TermosComponents/placenta/placenta.component';
import { CordaoUmbilicalComponent } from './TermosComponents/cordao-umbilical/cordao-umbilical.component';
import { LiquidoAmnioticoComponent } from './TermosComponents/liquido-amniotico/liquido-amniotico.component';
import { ObsCalculadoraComponent } from '../obs-calculadora/obs-calculadora.component';
import { ObsCalculadoraModule } from '../obs-calculadora/obs-calculadora.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    ObsCalculadoraModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
    TransvSegTercTrimestreComponent,
    UteroComponent,
    FetoComponent,
    ParametrosBiometricosComponent,
    PlacentaComponent,
    CordaoUmbilicalComponent,
    LiquidoAmnioticoComponent
  ],
  declarations: [
    TransvSegTercTrimestreComponent,
    UteroComponent,
    FetoComponent,
    ParametrosBiometricosComponent,
    PlacentaComponent,
    CordaoUmbilicalComponent,
    LiquidoAmnioticoComponent
  ]
})
export class TransvSegTercTrimestreModule { }
