export class UserMemberGetMember {
  id: number;
  memberGetMemberID: number;
  userID: number;
  indicatedUserID: number;
  token: string;
}

export class  UserHasMemberGetMemberWallet
    {
      id: number;
      UserID: number;
      MemberGetMemberID: number;
      TotalScore: number;
      SumTransection: number;
      MinusTransection: number;
      Description: string;
    }
