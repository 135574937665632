import { NgModule } from '@angular/core';
import {
  MatExpansionModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatTableDataSource,
  MatDialogModule,
  MatTooltipModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatSlideToggleModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {HttpClientModule} from '@angular/common/http';
import {LayoutModule} from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TextEditorModule} from '../../../../utils/textEditor/text-editor/text-editor.module';
import {TesticuloComponent} from './testiculo.component';
import { TesticuloDireitoComponent } from './TermosComponents/testiculo-direito/testiculo-direito.component';
import { TesticuloEsquerdoComponent } from './TermosComponents/testiculo-esquerdo/testiculo-esquerdo.component';
import { EpididimoDireitoComponent } from './TermosComponents/epididimo-direito/epididimo-direito.component';
import { EpididimoEsquerdoComponent } from './TermosComponents/epididimo-esquerdo/epididimo-esquerdo.component';
import { HidroceleDieitaComponent } from './TermosComponents/hidrocele-dieita/hidrocele-dieita.component';
import { HidroceleEsquerdaComponent } from './TermosComponents/hidrocele-esquerda/hidrocele-esquerda.component';
import { OrquiectomiaComponent } from './TermosComponents/orquiectomia/orquiectomia.component';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
    TesticuloComponent,
    TesticuloDireitoComponent,
    TesticuloEsquerdoComponent,
    EpididimoDireitoComponent,
    EpididimoEsquerdoComponent,
    HidroceleDieitaComponent,
    HidroceleEsquerdaComponent,
    OrquiectomiaComponent
  ],
  declarations: [
    TesticuloComponent,
    TesticuloDireitoComponent,
    TesticuloEsquerdoComponent,
    EpididimoDireitoComponent,
    EpididimoEsquerdoComponent,
    HidroceleDieitaComponent,
    HidroceleEsquerdaComponent,
    OrquiectomiaComponent
  ]
})
export class TesticuloModule { }
