import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

import { Term } from 'src/app/models/BO/Term';
import { TermResult } from 'src/app/models/BO/TermResult';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { CommonService } from 'src/app/services/common.service';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { Validacoes } from 'src/app/utils/validacoes';
import { TermosGenericos } from '../abdome-total/abdome-total.component';
import { MamaComponent } from '../mama/mama.component';

@Component({
  selector: 'app-mama-masculina',
  templateUrl: './mama-masculina.component.html',
  styleUrls: ['./mama-masculina.component.css']
})
export class MamaMasculinaComponent implements OnInit {
  public medicalReportTypeID = 71;
  public htmlContent: string;
  public subtitulo: string;
  public subtituloBloco: string;
  public mask: string;

  public switchFormReport: boolean = true;
  public showForm: boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];

  public term = new Term();
  public termResultsAPI: Array<TermResult>;
  public termConclusionAPI: Array<TermResult>;
  public cabecalhoTexto = '';
  public rodapeTexto = '';

  public cabecalho: boolean = false;
  public ginecomastia: boolean = false;
  public indicacao: boolean = true;
  public cisto: boolean = false;
  public linfonodomegalia: boolean = false;
  public nodulo: boolean = false;
  public achadosTexto: boolean = false;
  public rodape = false;

  public ginecomastiaID: number = 323;

  listTermDefault=[
    {listTerm:[375],blockID:16},
    {listTerm:[511],blockID:12},
    {listTerm:[510],blockID:19},
    {listTerm:[358],blockID:14},
    {listTerm:[478],blockID:13},
    {listTerm:[370],blockID:15},
    {listTerm:[381],blockID:17},
    {listTerm:[4615],blockID:25},
    {listTerm:[3982],blockID:252},
    {listTerm:[4682],blockID:323},
  ]

  public descricoes: DescricaoConclusao[] = [
    { value: 'Avaliação de ginecomastia', blockID: 16, textTermID: 0, visivel: true },
    { value: 'Pele e tecido celular subcutâneo de aspecto preservado.', blockID: 0, textTermID: 0, visivel: true },
    { value: 'Ausência de tecido fibroglandular na região retroareolar das mamas.', blockID: 323, textTermID: 0, visivel: true },
    { value: 'Não há evidências nódulos sólidos ao método.', blockID: 19, textTermID: 0, visivel: true },
    { value: 'Ausência de lesões císticas.', blockID: 12, textTermID: 0, visivel: true  },
    { value: 'Ausência de linfonodomegalias axilares.', blockID: 17, textTermID: 0, visivel: true }
  ];

  public conclusoes: DescricaoConclusao[] = [
    { value: 'Estudo ultrassonográfico normal.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 323, textTermID: 0, visivel: true },
    { value: '', blockID: 19, textTermID: 0, visivel: true },
    { value: '', blockID: 12, textTermID: 0, visivel: true },
    { value: '', blockID: 17, textTermID: 0, visivel: true }
  ];

  public genericTermsGinecomastia: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 0, termID: 4688, cenario: 0, posicao: 0, linha: 0, term: '[LatDireita]', termView: 'slLateralidade' },
    { value: '', selected: false, lesionID: 0, termID: 4687, cenario: 0, posicao: 0, linha: 0, term: '[LatEsquerda]', termView: 'slLateralidade' },
    { value: '', selected: false, lesionID: 0, termID: 4685, cenario: 0, posicao: 0, linha: 0, term: '[ExtensaoDireita]', termView: 'inExtensaoDireita' },
    { value: '', selected: false, lesionID: 0, termID: 4686, cenario: 0, posicao: 0, linha: 0, term: '[ExtensaoEsquerda]', termView: 'inExtensaoEsquerda' },
    { value: '', selected: false, lesionID: 0, termID: 4684, cenario: 0, posicao: 0, linha: 0, term: '[Bilateralidade]', termView: 'slLateralidade' },
  ];

  public blocos: Array<any> = [
    {
      id: 'cabecalho',
      nome: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: this.mama.indicacaoID,
      nome: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: true
    },
    {
      id: this.ginecomastiaID,
      nome: 'ginecomastia',
      titulo: 'Ginecomastia',
      selected: false
    },
    {
      id: this.mama.noduloID,
      nome: 'nodulo',
      titulo: 'Nódulo',
      selected: false
    },
    {
      id: this.mama.cistoID,
      nome: 'cisto',
      titulo: 'Cisto',
      selected: false
    },
    {
      id: this.mama.linfonodomegaliaID,
      nome: 'linfonodo',
      titulo: 'Linfonodo',
      selected: false
    },
    {
      id: 'achados',
      nome: 'achados',
      subtitulo: 'Preencha o campo achados adicionais',
      titulo: 'Achados Acidionais',
      selected: false
    },
    {
      id: 'rodape',
      nome: 'rodape',
      titulo: 'Assinatura',
      selected: false,
    }
  ];
  editLaudoMode: boolean = false;
  constructor(
    private _selected: SelectedItemEmiter,
    private _copyPaste: CopyPaste,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _pouchService: PouchDBService,
    private _validacoes: Validacoes,
    private _dialog: MatDialog,
    private _common: CommonService,
    private _config: ConfigLaudoService,
    public mama: MamaComponent,
    private dataService:DataTermService,
    public _addText: AddText,
  ) { }

  ngOnInit(): void {
    this.utilizarEditor();
    this.subtitulo = 'Indicação';
    this.subtituloBloco = 'Preencha o campo indicação';
    this.term.listTermID = new Array<number>();

    this._pouchService.loadDatabase('mamaMascAnalise', this.descricoes, this.conclusoes);
    this.resetaDialog();

    const listBlocks = [
      this.mama.indicacaoID,
      this.mama.cistoID,
      this.mama.noduloID,
      this.mama.linfonodomegaliaID,
      this.ginecomastiaID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, 'mamaMascMask').then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, 'mamaMascMask');
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-mamaMascMask');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-mamaMascMask');
      }
    });

    let cmRule = JSON.parse(localStorage.getItem('cmRule'));
    this.mama.unidadeMedida = cmRule.active ? 'mm' : 'cm';
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  resetaDialog(): void {
    this.mama.resetaDialog();
    this._common.invokeEvent.subscribe(event => {
      if(event) {
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.resetaLaudo();
          }
        });
      }
    });
  }

  resetaLaudo(): void {
    this._pouchService.getAll('mamaMascAnalise').then((obj) => {
      var concCalc = 0;

      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != '' || obj[i].doc.value != undefined) {
          if(obj[i].doc.type == 'descricao') {
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == 'conclusao') {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    // this.mama.listaBirads = [];
    this.showForm = false;
    this.mama.listaBirads = [
      { blockID: 13, value: 0 },
      { blockID: 19, value: 0 },
      { blockID: 12, value: 0 },
      { blockID: 14, value: 0 },
      { blockID: 15, value: 0 },
      { blockID: 17, value: 0 },
      { blockID: 25, value: 0 },
      { blockID: 252, value: 0 }
    ];

    this.cabecalhoTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  }

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }

    this.cabecalho =  (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === this.mama.indicacaoID);
    this.cisto = (itemSelecionado.id === this.mama.cistoID);
    this.nodulo = (itemSelecionado.id === this.mama.noduloID);
    this.linfonodomegalia = (itemSelecionado.id === this.mama.linfonodomegaliaID);
    this.ginecomastia = (itemSelecionado.id === this.ginecomastiaID);
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape =  (itemSelecionado.id === 'rodape');
  }

  limparTermos(): void {
    this.term.listTermID = new Array<number>();

    this.genericTermsGinecomastia = [
      { value: '', selected: false, lesionID: 0, termID: 4688, cenario: 0, posicao: 0, linha: 0, term: '[LatDireita]', termView: 'slLateralidade' },
      { value: '', selected: false, lesionID: 0, termID: 4687, cenario: 0, posicao: 0, linha: 0, term: '[LatEsquerda]', termView: 'slLateralidade' },
      { value: '', selected: false, lesionID: 0, termID: 4685, cenario: 0, posicao: 0, linha: 0, term: '[ExtensaoDireita]', termView: 'inExtensaoDireita' },
      { value: '', selected: false, lesionID: 0, termID: 4686, cenario: 0, posicao: 0, linha: 0, term: '[ExtensaoEsquerda]', termView: 'inExtensaoEsquerda' },
      { value: '', selected: false, lesionID: 0, termID: 4684, cenario: 0, posicao: 0, linha: 0, term: '[Bilateralidade]', termView: 'slLateralidade' },
    ];
  }

  enviaTermos($event): void {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaAchados(texto): void {
    if (texto)
      this.mama.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.mama.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.mama.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  adicionaSelecaoGinecomastia($event): void {
    this.genericTermsGinecomastia.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        const textoSplit = $event.elemento.value.split('|', 2);
        const value = textoSplit[0];
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal(value);
      }
    });

    this.term.listTermID.push($event.elemento.id);
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.mama.term.listTermID, blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      if (this.indicacao) {
        this.mama.term.blockID = this.mama.indicacaoID;
        let desc;
        this._pouchService.getLaudos(this.mama.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.mama.indicacaoID && termResults[0] !== undefined) {
              this.mama.termResultsAPI = termResults;
              obj.textTermID = this.mama.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.mama.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.mama.indicacaoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

              if(!this.newEditor) {
                this.AdicionaTexto(this.newEditor);
              } else {
                this.atualizaLinha({
                  blockId: this.mama.indicacaoID,
                  descricao: desc,
                });
              }
            }
          });
        });
      } else if(this.ginecomastia) {
        this.term.blockID = this.ginecomastiaID;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.ginecomastiaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoGinecomastia(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.ginecomastiaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.ginecomastiaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoGinecomastia(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ginecomastiaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ginecomastiaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.ginecomastiaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if(this.cisto) {
        this.mama.term.blockID = this.mama.cistoID;

        this.mama.term.injuries.push(this.mama.injuries);
        this.mama.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.mama.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.mama.cistoID && termResults[0] !== undefined) {
              this.mama.termResultsAPI = termResults;
              obj.textTermID = this.mama.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.mama.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.mama.MontaDescricaoCisto(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.mama.cistoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.mama.cistoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.mama.termConclusionAPI = termResults;
          let espaco = '';
          let contadorTexto = 0;
          this.mama.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.mama.MontaConclusaoCisto(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.mama.listaBirads.find(x => x.blockID === this.mama.cistoID);
                birads.value = 0;
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.mama.cistoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.mama.cistoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.mama.cistoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if(this.linfonodomegalia) {
        this.mama.term.blockID = this.mama.linfonodomegaliaID;

        let desc;
        this._pouchService.getLaudos(this.mama.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.mama.linfonodomegaliaID && termResults[0] !== undefined) {
              this.mama.termResultsAPI = termResults;
              obj.textTermID = this.mama.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.mama.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.mama.MontaDescricaoLinfonodomegalia(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.mama.linfonodomegaliaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.mama.linfonodomegaliaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.mama.termConclusionAPI = termResults;
          let espaco = '';
          this.mama.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.mama.MontaConclusaoLinfonodomegalia(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.mama.listaBirads.find(x => x.blockID === this.mama.linfonodomegaliaID);
                birads.value = 0;
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.mama.linfonodomegaliaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.mama.linfonodomegaliaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.mama.linfonodomegaliaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if(this.nodulo) {
        this.mama.term.blockID = this.mama.noduloID;

        this.mama.term.injuries.push(this.mama.injuries);
        this.mama.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.mama.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.mama.noduloID && termResults[0] !== undefined) {
              this.mama.termResultsAPI = termResults;
              obj.textTermID = this.mama.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.mama.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.mama.MontaDescricaoNodulo(resultado.text, contadorTexto);
                espaco = '<br/>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.mama.noduloID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.mama.noduloID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.mama.termConclusionAPI = termResults;
          let espaco = '';
          this.mama.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.mama.noduloID;
              obj.value = obj.value + espaco + this.mama.MontaConclusaoNodulo(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.mama.listaBirads.find(x => x.blockID === this.mama.noduloID);
                birads.value = 0;
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.mama.noduloID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.mama.noduloID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.mama.noduloID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  MontaDescricaoGinecomastia(texto: string, contador: any): string {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (
        +term === 4688
        || +term === 4687
        || +term === 4686
        || +term === 4685
        || +term === 4684
      ) {
        this.genericTermsGinecomastia.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoGinecomastia(texto: string): string {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (
        +term === 4688
        || +term === 4687
        || +term === 4686
        || +term === 4685
        || +term === 4684
      ) {
        this.genericTermsGinecomastia.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            const textoSplit = textoRetorno.split('|', 2);
            texto = textoSplit[0];
          }
        });
      }
    });

    return texto;
  }

  RetornaConclusaoNull(Texto): string {
    let texto = '';

    texto = 'Estudo ultrassonográfico normal.';

    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<div style="text-align: center;"><b>ULTRASSONOGRAFIA DAS MAMAS</br><br></b></div>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-${this.mama.indicacaoID}`,
        'name': ''
      },
      'data': {
        'text': `<p><b>INDICAÇÃO:</b></br>${this.descricoes[0].value}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.mama.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.mama.unidadeMedida);
      descricao.value = newValue2;
    });
    this.descricoes.forEach(descricao => {
      if (descricao.blockID) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.mama.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let ultimoBiRads = 0;
    let maskSplit = this.mask.split('|')

    //Monta texto exame
    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.descricoes[0].value;
      //Lógica Rules
      if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '</br></br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.';

    this.htmlContent += maskSplit[1];

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.mama.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.mama.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }

    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        if (i > 0 && i < this.descricoes.length - 1) {
          this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
        }
      }
    }

    this.htmlContent += '<br/>' + this.descricoes[this.descricoes.length - 1].value + '<br/>';
    this.htmlContent += maskSplit[2];

    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        if (i >= 0 && i < this.conclusoes.length - 1) {
          this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
        }
      }
    }

    this.htmlContent += '<br/>' + this.conclusoes[this.conclusoes.length - 1].value + '<br/>';

    if (this.conclusoes[0].value === '') {
      this.mama.listaBirads.forEach(obj => {
        if (obj.value > ultimoBiRads) {
          ultimoBiRads = obj.value;
        }
      });

      if(ultimoBiRads !== 0) {
        this.htmlContent += '<br/>' +'BI-RADS: ' + ultimoBiRads + '<br/>';
      }
    }

    this.htmlContent += this.mama.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId} p`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId} p`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  enviarMrID(): number {
    return this.medicalReportTypeID;
  }


  auditoriaUsuario(): void {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string): void {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
