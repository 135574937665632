import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { Validacoes } from '../../../../../../utils/validacoes';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';


@Component({
  selector: 'app-cistos-mao',
  templateUrl: './cistos-mao.component.html',
  styleUrls: ['./cistos-mao.component.css']
})
export class CistosMaoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('ckCistoArtro') ckCistoArtro: MatCheckbox;
  @ViewChild('ckCistoPeri') ckCistoPeri: MatCheckbox;

  @ViewChildren('cistoArtroChildren') cistoArtroChildren: QueryList<any>;
  @ViewChildren('cistoPeriChildren') cistoPeriChildren: QueryList<any>;

  localizacoes: ArrayDropDown[] = [
    { value: 'dorsal', viewValue: 'Dorsal' },
    { value: 'volar', viewValue: 'Volar' },
    { value: 'radial', viewValue: 'Radial' },
    { value: 'ulnar', viewValue: 'Ulnar' },
  ];

  articulacoes: ArrayDropDown[] = [
    { value: 'metacarpofalângica', viewValue: 'Metacarpofalângica' },
    { value: 'interfalângica proximal', viewValue: 'Interfalângica proximal' },
    { value: 'interfalângica distal', viewValue: 'Interfalângica distal' },
    { value: 'interfalângica', viewValue: 'Interfalângica' }
  ];

  dedos: ArrayDropDown[] = [
    { value: '1º', viewValue: '1º' },
    { value: '2º', viewValue: '2º' },
    { value: '3º', viewValue: '3º' },
    { value: '4º', viewValue: '4º' },
    { value: '5º', viewValue: '5º' }
  ];

  niveis: ArrayDropDown[] = [
    { value: 'do metacarpo', viewValue: 'Metacarpo' },
    { value: 'da falange proximal', viewValue: 'Falange proximal' },
    { value: 'da falange média', viewValue: 'Falange média' },
    { value: 'da falange distal', viewValue: 'Falange distal' },
    { value: 'da articulação metacarpofalângica', viewValue: 'Articulação metacarpofalângica' },
    { value: 'da articulação interfalângica proximal', viewValue: 'Articulação interfalângica proximal' },
    { value: 'da articulação interfalângica distal', viewValue: 'Articulação interfalângica distal' },
    { value: 'da articulação interfalângica', viewValue: 'Articulação interfalângica' }
  ];

  tendoes: ArrayDropDown[] = [
    { value: 'dos tendões flexores', viewValue: 'Tendões flexores' },
    { value: 'do complexo extensor', viewValue: 'Complexo extensor' }
  ];

  lesoesForm: FormGroup;
  showAndHideTerms: any;
  linha = 0;
  objeto: any;
  objetofilho: any;
  countLesoes = 0;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes, private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true,
      sahCistoArtro: false,
      sahPlusCistoArtro: false,
      sahCistoPeri: false,
      sahPlusCistoPeri: false,
    };

    this.lesoesForm = this._fb.group({
      cistoArtro: this._fb.array([this.addCistoArtroGrup()]),
      cistoPeri: this._fb.array([this.addCistoPeriGrup()])
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  //CISTO ARTROSSINOVIAL
  addCistoArtroGrup() {
    return this._fb.group({
      localizacao: [],
      articulacao: [],
      dedo: [],
      medida: []
    });
  }

  addCistoArtro() {
    this.cistoArtroArray.push(this.addCistoArtroGrup());
    this.catchIDs();
  }

  removeCistoArtro(index) {
    this.cistoArtroArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get cistoArtroArray() {
    return <FormArray>this.lesoesForm.get('cistoArtro');
  }

  //CISTO PERITENDINEO
  addCistoPeriGrup() {
    return this._fb.group({
      localizacao: [],
      nivel: [],
      tendao: [],
      dedo: [],
      medida: []
    });
  }

  addCistoPeri() {
    this.cistoPeriArray.push(this.addCistoPeriGrup());
    this.catchIDs();
  }

  removeCistoPeri(index) {
    this.cistoPeriArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get cistoPeriArray() {
    return <FormArray>this.lesoesForm.get('cistoPeri');
  }

  clickAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.showAndHideTerms.sahCistoArtro = false;
    this.showAndHideTerms.sahCistoPeri = false;
    if(this.ckCistoArtro.checked) {
      this.ckCistoArtro.checked = false;
    }
    if(this.ckCistoPeri.checked) {
      this.ckCistoPeri.checked = false;
    }
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    // this.ajustaArrayDinamico();
    // this.catchIDs();
  }

  clickCistoArtro() {
    this.rdPresente.checked = true;
    if (this.showAndHideTerms.sahCistoArtro == false) {
      this.showAndHideTerms.sahCistoArtro = true;
      this.showAndHideTerms.sahCistoArtro = true;
    } else {
      this.showAndHideTerms.sahCitsoArtro = false;
      this.showAndHideTerms.sahCistoArtro = false;
    }

    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickCistoPeri() {
    this.rdPresente.checked = true;
    if (this.showAndHideTerms.sahCistoPeri == false) {
      this.showAndHideTerms.sahCistoPeri = true;
      this.showAndHideTerms.sahPlusCistoPeri = true;
    } else {
      this.showAndHideTerms.sahCistoPeri = false;
      this.showAndHideTerms.sahPlusCistoPeri = false;
    }

    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.countLesoes = 0;

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente.checked) {

      if (this.ckCistoArtro != undefined && this.ckCistoArtro.checked) {
        this.termoSelecionado.emit(this.ckCistoArtro.id);
        this.cistoArtro();
      }

      if (this.ckCistoPeri != undefined && this.ckCistoPeri.checked) {
        //this.termoSelecionado.emit(this.ckDoisNodulosNaoSem.id);
        if (this.countLesoes > 0) {
          this.cistoPeriLesao();
        } else {
          this.cistoPeri();
        }
      }
    }

    this.listaCompleta.emit();
  }

  cistoArtro(){
    this.countLesoes++;

    let posicao = 0;
    this.linha = 0;

    this.cistoArtroChildren.forEach(obj => {

      if (obj.nativeElement != undefined) {

        if (obj.nativeElement.id == 4661) {
          if (posicao < 3) {
            posicao++;
          } else {
            posicao = 1;
          }
        } else {
          posicao = 0;
        }

        this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
        this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
        this.lesoesDinamicas.emit(this.objeto);
      } else {

        if (obj.id == 4658) {
          this.linha++;
        }

        this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
        this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
        this.lesoesDinamicas.emit(this.objeto);
      }
    });
  }


  cistoPeri(){
    this.countLesoes++;

    this.termoSelecionado.emit(this.ckCistoPeri.id);

    let posicao = 0;
    this.linha = 0;

    this.cistoPeriChildren.forEach(obj => {

      if (obj.nativeElement != undefined) {

        if (obj.nativeElement.id == 4668) {
          if (posicao < 3) {
            posicao++;
          } else {
            posicao = 1;
          }
        } else {
          posicao = 0;
        }

        this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
        this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
        this.lesoesDinamicas.emit(this.objeto);
      } else {

        if (obj.id == 4664) {
          this.linha++;
        }

        this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
        this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
        this.lesoesDinamicas.emit(this.objeto);
      }
    });
  }


  cistoPeriLesao() {
    this.countLesoes++;
    const objeto = { id: this.ckCistoPeri.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);

    let posicao = 0;
    this.linha = 0;

    this.cistoPeriChildren.forEach(obj => {

      if (obj.nativeElement != undefined) {

        if (obj.nativeElement.id == 4668) {
          if (posicao < 3) {
            posicao++;
          } else {
            posicao = 1;
          }
        } else {
          posicao = 0;
        }

        this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
        this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
        this.lesoesDinamicas.emit(this.objeto);
      } else {

        if (obj.id == 4664) {
          this.linha++;
        }

        this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
        this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
        this.lesoesDinamicas.emit(this.objeto);
      }
    });
  }

  ajustaArrayDinamico() {

    if ((this.ckCistoArtro !== undefined && !this.ckCistoArtro.checked) || !this.rdPresente.checked) {
      // this.showAndHideTerms.sahDoisNoduloSem = false;

      const arrayTotal = this.cistoArtroArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.cistoArtroArray.length - 1

        if (index != 0) {
          this.cistoArtroArray.removeAt(index);
        }
      }
      this.cistoArtroArray.reset();
    }

    if ((this.ckCistoPeri !== undefined && !this.ckCistoPeri.checked) || !this.rdPresente.checked) {
      // this.showAndHideTerms.sahDoisNodulosNaoSem = false;

      const arrayTotal = this.cistoPeriArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.cistoPeriArray.length - 1

        if (index != 0) {
          this.cistoPeriArray.removeAt(index);
        }
      }
      this.cistoPeriArray.reset();
    }
  }
}
