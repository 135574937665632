import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { CadastroComponent } from './cadastro/cadastro.component';
import { AuthGuard } from './auth/auth.guard';
import { EsqueceuSenhaComponent } from './login/esqueceu-senha/esqueceu-senha.component';
import { LaudosComponent } from './laudos/laudos.component';
import { AbdomeTotalComponent } from './laudos/us/modo-b/abdome-total/abdome-total.component';
import { MamaComponent } from './laudos/us/modo-b/mama/mama.component';
import { MamaMasculinaComponent } from './laudos/us/modo-b/mama-masculina/mama-masculina.component';
import { TransvaginalComponent } from './laudos/us/modo-b/transvaginal/transvaginal.component';
import { CervicalComponent } from './laudos/us/modo-b/cervical/cervical.component';
import { ProstataComponent } from './laudos/us/modo-b/prostata/prostata.component';
import { AbdomeSuperiorComponent } from './laudos/us/modo-b/abdome-superior/abdome-superior.component';
import { HipocondrioDireitoComponent } from './laudos/us/modo-b/hipocondrio-direito/hipocondrio-direito.component';
import { RinsViasComponent } from './laudos/us/modo-b/rins-vias/rins-vias.component';
import { TireoideComponent } from './laudos/us/modo-b/tireoide/tireoide.component';
import { GlandulasSalivaresComponent } from './laudos/us/modo-b/glandulas-salivares/glandulas-salivares.component';
import { PelvicoComponent } from './laudos/us/modo-b/pelvico/pelvico.component';
import { TesticuloComponent } from './laudos/us/modo-b/testiculo/testiculo.component';
import { TesticuloDopplerComponent } from './laudos/us/doppler/testiculo-doppler/testiculo-doppler.component';
import { ParedeAbdominalComponent } from './laudos/us/modo-b/parede-abdominal/parede-abdominal.component';
import { RegiaoInguinalComponent } from './laudos/us/modo-b/regiao-inguinal/regiao-inguinal.component';
import { PartesMolesComponent } from './laudos/us/modo-b/partes-moles/partes-moles.component';
import { TireoideDopplerComponent } from './laudos/us/doppler/tireoide-doppler/tireoide-doppler.component';
import { CarotidasComponent } from './laudos/us/doppler/carotidas/carotidas.component';
import { AbdomeDopplerComponent } from './laudos/us/doppler/abdome-doppler/abdome-doppler.component';
import { IntegrationComponent } from './integration/integration.component';
import { PunhoComponent } from './laudos/us/musculoesqueletico/punho/punho.component';
import { AbdomeSuperiorDopplerComponent } from './laudos/us/doppler/abdome-superior-doppler/abdome-superior-doppler.component';
import { OmbroComponent } from './laudos/us/musculoesqueletico/ombro/ombro.component';
import { JoelhoComponent } from './laudos/us/musculoesqueletico/joelho/joelho.component';
import { MusculosTendoesComponent } from './laudos/us/musculoesqueletico/musculos-tendoes/musculos-tendoes.component';
import { QuadrilComponent } from './laudos/us/musculoesqueletico/quadril/quadril.component';
import { TornozeloComponent } from './laudos/us/musculoesqueletico/tornozelo/tornozelo.component';
import { MaoComponent } from './laudos/us/musculoesqueletico/mao/mao.component';
import { CotoveloComponent } from './laudos/us/musculoesqueletico/cotovelo/cotovelo.component';
import { ToraxComponent } from './laudos/tc/torax/torax.component';
import { PeComponent } from './laudos/us/musculoesqueletico/pe/pe.component';
import { SenhaConfirmacaoComponent } from './login/senha-confirmacao/senha-confirmacao.component';
import { TransfontanelarComponent } from './laudos/us/modo-b/transfontanelar/transfontanelar.component';
import { ToraxUsComponent } from './laudos/us/modo-b/torax-us/torax-us.component';
import { ToraxRsnaComponent } from './laudos/tc/torax-rsna/torax-rsna.component';
import { MenuLateralComponent } from './menu-lateral/menu-lateral.component';
import { PlanosComponent } from './planos/planos.component';
import { TransvPrimeiroTrimestreComponent } from './laudos/us/obstetrico/transv-primeiro-trimestre/transv-primeiro-trimestre.component';
import { TransvSegTercTrimestreComponent } from './laudos/us/obstetrico/transv-seg-terc-trimestre/transv-seg-terc-trimestre.component';
import { ArteriAortaComponent } from './laudos/us/doppler/arteri-aorta/arteri-aorta.component';
import { PerfilComponent } from './perfil/perfil.component';
import { TransvSegTercDopplerComponent } from './laudos/us/obstetrico/transv-seg-terc-doppler/transv-seg-terc-doppler.component';
import { ArterialMembroSuperiorComponent } from './laudos/us/doppler/arterial-membro-superior/arterial-membro-superior.component';
import { VenosoMembroComponent } from './laudos/us/doppler/venoso-membro/venoso-membro.component';
import { AortaIliacaComponent } from './laudos/us/doppler/aorta-iliaca/aorta-iliaca.component';
import { ListaLaudoComponent } from './lista-laudo/lista-laudo.component';
import { DetalharLaudoComponent } from './lista-laudo/detalhar-laudo/detalhar-laudo.component';
import { ArteriaDoMembroInferiorComponent } from './laudos/us/doppler/arteria-do-membro-inferior/arteria-do-membro-inferior.component';
import { VenosoMembroSuperiorComponent } from './laudos/us/doppler/venoso-membro-superior/venoso-membro-superior.component';
import { VarizesComponent } from './laudos/us/doppler/varizes/varizes.component';
import { TransvDopplerComponent } from './laudos/us/doppler/transv-doppler/transv-doppler.component';
import { CervicalDopplerComponent } from './laudos/us/doppler/cervical-doppler/cervical-doppler.component';
import { ConfigLaudoComponent } from './config-laudo/config-laudo.component';
import { SuporteComponent } from './suporte/suporte.component';
import { AlteraLaudoComponent } from './altera-laudo/altera-laudo.component';
import { PenisComponent } from './laudos/us/modo-b/penis/penis.component';
import { QuadrilInfantilComponent } from './laudos/us/musculoesqueletico/quadril-infantil/quadril-infantil.component';
import { AlteraLaudoListComponent } from './altera-laudo-list/altera-laudo-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MamografiaComponent } from './laudos/mg/mamografico/mamografia/mamografia.component';
import { PagamentosRealizadosComponent } from './pagamentos-realizados/pagamentos-realizados.component';
import { PagRelDetalhesComponent } from './pagamentos-realizados/pag-rel-detalhes/pag-rel-detalhes.component';
import { AlteraPagamentoComponent } from './altera-pagamento/altera-pagamento.component';
import { VersaoComponent } from './versao/versao.component';
import { MenuExpansivo } from './manual-de-uso/manual-de-uso.component';
import { AxilasComponent } from './laudos/us/modo-b/axilas/axilas.component';
import { CervicometriaComponent } from './laudos/us/obstetrico/cervicometria/cervicometria.component';
import { CranioComponent } from './laudos/tc/cranio/cranio.component';
import { MorfologicoSegundoTrimestreComponent } from './laudos/us/obstetrico/morfologico-segundo-trimestre/morfologico-segundo-trimestre.component';
import { UsgMorfologicaPrimeiroTrimestreComponent } from './laudos/us/obstetrico/usg-morfologica-primeiro-trimestre/usg-morfologica-primeiro-trimestre.component';
import { OmbroBilateralComponent } from './laudos/us/musculoesqueletico/ombro-bilateral/ombro-bilateral.component';
import { PunhoBilateralComponent } from './laudos/us/musculoesqueletico/punho-bilateral/punho-bilateral.component';
import { UsgMorfoPrimDopplerComponent } from './laudos/us/obstetrico/usg-morfo-prim-doppler/usg-morfo-prim-doppler.component';
import { PeBilateralComponent } from './laudos/us/musculoesqueletico/pe-bilateral/pe-bilateral.component';
import { JoelhoBilateralComponent } from './laudos/us/musculoesqueletico/joelho-bilateral/joelho-bilateral.component';
import { MaoBilateralComponent } from './laudos/us/musculoesqueletico/mao-bilateral/mao-bilateral.component';
import { TornozeloBilateralComponent } from './laudos/us/musculoesqueletico/tornozelo-bilateral/tornozelo-bilateral.component';
import { MusculosTendoesBilateralComponent } from './laudos/us/musculoesqueletico/musculos-tendoes-bilateral/musculos-tendoes-bilateral.component';
import { CotoveloBilateralComponent } from './laudos/us/musculoesqueletico/cotovelo-bilateral/cotovelo-bilateral.component';
import { QuadrilBilateralComponent } from './laudos/us/musculoesqueletico/quadril-bilateral/quadril-bilateral.component';
import { QuadrilInfantilBilateralComponent } from './laudos/us/musculoesqueletico/quadril-infantil-bilateral/quadril-infantil-bilateral.component';
import { AbdomeTotalPelvicoComponent } from './laudos/us/modo-b/abdome-total-pelvico/abdome-total-pelvico.component';
import { MamaDopplerComponent } from './laudos/us/doppler/mama-doppler/mama-doppler.component';
import { AbdomeTotalProstataComponent } from './laudos/us/modo-b/abdome-total-prostata/abdome-total-prostata.component';
import { RinsPelvicoModule } from './laudos/us/modo-b/rins-pelvico/rins-pelvico.module';
import { RinsPelvicoComponent } from './laudos/us/modo-b/rins-pelvico/rins-pelvico.component';
import { RinsProstataComponent } from './laudos/us/modo-b/rins-prostata/rins-prostata.component';
import { EmailConfirmacaoComponent } from './cadastro/email-confirmacao/email-confirmacao.component';
import { AbdomeTotalRefatoradoComponent } from './laudos/us/modo-b/abdome-total-refatorado/abdome-total-refatorado.component';
import { MorfologicoSegundoTrimestreDopplerComponent } from './laudos/us/doppler/morfologico-segundo-trimestre-doppler/morfologico-segundo-trimestre-doppler.component';
import { LaudoPorVozComponent } from './laudo-por-voz/laudo-por-voz.component';
import { MemberGetMemberComponent } from './member-get-member/member-get-member.component';
import { PenisDopplerComponent } from './laudos/us/doppler/penis-doppler/penis-doppler.component';
import { SeiosDaFaceComponent } from './laudos/rx/seios-da-face/seios-da-face.component';
import { RxCranioComponent } from './laudos/rx/rx-cranio/rx-cranio.component';
import { RxToraxComponent } from './laudos/rx/rx-torax/rx-torax.component';
import { RxArcosComponent } from './laudos/rx/rx-arcos/rx-arcos.component';
import { RxAbdomeComponent } from './laudos/rx/rx-abdome/rx-abdome.component';
import { RxAtmComponent } from './laudos/rx/rx-atm/rx-atm.component';
import { RxColunaToracicaComponent } from './laudos/rx/rx-coluna-toracica/rx-coluna-toracica.component';
import { RxSacroComponent } from './laudos/rx/rx-sacro/rx-sacro.component';
import { RxColunaLombarComponent } from './laudos/rx/rx-coluna-lombar/rx-coluna-lombar.component';

const routes: Routes = [
  {
    path: 'cadastro',
    component: CadastroComponent,
    children: [
      {
        path: 'cadastro/:token',
        component: CadastroComponent,
        pathMatch: 'full'
      },
      {
        path: 'cadastro/:isPayment',
        component: CadastroComponent,
        pathMatch: 'full'
      },
      {
        path: 'cadastro/:usuario',
        component: CadastroComponent,
        pathMatch: 'full'
      },
      {
        path: 'cadastro',
        component: CadastroComponent,
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '',
    component: LoginComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      }
    ]
  },
  {
    path: 'recuperarSenha',
    component: EsqueceuSenhaComponent,
    pathMatch: 'full'
  },
  {
    path: 'senhaConfirmacao/:id',
    component: SenhaConfirmacaoComponent,
    pathMatch: 'full'
  },
  {
    path: 'perfil',
    component: PerfilComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'perfil',
        component: PerfilComponent,
        pathMatch: 'full',
      },
    ]
  },
  {
    path: 'memberGetMember',
    component: MemberGetMemberComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'memberGetMember',
        component: MemberGetMemberComponent,
      },
    ]
  },
  {
    path: 'laudo-por-voz',
    component: LaudoPorVozComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudo-por-voz',
        component: LaudoPorVozComponent,
        pathMatch: 'full',
      },
    ]
  },
  {
    path: 'laudos',
    component: LaudosComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos',
        component: LaudosComponent,
        pathMatch: 'full',
      },
    ]
  },
  {
    path: 'laudos/abdomeTotal',
    component: AbdomeTotalComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/abdomeTotal',
        component: AbdomeTotalComponent,
        pathMatch: 'full',
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/abdomeTotal2',
    component: AbdomeTotalRefatoradoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/abdomeTotal2',
        component: AbdomeTotalRefatoradoComponent,
        pathMatch: 'full',
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/abdomeTotalPelvico',
    component: AbdomeTotalPelvicoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/abdomeTotalPelvico',
        component: AbdomeTotalPelvicoComponent,
        pathMatch: 'full',
        data: {
          roles: [1]
        },
      }
    ]
  },
  {
    path: 'laudos/abdomeTotalProstata',
    component: AbdomeTotalProstataComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/abdomeTotalProstata',
        component: AbdomeTotalProstataComponent,
        pathMatch: 'full',
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/rinsPelvico',
    component: RinsPelvicoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/rinsPelvico',
        component: RinsPelvicoComponent,
        pathMatch: 'full',
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/rinsProstata',
    component: RinsProstataComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/rinsProstata',
        component: RinsProstataComponent,
        pathMatch: 'full',
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/mama',
    component: MamaComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/mama',
        component: MamaComponent,
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/mamaMasculina',
    component: MamaMasculinaComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/mamaMasculina',
        component: MamaMasculinaComponent,
      },
    ]
  },
  {
    path: 'laudos/mamaDoppler',
    component: MamaDopplerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/mamaDoppler',
        component: MamaDopplerComponent,
        data: {
          roles: [2]
        }
      },
    ]
  },
  {
    path: 'laudos/transvaginal',
    component: TransvaginalComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/transvaginal',
        component: TransvaginalComponent,
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/morfoSeg',
    component: MorfologicoSegundoTrimestreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/morfoSeg',
        component: MorfologicoSegundoTrimestreComponent,
        data: {
          roles: [4]
        }
      },
    ]
  },
  {
    path: 'laudos/morfoSegDoppler',
    component: MorfologicoSegundoTrimestreDopplerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/morfoSegDoppler',
        component: MorfologicoSegundoTrimestreDopplerComponent,
        data: {
          roles: [4]
        }
      },
    ]
  },
  {
    path: 'laudos/cervical',
    component: CervicalComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/cervical',
        component: CervicalComponent,
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/prostata',
    component: ProstataComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/prostata',
        component: ProstataComponent,
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/abdomeSuperior',
    component: AbdomeSuperiorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/abdomeSuperior',
        component: AbdomeSuperiorComponent,
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/hipocondrioDireito',
    component: HipocondrioDireitoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/hipocondrioDireito',
        component: HipocondrioDireitoComponent,
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/rinsVias',
    component: RinsViasComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/rinsVias',
        component: RinsViasComponent,
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/tireoide',
    component: TireoideComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/tireoide',
        component: TireoideComponent,
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/glandulasSalivares',
    component: GlandulasSalivaresComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/glandulasSalivares',
        component: GlandulasSalivaresComponent,
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/pelvico',
    component: PelvicoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/pelvico',
        component: PelvicoComponent,
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/testiculo',
    component: TesticuloComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/testiculo',
        component: TesticuloComponent,
        data: {
          roles: [1]
        }
      },
    ]
  }, {
    path: 'laudos/penis',
    component: PenisComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/penis',
        component: PenisComponent,
        data: {
          roles: [1]
        }
      },
    ]
  }, {
    path: 'laudos/testiculoDoppler',
    component: TesticuloDopplerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/testiculoDoppler',
        component: TesticuloDopplerComponent,
        data: {
          roles: [2]
        }
      },
    ]
  },
  {
    path: 'laudos/paradeAbdominal',
    component: ParedeAbdominalComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/paradeAbdominal',
        component: ParedeAbdominalComponent,
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/regiaoInguinal',
    component: RegiaoInguinalComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/regiaoInguinal',
        component: RegiaoInguinalComponent,
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/partesMoles',
    component: PartesMolesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/partesMoles',
        component: PartesMolesComponent,
        data: {
          roles: [1]
        }
      },
    ]
  },
  {
    path: 'laudos/tireoideDoppler',
    component: TireoideDopplerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/tireoideDoppler',
        component: TireoideDopplerComponent,
        data: {
          roles: [2]
        }
      },
    ]
  },
  {
    path: 'laudos/carotidas',
    component: CarotidasComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/carotidas',
        component: CarotidasComponent,
        data: {
          roles: [2]
        }
      },
    ]
  },
  {
    path: 'laudos/abdomeDoppler',
    component: AbdomeDopplerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/abdomeDoppler',
        component: AbdomeDopplerComponent,
        data: {
          roles: [2]
        }
      },
    ]
  },
  {
    path: 'laudos/abdomeSuperiorDoppler',
    component: AbdomeSuperiorDopplerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/abdomeSuperiorDoppler',
        component: AbdomeSuperiorDopplerComponent,
        data: {
          roles: [2]
        }
      },
    ]
  },
  {
    path: 'laudos/transv-doppler',
    component: TransvDopplerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/transv-doppler',
        component: TransvDopplerComponent,
      },
    ]
  },
  {
    path: 'laudos/cervical-doppler',
    component: CervicalDopplerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/cervical-doppler',
        component: CervicalDopplerComponent,
        data: {
          roles: [2]
        }
      },
    ]
  },
  {
    path: 'laudos/punho',
    component: PunhoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/punho',
        component: PunhoComponent,
        data: {
          roles: [3]
        }
      },
    ]
  },
  {
    path: 'laudos/punhoBilateral',
    component: PunhoBilateralComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/punhoBilateral',
        component: PunhoBilateralComponent,
        data: {
          roles: [3]
        }
      },
    ]
  },
  {
    path: 'laudos/ombro',
    component: OmbroComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/ombro',
        component: OmbroComponent,
        data: {
          roles: [3]
        }
      },
    ]
  },
  {
    path: 'laudos/ombroBilateral',
    component: OmbroBilateralComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/ombroBilateral',
        component: OmbroBilateralComponent,
        data: {
          roles: [3]
        }
      },
    ]
  },
  {
    path: 'laudos/joelho',
    component: JoelhoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/joelho',
        component: JoelhoComponent,
        data: {
          roles: [3]
        }
      },
    ]
  },
  {
    path: 'laudos/joelhoBilateral',
    component: JoelhoBilateralComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/joelhoBilateral',
        component: JoelhoBilateralComponent,
        data: {
          roles: [3]
        }
      },
    ]
  },
  {
    path: 'laudos/quadril',
    component: QuadrilComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/quadril',
        component: JoelhoComponent,
        data: {
          roles: [3]
        }
      }
    ]
  },
  {
    path: 'laudos/quadrilBilateral',
    component: QuadrilBilateralComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/quadrilBilateral',
        component: QuadrilBilateralComponent,
        data: {
          roles: [3]
        }
      },
    ]
  },
  {
    path: 'laudos/tornozelo',
    component: TornozeloComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/tornozelo',
        component: TornozeloComponent,
        data: {
          roles: [3]
        }
      },
    ]
  },
  {
    path: 'laudos/tornozeloBilateral',
    component: TornozeloBilateralComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/tornozeloBilateral',
        component: TornozeloBilateralComponent,
        data: {
          roles: [3]
        }
      },
    ]
  },
  {
    path: 'laudos/musculosTendoes',
    component: MusculosTendoesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/musculosTendoes',
        component: MusculosTendoesComponent,
        data: {
          roles: [3]
        }
      }
    ]
  },
  {
    path: 'laudos/musculosTendoesBilateral',
    component: MusculosTendoesBilateralComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/musculosTendoesBilateral',
        component: MusculosTendoesBilateralComponent,
        data: {
          roles: [3]
        }
      },
    ]
  },
  {
    path: 'laudos/mao',
    component: MaoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/mao',
        component: MaoComponent,
        data: {
          roles: [3]
        }
      }
    ]
  },
  {
    path: 'laudos/maoBilateral',
    component: MaoBilateralComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/maoBilateral',
        component: MaoBilateralComponent,
        data: {
          roles: [3]
        }
      },
    ]
  },
  {
    path: 'laudos/quadrilInfantil',
    component: QuadrilInfantilComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/quadrilInfantil',
        component: QuadrilInfantilComponent,
        data: {
          roles: [3]
        }
      }
    ]
  },
  {
    path: 'laudos/quadrilInfantilBilateral',
    component: QuadrilInfantilBilateralComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/quadrilInfantilBilateral',
        component: QuadrilInfantilBilateralComponent,
        data: {
          roles: [3]
        }
      }
    ]
  },
  {
    path: 'laudos/transfontanela',
    component: TransfontanelarComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/transfontanela',
        component: TransfontanelarComponent,
        data: {
          roles: [1]
        }
      }
    ]
  },
  {
    path: 'laudos/arteriasRenais',
    component: ArteriAortaComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/arteriasRenais',
        component: ArteriAortaComponent,
        data: {
          roles: [2]
        }
      }
    ]
  },
  {
    path: 'laudos/tvpInferior',
    component: VenosoMembroComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/tvpInferior',
        component: VenosoMembroComponent,
        data: {
          roles: [2]
        }
      }
    ]
  },
  {
    path: 'laudos/arterialInferior',
    component: ArteriaDoMembroInferiorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/arterialInferior',
        component: ArteriaDoMembroInferiorComponent,
        data: {
          roles: [2]
        }
      }
    ]
  },
  {
    path: 'laudos/tvpsuperior',
    component: VenosoMembroSuperiorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/tvpsuperior',
        component: VenosoMembroSuperiorComponent,
        data: {
          roles: [2]
        }
      }
    ]
  },
  {
    path: 'laudos/cotovelo',
    component: CotoveloComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/cotovelo',
        component: CotoveloComponent,
        data: {
          roles: [3]
        }
      }
    ]
  },
  {
    path: 'laudos/cotoveloBilateral',
    component: CotoveloBilateralComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/cotoveloBilateral',
        component: CotoveloBilateralComponent,
        data: {
          roles: [3]
        }
      },
    ]
  },
  {
    path: 'laudos/pe',
    component: PeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/pe',
        component: PeComponent,
        data: {
          roles: [3]
        }
      }
    ]
  },
  {
    path: 'laudos/peBilateral',
    component: PeBilateralComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/peBilateral',
        component: PeBilateralComponent,
        data: {
          roles: [3]
        }
      },
    ]
  },
  {
    path: 'laudos/obsTransPrimeiro',
    component: TransvPrimeiroTrimestreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/obsTransPrimeiro',
        component: TransvPrimeiroTrimestreComponent,
        data: {
          roles: [4]
        }
      }
    ]
  },
  {
    path: 'laudos/obsTransSegTerc',
    component: TransvSegTercTrimestreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/obsTransSegTerc',
        component: TransvSegTercTrimestreComponent,
        data: {
          roles: [4]
        }
      }
    ]
  },
  {
    path: 'laudos/obsTransSegTercDoppler',
    component: TransvSegTercDopplerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/obsTransSegTercDoppler',
        component: TransvSegTercDopplerComponent,
        data: {
          roles: [4]
        }
      }
    ]
  },
  {
    path: 'laudos/cervicometria',
    component: CervicometriaComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/cervicometria',
        component: CervicometriaComponent,
        data: {
          roles: [4]
        }
      },

    ]
  },
  {
    path: 'laudos/aortaIliacas',
    component: AortaIliacaComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/aortaIliacas',
        component: AortaIliacaComponent,
        data: {
          roles: [2]
        }
      }
    ]
  },
  {
    path: 'laudos/varizes',
    component: VarizesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/varizes',
        component: VarizesComponent,
        data: {
          roles: [2]
        }
      }
    ]
  },
  {
    path: 'laudos/toraxUs',
    component: ToraxUsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/toraxUs',
        component: ToraxUsComponent,
        data: {
          roles: [1]
        }
      }
    ]
  },
  {
    path: 'laudos/arterialSuperior',
    component: ArterialMembroSuperiorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/arterialSuperior',
        component: ArterialMembroSuperiorComponent,
        data: {
          roles: [2]
        }
      }
    ]
  },
  {
    path: 'laudos/axilas',
    component: AxilasComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/axilas',
        component: AxilasComponent,
        data: {
          roles: [1]
        }
      }
    ]
  },
  {
    path: 'laudos/toraxCovid',
    component: ToraxComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/toraxCovid',
        component: ToraxComponent,
        data: {
          roles: [5]
        }
      }
    ]
  },
  {
    path: 'laudos/toraxCovidRsna',
    component: ToraxRsnaComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/toraxCovidRsna',
        component: ToraxRsnaComponent,
        data: {
          roles: [5]
        }
      }
    ]
  },
  {
    path: 'laudos/cranio',
    component: CranioComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/cranio',
        component: CranioComponent,
        data: {
          roles: [5]
        }
      }
    ]
  },
  {
    path: 'laudos/morfoPrim',
    component: UsgMorfologicaPrimeiroTrimestreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/morfoPrim',
        component: UsgMorfologicaPrimeiroTrimestreComponent,
        data: {
          roles: [4]
        }
      }
    ]
  },
  {
    path: 'laudos/morfoPrimDoppler',
    component: UsgMorfoPrimDopplerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/morfoPrimDoppler',
        component: UsgMorfoPrimDopplerComponent,
        data: {
          roles: [4]
        }
      }
    ]
  },
  {
    path: 'laudos/mamografia',
    component: MamografiaComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/mamografia',
        component: MamografiaComponent,
        data: {
          roles: [6]
        }
      },
    ]
  },
  {
    path: 'laudos/penisDoppler',
    component: PenisDopplerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/penisDoppler',
        component: PenisDopplerComponent,
        data: {
          roles: [2]
        }
      }
    ]
  },
  {
    path: 'laudos/seiosDaFace',
    component: SeiosDaFaceComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/seiosDaFace',
        component: SeiosDaFaceComponent,
        data: {
          roles: [7]
        }
      },
    ]
  },
  {
    path: 'laudos/rxCranio',
    component: RxCranioComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/rxCranio',
        component: RxCranioComponent,
        data: {
          roles: [7]
        }
      },
    ]
  },
  {
    path: 'laudos/rxTorax',
    component: RxToraxComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/rxTorax',
        component: RxToraxComponent,
        data: {
          roles: [7]
        }
      },
    ]
  },
  {
    path: 'laudos/arcosCostais',
    component: RxArcosComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/arcosCostais',
        component: RxArcosComponent,
        data: {
          roles: [7]
        }
      },
    ]
  },
  {
    path: 'laudos/colunaLombar',
    component: RxColunaLombarComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/colunaLombar',
        component: RxColunaLombarComponent,
        data: {
          roles: [7]
        }
      },
    ]
  },
  {
    path: 'laudos/rxAbdome',
    component: RxAbdomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/rxAbdome',
        component: RxAbdomeComponent,
        data: {
          roles: [7]
        }
      },
    ]
  },
  {
    path: 'laudos/articulacaoTemporomandibular',
    component: RxAtmComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'laudos/colunaLombar',
        component: RxColunaLombarComponent,
        data: {
          roles: [7]
        }
      },
    ]
  },
  {
    path: 'menuLateral',
    component: MenuLateralComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'menuLateral',
        component: MenuLateralComponent,
      }
    ]
  },
  {
    path: 'planos',
    component: PlanosComponent,
    //canActivate: [AuthGuard],
    children: [
      {
        path: 'planos',
        component: PlanosComponent,
        pathMatch: 'full',
      }
    ]
  },
  {
    path: 'pagRealizados',
    component: PagamentosRealizadosComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'pagRealizados',
        component: PagamentosRealizadosComponent,
      },
    ]
  },
  {
    path: 'pagRealizados/pagDetalhes',
    component: PagRelDetalhesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'pagRealizados/pagDetalhes',
        component: PagRelDetalhesComponent,
      },
    ]
  },
  {
    path: 'config',
    component: ConfigLaudoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'config',
        component: ConfigLaudoComponent,
      }
    ]
  },
  {
    path: 'suporte',
    component: SuporteComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'suporte',
        component: SuporteComponent,
      }
    ]
  },
  {
    path: 'versao',
    component: VersaoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'versao',
        component: VersaoComponent,
      }
    ]
  },
  {
    path: 'manual',
    component: MenuExpansivo,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'manual',
        component: MenuExpansivo,
      }
    ]
  },
  {
    path: 'alteraLaudo',
    component: AlteraLaudoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'alteraLaudo',
        component: AlteraLaudoComponent,
      }
    ]
  },
  {
    path: 'alteraLaudoList',
    component: AlteraLaudoListComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'alteraLaudoList',
        component: AlteraLaudoListComponent,
      }
    ]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      }
    ]
  },
  {
    path: 'lista/detalhar',
    component: DetalharLaudoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'lista/detalhar',
        component: DetalharLaudoComponent,
      },
    ]
  },
  {
    path: 'lista',
    component: ListaLaudoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'lista',
        component: ListaLaudoComponent,
      },
    ]
  },
  {
    path: 'alteraPagamento',
    component: AlteraPagamentoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'alteraPagamento',
        component: AlteraPagamentoComponent,
      },
    ]
  },
  {
    path: 'email-confirmacao',
    component: EmailConfirmacaoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'email-confirmacao',
        component: EmailConfirmacaoComponent,
      },
    ]
  },
  {
    path: 'integration/:laudo/:token/:userid',
    component: IntegrationComponent,
  },
  {
    path: '**',
    component: LoginComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
