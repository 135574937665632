import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-risco-pre-eclampsia-dialog',
  templateUrl: './risco-pre-eclampsia-dialog.component.html',
  styleUrls: ['./risco-pre-eclampsia-dialog.component.css']
})
export class RiscoPreEclampsiaDialogComponent {
  
  displayedColumns =
    ['valor', 'risco'];
  dataSource = ELEMENT_DATA;

  constructor(
    public dialogRef: MatDialogRef<RiscoPreEclampsiaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }
}
export interface riscoPreEclElement {
  valor: string;
  risco: string;
}

const ELEMENT_DATA: riscoPreEclElement[] = [
  {valor: '< 1:200', risco: 'Baixo risco'},
  {valor: '> 1:200', risco: 'Alto risco'}
]
