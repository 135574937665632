import { Component, ElementRef, EventEmitter, Injectable, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { createViewChildren } from '@angular/compiler/src/core';
import { AbdomeTotalBO } from '../BO/AbdomeTotalBO';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../../utils/SelectedItemEmiter';


@Component({
  selector: 'app-svg-abdome-total',
  templateUrl: './svg-abdome-total.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./svg-abdome-total.component.css']
})

export class SvgAbdomeTotalComponent implements OnInit {
  @Output() orgaoSelecionado = new EventEmitter<string>();

  figadoID = 1;
  veiaportaID = 2;
  vesiculabiliarID = 3;
  viasbiliaresID = 4;
  bacoID = 5;
  pancreasID = 6;
  asciteID = 7;
  aortaID = 8;
  bexigaID = 9;
  rimdireitoID = 10;
  rimesquerdoID = 11;
  linfonodoID = 264;
  alcaIntestinalID = 265;
  limitacoesID = 274;

  btnBlocos = [
    {
      id: 'cabecalho',
      nome: 'cabecalho',
      titulo: 'Informações'
    },
    {
      id: 'indicacao',
      nome: 'indicacao',
      subtitulo: 'Preencha o campo indicação',
      titulo: 'Indicação'
    },
    {
      id: this.limitacoesID,
      nome: 'limitacoes',
      titulo: 'Limitações técnicas',
      selected: true
    },
    {
      id: this.figadoID,
      nome: 'figado',
      titulo: 'Fígado',
      selected: false
    },
    {
      id: this.veiaportaID,
      nome: 'veiaporta',
      titulo: 'Veia Porta',
      selected: false
    },
    {
      id: this.viasbiliaresID,
      nome: 'viasbiliares',
      titulo: 'Vias Biliares',
      selected: false
    },
    {
      id: this.vesiculabiliarID,
      nome: 'vesicula',
      titulo: 'Vesícula Biliar',
      selected: false
    },
    {
      id: this.pancreasID,
      nome: 'pancreas',
      titulo: 'Pâncreas',
      selected: false
    },
    {
      id: this.bacoID,
      nome: 'baco',
      titulo: 'Baço',
      selected: false
    },
    {
      id: this.rimdireitoID,
      nome: 'rim-direito',
      titulo: 'Rim Direito',
      selected: false
    },
    {
      id: this.rimesquerdoID,
      nome: 'rim-esquerdo',
      titulo: 'Rim Esquerdo',
      selected: false
    },
    {
      id: this.aortaID,
      nome: 'aorta',
      titulo: 'Aorta',
      selected: false
    },
    {
      id: this.linfonodoID,
      nome: 'linfonodo',
      titulo: 'Linfonodo',
      selected: false
    },
    {
      id: this.asciteID,
      nome: 'ascite',
      titulo: 'Ascite',
      selected: false
    },
    {
      id: this.bexigaID,
      nome: 'bexiga',
      titulo: 'Bexiga',
      selected: false
    },
    {
      id: this.alcaIntestinalID,
      nome: 'alca-intestinal',
      titulo: 'Alça Intestinal',
      selected: false
    },
    {
      id: 'achados',
      nome: 'achados',
      titulo: 'Achados Adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      id: 'rodape',
      nome: 'rodape',
      titulo: 'Assinatura',
      selected: false,
    }
  ]


  constructor(public _selected: SelectedItemEmiter) { }

  ngOnInit() { }

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.btnBlocos);
    this.btnBlocos = arrayAtualizado;
    this.orgaoSelecionado.emit(itemSelecionado);
  }
}
