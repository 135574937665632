import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TextEditorComponent } from './text-editor.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    AngularEditorModule,
    FormsModule
  ],
  declarations: [TextEditorComponent],
  exports: [TextEditorComponent]
})
export class TextEditorModule { }
