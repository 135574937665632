import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserHasTextTerm } from '../models/BO/UserHasTextTerm';


@Injectable({
  providedIn: 'root'
})
export class UserHasTextTermService {

  constructor(private http: HttpClient) { }

  gravarUserHasTextTerm(userHasTextTerm: UserHasTextTerm) {
    const url = environment.nLaudoApi + 'UserHasTextTerm/UserHasTextTerm';
    
    const objPost = {
        "TextTermID": userHasTextTerm.textTermID,
        "UserID": userHasTextTerm.userID,
        "Text": userHasTextTerm.text,
        "Conclusion": userHasTextTerm.conclusion,
    };

    this.http.post<UserHasTextTerm>(url, objPost).subscribe();
  }

  getUserHasTextTermByUser(userID: string):Observable<UserHasTextTerm[]>{
    const url = environment.nLaudoApi + 'UserHasTextTerm/UserHasTextTerm/';
    return this.http.get<UserHasTextTerm[]>(url + userID);
  }

  updateUserHasTextTerm(userID: string, obj:UserHasTextTerm){
    const url = environment.nLaudoApi + 'UserHasTextTerm/UserHasTextTerm/'+userID;
    this.http.put(url, obj).subscribe();
  }

  deleteUserHasTextTerm(userID: string, id: string):Observable<{}>{
    const url = environment.nLaudoApi + 'UserHasTextTerm/UserHasTextTerm/'+userID+"/"+id;
    return this.http.delete(url);
  }
  
}
