import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatRadioButton, MatSelect } from '@angular/material';
import { LesoesCarotidas } from '../../../../../../../models/Helper/LesoesCarotidas';
import { ElementCarotida } from '../../../../../../../models/Helper/ElementCarotida';
import { ArrayDropDown } from '../../../../../../../models/Helper/ArrayDropDown';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { ToastrManager } from 'ng6-toastr-notifications';


@Component({
  selector: 'app-lesoes-esquerda',
  templateUrl: './lesoes-esquerda.component.html',
  styleUrls: ['./lesoes-esquerda.component.css']
})
export class LesoesEsquerdaComponent implements OnInit {

  @ViewChild('rdPlaca') rdPlaca: MatRadioButton;
  @ViewChild('rdTortuosidade') rdTortuosidade: MatRadioButton;
  @ViewChild('rdEndoprotese') rdEndoprotese: MatRadioButton;
  @ViewChild('rdTrombose') rdTrombose: MatRadioButton;

  // Placa
  @ViewChild('slEcogenicidadePlaca') slEcogenicidadePlaca: MatSelect;
  @ViewChild('slEcotexturaPlaca') slEcotexturaPlaca: MatSelect;
  @ViewChild('slSuperficiePlaca') slSuperficiePlaca: MatSelect;
  @ViewChild('slEstenosePlaca') slEstenosePlaca: MatSelect;
  @ViewChild('inEspessuraPlaca') inEspessuraPlaca: ElementRef;
  @ViewChild('inExtensaoPlaca') inExtensaoPlaca: ElementRef;
  @ViewChild('slSegmentoPlaca') slSegmentoPlaca: MatSelect;

  // Tortousidade
  @ViewChild('slVelocidadeTortuosidade') slVelocidadeTortuosidade: MatSelect;
  @ViewChild('slSegmentoTortuosidade') slSegmentoTortuosidade: MatSelect;

  // Endoprotese
  @ViewChild('slEstenoseEndoprotese') slEstenoseEndoprotese: MatSelect;
  @ViewChild('inVps') inVps: ElementRef;
  @ViewChild('inAciAcc') inAciAcc: ElementRef;
  @ViewChild('slSegmentoEndoprotese') slSegmentoEndoprotese: MatSelect;

  // Trombose
  @ViewChild('inExtensaoTrombose') inExtensaoTrombose: ElementRef;
  @ViewChild('slSegmentoTrombose') slSegmentoTrombose: MatSelect;

  lesoesObj: LesoesCarotidas;
  segmentoArray = new Array<string>();

  ecogenicidades: ArrayDropDown[] = [
    { value: 'calcificada', viewValue: 'Calcificada' },
    { value: 'mista', viewValue: 'Mista' },
    { value: 'iso/hiperecogênica', viewValue: 'Iso/Hiperecogênica' },
    { value: 'hipoecogênica', viewValue: 'Hipoecogênica' }
  ];

  ecotexturas: ArrayDropDown[] = [
    { value: 'homogênea', viewValue: 'Homogênea' },
    { value: 'heterogênea', viewValue: 'Heterogênea' }
  ];

  superficies: ArrayDropDown[] = [
    { value: 'regular', viewValue: 'Regular' },
    { value: 'irregular', viewValue: 'Irregular' },
    { value: 'ulcerada', viewValue: 'Ulcerada' }
  ];

  estenoses: ArrayDropDown[] = [
    { value: 'sem determinar estenose hemodinamicamente significativa (< 50%)', viewValue: '< 50%' },
    { value: 'determinando estenose entre 50 - 69%', viewValue: '50 - 69%' },
    { value: 'determinando estenose ≥ 70%', viewValue: '≥ 70%' },
    { value: 'determinando estenose crítica (95 - 99%)', viewValue: '95 - 99%' },
    { value: 'determinando oclusão', viewValue: 'Oclusão' }
  ];

  velocidades: ArrayDropDown[] = [
    { value: 'sem aumento significativo da velocidade de pico sistólico', viewValue: 'Sem aumento' },
    { value: 'determinando aumento da velocidade de pico sistólico', viewValue: 'Determinando aumento' }
  ];

  segmentos: ArrayDropDown[] = [
    { value: 'no bulbo esquerdo', viewValue: 'bulbo' },
    { value: 'no segmento proximal da carótida comum esquerda', viewValue: 'proxacc' },
    { value: 'no terço médio da carótida comum esquerda', viewValue: 'medioacc' },
    { value: 'no segmento distal da carótida comum esquerda', viewValue: 'distalacc' },
    { value: 'na origem da carótida interna esquerda', viewValue: 'origemaci' },
    { value: 'no segmento proximal da carótida interna esquerda', viewValue: 'proxaci' },
    { value: 'na origem da carótida externa esquerda', viewValue: 'origemace' },
    { value: 'no segmento proximal da carótida externa esquerda', viewValue: 'proxace' }
  ];

  placaEsquerdaID = 57;
  carotidaEsquerdaID = 55;
  unidadeMedida: string;

  constructor(
    public dialogRef: MatDialogRef<LesoesEsquerdaComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string,
    private _dialog: MatDialog,
    private _toastr: ToastrManager,

  ) { }

  ngOnInit(){
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  showAndHideTerms = {
    sahPlaca: true,
    sahTortuosidade: false,
    sahEndoprotese: false,
    sahTrombose: false,
    sahAciAcc: false
  };

  clickPlaca() {
    this.showAndHideTerms.sahPlaca = true;
    this.showAndHideTerms.sahTortuosidade = false;
    this.showAndHideTerms.sahEndoprotese = false;
    this.showAndHideTerms.sahTrombose = false;
    this.catchIDs();
  }

  clickTortuosidade() {
    this.showAndHideTerms.sahTortuosidade = true;
    this.showAndHideTerms.sahPlaca = false;
    this.showAndHideTerms.sahEndoprotese = false;
    this.showAndHideTerms.sahTrombose = false;
    this.catchIDs();
  }

  clickEndoprotese() {
    this.showAndHideTerms.sahEndoprotese = true;
    this.showAndHideTerms.sahPlaca = false;
    this.showAndHideTerms.sahTortuosidade = false;
    this.showAndHideTerms.sahTrombose = false;
    this.exibeAciStent();
    this.catchIDs();
  }

  clickTrombose() {
    this.showAndHideTerms.sahTrombose = true;
    this.showAndHideTerms.sahPlaca = false;
    this.showAndHideTerms.sahTortuosidade = false;
    this.showAndHideTerms.sahEndoprotese = false;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }


  ClickSalvar(): void {
    this.catchIDs();
    if (this.segmentoArray.length > 0 && this.segmentoArray) {
      localStorage.setItem('objLesoesEsquerda', JSON.stringify(this.lesoesObj));
      this.dialogRef.close();
    } else {
      this._toastr.errorToastr('Preencha o segmento');
    }

  }

  ClickNao(): void {
    localStorage.removeItem('objLesoesEsquerda');
    this.dialogRef.close();
  }

  public validationSvg(): any {
    const dialog = this._dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: 'Segmento foi preenchido?',
    });
    dialog.afterClosed().subscribe(result => {
      if (result === false) {

      }

    });

  }

  segmentoAtivo($event) {
    const segmento = $event.toLowerCase();
    const segVal = this.segmentos.find(x => x.viewValue === segmento);

    if (segVal) {
      if (this.segmentoArray) {
        const index = this.segmentoArray.findIndex(x => x === segVal.value);
        if (index > -1) {
          this.segmentoArray.splice(index);
        } else {
          this.segmentoArray.push(segVal.value);
        }
      } else {
        this.segmentoArray.push(segVal.value);
      }
    }

    this.exibeAciStent();
  }

  catchIDs() {
    this.lesoesObj = new LesoesCarotidas;
    this.lesoesObj.element = new Array<ElementCarotida>();

    // Placa
    if (this.rdPlaca !== undefined && this.rdPlaca.checked) {
      this.lesoesObj.id = +this.rdPlaca.id;
      this.lesoesObj.value = 'Placa';
      this.lesoesObj.blockID = this.placaEsquerdaID;

      if (this.slEcogenicidadePlaca != undefined && this.slEcogenicidadePlaca.value != undefined) {
        // Encontra o valor da View
        const viewVal = this.ecogenicidades.find(x => x.value === this.slEcogenicidadePlaca.value);
        const elemento = { id: this.slEcogenicidadePlaca.id, value: this.slEcogenicidadePlaca.value, viewValue: viewVal.viewValue };
        this.lesoesObj.element.push(elemento);
      }

      if (this.slEcotexturaPlaca != undefined && this.slEcotexturaPlaca.value != undefined) {
        // Encontra o valor da View
        const viewVal = this.ecotexturas.find(x => x.value === this.slEcotexturaPlaca.value);
        const elemento = { id: this.slEcotexturaPlaca.id, value: this.slEcotexturaPlaca.value, viewValue: viewVal.viewValue };
        this.lesoesObj.element.push(elemento);
      }

      if (this.slSuperficiePlaca != undefined && this.slSuperficiePlaca.value != undefined) {
        // Encontra o valor da View
        const viewVal = this.superficies.find(x => x.value === this.slSuperficiePlaca.value);
        const elemento = { id: this.slSuperficiePlaca.id, value: this.slSuperficiePlaca.value, viewValue: viewVal.viewValue };
        this.lesoesObj.element.push(elemento);
      }

      if (this.slEstenosePlaca != undefined && this.slEstenosePlaca.value != undefined) {
        // Encontra o valor da View
        const viewVal = this.estenoses.find(x => x.value === this.slEstenosePlaca.value);
        const elemento = { id: this.slEstenosePlaca.id, value: this.slEstenosePlaca.value, viewValue: 'Estenose: ' + viewVal.viewValue };
        this.lesoesObj.element.push(elemento);
      }

      if (this.inEspessuraPlaca != undefined && this.inEspessuraPlaca.nativeElement.value) {
        const elemento = {
          id: this.inEspessuraPlaca.nativeElement.id, value: this.inEspessuraPlaca.nativeElement.value,
          viewValue: 'Espessura: ' + this.inEspessuraPlaca.nativeElement.value + ' ' + this.unidadeMedida
        };
        this.lesoesObj.element.push(elemento);
      }

      if (this.inExtensaoPlaca != undefined && this.inExtensaoPlaca.nativeElement.value) {
        const elemento = {
          id: this.inExtensaoPlaca.nativeElement.id, value: this.inExtensaoPlaca.nativeElement.value,
          viewValue: 'Extensão: ' + this.inExtensaoPlaca.nativeElement.value + ' ' + this.unidadeMedida
        };
        this.lesoesObj.element.push(elemento);
      }

      if (this.segmentoArray) {
        let viewVal, value = '';
        // Encontra o valor da View
        this.segmentoArray.forEach(obj => {
          if (viewVal)
            viewVal = viewVal + ', ' + this.verificaSegmento(obj);
          else
            viewVal = this.verificaSegmento(obj);
          if (value)
            value = value + ', ' + obj;
          else
            value = obj;
        });
        const elemento = { id: 1070, value: value, viewValue: viewVal };
        this.lesoesObj.element.push(elemento);
      }
    }

    // Tortousidade
    if (this.rdTortuosidade !== undefined && this.rdTortuosidade.checked) {
      this.lesoesObj.id = +this.rdTortuosidade.id;
      this.lesoesObj.value = 'Tortousidade';
      this.lesoesObj.blockID = this.carotidaEsquerdaID;


      if (this.slVelocidadeTortuosidade != undefined && this.slVelocidadeTortuosidade.value != undefined) {
        // Encontra o valor da View
        const viewVal = this.velocidades.find(x => x.value === this.slVelocidadeTortuosidade.value);
        const elemento = { id: this.slVelocidadeTortuosidade.id, value: this.slVelocidadeTortuosidade.value, viewValue: viewVal.viewValue };
        this.lesoesObj.element.push(elemento);
      }

      if (this.segmentoArray) {
        let viewVal, value = '';
        // Encontra o valor da View
        this.segmentoArray.forEach(obj => {
          if (viewVal)
            viewVal = viewVal + ', ' + this.verificaSegmento(obj);
          else
            viewVal = this.verificaSegmento(obj);
          if (value)
            value = value + ', ' + obj;
          else
            value = obj;
        });
        const elemento = { id: 1081, value: value, viewValue: viewVal };
        this.lesoesObj.element.push(elemento);
      }
    }

    // Endoprotese
    if (this.rdEndoprotese !== undefined && this.rdEndoprotese.checked) {
      this.lesoesObj.id = +this.rdEndoprotese.id;
      this.lesoesObj.value = 'Endoprotese';
      this.lesoesObj.blockID = this.carotidaEsquerdaID;


      if (this.slEstenoseEndoprotese != undefined && this.slEstenoseEndoprotese.value != undefined) {
        // Encontra o valor da View
        const viewVal = this.estenoses.find(x => x.value === this.slEstenoseEndoprotese.value);
        const elemento = { id: this.slEstenoseEndoprotese.id, value: this.slEstenoseEndoprotese.value, viewValue: 'Estenose: ' + viewVal.viewValue };
        this.lesoesObj.element.push(elemento);
      }

      if (this.inVps != undefined && this.inVps.nativeElement.value) {
        const elemento = {
          id: this.inVps.nativeElement.id, value: this.inVps.nativeElement.value,
          viewValue: 'VPS: ' + this.inVps.nativeElement.value + ' ' + this.unidadeMedida
        };
        this.lesoesObj.element.push(elemento);
      }

      if (this.inAciAcc != undefined && this.inAciAcc.nativeElement.value) {
        const elemento = {
          id: this.inAciAcc.nativeElement.id, value: this.inAciAcc.nativeElement.value,
          viewValue: 'ACI/ACC: ' + this.inAciAcc.nativeElement.value + ' ' + this.unidadeMedida
        };
        this.lesoesObj.element.push(elemento);
      }

      if (this.segmentoArray) {
        let viewVal, value = '';
        // Encontra o valor da View
        this.segmentoArray.forEach(obj => {
          if (viewVal)
            viewVal = viewVal + ', ' + this.verificaSegmento(obj);
          else
            viewVal = this.verificaSegmento(obj);
          if (value)
            value = value + ', ' + obj;
          else
            value = obj;
        });
        const elemento = { id: 1084, value: value, viewValue: viewVal };
        this.lesoesObj.element.push(elemento);
      }
    }

    // Trombose
    if (this.rdTrombose !== undefined && this.rdTrombose.checked) {
      this.lesoesObj.id = +this.rdTrombose.id;
      this.lesoesObj.value = 'Trombose';
      this.lesoesObj.blockID = this.carotidaEsquerdaID;

      if (this.inExtensaoTrombose != undefined && this.inExtensaoTrombose.nativeElement.value) {
        const elemento = {
          id: this.inExtensaoTrombose.nativeElement.id, value: this.inExtensaoTrombose.nativeElement.value,
          viewValue: 'Extensão: ' + this.inExtensaoTrombose.nativeElement.value + ' ' + this.unidadeMedida
        };
        this.lesoesObj.element.push(elemento);
      }


      if (this.segmentoArray) {
        let viewVal, value = '';
        // Encontra o valor da View
        this.segmentoArray.forEach(obj => {
          if (viewVal)
            viewVal = viewVal + ', ' + this.verificaSegmento(obj);
          else
            viewVal = this.verificaSegmento(obj);
          if (value)
            value = value + ', ' + obj;
          else
            value = obj;
        });
        const elemento = { id: 1089, value: value, viewValue: viewVal };
        this.lesoesObj.element.push(elemento);
      }
    }
  }

  verificaSegmento(segmento) {
    const segVal = this.segmentos.find(x => x.value === segmento);
    switch (segVal.viewValue) {
      case 'bulbo':
        return 'Bulbo';
      case 'proxacc':
        return 'Proximal da ACC';
      case 'medioacc':
        return 'Terço médio da ACC';
      case 'distalacc':
        return 'Distal da ACC';
      case 'origemaci':
        return 'Origem da ACI';
      case 'proxaci':
        return 'Proximal da ACI';
      case 'origemace':
        return 'Origem da ACE';
      case 'proxace':
        return 'Proximal da ACE';
    }
  }

  exibeAciStent() {
    const orgACI = this.segmentoArray.find(x => x === 'na origem da carótida interna esquerda');
    const proxACI = this.segmentoArray.find(x => x === 'no segmento proximal da carótida interna esquerda');
    if (orgACI || proxACI) {
      this.showAndHideTerms.sahAciAcc = true;
    } else {
      this.showAndHideTerms.sahAciAcc = false;
    }
  }
}
