import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterPipe } from 'ngx-filter-pipe';
import { ListaPlanosFavoritosService } from 'src/app/services/lista-planos-favoritos.service';
import { Speciality } from '../../../models/BO/Speciality';
import { ListaPlanosService } from '../../../services/lista-planos.service';

@Component({
  selector: 'app-menu-tc',
  templateUrl: './menu-tc.component.html',
  styleUrls: ['./menu-tc.component.css', '../../../app.component.css']
})
export class MenuTcComponent implements OnInit {
  examesTomografia: Speciality[] = [];
  public loading: boolean = true;

  exameFilter: Speciality = {
    id: 0,
    description: '',
    parentMedicalReportTypeID: 0,
    specialityID: 0,
    title: '',
    image: '',
    url: '',
    visible: false,
    favorite: false
  };

  constructor(private router: Router,
    private filter: FilterPipe,
    private _planoService: ListaPlanosService,
    private _favoritosService: ListaPlanosFavoritosService) { }

  ngOnInit() {
    const userID = parseInt(localStorage.getItem('userID'));
    //Busca Favorito
    this._favoritosService.buscarFavsPorUsuario(userID).subscribe(
      favs => {
        // Busca exame
        const exames = JSON.parse(localStorage.getItem('exames'));

        if (exames && exames.length > 0) {
          exames.forEach(item => {
            item.forEach(exame => {
              favs.forEach(fav => {
                if (exame.url == fav.url) // Favoritos
                  exame.favorite = true;
              });

              if (exame.specialityID === 5) // Tomografia
                this.examesTomografia.push(exame);
            });
          });
          this.loading = false;
        } else {
          const userID = parseInt(localStorage.getItem('userID'));
          this._planoService.buscarPlanosPorUsuario(userID).subscribe(
            exames => {
              if (exames) {
                if (exames.length > 0)
                  localStorage.setItem('exames', JSON.stringify(exames));

                exames.forEach(item => {
                  item.forEach(exame => {
                    favs.forEach(fav => {
                      if (exame.url == fav.url) // Favoritos
                        exame.favorite = true;
                    });

                    if (exame.specialityID === 5) // Tomografia
                      this.examesTomografia.push(exame);
                  });
                });
              }
            },
            erro => {
              console.log(erro);
            }
          );
          this.loading = false;
        }
      });
  }

  goTo(where: string) {
    this.router.navigate([`/laudos/${where}`]);
  }

  goToNewTab(where: string){
    window.open(`/laudos/${where}`,'_blank','noopener')
  }

  changeFavorito(exame: Speciality) {
    const userId = localStorage.getItem('userID');
    if (this._favoritosService.isFavorito(exame)) {
      exame.favorite = false;
      this._favoritosService.apagarFavoritoUsuario(+userId, exame)
    } else {
      exame.favorite = true;
      this._favoritosService.insereFavoritoUsuario(+userId, exame)
    }
  }

  isFavorito(exame: Speciality): boolean {
    return this._favoritosService.isFavorito(exame);
  }
}
