import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private _http: HttpClient) { }

  uploadImageReport(file): Observable<any> {

    // const url = environment.nLaudoApi + 'Upload/DownloadFile/Scorpions.png';
    const url = 'https://localhost:5001/api/Upload';

    const formData: FormData = new FormData();
    const headers = new HttpHeaders();

    formData.append('asset', file.target.files[0]);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this._http.post<any>(url, formData, { headers: headers });
    // return this._http.post<any>(url, { headers: headers });
  }
}
