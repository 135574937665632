import { Component, EventEmitter, ElementRef, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-efeito-expansivo',
  templateUrl: './efeito-expansivo.component.html',
  styleUrls: ['./efeito-expansivo.component.css']
})
export class EfeitoExpansivoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckDesvioLinha') ckDesvioLinha: MatCheckbox;
  @ViewChild('slLadoDesvioLinha') slLadoDesvioLinha: MatSelect;
  @ViewChild('inMedidaDesvioLinha') inMedidaDesvioLinha: ElementRef;

  @ViewChild('ckEfeitoEx') ckEfeitoEx: MatCheckbox;
  @ViewChild('slVentriculoEE') slVentriculoEE: MatSelect;
  @ViewChild('ckDesvioLinhaMed') ckDesvioLinhaMed: MatCheckbox;
  @ViewChild('slDesvioEE') slDesvioEE: MatSelect;
  @ViewChild('inMedidaEE') inMedidaEE: ElementRef;

  @ViewChild('ckHernSub') ckHernSub: MatCheckbox;
  @ViewChild('slLadoHS') slLadoHS: MatSelect;

  @ViewChild('ckHernTrans') ckHernTrans: MatCheckbox;
  @ViewChild('slLadoHTDA') slLadoHTDA: MatSelect;

  @ViewChild('ckHernTransDP') ckHernTransDP: MatCheckbox;
  @ViewChild('slLadoHTD') slLadoHTD: MatSelect;

  @ViewChild('ckHTDC') ckHTDC: MatCheckbox;

  @ViewChild('ckHTA') ckHTA: MatCheckbox;

  lados: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];

  ladosComp: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  ventriculos: ArrayDropDown[] = [
    { value: 'ventrículo lateral direito', viewValue: 'Ventrículo lateral direito' },
    { value: 'ventrículo lateral esquerdo', viewValue: 'Ventrículo lateral esquerdo' },
    { value: 'terceiro ventrículo', viewValue: 'Terceiro ventrículo' },
    { value: 'quarto ventrículo', viewValue: 'Quarto ventrículo' }
  ];

  showAndHideTerms: any;
  countLesoes = 0;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahDesvioLinha: false,
      sahEfeitoEx: false,
      sahDesvioLinhaMed: false,
      sahHernSub: false,
      sahHernTrans: false,
      sahHernTransDP: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.catchIDs();
  }

  clickDesviLinha() {
    if (this.showAndHideTerms.sahDesvioLinha === false) {
      this.showAndHideTerms.sahDesvioLinha = true;
    } else {
      this.showAndHideTerms.sahDesvioLinha = false;
    }
    this.mudaPresente();
    this.catchIDs();
  }

  clickEfeitoEx() {
    if (this.showAndHideTerms.sahEfeitoEx === false) {
      this.showAndHideTerms.sahEfeitoEx = true;
    } else {
      this.showAndHideTerms.sahEfeitoEx = false;
    }
    this.mudaPresente();
    this.catchIDs();
  }

  clickDesvioLinhaMed() {
    if (this.showAndHideTerms.sahDesvioLinhaMed === false) {
      this.showAndHideTerms.sahDesvioLinhaMed = true;
    } else {
      this.showAndHideTerms.sahDesvioLinhaMed = false;
    }
    this.mudaPresente();
    this.catchIDs();
  }

  clickHernSub() {
    if (this.showAndHideTerms.sahHernSub === false) {
      this.showAndHideTerms.sahHernSub = true;
    } else {
      this.showAndHideTerms.sahHernSub = false;
    }
    this.mudaPresente();
    this.catchIDs();
  }

  clickHernTrans() {
    if (this.showAndHideTerms.sahHernTrans === false) {
      this.showAndHideTerms.sahHernTrans = true;
    } else {
      this.showAndHideTerms.sahHernTrans = false;
    }
    this.mudaPresente();
    this.catchIDs();
  }

  clickHernTransDP() {
    if (this.showAndHideTerms.sahHernTransDP === false) {
      this.showAndHideTerms.sahHernTransDP = true;
    } else {
      this.showAndHideTerms.sahHernTransDP = false;
    }
    this.mudaPresente();
    this.catchIDs();
  }

  clickHTDC() {
    this.mudaPresente();
    this.catchIDs();
  }

  clickHTA() {
    this.mudaPresente();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  mudaPresente() {
    if ((this.ckDesvioLinha && this.ckDesvioLinha.checked) ||
      (this.ckEfeitoEx && this.ckEfeitoEx.checked) ||
      (this.ckHernSub && this.ckHernSub.checked) ||
      (this.ckHernTrans && this.ckHernTrans.checked) ||
      (this.ckHernTransDP && this.ckHernTransDP.checked) ||
      (this.ckHTDC && this.ckHTDC.checked) ||
      (this.ckHTA && this.ckHTA.checked)
    ) {
      this.rdAlterado.checked = true;
    } else {
      this.rdNormal.checked = true;
    }
  }


  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {

      if (this.ckDesvioLinha && this.ckDesvioLinha.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckDesvioLinha.id);

        if (this.slLadoDesvioLinha && this.slLadoDesvioLinha.value) {
          const objeto = { elemento: this.slLadoDesvioLinha, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaDesvioLinha && this.inMedidaDesvioLinha.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDesvioLinha.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.countLesoes > 0) {
        this.emitDesvioLinhaLesao();
      } else {
        this.emitDesvioLinha();
      }

      if (this.countLesoes > 0) {
        this.emitHernSubLesao();
      } else {
        this.emitHernSub();
      }

      if (this.countLesoes > 0) {
        this.emitHernTransLesao();
      } else {
        this.emitHernTrans();
      }

      if (this.countLesoes > 0) {
        this.emitHernTransDPLesao();
      } else {
        this.emitHernTransDP();
      }

      if (this.countLesoes > 0) {
        this.emitHTDCLesao();
      } else {
        this.emitHTDC();
      }

      if (this.countLesoes > 0) {
        this.emitHTALesao();
      } else {
        this.emitHTA();
      }
    }

    this.listaCompleta.emit();
  }

  emitDesvioLinha() {
    if (this.ckEfeitoEx && this.ckEfeitoEx.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEfeitoEx.id);

      if (this.slVentriculoEE && this.slVentriculoEE.value) {
        const objeto = { elemento: this.slVentriculoEE, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckDesvioLinhaMed && this.ckDesvioLinhaMed.checked) {
        this.termoSelecionado.emit(this.ckDesvioLinhaMed.id);

        if (this.slDesvioEE && this.slDesvioEE.value) {
          const objeto = { elemento: this.slDesvioEE, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaEE && this.inMedidaEE.nativeElement.value) {
          const objeto = { elemento: this.inMedidaEE.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

    }
  }

  emitDesvioLinhaLesao() {
    if (this.ckEfeitoEx && this.ckEfeitoEx.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckEfeitoEx.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slVentriculoEE && this.slVentriculoEE.value) {
        const objeto = { elemento: this.slVentriculoEE, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckDesvioLinhaMed && this.ckDesvioLinhaMed.checked) {
        this.countLesoes++;
        const objeto = { id: this.ckDesvioLinhaMed.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.slDesvioEE && this.slDesvioEE.value) {
          const objeto = { elemento: this.slDesvioEE, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaEE && this.inMedidaEE.nativeElement.value) {
          const objeto = { elemento: this.inMedidaEE.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitHernSub() {
    if (this.ckHernSub && this.ckHernSub.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHernSub.id);

      if (this.slLadoHS && this.slLadoHS.value) {
        const objeto = { elemento: this.slLadoHS, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHernSubLesao() {
    if (this.ckHernSub && this.ckHernSub.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckHernSub.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLadoHS && this.slLadoHS.value) {
        const objeto = { elemento: this.slLadoHS, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHernTrans() {
    if (this.ckHernTrans && this.ckHernTrans.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHernTrans.id);

      if (this.slLadoHTDA && this.slLadoHTDA.value) {
        const objeto = { elemento: this.slLadoHTDA, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHernTransLesao() {
    if (this.ckHernTrans && this.ckHernTrans.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckHernTrans.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLadoHTDA && this.slLadoHTDA.value) {
        const objeto = { elemento: this.slLadoHTDA, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHernTransDP() {
    if (this.ckHernTransDP && this.ckHernTransDP.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHernTransDP.id);

      if (this.slLadoHTD && this.slLadoHTD.value) {
        const objeto = { elemento: this.slLadoHTD, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHernTransDPLesao() {
    if (this.ckHernTransDP && this.ckHernTransDP.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckHernTransDP.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLadoHTD && this.slLadoHTD.value) {
        const objeto = { elemento: this.slLadoHTD, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHTDC() {
    if (this.ckHTDC && this.ckHTDC.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHTDC.id);
    }
  }

  emitHTDCLesao() {
    if (this.ckHTDC && this.ckHTDC.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckHTDC.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitHTA() {
    if (this.ckHTA && this.ckHTA.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHTDC.id);
    }
  }

  emitHTALesao() {
    if (this.ckHTA && this.ckHTA.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckHTA.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }
}
