import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-interapofisarias',
  templateUrl: './interapofisarias.component.html',
  styleUrls: ['./interapofisarias.component.css']
})
export class InterapofisariasComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckArtroseInter') ckArtroseInter: MatCheckbox;
  @ViewChild('ckArtroseMulti') ckArtroseMulti: MatCheckbox;

  @ViewChild('slNivelArtrose') slNivelArtrose: MatSelect;



  nivelArtrose: ArrayDropDown[] = [
    { value: 'C2-C3', viewValue: 'C2-C3' },
    { value: 'C3-C4', viewValue: 'C3-C4' },
    { value: 'C4-C5', viewValue: 'C4-C5' },
    { value: 'C5-C6', viewValue: 'C5-C6' },
    { value: 'C6-C7', viewValue: 'C6-C7' },
    { value: 'C7-T1', viewValue: 'C7-T1' },
  ];

  showAndHideTerms: any;

  constructor() {}

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahArtroseInter: false,
    };
  }

  clickNormal(){
    this.showAndHideTerms.sahArtroseInter = false;
    this.ckArtroseInter.checked = false;
    this.ckArtroseMulti.checked = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.rdNormal.checked = false;
  }

  clickArtroseInter(){
    this.rdAlterado.checked = true;
    this.ckArtroseMulti.checked = false;
    this.showAndHideTerms.sahArtroseInter= !this.showAndHideTerms.sahArtroseInter;
    this.catchIDs();
  }

  clickArtroseMulti(){
    this.rdAlterado.checked = true;
    this.ckArtroseInter.checked = false;
    this.showAndHideTerms.sahArtroseInter = false;
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs(){
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.ckArtroseInter && this.ckArtroseInter.checked) {
      this.termoSelecionado.emit(this.ckArtroseInter.id);
      if(this.slNivelArtrose && this.slNivelArtrose.value){
        const objeto = {elemento: this.slNivelArtrose, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.ckArtroseMulti && this.ckArtroseMulti.checked) {
      debugger
      this.termoSelecionado.emit(this.ckArtroseMulti.id);
    }

    this.listaCompleta.emit();
  }

}
