import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatCheckbox, MatDialog, MatRadioButton, MatSelect } from '@angular/material';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TireoideDopplerNivelCervicalComponent } from 'src/app/utils/dialog/imagens/tireoide-doppler-nivel-cervical/tireoide-doppler-nivel-cervical.component';
import { FigadoSegmentacaoComponent } from 'src/app/utils/dialog/imagens/figado-segmentacao/figado-segmentacao.component';
import { TranslateService } from '@ngx-translate/core';

export interface Dimensoes {
  value: string;
  selected: boolean;
  viewValue: string;
}

export interface Graduacao {
  value: string;
  selected: boolean;
  viewValue: string;
}

export interface Margem {
  value: string;
  selected: boolean;
  viewValue: string;
}

export interface Ecogenicidade {
  value: string;
  selected: boolean;
  viewValue: string;
}

export interface Segmento {
  value: string;
  selected: boolean;
  viewValue: string;
}

export interface Parede {
  value: string;
  selected: boolean;
  viewValue: string;
}

export interface Conteudo {
  value: string;
  selected: boolean;
  viewValue: string;
}

@Component({
  selector: 'app-figado',
  templateUrl: './figado.component.html',
  styleUrls: ['./figado.component.css']
})
export class FigadoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdHepatomegalia') rdHepatomegalia: MatRadioButton;
  @ViewChild('rdEsteatose') rdEsteatose: MatRadioButton;
  @ViewChild('rdHepatopatia') rdHepatopatia: MatRadioButton;
  @ViewChild('rdCirrose') rdCirrose: MatRadioButton;

  @ViewChild('slDimensao') slDimensao: MatSelect;
  @ViewChild('slGraduacao') slGraduacao: MatSelect;
  @ViewChild('ckDimenAumentada') ckDimenAumentada: MatCheckbox;

  @ViewChild('ckNodulo') ckNodulo: MatCheckbox;
  @ViewChild('rd1nodulo') rd1nodulo: MatRadioButton;
  @ViewChild('rd2nodulos') rd2nodulos: MatRadioButton;
  @ViewChild('rdNodulosMultiplos') rdNodulosMultiplos: MatRadioButton;
  @ViewChild('inNoduloMedindo1') inNoduloMedindo1: ElementRef;
  @ViewChild('inNoduloMedindo2') inNoduloMedindo2: ElementRef;
  @ViewChild('inNoduloMedindo3') inNoduloMedindo3: ElementRef;
  @ViewChild('slNoduloEcogenicidade') slNoduloEcogenicidade: MatSelect;
  @ViewChild('slNoduloSegmento') slNoduloSegmento: MatSelect;
  @ViewChild('slNoduloContorno') slNoduloContorno: MatSelect;

  @ViewChild('ckCisto') ckCisto: MatCheckbox;
  @ViewChild('rd1cisto') rd1cisto: MatRadioButton;
  @ViewChild('rd2cistos') rd2cistos: MatRadioButton;
  @ViewChild('inCistoMedindo') inCistoMedindo: ElementRef;
  @ViewChild('slCistoSegmento') slCistoSegmento: MatSelect;

  @ViewChild('ckCalcificacao') ckCalcificacao: MatCheckbox;
  @ViewChild('inCalcificacaoMedindo') inCalcificacaoMedindo: ElementRef;
  @ViewChild('slCalcificacaoSegmento') slCalcificacaoSegmento: MatSelect;

  @ViewChild('ckColecao') ckColecao: MatCheckbox;
  @ViewChild('inColecaoMedindo1') inColecaoMedindo1: ElementRef;
  @ViewChild('inColecaoMedindo2') inColecaoMedindo2: ElementRef;
  @ViewChild('inColecaoMedindo3') inColecaoMedindo3: ElementRef;
  @ViewChild('slColecaoSegmento') slColecaoSegmento: MatSelect;

  @ViewChild('ckHemangioma') ckHemangioma: MatCheckbox;
  @ViewChild('rd1hemangioma') rd1hemangioma: MatRadioButton;
  @ViewChild('rd2hemangiomas') rd2hemangiomas: MatRadioButton;
  @ViewChild('rdHemangiomasMultiplos') rdHemangiomasMultiplos: MatRadioButton;
  @ViewChild('inHemangiomasMedindo') inHemangiomasMedindo: ElementRef;
  @ViewChild('slHemangiomasSegmento') slHemangiomasSegmento: MatSelect;

  @ViewChildren('noduloChildren') noduloChildren: QueryList<any>;
  @ViewChildren('hemangiomaChildren') hemangiomaChildren: QueryList<any>;
  @ViewChildren('cistoChildren') cistoChildren: QueryList<any>;


  dimensoes: Dimensoes[] = [
    { value: 'normais', selected: false, viewValue: 'Normais' },
    { value: 'difusamente aumentadas', selected: false, viewValue: 'Difusamente aumentadas' },
    { value: 'aumentadas à custa do lobo direito', selected: false, viewValue: 'Aumentadas à custa do lobo direito' },
    { value: 'aumentadas à custa do lobo esquerdo', selected: false, viewValue: 'Aumentadas à custa do lobo esquerdo' },
    { value: 'reduzidas', selected: false, viewValue: 'Reduzidas' }
  ];
  graduacoes: Graduacao[] = [
    { value: '10', selected: false, viewValue: 'Não graduar' },
    { value: '11', selected: false, viewValue: 'Grau I' },
    { value: '12', selected: false, viewValue: 'Grau II' },
    { value: '13', selected: false, viewValue: 'Grau III' }
  ];
  margens: Margem[] = [
    { value: 'regulares', selected: false, viewValue: 'Regulares' },
    { value: 'lobulados', selected: false, viewValue: 'Lobulados' },
    { value: 'indefinidos', selected: false, viewValue: 'Indefinidos' }
  ];
  ecogenicidades: Ecogenicidade[] = [
    { value: 'hipoecogênicas', selected: false, viewValue: 'Hipoecogênica' },
    { value: 'hiperecogênicas', selected: false, viewValue: 'Hiperecogênica' },
    { value: 'isoecogênicas', selected: false, viewValue: 'Isoecogênica' }
  ];
  ecogenicidadesSing: Ecogenicidade[] = [
    { value: 'hipoecogênica', selected: false, viewValue: 'Hipoecogênica' },
    { value: 'hiperecogênica', selected: false, viewValue: 'Hiperecogênica' },
    { value: 'isoecogênica', selected: false, viewValue: 'Isoecogênica' }
  ];
  segmentos: Segmento[] = [
    { value: 'I', selected: false, viewValue: 'I' },
    { value: 'II', selected: false, viewValue: 'II' },
    { value: 'III', selected: false, viewValue: 'III' },
    { value: 'IVa', selected: false, viewValue: 'IVa' },
    { value: 'IVb', selected: false, viewValue: 'IVb' },
    { value: 'V', selected: false, viewValue: 'V' },
    { value: 'VI', selected: false, viewValue: 'VI' },
    { value: 'VII', selected: false, viewValue: 'VII' },
    { value: 'VIII', selected: false, viewValue: 'VIII' }
  ];
  paredes: Parede[] = [
    { value: 'finasRegulares', selected: false, viewValue: 'Finas e regulares' },
    { value: 'espessas', selected: false, viewValue: 'Espessas' },
    { value: 'calcificacao', selected: false, viewValue: 'Com focos de calcificação' }
  ];
  conteudos: Conteudo[] = [
    { value: 'anecoico', selected: false, viewValue: 'Anecóico' },
    { value: 'hipoecoico', selected: false, viewValue: 'Hipoecóico' }
  ];

  objeto: any;
  objetofilho: any;
  objetoLesao: any;
  objetoGrupoLesao: any;
  linha = 0;
  lesoesForm: FormGroup;
  showAndHideTerms: any;
  countLesoes = 0;
  @Input() medindo: string;

  dimensaoSelected: string;
  grauSelected: string;
  ecogenicidadeSelected: string;
  contornoSelected: string;
  isNoduloPlural = false;
  unidadeMedida: string;


  constructor(private _fb: FormBuilder,
    public dialog: MatDialog,
    private _translate: TranslateService) {
  }

  async ngOnInit() {
    this.showAndHideTerms = {
      sahDimensoes: false,
      sahGraduacoes: false,
      sahNodulosAll: false,
      sahCistosAll: false,
      sahCalcificacoesAll: false,
      sahColecaoAll: false,
      sahHemangiomasAll: false,
      sahLesoesAll: true,
      sahPlusNodulo: false,
      sahPlusCisto: false,
      sahPlusHemangioma: false,
      sahNoduloSimples: false,
      sahNodulosDinamicos: false,
      sahHemangiomaSimples: false,
      sahHemangiomasDinamicos: false,
      sahCistoSimples: false,
      sahCistoDinamicos: false

    };
    this.lesoesForm = this._fb.group({
      nodulo: this._fb.array([this.addNoduloGrup()]),
      hemangioma: this._fb.array([this.addHemangiomaGrup()]),
      cisto: this._fb.array([this.addCistoGrup()])
    });

    this.dimensaoSelected = '204';
    this.grauSelected = '10';
    this.ecogenicidadeSelected = this.ecogenicidades[0].value;
    this.contornoSelected = this.margens[0].value;

    let cmValidator = localStorage.getItem('cmRule');
    if (cmValidator == undefined || cmValidator == null) {
      this.unidadeMedida = 'cm';
    } else {
      let cmRule = JSON.parse(localStorage.getItem('cmRule'));
      this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
    }

    await this._translate.get('StartTranslation').toPromise().then();

    this.ecogenicidades = this.ecogenicidades.map((item, i) => {
      item.viewValue = this._translate.instant(`Laudos.abdomeTotal.Figado.ecogenicidades.viewValue.${i}`);
      item.value = this._translate.instant(`Laudos.abdomeTotal.Figado.ecogenicidades.value.${i}`);
      return item;
    });

    this.ecogenicidadesSing = this.ecogenicidadesSing.map((item, i) => {
      item.viewValue = this._translate.instant(`Laudos.abdomeTotal.Figado.ecogenicidades.viewValue.${i}`);
      item.value = this._translate.instant(`Laudos.abdomeTotal.Figado.ecogenicidades.value.${i}`);
      return item;
    });
  }

  addNoduloGrup() {
    return this._fb.group({
      dimensao: [],
      ecogenicidade: [],
      segmento: [],
      contorno: []
    });
  }

  addNodulo() {
    this.noduloArray.push(this.addNoduloGrup());
    this.catchIDs();
  }

  removeNodulo(index) {
    this.noduloArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get noduloArray() {
    return <FormArray>this.lesoesForm.get('nodulo');
  }

  addHemangiomaGrup() {
    return this._fb.group({
      dimensao: [],
      segmento: []
    });
  }

  addHemangioma() {
    this.hemangiomaArray.push(this.addHemangiomaGrup());
    this.catchIDs();
  }

  removeHemangioma(index) {
    this.hemangiomaArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get hemangiomaArray() {
    return <FormArray>this.lesoesForm.get('hemangioma');
  }

  addCistoGrup() {
    return this._fb.group({
      dimensao: [],
      segmento: []
    });
  }

  addCisto() {
    this.cistoArray.push(this.addCistoGrup());
    this.catchIDs();
  }

  removeCisto(index) {
    this.cistoArray.removeAt(index);
    this.catchIDs();
  }

  get cistoArray() {
    return <FormArray>this.lesoesForm.get('cisto');
  }

  clickNormal($event): void {
    this.showAndHideTerms.sahDimensoes = false;
    this.showAndHideTerms.sahGraduacoes = false;
    this.showAndHideTerms.sahLesoesAll = true;

    this.dimensoes = [
      { value: '2', selected: false, viewValue: 'Normais' },
      { value: '3', selected: false, viewValue: 'Difusamente aumentadas' },
      { value: '4', selected: false, viewValue: 'Aumentadas à custa do lobo direito' },
      { value: '5', selected: false, viewValue: 'Aumentadas à custa do lobo esquerdo' },
      { value: '6', selected: false, viewValue: 'Reduzidas' }
    ];
    this.catchIDs();
  }

  clickHepatomegalia($event) {
    this.showAndHideTerms.sahDimensoes = true;
    this.showAndHideTerms.sahGraduacoes = false;
    this.showAndHideTerms.sahLesoesAll = true;

    this.dimensoes = [
      { value: '204', selected: false, viewValue: 'Difusamente aumentadas' },
      { value: '205', selected: false, viewValue: 'Aumentadas à custa do lobo direito' },
      { value: '206', selected: false, viewValue: 'Aumentadas à custa do lobo esquerdo' }
    ];
    this.catchIDs();
  }

  clickEsteatose($event) {
    this.showAndHideTerms.sahLesoesAll = true;
    this.showAndHideTerms.sahDimensoes = false;
    this.showAndHideTerms.sahGraduacoes = true;
    this.catchIDs();
  }

  clickHepatopatia($event) {
    this.showAndHideTerms.sahDimensoes = false;
    this.showAndHideTerms.sahGraduacoes = false;
    this.showAndHideTerms.sahLesoesAll = true;

    this.dimensoes = [
      { value: '15', selected: false, viewValue: 'Normais' },
      { value: '16', selected: false, viewValue: 'Reduzidas' }
    ];
    this.catchIDs();
  }

  clickCirrose($event) {
    this.showAndHideTerms.sahDimensoes = false;
    this.showAndHideTerms.sahGraduacoes = false;
    this.showAndHideTerms.sahLesoesAll = true;
    this.catchIDs();
  }

  clickNodulos($event) {
    if (this.showAndHideTerms.sahNodulosAll === false) {
      this.showAndHideTerms.sahNodulosAll = true;
    } else {
      this.showAndHideTerms.sahNodulosAll = false;
    }

    if (!this.ckNodulo.checked) {
      this.showAndHideTerms.sahNodulosDinamicos = false;

      let arrayTotal = this.noduloArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        let index = this.noduloArray.length - 1

        if (index != 0) {
          this.noduloArray.removeAt(index);
        }
      }
      this.noduloArray.reset();
    }

    this.catchIDs();
  }

  clickCistos($event) {
    if (this.showAndHideTerms.sahCistosAll === false) {
      this.showAndHideTerms.sahCistosAll = true;
    } else {
      this.showAndHideTerms.sahCistosAll = false;
    }

    if (!this.rd1cisto.checked) {
      this.showAndHideTerms.sahCistoDinamicos = false;

      let arrayTotal = this.cistoArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        let index = this.cistoArray.length - 1

        if (index != 0) {
          this.cistoArray.removeAt(index);
        }
      }
      this.cistoArray.reset();
    }

    this.catchIDs();
  }

  clickCalcificacoes($event) {
    if (this.showAndHideTerms.sahCalcificacoesAll === false) {
      this.showAndHideTerms.sahCalcificacoesAll = true;
    } else {
      this.showAndHideTerms.sahCalcificacoesAll = false;
    }
    this.catchIDs();
  }

  clickColecao($event) {
    if (this.showAndHideTerms.sahColecaoAll === false) {
      this.showAndHideTerms.sahColecaoAll = true;
    } else {
      this.showAndHideTerms.sahColecaoAll = false;
    }
    this.catchIDs();
  }

  clickHemangiomas($event) {
    if (this.showAndHideTerms.sahHemangiomasAll === false) {
      this.showAndHideTerms.sahHemangiomasAll = true;
    } else {
      this.showAndHideTerms.sahHemangiomasAll = false;
    }

    if (!this.ckHemangioma.checked) {
      this.showAndHideTerms.sahHemangiomasDinamicos = false;

      let arrayTotal = this.hemangiomaArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        let index = this.hemangiomaArray.length - 1

        if (index != 0) {
          this.hemangiomaArray.removeAt(index);
        }
      }
      this.hemangiomaArray.reset();
    }

    this.catchIDs();
  }

  clickckDimenAumentada() {
    console.log(this.grauSelected);
    if (this.grauSelected == '10' && this.ckDimenAumentada.checked) {
      this.showAndHideTerms.sahLesoesAll = false;
      this.showAndHideTerms.sahNodulosAll = false;
      this.showAndHideTerms.sahCistosAll = false;
      this.showAndHideTerms.sahColecaoAll = false;
      this.showAndHideTerms.sahHemangiomasAll = false;
    }
    else {
      this.showAndHideTerms.sahLesoesAll = true;
    }
    this.catchIDs();
  }

  changeGraduacao($event) {
    if ($event == '10' && this.ckDimenAumentada.checked) {
      this.showAndHideTerms.sahLesoesAll = false;
      this.showAndHideTerms.sahCistosAll = false;
      this.showAndHideTerms.sahHemangiomasAll = false;
      this.showAndHideTerms.sahCalcificacoesAll = false;
      this.showAndHideTerms.sahNodulosAll = false;
    } else {
      this.showAndHideTerms.sahLesoesAll = true;
    }
    this.catchIDs();
  }

  changeDimensao($event) {
    this.catchIDs();
  }

  changeEcogenicidade(id, $event) {
    this.catchIDs();
  }

  changeSegmento(id, $event) {
    this.catchIDs();
  }

  changeContorno(id, $event) {
    this.catchIDs();
  }

  clickOptNodulo($event) {
    if ($event == 22) {
      this.showAndHideTerms.sahPlusNodulo = true;
      this.showAndHideTerms.sahNodulosDinamicos = true;
      this.showAndHideTerms.sahNoduloSimples = false;
    } else {
      this.showAndHideTerms.sahPlusNodulo = false;
      this.showAndHideTerms.sahNodulosDinamicos = false;
      this.showAndHideTerms.sahNoduloSimples = true;
    }
    if ($event == 194) {
      this.isNoduloPlural = true;
    } else {
      this.isNoduloPlural = false;
    }
    this.catchIDs();
  }

  clickOptCisto($event) {
    if ($event == 42) {
      this.showAndHideTerms.sahPlusCisto = true;
      this.showAndHideTerms.sahCistoDinamicos = true;
      this.showAndHideTerms.sahCistoSimples = false;
    } else {
      this.cistoArray.reset();
      this.showAndHideTerms.sahPlusCisto = false;
      this.showAndHideTerms.sahCistoDinamicos = false;
      this.showAndHideTerms.sahCistoSimples = true;
    }
    this.catchIDs();
  }

  clickOptHemangioma($event) {
    if ($event == 199) {
      this.showAndHideTerms.sahPlusHemangioma = true;
      this.showAndHideTerms.sahHemangiomaSimples = false;
      this.showAndHideTerms.sahHemangiomasDinamicos = true;
    } else {
      this.showAndHideTerms.sahPlusHemangioma = false;
      this.showAndHideTerms.sahHemangiomasDinamicos = false;
      this.showAndHideTerms.sahHemangiomaSimples = true;
    }
    this.catchIDs();
  }

  clickDinamic() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.countLesoes = 0;
    this.linha = 1;

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }
    if (this.rdHepatomegalia.checked) {
      this.termoSelecionado.emit(this.rdHepatomegalia.id);

      if (this.slDimensao != undefined && this.slDimensao.value != undefined) {
        this.termoSelecionado.emit(this.slDimensao.value);
      } else {
        this.termoSelecionado.emit(204);
      }
    }
    if (this.rdEsteatose.checked) {
      this.termoSelecionado.emit(this.rdEsteatose.id);

      if (this.slGraduacao != undefined && this.slGraduacao.value != undefined) {
        this.termoSelecionado.emit(this.slGraduacao.value);
      } else {
        this.termoSelecionado.emit(10);
      }

      if (this.ckDimenAumentada && this.ckDimenAumentada.checked) {
        this.termoSelecionado.emit(this.ckDimenAumentada.id);
      }
    }

    if (this.rdHepatopatia.checked) {
      this.termoSelecionado.emit(this.rdHepatopatia.id);
    }
    if (this.rdCirrose.checked) {
      this.termoSelecionado.emit(this.rdCirrose.id);
    }

    if (this.ckHemangioma != undefined && this.ckHemangioma.checked) {
      if (this.countLesoes == 0) {
        this.EmitPrimeiroHemangioma();
      } else {
        this.EmitOutroHemangioma();
      }
    }

    if (this.ckNodulo != undefined && this.ckNodulo.checked) {
      if (this.countLesoes == 0) {
        this.EmitPrimeiroNodulo();
      } else {
        this.EmitOutroNodulo();
      }
    }

    if (this.ckCisto != undefined && this.ckCisto.checked) {
      if (this.countLesoes == 0) {
        this.EmitPrimeiroCisto();
      } else {
        this.EmitOutroCisto();
      }
    }

    if (this.ckCalcificacao != undefined && this.ckCalcificacao.checked) {
      if (this.countLesoes == 0) {
        this.EmitPrimeiraCalcificacao();
      } else {
        this.EmitOutraCalcificacao();
      }
    }

    if (this.ckColecao != undefined && this.ckColecao.checked) {
      if (this.countLesoes == 0) {
        this.EmitPrimeiraColecao();
      } else {
        this.EmitOutraColecao();
      }
    }

    this.listaCompleta.emit();
  }


  EmitPrimeiroNodulo() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckNodulo.id);

    if (this.rd1nodulo != undefined && this.rd1nodulo.checked) {
      this.termoSelecionado.emit(this.rd1nodulo.value);

      this.EmitMedindoNoduloSimples();

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slNoduloEcogenicidade != undefined) {
        if (this.slNoduloEcogenicidade.value != undefined) {
          const objeto = { elemento: this.slNoduloEcogenicidade, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 209, value: this.ecogenicidadeSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slNoduloContorno != undefined) {
        if (this.slNoduloContorno.value != undefined) {
          const objeto = { elemento: this.slNoduloContorno, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 210, value: this.contornoSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slNoduloSegmento != undefined && this.slNoduloSegmento.value != undefined) {
        this.objetoLesao = { elemento: this.slNoduloSegmento, isPrimeiraLesao: true };
        this.termosGenericos.emit(this.objetoLesao);
      }

    } else if (this.rd2nodulos != undefined && this.rd2nodulos.checked) {
      this.termoSelecionado.emit(this.rd2nodulos.value);
      let posicao = 0;
      this.linha = 1;

      this.noduloChildren.forEach(obj => {

        if (obj.nativeElement != undefined) {

          if (posicao < 3) {
            posicao++;
          } else {
            posicao = 1;
            this.linha++;
          }

          this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
        } else {
          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
        }
      });
    } else if (this.rdNodulosMultiplos != undefined && this.rdNodulosMultiplos.checked) {
      this.termoSelecionado.emit(this.rdNodulosMultiplos.value);

      this.EmitMedindoNoduloSimples();

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slNoduloEcogenicidade != undefined) {
        if (this.slNoduloEcogenicidade.value != undefined) {
          const objeto = { elemento: this.slNoduloEcogenicidade, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 209, value: this.ecogenicidadeSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slNoduloContorno != undefined) {
        if (this.slNoduloContorno.value != undefined) {
          const objeto = { elemento: this.slNoduloContorno, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 210, value: this.contornoSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slNoduloSegmento != undefined && this.slNoduloSegmento.value != undefined) {
        this.objetoLesao = { elemento: this.slNoduloSegmento, isPrimeiraLesao: true };
        this.termosGenericos.emit(this.objetoLesao);
      }
    }
  }

  EmitMedindoNoduloSimples() {

    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inNoduloMedindo1 != undefined &&
      this.inNoduloMedindo1.nativeElement.id != undefined &&
      this.inNoduloMedindo1.nativeElement.value != '') {

      medindoValor = this.FormataDecimalNoduloSimples(this.inNoduloMedindo1.nativeElement.value);
    }

    if (this.inNoduloMedindo2 != undefined &&
      this.inNoduloMedindo2.nativeElement.id != undefined &&
      this.inNoduloMedindo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this.FormataDecimalNoduloSimples(this.inNoduloMedindo2.nativeElement.value);
      } else {
        medindoValor = this.FormataDecimalNoduloSimples(this.inNoduloMedindo2.nativeElement.value);
      }
    }

    if (this.inNoduloMedindo3 != undefined &&
      this.inNoduloMedindo3.nativeElement.id != undefined &&
      this.inNoduloMedindo3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this.FormataDecimalNoduloSimples(this.inNoduloMedindo3.nativeElement.value);
      } else {
        medindoValor = this.FormataDecimalNoduloSimples(this.inNoduloMedindo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      if (this.countLesoes <= 1) {
        isPrimeiraLesao = true;
      }
      const objetoElemento = { id: 23, value: medindoValor };
      this.objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(this.objetoLesao);
    }
  }

  EmitOutroNodulo() {
    this.countLesoes++;
    this.objetoGrupoLesao = { id: this.ckNodulo.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(this.objetoGrupoLesao);

    if (this.rd1nodulo != undefined && this.rd1nodulo.checked) {
      this.objetoGrupoLesao = { id: this.rd1nodulo.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(this.objetoGrupoLesao);

      this.EmitMedindoNoduloSimples();

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slNoduloEcogenicidade != undefined) {
        if (this.slNoduloEcogenicidade.value != undefined) {
          const objeto = { elemento: this.slNoduloEcogenicidade, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 209, value: this.ecogenicidadeSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slNoduloContorno != undefined) {
        if (this.slNoduloContorno.value != undefined) {
          const objeto = { elemento: this.slNoduloContorno, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 210, value: this.contornoSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slNoduloSegmento != undefined && this.slNoduloSegmento.value != undefined) {
        this.objetoLesao = { elemento: this.slNoduloSegmento, isPrimeiraLesao: false };
        this.termosGenericos.emit(this.objetoLesao);
      }

    } else if (this.rd2nodulos != undefined && this.rd2nodulos.checked) {
      this.objetoGrupoLesao = { id: this.rd2nodulos.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(this.objetoGrupoLesao);

      let posicao = 0;
      this.linha = 1;

      this.noduloChildren.forEach(obj => {

        if (obj.nativeElement != undefined) {

          if (posicao < 3) {
            posicao++;
          } else {
            posicao = 1;
            this.linha++;
          }
          this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.objeto);
        } else {
          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.objeto);
        }
      });

    } else if (this.rdNodulosMultiplos != undefined && this.rdNodulosMultiplos.checked) {
      this.objetoGrupoLesao = { id: this.rdNodulosMultiplos.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(this.objetoGrupoLesao);

      this.EmitMedindoNoduloSimples();

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slNoduloEcogenicidade != undefined) {
        if (this.slNoduloEcogenicidade.value != undefined) {
          const objeto = { elemento: this.slNoduloEcogenicidade, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 209, value: this.ecogenicidadeSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slNoduloContorno != undefined) {
        if (this.slNoduloContorno.value != undefined) {
          const objeto = { elemento: this.slNoduloContorno, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 210, value: this.contornoSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slNoduloSegmento != undefined && this.slNoduloSegmento.value != undefined) {
        this.objetoLesao = { elemento: this.slNoduloSegmento, isPrimeiraLesao: false };
        this.termosGenericos.emit(this.objetoLesao);
      }
    }
    this.listaCompleta.emit();
    // this.atualizaTexto.emit();
  }

  EmitPrimeiroCisto() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckCisto.id);

    if (this.rd1cisto != undefined && this.rd1cisto.checked) {
      this.termoSelecionado.emit(this.rd1cisto.value);

      if (this.inCistoMedindo != undefined && this.inCistoMedindo.nativeElement.id != undefined) {
        this.objetoLesao = { elemento: this.inCistoMedindo.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(this.objetoLesao);
      }
      if (this.slCistoSegmento != undefined && this.slCistoSegmento.value != undefined) {
        this.objetoLesao = { elemento: this.slCistoSegmento, isPrimeiraLesao: true };
        this.termosGenericos.emit(this.objetoLesao);
      }
    } else if (this.rd2cistos != undefined && this.rd2cistos.checked) {
      this.termoSelecionado.emit(this.rd2cistos.value);

      let posicao = 1;
      this.linha = 1;

      if (this.inCistoMedindo != undefined && this.inCistoMedindo.nativeElement.id != undefined) {
        this.objetoLesao = { elemento: this.inCistoMedindo.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(this.objetoLesao);
      }
      if (this.slHemangiomasSegmento != undefined && this.slHemangiomasSegmento.value != undefined) {
        this.objetoLesao = { elemento: this.slHemangiomasSegmento, isPrimeiraLesao: true };
        this.termosGenericos.emit(this.objetoLesao);
      }

      this.cistoChildren.forEach(obj => {

        if (obj.nativeElement != undefined) {
          this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
        } else {
          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
          this.linha = this.linha + 1;
        }
      });
    }

  }

  EmitOutroCisto() {
    this.countLesoes++;
    this.objetoGrupoLesao = { id: this.ckCisto.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(this.objetoGrupoLesao);

    if (this.rd1cisto != undefined && this.rd1cisto.checked) {
      this.objetoGrupoLesao = { id: this.rd1cisto.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(this.objetoGrupoLesao);

      if (this.inCistoMedindo != undefined && this.inCistoMedindo.nativeElement.id != undefined) {
        this.objetoLesao = { elemento: this.inCistoMedindo.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(this.objetoLesao);
      }
      if (this.slCistoSegmento != undefined && this.slCistoSegmento.value != undefined) {
        this.objetoLesao = { elemento: this.slCistoSegmento, isPrimeiraLesao: false };
        this.termosGenericos.emit(this.objetoLesao);
      }
    } else if (this.rd2cistos != undefined && this.rd2cistos.checked) {
      this.objetoGrupoLesao = { id: this.rd2cistos.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(this.objetoGrupoLesao);

      if (this.inCistoMedindo != undefined && this.inCistoMedindo.nativeElement.id != undefined) {
        this.objetoLesao = { elemento: this.inCistoMedindo.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(this.objetoLesao);
      }
      if (this.slCistoSegmento != undefined && this.slCistoSegmento.value != undefined) {
        this.objetoLesao = { elemento: this.slCistoSegmento, isPrimeiraLesao: false };
        this.termosGenericos.emit(this.objetoLesao);
      }

      let posicao = 1;
      this.linha = 1;

      this.cistoChildren.forEach(obj => {

        if (obj.nativeElement != undefined) {
          this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, posicao: posicao, linha: this.linha, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.objeto);
        } else {
          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, posicao: 0, linha: this.linha, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.objeto);
          this.linha = this.linha + 1;
        }
      });
    }
  }

  EmitPrimeiraCalcificacao() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckCalcificacao.id);

    if (this.inCalcificacaoMedindo != undefined && this.inCalcificacaoMedindo.nativeElement.id != undefined) {
      this.objetoLesao = { elemento: this.inCalcificacaoMedindo.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(this.objetoLesao);
    }
    if (this.slCalcificacaoSegmento != undefined && this.slCalcificacaoSegmento.value != undefined) {
      this.objetoLesao = { elemento: this.slCalcificacaoSegmento, isPrimeiraLesao: true };
      this.termosGenericos.emit(this.objetoLesao);
    }
  }

  EmitOutraCalcificacao() {
    this.countLesoes++;
    this.objetoGrupoLesao = { id: this.ckCalcificacao.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(this.objetoGrupoLesao);

    if (this.inCalcificacaoMedindo != undefined && this.inCalcificacaoMedindo.nativeElement.id != undefined) {
      this.objetoLesao = { elemento: this.inCalcificacaoMedindo.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(this.objetoLesao);
    }
    if (this.slCalcificacaoSegmento != undefined && this.slCalcificacaoSegmento.value != undefined) {
      this.objetoLesao = { elemento: this.slCalcificacaoSegmento, isPrimeiraLesao: false };
      this.termosGenericos.emit(this.objetoLesao);
    }
  }

  EmitPrimeiraColecao() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckColecao.id);

    this.EmitMedindoColecao();

    if (this.slColecaoSegmento != undefined && this.slColecaoSegmento.value != undefined) {
      this.objetoLesao = { elemento: this.slColecaoSegmento, isPrimeiraLesao: true };
      this.termosGenericos.emit(this.objetoLesao);
    }
  }

  EmitOutraColecao() {
    this.countLesoes++;
    this.objetoGrupoLesao = { id: this.ckColecao.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(this.objetoGrupoLesao);

    this.EmitMedindoColecao();

    if (this.slColecaoSegmento != undefined && this.slColecaoSegmento.value != undefined) {
      this.objetoLesao = { elemento: this.slColecaoSegmento, isPrimeiraLesao: false };
      this.termosGenericos.emit(this.objetoLesao);
    }
  }

  EmitMedindoColecao() {

    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inColecaoMedindo1 != undefined &&
      this.inColecaoMedindo1.nativeElement.id != undefined &&
      this.inColecaoMedindo1.nativeElement.value != '') {

      medindoValor = this.FormataDecimalNoduloSimples(this.inColecaoMedindo1.nativeElement.value);
    }

    if (this.inColecaoMedindo2 != undefined &&
      this.inColecaoMedindo2.nativeElement.id != undefined &&
      this.inColecaoMedindo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this.FormataDecimalNoduloSimples(this.inColecaoMedindo2.nativeElement.value);
      } else {
        medindoValor = this.FormataDecimalNoduloSimples(this.inColecaoMedindo2.nativeElement.value);
      }
    }

    if (this.inColecaoMedindo3 != undefined &&
      this.inColecaoMedindo3.nativeElement.id != undefined &&
      this.inColecaoMedindo3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this.FormataDecimalNoduloSimples(this.inColecaoMedindo3.nativeElement.value);
      } else {
        medindoValor = this.FormataDecimalNoduloSimples(this.inColecaoMedindo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      if (this.countLesoes <= 1) {
        isPrimeiraLesao = true;
      }
      const objetoElemento = { id: 202, value: medindoValor };
      this.objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(this.objetoLesao);
    }
  }

  EmitPrimeiroHemangioma() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckHemangioma.id);

    if (this.rd1hemangioma != undefined && this.rd1hemangioma.checked) {
      this.termoSelecionado.emit(this.rd1hemangioma.value);

      if (this.inHemangiomasMedindo != undefined && this.inHemangiomasMedindo.nativeElement.id != undefined) {
        this.objetoLesao = { elemento: this.inHemangiomasMedindo.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(this.objetoLesao);
      }
      if (this.slHemangiomasSegmento != undefined && this.slHemangiomasSegmento.value != undefined) {
        this.objetoLesao = { elemento: this.slHemangiomasSegmento, isPrimeiraLesao: true };
        this.termosGenericos.emit(this.objetoLesao);
      }

    } else if (this.rd2hemangiomas != undefined && this.rd2hemangiomas.checked) {
      this.termoSelecionado.emit(this.rd2hemangiomas.value);

      let posicao = 1;
      this.linha = 1;

      if (this.inHemangiomasMedindo != undefined && this.inHemangiomasMedindo.nativeElement.id != undefined) {
        this.objetoLesao = { elemento: this.inHemangiomasMedindo.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(this.objetoLesao);
      }
      if (this.slHemangiomasSegmento != undefined && this.slHemangiomasSegmento.value != undefined) {
        this.objetoLesao = { elemento: this.slHemangiomasSegmento, isPrimeiraLesao: true };
        this.termosGenericos.emit(this.objetoLesao);
      }

      this.hemangiomaChildren.forEach(obj => {

        if (obj.nativeElement != undefined) {
          this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
        } else {
          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
          this.linha = this.linha + 1;
        }
      });

    } else if (this.rdHemangiomasMultiplos != undefined && this.rdHemangiomasMultiplos.checked) {
      this.termoSelecionado.emit(this.rdHemangiomasMultiplos.value);

      if (this.inHemangiomasMedindo != undefined && this.inHemangiomasMedindo.nativeElement.id != undefined) {
        this.objetoLesao = { elemento: this.inHemangiomasMedindo.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(this.objetoLesao);
      }
      if (this.slHemangiomasSegmento != undefined && this.slHemangiomasSegmento.value != undefined) {
        this.objetoLesao = { elemento: this.slHemangiomasSegmento, isPrimeiraLesao: true };
        this.termosGenericos.emit(this.objetoLesao);
      }
    }
  }

  EmitOutroHemangioma() {
    this.countLesoes++;
    this.objetoGrupoLesao = { id: this.ckHemangioma.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(this.objetoGrupoLesao);

    if (this.rd1hemangioma != undefined && this.rd1hemangioma.checked) {
      this.objetoGrupoLesao = { id: this.rd1hemangioma.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(this.objetoGrupoLesao);

      if (this.inHemangiomasMedindo != undefined && this.inHemangiomasMedindo.nativeElement.id != undefined) {
        this.objetoLesao = { elemento: this.inHemangiomasMedindo.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(this.objetoLesao);
      }
      if (this.slHemangiomasSegmento != undefined && this.slHemangiomasSegmento.value != undefined) {
        this.objetoLesao = { elemento: this.slHemangiomasSegmento, isPrimeiraLesao: false };
        this.termosGenericos.emit(this.objetoLesao);
      }

    } else if (this.rd2hemangiomas != undefined && this.rd2hemangiomas.checked) {
      this.objetoGrupoLesao = { id: this.rd2hemangiomas.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(this.objetoGrupoLesao);

      if (this.inHemangiomasMedindo != undefined && this.inHemangiomasMedindo.nativeElement.id != undefined) {
        this.objetoLesao = { elemento: this.inHemangiomasMedindo.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(this.objetoLesao);
      }
      if (this.slHemangiomasSegmento != undefined && this.slHemangiomasSegmento.value != undefined) {
        this.objetoLesao = { elemento: this.slHemangiomasSegmento, isPrimeiraLesao: false };
        this.termosGenericos.emit(this.objetoLesao);
      }

      this.hemangiomaChildren.forEach(obj => {

        if (obj.nativeElement != undefined) {
          this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value };
          this.objeto = { elemento: this.objetofilho, linha: this.linha };
          this.lesoesDinamicas.emit(this.objeto);
          this.linha = this.linha + 1;

        } else {
          this.objetofilho = { id: obj.id, value: obj.value };
          this.objeto = { elemento: this.objetofilho, linha: this.linha };
          this.lesoesDinamicas.emit(this.objeto);
        }
      });

    } else if (this.rdHemangiomasMultiplos != undefined && this.rdHemangiomasMultiplos.checked) {
      this.objetoGrupoLesao = { id: this.rdHemangiomasMultiplos.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(this.objetoGrupoLesao);

      if (this.inHemangiomasMedindo != undefined && this.inHemangiomasMedindo.nativeElement.id != undefined) {
        this.objetoLesao = { elemento: this.inHemangiomasMedindo.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(this.objetoLesao);
      }
      if (this.slHemangiomasSegmento != undefined && this.slHemangiomasSegmento.value != undefined) {
        this.objetoLesao = { elemento: this.slHemangiomasSegmento, isPrimeiraLesao: false };
        this.termosGenericos.emit(this.objetoLesao);
      }
    }
  }

  FormataDecimalNoduloSimples(numero) {
    let retorno = numero.replace(/\.0$/, '');
    numero = numero.replace(/\.00$/, '');
    if (numero) {
      if (Number.isInteger(+numero)) {
        retorno = numero + ',0';
      } else if (!Number.isInteger(+numero)) {
        retorno = numero.replace(".", ",");
      }
    }
    return retorno;
  }
  clickImagemSegmentacao() {
    this.dialog.open(FigadoSegmentacaoComponent, {});
  }
}
