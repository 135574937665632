import { NgModule } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuUsComponent } from './menu-us.component';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { MatButtonToggleModule, MatCardModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, MatTooltipModule } from '@angular/material';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    FilterPipeModule,
    FormsModule,
    FlexLayoutModule
  ],
  exports: [MenuUsComponent],
  declarations: [MenuUsComponent]
})
export class MenuUsModule { }
