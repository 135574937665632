import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatCardModule, MatCheckboxModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule, MatRadioModule, MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {HttpClientModule} from '@angular/common/http';
import {LayoutModule} from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TextEditorModule} from '../../../../utils/textEditor/text-editor/text-editor.module';

import { TornozeloComponent } from './tornozelo.component';
import { PeleTecidoTornozeloComponent } from './TermosComponents/pele-tecido-tornozelo/pele-tecido-tornozelo.component';
import { DerrameArticularTornozeloComponent } from './TermosComponents/derrame-articular-tornozelo/derrame-articular-tornozelo.component';
import { SuperficieOsseaTornozeloComponent } from './TermosComponents/superficie-ossea-tornozelo/superficie-ossea-tornozelo.component';
import { TendoesCompartimentoAnteriorComponent } from './TermosComponents/tendoes-compartimento-anterior/tendoes-compartimento-anterior.component';
import { TendoesCompartimentoLateralComponent } from './TermosComponents/tendoes-compartimento-lateral/tendoes-compartimento-lateral.component';
import { TendoesCompartimentoMedialComponent } from './TermosComponents/tendoes-compartimento-medial/tendoes-compartimento-medial.component';
import { TendaoCalcaneoComponent } from './TermosComponents/tendao-calcaneo/tendao-calcaneo.component';
import { LigamentosTalofibularComponent } from './TermosComponents/ligamentos-talofibular/ligamentos-talofibular.component';
import { FasciaPlantarProximalComponent } from './TermosComponents/fascia-plantar-proximal/fascia-plantar-proximal.component';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LateralidadeModule } from 'src/app/utils/lateralidade/lateralidade.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    LateralidadeModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
   TornozeloComponent,
   PeleTecidoTornozeloComponent,
   DerrameArticularTornozeloComponent,
   SuperficieOsseaTornozeloComponent,
   TendoesCompartimentoAnteriorComponent,
   TendoesCompartimentoLateralComponent,
   TendoesCompartimentoMedialComponent,
   TendaoCalcaneoComponent,
   LigamentosTalofibularComponent,
   FasciaPlantarProximalComponent
  ],
  declarations: [
   TornozeloComponent,
   PeleTecidoTornozeloComponent,
   DerrameArticularTornozeloComponent,
   SuperficieOsseaTornozeloComponent,
   TendoesCompartimentoAnteriorComponent,
   TendoesCompartimentoLateralComponent,
   TendoesCompartimentoMedialComponent,
   TendaoCalcaneoComponent,
   LigamentosTalofibularComponent,
   FasciaPlantarProximalComponent
  ]
})
export class TornozeloModule { }
