import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Term } from '../models/BO/Term';
import { Exame } from '../models/BO/Exame';
import { TermResult } from '../models/BO/TermResult';
import { UserHasTextTermRequest } from '../models/BO/UserHasTextTerm';
import { TermHasTextTermResponse } from './../models/BO/TermHasTextTerm';
import { User } from '../auth/user';

@Injectable({
  providedIn: 'root'
})
export class LaudosService {

  objPost: any;

  constructor(private _http: HttpClient, private _user: User) { }

  buscaConclusaoPorTermo(term: Term): Observable<Array<TermResult>> {
    const _url = environment.nLaudoApi + 'MedicalReport/BuscaConclusaoPorTermos';
    term.companyID = this._user.getUserCompanyID();
    term.userID = this._user.getUserID();

    if (term.injuries != undefined) {
      this.objPost = {
        listTermID: term.listTermID,
        blockID: term.blockID,
        userID: term.userID,
        companyID: term.companyID,
        injuries: term.injuries
      };
    } else {
      this.objPost = {
        listTermID: term.listTermID,
        blockID: term.blockID,
        userID: term.userID,
        companyID: term.companyID,
        injuries: []
      };
    }

    console.log(JSON.stringify(this.objPost));
    return this._http.post<Array<TermResult>>(_url, this.objPost);
  }

  buscaConclusaoPorPesquisa(
    pesquisa: UserHasTextTermRequest
  ): Observable<Array<UserHasTextTermRequest>> {
    const _url = environment.nLaudoApi + 'MedicalReport/BuscaConclusaoPorPesquisa';

    const obj = {
      research: pesquisa
    };

    return this._http.post<Array<UserHasTextTermRequest>>(_url, obj);
  }

  buscarLaudoPorTermId(termId: number): Observable<TermHasTextTermResponse> {
    const _url = `${environment.nLaudoApi}MedicalReport/BuscaLaudoPorTemo/${termId}`;

    return this._http.get<TermHasTextTermResponse>(_url);
  }
}
