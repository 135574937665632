
import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-ateromatose',
  templateUrl: './ateromatose.component.html',
  styleUrls: ['./ateromatose.component.css']
})
export class AteromatoseComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdIncipiente') rdIncipiente: MatRadioButton;
  @ViewChild('rdEsparsa') rdEsparsa: MatRadioButton;
  @ViewChild('rdMultiplas') rdMultiplas: MatRadioButton;
  @ViewChild('rdAusente') rdAusente: MatRadioButton;


  constructor() { }

  ngOnInit() {
  }

  clickAusente(){
    this.catchIDs();
  }
  clickIncipiente(){
    this.catchIDs();
  }
  clickEsparsa(){
    this.catchIDs();
  }
  clickMultiplas(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }
    if (this.rdIncipiente.checked) {
      this.termoSelecionado.emit(this.rdIncipiente.id);
    }
    if (this.rdEsparsa.checked) {
      this.termoSelecionado.emit(this.rdEsparsa.id);
    }
    if (this.rdMultiplas.checked) {
      this.termoSelecionado.emit(this.rdMultiplas.id);
    }
    this.listaCompleta.emit();
  }
}
