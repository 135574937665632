import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatRadioButton} from '@angular/material';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-volume-miccional',
  templateUrl: './volume-miccional.component.html',
  styleUrls: ['./volume-miccional.component.css']
})
export class VolumeMiccionalComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdCitar') rdCitar: MatRadioButton;

  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  @ViewChild('inMedida3') inMedida3: ElementRef;
  @ViewChild('inVolume') inVolume: ElementRef;

  showAndHideTerms: any;
  phVolume = 'Volume';
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCitar: true,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNaoCitar() {
    this.showAndHideTerms.sahCitar = false;
    this.catchIDs();

  }

  clickCitar() {
    this.showAndHideTerms.sahCitar = true;
    this.catchIDs();
  }

  blurMedindo() {
    this.alteraAusentePresente();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.phVolume = 'Volume';

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }

    if (this.rdCitar.checked) {
      this.termoSelecionado.emit(this.rdCitar.id);

      this.formataMedindo();
    }

    this.listaCompleta.emit();
  }

  alteraAusentePresente() {
    if ((this.inVolume && this.inVolume.nativeElement.value != '') ||
      (this.inMedida1 && this.inMedida1.nativeElement.value != '') ||
      (this.inMedida2 && this.inMedida2.nativeElement.value != '') ||
      (this.inMedida3 && this.inMedida3.nativeElement.value != '')
    ) {
      this.rdCitar.checked = true;
    } else {
      this.rdNaoCitar.checked = true
      this.rdCitar.checked = false;
    }
  }

  formataMedindo() {
    let medindoValor = '';
    let resultado = 0;

    if (this.inMedida1 &&
      this.inMedida1.nativeElement.value != '') {

      resultado = this.inMedida1.nativeElement.value;
    }

    if (this.inMedida2 && this.inMedida2.nativeElement.value != '') {

      if (resultado > 0) {     
        resultado = resultado * this.inMedida2.nativeElement.value;
      } else {
        resultado = this.inMedida2.nativeElement.value;
      }
    }

    if (this.inMedida3 && this.inMedida3.nativeElement.value != '') {

      if (resultado > 0) {
        resultado = resultado * this.inMedida3.nativeElement.value;
      } else {
        resultado = this.inMedida3.nativeElement.value;
      }
    }

    if (resultado != 0 || this.inVolume.nativeElement.value) {
      // Volume
      resultado = resultado * 0.523;
      this.phVolume = resultado.toFixed(2).toString();

      if (this.inVolume.nativeElement.value) {
        medindoValor = this._validacoes.formataDecimal(this.inVolume.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(+resultado.toFixed(2));
      }

      const objetoElemento = { id: this.inVolume.nativeElement.id, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objetoLesao);
    }
  }
}
