import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule,
         MatFormFieldModule,
         MatToolbarModule,
         MatButtonModule,
         MatSidenavModule,
         MatIconModule,
         MatInputModule,
         MatListModule,
         MatTableModule,
         MatPaginatorModule,
         MatSortModule,
         MatGridListModule,
         MatCardModule,
         MatMenuModule,
         MatTabsModule,
         MatDatepickerModule,
         MatProgressBarModule,
         MatProgressSpinnerModule,
         MatNativeDateModule,
         MatRadioModule,
         MatCheckboxModule,
         MatSelectModule,
         MatTooltipModule, 
         MatSlideToggleModule} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from 'src/app/utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { ObsCalculadoraModule } from '../obs-calculadora/obs-calculadora.module';
import { TransvSegTercTrimestreModule } from '../transv-seg-terc-trimestre/transv-seg-terc-trimestre.module';
import { UsgMorfoPrimDopplerComponent } from './usg-morfo-prim-doppler.component';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { RiscoPreEclampsiaComponent } from './termosComponents/risco-pre-eclampsia/risco-pre-eclampsia.component';
import { VelocimetriaDopplerComponent } from './termosComponents/velocimetria-doppler/velocimetria-doppler.component';
import { UsgMorfologicaPrimeiroTrimestreModule } from '../usg-morfologica-primeiro-trimestre/usg-morfologica-primeiro-trimestre.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    ObsCalculadoraModule,
    TransvSegTercTrimestreModule,
    UsgMorfologicaPrimeiroTrimestreModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  declarations: [
    UsgMorfoPrimDopplerComponent,
    RiscoPreEclampsiaComponent,
    VelocimetriaDopplerComponent
    // AnatomiaFetalComponent,
    // RiscoFmfComponent,
    // OrientacoesAdicionaisComponent
  ],
  exports:[
    UsgMorfoPrimDopplerComponent,
    VelocimetriaDopplerComponent
  ]
})
export class UsgMorfoPrimDopplerModule { }
