import { Component, OnInit, EventEmitter, Output, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-derrame-articular-pe',
  templateUrl: './derrame-articular-pe.component.html',
  styleUrls: ['./derrame-articular-pe.component.css']
})
export class DerrameArticularPeComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChildren('derrameChildren') derrameChildren: QueryList<any>;

  volumes: ArrayDropDown[] = [
    { value: 'Pequeno', viewValue: 'Pequeno' },
    { value: 'Moderado', viewValue: 'Moderado' },
    { value: 'Acentuado', viewValue: 'Acentuado' }
  ];

  articulacoes: ArrayDropDown[] = [
    { value: 'metatarsofalângico', viewValue: 'Metatarsofalângico' },
    { value: 'interfalângico proximal', viewValue: 'Interfalângico proximal' },
    { value: 'interfalângico distal', viewValue: 'Interfalângico distal' },
    { value: 'interfalângico', viewValue: 'Interfalângico' }
  ];

  dedos: ArrayDropDown[] = [
    { value: 'hálux', viewValue: 'hálux' },
    { value: '2° dedo', viewValue: '2° dedo' },
    { value: '3° dedo', viewValue: '3° dedo' },
    { value: '4° dedo', viewValue: '4° dedo' },
    { value: '5° dedo', viewValue: '5° dedo' }
  ];

  showAndHideTerms: any;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  objeto: any;
  objetofilho: any;

  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true
    };
    this.lesoesForm = this._fb.group({
      derrameArt: this._fb.array([this.addDerrameArt()]),
    });
  }

  //Dinamico
  addDerrameArt() {
    return this._fb.group({
      articulacao: [],
      dedo: [],
      volume: []
    });
  }

  addDerrame() {
    this.derrameArray.push(this.addDerrameArt());
    this.catchIDs();
  }

  get derrameArray() {
    return <FormArray>this.lesoesForm.get('derrameArt');
  }

  removeDerrame(index) {
    this.derrameArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }


  clickAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.derrameArray.value[0].articulacao || this.derrameArray.value[0].dedo || this.derrameArray.value[0].volume)){
      this.rdPresente.checked = true;
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.value);
    }

    if (this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.value);
      this.verificaDerrame();
    }

    this.listaCompleta.emit();
  }

  verificaDerrame() {
    this.linha = 0;
    this.derrameChildren.forEach(obj => {

      if(+obj.id === 1728) this.linha++;

      this.objetofilho = { id: obj.id, value: obj.value, cenario: 1 };
      this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
      this.lesoesDinamicas.emit(this.objeto);
    });
  }

  ajustaArrayDinamico() {
    if ((this.rdPresente && !this.rdPresente.checked)) {
      const arrayTotal = this.derrameArray.length;
      for (let i  = 0; i <= arrayTotal; i++) {
        const index = this.derrameArray.length - 1
        if (index != 0) {
          this.derrameArray.removeAt(index);
        }
      }
      this.derrameArray.reset();
    }
  }

}
