import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox, MatDialog } from '@angular/material';
import { Validacoes } from '../../../../../../utils/validacoes';
import { Calculadora } from '../../../../../../utils/calculadora';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { debug } from 'console';
import { FetoDialogComponent } from 'src/app/utils/dialog/table/feto-dialog/feto-dialog.component';

@Component({
  selector: 'app-opt-embriao',
  templateUrl: './opt-embriao.component.html',
  styleUrls: ['./opt-embriao.component.css']
})
export class OptEmbriaoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdPresente') rdPresente: MatRadioButton;
  @ViewChild('rdNaoCaract') rdNaoCaract: MatRadioButton;
  @ViewChild('rdEctopica') rdEctopica: MatRadioButton;

  @ViewChild('inBcf') inBcf: ElementRef;
  @ViewChild('inCcn') inCcn: ElementRef;
  @ViewChild('ckSemBCF') ckSemBCF: MatCheckbox;


  @ViewChild('ckRota') ckRota: MatCheckbox;

  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  @ViewChild('inMedida3') inMedida3: ElementRef;

  @ViewChild('slLateralidade') slLateralidade: MatSelect;

  laterais: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' }
  ];

  showAndHideTerms: any;

  constructor(private _validacoes: Validacoes, private _calc: Calculadora, public dialog: MatDialog) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true,
      sahEctopica: false
    }
  }

  clickTabelaReferencia() {
    this.dialog.open(FetoDialogComponent, {});
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.showAndHideTerms.sahEctopica = false;
    this.catchIDs();
  }

  clickNaoCaract() {
    this.showAndHideTerms.sahPresente = false;
    this.showAndHideTerms.sahEctopica = false;
    this.catchIDs();
  }

  clickEctopica() {
    this.showAndHideTerms.sahPresente = false;
    this.showAndHideTerms.sahEctopica = true;
    this.catchIDs();
  }


  clickCkRota() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');


    if (this.rdNaoCaract && this.rdNaoCaract.checked) {
      this.termoSelecionado.emit(this.rdNaoCaract.id);
      this.formataSemana('N');
    }

    if (this.rdPresente && this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);

      if (this.inBcf && this.inBcf.nativeElement.value && !this.ckSemBCF.checked) {
        const objeto = { elemento: this.inBcf.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.inCcn && this.inCcn.nativeElement.value) {
        const comp = this._validacoes.formataDecimal(this.inCcn.nativeElement.value);
        const objetoElemento = { id: this.inCcn.nativeElement.id, value: comp, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataSemana('S');

    }

    if (this.rdEctopica && this.rdEctopica.checked) {
      this.termoSelecionado.emit(this.rdEctopica.id);

      if (this.slLateralidade && this.slLateralidade.value) {
        const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckRota && this.ckRota.checked) {
        this.termoSelecionado.emit(this.ckRota.id);
      }

      this.formataMedindo();

    }

    this.listaCompleta.emit();

  }

  formataSemana(sem: string) {
    let semaFrase = '';
    if (sem === 'S') {
      if (this.inCcn !== undefined &&
        this.inCcn.nativeElement.id !== undefined &&
        this.inCcn.nativeElement.value !== '') {

        const ccn = parseFloat(this._validacoes.formataDecimal(this.inCcn.nativeElement.value));
        semaFrase = this._calc.calculaIdadeGes(ccn);

        const objetoElemento = { id: 2034, value: semaFrase, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    } else if (sem === 'N') {

      var dmsg = +localStorage.getItem('dmsgValue');
      if (dmsg)
        semaFrase = this._calc.calculaIdadeGes(dmsg);
      else
        semaFrase = this._calc.calculaIdadeGes(100);

      const objetoElemento = { id: 2034, value: semaFrase, cenario: 0 };
      const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }


  formataMedindo() {
    let medidaFrase = '';
    let med1 = 0;
    let med2 = 0;
    let med3 = 0;

    if (this.inMedida1 !== undefined &&
      this.inMedida1.nativeElement.id !== undefined &&
      this.inMedida1.nativeElement.value !== '') {

      med1 = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);

      if (med1) {
        medidaFrase = med1.toString();
      }
    }

    if (this.inMedida2 != undefined &&
      this.inMedida2.nativeElement.id != undefined &&
      this.inMedida2.nativeElement.value != '') {
       
      med2 = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);

      if (medidaFrase != '') {
        medidaFrase = medidaFrase + ' x ' + med2;
      } else {
        medidaFrase = med2.toString();
      }
    }

    if (this.inMedida3 != undefined &&
      this.inMedida3.nativeElement.id != undefined &&
      this.inMedida3.nativeElement.value != '') {

      med3 = this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);

      if (medidaFrase != '') {
        medidaFrase = medidaFrase + ' x ' + med3;
      } else {
        medidaFrase = med3.toString();
      }
    }

    const objetoElemento = { id: '2032', value: medidaFrase, cenario: 0 };
    const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
    this.termosGenericos.emit(objeto);
  }

}
