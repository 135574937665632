import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-espaco-pleural',
  templateUrl: './espaco-pleural.component.html',
  styleUrls: ['./espaco-pleural.component.css']
})
export class EspacoPleuralComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckDerramePleu') ckDerramePleu: MatCheckbox;
  @ViewChild('ckDerrPleuDireito') ckDerrPleuDireito: MatCheckbox;
  @ViewChild('slGrauPleuDir') slGrauPleuDir: MatSelect;
  @ViewChild('ckAtelectasiaPleuDir') ckAtelectasiaPleuDir: MatCheckbox;

  @ViewChild('ckDerrPleuEsquerdo') ckDerrPleuEsquerdo: MatCheckbox;
  @ViewChild('slGraPleuEsq') slGraPleuEsq: MatSelect;
  @ViewChild('ckAtelectasiaPleuEsq') ckAtelectasiaPleuEsq: MatCheckbox;

  @ViewChild('ckDerrPleuLocu') ckDerrPleuLocu: MatCheckbox;
  @ViewChild('ckDerrPleuLocuDir') ckDerrPleuLocuDir: MatCheckbox;
  @ViewChild('slGrauLocuDir') slGrauLocuDir: MatSelect;
  @ViewChild('ckAtelectasiaLocuDir') ckAtelectasiaLocuDir: MatCheckbox;

  @ViewChild('ckDerrPleuLocuEsq') ckDerrPleuLocuEsq: MatCheckbox;
  @ViewChild('slGrauLocuEsq') slGrauLocuEsq: MatSelect;
  @ViewChild('ckAtelectasiaLocuEsq') ckAtelectasiaLocuEsq: MatCheckbox;

  @ViewChild('ckEmpiema') ckEmpiema: MatCheckbox;
  @ViewChild('ckEmpiemaDireito') ckEmpiemaDireito: MatCheckbox;
  @ViewChild('slGrauEmpiemaDir') slGrauEmpiemaDir: MatSelect;
  @ViewChild('ckAtelectasiaEmpiemaDir') ckAtelectasiaEmpiemaDir: MatCheckbox;

  @ViewChild('ckEmpiemaEsquerdo') ckEmpiemaEsquerdo: MatCheckbox;
  @ViewChild('slGrauEmpiemaEsq') slGrauEmpiemaEsq: MatSelect;
  @ViewChild('ckAtelectasiaEmpiemaEsq') ckAtelectasiaEmpiemaEsq: MatCheckbox;

  @ViewChild('ckPneumotorax') ckPneumotorax: MatRadioButton;
  @ViewChild('ckPneumoDireito') ckPneumoDireito: MatCheckbox;
  @ViewChild('slGrauPneumoDir') slGrauPneumoDir: MatSelect;
  @ViewChild('ckPneumoEsquerdo') ckPneumoEsquerdo: MatCheckbox;
  @ViewChild('slGrauPneumoEsq') slGrauPneumoEsq: MatSelect;

  @ViewChild('ckEspessamento') ckEspessamento: MatRadioButton;
  @ViewChild('slLateralidade') slLateralidade: MatSelect;

  graus: ArrayDropDown[] = [
    { value: 'Pequeno', viewValue: 'Pequeno' },
    { value: 'Moderado', viewValue: 'Moderado' },
    { value: 'Acentuado', viewValue: 'Acentuado' }
  ];

  lateralidades: ArrayDropDown[] = [
    { value: 'esquerdo', viewValue: 'Esquerdo' },
    { value: 'direito', viewValue: 'Direito' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  showAndHideTerms: any;
  countLesoes: number = 0;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahDerramePleu: false,
      sahDerrPleuDir: false,
      sahDerrPleuEsq: false,
      sahDerrPleuLocu: false,
      sahDerrPleuLocuDir: false,
      sahDerrPleuLocuEsq: false,
      sahEmpiema: false,
      sahEmpiemaDir: false,
      sahEmpiemaEsq: false,
      sahPneumo: false,
      sahPneumoDir: false,
      sahPneumoEsq: false,
      sahEspessamento: false
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahDerramePleu = false;
    this.showAndHideTerms.sahDerrPleuDir = false;
    this.showAndHideTerms.sahDerrPleuEsq = false;
    this.showAndHideTerms.sahDerrPleuLocu = false;
    this.showAndHideTerms.sahDerrPleuLocuDir = false;
    this.showAndHideTerms.sahDerrPleuLocuEsq = false;
    this.showAndHideTerms.sahEmpiema = false;
    this.showAndHideTerms.sahEmpiemaDir = false;
    this.showAndHideTerms.sahEmpiemaEsq = false;
    this.showAndHideTerms.sahPneumo = false;
    this.showAndHideTerms.sahPneumoDir = false;
    this.showAndHideTerms.sahPneumoEsq = false;
    this.showAndHideTerms.sahEspessamento = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahDerramePleu = false;
    this.showAndHideTerms.sahDerrPleuDir = false;
    this.showAndHideTerms.sahDerrPleuEsq = false;
    this.showAndHideTerms.sahDerrPleuLocu = false;
    this.showAndHideTerms.sahDerrPleuLocuDir = false;
    this.showAndHideTerms.sahDerrPleuLocuEsq = false;
    this.showAndHideTerms.sahEmpiema = false;
    this.showAndHideTerms.sahEmpiemaDir = false;
    this.showAndHideTerms.sahEmpiemaEsq = false;
    this.showAndHideTerms.sahPneumo = false;
    this.showAndHideTerms.sahPneumoDir = false;
    this.showAndHideTerms.sahPneumoEsq = false;
    this.showAndHideTerms.sahEspessamento = false;
  }

  clickDerPleu() {
    if (this.showAndHideTerms.sahDerramePleu === false) {
      this.showAndHideTerms.sahDerramePleu = true;
      this.showAndHideTerms.sahDerrPleuDir = false;
      this.showAndHideTerms.sahDerrPleuEsq = false;
    } else {
      this.showAndHideTerms.sahDerramePleu = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickDerrPleuLocu() {
    if (this.showAndHideTerms.sahDerrPleuLocu === false){
      this.showAndHideTerms.sahDerrPleuLocu = true;
      this.showAndHideTerms.sahDerrPleuLocuDir = false;
      this.showAndHideTerms.sahDerrPleuLocuEsq = false;
    } else {
      this.showAndHideTerms.sahDerrPleuLocu = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickEmpiema() {
    if (this.showAndHideTerms.sahEmpiema === false){
      this.showAndHideTerms.sahEmpiema = true;
      this.showAndHideTerms.sahEmpiemaDir = false;
      this.showAndHideTerms.sahEmpiemaEsq = false;
    } else {
      this.showAndHideTerms.sahEmpiema = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickPneumo() {
    if (this.showAndHideTerms.sahPneumo === false) {
      this.showAndHideTerms.sahPneumo = true;
      this.showAndHideTerms.sahPneumoDir = false;
      this.showAndHideTerms.sahPneumoEsq = false;
    } else {
      this.showAndHideTerms.sahPneumo = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickEspessamento() {
    if (this.showAndHideTerms.sahEspessamento === false)
      this.showAndHideTerms.sahEspessamento = true;
    else
      this.showAndHideTerms.sahEspessamento = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickDerrPleuDir() {
    if (this.showAndHideTerms.sahDerrPleuDir === false)
      this.showAndHideTerms.sahDerrPleuDir = true;
    else
      this.showAndHideTerms.sahDerrPleuDir = false;

    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  clickDerrPleuEsq() {
    if (this.showAndHideTerms.sahDerrPleuEsq === false)
      this.showAndHideTerms.sahDerrPleuEsq = true;
    else
      this.showAndHideTerms.sahDerrPleuEsq = false;

    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  clickDerrPleuLocuDir() {
    if (this.showAndHideTerms.sahDerrPleuLocuDir === false)
      this.showAndHideTerms.sahDerrPleuLocuDir = true;
    else
      this.showAndHideTerms.sahDerrPleuLocuDir = false;

    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  clickDerrPleuLocuEsq() {
    if (this.showAndHideTerms.sahDerrPleuLocuEsq === false)
      this.showAndHideTerms.sahDerrPleuLocuEsq = true;
    else
      this.showAndHideTerms.sahDerrPleuLocuEsq = false;

    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  clickEpiemaDir() {
    if (this.showAndHideTerms.sahEmpiemaDir === false)
      this.showAndHideTerms.sahEmpiemaDir = true;
    else
      this.showAndHideTerms.sahEmpiemaDir = false;

    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  clickEpiemaEsq() {
    if (this.showAndHideTerms.sahEmpiemaEsq === false)
      this.showAndHideTerms.sahEmpiemaEsq = true;
    else
      this.showAndHideTerms.sahEmpiemaEsq = false;

    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  clickPneumoDir() {
    if (this.showAndHideTerms.sahPneumoDir === false)
      this.showAndHideTerms.sahPneumoDir = true;
    else
      this.showAndHideTerms.sahPneumoDir = false;

    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  clickPneumoEsq() {
    if (this.showAndHideTerms.sahPneumoEsq === false)
      this.showAndHideTerms.sahPneumoEsq = true;
    else
      this.showAndHideTerms.sahPneumoEsq = false;

    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {
      this.emitDerrPleuDir();

      if (this.countLesoes > 0)
        this.emitDerrPleuEsqLesao();
      else
        this.emitDerrPleuEsq();

      if (this.countLesoes > 0)
        this.emitDerrPleuLocuDirLesao();
      else
        this.emitDerrPleuLocuDir();

      if (this.countLesoes > 0)
        this.emitDerrPleuLocuEsqLesao();
      else
        this.emitDerrPleuLocuEsq();

      if (this.countLesoes > 0)
        this.emitEmpiemaDirLesao();
      else
        this.emitEmpiemaDir();

      if (this.countLesoes > 0)
        this.emitEmpiemaEsqLesao();
      else
        this.emitEmpiemaEsq();

      if (this.countLesoes > 0)
        this.emitPneumoDirLesao();
      else
        this.emitPneumoDir();

      if (this.countLesoes > 0)
        this.emitPneumoEsqLesao();
      else
        this.emitPneumoEsq();

      if (this.countLesoes > 0)
        this.emitEspessamentoLesao();
      else
        this.emitEspessamento();

    }

    this.listaCompleta.emit();
  }

  emitDerrPleuDir() {
    if (this.ckDerramePleu && this.ckDerramePleu.checked
      && this.ckDerrPleuDireito && this.ckDerrPleuDireito.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckDerramePleu.id);
      // Direito
      this.termoSelecionado.emit(this.ckDerrPleuDireito.id);

      if (this.slGrauPleuDir && this.slGrauPleuDir.value) {
        const objeto = { elemento: this.slGrauPleuDir, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAtelectasiaPleuDir && this.ckAtelectasiaPleuDir.checked) {
        this.termoSelecionado.emit(this.ckAtelectasiaPleuDir.id);
      }
    }
  }

  emitDerrPleuEsq() {
    if (this.ckDerramePleu && this.ckDerramePleu.checked
      && this.ckDerrPleuEsquerdo && this.ckDerrPleuEsquerdo.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckDerramePleu.id);
      //Esquerdo
      this.termoSelecionado.emit(this.ckDerrPleuEsquerdo.id);

      if (this.slGraPleuEsq && this.slGraPleuEsq.value) {
        const objeto = { elemento: this.slGraPleuEsq, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAtelectasiaPleuEsq && this.ckAtelectasiaPleuEsq.checked) {
        this.termoSelecionado.emit(this.ckAtelectasiaPleuEsq.id);
      }
    }
  }

  emitDerrPleuEsqLesao() {
    if (this.ckDerramePleu && this.ckDerramePleu.checked
      && this.ckDerrPleuEsquerdo && this.ckDerrPleuEsquerdo.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckDerramePleu.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      //Esquerdo Lesao
      const objetoLes = { id: this.ckDerrPleuEsquerdo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoLes);

      if (this.slGraPleuEsq && this.slGraPleuEsq.value) {
        const objeto = { elemento: this.slGraPleuEsq, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAtelectasiaPleuEsq && this.ckAtelectasiaPleuEsq.checked) {
        const objeto = { id: this.ckAtelectasiaPleuEsq.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  emitDerrPleuLocuDir() {
    if (this.ckDerrPleuLocu && this.ckDerrPleuLocu.checked
      && this.ckDerrPleuLocuDir && this.ckDerrPleuLocuDir.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckDerrPleuLocu.id);
      //Direito
      this.termoSelecionado.emit(this.ckDerrPleuLocuDir.id);

      if (this.slGrauLocuDir && this.slGrauLocuDir.value) {
        const objeto = { elemento: this.slGrauLocuDir, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAtelectasiaLocuDir && this.ckAtelectasiaLocuDir.checked) {
        this.termoSelecionado.emit(this.ckAtelectasiaLocuDir.id);
      }
    }
  }

  emitDerrPleuLocuDirLesao() {
    if (this.ckDerrPleuLocu && this.ckDerrPleuLocu.checked
      && this.ckDerrPleuLocuDir && this.ckDerrPleuLocuDir.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckDerrPleuLocu.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      //Direito Lesao
      const objetoLes = { id: this.ckDerrPleuLocuDir.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoLes);

      if (this.slGrauLocuDir && this.slGrauLocuDir.value) {
        const objeto = { elemento: this.slGrauLocuDir, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAtelectasiaLocuDir && this.ckAtelectasiaLocuDir.checked) {
        const objeto = { id: this.ckAtelectasiaLocuDir.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  emitDerrPleuLocuEsq() {
    if (this.ckDerrPleuLocu && this.ckDerrPleuLocu.checked
      && this.ckDerrPleuLocuEsq && this.ckDerrPleuLocuEsq.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckDerrPleuLocu.id);
      //Direito
      this.termoSelecionado.emit(this.ckDerrPleuLocuEsq.id);

      if (this.slGrauLocuEsq && this.slGrauLocuEsq.value) {
        const objeto = { elemento: this.slGrauLocuEsq, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAtelectasiaLocuEsq && this.ckAtelectasiaLocuEsq.checked) {
        this.termoSelecionado.emit(this.ckAtelectasiaLocuEsq.id);
      }
    }
  }

  emitDerrPleuLocuEsqLesao() {
    if (this.ckDerrPleuLocu && this.ckDerrPleuLocu.checked
      && this.ckDerrPleuLocuEsq && this.ckDerrPleuLocuEsq.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckDerrPleuLocu.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      //Direito Lesao
      const objetoLes = { id: this.ckDerrPleuLocuEsq.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoLes);

      if (this.slGrauLocuEsq && this.slGrauLocuEsq.value) {
        const objeto = { elemento: this.slGrauLocuEsq, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAtelectasiaLocuEsq && this.ckAtelectasiaLocuEsq.checked) {
        const objeto = { id: this.ckAtelectasiaLocuEsq.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  emitEmpiemaDir() {
    if (this.ckEmpiema && this.ckEmpiema.checked
      && this.ckEmpiemaDireito && this.ckEmpiemaDireito.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEmpiema.id);
      //Direito
      this.termoSelecionado.emit(this.ckEmpiemaDireito.id);

      if (this.slGrauEmpiemaDir && this.slGrauEmpiemaDir.value) {
        const objeto = { elemento: this.slGrauEmpiemaDir, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAtelectasiaEmpiemaDir && this.ckAtelectasiaEmpiemaDir.checked) {
        this.termoSelecionado.emit(this.ckAtelectasiaEmpiemaDir.id);
      }
    }
  }

  emitEmpiemaDirLesao() {
    if (this.ckEmpiema && this.ckEmpiema.checked
      && this.ckEmpiemaDireito && this.ckEmpiemaDireito.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckEmpiema.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      //Direito Lesao
      const objetoLes = { id: this.ckEmpiemaDireito.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoLes);

      if (this.slGrauEmpiemaDir && this.slGrauEmpiemaDir.value) {
        const objeto = { elemento: this.slGrauEmpiemaDir, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAtelectasiaEmpiemaDir && this.ckAtelectasiaEmpiemaDir.checked) {
        const objeto = { id: this.ckAtelectasiaEmpiemaDir.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  emitEmpiemaEsq() {
    if (this.ckEmpiema && this.ckEmpiema.checked
      && this.ckEmpiemaEsquerdo && this.ckEmpiemaEsquerdo.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEmpiema.id);
      //Direito
      this.termoSelecionado.emit(this.ckEmpiemaEsquerdo.id);

      if (this.slGrauEmpiemaEsq && this.slGrauEmpiemaEsq.value) {
        const objeto = { elemento: this.slGrauEmpiemaEsq, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAtelectasiaEmpiemaEsq && this.ckAtelectasiaEmpiemaEsq.checked) {
        this.termoSelecionado.emit(this.ckAtelectasiaEmpiemaEsq.id);
      }
    }
  }

  emitEmpiemaEsqLesao() {
    if (this.ckEmpiema && this.ckEmpiema.checked
      && this.ckEmpiemaEsquerdo && this.ckEmpiemaEsquerdo.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckEmpiema.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      //Direito Lesao
      const objetoLes = { id: this.ckEmpiemaEsquerdo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoLes);

      if (this.slGrauEmpiemaEsq && this.slGrauEmpiemaEsq.value) {
        const objeto = { elemento: this.slGrauEmpiemaEsq, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAtelectasiaEmpiemaEsq && this.ckAtelectasiaEmpiemaEsq.checked) {
        const objeto = { id: this.ckAtelectasiaEmpiemaEsq.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  emitPneumoDir() {
    if (this.ckPneumotorax && this.ckPneumotorax.checked
      && this.ckPneumoDireito && this.ckPneumoDireito.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckPneumotorax.id);
      //Direito
      this.termoSelecionado.emit(this.ckPneumoDireito.id);

      if (this.slGrauPneumoDir && this.slGrauPneumoDir.value) {
        const objeto = { elemento: this.slGrauPneumoDir, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitPneumoDirLesao() {
    if (this.ckPneumotorax && this.ckPneumotorax.checked
      && this.ckPneumoDireito && this.ckPneumoDireito.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckPneumotorax.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      //Direito Lesao
      const objetoLes = { id: this.ckPneumoDireito.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoLes);

      if (this.slGrauPneumoDir && this.slGrauPneumoDir.value) {
        const objeto = { elemento: this.slGrauPneumoDir, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitPneumoEsq() {
    if (this.ckPneumotorax && this.ckPneumotorax.checked
      && this.ckPneumoEsquerdo && this.ckPneumoEsquerdo.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckPneumotorax.id);
      //Direito
      this.termoSelecionado.emit(this.ckPneumoEsquerdo.id);

      if (this.slGrauPneumoEsq && this.slGrauPneumoEsq.value) {
        const objeto = { elemento: this.slGrauPneumoEsq, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitPneumoEsqLesao() {
    if (this.ckPneumotorax && this.ckPneumotorax.checked
      && this.ckPneumoEsquerdo && this.ckPneumoEsquerdo.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckPneumotorax.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      //Direito Lesao
      const objetoLes = { id: this.ckPneumoEsquerdo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoLes);

      if (this.slGrauPneumoEsq && this.slGrauPneumoEsq.value) {
        const objeto = { elemento: this.slGrauPneumoEsq, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEspessamento() {
    if (this.ckEspessamento && this.ckEspessamento.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEspessamento.id);

      if (this.slLateralidade && this.slLateralidade.value) {
        const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEspessamentoLesao() {
    if (this.ckEspessamento && this.ckEspessamento.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckEspessamento.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLateralidade && this.slLateralidade.value) {
        const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  verificaAlterado() {
    if (!this.ckDerramePleu.checked &&
      !this.ckDerrPleuLocu.checked &&
      !this.ckEmpiema.checked &&
      !this.ckPneumotorax.checked &&
      !this.ckEspessamento.checked) {
      // Marca normal
      this.rdNormal.checked = true;
    }
  }
}