import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { Validacoes } from '../../../../../../utils/validacoes';

@Component({
  selector: 'app-musculatura-regional',
  templateUrl: './musculatura-regional.component.html',
  styleUrls: ['./musculatura-regional.component.css']
})
export class MusculaturaRegionalComponent implements OnInit {

  public showAndHideTerms: any;
  public unidadeMedida: string;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdRotura') rdRotura: MatRadioButton;
  @ViewChild('rdHematoma') rdHematoma: MatRadioButton;
  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  @ViewChild('inMedida3') inMedida3: ElementRef;
  @ViewChild('inVolume') inVolume: ElementRef;

  constructor(public readonly _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahRotura: false,
      sahHematoma: false,
      sahVolume: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public clickNormal(): any {
    this.showAndHideTerms.sahRotura = false;
    this.showAndHideTerms.sahHematoma = false;
    this.showAndHideTerms.sahVolume = false;
    this.rdNormal.checked = true;
    this.rdRotura.checked = false;
    this.rdHematoma.checked = false;
    this.catchIDs();
  }

  public clickRotura(): any {
    this.showAndHideTerms.sahRotura = true;
    this.showAndHideTerms.sahHematoma = false;
    this.showAndHideTerms.sahVolume = false;
    this.catchIDs();
  }

  public clickHematoma(): any {
    this.showAndHideTerms.sahRotura = false;
    this.showAndHideTerms.sahHematoma = true;
    this.showAndHideTerms.sahVolume = true;
    this.catchIDs();
  }

  public blurMedindo(): any {
    this.catchIDs();
  }

  formataMedindoLipoma(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedida1 != undefined &&
      this.inMedida1.nativeElement.id != undefined &&
      this.inMedida1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);
    }

    if (this.inMedida2 != undefined &&
      this.inMedida2.nativeElement.id != undefined &&
      this.inMedida2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
      }
    }

    if (this.inMedida3 != undefined &&
      this.inMedida3.nativeElement.id != undefined &&
      this.inMedida3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = { id: 1380, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdRotura.checked) {
      this.termoSelecionado.emit(this.rdRotura.value);


      if (this.inVolume && this.inVolume.nativeElement.value) {
        const objeto = { elemento: this.inVolume.nativeElement };
        this.termosGenericos.emit(objeto);
      }
      this.formataMedindoLipoma(true);
    }
    if (this.rdHematoma.checked) {
      this.termoSelecionado.emit(this.rdHematoma.value);


      if (this.inVolume && this.inVolume.nativeElement.value) {
        const objeto = { elemento: this.inVolume.nativeElement };
        this.termosGenericos.emit(objeto);
      }
      this.formataMedindoLipoma(true);
    }
    this.listaCompleta.emit();
  }
}
