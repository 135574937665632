import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material';
import { TransvSegTercDopplerComponent } from '../../transv-seg-terc-doppler.component';
import { ArteriaUterinaEsqComponent } from '../arteria-uterina-esq/arteria-uterina-esq.component';

@Component({
  selector: 'app-arterias-uterinas',
  templateUrl: './arterias-uterinas.component.html',
  styleUrls: ['./arterias-uterinas.component.css']
})
export class ArteriasUterinasComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @Output() showBlock = new EventEmitter();

  @ViewChild('ckDireita') ckDireita: MatCheckbox;
  @ViewChild('ckEsquerda') ckEsquerda: MatCheckbox;

  showAndHideBlocks: any;

  constructor(private transv: TransvSegTercDopplerComponent) { }

  ngOnInit() {
    this.showAndHideBlocks = {
      sahEsq: false,
      sahDir: false,
    };
  }

  clickEsquerda() {
    if (this.ckEsquerda.checked) {
      this.showAndHideBlocks.sahEsq = true;
      this.transv.showArtUterinaEsq = true;
    } else {
      this.showAndHideBlocks.sahEsq = false;
    }
    this.consultarAPI();
  }

  clickDireita() {
    if (this.ckDireita.checked) {
      this.showAndHideBlocks.sahDir = true;
      this.transv.showArtUterinaDir = true;
    } else {
      this.showAndHideBlocks.sahDir = false;
    }
    this.consultarAPI();
  }

  enviaTermos($event) {
    this.transv.enviaTermos($event);
  }

  adicionaSelecaoArtUterinaEsq($event) {
    this.transv.adicionaSelecaoArtUterinaEsq($event);
  }

  adicionaSelecaoArtUterinaDir($event) {
    this.transv.adicionaSelecaoArtUterinaDir($event);
  }

  consultarAPI(block?) {
    if (block == 'arteriaUterinaEsq') {
      this.transv.showArtUterinaEsq = true;
      this.transv.showArtUterinaDir = false;
    } else if (block == 'arteriaUterinaDir') {
      this.transv.showArtUterinaEsq = false;
      this.transv.showArtUterinaDir = true;
    }
    this.transv.consultarAPI();
  }
}
