import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatButtonModule,
    MatCardModule, MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule, MatRadioModule, MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatDialogModule,
    MatToolbarModule, MatTooltipModule, MatSlideToggleModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from '../../../../utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { ArteriAortaComponent } from '../arteri-aorta/arteri-aorta.component';
import { ArteriaAortaComponent } from './TermosComponents/arteria-aorta/arteria-aorta.component';
import { ArteriaRenalDireitaComponent } from './TermosComponents/arteria-renal-direita/arteria-renal-direita.component';
import { ArteriaRenalEsquerdaComponent } from './TermosComponents/arteria-renal-esquerda/arteria-renal-esquerda.component';
import { ArteriaSegmentaresDireitaComponent } from './TermosComponents/arteria-segmentares-direita/arteria-segmentares-direita.component';
import { ArteriaSegmentaresEsquerdaComponent } from './TermosComponents/arteria-segmentares-esquerda/arteria-segmentares-esquerda.component';
import { TabelaDiretoComponent } from './TermosComponents/tabela-direto/tabela-direto.component';
import { TabelaIndiretoComponent } from './TermosComponents/tabela-indireto/tabela-indireto.component';
import { RinsViasComponent } from '../../modo-b/rins-vias/rins-vias.component';
import { AbdomeTotalComponent } from '../../modo-b/abdome-total/abdome-total.component';
import { LateralidadeModule } from 'src/app/utils/lateralidade/lateralidade.module';
import { AbdomeTotalModule } from '../../modo-b/abdome-total/abdome-total.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
    imports: [
        MatExpansionModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatGridListModule,
        MatCardModule,
        DragDropModule,
        MatMenuModule,
        MatTabsModule,
        MatDatepickerModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatNativeDateModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSelectModule,
        FormsModule,
        AngularEditorModule,
        HttpClientModule,
        LayoutModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        MatDialogModule,
        TextEditorModule,
        CustomDirectiveModule,
        AbdomeTotalModule,
        LaudoFooterModule,
        LateralidadeModule,
        SharedModule,
        TextEditorJSmodule,
        MatSlideToggleModule,
    ],
    exports: [
        ArteriAortaComponent,
    ],
    declarations: [
        ArteriAortaComponent,
        ArteriaAortaComponent,
        ArteriaRenalDireitaComponent,
        ArteriaRenalEsquerdaComponent,
        ArteriaSegmentaresDireitaComponent,
        ArteriaSegmentaresEsquerdaComponent,
        TabelaDiretoComponent,
        TabelaIndiretoComponent,

    ],
    providers: [
        AbdomeTotalComponent
    ]

})
export class ArteriAortaModule { }
