import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatCheckbox, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-crescimento-fetal',
  templateUrl: './crescimento-fetal.component.html',
  styleUrls: ['./crescimento-fetal.component.css']
})
export class CrescimentoFetalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('inDBP') inDBP: ElementRef;
  @ViewChild('inDOF') inDOF: ElementRef;
  @ViewChild('inCC') inCC: ElementRef;
  @ViewChild('inCA') inCA: ElementRef;
  @ViewChild('inCUM') inCUM: ElementRef;
  @ViewChild('inCF') inCF: ElementRef;
  @ViewChild('inPeso') inPeso: ElementRef;
  @ViewChild('ckSemPreencimento') ckSemPreencimento: MatCheckbox;

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahFormulario: true
    }
    let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }
  clickSemPreenchimento(){
    if (this.showAndHideTerms.sahFormulario === false) {
      this.showAndHideTerms.sahFormulario = true;
    } else {
      this.showAndHideTerms.sahFormulario = false;
    }
    this.catchIDs();
  }

  blurParametros() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');


    if(this.ckSemPreencimento && this.ckSemPreencimento.checked){
      this.termoSelecionado.emit(this.ckSemPreencimento.id);
    } else{
      if(this.inDBP && this.inDBP.nativeElement.value){
        const objeto = { elemento: this.inDBP.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if(this.inDOF && this.inDOF.nativeElement.value){
        const objeto = { elemento: this.inDOF.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if(this.inCC && this.inCC.nativeElement.value){
        const objeto = { elemento: this.inCC.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if(this.inCA && this.inCA.nativeElement.value){
        const objeto = { elemento: this.inCA.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if(this.inCUM && this.inCUM.nativeElement.value){
        const objeto = { elemento: this.inCUM.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if(this.inCF && this.inCF.nativeElement.value){
        const objeto = { elemento: this.inCF.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if(this.inPeso && this.inPeso.nativeElement.value){
        const objeto = { elemento: this.inPeso.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
