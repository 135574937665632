import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrls: ['./cabecalho.component.css']
})
export class CabecalhoComponent implements OnInit {
  @Output() enviaCabecalhoCarotidas = new EventEmitter();
  @ViewChild('dataExame') dataExame: ElementRef;
  @ViewChild('nomePaciente') nomePaciente: ElementRef;
  @ViewChild('dataNascimento') dataNascimento: ElementRef;
  @ViewChild('numeroAtendimento') numeroAtendimento: ElementRef;
  @ViewChild('ckcopiarHtml') ckcopiarHtml: MatCheckbox;
  
  dataAtual;
  diaAtual: Date;
  showCampos = false;
  dataExameObj = '';
  nomeObj = '' ;
  dataNascObj = '' ;
  numeroAtenObj = '';
  showAndHideTerms: any;

  constructor(
    private toastr: ToastrManager,
  ) { }

  ngOnInit() {
    this.diaAtual = new Date();
    this.diaAtual.setDate(this.diaAtual.getDate());
    this.showAndHideTerms = {
      sahSem: true
    };
  }

  blurDataExame() {
    let dataExame = this.dataExame.nativeElement.value;
    if (this.dataExame){
      if (dataExame !== '') {
        this.dataExameObj = `<br/><b> Data do exame:</b> ${dataExame}`;
        this.adicionaTexto();
      } else {
        this.dataExameObj = ``;
        this.adicionaTexto();
      }
    }
  }

  blurNomePaciente() {
    let nomePaciente = this.nomePaciente.nativeElement.value;
    if (this.nomePaciente){
      if (nomePaciente !== '') {
        this.nomeObj = `<br/><div id="NomePacienteLaudo"><b> Nome do paciente:</b> ${nomePaciente}</div>`;
        this.adicionaTexto();
      } else {
        this.nomeObj = ``;
        this.adicionaTexto();
      }
    }
    this.toastr.warningToastr('Os campos Nome do paciente e Datade nascimento não serão salvos no hístorico');
  }

  blurDataNascimento() {
    let dataNascimento = this.dataNascimento.nativeElement.value;
    if (this.nomePaciente){
      if (dataNascimento !== '') {
        this.dataNascObj = `<br/><b> Data de nascimento:</b> ${dataNascimento}`;
        this.adicionaTexto();
      } else {
        this.dataNascObj = ``;
        this.adicionaTexto();
      }
    }
    this.toastr.warningToastr('Os campos Nome do paciente e Datade nascimento não serão salvos no hístorico');
  }

  blurNumeroAtendimento() {
    let numeroAtendimento = this.numeroAtendimento.nativeElement.value;
    if (this.nomePaciente){
      if (numeroAtendimento !== '') {
        this.numeroAtenObj = `<br/><b>Número de atendimento/registro:</b> ${numeroAtendimento}`;
        this.adicionaTexto();
      } else {
        this.numeroAtenObj = ``;
        this.adicionaTexto();
      }
    }
  }

  adicionaTexto(){
    const obj = {
      dataAtual: this.dataExameObj,
      nome: this.nomeObj,
      dataNascimento: this.dataNascObj,
      numeroAtendimento: this.numeroAtenObj
    }

    let text = Object.entries(obj)
    let textCompleto;

    if(text) {
      text.forEach(desc => {
        if (desc[1] !== undefined || desc[1] !== null || desc[1] !== '') {
          if(textCompleto === undefined) {
            textCompleto = desc[1];
          } else if (textCompleto !== undefined) {
            textCompleto += desc[1];
          }
        }
      });
    }

    if (textCompleto)
      this.enviaCabecalhoCarotidas.emit(`${textCompleto} <br/></br>`);
    else 
      this.enviaCabecalhoCarotidas.emit('');
  }
}
