import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-tireoide-doppler-nivel-cervical',
  templateUrl: './tireoide-doppler-nivel-cervical.component.html',
  styleUrls: ['./tireoide-doppler-nivel-cervical.component.css']
})
export class TireoideDopplerNivelCervicalComponent{

  constructor(
    public dialogRef: MatDialogRef<TireoideDopplerNivelCervicalComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }
}
