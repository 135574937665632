
import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatDialog } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { FetoDialogComponent } from 'src/app/utils/dialog/table/feto-dialog/feto-dialog.component';


@Component({
  selector: 'app-mst-feto',
  templateUrl: './mst-feto.component.html',
  styleUrls: ['./mst-feto.component.css']
})
export class MstFetoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdBatCardAusentes') rdBatCardAusentes: MatRadioButton;
  @ViewChild('slSituacao') slSituacao: MatSelect;
  @ViewChild('slApresentacao') slApresentacao: MatSelect;
  @ViewChild('slDorso') slDorso: MatSelect;
  @ViewChild('slPoloCefalico') slPoloCefalico: MatSelect;
  @ViewChild('inBatimentos') inBatimentos: ElementRef;

  showBatimentos = true;


  situacoes: ArrayDropDown[] = [
    { value: 'longitudinal', viewValue: 'Longitudinal' },
    { value: 'transversa', viewValue: 'Transversa' }
  ];

  apresentacoes: ArrayDropDown[] = [
    { value: 'cefálica', viewValue: 'Cefálica' },
    { value: 'pélvica', viewValue: 'Pélvica' },
    { value: 'córmica', viewValue: 'Córmica' }
  ];

  apresentacoesTransversa: ArrayDropDown[] = [
    { value: 'córmica', viewValue: 'Córmica' }
  ];

  dorso: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' },
    { value: 'anterior', viewValue: 'Anterior' },
    { value: 'posterior', viewValue: 'Posterior' },
    { value: 'superior', viewValue: 'Superior' },
    { value: 'inferior', viewValue: 'Inferior' },
  ];

  dorsoTransverso: ArrayDropDown[] = [
    { value: 'superior', viewValue: 'Superior' },
    { value: 'inferior', viewValue: 'Inferior' },
    { value: 'anterior', viewValue: 'Anterior' },
    { value: 'posterior', viewValue: 'Posterior' },
  ];

  polos: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' },
  ];


  constructor() { }

  ngOnInit() {
  }

  isTransversa(){
    if(this.slSituacao && this.slSituacao.value){
      if(this.slSituacao.value === 'transversa'){
        return true;
      }

    }
    return false;
  }

  clickNormal() {
    this.showBatimentos = true;
    this.catchIDs();
  }

  clickrdBatCard(){
    this.showBatimentos = false;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurBatimentos(){
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');
    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.slSituacao && this.slSituacao.value) {
      let objeto = { elemento: this.slSituacao, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.slApresentacao && this.slApresentacao.value){
      let objeto = { elemento: this.slApresentacao, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);     
    }

    if (this.slDorso && this.slDorso.value) {
      let objeto = { elemento: this.slDorso, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }
    
    if (this.slPoloCefalico && this.slPoloCefalico.value) {
      let objeto = { elemento: this.slPoloCefalico, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.inBatimentos && this.inBatimentos.nativeElement.value) {
      let objeto = { elemento: this.inBatimentos.nativeElement, inBatimentos: true};
      this.termosGenericos.emit(objeto);
    }

    this.listaCompleta.emit();
  } 
}
