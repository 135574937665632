import { Component, OnInit, Inject, Output, EventEmitter, ViewChild, ElementRef, Renderer2, HostListener, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NPSService } from '../services/nps.service';

@Component({
  selector: 'app-pesquisa',
  templateUrl: './pesquisa.component.html',
  styleUrls: ['./pesquisa.component.css']
})
export class PesquisaComponent implements OnInit {
  //id: any;
  url: SafeResourceUrl;
  @ViewChild('iframe') iframe: ElementRef;

  constructor(
    public sanitizer: DomSanitizer,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _nps: NPSService,) { }

  ngOnInit() {
    const unsafeURL = this.data.url;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeURL); 
  }

  finalizarPesquisa() {
    setTimeout(() => {
      this._nps.insertUserNps(this.data.userID);
    }, 3000);   
  }
}
