import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-mst-face',
  templateUrl: './mst-face.component.html',
  styleUrls: ['./mst-face.component.css']
})
export class MstFaceComponent implements OnInit {
  @ViewChild('orbitasOcularesAlt') orbitasOcularesAlt: MatSelect;
  @ViewChild('cristalinosAlt') cristalinosAlt: MatSelect;
  @ViewChild('ossoNasalAlt') ossoNasalAlt: MatSelect;
  @ViewChild('labiosAlt') labiosAlt: MatSelect;
  @ViewChild('pefilFaceAlt') pefilFaceAlt: MatSelect;

  @ViewChild('orbitasOcularesAlterado') orbitasOcularesAlterado: ElementRef;
  @ViewChild('cristalinosAlterado') cristalinosAlterado: ElementRef;
  @ViewChild('labiosAlterado') labiosAlterado: ElementRef;
  @ViewChild('pefilFaceAlterado') pefilFaceAlterado: ElementRef;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  showAndHideTerms: any;
  
  orbitasOculares: ArrayDropDown[] = [
    { value: 'identificadas', viewValue: 'Identificadas' },
    { value: 'alteradas: ', viewValue: 'Alteradas' },
  ];

  cristalinos: ArrayDropDown[] = [
    { value: 'identificados e de aspecto habitual', viewValue: 'Identificados e de aspecto habitual' },
    { value: 'alterados: ', viewValue: 'Alterados' },
  ];

  ossoNasal: ArrayDropDown[] = [
    { value: 'presente', viewValue: 'Presente' },
    { value: 'de dimensões normais', viewValue: 'De dimensões normais' },
    { value: 'Osso nasal hipoplásico', viewValue: 'Hipoplásico' },
    { value: 'Osso nasal ausente', viewValue: 'Ausente' },
  ];

  labios: ArrayDropDown[] = [
    { value: 'sem soluções de continuidade', viewValue: 'Sem soluções de continuidade' },
    { value: 'Presença de fenda labial', viewValue: 'Fenda labial' },
    { value: 'Presença de fenda lábio-palatina', viewValue: 'Fenda lábio-palatina' },
    { value: 'alteradas: ', viewValue: 'Alteradas' },
  ];

  pefilFace: ArrayDropDown[] = [
    { value: 'sem alterações', viewValue: 'Sem alterações' },
    { value: 'Sinais de micrognatiaa', viewValue: 'Sinais de micrognatia' },
    { value: 'Presença de bossa frontal', viewValue: 'Bossa frontal' },
    { value: 'Face com fronte inclinada', viewValue: 'Fronte inclinada' },
    { value: 'outras alterações: ', viewValue: 'Outras alterações' },
  ];
  
  constructor() { }

  ngOnInit() {

    this.orbitasOcularesAlt.value = this.orbitasOculares[0].value;
    this.cristalinosAlt.value = this.cristalinos[0].value;
    this.ossoNasalAlt.value = this.ossoNasal[0].value;
    this.labiosAlt.value = this.labios[0].value;
    this.pefilFaceAlt.value = this.pefilFace[0].value;

    this.showAndHideTerms = {
      shaOrbitasOculares: false,
      sahCristalinos: false,
      shaLabios: false,
      shaPefilFace: false,
    };
  }

  blurParametros() {
    // this.catchIDs();
  }

  verificaAlterado() {
    if (this.orbitasOcularesAlt.value === 'alteradas: ') {
      this.showAndHideTerms.shaOrbitasOculares = true;
    } else {
      this.showAndHideTerms.shaOrbitasOculares = false;
    }

    if (this.cristalinosAlt.value === 'alterados: ') {
      this.showAndHideTerms.sahCristalinos = true;
    } else {
      this.showAndHideTerms.sahCristalinos = false;
    }

    if (this.labiosAlt.value === 'alteradas: ') {
      this.showAndHideTerms.shaLabios = true;
    } else {
      this.showAndHideTerms.shaLabios = false;
    }

    if (this.pefilFaceAlt.value === 'outras alterações: ') {
      this.showAndHideTerms.shaPefilFace = true;
    } else {
      this.showAndHideTerms.shaPefilFace = false;
    } 
  }

  blur() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    let elemento;

    if (this.orbitasOcularesAlt && this.orbitasOcularesAlt.value) {
      
      if (this.orbitasOcularesAlterado && this.orbitasOcularesAlt.value === 'alteradas: ')
        elemento = { id: this.orbitasOcularesAlt.id, value: this.orbitasOcularesAlterado.nativeElement.value };
      else
        elemento = { id: this.orbitasOcularesAlt.id, value: this.orbitasOcularesAlt.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.cristalinosAlt && this.cristalinosAlt.value) {
      
      if (this.cristalinosAlterado && this.cristalinosAlt.value === 'alterados: ')
        elemento = { id: this.cristalinosAlt.id, value: this.cristalinosAlterado.nativeElement.value };
      else
        elemento = { id: this.cristalinosAlt.id, value: this.cristalinosAlt.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.ossoNasalAlt && this.ossoNasalAlt.value) {
      elemento = { id: this.ossoNasalAlt.id, value: this.ossoNasalAlt.value };
      const objeto = { elemento: elemento, isPrimeiraLesao: true };

      this.termosGenericos.emit(objeto);
    }

    if (this.labiosAlt && this.labiosAlt.value) {
      
      if (this.labiosAlterado && this.labiosAlt.value === 'alteradas: ')
        elemento = { id: this.labiosAlt.id, value: this.labiosAlterado.nativeElement.value };
      else
        elemento = { id: this.labiosAlt.id, value: this.labiosAlt.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.pefilFaceAlt && this.pefilFaceAlt.value) {
      
      if (this.pefilFaceAlterado && this.pefilFaceAlt.value === 'outras alterações: ')
        elemento = { id: this.pefilFaceAlt.id, value: this.pefilFaceAlterado.nativeElement.value };
      else
        elemento = { id: this.pefilFaceAlt.id, value: this.pefilFaceAlt.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    this.listaCompleta.emit();
  }
}
