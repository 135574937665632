import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-ax-linfonodos',
  templateUrl: './ax-linfonodos.component.html',
  styleUrls: ['./ax-linfonodos.component.css']
})
export class AxLinfonodosComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdHabituais') rdHabituais: MatRadioButton;
  @ViewChild('rdPreservada') rdPreservada: MatRadioButton;
  @ViewChild('rdReacional') rdReacional: MatRadioButton;
  @ViewChild('rdPerda') rdPerda: MatRadioButton;

  @ViewChild('slLado') slLado: MatSelect;
  @ViewChild('slLadoBi') slLadoBi: MatSelect;

  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;

  lados: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' },
    { value: 'bilateral', viewValue: 'Bilateral' },
  ];

  ladosBi: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' },
    { value: 'bilateral', viewValue: 'Bilateral' },
  ];

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahLado: false,
      sahLadoBi: false,
      sahMedida: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickHabituais() {
    this.showAndHideTerms.sahMedida = false;
    this.catchIDs();
  }

  clickPreservada() {
    this.showAndHideTerms.sahMedida = true;
    this.showAndHideTerms.sahLado = false;
    this.showAndHideTerms.sahLadoBi = true;
    this.catchIDs();
  }

  clickReacional() {
    this.showAndHideTerms.sahMedida = true;
    this.showAndHideTerms.sahLado = true;
    this.showAndHideTerms.sahLadoBi = false;
    this.catchIDs();
  }

  clickPerda() {
    this.showAndHideTerms.sahMedida = true;
    this.showAndHideTerms.sahLado = true;
    this.showAndHideTerms.sahLadoBi = false;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdHabituais && this.rdHabituais.checked) {
      this.termoSelecionado.emit(this.rdHabituais.id);
    }

    if (this.rdPreservada && this.rdPreservada.checked) {
      this.termoSelecionado.emit(this.rdPreservada.id);

      if (this.slLadoBi && this.slLadoBi.value) {
        const objeto = { elemento: this.slLadoBi, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoNodulo();
    }

    if (this.rdReacional && this.rdReacional.checked) {
      this.termoSelecionado.emit(this.rdReacional.id);

      if (this.slLado && this.slLado.value) {
        const objeto = { elemento: this.slLado, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoNodulo();
    }

    if (this.rdPerda && this.rdPerda.checked) {
      this.termoSelecionado.emit(this.rdPerda.id);

      if (this.slLado && this.slLado.value) {
        const objeto = { elemento: this.slLado, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoNodulo();
    }

    this.listaCompleta.emit();
  }

  formataMedindoNodulo() {
    let medindoValor = '';
    let isPrimeiraLesao = true;

    if (this.inMedida1 &&
      this.inMedida1.nativeElement.id &&
      this.inMedida1.nativeElement.value) {

      medindoValor = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);
    }

    if (this.inMedida2 &&
      this.inMedida2.nativeElement.id &&
      this.inMedida2.nativeElement.value) {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = { id: this.inMedida1.nativeElement.id, value: medindoValor };
      const objeto = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objeto);
    }
  }

}
