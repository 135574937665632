import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-mst-placenta',
  templateUrl: './mst-placenta.component.html',
  styleUrls: ['./mst-placenta.component.css']
})
export class MstPlacentaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slGrau') slGrau: MatSelect;

  localizacoes: ArrayDropDown[] = [
    { value: 'anterior', viewValue: 'Anterior' },
    { value: 'posterior', viewValue: 'Posterior' },
    { value: 'fúndica', viewValue: 'Fúndica' },
    { value: 'lateral direita', viewValue: 'Lateral direita' },
    { value: 'lateral esquerda', viewValue: 'Lateral esquerda' }
  ];
  
  graus: ArrayDropDown[] = [
    { value: '0', viewValue: '0' },
    { value: 'I', viewValue: 'I' },
    { value: 'II', viewValue: 'II' },
    { value: 'III', viewValue: 'III' }
  ];

  constructor() { }

  ngOnInit() {
  }

  clickDefault(){
    this.catchIDs();
  }

  
  changeSelect(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.slLocalizacao && this.slLocalizacao.value){
      let objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);     
    }

    if (this.slGrau && this.slGrau.value) {
      let objeto = { elemento: this.slGrau, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }



    this.listaCompleta.emit();
  }

}
