import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-pele-tecido-pe',
  templateUrl: './pele-tecido-pe.component.html',
  styleUrls: ['./pele-tecido-pe.component.css']
})
export class PeleTecidoPeComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdEdema') rdEdema: MatRadioButton;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;

  localizacoes: ArrayDropDown[] = [
    { value: 'dorsal', viewValue: 'Dorsal' },
    { value: 'plantar', viewValue: 'Plantar' },
    { value: 'medial', viewValue: 'Medial' },
    { value: 'lateral', viewValue: 'Lateral' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahEdema: true
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahEdema = false;
    this.catchIDs();
  }

  clickEdema() {
    this.showAndHideTerms.sahEdema = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.slLocalizacao && this.slLocalizacao.value)){
      this.rdEdema.checked = true;
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdEdema.checked) {
      this.termoSelecionado.emit(this.rdEdema.value);
      if (this.slLocalizacao && this.slLocalizacao.value) {
        const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
