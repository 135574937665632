import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-mg-indicacao',
  templateUrl: './mg-indicacao.component.html',
  styleUrls: ['./mg-indicacao.component.css']
})
export class MgIndicacaoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdRastreamento') rdRastreamento: MatRadioButton;
  @ViewChild('rdAcompanhamentoNodulo') rdAcompanhamentoNodulo: MatRadioButton;
  @ViewChild('rdComplementacao') rdComplementacao: MatRadioButton;
  @ViewChild('rdPosOperatorio') rdPosOperatorio: MatRadioButton;
  @ViewChild('rdFluxoPapilar') rdFluxoPapilar: MatRadioButton;
  @ViewChild('rdRetracaoCutanea') rdRetracaoCutanea: MatRadioButton;
  @ViewChild('rdLinfonodoAxilar') rdLinfonodoAxilar: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickDefault() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');


    if (this.rdRastreamento.checked) {
      this.termoSelecionado.emit(this.rdRastreamento.id);
    }

    if (this.rdAcompanhamentoNodulo.checked) {
      this.termoSelecionado.emit(this.rdAcompanhamentoNodulo.id);
    }

    if (this.rdComplementacao.checked) {
      this.termoSelecionado.emit(this.rdComplementacao.id);
    }

    if (this.rdPosOperatorio.checked) {
      this.termoSelecionado.emit(this.rdPosOperatorio.id);
    }

    if (this.rdFluxoPapilar.checked) {
      this.termoSelecionado.emit(this.rdFluxoPapilar.id);
    }

    if (this.rdRetracaoCutanea.checked) {
      this.termoSelecionado.emit(this.rdRetracaoCutanea.id);
    }

    if (this.rdLinfonodoAxilar.checked) {
      this.termoSelecionado.emit(this.rdLinfonodoAxilar.id);
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

}
