import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatCheckbox } from '@angular/material';


@Component({
  selector: 'app-arteria-hepatica',
  templateUrl: './arteria-hepatica.component.html',
  styleUrls: ['./arteria-hepatica.component.css']
})
export class ArteriaHepaticaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdCitar') rdCitar: MatRadioButton;

  @ViewChild('ckIR') ckIR: MatCheckbox;
  @ViewChild('inIR') inIR: ElementRef;

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCitar: true,
      sahIR: false
    };
  }

  clickNaoCitar() {
    this.showAndHideTerms.sahCitar = false;
    this.showAndHideTerms.sahIR = false;
    this.catchIDs();
  }

  clickCitar() {
    this.showAndHideTerms.sahCitar = true;
    this.catchIDs();
  }

  clickIR() {
    if (this.showAndHideTerms.sahIR === false) {
      this.showAndHideTerms.sahIR = true;
    } else {
      this.showAndHideTerms.sahIR = false;
    }
    this.alteraAusentePresente();
    this.catchIDs();
  }

  changeDefault() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }

    if (this.rdCitar.checked) {
      this.termoSelecionado.emit(this.rdCitar.id);

      if (this.ckIR && this.ckIR.checked) {
        this.termoSelecionado.emit(this.ckIR.id);

        if (this.inIR && this.inIR.nativeElement.value) {

          const objeto = { elemento: this.inIR.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

    }

    this.listaCompleta.emit();
  }

  alteraAusentePresente() {
    if (
      (this.ckIR && this.ckIR.checked)
    ) {
      this.rdCitar.checked = true;
    } else {
      this.rdNaoCitar.checked = true;
      this.rdCitar.checked = false;
    }
  }
}