import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-ax-tecido-mamario-ectopico',
  templateUrl: './ax-tecido-mamario-ectopico.component.html',
  styleUrls: ['./ax-tecido-mamario-ectopico.component.css']
})
export class AxTecidoMamarioEctopicoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdTecMama') rdTecMama: MatRadioButton;

  @ViewChild('slLado') slLado: MatSelect;

  lados: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahLado: true
    }
  }

  clickAusente() {
    this.showAndHideTerms.sahLado = false;
    this.catchIDs();
  }

  clickMamaAce() {
    this.showAndHideTerms.sahLado = true;
    this.catchIDs();
  }


  changeSelect() {
    this.mudaTrue();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente && this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdTecMama && this.rdTecMama.checked) {
      this.termoSelecionado.emit(this.rdTecMama.id);

      if (this.slLado && this.slLado.value) {
        const objeto = { elemento: this.slLado, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

  mudaTrue() {
    if (this.slLado.selectionChange) {
      this.rdTecMama.checked = true;
    }
  }

}
