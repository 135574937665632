import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tronco-cerebelo',
  templateUrl: './tronco-cerebelo.component.html',
  styleUrls: ['./tronco-cerebelo.component.css']
})
export class TroncoCerebeloComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
