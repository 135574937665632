import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../models/Helper/ArrayDropDown';


@Component({
  selector: 'app-exame-comparativo-tctorax',
  templateUrl: './exame-comparativo-tctorax.component.html',
  styleUrls: ['./exame-comparativo-tctorax.component.css']
})
export class ExameComparativoTctoraxComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdNaoDisponivel') rdNaoDisponivel: MatRadioButton;
  @ViewChild('rdDisponivel') rdDisponivel: MatRadioButton;

  @ViewChild('rdSemAlteracao') rdSemAlteracao: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('inDataUltimo') inDataUltimo: ElementRef;

  @ViewChild('slOpacidades') slOpacidades: MatSelect;
  @ViewChild('slLobosOpac') slLobosOpac: MatSelect;

  @ViewChild('slConsolidacoes') slConsolidacoes: MatSelect;
  @ViewChild('slLobosConso') slLobosConso: MatSelect;

  @ViewChild('slEspessamento') slEspessamento: MatSelect;
  @ViewChild('slLobosEspes') slLobosEspes: MatSelect;

  @ViewChild('slExtensao') slExtensao: MatSelect;
  @ViewChild('slPorcentagem') slPorcentagem: MatSelect;

  opacidades: ArrayDropDown[] = [
    { value: 'Redução das opacidades com atenuação em vidro fosco', viewValue: 'Redução das opacidades com atenuação em vidro fosco' },
    { value: 'Estabilidade das opacidades com atenuação em vidro fosco', viewValue: 'Estabilidade das opacidades com atenuação em vidro fosco' },
    { value: 'Aumento das opacidades com atenuação em vidro fosco', viewValue: 'Aumento das opacidades com atenuação em vidro fosco' },
    { value: 'Surgimento de novas opacidades com atenuação em vidro fosco', viewValue: 'Surgimento de novas opacidades com atenuação em vidro fosco' },
    { value: 'Surgimento de opacidades com atenuação em vidro fosco localizado no ', viewValue: 'Surgimento de opacidades com atenuação em vidro fosco localizado' }
  ];

  lobos: ArrayDropDown[] = [
    { value: 'lobo superior direito', viewValue: 'Lobo superior direito' },
    { value: 'lobo médio', viewValue: 'Lobo médio' },
    { value: 'lobo inferior direito', viewValue: 'Lobo inferior direito' },
    { value: 'lobo superior esquerdo', viewValue: 'Lobo superior esquerdo' },
    { value: 'lobo inferior esquerdo', viewValue: 'Lobo inferior esquerdo' }
  ];

  consolidacoes: ArrayDropDown[] = [
    { value: 'Redução das consolidações', viewValue: 'Redução das consolidações' },
    { value: 'Estabilidade das consolidações', viewValue: 'Estabilidade das consolidações' },
    { value: 'Aumento das consolidações', viewValue: 'Aumento das consolidações' },
    { value: 'Surgimento de focos de consolidação esparsos', viewValue: 'Surgimento de focos de consolidação esparsos' },
    { value: 'Surgimento de consolidação localizada no ', viewValue: 'Surgimento de consolidação localizada no' }
  ];

  espessamentos: ArrayDropDown[] = [
    { value: 'Redução do espessamento de septos interlobulares', viewValue: 'Redução do espessamento de septos interlobulares' },
    { value: 'Estabilidade do espessamento de septos interlobulares', viewValue: 'Estabilidade do espessamento de septos interlobulares' },
    { value: 'Aumento do espessamento de septos interlobulares', viewValue: 'Aumento do espessamento de septos interlobulares' },
    { value: 'Surgimento de espessamento de septos interlobulares', viewValue: 'Surgimento de espessamento de septos interlobulares' },
    { value: 'Surgimento de espessamento de septos interlobulares no ', viewValue: 'Surgimento de espessamento de septos interlobulares no' }
  ];

  extensoes: ArrayDropDown[] = [
    { value: 'Extensão do acometimento pulmonar estável', viewValue: 'Extensão do acometimento pulmonar estável' },
    { value: 'Aumento da extensão do acometimento pulmonar', viewValue: 'Aumento da extensão do acometimento pulmonar' },
    { value: 'Redução da extensão do acometimento pulmonar', viewValue: 'Redução da extensão do acometimento pulmonar' }
  ];

  porcentagens: ArrayDropDown[] = [
    { value: ' (menos de 25%)', viewValue: ' Menos de 25%' },
    { value: ' (entre 25-50%)', viewValue: ' Entre 25-50%' },
    { value: ' (maior que 50%)', viewValue: ' Maior que 50%' }
  ];

  date = new Date();
  showAndHideTerms: any;



  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahNaoDisponivel: false,
      sahAlterado: true
    };
  }

  clickNaoCitar() {
    this.showAndHideTerms.sahNaoDisponivel = false;
    this.showAndHideTerms.sahAlterado = false;
    this.catchIDs();
  }

  clickNaoDisponivel() {
    this.showAndHideTerms.sahNaoDisponivel = false;
    this.showAndHideTerms.sahAlterado = false;
    this.catchIDs();
  }

  clickDisponivel() {
    this.showAndHideTerms.sahNaoDisponivel = true;
    this.showAndHideTerms.sahAlterado = false;
    this.catchIDs();
  }

  clickSemAlteracao() {
    this.showAndHideTerms.sahNaoDisponivel = true;
    this.showAndHideTerms.sahAlterado = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahNaoDisponivel = true;
    this.showAndHideTerms.sahAlterado = true;
    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  blurData() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {

    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }

    if (this.rdNaoDisponivel.checked) {
      this.termoSelecionado.emit(this.rdNaoDisponivel.id);
    }

    if (this.rdNaoDisponivel.checked) {
      this.termoSelecionado.emit(this.rdNaoDisponivel.id);
    }

    if (this.rdDisponivel.checked) {
      this.termoSelecionado.emit(this.rdDisponivel.id);

      if (this.inDataUltimo && this.inDataUltimo.nativeElement.value) {
        const objeto = { elemento: this.inDataUltimo.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.rdSemAlteracao && this.rdSemAlteracao.checked) {
        this.termoSelecionado.emit(this.rdSemAlteracao.id);
      }

      if (this.rdAlterado && this.rdAlterado.checked) {
        let texto = '';
        this.termoSelecionado.emit(this.rdAlterado.id);

        if (this.slOpacidades && this.slOpacidades.value) {
          if (this.slOpacidades.value === 'Surgimento de opacidades com atenuação em vidro fosco localizado no ') {
            texto += '</br>- ' + this.slOpacidades.value + this.formatMultipleLobos(this.slLobosOpac.value);
          } else {
            texto = '</br>- ' + this.slOpacidades.value;
          }
        }

        if (this.slConsolidacoes && this.slConsolidacoes.value) {
          if (this.slConsolidacoes.value === 'Surgimento de consolidação localizada no ') 
            texto += '</br>- ' + this.slConsolidacoes.value + this.formatMultipleLobos(this.slLobosConso.value);
          else
            texto += '</br>- ' + this.slConsolidacoes.value;
        }

        if (this.slEspessamento && this.slEspessamento.value) {
          if (this.slEspessamento.value === 'Surgimento de espessamento de septos interlobulares no ')
            texto += '</br>- ' + this.slEspessamento.value + this.formatMultipleLobos(this.slLobosEspes.value);
          else
            texto += '</br>- ' + this.slEspessamento.value;
        }

        if (this.slExtensao && this.slExtensao.value) {
          if (this.slPorcentagem && this.slPorcentagem.value)
            texto += '</br>- ' + this.slExtensao.value + this.slPorcentagem.value;
        }

        if (texto) {
          const elemento = { id: '1945', value: texto };
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }


        // if (this.slOpacidades && this.slOpacidades.value) {
        //   if (this.slOpacidades && this.slOpacidades.value)
        //     texto += this.slOpacidades.value + this.slOpacidades.value;
        //   else
        //     texto += this.slOpacidades.value;
        // }
        // if (this.slLobos && this.slLobos.value) {
        //   const objeto = { elemento: this.slLobos, isPrimeiraLesao: true };
        //   this.termosGenericos.emit(objeto);
        // }

        // if (this.slOpacidades && this.slOpacidades.value) {
        //   if (this.slOpacidades.value === 'Surgimento de opacidades com atenuação em vidro fosco localizado no [Lobo]') {
        //     texto = 'Surgimento de opacidades com atenuação em vidro fosco localizado no ' + this.slLobos.value;
        //     const opacidade = {id: this.slOpacidades.id, value: texto};
        //     const objeto = { elemento: opacidade, isPrimeiraLesao: true };
        //     this.termosGenericos.emit(objeto);
        //   } else {
        //     const objeto = { elemento: this.slOpacidades, isPrimeiraLesao: true };
        //     this.termosGenericos.emit(objeto);
        //   }
        // }

        // if (this.slEspessamento && this.slEspessamento.value) {
        //   if (this.slEspessamento.value === 'Surgimento de espessamento de septos interlobulares no [Lobo]') {
        //     texto = 'Surgimento de espessamento de septos interlobulares no ' + this.slLobos.value;
        //     const espessamento = {id: this.slEspessamento.id, value: texto};
        //     const objeto = { elemento: espessamento, isPrimeiraLesao: true };
        //     this.termosGenericos.emit(objeto);
        //   } else {
        //     const objeto = { elemento: this.slEspessamento, isPrimeiraLesao: true };
        //     this.termosGenericos.emit(objeto);
        //   }
        // }

        // if (this.slConsolidacoes && this.slConsolidacoes.value) {
        //   if (this.slConsolidacoes.value === 'Surgimento de consolidação localizada no [Lobo]') {
        //     texto = 'Surgimento de consolidação localizada no ' + this.slLobos.value;
        //     const consolidacao = {id: this.slConsolidacoes.id, value: texto};
        //     const objeto = { elemento: consolidacao, isPrimeiraLesao: true };
        //     this.termosGenericos.emit(objeto);
        //   } else {
        //     const objeto = { elemento: this.slConsolidacoes, isPrimeiraLesao: true };
        //     this.termosGenericos.emit(objeto);
        //   }
        // }

        // if (this.slExtensao && this.slExtensao.value) {
        //   const objeto = { elemento: this.slExtensao, isPrimeiraLesao: true };
        //   this.termosGenericos.emit(objeto);
        // }

        // if (this.slPorcentagem && this.slPorcentagem.value) {
        //   const objeto = { elemento: this.slPorcentagem, isPrimeiraLesao: true };
        //   this.termosGenericos.emit(objeto);
        // }

      }
    }


    this.listaCompleta.emit();
  }


  public formatMultipleLobos(lobos: any) {
    let result: string = '';
    const arrLength = lobos.length;

    lobos.forEach(function (objArr, index) {

      if (arrLength > 1 && arrLength) {
        if (index > 0 && index) {
          if (arrLength == index + 1)
            result = result + ' e ' + objArr;
          else
            result = result + ', ' + objArr;
        } else {
          result = objArr
        }
      } else {
        result = objArr
      }
    });

    return result;
  }

}
