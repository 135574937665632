import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validacoes } from '../../../../utils/validacoes';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { TermosDinamicos } from '../../../../models/Helper/TermosDinamicos';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatCheckbox } from '@angular/material';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-carotidas',
  templateUrl: './carotidas.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./carotidas.component.css']
})

//! REFATORAR ESSE COMPONENT COMPLETAMENTE
export class CarotidasComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;
  @ViewChild('ckcopiarHtml') ckcopiarHtml: MatCheckbox;
  medidasDireita = new Array<string>();
  medidasEsquerda = new Array<string>();

  descricoes: DescricaoConclusao[] = [
    { value: 'Realizado estudo comparativo do sistema arterial carotídeo e vertebral.<br/>', blockID: 0, textTermID: 0, visivel: true },
    { value: 'Carótidas comuns, interna e externas com trajeto e calibre conservados.', blockID: 0, textTermID: 0, visivel: true },
    { value: 'Espessura do complexo médio-intimal direito:', blockID: 58, textTermID: 0, visivel: true },
    { value: 'Espessura do complexo médio-intimal esquerdo:', blockID: 59, textTermID: 0, visivel: true },
    { value: '', blockID: 54, textTermID: 0, visivel: true },
    { value: '', blockID: 55, textTermID: 0, visivel: true },
    { value: 'Não se observam placas ateromatosas.', blockID: 57, textTermID: 0, visivel: true },
    { value: '', blockID: 56, textTermID: 0, visivel: true },
    { value: '', blockID: 62, textTermID: 0, visivel: true },
    { value: 'Ao estudo Doppler os segmentos carotídeos analisados apresentam padrão espectral e velocidades normais.', blockID: 1, textTermID: 0, visivel: true },
    { value: 'Artéria vertebral direita pérvia e com fluxo cranial.', blockID: 60, textTermID: 0, visivel: true },
    { value: 'Artéria vertebral esquerda pérvia e com fluxo cranial.', blockID: 61, textTermID: 0, visivel: true }
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Estudo Doppler do sistema carotídeo e vertebral dentro dos limites da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 58, textTermID: 0, visivel: true },
    { value: '', blockID: 59, textTermID: 0, visivel: true },
    { value: '', blockID: 54, textTermID: 0, visivel: true },
    { value: '', blockID: 55, textTermID: 0, visivel: true },
    { value: '', blockID: 57, textTermID: 0, visivel: true },
    { value: '', blockID: 56, textTermID: 0, visivel: true },
    { value: '', blockID: 62, textTermID: 0, visivel: true },
    { value: '', blockID: 60, textTermID: 0, visivel: true },
    { value: '', blockID: 61, textTermID: 0, visivel: true }
  ];

  genericTermsCartotidaDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 1038, term: '[Segmento]' },
    { value: '', selected: false, termID: 1039, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 1040, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 1041, term: '[Superfície]' },
    { value: '', selected: false, termID: 1042, term: '[Espessura]' },
    { value: '', selected: false, termID: 1043, term: '[Extensão]' },
    { value: '', selected: false, termID: 1044, term: '[Estenose]' },
    { value: '', selected: false, termID: 1049, term: '[Segmento]' },
    { value: '', selected: false, termID: 1050, term: '[Velocidade]' },
    { value: '', selected: false, termID: 1052, term: '[Segmento]' },
    { value: '', selected: false, termID: 1053, term: '[Estenose]' },
    { value: '', selected: false, termID: 1054, term: '[VPS]' },
    { value: '', selected: false, termID: 1055, term: '[ACI/ACC]' },
    { value: '', selected: false, termID: 1057, term: '[Segmento]' },
    { value: '', selected: false, termID: 1060, term: '[CMI]' },
    { value: '', selected: false, termID: 1067, term: '[Graduação]' },
    { value: '', selected: false, termID: 1068, term: '[Extensão]' }
  ];

  genericTermsCartotidaEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 1070, term: '[Segmento]' },
    { value: '', selected: false, termID: 1071, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 1072, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 1073, term: '[Superfície]' },
    { value: '', selected: false, termID: 1074, term: '[Espessura]' },
    { value: '', selected: false, termID: 1075, term: '[Extensão]' },
    { value: '', selected: false, termID: 1076, term: '[Estenose]' },
    { value: '', selected: false, termID: 1081, term: '[Segmento]' },
    { value: '', selected: false, termID: 1082, term: '[Velocidade]' },
    { value: '', selected: false, termID: 1084, term: '[Segmento]' },
    { value: '', selected: false, termID: 1085, term: '[Estenose]' },
    { value: '', selected: false, termID: 1086, term: '[VPS]' },
    { value: '', selected: false, termID: 1087, term: '[ACI/ACC]' },
    { value: '', selected: false, termID: 1089, term: '[Segmento]' },
    { value: '', selected: false, termID: 1093, term: '[CMI]' },
    { value: '', selected: false, termID: 1100, term: '[Graduação]' },
    { value: '', selected: false, termID: 1090, term: '[Extensão]' }
  ];

  term = new Term();
  injuries = new Injuries();
  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  dinamicLesions: TermosDinamicos[] = [];
  dinamicLesion: any;
  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  contadorTexto = 0;
  indicacaoTexto = '';
  descPlacaDireita = '';
  conclPlacaDireita = '';
  descCarotidaDireita = '';
  conclCarotidaDireita = '';
  descPlacaEsquerda = '';
  conclPlacaEsquerda = '';
  descCarotidaEsquerda = '';
  conclCarotidaEsquerda = '';
  relacaoDireita = 0;
  relacaoEsquerda = 0;
  htmlContent: string;
  mask: string;
  tabelaReferencia = '';
  cabecalhoTexto = '';
  rodapeTexto = '';
  showAndHideTerms: any;
  diaAtual: Date;

  cabecalho = false;
  showCabecalhoRodape = false;
  rodape = false;
  editLaudoMode: boolean = false;

  medicalReportTypeID = 11;
  carotidaDireitaID = 54;
  carotidaEsquerdaID = 55;
  placaDireitaID = 56;
  placaEsquerdaID = 57;
  cmiDireitoID = 58;
  cmiEsquerdoID = 59;
  vertebralDireitoID = 60;
  vertebralEsquerdoID = 61;
  ateromatoseID = 62;
  public switchFormReport = true;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  unidadeMedida: string;

  constructor(private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    public dialog: MatDialog,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _config: ConfigLaudoService,
    public _addText: AddText,
    private _copyPaste: CopyPaste,
    private dataService:DataTermService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("carotidasAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto(true);
    //  });

    const listBlocks = [
      this.carotidaDireitaID,
      this.carotidaEsquerdaID,
      this.placaDireitaID,
      this.placaEsquerdaID,
      this.cmiDireitoID,
      this.cmiEsquerdoID,
      this.vertebralDireitoID,
      this.vertebralEsquerdoID,
      this.ateromatoseID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "carotidasMask").then((obj: any) => {
        this._serviceMedicalReport.criaLaudo(obj, false);
        this.mask = obj.mask;
        this.AdicionaTexto(true, this.newEditor);
        let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

        if (loadLaudosRule) {
          if (loadLaudosRule.active) {
            this._pouchService.createDatabase(listBlocks, "carotidas");
          }
          if (!loadLaudosRule.active) {
            this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-carotidas-doppler');
          }
        }
        if (loadLaudosRule == null) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-carotidas-doppler');
        }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }

    this.diaAtual = new Date();
    this.diaAtual.setDate(this.diaAtual.getDate());
    this.showAndHideTerms = {
      sahSem: true
    };
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(null ,this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo() {
    this._pouchService.getAll("carotidasAnalise").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.relacaoDireita = 0;
    this.relacaoEsquerda = 0;
    this.indicacaoTexto = "";
    this.showForm = false;
    this.tabelaReferencia = "";
    this.medidasDireita = [];
    this.medidasEsquerda = [];
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(true, this.newEditor);
      this.showForm = true;
    }, 500);
  };

  limparListTerm() {
    this.term.listTermID = new Array<number>();
    this.cenarioCorrente = 0;
    this.dinamicLesions = [];
    this.descPlacaDireita = '';
    this.conclPlacaDireita = '';
    this.descCarotidaDireita = '';
    this.conclCarotidaDireita = '';
    this.descPlacaEsquerda = '';
    this.conclPlacaEsquerda = '';
    this.descCarotidaEsquerda = '';
    this.conclCarotidaEsquerda = '';
    this.contadorTexto = 0;
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;
    this.dinamicLesions = [];
    this.descPlacaDireita = '';
    this.conclPlacaDireita = '';
    this.descCarotidaDireita = '';
    this.conclCarotidaDireita = '';
    this.descPlacaEsquerda = '';
    this.conclPlacaEsquerda = '';
    this.descCarotidaEsquerda = '';
    this.conclCarotidaEsquerda = '';
    this.contadorTexto = 0;

    this.genericTermsCartotidaDireita = [
      { value: '', selected: false, termID: 1038, term: '[Segmento]' },
      { value: '', selected: false, termID: 1039, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 1040, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 1041, term: '[Superfície]' },
      { value: '', selected: false, termID: 1042, term: '[Espessura]' },
      { value: '', selected: false, termID: 1043, term: '[Extensão]' },
      { value: '', selected: false, termID: 1044, term: '[Estenose]' },
      { value: '', selected: false, termID: 1049, term: '[Segmento]' },
      { value: '', selected: false, termID: 1050, term: '[Velocidade]' },
      { value: '', selected: false, termID: 1052, term: '[Segmento]' },
      { value: '', selected: false, termID: 1053, term: '[Estenose]' },
      { value: '', selected: false, termID: 1054, term: '[VPS]' },
      { value: '', selected: false, termID: 1055, term: '[ACI/ACC]' },
      { value: '', selected: false, termID: 1057, term: '[Segmento]' },
      { value: '', selected: false, termID: 1060, term: '[CMI]' },
      { value: '', selected: false, termID: 1067, term: '[Graduação]' },
      { value: '', selected: false, termID: 1068, term: '[Extensão]' }
    ];

    this.genericTermsCartotidaEsquerda = [
      { value: '', selected: false, termID: 1070, term: '[Segmento]' },
      { value: '', selected: false, termID: 1071, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 1072, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 1073, term: '[Superfície]' },
      { value: '', selected: false, termID: 1074, term: '[Espessura]' },
      { value: '', selected: false, termID: 1075, term: '[Extensão]' },
      { value: '', selected: false, termID: 1076, term: '[Estenose]' },
      { value: '', selected: false, termID: 1081, term: '[Segmento]' },
      { value: '', selected: false, termID: 1082, term: '[Velocidade]' },
      { value: '', selected: false, termID: 1084, term: '[Segmento]' },
      { value: '', selected: false, termID: 1085, term: '[Estenose]' },
      { value: '', selected: false, termID: 1086, term: '[VPS]' },
      { value: '', selected: false, termID: 1087, term: '[ACI/ACC]' },
      { value: '', selected: false, termID: 1089, term: '[Segmento]' },
      { value: '', selected: false, termID: 1093, term: '[CMI]' },
      { value: '', selected: false, termID: 1100, term: '[Graduação]' },
      { value: '', selected: false, termID: 1090, term: '[Extensão]' }
    ];
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    }
    else if ($event == 'CleanList') {
      this.limparListTerm();
    } else {
      this.term.listTermID.push($event);
    }
  }

  adicionaRemoveCampos() {
    if (this.ckcopiarHtml.checked === false) {
      this.showCabecalhoRodape = true;
    } else {
      this.cabecalhoTexto = "";
      this.rodapeTexto = "";
      this.showCabecalhoRodape = false;
      this.AdicionaTexto(null ,this.newEditor);
    }

    this.scrollBottom();
  }

  scrollBottom() {
    setTimeout(() => {
      window.scroll({
        top: 1000,
        left: 0,
        behavior: 'smooth'
      })
    }, 100)
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);
      this.injuries.position = $event.cenario;

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      if (this.injuries.listInjuries.length > 0) {
        this.injuries.listInjuries.forEach(obj => {
          injuryToAdd.listInjuries.push(obj);
        });

        injuryToAdd.position = this.injuries.position
        this.term.injuries.push(injuryToAdd);
      }

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);
      this.injuries.position = $event.cenario;

      this.cenarioCorrente = $event.cenario;
    }
  }

  adicionaSelecaoCarotidaDireita($event) {
    this.genericTermsCartotidaDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.posicao };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoCarotidaEsquerda($event) {
    this.genericTermsCartotidaEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.posicao };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaArrayLesaoDireito($event) {
    const valor = this._validacoes.formataDecimal($event.value);

    switch (+$event.id) {
      // PLACA
      case 1038:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1038,
          term: '[Segmento]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1039:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1039,
          term: '[Ecogenicidade]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1040:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1040,
          term: '[Ecotextura]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1041:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1041,
          term: '[Superfície]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1042:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1042,
          term: '[Espessura]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1043:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1043,
          term: '[Extensão]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1044:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1044,
          term: '[Estenose]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // TORTOSIDADE
      case 1049:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1049,
          term: '[Segmento]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1050:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1050,
          term: '[Velocidade]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // ENDOPROTESE
      case 1052:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1052,
          term: '[Segmento]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1053:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1053,
          term: '[Estenose]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1054:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1054,
          term: '[VPS]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1055:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1055,
          term: '[ACI/ACC]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // TROMBOSE
      case 1057:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1057,
          term: '[Segmento]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1068:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1068,
          term: '[Extensão]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }
  }

  adicionaArrayLesaoEsquerdo($event) {
    const valor = this._validacoes.formataDecimal($event.value);

    switch (+$event.id) {
      // PLACA
      case 1070:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1070,
          term: '[Segmento]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1071:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1071,
          term: '[Ecogenicidade]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1072:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1072,
          term: '[Ecotextura]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1073:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1073,
          term: '[Superfície]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1074:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1074,
          term: '[Espessura]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1075:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1075,
          term: '[Extensão]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1076:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1076,
          term: '[Estenose]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // TORTOSIDADE
      case 1081:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1081,
          term: '[Segmento]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1082:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1082,
          term: '[Velocidade]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // ENDOPROTESE
      case 1084:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1084,
          term: '[Segmento]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1085:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1085,
          term: '[Estenose]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1086:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1086,
          term: '[VPS]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1087:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1087,
          term: '[ACI/ACC]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // TROMBOSE
      case 1089:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1089,
          term: '[Segmento]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case 1090:
        this.dinamicLesion = {
          value: valor,
          selected: true,
          termID: 1090,
          term: '[Extensão]',
          posicao: +$event.posicao
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }
  }

  consultarAPI($event) {
      let desc;
      if (+$event === this.cmiDireitoID || +$event === this.vertebralDireitoID) {
        this.term.blockID = +$event;
        this.dataService.setTermos(this.term.listTermID,this.term.blockID)
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === +$event && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoCarotidaDireita(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === +$event);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: +$event,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoCarotidaDireita(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === +$event);

          if (obj.blockID === 0) {
            if (conclusaoAll && conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === +$event) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(null ,this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else
      if (+$event === this.placaDireitaID || +$event === this.carotidaDireitaID) {
        this.term.blockID = +$event;
        this.dataService.setTermos(this.term.listTermID,this.term.blockID)
        const placaDesc = this.descricoes.find(x => x.blockID === this.placaDireitaID);
        placaDesc.value = '';
        const placaConcl = this.conclusoes.find(x => x.blockID === this.placaDireitaID);
        placaConcl.value = '';
        const carotidaDesc = this.descricoes.find(x => x.blockID === this.carotidaDireitaID);
        carotidaDesc.value = '';
        const carotidaConcl = this.conclusoes.find(x => x.blockID === this.carotidaDireitaID);
        carotidaConcl.value = '';

        if (this.term.injuries.length === 0) {
          this.term.injuries.push(this.injuries);
        }
        this.cenarioCorrente++;
        // this.contadorTexto = 0;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === +$event && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              // ID de placa
              if (+obj.textTermID === 870) {
                if (this.descPlacaDireita) {
                  espaco = '</br>';
                }
                obj.value = this.descPlacaDireita + espaco + this.MontaDescricaoLesoesDireita(this.termResultsAPI[0].text, this.contadorTexto);
                this.descPlacaDireita = obj.value;
              } else {
                if (this.descCarotidaDireita) {
                  espaco = '</br>';
                }
                obj.value = this.descCarotidaDireita + espaco + this.MontaDescricaoLesoesDireita(this.termResultsAPI[0].text, this.contadorTexto);
                this.descCarotidaDireita = obj.value;
              }

              let index = this.descricoes.findIndex(x => x.blockID === +$event);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

              // Removendo frase
              if (+$event === this.placaDireitaID) {

                // Remove desc de negação de placa
                const placaEsquerda = this.descricoes.find(x => x.blockID === this.placaEsquerdaID);
                if (placaEsquerda.value === 'Não se observam placas ateromatosas.') {
                  placaEsquerda.value = '';
                }
              }

              if(!this.newEditor) {
                this.AdicionaTexto(null ,this.newEditor);
              } else {
                this.atualizaLinha({
                  blockId: this.term.blockID,
                  descricao: desc,
                  conclusao: conclusao.value,
                  conclusaoNull: conclusaoNull.value
                });
              }
            }
          });

          let obj = {
            value: '',
            blockID: +$event,
            textTermID: this.termResultsAPI[0].id
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;

          // ID de placa
          if (this.termConclusionAPI[0].conclusion) {
            if (+obj.textTermID === 870) {
              if (this.conclPlacaDireita) {
                espaco = '</br>';
              }
              obj.value = this.conclPlacaDireita + espaco + this.MontaConclusaoLesoesDireita(this.termConclusionAPI[0].conclusion, this.contadorTexto);
              this.conclPlacaDireita = obj.value;
            } else {
              if (this.conclCarotidaDireita) {
                espaco = '</br>';
              }
              obj.value = this.conclCarotidaDireita + espaco + this.MontaConclusaoLesoesDireita(this.termConclusionAPI[0].conclusion, this.contadorTexto);
              this.conclCarotidaDireita = obj.value;
            }
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(this.termConclusionAPI[0].conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }

          // Adiciona o count para comprar com a posicao
          this.contadorTexto++;
          this.contadorTexto++;

          // Ajusta conclusão
          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === +$event);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === +$event) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(null ,this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if ($event === this.cmiEsquerdoID || $event === this.vertebralEsquerdoID) {
        this.term.blockID = +$event;
        this.dataService.setTermos(this.term.listTermID,this.term.blockID)
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === +$event && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoCarotidaEsquerda(resultado.text);
              });

              let index = this.descricoes.findIndex(x => x.blockID === +$event);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: +$event,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoCarotidaEsquerda(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === +$event);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === +$event) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(null ,this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } if (+$event === this.placaEsquerdaID || +$event === this.carotidaEsquerdaID) {
        this.term.blockID = +$event;
        this.dataService.setTermos(this.term.listTermID,this.term.blockID)
        // Remove as frases
        const placaDesc = this.descricoes.find(x => x.blockID === this.placaEsquerdaID);
        placaDesc.value = '';
        const placaConcl = this.conclusoes.find(x => x.blockID === this.placaEsquerdaID);
        placaConcl.value = '';
        const carotidaDesc = this.descricoes.find(x => x.blockID === this.carotidaEsquerdaID);
        carotidaDesc.value = '';
        const carotidaConcl = this.conclusoes.find(x => x.blockID === this.carotidaEsquerdaID);
        carotidaConcl.value = '';

        if (this.term.injuries.length === 0) {
          this.term.injuries.push(this.injuries);
        }
        this.cenarioCorrente++;
        // this.contadorTexto = 0;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === +$event && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              // ID de placa
              if (+obj.textTermID === 886) {
                if (this.descPlacaEsquerda) {
                  espaco = '</br>';
                }
                obj.value = this.descPlacaEsquerda + espaco + this.MontaDescricaoLesoesEsquerda(this.termResultsAPI[0].text, this.contadorTexto);
                this.descPlacaEsquerda = obj.value;
              } else {
                if (this.descCarotidaEsquerda) {
                  espaco = '</br>';
                }
                obj.value = this.descCarotidaEsquerda + espaco + this.MontaDescricaoLesoesEsquerda(this.termResultsAPI[0].text, this.contadorTexto);
                this.descCarotidaEsquerda = obj.value;
              }

              let index = this.descricoes.findIndex(x => x.blockID === +$event);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: +$event,
            textTermID: this.termResultsAPI[0].id
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;

          // ID de placa
          if (this.termConclusionAPI[0].conclusion) {
            if (+obj.textTermID === 886) {
              if (this.conclPlacaEsquerda) {
                espaco = '</br>';
              }
              obj.value = this.conclPlacaEsquerda + espaco + this.MontaConclusaoLesoesEsquerda(this.termConclusionAPI[0].conclusion, this.contadorTexto);
              this.conclPlacaEsquerda = obj.value;
            } else {
              if (this.conclCarotidaEsquerda) {
                espaco = '</br>';
              }
              obj.value = this.conclCarotidaEsquerda + espaco + this.MontaConclusaoLesoesEsquerda(this.termConclusionAPI[0].conclusion, this.contadorTexto);
              this.conclCarotidaEsquerda = obj.value;
            }
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(this.termConclusionAPI[0].conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }

          // Adiciona o count para comprar com a posicao
          this.contadorTexto++;
          this.contadorTexto++;

          // Ajusta conclusão
          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === +$event);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === +$event) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(null ,this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if ($event === this.ateromatoseID) {
        this.term.blockID = +$event;
        this.dataService.setTermos(this.term.listTermID,this.term.blockID)
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === +$event && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoes.findIndex(x => x.blockID === +$event);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
            }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: +$event,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === +$event);
          const descricaoPlaca = this.descricoes.find(x => x.blockID === 57);

          // TODO - Negação de placa
          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === +$event) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
            if (descricaoPlaca.value === 'Não se observam placas ateromatosas.') {
              descricaoPlaca.value = '';
            }
          }

          if(!this.newEditor) {
            this.AdicionaTexto(null ,this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
  }

  MontaDescricaoCarotidaDireita(texto) {
    let textoRetorno = '';

    //if (contadorTexto == 0) {
    this.term.listTermID.forEach(term => {
      this.genericTermsCartotidaDireita.forEach(generic => {
        generic.selected = true;
        textoRetorno = texto.replace(generic.term, generic.value);
        texto = textoRetorno;
      });
    });
    //}
    return texto;
  }

  MontaConclusaoCarotidaDireita(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
          this.genericTermsCartotidaDireita.forEach(generic => {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
          });
      });
    }
    return texto;
  }

  MontaDescricaoLesoesDireita(texto, contadorTexto) {
    let textoRetorno = '';
    const lista = this.term.injuries.find(x => x.position === contadorTexto);
    lista.listInjuries.forEach(term => {
      if (+term === 1038 || +term === 1039 || +term === 1040 || +term === 1041 ||
        +term === 1042 || +term === 1043 || +term === 1044 || +term === 1049 ||
        +term === 1050 || +term === 1052 || +term === 1053 ||
        +term === 1054 || +term === 1055 || +term === 1057 || +term === 1060 ||
        +term === 1067 || +term === 1068) {
        this.dinamicLesions.forEach(generic => {
          if (generic.termID == term && generic.posicao == lista.position) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;

            // Ajuste da frase de doppler
            if (+term === 1044 || +term === 1053) {
              const doppler = this.descricoes.find(x => x.blockID === 1);
              if (generic.value === 'sem determinar estenose hemodinamicamente significativa (< 50%)') {
                doppler.value = 'Ao estudo Doppler os segmentos carotídeos analisados apresentam padrão espectral e velocidades normais.';
              } else {
                doppler.value = 'Ao estudo Doppler os demais segmentos carotídeos analisados apresentam padrão espectral e velocidades normais.';
              }
            }
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoLesoesDireita(texto, contadorTexto) {
    let textoRetorno = '';
    const lista = this.term.injuries.find(x => x.position === contadorTexto);

    lista.listInjuries.forEach(term => {
      if (+term === 1038 || +term === 1039 || +term === 1040 || +term === 1041 ||
        +term === 1042 || +term === 1043 || +term === 1044 || +term === 1049 ||
        +term === 1050 || +term === 1052 || +term === 1053 ||
        +term === 1054 || +term === 1055 || +term === 1057 || +term === 1060 ||
        +term === 1067 || +term === 1068) {
        this.dinamicLesions.forEach(generic => {
          if (generic.termID == term && generic.posicao == lista.position) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoCarotidaEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
        this.genericTermsCartotidaEsquerda.forEach(generic => {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
        });
    });

    return texto;
  }

  MontaConclusaoCarotidaEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
        this.genericTermsCartotidaEsquerda.forEach(generic => {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
        });
    });

    return texto;
  }

  MontaDescricaoLesoesEsquerda(texto, contadorTexto) {
    let textoRetorno = '';
    const lista = this.term.injuries.find(x => x.position === contadorTexto);

    lista.listInjuries.forEach(term => {
      if (+term === 1081 || +term === 1082 || +term === 1084 || +term === 1085 ||
        +term === 1086 || +term === 1087 || +term === 1089 || +term === 1090 ||
        +term === 1070 || +term === 1071 || +term === 1072 ||
        +term === 1073 || +term === 1074 || +term === 1075 || +term === 1076) {
        this.dinamicLesions.forEach(generic => {
          if (generic.termID == term && generic.posicao == lista.position) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;

            // Ajuste da frase de doppler
            if (+term === 1076 || +term === 1085) {
              const doppler = this.descricoes.find(x => x.blockID === 1);
              if (generic.value === 'sem determinar estenose hemodinamicamente significativa (< 50%)') {
                doppler.value = 'Ao estudo Doppler os segmentos carotídeos analisados apresentam padrão espectral e velocidades normais.';
              } else {
                doppler.value = 'Ao estudo Doppler os demais segmentos carotídeos analisados apresentam padrão espectral e velocidades normais.';
              }
            }
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoLesoesEsquerda(texto, contadorTexto) {
    let textoRetorno = '';
    const lista = this.term.injuries.find(x => x.position === contadorTexto);

    lista.listInjuries.forEach(term => {
      if (+term === 1081 || +term === 1082 || +term === 1084 || +term === 1085 ||
        +term === 1086 || +term === 1087 || +term === 1089 || +term === 1090 ||
        +term === 1070 || +term === 1071 || +term === 1072 ||
        +term === 1073 || +term === 1074 || +term === 1075 || +term === 1076) {
        this.dinamicLesions.forEach(generic => {
          if (generic.termID == term && generic.posicao == lista.position) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  AtualizaLesao($event) {

    if ($event === 'direita') {
      // Ajustando frases de sem lesão
      const placaDesc = this.descricoes.find(x => x.blockID === this.placaDireitaID);
      placaDesc.value = '';
      const placaConcl = this.conclusoes.find(x => x.blockID === this.placaDireitaID);
      placaConcl.value = '';
      const carotidaDesc = this.descricoes.find(x => x.blockID === this.carotidaDireitaID);
      carotidaDesc.value = '';
      const carotidaConcl = this.conclusoes.find(x => x.blockID === this.carotidaDireitaID);
      carotidaConcl.value = '';
    } else if ($event === 'esquerda') {
      // Ajustando frases de sem lesão
      const placaDesc = this.descricoes.find(x => x.blockID === this.placaEsquerdaID);
      placaDesc.value = '';
      const placaConcl = this.conclusoes.find(x => x.blockID === this.placaEsquerdaID);
      placaConcl.value = '';
      const carotidaDesc = this.descricoes.find(x => x.blockID === this.carotidaEsquerdaID);
      carotidaDesc.value = '';
      const carotidaConcl = this.conclusoes.find(x => x.blockID === this.carotidaEsquerdaID);
      carotidaConcl.value = '';
    }

    // Ajusta conclusão normal
    const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
    const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);

    if (conclusaoAll.length <= 0) {
      conclusaoNull.value = 'Estudo Doppler do sistema carotídeo e vertebral dentro dos limites da normalidade.';
    }
  }

  atribuiMedidas($element) {
    // Preenchendo as medidas
    let accDir, aciDir, accEsq, aciEsq: number;
    const value = this._validacoes.formataDecimal($element.value);

    if ($element.id === 'ACCSitDireita') {
      this.medidasDireita[0] = value;
    } else if ($element.id === 'ACCSitEsquerda') {
      this.medidasEsquerda[0] = value;
    } else if ($element.id === 'ACCDistDireita') {
      this.medidasDireita[1] = value;
    } else if ($element.id === 'ACCDistEsquerda') {
      this.medidasEsquerda[1] = value;
    } else if ($element.id === 'ACISistDireita') {
      this.medidasDireita[2] = value;
    } else if ($element.id === 'ACISistEsquerda') {
      this.medidasEsquerda[2] = value;
    } else if ($element.id === 'ACIDistDireita') {
      this.medidasDireita[3] = value;
    } else if ($element.id === 'ACIDistEsquerda') {
      this.medidasEsquerda[3] = value;
    } else if ($element.id === 'ACESistDireita') {
      this.medidasDireita[4] = value;
    } else if ($element.id === 'ACESistEsquerda') {
      this.medidasEsquerda[4] = value;
    } else if ($element.id === 'ACEDistDireita') {
      this.medidasDireita[5] = value;
    } else if ($element.id === 'ACEDistEsquerda') {
      this.medidasEsquerda[5] = value;
    } else if ($element.id === 'VertebralSistDireita') {
      this.medidasDireita[6] = value;
    } else if ($element.id === 'VertebralSistEsquerda') {
      this.medidasEsquerda[6] = value;
    } else if ($element.id === 'VertebralDistDireita') {
      this.medidasDireita[7] = value;
    } else if ($element.id === 'VertebralDistEsquerda') {
      this.medidasEsquerda[7] = value;
    }

    //Logica para calcular a relção
    if (this.medidasDireita[0] && this.medidasDireita[2]) {
      accDir = parseFloat(this.medidasDireita[0].replace(",", "."));
      aciDir = parseFloat(this.medidasDireita[2].replace(",", "."));

      this.relacaoDireita = aciDir / accDir
    }

    if (this.medidasEsquerda[0] && this.medidasEsquerda[2]) {
      accEsq = parseFloat(this.medidasEsquerda[0].replace(",", "."));
      aciEsq = parseFloat(this.medidasEsquerda[2].replace(",", "."));

      this.relacaoEsquerda = aciEsq / accEsq;
    }

    this.AdicionaTexto(null ,this.newEditor);
  }

  AdicionaTexto(reset?, isNewEditor?: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor(reset);
    else
      this.adicionaTextoOldEditor(reset);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor(reset) {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p></br>${this.indicacaoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    this.descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    if (!reset) {
      this.htmlContent +=  '<br/>' + this.TableCarotidas() + '<br/>';

      this.laudoCompleto.push({
        'type': 'paragraph',
        'data': {
          'text': `<p><br/> ${this.TableCarotidas()} <br/></p>`,
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    if (this.tabelaReferencia) {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'data': {
          'text': `<p>${maskSplit[3]}</p>`,
        }
      });

      this.laudoCompleto.push({
        'type': 'paragraph',
        'data': {
          'text': `<p>${this.tabelaReferencia}</p>`,
        }
      });

      this.laudoCompleto.push({
        'type': 'paragraph',
        'data': {
          'text': `<p>${maskSplit[4]}</p>`,
        }
      });
    }


    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);

  }

  adicionaTextoOldEditor(reset?) {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.indicacaoTexto;
    this.htmlContent += maskSplit[1];

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value && this.descricoes[i].visivel === true) {
        this.htmlContent += this.descricoes[i].value + '<br/> <br/>';
      }
    }
    // Tabela
    if (!reset) {
      this.htmlContent +=  '<br/>' + this.TableCarotidas();
    }


    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value && this.conclusoes[i].visivel === true) {
        this.htmlContent += this.conclusoes[i].value + '<br/>';
      }
    }

    if (this.tabelaReferencia) {
      this.htmlContent += maskSplit[3];
      this.htmlContent += this.tabelaReferencia;
      this.htmlContent += maskSplit[4];
    }

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }


  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(null, true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(null, true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId} p`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId} p`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(null, true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(null, true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  RetornaConclusaoNull(Texto) {
    const texto = 'Estudo Doppler do sistema carotídeo e vertebral dentro dos limites da normalidade.';

    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  escreveReferencia($event) {
    if ($event == null) {
      this.tabelaReferencia = '';
    }
    else {
      if ($event) {
        this.tabelaReferencia = this.tabelaReferenciaCarotida();
      }
    }
    console.log('passou')
    this.AdicionaTexto(null ,this.newEditor);
  }

  tabelaReferenciaCarotida(): string {

    let strTable = '<style>' +
      'table {' +
      'width: 80%;' +
      '}' +
      'table, th, td {' +
      'border: 1px solid #c2c0c2;' +
      'border-collapse: collapse;' +
      'th, td {' +
      '  padding: 5px;' +
      '  text-align: left;' +
      '}' +
      '</style>' +
      '<br><br>' +
      '<table>' +
      '  <tr>' +
      '    <th></th>' +
      '    <th>VPS ACI (cm/s)</th>' +
      '    <th>VDF ACI (cm/s)</th>' +
      '    <th>VPS (ACI/ACC)</th>' +
      '    <th>Placa</th>' +
      '  </tr>';
    strTable += '  <tr>' +
      '    <td>Normal</td>' +
      '    <td>< 125</td>' +
      '    <td>< 40</td>' +
      '    <td>< 2</td>' +
      '    <td>Nenhuma</td>' +
      '  </tr>';
    strTable += '  <tr>' +
      '    <td>< 50%</td>' +
      '    <td>< 125</td>' +
      '    <td>< 40</td>' +
      '    <td>< 2</td>' +
      '    <td>< 50</td>' +
      '  </tr>';
    strTable += '  <tr>' +
      '    <td>50-69%</td>' +
      '    <td>125-230</td>' +
      '    <td>40-100</td>' +
      '    <td>2-4</td>' +
      '    <td>≥ 50</td>' +
      '  </tr>';
    strTable += '  <tr>' +
      '    <td>≥ 70%</td>' +
      '    <td>> 230</td>' +
      '    <td>> 100</td>' +
      '    <td>> 4</td>' +
      '    <td>≥ 50</td>' +
      '  </tr>';
    strTable += '  <tr>' +
      '    <td>Suboclusão</td>' +
      '    <td>Alta, baixa ou não detectável</td>' +
      '    <td>Variável</td>' +
      '    <td>Variável</td>' +
      '    <td>Variável</td>' +
      '  </tr>';
    strTable += '  <tr>' +
      '    <td>Oclusão</td>' +
      '    <td>Não detectável</td>' +
      '    <td>Não aplicável</td>' +
      '    <td>Não aplicável</td>' +
      '    <td>Não aplicável</td>' +
      '  </tr>';
    strTable += '</table>';


    return strTable;
  }

  TableCarotidas() {
    let strTable = '<style>' +
      'table {' +
      'width: 80%;' +
      '}' +
      'table, th, td {' +
      'border: 1px solid #c2c0c2;' +
      'border-collapse: collapse;' +
      'th, td {' +
      '  padding: 5px;' +
      '  text-align: left;' +
      '}' +
      '</style>' +
      '<table>' +
      '  <tr>' +
      '    <th></th>' +
      '    <th>Direita</th>' +
      '    <th>Esquerda</th>' +
      '  </tr>';
    if (this.medidasDireita[0] || this.medidasEsquerda[0]) {
      strTable += '  <tr>' +
        '    <td>ACC Sistólica</td>';
      if (this.medidasDireita[0]) {
        strTable += '    <td>' + this.medidasDireita[0] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '    <td>0 ' + this.unidadeMedida + '/s</td>';;
      }
      if (this.medidasEsquerda[0]) {
        strTable += '    <td>' + this.medidasEsquerda[0] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '    <td>0 ' + this.unidadeMedida + '/s</td>';;
      }
      strTable += '  </tr>';
    }
    if (this.medidasDireita[1] || this.medidasEsquerda[1]) {
      strTable += '  <tr>' +
        '    <td>ACC Diastólica</td>';
      if (this.medidasDireita[1]) {
        strTable += '    <td>' + this.medidasDireita[1] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '    <td>0 ' + this.unidadeMedida + '/s</td>';;
      }
      if (this.medidasEsquerda[1]) {
        strTable += '    <td>' + this.medidasEsquerda[1] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '    <td>0 ' + this.unidadeMedida + '/s</td>';;
      }
      strTable += '  </tr>';
    }
    if (this.medidasDireita[2] || this.medidasEsquerda[2]) {
      strTable += '  <tr>' +
        '    <td>ACI Sistólica</td>';
      if (this.medidasDireita[2]) {
        strTable += '    <td>' + this.medidasDireita[2] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '    <td>0 ' + this.unidadeMedida + '/s</td>';;
      }
      if (this.medidasEsquerda[2]) {
        strTable += '    <td>' + this.medidasEsquerda[2] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '    <td>0 ' + this.unidadeMedida + '/s</td>';;
      }
      strTable += '  </tr>';
    }
    if (this.medidasDireita[3] || this.medidasEsquerda[3]) {
      strTable += '  <tr>' +
        '    <td>ACI Diastólica</td>';
      if (this.medidasDireita[3]) {
        strTable += '    <td>' + this.medidasDireita[3] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '    <td>0 ' + this.unidadeMedida + '/s</td>';;
      }
      if (this.medidasEsquerda[3]) {
        strTable += '    <td>' + this.medidasEsquerda[3] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '    <td>0 ' + this.unidadeMedida + '/s</td>';;
      }
      strTable += '  </tr>';
    }
    if (this.medidasDireita[4] && this.medidasDireita[4] != "0"
      || this.medidasEsquerda[4] && this.medidasEsquerda[4] != "0") {
      strTable += '  <tr>' +
        '    <td>ACE Sistólica</td>';
      if (this.medidasDireita[4]) {
        strTable += '    <td>' + this.medidasDireita[4] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '    <td>0 ' + this.unidadeMedida + '/s</td>';;
      }
      if (this.medidasEsquerda[4]) {
        strTable += '    <td>' + this.medidasEsquerda[4] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '    <td>0 ' + this.unidadeMedida + '/s</td>';;
      }
      strTable += '  </tr>';
    }
    if (this.medidasDireita[5] && this.medidasDireita[5] != "0"
      || this.medidasEsquerda[5] && this.medidasEsquerda[5] != "0") {
      strTable += '  <tr>' +
        '    <td>ACE Diastólica</td>';
      if (this.medidasDireita[5]) {
        strTable += '    <td>' + this.medidasDireita[5] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '    <td>0 ' + this.unidadeMedida + '/s</td>';;
      }
      if (this.medidasEsquerda[5]) {
        strTable += '    <td>' + this.medidasEsquerda[5] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '    <td>0 ' + this.unidadeMedida + '/s</td>';;
      }
      strTable += '  </tr>';
    }
    if (this.medidasDireita[6] || this.medidasEsquerda[6]) {
      strTable += '  <tr>' +
        '    <td>Vertebral Sistólica</td>';
      if (this.medidasDireita[6]) {
        strTable += '    <td>' + this.medidasDireita[6] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '    <td>0 ' + this.unidadeMedida + '/s</td>';;
      }
      if (this.medidasEsquerda[6]) {
        strTable += '    <td>' + this.medidasEsquerda[6] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '    <td>0 ' + this.unidadeMedida + '/s</td>';;
      }
      strTable += '  </tr>';
    }
    if (this.medidasDireita[7] || this.medidasEsquerda[7]) {
      strTable += '  <tr>' +
        '    <td>Vertebral Diastólica</td>';
      if (this.medidasDireita[7]) {
        strTable += '    <td>' + this.medidasDireita[7] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '    <td>0 ' + this.unidadeMedida + '/s</td>';;
      }
      if (this.medidasEsquerda[7]) {
        strTable += '    <td>' + this.medidasEsquerda[7] + ' ' + this.unidadeMedida + '/s</td>';
      } else {
        strTable += '     <td>0 ' + this.unidadeMedida + '/s</td>';
      }
      strTable += '  </tr>';
      '  </tr>';
    }
    strTable += '  </tr>' +
      '    <td>Relação ACI/ACC</td>';
    if (this.relacaoDireita >= 2) {
      strTable += '<td>' + this.relacaoDireita.toFixed(1) + '</td>';
    } else {
      strTable += '<td>< 2</td>';
    }
    if (this.relacaoEsquerda >= 2) {
      strTable += '<td>' + this.relacaoEsquerda.toFixed(1) + '</td>';
    } else {
      strTable += '<td>< 2</td>';
    }
    strTable += '</tr>' +
      '</table>';

    return strTable;
  }

  handleSwitchFormReport(formReport: string) {
    if (formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
