import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { AlteraLaudoComponent } from '../altera-laudo/altera-laudo.component';

@Injectable({
  providedIn: 'root'
})
export class DataTermService {
  public termAndBlock = new Subject()
  constructor() { }
  setTermos(listTerm,blockID){
    this.termAndBlock.next({listTerm,blockID});
  }

}
