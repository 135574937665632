import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-promontorio',
  templateUrl: './promontorio.component.html',
  styleUrls: ['./promontorio.component.css']
})
export class PromontorioComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdArredondado') rdArredondado: MatRadioButton;
  @ViewChild('rdObliquo') rdObliquo: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickDefault(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked)
      this.termoSelecionado.emit(this.rdNormal.value);

    if (this.rdArredondado.checked)
      this.termoSelecionado.emit(this.rdArredondado.value);
    
    if (this.rdObliquo.checked)
      this.termoSelecionado.emit(this.rdObliquo.value);

    this.listaCompleta.emit();
  }

}
