import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-artefatos-limitacoes',
  templateUrl: './artefatos-limitacoes.component.html',
  styleUrls: ['./artefatos-limitacoes.component.css']
})
export class ArtefatosLimitacoesComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('ckArtefatos') ckArtefatos: MatRadioButton;
  @ViewChild('ckStrike') ckStrike: MatRadioButton;
  @ViewChild('ckExpirado') ckExpirado: MatRadioButton;

  @ViewChild('slLimitacao1') slLimitacao1: MatSelect;
  @ViewChild('slLimitacao2') slLimitacao2: MatSelect;

  limitacoes: ArrayDropDown[] = [
    { value: 'do parênquima pulmonar', viewValue: 'Parênquima pulmonar' },
    { value: 'dos arcos costais', viewValue: 'Arcos costais' },
    { value: 'dos ramos arteriais segmentares e subsegmentares', viewValue: 'Ramos arteriais segmentares e subsegmentares' }
  ];

  showAndHideTerms: any;
  countLesoes: number = 0;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true,
      sahArtefato: false,
      sahStrike: false,
    };
  }


  clickAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.showAndHideTerms.sahArtefato = false;
    this.showAndHideTerms.sahStrike = false;
    this.catchIDs();
  }

  clickArtefato() {
    if (this.showAndHideTerms.sahArtefato === false)
      this.showAndHideTerms.sahArtefato = true;
    else
      this.showAndHideTerms.sahArtefato = false;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickStrike() {
    if (this.showAndHideTerms.sahStrike === false)
      this.showAndHideTerms.sahStrike = true;
    else
      this.showAndHideTerms.sahStrike = false;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickExpirado() {
    this.verificaAlterado();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked)
      this.termoSelecionado.emit(this.rdAusente.id);

    if (this.rdPresente.checked) {

      if (this.countLesoes > 0)
        this.emitArtefatoLesao();
      else
        this.emitArtefato();

      if (this.countLesoes > 0)
        this.emitStrikeLesao();
      else
        this.emitStrike();

      if (this.countLesoes > 0)
        this.emitExpiradoLesao();
      else
        this.emitExpirado();

    }

    this.listaCompleta.emit();
  }

  emitArtefato() {
    if (this.ckArtefatos.checked) {
      this.countLesoes++
      this.termoSelecionado.emit(this.ckArtefatos.id);

      if (this.slLimitacao1 && this.slLimitacao1.value) {
        const objeto = { elemento: this.slLimitacao1, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitArtefatoLesao() {
    if (this.ckArtefatos && this.ckArtefatos.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckArtefatos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLimitacao1 && this.slLimitacao1.value) {
        const objeto = { elemento: this.slLimitacao1, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitStrike() {
    if (this.ckStrike.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckStrike.id);

      if (this.slLimitacao2 && this.slLimitacao2.value) {
        const objeto = { elemento: this.slLimitacao2, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitStrikeLesao() {
    if (this.ckStrike && this.ckStrike.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckStrike.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLimitacao2 && this.slLimitacao2.value) {
        const objeto = { elemento: this.slLimitacao2, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitExpirado() {
    if (this.ckExpirado.checked)
      this.countLesoes++;
    this.termoSelecionado.emit(this.ckExpirado.id);
  }

  emitExpiradoLesao() {
    if (this.ckExpirado && this.ckExpirado.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckExpirado.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  verificaAlterado() {
    if (!this.ckArtefatos.checked &&
      !this.ckExpirado.checked &&
      !this.ckStrike.checked
    ) {
      // Marca normal
      this.rdAusente.checked = true;
    } else {
      this.rdPresente.checked = true;
    }
  }


}
