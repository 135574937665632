import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatCheckbox , MatSelect } from '@angular/material';
import { Validacoes } from '../../../../../../../app/utils/validacoes';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-tendao-quadril',
  templateUrl: './tendao-quadril.component.html',
  styleUrls: ['./tendao-quadril.component.css']
})
export class TendaoQuadrilComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTendinopatia') rdTendinopatia: MatRadioButton;

  @ViewChild('rdIquio') rdIquio: MatRadioButton;
  @ViewChild('slLocalizacaoTend') slLocalizacaoTend: MatSelect;

  @ViewChild('rdCalcarea') rdCalcarea: MatRadioButton;
  @ViewChild('slLocalizacaoCalc') slLocalizacaoCalc: MatSelect;
  @ViewChild('inMedindo') inMedindo: ElementRef;

  genericLocalizaTend: ArrayDropDown[] = [
    { value: 'do glúteo mínimo', viewValue: 'Glúteo Mínimo' },
    { value: 'do glúteo médio', viewValue: 'Glúteo Médio' },
    { value: 'do glúteo máximo', viewValue: 'Glúteo Máximo' },
    { value: 'do iliopsoas', viewValue: 'Iliopsoas' }
  ];

  showAndHideTerms: any;
  countLesoes = 0;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahTendinopatia : false,
      sahCalcarea: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahTendinopatia = false;
    this.showAndHideTerms.sahCalcarea = false;
    this.catchIDs();
  }

  clickTendinopatia() {
    this.showAndHideTerms.sahTendinopatia = true;
    this.showAndHideTerms.sahCalcarea = false;
    this.catchIDs();
  }

  clickIsquiotibiais() {
    this.showAndHideTerms.sahTendinopatia = false;
    this.showAndHideTerms.sahCalcarea = false;
    this.catchIDs();
  }

  clickCalcarea() {

    this.showAndHideTerms.sahCalcarea = true;
    this.showAndHideTerms.sahTendinopatia = true;

    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdTendinopatia.checked) {
      this.termoSelecionado.emit(this.rdTendinopatia.value);
      this.emitLocalizacao();
    }

    if(this.rdIquio.checked){
      this.termoSelecionado.emit(this.rdIquio.value);
    }

    if (this.rdCalcarea.checked) {
      this.termoSelecionado.emit(this.rdCalcarea.value);
      this.emitLocalizacao();
      if (this.inMedindo && this.inMedindo.nativeElement.value) {
        const objeto = { elemento: this.inMedindo.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }


    this.listaCompleta.emit();
  }

  emitLocalizacao() {
     if (this.slLocalizacaoTend && this.slLocalizacaoTend.value) {
        const objeto = { elemento: this.slLocalizacaoTend, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
  }


}
