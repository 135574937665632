import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatCheckbox, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-interapofisarias',
  templateUrl: './interapofisarias.component.html',
  styleUrls: ['./interapofisarias.component.css']
})
export class InterapofisariasComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdArtroseInterapofisariasMult') rdArtroseInterapofisariasMult: MatRadioButton;

  @ViewChild('rdArtroseInterapofisarias') rdArtroseInterapofisarias: MatRadioButton;

  @ViewChild('slNivelArtrose')slNivelArtrose: MatSelect;

  showAndHideTerms;
  countLesoes;

  nivelArtrose: ArrayDropDown[]=[
    { value: 'T1-T2', viewValue: 'T1-T2' },
    { value: 'T2-T3', viewValue: 'T2-T3' },
    { value: 'T3-T4', viewValue: 'T3-T4' },
    { value: 'T4-T5', viewValue: 'T4-T5' },
    { value: 'T5-T6', viewValue: 'T5-T6' },
    { value: 'T6-T7', viewValue: 'T6-T7' },
    { value: 'T7-T8', viewValue: 'T7-T8' },
    { value: 'T8-T9', viewValue: 'T8-T9' },
    { value: 'T9-T10', viewValue: 'T9-T10' },
    { value: 'T10-T11', viewValue: 'T10-T11'},
    { value: 'T11-T12', viewValue: 'T11-T12'},
    { value: 'T12-L1', viewValue: 'T12-L1' }
  ]

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahArtroseInterapofisarias: false,
    }
  }
  clickDefault(){
    this.catchIDs();
  }
  changeSelect(){
    this.catchIDs();
  }

  clickArtroseInterapofisarias(){
    this.rdNormal.checked=false;
    this.rdArtroseInterapofisariasMult.checked=false;
    this.showAndHideTerms.sahArtroseInterapofisarias= !this.showAndHideTerms.sahArtroseInterapofisarias;
    this.catchIDs();
  }

  clickArtroseInterapofisariasMult(){
    this.rdNormal.checked=false;
    this.rdArtroseInterapofisariasMult.checked=true;
    this.rdArtroseInterapofisarias.checked=false;
    this.showAndHideTerms.sahArtroseInterapofisarias=false;
    this.catchIDs();
  }
  clickNormal(){
    this.rdNormal.checked=true;
    this.rdArtroseInterapofisariasMult.checked=false;
    this.rdArtroseInterapofisarias.checked=false;
    this.showAndHideTerms.sahArtroseInterapofisarias=false;
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if(!this.rdArtroseInterapofisarias.checked && !this.rdArtroseInterapofisariasMult.checked){
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if(this.countLesoes > 0){
      this.emitArtroseLesao();
    } else {
      this.emitArtroseSimples();
    }

    if(this.countLesoes > 0){
      this.emitArtroseMultiLesao();
    } else {
      this.emitArtroseMultiSimples();
    }

    this.listaCompleta.emit();
  }

  emitArtroseMultiSimples() {
    if(this.rdArtroseInterapofisariasMult.checked){
    this.countLesoes++;
    this.termoSelecionado.emit(this.rdArtroseInterapofisariasMult.id);
    }
  }
  emitArtroseSimples() {
    if(this.rdArtroseInterapofisarias.checked){
    this.countLesoes++;
    this.termoSelecionado.emit(this.rdArtroseInterapofisarias.id);

    if(this.slNivelArtrose && this.slNivelArtrose.value){
      const objeto = {elemento: this.slNivelArtrose, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
      }
    }
  }

  emitArtroseMultiLesao() {
    if(this.rdArtroseInterapofisariasMult.checked){
      this.countLesoes++;
      const objeto = { id: this.rdArtroseInterapofisariasMult.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitArtroseLesao() {
    if(this.rdArtroseInterapofisarias.checked){
    this.countLesoes++;
    const objeto = { id: this.rdArtroseInterapofisarias.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);

    if(this.slNivelArtrose && this.slNivelArtrose.value){
      const objeto = {elemento: this.slNivelArtrose, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
      }
    }
  }
}
