import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-abd-limitacoes-tecnicas',
  templateUrl: './abd-limitacoes-tecnicas.component.html',
  styleUrls: ['./abd-limitacoes-tecnicas.component.css']
})
export class AbdLimitacoesTecnicasComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('ckMeteorismo') ckMeteorismo: MatCheckbox;
  @ViewChild('ckBiotipo') ckBiotipo: MatCheckbox;

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true
    };
  }

  clickAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.catchIDs();
  }

  clickDefault() {
    this.alteraAusentePresente();
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);

      if (this.ckMeteorismo && this.ckMeteorismo.checked)
        this.termoSelecionado.emit(this.ckMeteorismo.id);

      if (this.ckBiotipo && this.ckBiotipo.checked)
        this.termoSelecionado.emit(this.ckBiotipo.id);
    }

    this.listaCompleta.emit();
  }

  alteraAusentePresente() {
    if ((this.ckMeteorismo && this.ckMeteorismo.checked) ||
      (this.ckBiotipo && this.ckBiotipo.checked)) {
      this.rdPresente.checked = true;
    } else {
      this.rdPresente.checked = false;
      this.rdAusente.checked = true;
    }
  }

}
