import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { MatRadioButton, MatSelect } from '@angular/material';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-opt-corpo-luteo',
  templateUrl: './opt-corpo-luteo.component.html',
  styleUrls: ['./opt-corpo-luteo.component.css']
})
export class OptCorpoLuteoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNaoCarac') rdNaoCarac: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('inMedida') inMedida: ElementRef;

  @ViewChild('sllocal') sllocal: MatSelect;

  locais: ArrayDropDown[] = [
    { value: 'ovário direito', viewValue: 'Ovário direito' },
    { value: 'ovário esquerdo', viewValue: 'Ovário esquerdo' }
  ];

  showAndHideTerms: any;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahLocal: true
    };
  }
  verificaLesoes(){
    if((this.sllocal && this.sllocal.value) || (this.inMedida && this.inMedida.nativeElement.value)){
      this.rdPresente.checked = true;
    }
  }
  
  clickrdNaoCarac() {
    this.showAndHideTerms.sahLocal = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahLocal = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  blurMedindo() {
    this.verificaLesoes();
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCarac && this.rdNaoCarac.checked) {
      this.termoSelecionado.emit(this.rdNaoCarac.id);
    }

    if (this.rdPresente && this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);

      if (this.sllocal && this.sllocal.value) {
        const objeto = { elemento: this.sllocal, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedida &&
        this.inMedida.nativeElement.value !== '') {
        const Diam = this._validacoes.formataDecimal(this.inMedida.nativeElement.value);
        const objetoElemento = { id: this.inMedida.nativeElement.id, value: Diam, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
