import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatCheckbox} from '@angular/material';

@Component({
  selector: 'app-tireoide-turing-dialog',
  templateUrl: './tireoide-turing-dialog.component.html',
  styleUrls: ['./tireoide-turing-dialog.component.css']
})
export class TireoideTuringDialogComponent implements OnInit {
  @ViewChild('ckTabela') ckTabela: MatCheckbox;

  displayedColumns = ['composicao', 'ecogenicidade', 'forma', 'margem', 'foco'];
  displayedColumnsPoints = ['TIRADS1', 'TIRADS2', 'TIRADS3', 'TIRADS4', 'TIRADS5'];
  dataSource = ELEMENT_DATA;
  dataSourcePoints = ELEMENT_DATA_POINTS;
  constructor(
    public dialogRef: MatDialogRef<TireoideTuringDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

    ClickNao(): void {
      if(this.ckTabela.checked){
        this.dialogRef.close(this.ckTabela.value);
      }else{
        this.dialogRef.close(this.ckTabela.checked);
      }
    }

  ngOnInit() {
  }

  verificarTitle(item:PointItem){
    if(item.title){
      return item.points;
    }
    return "";
  }

}

export interface PointItem {
  title: string;
  points: number;
}

export interface RefElement {
  composicao: PointItem;
  ecogenicidade: PointItem;
  forma: PointItem;
  margem: PointItem;
  foco: PointItem;
}

export interface RefElementPoints {
  TIRADS1: string;
  TIRADS2: string;
  TIRADS3: string;
  TIRADS4: string;
  TIRADS5: string;
}

const ELEMENT_DATA: RefElement[] = [
  {composicao: {title: "Cística ou quase completamente cística", points:0}, ecogenicidade: {title: "Anecóica", points:0}, forma: {title: "Mais largo do que alto", points:0}, margem: {title: "Regular", points:0},foco: {title: 'Nenhum ou artefatos com "cauda de cometa"', points:0}},

  {composicao: {title: "Espongiforme", points:0}, ecogenicidade: {title: "Hiperecoica ou isoecoides", points:1}, forma: {title: "Mais alto do que largo", points:3}, margem: {title: "Mal definido", points:0},foco: {title: "Macrocalcificações", points:1}},

  {composicao: {title: "Cística misto e sólido", points:1}, ecogenicidade: {title: "Hipoecóico", points:2}, forma: {title: "", points:0}, margem: {title: "Lobulada ou Irregular", points:2},foco: {title: "Periféricos (RIM) calcificações", points:2}},

  {composicao: {title: "Sólido ou quase completamente sólido", points:2}, ecogenicidade: {title: "Muito Hipoecóico", points:3}, forma: {title: "", points:0}, margem: {title: "Extensão extra-tireoidiana", points:3},foco: {title: "Focos ecogênicos pontiformes", points:3}}
];

const ELEMENT_DATA_POINTS: RefElementPoints[] = [
  {TIRADS1:'TI-RADS 1', TIRADS2:'TI-RADS 2',TIRADS3:'TI-RADS 3',TIRADS4:'TI-RADS 4',TIRADS5:'TI-RADS 5'},

  {TIRADS1:'Achados', TIRADS2:'Achados',TIRADS3:'Achados',TIRADS4:'Achados',TIRADS5:'Achados'},

  {TIRADS1:'Benignos', TIRADS2:'Insuspeitos',TIRADS3:'Discretamente',TIRADS4:'Moderadamente',TIRADS5:'Altamente'},

  {TIRADS1:'', TIRADS2:'',TIRADS3:'Suspeitos',TIRADS4:'Suspeitos',TIRADS5:'Suspeitos'}
];
