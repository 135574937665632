import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AchadosExameComponent } from './achados-exame/achados-exame.component';
import { IndicacaoExameComponent } from './indicacao-exame/indicacao-exame.component';
import { MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatRadioModule, MatSelectModule, MatSlideToggleModule } from '@angular/material';
import { MetodoComponent } from './metodo/metodo.component';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CabecalhoExameComponent } from './cabecalho-exame/cabecalho-exame.component';
import { RodapeExameComponent } from './rodape-exame/rodape-exame.component';
import { TransferState } from '@angular/platform-browser';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatIconModule,
    FormsModule,
    LayoutModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatIconModule,
    MatButtonModule
  ],
  declarations: [
    IndicacaoExameComponent,
    AchadosExameComponent,
    MetodoComponent,
    CabecalhoExameComponent,
    RodapeExameComponent,
  ],
  exports: [
    IndicacaoExameComponent,
    AchadosExameComponent,
    MetodoComponent,
    CabecalhoExameComponent,
    RodapeExameComponent
  ],
  providers: [
    TransferState
  ]
})
export class SharedModule { }
