import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-corpos-vertebrais',
  templateUrl: './corpos-vertebrais.component.html',
  styleUrls: ['./corpos-vertebrais.component.css']
})
export class CorposVertebraisComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckAcentLordose') ckAcentLordose: MatCheckbox;
  @ViewChild('ckOsteofitos') ckOsteofitos: MatCheckbox;
  @ViewChild('ckFratCorpo') ckFratCorpo: MatCheckbox;
  @ViewChild('ckFratCoccix') ckFratCoccix: MatCheckbox;


  @ViewChild('slNivel') slNivel: MatSelect;

  showAndHideTerms: any;
  countLesoes = 0;

  fraturaCorpoFormGroup: FormGroup;

  nivel: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {

    this.iniciaFormFraturaCorpo();

    this.nivel = [
      { id: 's1', value: 'S1', name: 'S1' },
      { id: 's2', value: 'S2', name: 'S2' },
      { id: 's3', value: 'S3', name: 'S3' },
      { id: 's4', value: 'S4', name: 'S4' },
      { id: 's5', value: 'S5', name: 'S5' },
    ];

    this.showAndHideTerms = {
      sahAlterado : true,
      sahFratCorpo :false,
    };
  }

  clickNormal() {
    if (this.rdNormal.checked){
      this.showAndHideTerms.sahAlterado = false;
      this.showAndHideTerms.sahFratCorpo = false;
      this.ckFratCoccix.checked = false;
      this.ckFratCorpo.checked = false;
      this.ckOsteofitos.checked = false;
    }
    this.catchIDs();
  }

  clickCheckbox() {
    this.catchIDs();
  }

  clickAlterado() {
    if (this.rdAlterado.checked){
      this.showAndHideTerms.sahAlterado = true;
    }
    this.catchIDs();
  }
  
  clickOsteofitos(){
    this.mudaAlterado();
    this.catchIDs();
  }

  clickFratCorpo(){
    if (this.ckFratCorpo.checked){
      this.showAndHideTerms.sahFratCorpo = true;
    }else{
      this.showAndHideTerms.sahFratCorpo = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickFratCoccix(){
    this.mudaAlterado();
    this.catchIDs();
  }

  clickCheckboxFraturaCorpo(event) {
    let fraturaCorpo = <FormArray>this.fraturaCorpoFormGroup.get('nivel') as FormArray;

    if (event.checked) {
      fraturaCorpo.push(new FormControl(event.source.value));
    } else {
      let iDir = fraturaCorpo.controls.findIndex(x => x.value === event.source.value);
      fraturaCorpo.removeAt(iDir);
    }

    this.catchIDs();
  }

  iniciaFormFraturaCorpo() {
    this.fraturaCorpoFormGroup = this.fb.group({
      nivel: this.fb.array([])
    });
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');
    
    if (!this.ckFratCorpo.checked) {
      this.iniciaFormFraturaCorpo();
    }

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }else if (!this.rdNormal.checked) {

      this.emitOsteofito();
      
      //Fratura Corpo
      if(this.countLesoes > 0){
        this.emitFraturaCorpoLesao();
      } else {
        this.emitFraturaCorpo();
      }    

      //Fratura Coccix
      if(this.countLesoes > 0){
        this.emitCoccixLesao();
      } else {
        this.emitCoccix();
      }    
    }

    this.listaCompleta.emit();
  }

  emitFraturaCorpoLesao() {
    if (this.ckFratCorpo && this.ckFratCorpo.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckFratCorpo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let fraturaCorpo = this.fraturaCorpoFormGroup.value.nivel;

      if (fraturaCorpo.length > 1) {
        let objeto = { id: 5153, value: fraturaCorpo };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
        //termo múltiplas fraturas
        this.termoSelecionado.emit(5152);

      } else if (fraturaCorpo.length == 1) {
        let objeto = { id: 5153, value: fraturaCorpo };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }
    }

  }

  emitFraturaCorpo() {
    if (this.ckFratCorpo && this.ckFratCorpo.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFratCorpo.id);

      let fraturaCorpo = this.fraturaCorpoFormGroup.value.nivel;

      if (fraturaCorpo.length > 1) {
        let objeto = { id: 5153, value: fraturaCorpo };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
        //termo múltiplas fraturas
        this.termoSelecionado.emit(5152);

      } else if (fraturaCorpo.length == 1) {
        let objeto = { id: 5153, value: fraturaCorpo };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }

  }

  emitOsteofito(){
    if(this.ckOsteofitos.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckOsteofitos.id);
    }
  }

  emitCoccixLesao(){
    if(this.ckFratCoccix.checked){
      this.countLesoes++;
      const objeto = { id: this.ckFratCoccix.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitCoccix(){
    if(this.ckFratCoccix.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFratCoccix.id);
    }
  }

  mudaAlterado(){
    if (this.ckFratCoccix.checked || this.ckFratCorpo.checked || this.ckOsteofitos.checked){
      this.rdAlterado.checked = true;
    }else{
      this.rdNormal.checked = true;
    }
  }

}
