import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-fascia-plantar-proximal',
  templateUrl: './fascia-plantar-proximal.component.html',
  styleUrls: ['./fascia-plantar-proximal.component.css']
})
export class FasciaPlantarProximalComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdFascilite') rdFascilite: MatRadioButton;
  @ViewChild('rdRoturaParcial') rdRoturaParcial: MatRadioButton;
  @ViewChild('rdFibromatose') rdFibromatose: MatRadioButton;
  // Fascilite
  @ViewChild('inEspessura') inEspessura: ElementRef;
  // Parcial
  @ViewChild('inDistancia') inDistancia: ElementRef;
  // Fibromatose
  @ViewChild('rdUmaFibromatose') rdUmaFibromatose: MatRadioButton;
  @ViewChild('rdDuasFibromatoses') rdDuasFibromatoses: MatRadioButton;
  @ViewChild('slUmaLocalizacao') slUmaLocalizacao: MatSelect;
  @ViewChild('slUmaBanda') slUmaBanda: MatSelect;
  @ViewChild('inUmaMedida') inUmaMedida: ElementRef;

  @ViewChildren('duasFibromatoses') duasFibromatoses: QueryList<any>;


  localizacoes: ArrayDropDown[] = [
    { value: 'proximal', viewValue: 'Proximal' },
    { value: 'médio', viewValue: 'Médio' },
    { value: 'distal', viewValue: 'Distal' }
  ];

  bandas: ArrayDropDown[] = [
    { value: 'central', viewValue: 'Central' },
    { value: 'medial', viewValue: 'Medial' },
    { value: 'lateral', viewValue: 'Lateral' },
  ];

  showAndHideTerms: any;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  objeto: any;
  objetofilho: any;
  unidadeMedida: string;

  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahFascilite: false,
      sahParcial: false,
      sahFibromatose: false,
      sahUmaFibromatose: true,
      sahDuasFibromatoses: false
    };
    this.lesoesForm = this._fb.group({
      fibromatoses: this._fb.array([this.addFibromatoseGrup()]),
    });
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  //Dinamico Fibromatose
  addFibromatoseGrup() {
    return this._fb.group({
      localizacao: [],
      banda: [],
      medida: []
    });
  }

  addFibromatose() {
    this.fibromatoseArray.push(this.addFibromatoseGrup());
    this.catchIDs();
  }

  removeFibromatose(index) {
    this.fibromatoseArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get fibromatoseArray() {
    return <FormArray>this.lesoesForm.get('fibromatoses');
  }

  clickNormal() {
    this.showAndHideTerms.sahFascilite = false;
    this.showAndHideTerms.sahParcial = false;
    this.showAndHideTerms.sahFibromatose = false;
    this.catchIDs();
  }

  clickFascilite() {
    this.showAndHideTerms.sahFascilite = true;
    this.showAndHideTerms.sahParcial = false;
    this.showAndHideTerms.sahFibromatose = false;
    this.catchIDs();
  }

  clickRoturaParcial() {
    this.showAndHideTerms.sahFascilite = false;
    this.showAndHideTerms.sahParcial = true;
    this.showAndHideTerms.sahFibromatose = false;
    this.catchIDs();
  }

  clickFibromatose() {
    this.showAndHideTerms.sahFascilite = false;
    this.showAndHideTerms.sahParcial = false;
    this.showAndHideTerms.sahFibromatose = true;
    this.showAndHideTerms.sahUmaFibromatose = true;
    this.showAndHideTerms.sahDuasFibromatoses = false;
    this.catchIDs();
  }

  clickUmaFibromatose() {
    this.showAndHideTerms.sahUmaFibromatose = true;
    this.showAndHideTerms.sahDuasFibromatoses = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickDuasFibromatoses() {
    this.showAndHideTerms.sahUmaFibromatose = false;
    this.showAndHideTerms.sahDuasFibromatoses = true;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.countLesoes = 0;

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdFascilite.checked) {
      this.termoSelecionado.emit(this.rdFascilite.value);

      if (this.inEspessura && this.inEspessura.nativeElement.value) {
        const objeto = { elemento: this.inEspessura.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdRoturaParcial.checked) {
      this.termoSelecionado.emit(this.rdRoturaParcial.value);

      if (this.inDistancia && this.inDistancia.nativeElement.value) {
        const objeto = { elemento: this.inDistancia.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdFibromatose.checked) {
      this.termoSelecionado.emit(this.rdFibromatose.value);

      // Uma fibromatose
      this.emitUmaFibromatose();

      // Duas fibromatoses
      this.emitDuasFibramotoses();
    }

    this.listaCompleta.emit();
  }

  emitUmaFibromatose() {
    if (this.rdUmaFibromatose && this.rdUmaFibromatose.checked) {
      this.termoSelecionado.emit(this.rdUmaFibromatose.value);

      if (this.slUmaLocalizacao && this.slUmaLocalizacao.value) {
        const objeto = { elemento: this.slUmaLocalizacao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slUmaBanda && this.slUmaBanda.value) {
        const objeto = { elemento: this.slUmaBanda, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inUmaMedida && this.inUmaMedida.nativeElement.value) {
        const objeto = { elemento: this.inUmaMedida.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitDuasFibramotoses() {
    let posicao = 0;
    this.linha = 0;
    if (this.rdDuasFibromatoses && this.rdDuasFibromatoses.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdDuasFibromatoses.value);

      this.duasFibromatoses.forEach(obj => {

        if (obj.nativeElement) {

          this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
        } else {

          if (obj.id == 1450) {
            this.linha++;
          }

          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
        }
      });
    }
  }

  ajustaArrayDinamico() {
    if (this.rdDuasFibromatoses && !this.rdDuasFibromatoses.checked) {
  
      const arrayTotal = this.fibromatoseArray.length;
  
      for (let i  = 0; i <= arrayTotal; i++) {
        const index = this.fibromatoseArray.length - 1
  
        if (index != 0) {
          this.fibromatoseArray.removeAt(index);
        }
      }
      this.fibromatoseArray.reset();
    }
  }
}