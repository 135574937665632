import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { AbdomeTotalComponent, Conclusao } from '../abdome-total/abdome-total.component';
import { MatDialog } from '@angular/material';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { Validacoes } from '../../../../utils/validacoes';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { DataTermService } from 'src/app/services/dataTerm.service';

export interface TermosGenericos {
  value: string;
  selected: boolean;
  term: string;
  termView: string;
  termID: number;
  linha: number;
  cenario: number;
  posicao: number;
  lesionID: number;
}

@Component({
  selector: 'app-abdome-superior',
  templateUrl: './abdome-superior.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./abdome-superior.component.css']
})
export class AbdomeSuperiorComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;
  medicaoNodulo = '';
  listTermDefault=[
    {listTerm:[1],blockID:1},
    {listTerm:[74],blockID:2},
    {listTerm:[107],blockID:4},
    {listTerm:[79],blockID:3},
    {listTerm:[162,159],blockID:6},
    {listTerm:[136],blockID:5},
    {listTerm:[178],blockID:8},
    {listTerm:[173],blockID:7},
    {listTerm:[4109],blockID:264},
    {listTerm:[1],blockID:274}
  ];

  descricoes: DescricaoConclusao[] = [
    { value: '', blockID: 274, textTermID: 0, visivel: true },
    { value: 'Fígado com forma, dimensões e contornos preservados. Parênquima hepático com ecotextura homogênea.', blockID: 1, textTermID: 4, visivel: true },
    { value: 'Veia porta pérvia, de calibre preservado.', blockID: 2, textTermID: 1, visivel: true },
    { value: 'Vias biliares intra e extra-hepáticas sem sinais de dilatação.', blockID: 4, textTermID: 56, visivel: true },
    { value: 'Vesícula biliar normodistendida, com paredes finas e regulares, com conteúdo anecoico. Não há imagens sugestivas de cálculos no seu interior.', blockID: 3, textTermID: 30, visivel: true },
    { value: 'Pâncreas com morfologia e ecotextura normais.', blockID: 6, textTermID: 83, visivel: true },
    { value: 'Baço com forma, dimensões e contornos preservados. Ecotextura esplênica homogênea.', blockID: 5, textTermID: 70, visivel: true },
    { value: 'Aorta com trajeto, calibre e contornos preservados.', blockID: 8, textTermID: 185, visivel: true },
    { value: 'Não foram caracterizadas linfonodomegalias nos segmentos avaliados.', blockID: 264, textTermID: 0, visivel: true },
    { value: 'Ausência de líquido livre na cavidade abdominal nas janelas acústicas disponíveis.', blockID: 7, textTermID: 94, visivel: true }
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Estudo ultrassonográfico sem alterações significativas.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 274, textTermID: 0, visivel: true },
    { value: '', blockID: 1, textTermID: 0, visivel: true },
    { value: '', blockID: 2, textTermID: 0, visivel: true },
    { value: '', blockID: 4, textTermID: 0, visivel: true },
    { value: '', blockID: 3, textTermID: 0, visivel: true },
    { value: '', blockID: 6, textTermID: 0, visivel: true },
    { value: '', blockID: 5, textTermID: 0, visivel: true},
    { value: '', blockID: 8, textTermID: 0, visivel: true },
    { value: '', blockID: 264, textTermID: 0, visivel: true },
    { value: '', blockID: 7, textTermID: 0, visivel: true }
  ];

  genericTerms: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 20, termID: 209, posicao: 0, cenario: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slNoduloEcogenicidade' },
    { value: '', selected: false, lesionID: 20, termID: 198, posicao: 0, cenario: 0, linha: 0, term: '[Segmento]', termView: 'slNoduloSegmento' },
    { value: '', selected: false, lesionID: 20, termID: 23, posicao: 1, cenario: 0, linha: 0, term: '[Medindo]', termView: 'inNoduloMedindo' },
    { value: '', selected: false, lesionID: 20, termID: 23, posicao: 2, cenario: 0, linha: 0, term: '[Medindo]', termView: 'inNoduloMedindo' },
    { value: '', selected: false, lesionID: 20, termID: 23, posicao: 3, cenario: 0, linha: 0, term: '[Medindo]', termView: 'inNoduloMedindo' },
    { value: '', selected: false, lesionID: 20, termID: 210, posicao: 0, cenario: 0, linha: 0, term: '[Contorno]', termView: 'slNoduloContorno' },
    { value: '', selected: false, lesionID: 40, termID: 43, posicao: 0, cenario: 0, linha: 0, term: '[Medindo]', termView: 'inCistoMedindo' },
    { value: '', selected: false, lesionID: 40, termID: 207, posicao: 0, cenario: 0, linha: 0, term: '[Segmento]', termView: 'slCistoSegmento' },
    { value: '', selected: false, lesionID: 58, termID: 208, posicao: 0, cenario: 0, linha: 0, term: '[Segmento]', termView: 'slCalcificacaoSegmento' },
    { value: '', selected: false, lesionID: 58, termID: 59, posicao: 0, cenario: 0, linha: 0, term: '[Medindo]', termView: 'inCalcificacaoMedindo' },
    { value: '', selected: false, lesionID: 191, termID: 203, posicao: 0, cenario: 0, linha: 0, term: '[Segmento]', termView: 'slColecaoSegmento' },
    { value: '', selected: false, lesionID: 191, termID: 202, posicao: 0, cenario: 0, linha: 0, term: '[Medindo]', termView: 'inColecaoMedindo' },
    { value: '', selected: false, lesionID: 193, termID: 197, posicao: 0, cenario: 0, linha: 0, term: '[Segmento]', termView: 'slHemangiomasSegmento' },
    { value: '', selected: false, lesionID: 193, termID: 195, posicao: 0, cenario: 0, linha: 0, term: '[Medindo]', termView: 'inHemangiomasMedindo' }
  ];

  genericTermsVesicula: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 83, termID: 215, posicao: 0, cenario: 0, linha: 0, term: '[espessura da parede]', termView: 'inEspessuraParede' },
    { value: '', selected: false, lesionID: 83, termID: 216, posicao: 0, cenario: 0, linha: 0, term: '[com/sem]', termView: 'slMurphyUltrassonico' },
    { value: '', selected: false, lesionID: 87, termID: 88, posicao: 0, cenario: 0, linha: 0, term: '[Medindo]', termView: 'inCalculosMedindo' },
    { value: '', selected: false, lesionID: 94, termID: 95, posicao: 0, cenario: 0, linha: 0, term: '[Medindo]', termView: 'inPoliposMedindo' }
  ];

  genericTermsAorta: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 184, termID: 184, posicao: 0, cenario: 0, linha: 0, term: '[Calibre]', termView: 'inCalibre' }
  ];

  genericTermsLinfo: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 4111, termID: 4120, posicao: 0, cenario: 0, linha: 0, term: '[Medidas]', termView: 'inMedida' },
    { value: '', selected: false, lesionID: 4114, termID: 4121, posicao: 0, cenario: 0, linha: 0, term: '[Medidas]', termView: 'inMedida' },
    { value: '', selected: false, lesionID: 4117, termID: 4122, posicao: 0, cenario: 0, linha: 0, term: '[Medidas]', termView: 'inMedida' }
  ];

  genericTermsAscite: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 175, termID: 175, posicao: 0, cenario: 0, linha: 0, term: '[Grau]', termView: 'slGrau' }
  ];

  genericTermsVeiaPorta: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 75, termID: 75, posicao: 0, cenario: 0, linha: 0, term: '[Medindo]', termView: 'inMedindo' }
  ];

  genericTermsPancreas: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 167, termID: 167, posicao: 0, linha: 0, cenario: 0, term: '[Medindo]', termView: 'inMedindo' },
    { value: '', selected: false, lesionID: 165, termID: 165, posicao: 0, linha: 0, cenario: 0, term: '[Localizacao]', termView: 'ckLocalizacao' }
  ];

  genericTermsBaco: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 141, termID: 141, cenario: 0, posicao: 0, linha: 0, term: '[Indice Esplenico]', termView: 'inIndiceEsplenico' },
    { value: '', selected: false, lesionID: 145, termID: 145, posicao: 0, linha: 0, cenario: 0, term: '[Medindo]', termView: 'inMedindoBaco' },
    { value: '', selected: false, lesionID: 228, termID: 228, posicao: 0, linha: 0, cenario: 0, term: '[Maior mede]', termView: 'inMaiorMede' },
    { value: '', selected: false, lesionID: 147, termID: 147, posicao: 0, linha: 0, cenario: 0, term: '[Medindo]', termView: 'inCalcificacaoMedindo' },
    { value: '', selected: false, lesionID: 148, termID: 148, posicao: 0, linha: 0, cenario: 0, term: '[Local]', termView: 'slCalcificacaoLocal' },
    { value: '', selected: false, lesionID: 150, termID: 150, posicao: 0, linha: 0, cenario: 0, term: '[Medindo]', termView: 'inMedindoNodulo' },
    { value: '', selected: false, lesionID: 151, termID: 151, posicao: 0, linha: 0, cenario: 0, term: '[Margens]', termView: 'slNoduloMargem' },
    { value: '', selected: false, lesionID: 152, termID: 152, posicao: 0, linha: 0, cenario: 0, term: '[Ecogenicidade]', termView: 'slNoduloEcogenicidade' },
    { value: '', selected: false, lesionID: 153, termID: 153, posicao: 0, linha: 0, cenario: 0, term: '[Local]', termView: 'slNoduloLocal' },
    { value: '', selected: false, lesionID: 155, termID: 155, posicao: 0, linha: 0, cenario: 0, term: '[Medindo]', termView: 'inMedindoHemangioma' },
    { value: '', selected: false, lesionID: 156, termID: 156, posicao: 0, linha: 0, cenario: 0, term: '[Margens]', termView: 'slHemangiomaMargem' },
    { value: '', selected: false, lesionID: 158, termID: 158, posicao: 0, linha: 0, cenario: 0, term: '[Local]', termView: 'slHemangiomaLocal' }
  ];

  genericTermsViasBiliares: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 230, termID: 230, posicao: 0, cenario: 0, linha: 0, term: '[Medida Colédoco]', termView: 'inMedidaColedoco' },
    { value: '', selected: false, lesionID: 106, termID: 106, posicao: 0, cenario: 0, linha: 0, term: '[Graduação]', termView: 'slGraducao' },
    { value: '', selected: false, lesionID: 105, termID: 105, posicao: 0, cenario: 0, linha: 0, term: '[Cálculo obstrutivo]', termView: 'inCalculoObs' }
  ];


  dinamicLesions: TermosGenericos[] = [];
  dinamicLesion: any;
  lesionRow = 1;
  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;

  htmlContent: string;
  mask: string;
  Subtitulo: string;
  subtituloBloco: string;
  blockID: number;
  term = new Term();
  injuries = new Injuries();
  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  cabecalhoTexto = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  rodapeTexto = '';

  veiaporta = false;
  vesiculabiliar = false;
  viasbiliares = false;
  baco = false;
  pancreas = false;
  figado = false;
  aorta = false;
  ascite = false;
  cabecalho = false;
  indicacao = false;
  achadosTexto = false;
  linfonodo = false;
  limitacoes = true;
  rodape = false;
  editLaudoMode: boolean = false;

  medicalReportTypeID = 27;
  figadoID = 1;
  veiaportaID = 2;
  vesiculabiliarID = 3;
  viasbiliaresID = 4;
  bacoID = 5;
  pancreasID = 6;
  asciteID = 7;
  aortaID = 8;
  linfonodoID = 264;
  limitacoesID = 274;
  public switchFormReport = true;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  unidadeMedida: string;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _abTotal: AbdomeTotalComponent,
    private _dialog: MatDialog,
    private _common: CommonService,
    private _copyPaste: CopyPaste,
    public _addText: AddText,
    private _config: ConfigLaudoService,
    private _pouchService: PouchDBService,
    private dataService:DataTermService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("abdome-superiorAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Limitações técnicas'
    this.subtituloBloco = 'Limitações técnicas'

    const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [this.figadoID,
      this.veiaportaID,
      this.vesiculabiliarID,
      this.viasbiliaresID,
      this.bacoID,
      this.pancreasID,
      this.asciteID,
      this.aortaID,
      this.linfonodoID,
      this.limitacoesID];

    this._pouchService.loadMask(this.medicalReportTypeID, "abdome-superiorMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "abdomeSuperior");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-abdome-superior');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-abdome-superior');
      }
    });
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("abdome-superiorAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.achadosAdTexto = "";
    this.showForm = false;
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      subtitulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      id: this.limitacoesID,
      name: 'limitacoes',
      titulo: 'Limitações técnicas',
      selected: true
    },
    {
      id: this.figadoID,
      name: 'figado',
      titulo: 'Fígado',
      selected: false
    },
    {
      id: this.veiaportaID,
      name: 'veia-porta',
      titulo: 'Veia Porta',
      selected: false
    },
    {
      id: this.viasbiliaresID,
      name: 'vias-biliares',
      titulo: 'Vias Biliares',
      subtitulo: 'VIAS BILIARES',
      selected: false
    },
    {
      id: this.vesiculabiliarID,
      name: 'vesicula',
      titulo: 'Vesícula Biliar',
      selected: false
    },
    {
      id: this.pancreasID,
      name: 'pancreas',
      titulo: 'Pancrêas',
      selected: false
    },
    {
      id: this.bacoID,
      name: 'baco',
      titulo: 'Baço',
      selected: false
    },
    {
      id: this.aortaID,
      name: 'aorta',
      titulo: 'Aorta',
      selected: false
    },
    {
      id: this.linfonodoID,
      name: 'linfonodo',
      titulo: 'Linfonodo',
      selected: false
    },
    {
      id: this.asciteID,
      name: 'ascite',
      titulo: 'Ascite',
      selected: false
    },
    {
      id: 'achados',
      name: 'achados',
      titulo: 'Achados Adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=>item.blockID==itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.veiaporta = (itemSelecionado.id === this.veiaportaID);
    this.vesiculabiliar = (itemSelecionado.id === this.vesiculabiliarID);
    this.viasbiliares = (itemSelecionado.id === this.viasbiliaresID);
    this.baco = (itemSelecionado.id === this.bacoID);
    this.pancreas = (itemSelecionado.id === this.pancreasID);
    this.figado = (itemSelecionado.id === this.figadoID);
    this.aorta = (itemSelecionado.id === this.aortaID);
    this.ascite = (itemSelecionado.id === this.asciteID);
    this.linfonodo = (itemSelecionado.id === this.linfonodoID);
    this.limitacoes = (itemSelecionado.id === this.limitacoesID);
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  // enviaTermos($event) {
  //   this._abTotal.enviaTermos($event);
  //   this.AdicionaTexto();
  // }
  //
  // enviaLesoes($event) {
  //   this._abTotal.enviaLesoes($event);
  // }
  //
  // adicionaSelecao($event) {
  //   this._abTotal.adicionaSelecao($event);
  // }
  //
  // adicionaSelecaoVesicula($event) {
  //   this._abTotal.adicionaSelecaoVesicula($event);
  // }
  //
  // adicionaSelecaoAorta($event) {
  //   this._abTotal.adicionaSelecaoAorta($event);
  // }
  //
  // adicionaSelecaoAscite($event) {
  //   this._abTotal.adicionaSelecaoAscite($event);
  // }
  //
  // adicionaSelecaoVeiaPorta($event) {
  //   this._abTotal.adicionaSelecaoVeiaPorta($event);
  // }
  //
  // adicionaSelecaoPancreas($event) {
  //   this._abTotal.adicionaSelecaoPancreas($event);
  // }
  //
  // adicionaSelecaoBaco($event) {
  //   this._abTotal.adicionaSelecaoBaco($event);
  // }
  //
  // adicionaSelecaoViasBiliares($event) {
  //   this._abTotal.adicionaSelecaoViasBiliares($event);
  // }
  //
  // limparTermos() {
  //   this._abTotal.limparTermos();
  // }
  //
  // incluirLesoesDinamicas($event) {
  //   this._abTotal.incluirLesoesDinamicas($event);
  // }
  //
  // consultarAPI() {
  //   this._abTotal.consultarAPI();
  //   this.AdicionaTexto();
  // }

  // AdicionaTexto() {
  //   let i;
  //   this.htmlContent = '<div style="text-align: center;"><b>ULTRASSONOGRAFIA DE ABDOME SUPERIOR</div></b>';
  //   this.htmlContent += '<br/>';
  //
  //   for (i = 0; i < this._abTotal.descricoes.length; i++) {
  //     if (this._abTotal.descricoes[i].value) {
  //       this.htmlContent += '<br/>' + this._abTotal.descricoes[i].value + '<br/>';
  //     }
  //   }
  //   this.htmlContent += '<br/><br/>';
  //   this.htmlContent += '<b>OPINIÃO</b>';
  //   this.htmlContent += '<br/>';
  //   for (i = 0; i < this._abTotal.conclusoes.length; i++) {
  //     if (this._abTotal.conclusoes[i].value) {
  //       this.htmlContent += '<br/>' + this._abTotal.conclusoes[i].value + '<br/>';
  //     }
  //   }
  // }

  // ************************************************************************************

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {

    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {

      //Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      //adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  adicionaSelecao($event) {
    this.genericTerms.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoVesicula($event) {
    this.genericTermsVesicula.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoAorta($event) {
    this.genericTermsAorta.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    }
  }

  adicionaSelecaoLinfonodo($event) {
    this.genericTermsLinfo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    }
  }

  adicionaSelecaoAscite($event) {
    this.genericTermsAscite.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    }
  }

  adicionaSelecaoVeiaPorta($event) {
    this.genericTermsVeiaPorta.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    }
  }

  adicionaSelecaoPancreas($event) {
    this.genericTermsPancreas.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoBaco($event) {
    this.genericTermsBaco.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoViasBiliares($event) {
    this.genericTermsViasBiliares.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    }
  }

  incluirLesoesDinamicas($event) {
    const lesoesValue = this._validacoes.formataDecimal($event.elemento.value);

    if ($event.elemento.id == 23 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 23,
          lesionID: 20,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medindo]',
          termView: 'inNoduloMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 23;
            obj.lesionID = 20;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medindo]';
            obj.termView = 'inNoduloMedindo';
          }
        });
      }
    }

    if ($event.linha == 1 && ($event.posicao == 0 || $event.posicao == 1)) {
      this.genericTerms.forEach(obj => {
        if (obj.termID == $event.elemento.id) {
          obj.selected = true;
          obj.value = lesoesValue;
        }
      });
      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaLesoes($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      case '209':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 209,
          lesionID: 20,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]',
          termView: 'slNoduloEcogenicidade'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '198':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 198,
          lesionID: 20,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]',
          termView: 'slNoduloSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '210':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 210,
          lesionID: 20,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Contorno]',
          termView: 'slNoduloContorno'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;

      case '43':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 43,
          lesionID: 40,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inCistoMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '207':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 207,
          lesionID: 40,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]',
          termView: 'slCistoSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;

      case '59':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 59,
          lesionID: 58,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inCalcificacaoMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '208':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 208,
          lesionID: 58,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]',
          termView: 'slCalcificacaoSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;

      case '202':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 202,
          lesionID: 191,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inColecaoMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '203':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 203,
          lesionID: 191,
          linha: $event.linha,
          posicao: 0,
          term: '[Segmento]',
          termView: 'slColecaoSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '195':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 195,
          lesionID: 193,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inHemangiomasMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '197':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 197,
          lesionID: 193,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]',
          termView: 'slHemangiomasSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '43':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 43,
          lesionID: 184,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inCistoMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '207':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 207,
          lesionID: 184,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Segmento]',
          termView: 'slCistoSegmento'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }

    this.lesionRow = $event.linha;
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;

    this.genericTerms = [
      { value: '', selected: false, lesionID: 20, termID: 209, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slNoduloEcogenicidade' },
      { value: '', selected: false, lesionID: 20, termID: 198, cenario: 0, posicao: 0, linha: 0, term: '[Segmento]', termView: 'slNoduloSegmento' },
      { value: '', selected: false, lesionID: 20, termID: 23, cenario: 0, posicao: 1, linha: 0, term: '[Medindo]', termView: 'inNoduloMedindo' },
      { value: '', selected: false, lesionID: 20, termID: 23, cenario: 0, posicao: 2, linha: 0, term: '[Medindo]', termView: 'inNoduloMedindo' },
      { value: '', selected: false, lesionID: 20, termID: 23, cenario: 0, posicao: 3, linha: 0, term: '[Medindo]', termView: 'inNoduloMedindo' },
      { value: '', selected: false, lesionID: 20, termID: 210, cenario: 0, posicao: 0, linha: 0, term: '[Contorno]', termView: 'slNoduloContorno' },
      { value: '', selected: false, lesionID: 40, termID: 43, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inCistoMedindo' },
      { value: '', selected: false, lesionID: 40, termID: 207, cenario: 0, posicao: 0, linha: 0, term: '[Segmento]', termView: 'slCistoSegmento' },
      { value: '', selected: false, lesionID: 58, termID: 208, cenario: 0, posicao: 0, linha: 0, term: '[Segmento]', termView: 'slCalcificacaoSegmento' },
      { value: '', selected: false, lesionID: 58, termID: 59, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inCalcificacaoMedindo' },
      { value: '', selected: false, lesionID: 191, termID: 203, cenario: 0, posicao: 0, linha: 0, term: '[Segmento]', termView: 'slColecaoSegmento' },
      { value: '', selected: false, lesionID: 191, termID: 202, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inColecaoMedindo' },
      { value: '', selected: false, lesionID: 193, termID: 197, cenario: 0, posicao: 0, linha: 0, term: '[Segmento]', termView: 'slHemangiomasSegmento' },
      { value: '', selected: false, lesionID: 193, termID: 195, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inHemangiomasMedindo' }
    ];

    this.genericTermsVesicula = [
      { value: '', selected: false, lesionID: 83, termID: 215, cenario: 0, posicao: 0, linha: 0, term: '[espessura da parede]', termView: 'inEspessuraParede' },
      { value: '', selected: false, lesionID: 83, termID: 216, cenario: 0, posicao: 0, linha: 0, term: '[com/sem]', termView: 'slMurphyUltrassonico' },
      { value: '', selected: false, lesionID: 87, termID: 88, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inCalculosMedindo' },
      { value: '', selected: false, lesionID: 94, termID: 95, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inPoliposMedindo' }
    ];

    this.genericTermsAorta = [
      { value: '', selected: false, lesionID: 184, termID: 184, cenario: 0, posicao: 0, linha: 0, term: '[Calibre]', termView: 'inCalibre' }
    ];

    this.genericTermsLinfo = [
      { value: '', selected: false, lesionID: 4111, termID: 4120, posicao: 0, cenario: 0, linha: 0, term: '[Medidas]', termView: 'inMedida' },
      { value: '', selected: false, lesionID: 4114, termID: 4121, posicao: 0, cenario: 0, linha: 0, term: '[Medidas]', termView: 'inMedida' },
      { value: '', selected: false, lesionID: 4117, termID: 4122, posicao: 0, cenario: 0, linha: 0, term: '[Medidas]', termView: 'inMedida' }
    ];

    this.genericTermsAscite = [
      { value: '', selected: false, lesionID: 175, termID: 175, cenario: 0, posicao: 0, linha: 0, term: '[Grau]', termView: 'slGrau' }
    ];

    this.genericTermsVeiaPorta = [
      { value: '', selected: false, lesionID: 75, termID: 75, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inMedindo' }
    ];

    this.genericTermsBaco = [
      { value: '', selected: false, lesionID: 141, termID: 141, cenario: 0, posicao: 0, linha: 0, term: '[Indice Esplenico]', termView: 'inIndiceEsplenico' },
      { value: '', selected: false, lesionID: 145, termID: 145, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inMedindo' },
      { value: '', selected: false, lesionID: 228, termID: 228, cenario: 0, posicao: 0, linha: 0, term: '[Maior mede]', termView: 'inMaiorMede' },
      { value: '', selected: false, lesionID: 147, termID: 147, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inCalcificacaoMedindo' },
      { value: '', selected: false, lesionID: 148, termID: 148, cenario: 0, posicao: 0, linha: 0, term: '[Local]', termView: 'slCalcificacaoLocal' },
      { value: '', selected: false, lesionID: 150, termID: 150, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inMedindoNodulo' },
      { value: '', selected: false, lesionID: 151, termID: 151, cenario: 0, posicao: 0, linha: 0, term: '[Margens]', termView: 'slNoduloMargem' },
      { value: '', selected: false, lesionID: 152, termID: 152, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slNoduloEcogenicidade' },
      { value: '', selected: false, lesionID: 153, termID: 153, cenario: 0, posicao: 0, linha: 0, term: '[Local]', termView: 'slNoduloLocal' },
      { value: '', selected: false, lesionID: 155, termID: 155, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inMedindoHemangioma' },
      { value: '', selected: false, lesionID: 156, termID: 156, cenario: 0, posicao: 0, linha: 0, term: '[Margens]', termView: 'slHemangiomaMargem' },
      { value: '', selected: false, lesionID: 158, termID: 158, cenario: 0, posicao: 0, linha: 0, term: '[Local]', termView: 'slHemangiomaLocal' }
    ];

    this.genericTermsViasBiliares = [
      { value: '', selected: false, lesionID: 230, termID: 230, cenario: 0, posicao: 0, linha: 0, term: '[Medida Colédoco]', termView: 'inMedidaColedoco' },
      { value: '', selected: false, lesionID: 106, termID: 106, cenario: 0, posicao: 0, linha: 0, term: '[Graduação]', termView: 'slGraducao' },
      { value: '', selected: false, lesionID: 105, termID: 105, cenario: 0, posicao: 0, linha: 0, term: '[Cálculo obstrutivo]', termView: 'inCalculoObs' }
    ];

    this.dinamicLesions = [];
    this.lesionRow = 1;
    this.medicaoNodulo = '';
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      if (this.veiaporta) {
        this.term.blockID = this.veiaportaID;
        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.veiaportaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVeiaPorta(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.veiaportaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.veiaportaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.veiaportaID;
              obj.value = obj.value + espaco + this.MontaConclusaoVeiaPorta(resultado.conclusion);
              espaco = '\n';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.veiaportaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.veiaportaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.veiaportaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.vesiculabiliar) {
        this.term.blockID = this.vesiculabiliarID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.vesiculabiliarID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoVesicula(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.vesiculabiliarID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.vesiculabiliarID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.vesiculabiliarID;
              obj.value = obj.value + espaco + this.MontarConclusaoVesicula(resultado.conclusion);
              espaco = '\n';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.vesiculabiliarID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.vesiculabiliarID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.vesiculabiliarID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.viasbiliares) {
        this.term.blockID = this.viasbiliaresID;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.viasbiliaresID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoViasBiliares(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.viasbiliaresID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.viasbiliaresID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.viasbiliaresID;
              obj.value = obj.value + espaco + this.MontarConclusaoViasBiliares(resultado.conclusion);
              espaco = '\n';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.viasbiliaresID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.viasbiliaresID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.viasbiliaresID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.baco) {
        this.term.blockID = this.bacoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.bacoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoBaco(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.bacoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.bacoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.bacoID;
              obj.value = obj.value + espaco + this.MontarConclusaoBaco(resultado.conclusion);
              espaco = '\n';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.bacoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.bacoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.bacoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.pancreas) {
        this.term.blockID = this.pancreasID;

        this.term.injuries.push(this.injuries);

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.pancreasID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoPancreas(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.pancreasID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.pancreasID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          let espaco = '';
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.pancreasID;
              obj.value = obj.value + espaco + this.MontarConclusaoPancreas(resultado.conclusion);
              espaco = '\n';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.pancreasID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.pancreasID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.pancreasID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.figado) {
        this.term.blockID = this.figadoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.figadoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {

                obj.value = obj.value + espaco + this.MontaDescricao(resultado.text, contadorTexto);
                espaco = '<br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.figadoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.figadoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.figadoID;
              obj.value = obj.value + espaco + this.MontarConclusao(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.figadoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.figadoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.figadoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.aorta) {
        this.term.blockID = this.aortaID;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.aortaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoAorta(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.aortaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.aortaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.aortaID;
              obj.value = obj.value + espaco + this.MontarConclusaoAorta(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.aortaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.aortaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.aortaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ascite) {
        this.term.blockID = this.asciteID;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.asciteID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoAscite(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.asciteID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.asciteID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.asciteID;
              obj.value = obj.value + espaco + this.MontarConclusaoAscite(resultado.conclusion);
              espaco = '\n';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.asciteID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.asciteID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.asciteID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.linfonodo) {
        this.term.blockID = this.linfonodoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.linfonodoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoConclusaoLinfo(resultado.text);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.linfonodoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.linfonodoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.linfonodoID;
              obj.value = obj.value + espaco + this.MontaDescricaoConclusaoLinfo(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.linfonodoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.linfonodoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.linfonodoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.limitacoes) {
        this.term.blockID = this.limitacoesID;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.limitacoesID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              const index = this.descricoes.findIndex(x => x.blockID === this.limitacoesID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.limitacoesID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.limitacoesID;
              obj.value = resultado.conclusion;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.limitacoesID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.limitacoesID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.limitacoesID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  MontaDescricaoConclusaoLinfo(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4120 || +term === 4121 || +term === 4122) {
        this.genericTermsLinfo.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoViasBiliares(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (term == 105 || term == 106 || term == 230) {
        this.genericTermsViasBiliares.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontarConclusaoViasBiliares(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }
    this.term.listTermID.forEach(term => {
      if (term == 105 || term == 106 || term == 230) {
        this.genericTermsViasBiliares.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoBaco(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (term == 141 || term == 145 || term == 228 || term == 147 || term == 148
          || term == 150 || term == 151 || term == 152 || term == 153 || term == 155
          || term == 156 || term == 158) {
          this.genericTermsBaco.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term == 141 || term == 145 || term == 228 || term == 147 || term == 148
          || term == 150 || term == 151 || term == 152 || term == 153 || term == 155
          || term == 156 || term == 158) {
          this.genericTermsBaco.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontarConclusaoBaco(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }
    this.term.listTermID.forEach(term => {
      if (term == 141 || term == 145 || term == 228 || term == 147 || term == 148
        || term == 150 || term == 151 || term == 152 || term == 153 || term == 155
        || term == 156 || term == 158) {
        this.genericTermsBaco.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoPancreas(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (term == 167 || term == 165) {
          this.genericTermsPancreas.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term == 167 || term == 165) {
          this.genericTermsPancreas.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontarConclusaoPancreas(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }
    this.term.listTermID.forEach(term => {
      if (term == 167 || term == 165) {
        this.genericTermsPancreas.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoVeiaPorta(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (term == 75) {
        this.genericTermsVeiaPorta.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoVeiaPorta(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }

    this.term.listTermID.forEach(term => {
      if (term == 75) {
        this.genericTermsVeiaPorta.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoAscite(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (term == 175) {
        this.genericTermsAscite.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontarConclusaoAscite(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }

    this.term.listTermID.forEach(term => {
      if (term == 175) {
        this.genericTermsAscite.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoAorta(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (term == 184) {
        this.genericTermsAorta.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontarConclusaoAorta(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }

    this.term.listTermID.forEach(term => {
      if (term == 184) {
        this.genericTermsAorta.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoVesicula(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.linha - 1) == contadorTexto;
    });

    if (rowDinamic.length > 0) {
      let lesaoID = rowDinamic[0].lesionID;
      texto = this.MontarTextoDescricaoDinamicaVesicula(texto, contadorTexto, lesaoID);
    } else {
      texto = this.MontarTextoDescricaoNormalVesicula(texto, contadorTexto);
    }
    return texto;
  }

  MontarTextoDescricaoNormalVesicula(texto, contadorTexto) {
    // Descrição para lesões normais (1 ou múltiplas)
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';

    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (term == 215 || term == 216 || term == 88 || term == 95) {
          this.genericTermsVesicula.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)

      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term == 215 || term == 216 || term == 88 || term == 95) {
          this.genericTermsVesicula.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    }
    return texto;
  }

  MontarTextoDescricaoDinamicaVesicula(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {

      if (item.linha == linha) {
        if (item.termID == 215 || item.termID == 216 || item.termID == 88 || item.termID == 95) {
          textoDinamico = textoDinamico.replace(item.term, item.value);
        }
      } else {
        linha++;
        textoRetorno = textoRetorno + textoDinamico + '\n';

        if (textoSplit.length == 1) {
          textoDinamico = textoSplit[0];
        } else {
          textoDinamico = textoSplit[1];
        }

        if (item.termID == 215 || item.termID == 216 || item.termID == 88 || item.termID == 95) {
          textoDinamico = textoDinamico.replace(item.term, item.value);
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  MontarConclusaoVesicula(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }

    this.term.listTermID.forEach(term => {
      if (term == 209 || term == 198 || term == 210 || term == 207 || term == 208 || term == 203 || term == 197 || term == 23
        || term == 43 || term == 59 || term == 202 || term == 195) {
        this.genericTermsVesicula.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricao(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.linha - 1) == contadorTexto && (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.MontarTextoDescricaoDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.MontarTextoDescricaoNormal(texto, contadorTexto);
    }
    return texto;
  }

  MontarTextoDescricaoNormal(texto, contadorTexto) {
    // Descrição para lesões normais (1 ou múltiplas)
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';

    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (term == 209 || term == 198 || term == 210 || term == 207 || term == 208 || term == 203 || term == 197 || term == 23
          || term == 43 || term == 59 || term == 202 || term == 195) {
          this.genericTerms.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)

      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (term == 209 || term == 198 || term == 210 || term == 207 || term == 208 || term == 203 || term == 197 || term == 23
          || term == 43 || term == 59 || term == 202 || term == 195) {
          this.genericTerms.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    }
    return texto;
  }

  MontarTextoDescricaoDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }
    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 209 || item.termID == 198 || item.termID == 210 || item.termID == 207 || item.termID == 208
            || item.termID == 203 || item.termID == 197 || item.termID == 23 || item.termID == 43 || item.termID == 59
            || item.termID == 202 || item.termID == 195) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico + '\n';

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (item.termID == 209 || item.termID == 198 || item.termID == 210 || item.termID == 207 || item.termID == 208
            || item.termID == 203 || item.termID == 197 || item.termID == 23 || item.termID == 43 || item.termID == 59
            || item.termID == 202 || item.termID == 195) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  MontarConclusao(texto) {
    let textoSplit = '';
    let textoRetorno = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '</br>' + textoSplit[1];
    }

    this.term.listTermID.forEach(term => {
      if (term == 209 || term == 198 || term == 210 || term == 207 || term == 208 || term == 203 || term == 197 || term == 23
        || term == 43 || term == 59 || term == 202 || term == 195) {
        this.genericTerms.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    // if (this.conclusoes.length <= 1) {
    texto = 'Estudo ultrassonográfico sem alterações significativas.';
    // }
    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p></br>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    this.descricoes.forEach(descricao => {
      if (descricao.value) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.indicacaoTexto;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContent += maskSplit[1];

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
    }
    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }
    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId} p`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId} p`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
