import { Component, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-sela-turca',
  templateUrl: './sela-turca.component.html',
  styleUrls: ['./sela-turca.component.css']
})
export class SelaTurcaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdAumentada') rdAumentada: MatRadioButton;
  @ViewChild('slErosao') slErosao: MatSelect;

  showAndHideTerms: any;
  
  erosoes: ArrayDropDown[] = [
    { value: 'Sem sinais de erosão', viewValue: 'Sem sinais de erosão' },
    { value: 'Com erosão do assoalho', viewValue: 'Com erosão do assoalho' },
    { value: 'Com erosão do processo clinóide anterior', viewValue: 'Com erosão do processo clinóide anterior' },
    { value: 'Com erosão do processo clinóide posterior', viewValue: 'Com erosão do processo clinóide posterior' },
    { value: 'Com erosão dos processos clinóides', viewValue: 'Com erosão dos processos clinóides' },
  ];

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCitar: false,
    };
  }
  
  clickDefault(){
    if (this.rdNaoCitar.checked) {
      this.showAndHideTerms.sahCitar = false;
    } else if (this.rdAumentada.checked) {
      this.showAndHideTerms.sahCitar = true;
    }

    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  } 

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }
    if (this.rdAumentada.checked) {
      this.termoSelecionado.emit(this.rdAumentada.id);

      if(this.slErosao && this.slErosao.value){
        const objeto = {elemento: this.slErosao, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
