import { ArrayDropDown } from './../../../../../models/Helper/ArrayDropDown';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-processo-transverso',
  templateUrl: './processo-transverso.component.html',
  styleUrls: ['./processo-transverso.component.css']
})
export class ProcessoTransversoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdDireita') rdDireita: MatRadioButton;
  @ViewChild('rdDireitaMult') rdDireitaMult: MatRadioButton;
  @ViewChild('rdEsquerda') rdEsquerda: MatRadioButton;
  @ViewChild('rdEsquerdaMult') rdEsquerdaMult: MatRadioButton;
  @ViewChild('rdBilateral') rdBilateral: MatRadioButton;

  @ViewChild('slNivelFraturaDireita') slNivelFraturaDireita: MatSelect;
  @ViewChild('slNivelFraturaDireitaMult') slNivelFraturaDireitaMult: MatSelect;
  @ViewChild('slNivelFraturaEsquerda') slNivelFraturaEsquerda: MatSelect;
  @ViewChild('slNivelFraturaEsquerdaMult') slNivelFraturaEsquerdaMult: MatSelect;

  nivelFraturaDireita: ArrayDropDown[] = [
    { value: 'C2', viewValue: 'C2' },
    { value: 'C3', viewValue: 'C3' },
    { value: 'C4', viewValue: 'C4' },
    { value: 'C5', viewValue: 'C5' },
    { value: 'C6', viewValue: 'C6' },
    { value: 'C7', viewValue: 'C7' },
  ];

  nivelFraturaEsquerda: ArrayDropDown[] = [
    { value: 'C2', viewValue: 'C2' },
    { value: 'C3', viewValue: 'C3' },
    { value: 'C4', viewValue: 'C4' },
    { value: 'C5', viewValue: 'C5' },
    { value: 'C6', viewValue: 'C6' },
    { value: 'C7', viewValue: 'C7' },
  ];

  countLesoes = 0;
  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {

    this.showAndHideTerms = {
      sahFraturaDireita: false,
      sahFraturaDireitaMult: false,
      sahFraturaEsquerda: false,
      sahFraturaEsquerdaMult: false,
      sahFraturaBilateral: false
    };

  }

  clickNormal(){
    this.showAndHideTerms.sahFraturaDireita = false,
    this.showAndHideTerms.sahFraturaDireitaMult = false,
    this.showAndHideTerms.sahFraturaEsquerda = false,
    this.showAndHideTerms.sahFraturaEsquerdaMult = false,
    this.showAndHideTerms.sahFraturaBilateral = false
    this.catchIDs();
  }

  clickFraturaDireita(){
    this.showAndHideTerms.sahFraturaDireitaMult = false,
    this.showAndHideTerms.sahFraturaEsquerda = false,
    this.showAndHideTerms.sahFraturaEsquerdaMult = false,
    this.showAndHideTerms.sahFraturaBilateral = false
    this.showAndHideTerms.sahFraturaDireita = !this.showAndHideTerms.sahFraturaDireita;
    this.catchIDs();
  }

  clickFraturaDireitaMult(){
    this.showAndHideTerms.sahFraturaDireita = false,
    this.showAndHideTerms.sahFraturaEsquerda = false,
    this.showAndHideTerms.sahFraturaEsquerdaMult = false,
    this.showAndHideTerms.sahFraturaBilateral = false
    this.showAndHideTerms.sahFraturaDireitaMult = !this.showAndHideTerms.sahFraturaDireitaMult;
    this.catchIDs();
  }

  clickFraturaEsquerda(){
    this.showAndHideTerms.sahFraturaDireita = false,
    this.showAndHideTerms.sahFraturaDireitaMult = false,
    this.showAndHideTerms.sahFraturaEsquerdaMult = false,
    this.showAndHideTerms.sahFraturaBilateral = false
    this.showAndHideTerms.sahFraturaEsquerda = !this.showAndHideTerms.sahFraturaEsquerda;
    this.catchIDs();
  }

  clickFraturaEsquerdaMult(){
    this.showAndHideTerms.sahFraturaDireita = false,
    this.showAndHideTerms.sahFraturaDireitaMult = false,
    this.showAndHideTerms.sahFraturaEsquerda = false,
    this.showAndHideTerms.sahFraturaBilateral = false
    this.showAndHideTerms.sahFraturaEsquerdaMult = !this.showAndHideTerms.sahFraturaEsquerdaMult;
    this.catchIDs();
  }

  clickMultiplas(){
    this.showAndHideTerms.sahFraturaDireita = false,
    this.showAndHideTerms.sahFraturaDireitaMult = false,
    this.showAndHideTerms.sahFraturaEsquerda = false,
    this.showAndHideTerms.sahFraturaEsquerdaMult = false,
    this.showAndHideTerms.sahFraturaBilateral = !this.showAndHideTerms.sahFraturaBilateral;
    this.catchIDs();
  }



  changeSelect(){
    this.catchIDs();
  }

  catchIDs(){
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }
    if (this.rdDireita.checked) {
      this.termoSelecionado.emit(this.rdDireita.id);
      if(this.slNivelFraturaDireita && this.slNivelFraturaDireita.value){
        const objeto = {elemento: this.slNivelFraturaDireita, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
    if (this.rdDireitaMult.checked) {
      this.termoSelecionado.emit(this.rdDireitaMult.id);
      if(this.slNivelFraturaDireitaMult && this.slNivelFraturaDireitaMult.value){
        const objeto = {elemento: this.slNivelFraturaDireitaMult, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
    if (this.rdEsquerda.checked) {
      this.termoSelecionado.emit(this.rdEsquerda.id);
      if(this.slNivelFraturaEsquerda && this.slNivelFraturaEsquerda.value){
        const objeto = {elemento: this.slNivelFraturaEsquerda, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }

    }
    if (this.rdEsquerdaMult.checked) {
      this.termoSelecionado.emit(this.rdEsquerdaMult.id);
      if(this.slNivelFraturaEsquerdaMult && this.slNivelFraturaEsquerdaMult.value){
        const objeto = {elemento: this.slNivelFraturaEsquerdaMult, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
    if (this.rdBilateral.checked) {
      this.termoSelecionado.emit(this.rdBilateral.id);
      if(this.slNivelFraturaDireitaMult && this.slNivelFraturaDireitaMult.value){
        const objeto = {elemento: this.slNivelFraturaDireitaMult, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelFraturaEsquerdaMult && this.slNivelFraturaEsquerdaMult.value){
        const objeto = {elemento: this.slNivelFraturaEsquerdaMult, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
