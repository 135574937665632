import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-consolidacao-atelectasia',
  templateUrl: './consolidacao-atelectasia.component.html',
  styleUrls: ['./consolidacao-atelectasia.component.css']
})
export class ConsolidacaoAtelectasiaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  // @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('slLado') slLado: MatSelect;

  @ViewChild('ckComFoco') ckComFoco: MatCheckbox;

  lados: ArrayDropDown[];

  showAndHideTerms: any;
  codAusente;
  textAusente;
  codPresente;
  textPresente;
  codLado;
  textLado;
  codFoco;
  textFoco;

  constructor() { }

  ngOnInit() {
    this.codAusente = "1921";
    this.textAusente = "Ausente";
    this.codPresente = "1923";
    this.textPresente = "Presente";
    this.codLado = "1922";
    this.textLado = "Lado";
    this.codFoco = "1924";
    this.textFoco = "Com focos ecogênicos";

    this.lados = [
      { value: 'esquerdo', viewValue: 'Esquerdo' },
      { value: 'direito', viewValue: 'Direito' }
    ];

    this.showAndHideTerms = {
      sahPresente: true
    }
  }

  clickAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickpresente() {
    this.showAndHideTerms.sahPresente = true;
    this.catchIDs();
  }

  clickComFoco() {
    this.verificaAlterado();
    this.catchIDs();
  }

  changeSelect() {
    this.verificaAlterado();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente && this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);
      if (this.slLado && this.slLado.value) {
        const objeto = { elemento: this.slLado, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.ckComFoco && this.ckComFoco.checked) {
        this.termoSelecionado.emit(this.ckComFoco.id);
      }
    }

    // this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  verificaAlterado() {
    if (this.slLado.value ||
      this.ckComFoco.checked) {
      // Marca Presente
      this.rdPresente.checked = true;
    }
  }

}
