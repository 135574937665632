import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Estado} from '../baco/baco.component';
import {MatCheckbox, MatRadioButton, MatSelect} from '@angular/material';

export interface Graducao {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-vias-biliares',
  templateUrl: './vias-biliares.component.html',
  styleUrls: ['./vias-biliares.component.css']
})
export class ViasBiliaresComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdDilatacao') rdDilatacao: MatRadioButton;
  @ViewChild('inMedidaColedoco') inMedidaColedoco: ElementRef;

  @ViewChild('slGraduacao') slGraduacao: MatSelect;

  @ViewChild('ckIntraHepatica') ckIntraHepatica: MatCheckbox;
  @ViewChild('ckExtraHepatica') ckExtraHepatica: MatCheckbox;

  @ViewChild('ckCalculoObs') ckCalculoObs: MatCheckbox;
  @ViewChild('inCalculoObs') inCalculoObs: ElementRef;



  graduacoes: Graducao[] = [
    {value: 'Leve', viewValue: 'Leve'},
    {value: 'Moderada', viewValue: 'Moderada'},
    {value: 'Acentuada', viewValue: 'Acentuada'}
  ];

  showAndHideTerms: any;
  objetoGenerico: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahDilatacao: true,
      sahCalculoObstrutivo: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahDilatacao = false;
    this.showAndHideTerms.sahCalculoObstrutivo = false;
    this.catchIDs();
  }

  clickDilatacao() {
    this.showAndHideTerms.sahDilatacao = true;
    this.showAndHideTerms.sahCalculoObstrutivo = false;
    this.catchIDs();
  }

  clickCalculoObs() {
    if (this.showAndHideTerms.sahCalculoObstrutivo === false) {
      this.showAndHideTerms.sahCalculoObstrutivo = true;
    } else {
      this.showAndHideTerms.sahCalculoObstrutivo = false;
    }
    this.alteraAusentePresente();
    this.catchIDs();
  }

  clickIntraHepatica() {
    this.alteraAusentePresente();
    this.catchIDs();
  }

  clickExtraHepatica() {
    this.alteraAusentePresente();
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  changeGraducao(id, $event) {
    this.catchIDs();
  }

  catchIDs() {

    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.inMedidaColedoco != undefined &&
        this.inMedidaColedoco.nativeElement.id != undefined &&
        this.inMedidaColedoco.nativeElement.value != '') {

        const objeto = {elemento: this.inMedidaColedoco.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdDilatacao.checked) {
      this.termoSelecionado.emit(this.rdDilatacao.id);

      if (this.inMedidaColedoco != undefined &&
        this.inMedidaColedoco.nativeElement.id != undefined &&
        this.inMedidaColedoco.nativeElement.value != '') {

        const objeto = {elemento: this.inMedidaColedoco.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.ckExtraHepatica != undefined && this.ckExtraHepatica.checked) {
        this.termoSelecionado.emit(this.ckExtraHepatica.id);
      }

      if (this.ckIntraHepatica != undefined && this.ckIntraHepatica.checked) {
        this.termoSelecionado.emit(this.ckIntraHepatica.id);
      }

      if (this.slGraduacao != undefined && this.slGraduacao.value != undefined) {
        const objeto = {elemento: this.slGraduacao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.ckCalculoObs != undefined && this.ckCalculoObs.checked){
        if (this.inCalculoObs != undefined &&
          this.inCalculoObs.nativeElement.id != undefined &&
          this.inCalculoObs.nativeElement.value != '') {

          const objeto = {elemento: this.inCalculoObs.nativeElement, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }
    }

    this.listaCompleta.emit();
  }

  alteraAusentePresente() {
   if ((this.ckExtraHepatica != undefined && this.ckExtraHepatica.checked) ||
   (this.ckIntraHepatica != undefined && this.ckIntraHepatica.checked) ||
   (this.ckCalculoObs != undefined && this.ckCalculoObs.checked)
   ) {
     this.rdDilatacao.checked = true;
   } else {
    this.rdNormal.checked = true;
    this.rdDilatacao.checked = false;
   }
  }


}
