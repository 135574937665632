import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatExpansionModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatTableDataSource,
  MatDialogModule,
  MatTooltipModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatSlideToggleModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from '../../../utils/textEditor/text-editor/text-editor.module';
import { ArcaboucoComponent } from './TermosComponents/arcabouco/arcabouco.component';
import { CoracaoComponent } from './TermosComponents/coracao/coracao.component';
import { TuboCateterComponent } from './TermosComponents/tubo-cateter/tubo-cateter.component';
import { EsofagoComponent } from './TermosComponents/esofago/esofago.component';
import { EspacoPleuralComponent } from './TermosComponents/espaco-pleural/espaco-pleural.component';
import { EstruturasVascularesComponent } from './TermosComponents/estruturas-vasculares/estruturas-vasculares.component';
import { IndicacaoToraxComponent } from './TermosComponents/indicacao-torax/indicacao-torax.component';
import { LinfonodoToraxComponent } from './TermosComponents/linfonodo-torax/linfonodo-torax.component';
import { ParedeToracicaComponent } from './TermosComponents/parede-toracica/parede-toracica.component';
import { ParenquimaPulmonarComponent } from './TermosComponents/parenquima-pulmonar/parenquima-pulmonar.component';
import { TransicaoAbdominalComponent } from './TermosComponents/transicao-abdominal/transicao-abdominal.component';
import { TraqueiaBronquiosComponent } from './TermosComponents/traqueia-bronquios/traqueia-bronquios.component';
import { ToraxComponent } from './torax.component';
import { MetodoToraxComponent } from './TermosComponents/metodo-torax/metodo-torax.component';
import { ExameComparativoTctoraxComponent } from './TermosComponents/exame-comparativo-tctorax/exame-comparativo-tctorax.component';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { ArtefatosLimitacoesComponent } from './TermosComponents/artefatos-limitacoes/artefatos-limitacoes.component';
import { SharedModule } from '../../shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
    ToraxComponent,
    ArcaboucoComponent,
    CoracaoComponent,
    TuboCateterComponent,
    EsofagoComponent,
    EspacoPleuralComponent,
    EstruturasVascularesComponent,
    IndicacaoToraxComponent,
    LinfonodoToraxComponent,
    ParedeToracicaComponent,
    ParenquimaPulmonarComponent,
    TransicaoAbdominalComponent,
    TraqueiaBronquiosComponent,
    MetodoToraxComponent,
    ExameComparativoTctoraxComponent,
    ArtefatosLimitacoesComponent
  ],
  declarations: [
    ToraxComponent,
    ArcaboucoComponent,
    CoracaoComponent,
    TuboCateterComponent,
    EsofagoComponent,
    EspacoPleuralComponent,
    EstruturasVascularesComponent,
    IndicacaoToraxComponent,
    LinfonodoToraxComponent,
    ParedeToracicaComponent,
    ParenquimaPulmonarComponent,
    TransicaoAbdominalComponent,
    TraqueiaBronquiosComponent,
    MetodoToraxComponent,
    ExameComparativoTctoraxComponent,
    ArtefatosLimitacoesComponent
  ]
})
export class ToraxModule { }
