import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { ListaPlanosService } from '../services/lista-planos.service';

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.css']
})
export class MenuLateralComponent implements OnInit {

  nomeUsuario: string;
  url: string;
  planoAtivo: boolean;
  isExpand = false;

  constructor(private router: Router,
    private authService: AuthService,
    private _listaPlanosService: ListaPlanosService,
    ) { }

  ngOnInit() {
    this.buscaPlanoDoUsuario();
    this.nomeUsuario = localStorage.getItem('nomeUsuario');
    this.buscaPlanoDoUsuario();
  }

  clickButton(url) {
    this.router.navigateByUrl(url);
  }
  clickExpand() {
    this.isExpand = !this.isExpand;
  }
  
  onLogout() {
    this.authService.logout();
  }

  buscaPlanoDoUsuario() {
    this._listaPlanosService.buscaPlanoDoUsuario(
      parseInt(localStorage.getItem('userID'))
    ).subscribe(planoDoUsuario => {
      if(planoDoUsuario.length > 0) {
        for(let i = 0; i < planoDoUsuario.length; i++) {
          if(planoDoUsuario[i].planID != 7 && planoDoUsuario[i].planID != 2) {
            this.planoAtivo = true;
          } else {
            this.planoAtivo = false;
          }
        }
      } else {
        this.planoAtivo = false;
      }
    }, erro => {
      console.log(erro);
    });
  }
}
