import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-assimetria',
  templateUrl: './assimetria.component.html',
  styleUrls: ['./assimetria.component.css']
})
export class AssimetriaComponent implements OnInit {
  
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('slTipos') slTipos: MatSelect;
  @ViewChild('slMama') slMama: MatSelect;
  @ViewChild('slLocal') slLocal: MatSelect;

  tipos: ArrayDropDown[] = [
    { value: 'assimetria', viewValue: 'Assimetria' },
    { value: 'assimetria focal', viewValue: 'Assimetria focal' },
    { value: 'assimetria global', viewValue: 'Assimetria global' },
    { value: 'assimetria em desenvolvimento', viewValue: 'Assimetria em desenvolvimento' },
  ];

  mamas: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];

  locais: ArrayDropDown[] = [
    { value: 'na região retroareolar', viewValue: 'Região retroareolar' },
    { value: 'no quadrante superolateral', viewValue: 'Quadrante superolateral' },
    { value: 'no quadrante inferolateral', viewValue: 'Quadrante inferolateral' },
    { value: 'no quadrante superomedial', viewValue: 'Quadrante superomedial' },
    { value: 'no quadrante inferomedial', viewValue: 'Quadrante inferomedial' },
    { value: 'na junção dos quadrantes superiores', viewValue: 'Junção dos quadrantes superiores' },
    { value: 'na junção dos quadrantes inferiores', viewValue: 'Junção dos quadrantes inferiores' },
    { value: 'na junção dos quadrantes laterais', viewValue: 'Junção dos quadrantes laterais' },
    { value: 'na junção dos quadrantes mediais', viewValue: 'Junção dos quadrantes mediais' }
  ];
  
  showAndHideTerms: any;
  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true
    };
  }
  clickrdAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.catchIDs();
  }

  changeSelect() {
    this.alteraAusentePresente();
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente && this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente && this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);

      if (this.slTipos && this.slTipos.value) {
        const objeto = { elemento: this.slTipos, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slMama && this.slMama.value) {
        const objeto = { elemento: this.slMama, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocal && this.slLocal.value) {
        const objeto = { elemento: this.slLocal, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

  alteraAusentePresente() {
    if ((this.slTipos && this.slTipos.value) ||
        (this.slMama && this.slMama.value) ||
        (this.slLocal && this.slLocal.value)
    ) {
      this.rdPresente.checked = true;
    } else {
      this.rdAusente.checked = true;
      this.rdPresente.checked = false;
    }
  }

}
