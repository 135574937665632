import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatCheckbox, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-dispositivos',
  templateUrl: './dispositivos.component.html',
  styleUrls: ['./dispositivos.component.css']
})
export class DispositivosComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdCitar') rdCitar: MatRadioButton;

  @ViewChild('ckGastroenteral') ckGastroenteral: MatCheckbox;
  @ViewChild('ckCateterVenoso') ckCateterVenoso: MatCheckbox;
  @ViewChild('ckSondaVesical') ckSondaVesical: MatCheckbox;
  @ViewChild('ckCateterDJ') ckCateterDJ: MatCheckbox;
  @ViewChild('ckGastrostomia') ckGastrostomia: MatCheckbox;
  @ViewChild('ckCateterVentriculoperitoneal') ckCateterVentriculoperitoneal: MatCheckbox;

  @ViewChild('slExtremidadeGastroenteral') slExtremidadeGastroenteral : MatSelect;
  @ViewChild('slLateralidadeCateter') slLateralidadeCateter : MatSelect;
  @ViewChild('slLateralidadeCateterDJ') slLateralidadeCateterDJ : MatSelect;

  showAndHideTerms;
  countLesoes = 0;
  presente=true;

  extremidadeGastroenteral: ArrayDropDown[]=[
    { value: 'na câmara gástrica', viewValue: 'Câmara gástrica' },
    { value: 'no duodeno', viewValue: 'Duodeno' }
  ];

  lateralidadeCateter: ArrayDropDown[]=[
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];

  lateralidadeCateterDJ: ArrayDropDown[]=[
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms={
      cateterDJ:false,
      cateterVenoso:false,
      gastroenteral:false
    }
  }
  clickNormal(){
    this.showAndHideTerms.gastroenteral=false;
    this.showAndHideTerms.cateterVenoso=false;
    this.showAndHideTerms.cateterDJ=false;

    this.ckCateterDJ.checked=false;
    this.ckCateterVenoso.checked=false;
    this.ckGastroenteral.checked=false;
    this.ckGastrostomia.checked=false;
    this.ckSondaVesical.checked=false;
    this.ckCateterVentriculoperitoneal.checked=false;

    this.checkAlterado();
    this.catchIDs();
  }

  clickDefault(){
    this.checkAlterado();
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  clickGastroenteral(){
    this.showAndHideTerms.gastroenteral= !this.showAndHideTerms.gastroenteral;
    this.checkAlterado();
    this.catchIDs();
  }

  clickCateterVenoso(){
    this.showAndHideTerms.cateterVenoso= !this.showAndHideTerms.cateterVenoso;
    this.checkAlterado();
    this.catchIDs();
  }

  clickCateterDJ(){
    this.showAndHideTerms.cateterDJ= !this.showAndHideTerms.cateterDJ;
    this.checkAlterado();
    this.catchIDs();
  }

  checkAlterado(){
    if(this.ckSondaVesical.checked || this.ckCateterDJ.checked || this.ckCateterVenoso.checked || this.ckCateterVentriculoperitoneal.checked || this.ckGastrostomia.checked || this.ckGastroenteral.checked){
      this.rdCitar.checked=true;
      this.rdNaoCitar.checked=false;
    }else{
      this.rdCitar.checked=false;
      this.rdNaoCitar.checked=true;
    }
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if(this.countLesoes > 0){
      this.emitGastroenteralLesao();
    } else {
      this.emitGastroenteralSimples();
    }

    if(this.countLesoes > 0){
      this.emitCateterVenosoLesao();
    } else {
      this.emitCateterVenosoSimples();
    }

    if(this.countLesoes > 0){
      this.emitSondaVesicalLesao();
    } else {
      this.emitSondaVesicalSimples();
    }

    if(this.countLesoes > 0){
      this.emitCateterDJLesao();
    } else {
      this.emitCateterDJSimples();
    }

    if(this.countLesoes > 0){
      this.emitCateterLesao();
    } else {
      this.emitCateterSimples();
    }

    if(this.countLesoes > 0){
      this.emitGastrostomiaLesao();
    } else {
      this.emitGastrostomiaSimples();
    }

    if(this.rdNaoCitar.checked){
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }

    this.listaCompleta.emit();
  }

  emitGastroenteralLesao(){
    if(this.ckGastroenteral.checked){
      this.countLesoes++;
      const objeto = { id: this.ckGastroenteral.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slExtremidadeGastroenteral && this.slExtremidadeGastroenteral.value){
        const objeto = {elemento: this.slExtremidadeGastroenteral, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitGastroenteralSimples(){
    if(this.ckGastroenteral.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckGastroenteral.id);

      if(this.slExtremidadeGastroenteral && this.slExtremidadeGastroenteral.value){
        const objeto = {elemento: this.slExtremidadeGastroenteral, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCateterVenosoLesao(){
    if(this.ckCateterVenoso.checked){
      this.countLesoes++;
      const objeto = { id: this.ckCateterVenoso.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLateralidadeCateter && this.slLateralidadeCateter.value){
        const objeto = {elemento: this.slLateralidadeCateter, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCateterVenosoSimples(){
    if(this.ckCateterVenoso.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCateterVenoso.id);

      if(this.slLateralidadeCateter && this.slLateralidadeCateter.value){
        const objeto = {elemento: this.slLateralidadeCateter, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitSondaVesicalLesao(){
    if(this.ckSondaVesical.checked){
      this.countLesoes++;
      const objeto = { id: this.ckSondaVesical.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitSondaVesicalSimples(){
    if(this.ckSondaVesical.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckSondaVesical.id);
    }
  }

  emitCateterDJLesao(){
    if(this.ckCateterDJ.checked){
      this.countLesoes++;
      const objeto = { id: this.ckCateterDJ.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLateralidadeCateterDJ && this.slLateralidadeCateterDJ.value){
        const objeto = {elemento: this.slLateralidadeCateterDJ, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCateterDJSimples(){
    if(this.ckCateterDJ.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCateterDJ.id);

      if(this.slLateralidadeCateterDJ && this.slLateralidadeCateterDJ.value){
        const objeto = {elemento: this.slLateralidadeCateterDJ, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCateterLesao(){
    if(this.ckCateterVentriculoperitoneal.checked){
      this.countLesoes++;
      const objeto = { id: this.ckCateterVentriculoperitoneal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitCateterSimples(){
    if(this.ckCateterVentriculoperitoneal.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCateterVentriculoperitoneal.id);
    }
  }

  emitGastrostomiaLesao(){
    if(this.ckGastrostomia.checked){
      this.countLesoes++;
      const objeto = { id: this.ckGastrostomia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitGastrostomiaSimples(){
    if(this.ckGastrostomia.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckGastrostomia.id);
    }
  }
}
