import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipeModule } from 'ngx-filter-pipe';
import {
  MatButtonToggleModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatButtonModule,
  MatIconModule,
  MatCheckboxModule,
  MatBadgeModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSidenavModule,
  MatStepperModule,
  MatExpansionModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PlanosComponent } from './planos.component';
import { PagamentosComponent } from './pagamentos/pagamentos.component';
import { PagamentosModule } from './pagamentos/pagamentos.modules';
import { MenuLateralModule } from '../menu-lateral/menu-lateral.module';
import { MenuLateralComponent } from '../menu-lateral/menu-lateral.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PagamentosDiretoComponent } from './pagamentos-direto/pagamentos-direto.component';
import { InteractivePaycardModule  } from 'ngx-interactive-paycard';

@NgModule({
  imports: [
    CommonModule,
    FilterPipeModule,
    MatButtonToggleModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    MatCheckboxModule,
    FormsModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    PagamentosModule,
    MenuLateralModule,
    MatBadgeModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatStepperModule,
    InteractivePaycardModule,
    MatExpansionModule
  ],
  exports: [PlanosComponent, PagamentosComponent ],
  declarations: [PlanosComponent, PagamentosDiretoComponent],
  entryComponents: [PagamentosComponent, MenuLateralComponent]
})
export class PlanosModule { }
