import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-mst-cordao-umbilical',
  templateUrl: './mst-cordao-umbilical.component.html',
  styleUrls: ['./mst-cordao-umbilical.component.css']
})
export class MstCordaoUmbilicalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdArteria') rdArteria: MatRadioButton;
  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('slInsercaoCordao') slInsercaoCordao: MatSelect;

  showAndHideTerms: any;

  cordaoUmbilical: ArrayDropDown[] = [
    { value: 'Central', viewValue: 'Central' },
    { value: 'Paracentral', viewValue: 'Paracentral' },
    { value: 'Marginal (< 2cm da margem placentária)', viewValue: 'Marginal (< 2cm da margem placentária)' },
    { value: 'Velamentosa', viewValue: 'Velamentosa' },
    { value: 'Não pôde ser examinada', viewValue: 'Não pôde ser examinada' },
    { value: 'Incerta', viewValue: 'Incerta' }
  ];

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahInsercaoCordaoUmbilical: true,
    }
  }

  clickNormal(){
    this.showAndHideTerms.sahInsercaoCordaoUmbilical = false;
    this.catchIDs();
  }
  clickInsercaoCordao(){
    this.showAndHideTerms.sahInsercaoCordaoUmbilical = true;
    this.catchIDs();
  }
  changeSelect(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked){
      this.termoSelecionado.emit(this.rdNormal.id);
    }
    
    if (this.rdArteria && this.rdArteria.checked){
      this.termoSelecionado.emit(this.rdArteria.id);
    }

    if (this.rdNaoCitar && this.rdNaoCitar.checked){
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }

    if (this.slInsercaoCordao && this.slInsercaoCordao.value) {
      const objeto = { elemento: this.slInsercaoCordao, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
    
    this.listaCompleta.emit();
  }

}
