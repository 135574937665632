import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { Validacoes } from '../../../../../../utils/validacoes';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-cisto',
  templateUrl: './cisto.component.html',
  styleUrls: ['./cisto.component.css']
})
export class CistoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  // @Output() atualizaTexto = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdAusencia') rdAusencia: MatRadioButton;
  @ViewChild('rdCisto') rdCisto: MatRadioButton;

  //Simples
  @ViewChild('ckCistoSimples') ckCistoSimples: MatCheckbox;
  @ViewChild('rdUmCistoSimples') rdUmCistoSimples: MatRadioButton;
  @ViewChild('rdDoisCistosSimples') rdDoisCistosSimples: MatRadioButton;
  @ViewChild('inMedidaCistoSimples') inMedidaCistoSimples: ElementRef;
  @ViewChild('slLocalizacaoCistoSimples') slLocalizacaoCistoSimples: MatSelect;
  @ViewChild('slLadoMamaCistoSimples') slLadoMamaCistoSimples: MatSelect;
  @ViewChild('slLadoMamaCistoSimplesDinamico') slLadoMamaCistoSimplesDinamico: MatSelect;

  //Complicado
  @ViewChild('ckCistoComplicado') ckCistoComplicado: MatCheckbox;
  @ViewChild('rdUmCistoComplicado') rdUmCistoComplicado: MatRadioButton;
  @ViewChild('rdDoisCistosComplicado') rdDoisCistosComplicado: MatRadioButton;
  @ViewChild('inMedidaCistoComplicado') inMedidaCistoComplicado: ElementRef;
  @ViewChild('slLocalizacaoCistoComplicado') slLocalizacaoCistoComplicado: MatSelect;
  @ViewChild('slLadoMamaCistoComplicado') slLadoMamaCistoComplicado: MatSelect;
  @ViewChild('slConteudoCistoComplicado') slConteudoCistoComplicado: MatSelect;
  @ViewChild('inDistPeleCistoComplicado') inDistPeleCistoComplicado: ElementRef;
  @ViewChild('inDistPapilaCistoComplicado') inDistPapilaCistoComplicado: ElementRef;
  @ViewChild('slLadoMamaCistoComplicadoDinamico') slLadoMamaCistoComplicadoDinamico: MatSelect;
  @ViewChild('slConteudoCistoComplicadoDinamico') slConteudoCistoComplicadoDinamico: MatSelect;


  //Complexo
  @ViewChild('ckCistoComplexo') ckCistoComplexo: MatCheckbox;
  @ViewChild('slParedeCistoComplexo') slParedeCistoComplexo: MatSelect;
  @ViewChild('slSeptoCistoComplexo') slSeptoCistoComplexo: MatSelect;
  @ViewChild('slnoduloMuralCistoComplexo') slnoduloMuralCistoComplexo: MatSelect;
  @ViewChild('inMedidaCistoComplexo1') inMedidaCistoComplexo1: ElementRef;
  @ViewChild('inMedidaCistoComplexo2') inMedidaCistoComplexo2: ElementRef;
  @ViewChild('inMedidaCistoComplexo3') inMedidaCistoComplexo3: ElementRef;
  @ViewChild('slLocalizacaoCistoComplexo') slLocalizacaoCistoComplexo: MatSelect;
  @ViewChild('slLadoMamaCistoComplexo') slLadoMamaCistoComplexo: MatSelect;
  @ViewChild('inDistPeleCistoComplexo') inDistPeleCistoComplexo: ElementRef;
  @ViewChild('inDistPapilaCistoComplexo') inDistPapilaCistoComplexo: ElementRef;

  //Agrupado
  @ViewChild('ckMicroAgrupados') ckMicroAgrupados: MatCheckbox;
  @ViewChild('rdUmConjunto') rdUmConjunto: MatRadioButton;
  @ViewChild('rdMultiplosConjuntos') rdMultiplosConjuntos: MatRadioButton;
  @ViewChild('rdDoisOuMaisConjuntos') rdDoisOuMaisConjuntos: MatRadioButton;
  @ViewChild('inMedidaMicroAgrupados1') inMedidaMicroAgrupados1: ElementRef;
  @ViewChild('inMedidaMicroAgrupados2') inMedidaMicroAgrupados2: ElementRef;
  @ViewChild('inMedidaMicroAgrupados3') inMedidaMicroAgrupados3: ElementRef;
  @ViewChild('slLocalizacaoMicroAgrupados') slLocalizacaoMicroAgrupados: MatSelect;
  @ViewChild('slLadoMamaMicroAgrupados') slLadoMamaMicroAgrupados: MatSelect;
  @ViewChild('slMaiorNaMama') slMaiorNaMama: MatSelect;
  @ViewChild('inDistPeleMicroAgrupados') inDistPeleMicroAgrupados: ElementRef;
  @ViewChild('inDistPapilaMicroAgrupados') inDistPapilaMicroAgrupados: ElementRef;
  @ViewChild('ckVascularizacao') ckVascularizacao: MatCheckbox;
  @ViewChild('rdVascularizacaoPresente') rdVascularizacaoPresente: MatRadioButton;
  @ViewChild('rdVascularizacaoAusente') rdVascularizacaoAusente: MatRadioButton;

  //Dinamicos
  @ViewChildren('cistoSimplesChildren') cistoSimplesChildren: QueryList<any>;
  @ViewChildren('cistoComplicadoChildren') cistoComplicadoChildren: QueryList<any>;
  @ViewChildren('cistoMicroChildren') cistoMicroChildren: QueryList<any>;

  localizacoesMaiuscula: ArrayDropDown[] = [
    { value: 'À 1 hora', viewValue: '1 Hora' },
    { value: 'Às 2 horas', viewValue: '2 Horas' },
    { value: 'Às 3 horas', viewValue: '3 Horas' },
    { value: 'Às 4 horas', viewValue: '4 Horas' },
    { value: 'Às 5 horas', viewValue: '5 Horas' },
    { value: 'Às 6 horas', viewValue: '6 Horas' },
    { value: 'Às 7 horas', viewValue: '7 Horas' },
    { value: 'Às 8 horas', viewValue: '8 Horas' },
    { value: 'Às 9 horas', viewValue: '9 Horas' },
    { value: 'Às 10 horas', viewValue: '10 Horas' },
    { value: 'Às 11 horas', viewValue: '11 Horas' },
    { value: 'Às 12 horas', viewValue: '12 Horas' },
    { value: 'Na região retroareolar', viewValue: 'Região retroareolar' }
  ];

  localizacoesMinuscula: ArrayDropDown[] = [
    { value: 'à 1 hora', viewValue: '1 Hora' },
    { value: 'às 2 horas', viewValue: '2 Horas' },
    { value: 'às 3 horas', viewValue: '3 Horas' },
    { value: 'às 4 horas', viewValue: '4 Horas' },
    { value: 'às 5 horas', viewValue: '5 Horas' },
    { value: 'às 6 horas', viewValue: '6 Horas' },
    { value: 'às 7 horas', viewValue: '7 Horas' },
    { value: 'às 8 horas', viewValue: '8 Horas' },
    { value: 'às 9 horas', viewValue: '9 Horas' },
    { value: 'às 10 horas', viewValue: '10 Horas' },
    { value: 'às 11 horas', viewValue: '11 Horas' },
    { value: 'às 12 horas', viewValue: '12 Horas' },
    { value: 'na região retroareolar', viewValue: 'Região retroareolar' }
  ];

  ladosMama: ArrayDropDown[] = [
    { value: 'da mama direita', viewValue: 'Direita' },
    { value: 'da mama esquerda', viewValue: 'Esquerda' }
  ];

  ladosMamaSimples: ArrayDropDown[] = [
    { value: 'da mama direita', viewValue: 'Direita' },
    { value: 'da mama esquerda', viewValue: 'Esquerda' }
  ];

  ladosMamaComplicado: ArrayDropDown[] = [
    { value: 'da mama direita', viewValue: 'Direita' },
    { value: 'da mama esquerda', viewValue: 'Esquerda' }
  ];

  ladosMamaMicroAgrupados: ArrayDropDown[] = [
    { value: 'da mama direita', viewValue: 'Direita' },
    { value: 'da mama esquerda', viewValue: 'Esquerda' }
  ];

  ladosMamaCompleto: ArrayDropDown[] = [
    { value: 'da mama direita', viewValue: 'Direita' },
    { value: 'da mama esquerda', viewValue: 'Esquerda' },
    { value: 'nas mamas', viewValue: 'Mamas' }
  ];

  conteudos: ArrayDropDown[] = [
    { value: 'espesso homogêneo', viewValue: 'Espesso homogêneo' },
    { value: 'formador de nível', viewValue: 'Formador de nível' },
    { value: 'hiperecogênico', viewValue: 'Hiperecogênico' }
  ];

  paredes: ArrayDropDown[] = [
    { value: 'finas', viewValue: 'Finas' },
    { value: 'espessas', viewValue: 'Espessas' }
  ];

  septos: ArrayDropDown[] = [
    { value: 'fina', viewValue: 'Fina' },
    { value: 'espessa', viewValue: 'Espessa' }
  ];

  nodulosMural: ArrayDropDown[] = [
    { value: 'sem', viewValue: 'Sem' },
    { value: 'com', viewValue: 'Com' }
  ];

  countLesoes = 0;
  linha = 0;
  objeto: any;
  objetofilho: any;
  lesoesForm: FormGroup;
  showAndHideTerms: any;
  showHideAureolar: any;
  desabilitado: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes, private _fb: FormBuilder) { }

  ngOnInit() {
    this.showHideAureolar = {
      showAureolarCistoComplicado: true,
      showAureolar2CistoComplicado: true,
      showAureolarCistoComplexo: true,
      showAureolarMicroAgrupados: true,

    }
    this.showAndHideTerms = {
      sahCistos: true,
      sahCistoSimplesAll: false,
      sahCistoSimples: false,
      sahCistoSimplesDinamico: false,
      sahPlusCistoSimples: false,
      sahCistoComplicadoAll: false,
      sahCistoComplicado: false,
      sahCistoComplicadoDinamico: false,
      sahPlusCistoComplicado: false,
      sahCistoComplexo: false,
      sahMicroAgrupados: false,
      sahMicroAgrupadosTodos: false,      
      sahMicroAgrupadosUmConj: false,
      sahMicroAgrupadosMultiplosConj: false,
      sahMicroAgrupadosDoisOuMaisConj: false,
      sahPlusCistoMicro: false,
      sahVascularizacao: false,
    };
    this.desabilitado = {
      desabComplicado: false,
      desabComplexo: false,
      desabAgrupado: false,

    };
    this.lesoesForm = this._fb.group({
      cistoSimples: this._fb.array([this.addCistoSimplesGrup()]),
      cistoComplicado: this._fb.array([this.addCistoComplicadoGrup()]),
      cistoMicro: this._fb.array([this.addCistoMicroGrup()]),
    });
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }

  }

  //Cisto simples dinamico
  addCistoSimplesGrup() {
    return this._fb.group({
      medindo: [],
      localizacao: [],
      maiorMama: []
    });
  }

  addCistoSimples() {
    this.cistoSimplesArray.push(this.addCistoSimplesGrup());
    this.catchIDs();
  }

  removeCistoSimples(index) {
    this.cistoSimplesArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get cistoSimplesArray() {
    return <FormArray>this.lesoesForm.get('cistoSimples');
  }

  // Cisto complicado dinamico
  addCistoComplicadoGrup() {
    return this._fb.group({
      medindo: [],
      localizacao: [],
      distPele: [],
      distPapila: [],
      maiorMama: []
    });
  }

  addCistoComplicado() {
    this.cistoComplicadoArray.push(this.addCistoComplicadoGrup());
    this.catchIDs();
  }

  removeCistoComplicado(index) {
    this.cistoComplicadoArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get cistoComplicadoArray() {
    return <FormArray>this.lesoesForm.get('cistoComplicado');
  }

  // MicroCisto dinamico
  addCistoMicroGrup() {
    return this._fb.group({
      localizacao: [],
      ladoMama: [],
      medida: [],
    });
  }

  addCistoMicro() {
    this.cistoMicroArray.push(this.addCistoMicroGrup());
    this.catchIDs();
  }

  removeCistoMicro(index) {
    this.cistoMicroArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get cistoMicroArray() {
    return <FormArray>this.lesoesForm.get('cistoMicro');
  }

  clickAusencia() {
    this.showAndHideTerms.sahCistos = false;
    this.showAndHideTerms.sahCistoSimplesAll = false;
    this.showAndHideTerms.sahCistoComplicadoAll = false;
    this.showAndHideTerms.sahCistoComplexo = false;
    this.showAndHideTerms.sahMicroAgrupados = false;


    this.showHideAureolar.showAureolarCistoComplicado = true;
    this.showHideAureolar.showAureolarCistoComplexo = true;
    this.showHideAureolar.showAureolarMicroAgrupados = true;
    this.showHideAureolar.showAureolar2CistoComplicado = true;

    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickCisto() {
    this.showAndHideTerms.sahCistos = true;
    this.catchIDs();
  }

  clickCistoSimples() {
    if (this.showAndHideTerms.sahCistoSimplesAll == false) {
      this.showAndHideTerms.sahCistoSimplesAll = true;
      this.showAndHideTerms.sahCistoSimples = true;
      this.showAndHideTerms.sahCistoSimplesDinamico = false;
    } else {
      this.showAndHideTerms.sahCistoSimplesAll = false;
      this.showAndHideTerms.sahCistoSimples = false;
      this.showAndHideTerms.sahCistoSimplesDinamico = false;
    }

    this.ajustaArrayDinamico();
    this.alteraAusentePresente();
    this.catchIDs();
  }

  clickUmCistoSimples() {
    this.showAndHideTerms.sahCistoSimples = true;
    this.showAndHideTerms.sahCistoSimplesDinamico = false;
    this.showAndHideTerms.sahPlusCistoSimples = false;

    this.ajustaArrayDinamico();
    this.alteraAusentePresente();
    this.catchIDs();
  }

  clickDoisCistosSimples() {
    this.showAndHideTerms.sahCistoSimples = false;
    this.showAndHideTerms.sahCistoSimplesDinamico = true;
    this.showAndHideTerms.sahPlusCistoSimples = true;
    this.catchIDs();
  }

  clickCistoComplicado() {
    if (this.showAndHideTerms.sahCistoComplicadoAll == false) {
      this.showAndHideTerms.sahCistoComplicadoAll = true;
      this.showAndHideTerms.sahCistoComplicado = true;
      this.showAndHideTerms.sahCistoComplicadoDinamico = false;
    } else {
      this.showAndHideTerms.sahCistoComplicadoAll = false;
      this.showAndHideTerms.sahCistoComplicado = false;
      this.showAndHideTerms.sahCistoComplicadoDinamico = false;
    }

    this.ajustaArrayDinamico();
    this.alteraAusentePresente();
    this.catchIDs();
  }

  clickUmCistoComplicado() {
    this.showAndHideTerms.sahCistoComplicado = true;
    this.showAndHideTerms.sahCistoComplicadoDinamico = false;
    this.showAndHideTerms.sahPlusCistoComplicado = false;

    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickDoisCistosComplicado() {
    this.showHideAureolar.showAureolarCistoComplicado = true;
    this.showHideAureolar.showAureolar2CistoComplicado = true;

    this.showAndHideTerms.sahCistoComplicado = false;
    this.showAndHideTerms.sahCistoComplicadoDinamico = true;
    this.showAndHideTerms.sahPlusCistoComplicado = true;
    this.catchIDs();
  }

  clickCistoComplexo() {
    this.showHideAureolar.showAureolarCistoComplexo = true;

    if (this.showAndHideTerms.sahCistoComplexo == false) {
      this.showAndHideTerms.sahCistoComplexo = true;
    } else {
      this.showAndHideTerms.sahCistoComplexo = false;
    }

    this.alteraAusentePresente();
    this.catchIDs();
  }

  clickckMicroAgrupados() {
    this.showHideAureolar.showAureolarMicroAgrupados = true;
    if (this.showAndHideTerms.sahMicroAgrupados == false) {
      this.showAndHideTerms.sahMicroAgrupados = true;
      this.showAndHideTerms.sahMicroAgrupadosUmConj = true;
      this.showAndHideTerms.sahMicroAgrupadosTodos = true;
    } else {
      this.showAndHideTerms.sahMicroAgrupados = false;
    }

    this.alteraAusentePresente();
    this.catchIDs();
  }

  clickUmConjunto() {
    this.showAndHideTerms.sahMicroAgrupadosUmConj = true;
    this.showAndHideTerms.sahMicroAgrupadosMultiplosConj = false;
    this.showAndHideTerms.sahMicroAgrupadosDoisOuMaisConj = false;
    this.showAndHideTerms.sahMicroAgrupadosTodos = true;
    this.showAndHideTerms.sahPlusCistoMicro = false;
    this.catchIDs();
  }

  clickMultiplosConjuntos() {
    this.showAndHideTerms.sahMicroAgrupadosUmConj = false;
    this.showAndHideTerms.sahMicroAgrupadosMultiplosConj = true;
    this.showAndHideTerms.sahMicroAgrupadosDoisOuMaisConj = false;
    this.showAndHideTerms.sahMicroAgrupadosTodos = true;
    this.showAndHideTerms.sahPlusCistoMicro = false;
    this.catchIDs();
  }

  clickDoisMaisConjuntos() {
    this.showAndHideTerms.sahMicroAgrupadosUmConj = false;
    this.showAndHideTerms.sahMicroAgrupadosMultiplosConj = false;
    this.showAndHideTerms.sahMicroAgrupadosDoisOuMaisConj = true;
    this.showAndHideTerms.sahMicroAgrupadosTodos = false;
    this.showAndHideTerms.sahPlusCistoMicro = true;
    this.catchIDs();
  }

  clickVascularizacaoCK() {
    if (this.showAndHideTerms.sahVascularizacao == false) {
      this.showAndHideTerms.sahVascularizacao = true;
    } else {
      this.showAndHideTerms.sahVascularizacao = false;
    }
    this.catchIDs();
  }

  clickVascularizacao() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  changeSelectLocalizacaoComplicado() {
    if (this.slLocalizacaoCistoComplicado != undefined && this.slLocalizacaoCistoComplicado.value != undefined) {
      if (this.slLocalizacaoCistoComplicado.value === 'na região retroareolar') {
        //this.inDistPapilaCistoComplicado.nativeElement.value = ''
        //this.showHideAureolar.showAureolarCistoComplicado = false;
        this.desabilitado.desabComplicado = true;
      }
      else
        //his.showHideAureolar.showAureolarCistoComplicado = true;
        this.desabilitado.desabComplicado = false;
    }
    this.catchIDs();
  }

  changeSelectLocalizacaoMicroAgrupados() {
    if (this.slLocalizacaoMicroAgrupados != undefined && this.slLocalizacaoMicroAgrupados.value != undefined) {
      if (this.slLocalizacaoMicroAgrupados.value === 'na região retroareolar') {
        //this.inDistPapilaMicroAgrupados.nativeElement.value = '';
        //this.showHideAureolar.showAureolarMicroAgrupados = false;
        this.desabilitado.desabAgrupado = true;
      }
      else
        //this.showHideAureolar.showAureolarMicroAgrupados = true;
        this.desabilitado.desabAgrupado = false;
    }
    this.catchIDs();
  }

  changeSelectLocalizacaoComplexo() {
    if (this.slLocalizacaoCistoComplexo != undefined && this.slLocalizacaoCistoComplexo.value != undefined) {
      if (this.slLocalizacaoCistoComplexo.value === 'na região retroareolar') {
        //this.inDistPapilaCistoComplexo.nativeElement.value = '';
        //this.showHideAureolar.showAureolarCistoComplexo = false
        this.desabilitado.desabComplexo = true;
      }
      else
        //this.showHideAureolar.showAureolarCistoComplexo = true
        this.desabilitado.desabComplexo = false;
    }
    this.catchIDs();
  }




  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.formataListas();
    this.countLesoes = 0;

    if (this.rdAusencia.checked) {
      this.termoSelecionado.emit(this.rdAusencia.id);
    }

    if (this.rdCisto.checked) {

      if (this.ckCistoSimples != undefined && this.ckCistoSimples.checked) {
        this.emitCistoSimples();
      }

      if (this.ckCistoComplicado != undefined && this.ckCistoComplicado.checked) {
        if (this.countLesoes > 0) {
          this.emitCistoComplicadoLesao();
        } else {
          this.emitCistoComplicado();
        }
      }

      if (this.ckCistoComplexo != undefined && this.ckCistoComplexo.checked) {
        if (this.countLesoes > 0) {
          this.emitCistoComplexoLesao();
        } else {
          this.emitCistoComplexo();
        }
      }

      if (this.ckMicroAgrupados != undefined && this.ckMicroAgrupados.checked) {
        if (this.countLesoes > 0) {
          this.emitMicroAgrupadosLesao();
        } else {
          this.emitMicroAgrupados();
        }
      }
    }

    // this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  emitCistoSimples() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckCistoSimples.id);

    if (this.rdUmCistoSimples != undefined && this.rdUmCistoSimples.checked) {
      this.termoSelecionado.emit(this.rdUmCistoSimples.id);

      if (this.inMedidaCistoSimples != undefined &&
        this.inMedidaCistoSimples.nativeElement.id != undefined &&
        this.inMedidaCistoSimples.nativeElement.value != '') {

        const objeto = { elemento: this.inMedidaCistoSimples.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacaoCistoSimples != undefined && this.slLocalizacaoCistoSimples.value != undefined) {
        const objeto = { elemento: this.slLocalizacaoCistoSimples, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoMamaCistoSimples != undefined && this.slLadoMamaCistoSimples.value != undefined) {
        const objeto = { elemento: this.slLadoMamaCistoSimples, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

    } else if (this.rdDoisCistosSimples != undefined && this.rdDoisCistosSimples.checked) {
      this.termoSelecionado.emit(this.rdDoisCistosSimples.id);

      if (this.slLadoMamaCistoSimplesDinamico != undefined && this.slLadoMamaCistoSimplesDinamico.value != undefined) {
        const objeto = { elemento: this.slLadoMamaCistoSimplesDinamico, isPrimeiraLesao: true };

        if (this.slLadoMamaCistoSimplesDinamico.value === 'da mama direita') {
          this.ladosMamaSimples = [
            { value: 'da mama direita', viewValue: 'Direita' },
          ];
        } else if (this.slLadoMamaCistoSimplesDinamico.value === 'da mama esquerda') {
          this.ladosMamaSimples = [
            { value: 'da mama esquerda', viewValue: 'Esquerda' },
          ];
        }

        this.termosGenericos.emit(objeto);
      }

      let posicao = 0;
      this.linha = 0;

      this.cistoSimplesChildren.forEach(obj => {

        if (obj.nativeElement != undefined) {

          if (obj.nativeElement.id == 513) {
            posicao = 1;
            this.linha++;
          }

          this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
        } else {

          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
        }
      });

    }
  }

  emitCistoComplicado() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckCistoComplicado.id);

    if (this.rdUmCistoComplicado != undefined && this.rdUmCistoComplicado.checked) {
      this.termoSelecionado.emit(this.rdUmCistoComplicado.id);

      if (this.inMedidaCistoComplicado != undefined &&
        this.inMedidaCistoComplicado.nativeElement.id != undefined &&
        this.inMedidaCistoComplicado.nativeElement.value != '') {

        const objeto = { elemento: this.inMedidaCistoComplicado.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacaoCistoComplicado != undefined && this.slLocalizacaoCistoComplicado.value != undefined) {
        const objeto = { elemento: this.slLocalizacaoCistoComplicado, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);

        if (this.slLocalizacaoCistoComplicado.value === 'na região retroareolar') {
          const elemento = { id: 4239 }
          const retroareolar = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(retroareolar);
        }
      }

      if (this.slLadoMamaCistoComplicado != undefined && this.slLadoMamaCistoComplicado.value != undefined) {
        const objeto = { elemento: this.slLadoMamaCistoComplicado, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoCistoComplicado != undefined && this.slConteudoCistoComplicado.value != undefined) {
        const objeto = { elemento: this.slConteudoCistoComplicado, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPeleCistoComplicado != undefined &&
        this.inDistPeleCistoComplicado.nativeElement.id != undefined &&
        this.inDistPeleCistoComplicado.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleCistoComplicado.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPapilaCistoComplicado != undefined &&
        this.inDistPapilaCistoComplicado.nativeElement.id != undefined &&
        this.inDistPapilaCistoComplicado.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPapilaCistoComplicado.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

    } else if (this.rdDoisCistosComplicado != undefined && this.rdDoisCistosComplicado.checked) {
      this.termoSelecionado.emit(this.rdDoisCistosComplicado.id);

      if (this.slLadoMamaCistoComplicadoDinamico != undefined && this.slLadoMamaCistoComplicadoDinamico.value != undefined) {
        const objeto = { elemento: this.slLadoMamaCistoComplicadoDinamico, isPrimeiraLesao: true };

        if (this.slLadoMamaCistoComplicadoDinamico.value === 'da mama direita') {
          this.ladosMamaComplicado = [
            { value: 'da mama direita', viewValue: 'Direita' },
          ];
        } else if (this.slLadoMamaCistoComplicadoDinamico.value === 'da mama esquerda') {
          this.ladosMamaComplicado = [
            { value: 'da mama esquerda', viewValue: 'Esquerda' },
          ];
        }

        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoCistoComplicadoDinamico != undefined && this.slConteudoCistoComplicadoDinamico.value != undefined) {
        const objeto = { elemento: this.slConteudoCistoComplicadoDinamico, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      let posicao = 0;
      this.linha = 0;
      let isRetroAre = false;

      this.cistoComplicadoChildren.forEach(obj => {

        if (obj.nativeElement != undefined) {

          if (obj.nativeElement.id == 516) {
            posicao = 1;
            this.linha++;
            isRetroAre = false;
          }

          // Desabilitando o input de distancia da papila
          if(obj.nativeElement.id == 521 && isRetroAre) {
            obj.nativeElement.value = '';
            obj.nativeElement.disabled = true;
          } else {
            obj.nativeElement.disabled = false;
          }

          if (obj.nativeElement.value != '') {
            this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.objeto);
          }
        } else {

          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);

          if (obj.id == 517) {
            if (obj.value === 'Na região retroareolar') {
              isRetroAre = true;
              //this.showHideAureolar.showAureolar2CistoComplicado = false;
              const elem = { id: '4240', value: '4240', cenario: this.countLesoes }
              const retroareolar = { elemento: elem, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
              this.lesoesDinamicas.emit(retroareolar);
            } else {
              isRetroAre = false;
              //this.showHideAureolar.showAureolar2CistoComplicado = true;
            }
          }
        }
      });
    }
  }

  emitCistoComplicadoLesao() {
    this.countLesoes++;
    const objeto = { id: this.ckCistoComplicado.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);

    if (this.rdUmCistoComplicado != undefined && this.rdUmCistoComplicado.checked) {
      const objeto = { id: this.rdUmCistoComplicado.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inMedidaCistoComplicado != undefined &&
        this.inMedidaCistoComplicado.nativeElement.id != undefined &&
        this.inMedidaCistoComplicado.nativeElement.value != '') {

        const objeto = { elemento: this.inMedidaCistoComplicado.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedidaCistoComplicado != undefined &&
        this.inMedidaCistoComplicado.nativeElement.id != undefined &&
        this.inMedidaCistoComplicado.nativeElement.value != '') {

        const objeto = { elemento: this.inMedidaCistoComplicado.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacaoCistoComplicado != undefined && this.slLocalizacaoCistoComplicado.value != undefined) {
        const objeto = { elemento: this.slLocalizacaoCistoComplicado, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        if (this.slLocalizacaoCistoComplicado.value === 'na região retroareolar') {
          const elemento = { id: 4239 }
          const retroareolar = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(retroareolar);
        }
      }

      if (this.slLadoMamaCistoComplicado != undefined && this.slLadoMamaCistoComplicado.value != undefined) {
        const objeto = { elemento: this.slLadoMamaCistoComplicado, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoCistoComplicado != undefined && this.slConteudoCistoComplicado.value != undefined) {
        const objeto = { elemento: this.slConteudoCistoComplicado, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPeleCistoComplicado != undefined &&
        this.inDistPeleCistoComplicado.nativeElement.id != undefined &&
        this.inDistPeleCistoComplicado.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleCistoComplicado.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPapilaCistoComplicado != undefined &&
        this.inDistPapilaCistoComplicado.nativeElement.id != undefined &&
        this.inDistPapilaCistoComplicado.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPapilaCistoComplicado.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

    } else if (this.rdDoisCistosComplicado != undefined && this.rdDoisCistosComplicado.checked) {
      const objeto = { id: this.rdDoisCistosComplicado.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLadoMamaCistoComplicadoDinamico != undefined && this.slLadoMamaCistoComplicadoDinamico.value != undefined) {
        const objeto = { elemento: this.slLadoMamaCistoComplicadoDinamico, isPrimeiraLesao: false };

        if (this.slLadoMamaCistoComplicadoDinamico.value === 'da mama direita') {
          this.ladosMamaComplicado = [
            { value: 'da mama direita', viewValue: 'Direita' },
          ];
        } else if (this.slLadoMamaCistoComplicadoDinamico.value === 'da mama esquerda') {
          this.ladosMamaComplicado = [
            { value: 'da mama esquerda', viewValue: 'Esquerda' },
          ];
        }

        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoCistoComplicadoDinamico != undefined && this.slConteudoCistoComplicadoDinamico.value != undefined) {
        const objeto = { elemento: this.slConteudoCistoComplicadoDinamico, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      let posicao = 0;
      this.linha = 0;
      let isRetroAre = false;

      this.cistoComplicadoChildren.forEach(obj => {

        if (obj.nativeElement != undefined) {

          if (obj.nativeElement.id == 516) {
            posicao = 1;
            this.linha++;
            isRetroAre = false;
          }

          // Desabilitando o input de distancia da papila
          if(obj.nativeElement.id == 521 && isRetroAre) {
            obj.nativeElement.value = '';
            obj.nativeElement.disabled = true;
          } else {
            obj.nativeElement.disabled = false;
          }

          if (obj.nativeElement.value != '') {
            this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.objeto);
          }
        } else {

          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.objeto);

          if (obj.id == 517) {
            if (obj.value === 'Na região retroareolar') {
              //this.showHideAureolar.showAureolar2CistoComplicado = false;
              isRetroAre = true;
              const elem = { id: '4240', value: '4240', cenario: this.countLesoes }
              const retroareolar = { elemento: elem, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
              this.lesoesDinamicas.emit(retroareolar);
            } else {
              //this.showHideAureolar.showAureolar2CistoComplicado = true;
              isRetroAre = false;
            }
          }
        }
      });
    }
  }

  emitCistoComplexo() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckCistoComplexo.id);

    if (this.slParedeCistoComplexo != undefined && this.slParedeCistoComplexo.value != undefined) {
      const objeto = { elemento: this.slParedeCistoComplexo, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slSeptoCistoComplexo != undefined && this.slSeptoCistoComplexo.value != undefined) {
      const objeto = { elemento: this.slSeptoCistoComplexo, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slnoduloMuralCistoComplexo != undefined && this.slnoduloMuralCistoComplexo.value != undefined) {
      const objeto = { elemento: this.slnoduloMuralCistoComplexo, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    this.formataMedindoComplexo();

    if (this.slLocalizacaoCistoComplexo != undefined && this.slLocalizacaoCistoComplexo.value != undefined) {
      const objeto = { elemento: this.slLocalizacaoCistoComplexo, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);

      if (this.slLocalizacaoCistoComplexo.value === 'na região retroareolar') {
        const elemento = { id: 4241 }
        const retroareolar = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(retroareolar);
      }
    }

    if (this.slLadoMamaCistoComplexo != undefined && this.slLadoMamaCistoComplexo.value != undefined) {
      const objeto = { elemento: this.slLadoMamaCistoComplexo, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.inDistPapilaCistoComplexo != undefined &&
      this.inDistPapilaCistoComplexo.nativeElement.id != undefined &&
      this.inDistPapilaCistoComplexo.nativeElement.value != '') {

      const objeto = { elemento: this.inDistPapilaCistoComplexo.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.inDistPeleCistoComplexo != undefined &&
      this.inDistPeleCistoComplexo.nativeElement.id != undefined &&
      this.inDistPeleCistoComplexo.nativeElement.value != '') {

      const objeto = { elemento: this.inDistPeleCistoComplexo.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

  }

  emitCistoComplexoLesao() {
    this.countLesoes++;
    const objeto = { id: this.ckCistoComplexo.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);

    if (this.slParedeCistoComplexo != undefined && this.slParedeCistoComplexo.value != undefined) {
      const objeto = { elemento: this.slParedeCistoComplexo, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
    }

    if (this.slSeptoCistoComplexo != undefined && this.slSeptoCistoComplexo.value != undefined) {
      const objeto = { elemento: this.slSeptoCistoComplexo, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
    }

    if (this.slnoduloMuralCistoComplexo != undefined && this.slnoduloMuralCistoComplexo.value != undefined) {
      const objeto = { elemento: this.slnoduloMuralCistoComplexo, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
    }

    this.formataMedindoComplexo();

    if (this.slLocalizacaoCistoComplexo != undefined && this.slLocalizacaoCistoComplexo.value != undefined) {
      const objeto = { elemento: this.slLocalizacaoCistoComplexo, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);

      if (this.slLocalizacaoCistoComplexo.value === 'na região retroareolar') {
        const elemento = { id: 4241 }
        const retroareolar = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(retroareolar);
      }
    }

    if (this.slLadoMamaCistoComplexo != undefined && this.slLadoMamaCistoComplexo.value != undefined) {
      const objeto = { elemento: this.slLadoMamaCistoComplexo, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
    }

    if (this.inDistPapilaCistoComplexo != undefined &&
      this.inDistPapilaCistoComplexo.nativeElement.id != undefined &&
      this.inDistPapilaCistoComplexo.nativeElement.value != '') {

      const objeto = { elemento: this.inDistPapilaCistoComplexo.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
    }

    if (this.inDistPeleCistoComplexo != undefined &&
      this.inDistPeleCistoComplexo.nativeElement.id != undefined &&
      this.inDistPeleCistoComplexo.nativeElement.value != '') {

      const objeto = { elemento: this.inDistPeleCistoComplexo.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
    }

  }

  emitMicroAgrupados() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckMicroAgrupados.id);

    if (this.rdUmConjunto != undefined && this.rdUmConjunto.checked) {
      this.termoSelecionado.emit(this.rdUmConjunto.id);

      this.formataMedindoMicroAgrupados();

      if (this.slLocalizacaoMicroAgrupados != undefined && this.slLocalizacaoMicroAgrupados.value != undefined) {
        const objeto = { elemento: this.slLocalizacaoMicroAgrupados, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);

        if (this.slLocalizacaoMicroAgrupados.value === 'na região retroareolar') {
          const elemento = { id: 4242 }
          const retroareolar = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(retroareolar);
        }
      }

      if (this.slLadoMamaMicroAgrupados != undefined && this.slLadoMamaMicroAgrupados.value != undefined) {
        const objeto = { elemento: this.slLadoMamaMicroAgrupados, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacaoMicroAgrupados != undefined && this.slLocalizacaoMicroAgrupados.value != undefined) {
        const objeto = { elemento: this.slLocalizacaoMicroAgrupados, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);

        if (this.slLocalizacaoMicroAgrupados.value === 'na região retroareolar') {
          const elemento = { id: 4242 }
          const retroareolar = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(retroareolar);
        }
      }

      if (this.slLadoMamaMicroAgrupados != undefined && this.slLadoMamaMicroAgrupados.value != undefined) {
        const objeto = { elemento: this.slLadoMamaMicroAgrupados, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPeleMicroAgrupados != undefined &&
        this.inDistPeleMicroAgrupados.nativeElement.id != undefined &&
        this.inDistPeleMicroAgrupados.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleMicroAgrupados.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPapilaMicroAgrupados != undefined &&
        this.inDistPapilaMicroAgrupados.nativeElement.id != undefined &&
        this.inDistPapilaMicroAgrupados.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPapilaMicroAgrupados.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckVascularizacao != undefined && this.ckVascularizacao.checked) {
        this.termoSelecionado.emit(this.ckVascularizacao.id);

        if (this.rdVascularizacaoPresente != undefined && this.rdVascularizacaoPresente.checked) {
          this.termoSelecionado.emit(this.rdVascularizacaoPresente.id);
        } else if (this.rdVascularizacaoAusente != undefined && this.rdVascularizacaoAusente.checked) {
          this.termoSelecionado.emit(this.rdVascularizacaoAusente.id);
        }
      }

    } else if (this.rdMultiplosConjuntos != undefined && this.rdMultiplosConjuntos.checked) {
      this.termoSelecionado.emit(this.rdMultiplosConjuntos.id);

      this.formataMedindoMicroAgrupados();

      if (this.slLocalizacaoMicroAgrupados != undefined && this.slLocalizacaoMicroAgrupados.value != undefined) {
        const objeto = { elemento: this.slLocalizacaoMicroAgrupados, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoMamaMicroAgrupados != undefined && this.slLadoMamaMicroAgrupados.value != undefined) {
        const objeto = { elemento: this.slLadoMamaMicroAgrupados, isPrimeiraLesao: true };

        if (this.slLadoMamaMicroAgrupados.value === 'da mama direita') {
          this.ladosMamaMicroAgrupados = [
            { value: 'da mama direita', viewValue: 'Direita' },
          ];
        } else if (this.slLadoMamaMicroAgrupados.value === 'da mama esquerda') {
          this.ladosMamaMicroAgrupados = [
            { value: 'da mama esquerda', viewValue: 'Esquerda' },
          ];
        }

        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPeleMicroAgrupados != undefined &&
        this.inDistPeleMicroAgrupados.nativeElement.id != undefined &&
        this.inDistPeleMicroAgrupados.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleMicroAgrupados.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPapilaMicroAgrupados != undefined &&
        this.inDistPapilaMicroAgrupados.nativeElement.id != undefined &&
        this.inDistPapilaMicroAgrupados.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPapilaMicroAgrupados.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slMaiorNaMama != undefined && this.slMaiorNaMama.value != undefined) {
        const objeto = { elemento: this.slMaiorNaMama, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    } else if (this.rdDoisOuMaisConjuntos && this.rdDoisOuMaisConjuntos.checked) {
          this.termoSelecionado.emit(this.rdDoisOuMaisConjuntos.id);

      let posicao = 0;
      this.linha = 0;

      this.cistoMicroChildren.forEach(obj => {

        if (obj.nativeElement != undefined) {

          if (obj.nativeElement.id == 346) {
            if (posicao < 3) {
              posicao++;
            } else {
              posicao = 1;
            }
          }    

          if (obj.nativeElement.value != '') {
            this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.objeto);
          }
        } else {
          if (obj.id == 347) {
            this.linha++;
          }

          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
        }
      });
    }

  }

  emitMicroAgrupadosLesao() {
    this.countLesoes++;
    const objeto = { id: this.ckMicroAgrupados.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);

    if (this.rdUmConjunto != undefined && this.rdUmConjunto.checked) {
      const objeto = { id: this.rdUmConjunto.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      this.formataMedindoMicroAgrupados();

      if (this.slLocalizacaoMicroAgrupados != undefined && this.slLocalizacaoMicroAgrupados.value != undefined) {
        const objeto = { elemento: this.slLocalizacaoMicroAgrupados, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        if (this.slLocalizacaoMicroAgrupados.value === 'na região retroareolar') {
          const elemento = { id: 4242 }
          const retroareolar = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(retroareolar);
        }
      }

      if (this.slLadoMamaMicroAgrupados != undefined && this.slLadoMamaMicroAgrupados.value != undefined) {
        const objeto = { elemento: this.slLadoMamaMicroAgrupados, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacaoMicroAgrupados != undefined && this.slLocalizacaoMicroAgrupados.value != undefined) {
        const objeto = { elemento: this.slLocalizacaoMicroAgrupados, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        if (this.slLocalizacaoMicroAgrupados.value === 'na região retroareolar') {
          const elemento = { id: 4242 }
          const retroareolar = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(retroareolar);
        }
      }

      if (this.slLadoMamaMicroAgrupados != undefined && this.slLadoMamaMicroAgrupados.value != undefined) {
        const objeto = { elemento: this.slLadoMamaMicroAgrupados, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPeleMicroAgrupados != undefined &&
        this.inDistPeleMicroAgrupados.nativeElement.id != undefined &&
        this.inDistPeleMicroAgrupados.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleMicroAgrupados.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPapilaMicroAgrupados != undefined &&
        this.inDistPapilaMicroAgrupados.nativeElement.id != undefined &&
        this.inDistPapilaMicroAgrupados.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPapilaMicroAgrupados.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckVascularizacao != undefined && this.ckVascularizacao.checked) {
        const objeto = { id: this.ckVascularizacao.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.rdVascularizacaoPresente != undefined && this.rdVascularizacaoPresente.checked) {
          const objeto = { id: this.rdVascularizacaoPresente.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else if (this.rdVascularizacaoAusente != undefined && this.rdVascularizacaoAusente.checked) {
          const objeto = { id: this.rdVascularizacaoAusente.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        }
      }

    } else if (this.rdMultiplosConjuntos != undefined && this.rdMultiplosConjuntos.checked) {
      const objeto = { id: this.rdMultiplosConjuntos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      this.formataMedindoMicroAgrupados();

      if (this.slLocalizacaoMicroAgrupados != undefined && this.slLocalizacaoMicroAgrupados.value != undefined) {
        const objeto = { elemento: this.slLocalizacaoMicroAgrupados, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoMamaMicroAgrupados != undefined && this.slLadoMamaMicroAgrupados.value != undefined) {
        const objeto = { elemento: this.slLadoMamaMicroAgrupados, isPrimeiraLesao: false };

        if (this.slLadoMamaMicroAgrupados.value === 'da mama direita') {
          this.ladosMamaMicroAgrupados = [
            { value: 'da mama direita', viewValue: 'Direita' },
          ];
        } else if (this.slLadoMamaMicroAgrupados.value === 'da mama esquerda') {
          this.ladosMamaMicroAgrupados = [
            { value: 'da mama esquerda', viewValue: 'Esquerda' },
          ];
        }

        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacaoMicroAgrupados != undefined && this.slLocalizacaoMicroAgrupados.value != undefined) {
        const objeto = { elemento: this.slLocalizacaoMicroAgrupados, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoMamaMicroAgrupados != undefined && this.slLadoMamaMicroAgrupados.value != undefined) {
        const objeto = { elemento: this.slLadoMamaMicroAgrupados, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPeleMicroAgrupados != undefined &&
        this.inDistPeleMicroAgrupados.nativeElement.id != undefined &&
        this.inDistPeleMicroAgrupados.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleMicroAgrupados.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPapilaMicroAgrupados != undefined &&
        this.inDistPapilaMicroAgrupados.nativeElement.id != undefined &&
        this.inDistPapilaMicroAgrupados.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPapilaMicroAgrupados.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slMaiorNaMama != undefined && this.slMaiorNaMama.value != undefined) {
        const objeto = { elemento: this.slMaiorNaMama, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    } else if (this.rdDoisOuMaisConjuntos && this.rdDoisOuMaisConjuntos.checked) {
      const objeto = { id: this.rdDoisOuMaisConjuntos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      let posicao = 0;
      this.linha = 0;

      this.cistoMicroChildren.forEach(obj => {

        if (obj.nativeElement != undefined) {

          if (obj.nativeElement.id == 346) {
            if (posicao < 3) {
              posicao++;
            } else {
              posicao = 1;
            }
          }            

          if (obj.nativeElement.value != '') {
            this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.objeto);
          }
        } else {

          if (obj.id == 347) {
            this.linha++;
          }

          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.objeto);
        }
      });
    }
  }

  formataMedindoComplexo() {
    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inMedidaCistoComplexo1 != undefined &&
      this.inMedidaCistoComplexo1.nativeElement.id != undefined &&
      this.inMedidaCistoComplexo1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaCistoComplexo1.nativeElement.value);
    }

    if (this.inMedidaCistoComplexo2 != undefined &&
      this.inMedidaCistoComplexo2.nativeElement.id != undefined &&
      this.inMedidaCistoComplexo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaCistoComplexo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaCistoComplexo2.nativeElement.value);
      }
    }

    if (this.inMedidaCistoComplexo3 != undefined &&
      this.inMedidaCistoComplexo3.nativeElement.id != undefined &&
      this.inMedidaCistoComplexo3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaCistoComplexo3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaCistoComplexo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      if (this.countLesoes <= 1) {
        isPrimeiraLesao = true;
      }
      const objetoElemento = { id: 338, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoMicroAgrupados() {
    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inMedidaMicroAgrupados1 != undefined &&
      this.inMedidaMicroAgrupados1.nativeElement.id != undefined &&
      this.inMedidaMicroAgrupados1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaMicroAgrupados1.nativeElement.value);
    }

    if (this.inMedidaMicroAgrupados2 != undefined &&
      this.inMedidaMicroAgrupados2.nativeElement.id != undefined &&
      this.inMedidaMicroAgrupados2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaMicroAgrupados2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaMicroAgrupados2.nativeElement.value);
      }
    }

    if (this.inMedidaMicroAgrupados3 != undefined &&
      this.inMedidaMicroAgrupados3.nativeElement.id != undefined &&
      this.inMedidaMicroAgrupados3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaMicroAgrupados3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaMicroAgrupados3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      if (this.countLesoes <= 1) {
        isPrimeiraLesao = true;
      }

      const objetoElemento = { id: 346, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  ajustaArrayDinamico() {

    if ((this.rdDoisCistosSimples !== undefined && !this.rdDoisCistosSimples.checked) || this.rdAusencia.checked
      || (!this.ckCistoSimples !== undefined && !this.ckCistoSimples.checked)) {
      // this.showAndHideTerms.sahCistoSimplesDinamico = false;

      const arrayTotal = this.cistoSimplesArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.cistoSimplesArray.length - 1

        if (index != 0) {
          this.cistoSimplesArray.removeAt(index);
        }
      }
      this.cistoSimplesArray.reset();
    }

    if ((this.rdDoisCistosComplicado !== undefined && !this.rdDoisCistosComplicado.checked) || this.rdAusencia.checked
      || (!this.ckCistoComplicado !== undefined && !this.ckCistoComplicado.checked)) {
      // this.showAndHideTerms.sahCistoComplicadoDinamico = false;

      const arrayTotal = this.cistoComplicadoArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.cistoComplicadoArray.length - 1

        if (index != 0) {
          this.cistoComplicadoArray.removeAt(index);
        }
      }
      this.cistoComplicadoArray.reset();
    }

    if ((this.rdDoisOuMaisConjuntos !== undefined && !this.rdDoisOuMaisConjuntos.checked) || this.rdAusencia.checked
      || (!this.ckMicroAgrupados !== undefined && !this.ckMicroAgrupados.checked)) {

      const arrayTotal = this.cistoMicroArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.cistoMicroArray.length - 1

        if (index != 0) {
          this.cistoMicroArray.removeAt(index);
        }
      }
      this.cistoMicroArray.reset();
    }

  }

  formataListas() {

    this.ladosMama = [
      { value: 'da mama direita', viewValue: 'Direita' },
      { value: 'da mama esquerda', viewValue: 'Esquerda' }
    ];

    this.ladosMamaSimples = [
      { value: 'da mama direita', viewValue: 'Direita' },
      { value: 'da mama esquerda', viewValue: 'Esquerda' }
    ];

    this.ladosMamaComplicado = [
      { value: 'da mama direita', viewValue: 'Direita' },
      { value: 'da mama esquerda', viewValue: 'Esquerda' }
    ];

    this.ladosMamaMicroAgrupados = [
      { value: 'da mama direita', viewValue: 'Direita' },
      { value: 'da mama esquerda', viewValue: 'Esquerda' }
    ];
  }

  alteraAusentePresente() {
    if ((this.ckCistoSimples && this.ckCistoSimples.checked) ||
      (this.ckCistoComplicado && this.ckCistoComplicado.checked) ||
      (this.ckCistoComplexo && this.ckCistoComplexo.checked) ||
      (this.ckMicroAgrupados && this.ckMicroAgrupados.checked)
    ) {
      this.rdCisto.checked = true;
    } else {
      this.rdAusencia.checked = true;
      this.rdCisto.checked = false;
    }
  }


}
