import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatCheckbox } from '@angular/material';


@Component({
  selector: 'app-arteria-aorta',
  templateUrl: './arteria-aorta.component.html',
  styleUrls: ['./arteria-aorta.component.css']
})
export class ArteriaAortaComponent implements OnInit {

  public showAndHideTerms: any;


  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdnaoCaracterizada') rdnaoCaracterizada: MatRadioButton;
  @ViewChild('rdAteromatose') rdAteromatose: MatRadioButton;
  @ViewChild('rdEctasia') rdEctasia: MatRadioButton;
  @ViewChild('inCalibr') inCalibr: ElementRef;
  @ViewChild('inCalibre') inCalibre: ElementRef;
  @ViewChild('inCalibres') inCalibres: ElementRef;
  @ViewChild('ckParcialmenteCarac') ckParcialmenteCarac: MatCheckbox;
  @ViewChild('ckParcialmenteCaract') ckParcialmenteCaract: MatCheckbox;
  @ViewChild('ckAteromatose') ckAteromatose: MatCheckbox;

  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      SahNormal: true,
      SahNaoCaracterizada: false,
      SahAteromatose: false,
      SahEctasia: false,
      SahckParcialmenteCarac: false,
      SahckAteromatose: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public ClickNormal() {
    this.showAndHideTerms.SahNormal = true;
    this.showAndHideTerms.SahNaoCaracterizada = false;
    this.showAndHideTerms.SahAteromatose = false;
    this.showAndHideTerms.SahEctasia = false;
    this.showAndHideTerms.SahckParcialmenteCarac = false;
    this.showAndHideTerms.SahckAteromatose = false;
    this.catchIDs();
  }

  public ClickNaoCaracterizada() {
    this.showAndHideTerms.SahNormal = false;
    this.showAndHideTerms.SahNaoCaracterizada = true;
    this.showAndHideTerms.SahAteromatose = false;
    this.showAndHideTerms.SahEctasia = false;
    this.showAndHideTerms.SahckParcialmenteCarac = false;
    this.showAndHideTerms.SahckAteromatose = false;
    this.catchIDs();
  }

  public ClickAteromatose() {
    this.showAndHideTerms.SahNormal = false;
    this.showAndHideTerms.SahNaoCaracterizada = false;
    this.showAndHideTerms.SahAteromatose = true;
    this.showAndHideTerms.SahEctasia = false;
    this.showAndHideTerms.SahckParcialmenteCarac = true;
    this.showAndHideTerms.SahckAteromatose = false;
    this.catchIDs();
  }

  public ClickEctasia() {
    this.showAndHideTerms.SahNormal = false;
    this.showAndHideTerms.SahNaoCaracterizada = false;
    this.showAndHideTerms.SahAteromatose = false;
    this.showAndHideTerms.SahEctasia = true;
    this.showAndHideTerms.SahckParcialmenteCarac = false;
    this.showAndHideTerms.SahckAteromatose = true;
    this.catchIDs();
  }

  public clickckAteromatose() {
    if (this.showAndHideTerms.SahckAteromatose === false) {
      this.showAndHideTerms.SahckAteromatose = true;
    } else {
      this.showAndHideTerms.SahckAteromatose = false;
    }
    this.catchIDs();
  }

  public clickckParcialmenteCarac() {
    if (this.showAndHideTerms.SahckParcialmenteCarac === false) {
      this.showAndHideTerms.SahckParcialmenteCarac = true;
    } else {
      this.showAndHideTerms.SahckParcialmenteCarac = false;
    }
    this.catchIDs();
  }


  public blurMedida(): any {
    this.catchIDs();
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.ckParcialmenteCarac && this.ckParcialmenteCarac.checked) {
        this.termoSelecionado.emit(this.ckParcialmenteCarac.id);

      }

      if (this.inCalibr && this.inCalibr.nativeElement.value) {
        const objeto = { elemento: this.inCalibr.nativeElement };
        this.termosGenericos.emit(objeto);
      }

    }

    if (this.rdnaoCaracterizada.checked) {
      this.termoSelecionado.emit(this.rdnaoCaracterizada.id);
    }

    if (this.rdAteromatose && this.rdAteromatose.checked) {
      this.termoSelecionado.emit(this.rdAteromatose.id);


      if (this.ckParcialmenteCaract && this.ckParcialmenteCaract.checked) {
        this.termoSelecionado.emit(this.ckParcialmenteCaract.id);

      }

      if (this.inCalibre && this.inCalibre.nativeElement.value) {
        const objeto = { elemento: this.inCalibre.nativeElement };
        this.termosGenericos.emit(objeto);
      }

    }

    if (this.rdEctasia && this.rdEctasia.checked) {
      this.termoSelecionado.emit(this.rdEctasia.id);

      if (this.ckAteromatose && this.ckAteromatose.checked) {
        this.termoSelecionado.emit(this.ckAteromatose.id);
      }

      if (this.inCalibres && this.inCalibres.nativeElement.value) {
        const objeto = { elemento: this.inCalibres.nativeElement };
        this.termosGenericos.emit(objeto);
      }

    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();

  }
}
