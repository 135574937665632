import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";

@Injectable({
    providedIn: 'root'
  })
export class Validacoes {

    validaCPF(num) {
        if (num) {
            num = num.toString();
            num = num.replace(/\D/g, "");

            switch (num.length) {
                case 4:
                    num = num.replace(/(\d+)(\d{3})/, " $1.$2");
                    break;
                case 5:
                    num = num.replace(/(\d+)(\d{3})/, " $1.$2");
                    break;
                case 6:
                    num = num.replace(/(\d+)(\d{3})/, " $1.$2");
                    break;
                case 7:
                    num = num.replace(/(\d+)(\d{3})(\d{3})/, " $1.$2.$3");
                    break;
                case 8:
                    num = num.replace(/(\d+)(\d{3})(\d{3})/, " $1.$2.$3");
                    break;
                case 9:
                    num = num.replace(/(\d+)(\d{3})(\d{3})/, " $1.$2.$3");
                    break;
                case 10:
                    num = num.replace(/(\d+)(\d{3})(\d{3})(\d{1})/, " $1.$2.$3-$4");
                    break;
                case 11:
                    num = num.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, " $1.$2.$3-$4");
                    break;
                case 12:
                    num = num.replace(/(\d+)(\d{3})(\d{3})(\d{4})/, " $1.$2.$3/$4");
                    break;
                case 13:
                    num = num.replace(/(\d+)(\d{3})(\d{3})(\d{4})(\d{2})/, " $1.$2.$3/$4-$5");
                    break;
                case 14:
                    num = num.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, " $1.$2.$3/$4-$5");
                    break;
            }
        }
        return num;
    }

    validarCPF(CPF) {
        var soma = 0;
        var resto;
        var inputCPF = CPF.replace(/[^0-9]/g,'');

        if(inputCPF == '00000000000') return false;
        if(inputCPF == '11111111111') return false;
        if(inputCPF == '22222222222') return false;
        if(inputCPF == '33333333333') return false;
        if(inputCPF == '44444444444') return false;
        if(inputCPF == '55555555555') return false;
        if(inputCPF == '66666666666') return false;
        if(inputCPF == '77777777777') return false;
        if(inputCPF == '88888888888') return false;
        if(inputCPF == '99999999999') return false;

        for(let i=1; i<=9; i++) soma = soma + parseInt(inputCPF.substring(i-1, i)) * (11 - i);
        resto = (soma * 10) % 11;

        if((resto == 10) || (resto == 11)) resto = 0;
        if(resto != parseInt(inputCPF.substring(9, 10))) return false;

        soma = 0;
        for(let i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i-1, i))*(12-i);
        resto = (soma * 10) % 11;

        if((resto == 10) || (resto == 11)) resto = 0;
        if(resto != parseInt(inputCPF.substring(10, 11))) return false;
        return true;
    }

    apenasNumeros(val: any) {
        let _val = val.toString();
        let res = _val.replace(/[^0-9]/g,'');
        return parseInt(res);
    }

    formataDecimal(numero) {
      let retorno = numero;

      if (numero) {
        numero = numero.toString().replace(/\.0$/,'');
        numero = numero.toString().replace(/\.00$/,'');
        // if (numero) {
        if (Number.isInteger(+numero)) {
          retorno = numero + ',0';
        } else if (!Number.isInteger(+numero)) {
          const numStr = numero.toString();
          retorno = numStr.replace('.', ',');
        }
        // }
      }
      return retorno;
    }

    static verificaSeExisteSobrenome(control: AbstractControl) {
      var nameControl = control.value;
      var name = nameControl.split(' ');

      if(
        name.length == 1 ||
        name[1] === undefined ||
        name[1] === ''
      ) {
        return { nameInvalid: true }
      }

      return null;
    }
}
