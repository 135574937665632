import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-rins-dialog',
  templateUrl: './rins-dialog.component.html',
  styleUrls: ['./rins-dialog.component.css']
})
export class RinsDialogComponent {
  displayedColumns =
    ['idade', 'comprimento', 'sd'];
  dataSource = ELEMENT_DATA;

  constructor(
    public dialogRef: MatDialogRef<RinsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }
}

export interface RinsRefElement {
  idade: string;
  comprimento: number;
  sd: number;
}

const ELEMENT_DATA: RinsRefElement[] = [
  {idade: '0 - 1 Semana', comprimento: 4.48, sd: 0.31},
  {idade: '1 Semana - 4 Meses', comprimento: 5.28, sd: 0.66},
  {idade: '4 - 8 Meses', comprimento: 6.15, sd: 0.67},
  {idade: '8 Meses - 1 Ano', comprimento: 6.23, sd: 0.63},
  {idade: '1 - 2 Anos', comprimento: 6.65, sd: 0.54},
  {idade: '2 - 3 Anos', comprimento: 7.36, sd: 0.54},
  {idade: '3 - 4 Anos', comprimento: 7.36, sd: 0.64},
  {idade: '4 - 5 Anos', comprimento: 7.87, sd: 0.5},
  {idade: '5 - 6 Anos', comprimento: 8.09, sd: 0.54},
  {idade: '6 - 7 Anos', comprimento: 7.83, sd: 0.72},
  {idade: '7 - 8 Anos', comprimento: 8.33, sd: 0.51},
  {idade: '8 - 9 Anos', comprimento: 8.9, sd: 0.88},
  {idade: '9 - 10 Anos', comprimento: 9.2, sd: 0.9},
  {idade: '10 - 11 Anos', comprimento: 9.17, sd: 0.82},
  {idade: '11 - 12 Anos', comprimento: 9.6, sd: 0.64},
  {idade: '12 - 13 Anos', comprimento: 10.42, sd: 0.87},
  {idade: '13 - 14 Anos', comprimento: 9.79, sd: 0.75},
  {idade: '14 - 15 Anos', comprimento: 10.05, sd: 0.62},
  {idade: '15 - 16 Anos', comprimento: 10.93, sd: 0.76},
  {idade: '16 - 17 Anos', comprimento: 10.04, sd: 0.86},
  {idade: '17 - 18 Anos', comprimento: 10.53, sd: 0.29},
  {idade: '18 - 19 Anos', comprimento: 10.81, sd: 1.13}
];
