import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-estruturas-osseas',
  templateUrl: './estruturas-osseas.component.html',
  styleUrls: ['./estruturas-osseas.component.css']
})
export class EstruturasOsseasComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckOsteofitos') ckOsteofitos: MatCheckbox;
  @ViewChild('ckAltMorfologia') ckAltMorfologia: MatCheckbox;
  @ViewChild('slLatOsteofito') slLatOsteofito: MatSelect;
  @ViewChild('slLatAlteracao') slLatAlteracao: MatSelect;

  Lateralidade: ArrayDropDown[] = [
    { value: 'direito', viewValue: 'Direito' },
    { value: 'esquerdo', viewValue: 'Esquerdo'},
    { value: 'bilateral', viewValue: 'Bilateral'}
  ];

  showAndHideTerms: any;
  countLesoes = 0;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado : true,
      sahOsteofitos : false,
      sahAltMorfologia : false
    };
  }

  clickNormal(){
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahOsteofitos = false;
    this.showAndHideTerms.sahAltMorfologia = false;
    this.catchIDs();
  }

  clickAlterado(){
    this.showAndHideTerms.sahAlterado = true;
    this.catchIDs();
  }

  clickOsteofitos(){
    if (this.showAndHideTerms.sahOsteofitos === false) {
      this.showAndHideTerms.sahOsteofitos = true;
    } else {
      this.showAndHideTerms.sahOsteofitos = false;
    }
    this.alteraNormal();
    this.catchIDs();
  }

  clickAltMorfologia(){
    if (this.showAndHideTerms.sahAltMorfologia === false) {
      this.showAndHideTerms.sahAltMorfologia = true;
    } else {
      this.showAndHideTerms.sahAltMorfologia = false;
    }
    this.alteraNormal();
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked){
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked){
      this.emitOsteofitos();

      if(this.countLesoes > 0){
        this.emitAltMorfologiaLesao();
      } else {
        this.emitAltMorfologia();
      }

    }

    this.listaCompleta.emit();

  }

  alteraNormal() {
    if (this.ckOsteofitos && this.ckOsteofitos.checked || this.ckAltMorfologia && this.ckAltMorfologia.checked){
      this.rdAlterado.checked = true;
    }else {
      this.rdNormal.checked = true;
    }
  }

  emitOsteofitos(){
    if(this.ckOsteofitos.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckOsteofitos.id);
      if(this.slLatOsteofito && this.slLatOsteofito.value){
        const objeto = {elemento: this.slLatOsteofito, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAltMorfologia(){
    if(this.ckAltMorfologia.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAltMorfologia.id);
      if(this.slLatAlteracao && this.slLatAlteracao.value){
        const objeto = {elemento: this.slLatAlteracao, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAltMorfologiaLesao(){
    if(this.ckAltMorfologia.checked){
      this.countLesoes++;
      const objeto = { id: this.ckAltMorfologia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if(this.slLatAlteracao && this.slLatAlteracao.value){
        const objeto = {elemento: this.slLatAlteracao, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }


}
