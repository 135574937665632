import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-uncovertebrais',
  templateUrl: './uncovertebrais.component.html',
  styleUrls: ['./uncovertebrais.component.css']
})
export class UncovertebraisComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckArtroseUnco') ckArtroseUnco: MatCheckbox;
  @ViewChild('ckArtroseMulti') ckArtroseMulti: MatCheckbox;

  @ViewChild('slNivelArtrose') slNivelArtrose: MatSelect;

  nivelArtrose: ArrayDropDown[] = [
    { value: 'C2', viewValue: 'C2' },
    { value: 'C3', viewValue: 'C3' },
    { value: 'C4', viewValue: 'C4' },
    { value: 'C5', viewValue: 'C5' },
    { value: 'C6', viewValue: 'C6' },
    { value: 'C7', viewValue: 'C7' },
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahArtroseUnco: false,
    };
  }

  clickNormal(){
    this.showAndHideTerms.sahArtroseUnco = false;
    this.ckArtroseUnco.checked = false;
    this.ckArtroseMulti.checked = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.rdNormal.checked = false;
  }

  clickArtroseUnco(){
    this.rdAlterado.checked = true;
    this.ckArtroseMulti.checked = false;
    this.showAndHideTerms.sahArtroseUnco= !this.showAndHideTerms.sahArtroseUnco;
    this.catchIDs();
  }

  clickArtroseMulti(){
    this.rdAlterado.checked = true;
    this.ckArtroseUnco.checked = false;
    this.showAndHideTerms.sahArtroseUnco = false;
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs(){
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.ckArtroseUnco && this.ckArtroseUnco.checked) {
      this.termoSelecionado.emit(this.ckArtroseUnco.id);
      if(this.slNivelArtrose && this.slNivelArtrose.value){
        const objeto = {elemento: this.slNivelArtrose, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.ckArtroseMulti && this.ckArtroseMulti.checked) {
      debugger
      this.termoSelecionado.emit(this.ckArtroseMulti.id);
    }

    this.listaCompleta.emit();
  }

}
