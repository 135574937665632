import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-tendoes-patelar',
  templateUrl: './tendoes-patelar.component.html',
  styleUrls: ['./tendoes-patelar.component.css']
})
export class TendoesPatelarComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckPatelar') ckPatelar: MatCheckbox;
  @ViewChild('slLocalizacaoPate') slLocalizacaoPate: MatSelect;

  @ViewChild('ckQuadricipital') ckQuadricipital: MatCheckbox;
  @ViewChild('slLocalizacaoQuad') slLocalizacaoQuad: MatSelect;

  localizacoesPate: ArrayDropDown[] = [
    { value: 'no terço proximal', viewValue: 'Terço proximal' },
    { value: 'no terço distal', viewValue: 'Terço distal' },
    { value: 'difusa', viewValue: 'Difusa' }
  ];

  localizacoesQuad: ArrayDropDown[] = [
    { value: 'insercional', viewValue: 'Insercional' },
    { value: 'difusa', viewValue: 'Difusa' }
  ];

  showAndHideTerms: any;
  countLesoes = 0;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahPatelar: false,
      sahQuadricipital: false
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahPatelar = false;
    this.showAndHideTerms.sahQuadricipital = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.catchIDs();
  }

  clickPatelar() {
    this.verificaLesoes();

    if(this.ckPatelar.checked){
      this.showAndHideTerms.sahPatelar=true;
      this.ckPatelar.checked = true;
    }else{
      this.ckPatelar.checked = false;
      this.showAndHideTerms.sahPatelar=false;
    }

    this.catchIDs();
  }

  clickQuadricipital() {
    this.verificaLesoes();
    if(this.ckQuadricipital.checked){
      this.showAndHideTerms.sahQuadricipital=true;
      this.ckQuadricipital.checked = true;
    }else{
      this.ckQuadricipital.checked = false;
      this.showAndHideTerms.sahQuadricipital=false;
    }
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.ckPatelar && this.ckPatelar.checked) || (this.ckQuadricipital && this.ckQuadricipital.checked)){
      this.rdAlterado.checked = true;
    }
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdAlterado.checked) {

      this.emitPatelar();

      if(this.countLesoes > 0) {
        this.emitQuadricipitalLesao();
      } else {
        this.emitQuadricipital();
      }
    }

    this.listaCompleta.emit();
  }

  emitPatelar() {
    if(this.ckPatelar && this.ckPatelar.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckPatelar.value);

      if (this.slLocalizacaoPate && this.slLocalizacaoPate.value) {
        const objeto = { elemento: this.slLocalizacaoPate, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitQuadricipital() {
    if(this.ckQuadricipital && this.ckQuadricipital.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckQuadricipital.value);

      if (this.slLocalizacaoQuad && this.slLocalizacaoQuad.value) {
        const objeto = { elemento: this.slLocalizacaoQuad, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitQuadricipitalLesao() {
    if (this.ckQuadricipital !== undefined && this.ckQuadricipital.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckQuadricipital.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacaoQuad && this.slLocalizacaoQuad.value) {
        const objeto = {elemento: this.slLocalizacaoQuad, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }
    }
  }
}
