import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-opt-liquido-livre',
  templateUrl: './opt-liquido-livre.component.html',
  styleUrls: ['./opt-liquido-livre.component.css']
})
export class OptLiquidoLivreComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('slQuantidade') slQuantidade: MatSelect;
  @ViewChild('slConteudo') slConteudo: MatSelect;
  @ViewChild('slLocal') slLocal: MatSelect;

  showAndHideTerms: any;

  quantidades: ArrayDropDown[] = [
    { value: 'Pequena', viewValue: 'Pequena' },
    { value: 'Moderada', viewValue: 'Moderada' },
    { value: 'Acentuada', viewValue: 'Acentuada' }
  ];

  conteudos: ArrayDropDown[] = [
    { value: 'anecoico', viewValue: 'Anecoico' },
    { value: 'com debris', viewValue: 'Com debris' },
    { value: 'espesso', viewValue: 'Espesso' }
  ];
  locais: ArrayDropDown[] = [
    { value: 'no fundo de saco', viewValue: 'Fundo de saco' },
    { value: 'predominando na região anexial direita', viewValue: 'Região anexial direita' },
    { value: 'predominando na região anexial à esquerda', viewValue: 'Região anexial esquerda' }
  ];

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true
    }
  }

  clickAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.catchIDs();
  }

  
  
  
  verificaLesoes(){
    if((this.slLocal && this.slLocal.value) || 
    (this.slConteudo && this.slConteudo.value) || 
    (this.slQuantidade && this.slQuantidade.value)){
      this.rdPresente.checked = true;
    }
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente && this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente && this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);

      if (this.slQuantidade && this.slQuantidade.value) {
        const objeto = { elemento: this.slQuantidade, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudo && this.slConteudo.value) {
        const objeto = { elemento: this.slConteudo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocal && this.slLocal.value) {
        const objeto = { elemento: this.slLocal, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

    }

    this.listaCompleta.emit();
  }



}
