import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-veias-hepaticas',
  templateUrl: './veias-hepaticas.component.html',
  styleUrls: ['./veias-hepaticas.component.css']
})
export class VeiasHepaticasComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdPervia') rdPervia: MatRadioButton;
  @ViewChild('rdTrombose') rdTrombose: MatRadioButton;

  @ViewChild('slPeriodicidade') slPeriodicidade: MatSelect;
  @ViewChild('slVeia') slVeia: MatSelect;

  periodicidades: ArrGenerico[] = [
    { value: '1131', viewValue: 'Preservado' },
    { value: '1132', viewValue: 'Perda' }
  ];

  veias: ArrGenerico[] = [
    { value: 'da veia hepática direita', viewValue: 'Veia hepática direita' },
    { value: 'da veia hepática média', viewValue: 'Veia hepática média' },
    { value: 'da veia hepática esquerda', viewValue: 'Veia hepática esquerda' },
    { value: 'de todas as veias hepáticas', viewValue: 'Todas as veias hepáticas' }
  ];

  periodicidadeSelected: string;
  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPervia: true,
      sahTrombose: false
    };

    // this.periodicidadeSelected = this.periodicidades[0].value;
    this.catchIDs()
  }

  clickPervia() {
    this.showAndHideTerms.sahPervia = true;
    this.showAndHideTerms.sahTrombose = false;
    this.catchIDs();
  }

  clickTrombose() {
    this.showAndHideTerms.sahTrombose = true;
    this.showAndHideTerms.sahPervia = false;
    this.catchIDs();
  }

  changeDefault() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdPervia.checked) {
      this.termoSelecionado.emit(this.rdPervia.id);

      if (this.slPeriodicidade != undefined && this.slPeriodicidade.value != undefined) {
        this.termoSelecionado.emit(this.slPeriodicidade.value);
      } else {
        this.termoSelecionado.emit(this.periodicidadeSelected);
      }
    } else if (this.rdTrombose.checked) {
      this.termoSelecionado.emit(this.rdTrombose.id);

      if (this.slVeia != undefined && this.slVeia.value != undefined) {
        const objeto = { elemento: this.slVeia, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    } else {
      // Envia ao abrir
      this.termoSelecionado.emit(1130);
      this.termoSelecionado.emit(this.periodicidadeSelected);
    }

    this.listaCompleta.emit();
  }
}