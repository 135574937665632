import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs-compat/operator/take';
import { PagamentoService } from 'src/app/services/pagamento.service';

@Component({
  selector: 'app-pag-rel-detalhes',
  templateUrl: './pag-rel-detalhes.component.html',
  styleUrls: ['./pag-rel-detalhes.component.css']
})
export class PagRelDetalhesComponent implements OnInit {

  pgtoUser: any = {};
  bandeira: string;
  codigo: string;
  data: string;
  nome: string;

  constructor(private router: Router,
              private _pagamento: PagamentoService) { }

  ngOnInit() {
    this._pagamento.getPagamento()
      .pipe()
      .subscribe(pgto =>
        this.pgtoUser = pgto
      );

    this.bandeira = 'MASTER';
    this.codigo = '123456789';
    this.data = '20/05/2020';
    this.nome = 'Jhon Doe';
  }

  voltar(url: string) {
    this.router.navigateByUrl(url);
  }

}
