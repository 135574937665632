import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatRadioButton} from '@angular/material';

@Component({
  selector: 'app-indicacao',
  templateUrl: './indicacao.component.html',
  styleUrls: ['./indicacao.component.css']
})
export class IndicacaoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdAbaulamento') rdAbaulamento: MatRadioButton;
  @ViewChild('rdPesquisaHernia') rdPesquisaHernia: MatRadioButton;
  @ViewChild('rdPreOperatorio') rdPreOperatorio: MatRadioButton;
  @ViewChild('rdPesquisaColecao') rdPesquisaColecao: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickDefault() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAbaulamento.checked) {
      this.termoSelecionado.emit(this.rdAbaulamento.id);
    }

    if (this.rdPesquisaHernia.checked) {
      this.termoSelecionado.emit(this.rdPesquisaHernia.id);
    }

    if (this.rdPreOperatorio.checked) {
      this.termoSelecionado.emit(this.rdPreOperatorio.id);
    }

    if (this.rdPesquisaColecao.checked) {
      this.termoSelecionado.emit(this.rdPesquisaColecao.id);
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

}
