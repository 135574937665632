import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { AngularEditorConfig, CustomClass } from '@kolkov/angular-editor';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.css']
})
export class TextEditorComponent implements OnInit {
  @Input() htmlContent: string;
  @Output() onEnterLaudo: EventEmitter<any> = new EventEmitter();

  laudoFocus(): void {
    this.onEnterLaudo.emit();
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    enableToolbar: true,
    showToolbar: true,
    height: '100%',
    minHeight: '5rem',
    placeholder: 'CARREGANDO LAUDO, AGUARDE...',
    translate: 'no',
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'https://app-nreportapi-03.azurewebsites.net/api/upload'
  };
  constructor() { }

  ngOnInit() {
    this.onChange();
  }

  onChange() {
    const editor = document.querySelector('.angular-editor-textarea');

    editor.addEventListener('DOMSubtreeModified', () => {
      const chields = Array.from(editor.children);

      chields.forEach(chield => {
        if(chield.nodeName === 'IMG') {
          const attribute = chield.getAttribute('style');

          if(!attribute) {
            chield.setAttribute('style', 'max-width: 90%;');
          }
        }
      });
    });
  }
}
