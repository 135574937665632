import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-megapofise-transversa',
  templateUrl: './megapofise-transversa.component.html',
  styleUrls: ['./megapofise-transversa.component.css']
})
export class MegapofiseTransversaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdMegapofise') rdMegapofise: MatRadioButton;

  @ViewChild('slLateralidade') slLateralidade: MatSelect;

  lateralidadeMegapofise: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'À direita' },
    { value: 'à esquerda', viewValue: 'À esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' },
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMegapofise: false,
    };
  }

  clickAusente(){
    this.showAndHideTerms.sahMegapofise = false;
    this.catchIDs();
  }

  clickMegapofise(){
    this.showAndHideTerms.sahMegapofise = true;
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }
    if (this.rdMegapofise.checked) {
      this.termoSelecionado.emit(this.rdMegapofise.id);
      if(this.slLateralidade && this.slLateralidade.value){
        const objeto = {elemento: this.slLateralidade, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
