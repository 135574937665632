import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Calculadora } from '../../../../utils/calculadora';
import * as moment from 'moment';
import { MatRadioButton, MatCheckbox } from '@angular/material';

@Component({
  selector: 'app-obs-calculadora',
  templateUrl: './obs-calculadora.component.html',
  styleUrls: ['./obs-calculadora.component.css']
})
export class ObsCalculadoraComponent implements OnInit {
  @Output() calculadoraEmmit = new EventEmitter();

  @ViewChild('inDataUltimo') inDataUltimo: ElementRef;
  @ViewChild('inDataDeste') inDataDeste: ElementRef;
  @ViewChild('inDataPrimeiro') inDataPrimeiro: ElementRef;

  @ViewChild('inSemanas') inSemanas: ElementRef;
  @ViewChild('inDias') inDias: ElementRef;

  @ViewChild('ckSem') ckSem: MatCheckbox;
  @ViewChild('ckDppDum') ckDppDum: MatCheckbox;

  showAndHideTerms: any;
  diaAtual: Date;
  resultado: Array<any>;

  constructor(private _calc: Calculadora) { }

  ngOnInit() {
    this.diaAtual = new Date();
    this.diaAtual.setDate(this.diaAtual.getDate());
    this.showAndHideTerms = {
      sahSem: true
    };
  }

  blurData() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  clickSem() {
    this.showAndHideTerms.sahSem = false;
    this.catchIDs();
  }

  clickDppDum() {
    this.catchIDs();
  }

  catchIDs() {
    this.resultado = new Array();
    let frase = '';
    let conclusao = '';
    let diaDum = '';
    let diaDeste = '';
    let diaPrim = '';
    let s = 0;
    let d = 0;
    let dataDum: Date;
    let dataDeste: Date;
    let dataPrim: Date;

    if (this.ckSem && this.ckSem.checked) {
      frase = '';
      this.resultado.push(frase);
      conclusao = 'Gestação única, tópica, de concepto vivo.';
      this.resultado.push(conclusao);
    } else {
      this.showAndHideTerms.sahSem = true;
    }

    // todos os campos preenchidos
    if (this.inDataUltimo &&
      this.inDataUltimo.nativeElement.value != '' &&
      this.inDataDeste &&
      this.inDataDeste.nativeElement.value != '' &&
      this.inDataPrimeiro &&
      this.inDataPrimeiro.nativeElement.value != '' &&
      this.inSemanas &&
      this.inSemanas.nativeElement.value) {

      // Data da Ultima Menstruação
      diaDum = moment(this.inDataUltimo.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
      dataDum = moment(diaDum, 'DD/MM/YYYY').toDate();

      // Data deste Exame
      diaDeste = moment(this.inDataDeste.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
      dataDeste = moment(diaDeste, 'DD/MM/YYYY').toDate();

      // Data da Primeira Ultrasom
      diaPrim = moment(this.inDataPrimeiro.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
      dataPrim = moment(diaPrim, 'DD/MM/YYYY').toDate();

      //DPP
      s = this.inSemanas.nativeElement.value;
      d = this.inDias.nativeElement.value;

      frase = 'Data da última menstruação: ';
      frase += diaDum + '</br>IG (menstrual): ';
      frase += this._calc.calculoIGMenstrual(dataDeste, dataDum);
      frase += '</br>IG (Primeiro Ultrassom): ';
      frase += this._calc.calculaDppIg(s,d,dataDeste,dataPrim) +'.';
      this.resultado.push(frase);

      conclusao = 'Gestação única, tópica, de concepto vivo, compatível com '
      conclusao += this._calc.calculoIGMenstrual(dataDeste, dataDum) + ' pela amenorréia e ';
      conclusao += this._calc.calculaDppIg(s,d,dataDeste,dataPrim) + ' pelo primeiro ultrassom.';
      this.resultado.push(conclusao);


    }

    // Data da última menstruação não informada
    if (this.inDataUltimo &&
      this.inDataUltimo.nativeElement.value == '' &&
      this.inDataDeste &&
      this.inDataDeste.nativeElement.value != '' &&
      this.inDataPrimeiro &&
      this.inDataPrimeiro.nativeElement.value != '') {

      // Data da Ultima Menstruação não informada
      if (this.inDataUltimo.nativeElement.value == '') {
        diaDum = 'n/a'
      }

      // Data deste Exame
      diaDeste = moment(this.inDataDeste.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
      dataDeste = moment(diaDeste, 'DD/MM/YYYY').toDate();

      // Data da Primeira Ultrasom
      diaPrim = moment(this.inDataPrimeiro.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
      dataPrim = moment(diaPrim, 'DD/MM/YYYY').toDate();

      frase = 'Data da última menstruação: ';
      frase += diaDum + '</br>IG (menstrual): ';
      frase += 'n/a';
      frase += '</br>IG (Primeiro Ultrassom): ';
      frase += this._calc.calculoIGPrimUltrasom(dataDeste, dataPrim, this.inSemanas.nativeElement.value, this.inDias.nativeElement.value);
      this.resultado.push(frase);

      conclusao = 'Gestação única, tópica, de concepto vivo, compatível com '
      conclusao += this._calc.calculoIGPrimUltrasom(dataDeste, dataPrim, this.inSemanas.nativeElement.value, this.inDias.nativeElement.value) + ' pelo primeiro ultrassom.';
      this.resultado.push(conclusao);
    }

    // Data da última menstruação não informada e
    // Data do Primeiro Ulrtrasom não informado
    if (this.inDataDeste &&
      this.inDataDeste.nativeElement.value != '' &&
      this.inDataUltimo &&
      this.inDataUltimo.nativeElement.value == '' &&
      this.inDataPrimeiro &&
      this.inDataPrimeiro.nativeElement.value == '') {

      // Data da Ultima Menstruação não informada
      if (this.inDataUltimo.nativeElement.value == '') {
        diaDum = 'n/a'
      }

      frase = 'Data da última menstruação: ';
      frase += diaDum + '</br>IG (menstrual): ';
      frase += 'n/a';
      frase += '</br>IG (Primeiro Ultrassom): ';
      frase += 'n/a';
      this.resultado.push(frase);

      conclusao = 'Gestação única, tópica, de concepto vivo.';
      this.resultado.push(conclusao);
    }

    //informar só DUM e DESTE
    if (this.inDataUltimo &&
      this.inDataUltimo.nativeElement.value != '' &&
      this.inDataDeste &&
      this.inDataDeste.nativeElement.value != '') {

      // Data da Ultima Menstruação
      diaDum = moment(this.inDataUltimo.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
      dataDum = moment(diaDum, 'DD/MM/YYYY').toDate();

      // Data deste Exame
      diaDeste = moment(this.inDataDeste.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
      dataDeste = moment(diaDeste, 'DD/MM/YYYY').toDate();

      frase = 'Data da última menstruação: ';
      frase += diaDum + '</br>IG (menstrual): ';
      frase += this._calc.calculoIGMenstrual(dataDeste, dataDum) +'.';
      this.resultado.push(frase);

      conclusao = 'Gestação única, tópica, de concepto vivo, compatível com '
      conclusao += this._calc.calculoIGMenstrual(dataDeste, dataDum) + ' pela amenorréia.';
      this.resultado.push(conclusao);
    }

    // DPP pela DUM
    if (this.inDataUltimo &&
      this.inDataUltimo.nativeElement.value != '' &&
      this.inDataDeste &&
      this.inDataDeste.nativeElement.value != '' &&
      this.ckDppDum &&
      this.ckDppDum.checked) {

      // Data da Ultima Menstruação
      diaDum = moment(this.inDataUltimo.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
      dataDum = moment(diaDum, 'DD/MM/YYYY').toDate();

      // Data deste Exame
      diaDeste = moment(this.inDataDeste.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
      dataDeste = moment(diaDeste, 'DD/MM/YYYY').toDate();

      frase = 'Data da última menstruação: ';
      frase += diaDum + '</br>IG (menstrual): ';
      frase += this._calc.calculoIGMenstrual(dataDeste, dataDum);
      frase += '</br>DPP: ';
      frase += this._calc.calculoDPP(dataDum);
      this.resultado.push(frase);

      conclusao = 'Gestação única, tópica, de concepto vivo, compatível com '
      conclusao += this._calc.calculoIGMenstrual(dataDeste, dataDum) + ' pela amenorréia.';
      this.resultado.push(conclusao);
    }

    this.calculadoraEmmit.emit(this.resultado);
  }

}
