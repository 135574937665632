import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatCheckbox } from '@angular/material';

@Component({
  selector: 'app-tireoide-tirad-dialog',
  templateUrl: './tireoide-tirad-dialog.component.html',
  styleUrls: ['./tireoide-tirad-dialog.component.css']
})
export class TireoideTiradDialogComponent {
  @ViewChild('ckTabela') ckTabela: MatCheckbox;

  displayedColumns = ['titulo', 'descricao'];
  dataSource = ELEMENT_DATA;

  constructor(
    public dialogRef: MatDialogRef<TireoideTiradDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    if(this.ckTabela.checked){
      this.dialogRef.close(this.ckTabela.value);
    }else{
      this.dialogRef.close(this.ckTabela.checked);
    }
  }
}

export interface TiradRefElement {
  titulo: string;
  descricao: string;
}

const ELEMENT_DATA: TiradRefElement[] = [
  {titulo: 'TIRADS 1', descricao: ' - Não há necessidade de PAAF ou controle ultrassonográfico.'},
  {titulo: 'TIRADS 2', descricao: ' - Não há necessidade de PAAF ou controle ultrassonográfico.'},
  {titulo: 'TIRADS 3', descricao: ' - PAAF se ≥ 25 mm; 15 - 24 mm controle ultrassonográfico em 1, 2, 3 e 5 anos.'},
  {titulo: 'TIRADS 4', descricao: ' - PAAF se ≥ 15 mm; 10 - 14 mm controle ultrassonográfico em 1, 2, 3 e 5 anos.'},
  {titulo: 'TIRADS 5', descricao: ' - PAAF se ≥ 10 mm; 5 - 9 mm controle ultrassonográfico anual durante 5 anos.'},
];
