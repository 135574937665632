import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-abd-alca-intestinal',
  templateUrl: './abd-alca-intestinal.component.html',
  styleUrls: ['./abd-alca-intestinal.component.css']
})
export class AbdAlcaIntestinalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdApendice') rdApendice: MatRadioButton;
  @ViewChild('rdIntussu') rdIntussu: MatRadioButton;
  @ViewChild('rdDivert') rdDivert: MatRadioButton;

  @ViewChild('rdNaoCarac') rdNaoCarac: MatRadioButton;
  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdApendicite') rdApendicite: MatRadioButton;

  @ViewChild('ckFossa') ckFossa: MatCheckbox;
  @ViewChild('ckDensi') ckDensi: MatCheckbox;
  @ViewChild('ckLiquid') ckLiquid: MatCheckbox;

  @ViewChild('ckColecFossaDir') ckColecFossaDir: MatCheckbox;
  @ViewChild('ckColecFossaEsq') ckColecFossaEsq: MatCheckbox;

  @ViewChild('slConteudo') slConteudo: MatSelect;
  @ViewChild('slConteudoEsq') slConteudoEsq: MatSelect;
  @ViewChild('slLocaliza') slLocaliza: MatSelect;
  @ViewChild('slColon') slColon: MatSelect;

  @ViewChild('inCalibre') inCalibre: ElementRef;

  @ViewChild('inMedindoDir1') inMedindoDir1: ElementRef;
  @ViewChild('inMedindoDir2') inMedindoDir2: ElementRef;
  @ViewChild('inMedindoDir3') inMedindoDir3: ElementRef;
  @ViewChild('inVolumeDir') inVolumeDir: ElementRef;

  @ViewChild('inMedindoEsq1') inMedindoEsq1: ElementRef;
  @ViewChild('inMedindoEsq2') inMedindoEsq2: ElementRef;
  @ViewChild('inMedindoEsq3') inMedindoEsq3: ElementRef;
  @ViewChild('inVolumeEsq') inVolumeEsq: ElementRef;

  conteudos: ArrayDropDown[] = [
    { value: 'anecoico', viewValue: 'Anecoico' },
    { value: 'hipoecogênico', viewValue: 'Hipoecogênico' },
    { value: 'heterogêneo', viewValue: 'Heterogêneo' },
    { value: 'com debris', viewValue: 'Debris' }
  ];

  locais: ArrayDropDown[] = [
    { value: 'no hipocôndrio direito', viewValue: 'Hipocôndrio direito' },
    { value: 'no hipocôndrio esquerdo', viewValue: 'Hipocôndrio esquerdo' },
    { value: 'no flanco direito', viewValue: 'Flanco direito' },
    { value: 'no flanco esquerdo', viewValue: 'Flanco esquerdo' },
    { value: 'no epigastro', viewValue: 'Epigastro' },
    { value: 'no mesogastro', viewValue: 'Mesogastro' },
    { value: 'no hipogastro', viewValue: 'Hipogastro' },
    { value: 'na fossa ilíaca direita', viewValue: 'Fossa ilíaca direita' },
    { value: 'na fossa ilíaca esquerda', viewValue: 'Fossa ilíaca esquerda' }
  ];

  colons: ArrayDropDown[] = [
    { value: 'cólon direito', viewValue: 'Cólon direito' },
    { value: 'cólon transverso', viewValue: 'Cólon transverso' },
    { value: 'cólon esquerdo', viewValue: 'Cólon esquerdo' },
    { value: 'cólon sigmóide', viewValue: 'Cólon sigmóide' }
  ];

  showAndHideTerms: any;
  obj: any;
  objetofilho: any;
  countLesoes = 0;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahApendice: false,
      sahApendNaoCarac: false,
      sahApendicite: false,
      sahMedida: false,
      sahColecFossaDir: this.ckColecFossaDir.checked,
      sahColecFossaEsq: this.ckColecFossaEsq.checked,
      sahIntussu: false,
      sahDivert: false,
    };
    
    this.clickNaoCitar();
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNaoCitar() {
    this.showAndHideTerms.sahApendice = false;
    this.showAndHideTerms.sahIntussu = false;
    this.showAndHideTerms.sahDivert = false;
    this.catchIDs();
  }

  clickApendice() {
    this.showAndHideTerms.sahApendice = true;
    this.showAndHideTerms.sahIntussu = false;
    this.showAndHideTerms.sahDivert = false;
    this.catchIDs();
  }

  clickNaoCarac() {
    this.showAndHideTerms.sahApendNaoCarac = true;
    this.showAndHideTerms.sahMedida = false;
    this.showAndHideTerms.sahApendicite = false;

    this.catchIDs();
  }

  clickNormal() {
    this.showAndHideTerms.sahMedida = true;
    this.showAndHideTerms.sahApendicite = false;
    this.showAndHideTerms.sahApendNaoCarac = false;
    this.catchIDs();
  }

  clickApendi() {
    this.showAndHideTerms.sahMedida = true;
    this.showAndHideTerms.sahApendicite = true;
    this.showAndHideTerms.sahApendNaoCarac = false;
    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  clickIntussu() {
    this.showAndHideTerms.sahApendice = false;
    this.showAndHideTerms.sahIntussu = true;
    this.showAndHideTerms.sahDivert = false;
    this.catchIDs();
  }

  clickDivert() {
    this.showAndHideTerms.sahApendice = false;
    this.showAndHideTerms.sahIntussu = false;
    this.showAndHideTerms.sahDivert = true;
    this.catchIDs();
  }

  clickColecFossaDir() {
    if (this.showAndHideTerms.sahColecFossaDir === false) {
      this.showAndHideTerms.sahColecFossaDir = true;
    } else {
      this.showAndHideTerms.sahColecFossaDir = false;
    }
    this.catchIDs();
  }

  clickColecFossaEsq() {
    if (this.showAndHideTerms.sahColecFossaEsq === false) {
      this.showAndHideTerms.sahColecFossaEsq = true;
    } else {
      this.showAndHideTerms.sahColecFossaEsq = false;
    }
    this.catchIDs();
  }


  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }

    if (this.rdApendice.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdApendice.id);

      if (this.rdNaoCarac && this.rdNaoCarac.checked) {
        this.termoSelecionado.emit(this.rdNaoCarac.id);
        if (this.ckFossa && this.ckFossa.checked) {
          this.termoSelecionado.emit(this.ckFossa.id);
        }
      }

      if (this.rdNormal && this.rdNormal.checked) {
        this.termoSelecionado.emit(this.rdNormal.id);
        if (this.inCalibre &&
          this.inCalibre.nativeElement.id &&
          this.inCalibre.nativeElement.value) {
          const valor = this._validacoes.formataDecimal(this.inCalibre.nativeElement.value);
          const objetoElemento = { id: this.inCalibre.nativeElement.id, value: valor };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdApendicite && this.rdApendicite.checked) {
        this.termoSelecionado.emit(this.rdApendicite.id);
        if (this.inCalibre &&
          this.inCalibre.nativeElement.id &&
          this.inCalibre.nativeElement.value) {
          const valor = this._validacoes.formataDecimal(this.inCalibre.nativeElement.value);
          const objetoElemento = { id: this.inCalibre.nativeElement.id, value: valor };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.ckDensi && this.ckDensi.checked) {
          this.termoSelecionado.emit(this.ckDensi.id);
        }

        if (this.ckLiquid && this.ckLiquid.checked) {
          this.termoSelecionado.emit(this.ckLiquid.id);
        }
      }
    }

    if (this.rdIntussu.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdIntussu.id);
      if (this.slLocaliza && this.slLocaliza.value) {
        const objeto = { elemento: this.slLocaliza, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdDivert.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdDivert.id);
      if (this.slColon && this.slColon.value) {
        const objeto = { elemento: this.slColon, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if(this.countLesoes > 0) {
      this.emitFossaDirLesao();
    } else {
      this.emitFossaDir();
    }

    if(this.countLesoes > 0) {
      this.emitFossaEsqLesao();
    } else {
      this.emitFossaEsq();
    }


    this.listaCompleta.emit();
  }


  emitFossaDir() {
    if (this.ckColecFossaDir.checked) {
      this.termoSelecionado.emit(this.ckColecFossaDir.id);
      if (this.slConteudo && this.slConteudo.value) {
        const objeto = { elemento: this.slConteudo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoFossaDir(true);

      if (this.inVolumeDir &&
        this.inVolumeDir.nativeElement.id &&
        this.inVolumeDir.nativeElement.value) {
        const valor = this._validacoes.formataDecimal(this.inVolumeDir.nativeElement.value);
        const objetoElemento = { id: this.inVolumeDir.nativeElement.id, value: valor };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitFossaDirLesao() {
    if (this.ckColecFossaDir.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckColecFossaDir.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slConteudo && this.slConteudo.value) {
        const objeto = { elemento: this.slConteudo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoFossaDir(false);

      if (this.inVolumeDir &&
        this.inVolumeDir.nativeElement.id &&
        this.inVolumeDir.nativeElement.value) {
        const valor = this._validacoes.formataDecimal(this.inVolumeDir.nativeElement.value);
        const objetoElemento = { id: this.inVolumeDir.nativeElement.id, value: valor };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }   
  }

  emitFossaEsq() {
    if (this.ckColecFossaEsq.checked) {
      this.termoSelecionado.emit(this.ckColecFossaEsq.id);
      if (this.slConteudoEsq && this.slConteudoEsq.value) {
        const objeto = { elemento: this.slConteudoEsq, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoFossaEsq(true);

      if (this.inVolumeEsq &&
        this.inVolumeEsq.nativeElement.id &&
        this.inVolumeEsq.nativeElement.value) {
        const valor = this._validacoes.formataDecimal(this.inVolumeEsq.nativeElement.value);
        const objetoElemento = { id: this.inVolumeEsq.nativeElement.id, value: valor };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitFossaEsqLesao() {
    if (this.ckColecFossaEsq.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckColecFossaEsq.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slConteudoEsq && this.slConteudoEsq.value) {
        const objeto = { elemento: this.slConteudoEsq, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoFossaEsq(false);

      if (this.inVolumeEsq &&
        this.inVolumeEsq.nativeElement.id &&
        this.inVolumeEsq.nativeElement.value) {
        const valor = this._validacoes.formataDecimal(this.inVolumeEsq.nativeElement.value);
        const objetoElemento = { id: this.inVolumeEsq.nativeElement.id, value: valor };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }   
  }

  formataMedindoFossaDir(isPrimeiraLesao: boolean) {
    let medindoValor = '';

    if (this.inMedindoDir1 &&
      this.inMedindoDir1.nativeElement.id &&
      this.inMedindoDir1.nativeElement.value) {

      medindoValor = this._validacoes.formataDecimal(this.inMedindoDir1.nativeElement.value);
    }

    if (this.inMedindoDir2 &&
      this.inMedindoDir2.nativeElement.id &&
      this.inMedindoDir2.nativeElement.value) {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedindoDir2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedindoDir2.nativeElement.value);
      }
    }

    if (this.inMedindoDir3 &&
      this.inMedindoDir3.nativeElement.id &&
      this.inMedindoDir3.nativeElement.value) {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedindoDir3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedindoDir3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = { id: "4137", value: medindoValor };
      const objeto = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objeto);
    }
  }

  formataMedindoFossaEsq(isPrimeiraLesao: boolean) {
    let medindoValor = '';

    if (this.inMedindoEsq1 &&
      this.inMedindoEsq1.nativeElement.id &&
      this.inMedindoEsq1.nativeElement.value) {

      medindoValor = this._validacoes.formataDecimal(this.inMedindoEsq1.nativeElement.value);
    }

    if (this.inMedindoEsq2 &&
      this.inMedindoEsq2.nativeElement.id &&
      this.inMedindoEsq2.nativeElement.value) {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedindoEsq2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedindoEsq2.nativeElement.value);
      }
    }

    if (this.inMedindoEsq3 &&
      this.inMedindoEsq3.nativeElement.id &&
      this.inMedindoEsq3.nativeElement.value) {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedindoEsq3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedindoEsq3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = { id: "4142", value: medindoValor };
      const objeto = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objeto);
    }
  }

}
