import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';
@Component({
  selector: 'app-parenquima-cranio',
  templateUrl: './parenquima-cranio.component.html',
  styleUrls: ['./parenquima-cranio.component.css']
})
export class ParenquimaCranioComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckRestParenquima') ckRestParenquima: MatCheckbox;

  //Microangiopatia
  @ViewChild('ckMicroangiopatia') ckMicroangiopatia: MatCheckbox;
  @ViewChild('rdMicroangiopatiaLeve') rdMicroangiopatiaLeve: MatRadioButton;
  @ViewChild('rdMicroangiopatiaModerada') rdMicroangiopatiaModerada: MatRadioButton;
  @ViewChild('rdMicroangiopatiaAcentuada') rdMicroangiopatiaAcentuada: MatRadioButton;

  //Lacuna isquêmica
  @ViewChild('ckLacuna') ckLacuna: MatCheckbox;

  @ViewChild('rdLacunaUnilateral') rdLacunaUnilateral: MatRadioButton;
  @ViewChild('slLateralidadeUnilateral') slLateralidadeUnilateral: MatSelect;

  @ViewChild('rdLacunaBilateral') rdLacunaBilateral: MatRadioButton;

  @ViewChild('rdLacunaUnica') rdLacunaUnica: MatRadioButton;
  @ViewChild('slLocalizacaoUnica') slLocalizacaoUnica: MatSelect;
  @ViewChild('slLateralidadeUnica') slLateralidadeUnica: MatSelect;

  //Encefalomalácia

  @ViewChild('ckEncefalomalacia') ckEncefalomalacia: MatCheckbox;
  @ViewChild('slLocalizacaoEnce') slLocalizacaoEnce: MatSelect;
  @ViewChild('slLateralidadeSing') slLateralidadeSing: MatSelect;
  @ViewChild('slLateralidadePlural') slLateralidadePlural: MatSelect;

  //Não Graduar
  @ViewChild('rdEnceNaoGraduar') rdEnceNaoGraduar: MatRadioButton;
  @ViewChild('rdEnceNaoGraduarSing') rdEnceNaoGraduarSing: MatRadioButton;

  @ViewChild('rdEnceNaoGraduarPlural') rdEnceNaoGraduarPlural: MatRadioButton;

  //Graduar
  @ViewChild('rdEnceGraduar') rdEnceGraduar: MatRadioButton;
  @ViewChild('rdEnceGraduarSing') rdEnceGraduarSing: MatRadioButton;
  @ViewChild('slGrauGraduarSing') slGrauGraduarSing: MatSelect;

  @ViewChild('rdEnceGraduarPlural') rdEnceGraduarPlural: MatRadioButton;
  @ViewChild('slGrauGraduarPlural') slGrauGraduarPlural: MatSelect;

  //Efeito Atrófico
  @ViewChild('rdEnceEfeitoAtrofico') rdEnceEfeitoAtrofico: MatRadioButton;
  @ViewChild('slEfeitoVentriculo') slEfeitoVentriculo: MatSelect;

  @ViewChild('rdEnceEfeitoSing') rdEnceEfeitoSing: MatRadioButton;

  @ViewChild('rdEnceEfeitoPlural') rdEnceEfeitoPlural: MatRadioButton;

  //AVC Isquêmico
  @ViewChild('ckAvc') ckAvc: MatCheckbox;
  @ViewChild('slLocalizacaoAvc') slLocalizacaoAvc: MatSelect;
  @ViewChild('slLateralidadeAvcSing') slLateralidadeAvcSing: MatSelect;
  @ViewChild('slLateralidadeAvcPlural') slLateralidadeAvcPlural: MatSelect;
  @ViewChild('slArteriaAvc') slArteriaAvc: MatSelect;

  @ViewChild('rdAvcRecente') rdAvcRecente: MatRadioButton;
  @ViewChild('rdAvcRecenteSing') rdAvcRecenteSing: MatRadioButton;
  @ViewChild('rdAvcRecentePlural') rdAvcRecentePlural: MatRadioButton;


  @ViewChild('rdAvcTransformacao') rdAvcTransformacao: MatRadioButton;
  @ViewChild('rdAvcTransformacaoSing') rdAvcTransformacaoSing: MatRadioButton;
  @ViewChild('rdAvcTransformacaoPlural') rdAvcTransformacaoPlural: MatRadioButton;


  @ViewChild('rdAvcNecrose') rdAvcNecrose: MatRadioButton;
  @ViewChild('rdAvcNecroseSing') rdAvcNecroseSing: MatRadioButton;
  @ViewChild('rdAvcNecrosePlural') rdAvcNecrosePlural: MatRadioButton;
  @ViewChild('slLocalizacaoAvcNecrose') slLocalizacaoAvcNecrose: MatSelect;


  // Hemorragia Intraparenquimatosa
  @ViewChild('ckHemorragia') ckHemorragia: MatCheckbox;
  @ViewChild('ckSpotSign') ckSpotSign: MatCheckbox;
  @ViewChild('slLocalizacaoHemorragia') slLocalizacaoHemorragia: MatSelect;
  @ViewChild('slLateralidadeHemorragia') slLateralidadeHemorragia: MatSelect;
  @ViewChild('slContornoHemorragia') slContornoHemorragia: MatSelect;
  @ViewChild('slAtenuacaoHemorragia') slAtenuacaoHemorragia: MatSelect;

  @ViewChild('inMedidaHemorragia1') inMedidaHemorragia1: ElementRef;
  @ViewChild('inMedidaHemorragia2') inMedidaHemorragia2: ElementRef;
  @ViewChild('inMedidaHemorragia3') inMedidaHemorragia3: ElementRef;
  @ViewChild('inVolumeHemorragia') inVolumeHemorragia: ElementRef;

  //Calcificações
  @ViewChild('ckCalcificacoes') ckCalcificacoes: MatCheckbox;
  @ViewChild('rdCalcificacaoResidual') rdCalcificacaoResidual: MatRadioButton;
  @ViewChild('rdCalcificacoesPalidais') rdCalcificacoesPalidais: MatRadioButton;
  @ViewChild('slLocalizacaoCalc') slLocalizacaoCalc: MatSelect;
  @ViewChild('slLateralidadeCalc') slLateralidadeCalc: MatSelect;

  //Lesões Intra-axiais
  @ViewChild('ckLesoesIntra') ckLesoesIntra: MatCheckbox;
  @ViewChild('slLocalizacaoLesao') slLocalizacaoLesao: MatSelect;
  @ViewChild('slLateralidadeLesao') slLateralidadeLesao: MatSelect;

  @ViewChild('rdLesaoExpansiva') rdLesaoExpansiva: MatRadioButton;
  @ViewChild('slAtenuacaoLesao') slAtenuacaoLesao: MatSelect;
  @ViewChild('slImpregnacaoLesao') slImpregnacaoLesao: MatSelect;
  @ViewChild('inMedidaLesaoIntra1') inMedidaLesaoIntra1: ElementRef;
  @ViewChild('inMedidaLesaoIntra2') inMedidaLesaoIntra2: ElementRef;
  @ViewChild('inMedidaLesaoIntra3') inMedidaLesaoIntra3: ElementRef;

  @ViewChild('rdMultiplasLesoes') rdMultiplasLesoes: MatRadioButton;
  @ViewChild('inMedidaUnicaLesao') inMedidaUnicaLesao: ElementRef;

  // Lesões Extra-Axiais
  @ViewChild('ckLesoesExtra') ckLesoesExtra: MatCheckbox;
  @ViewChild('ckHiperostoseOssea') ckHiperostoseOssea: MatCheckbox;
  @ViewChild('slLocalizacaoLesaoExtra') slLocalizacaoLesaoExtra: MatSelect;
  @ViewChild('slLateralidadeLesaoExtra') slLateralidadeLesaoExtra: MatSelect;
  @ViewChild('inMedidaLesaoExtra1') inMedidaLesaoExtra1: ElementRef;
  @ViewChild('inMedidaLesaoExtra2') inMedidaLesaoExtra2: ElementRef;

  @ViewChild('rdLesaoExtraMeningioma') rdLesaoExtraMeningioma: MatRadioButton;
  @ViewChild('rdLesaoExtraMeningiomaCalc') rdLesaoExtraMeningiomaCalc: MatRadioButton;

  @ViewChild('rdLesaoExtraOsteoma') rdLesaoExtraOsteoma: MatRadioButton;
  @ViewChild('slLocalizacaoLesaoExtraOsteoma') slLocalizacaoLesaoExtraOsteoma: MatSelect;

  // Tonsilas Cerebelares
  @ViewChild('ckTonsilas') ckTonsilas: MatCheckbox;
  @ViewChild('rdEctopia') rdEctopia: MatRadioButton;
  @ViewChild('rdEctopiaChiari') rdEctopiaChiari: MatRadioButton;
  @ViewChild('inMedidaEctopia') inMedidaEctopia: ElementRef;

  // Sela Turca
  @ViewChild('ckSelaTurca') ckSelaTurca: MatCheckbox;
  @ViewChild('rdSelaHipertensao') rdSelaHipertensao: MatRadioButton;
  @ViewChild('rdSelaInesp') rdSelaInesp: MatRadioButton;
  @ViewChild('rdSelaHabitual') rdSelaHabitual: MatRadioButton;

  lateralidade: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'À direita' },
    { value: 'à esquerda', viewValue: 'À esquerda' }
  ];

  localizacao: ArrayDropDown[] = [
    { value: 'nucleocapsular', viewValue: 'Nucleocapsular' },
    { value: 'no tálamo, no centro semi-oval / coroa radiada', viewValue: 'No tálamo, no centro semi-oval / coroa radiada' },
    { value: 'no tegmento pontino', viewValue: 'No tegmento pontino' }
  ];

  localizacaoEnce: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal' },
    { value: 'parietal', viewValue: 'Parietal' },
    { value: 'temporal', viewValue: 'Temporal' },
    { value: 'occipital', viewValue: 'Occipital' },
    { value: 'frontoparietal', viewValue: 'Frontoparietal' },
    { value: 'frontotemporal', viewValue: 'Frontotemporal' },
    { value: 'parietotemporal', viewValue: 'Parietotemporal' },
    { value: 'parieto-occipital', viewValue: 'Parieto-occipital' },
    { value: 'nucleocapsular', viewValue: 'Nucleocapsular' },
    { value: 'insular', viewValue: 'Insular' },
    { value: 'cerebelar', viewValue: 'Cerebelar' }
  ];

  lado: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'À direita' },
    { value: 'à esquerda', viewValue: 'À esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  grau: ArrayDropDown[] = [
    { value: 'Pequena', viewValue: 'Pequena' },
    { value: 'Extensa', viewValue: 'Extensa' }
  ];

  grauPlural: ArrayDropDown[] = [
    { value: 'Pequenas', viewValue: 'Pequenas' },
    { value: 'Extensas', viewValue: 'Extensas' }
  ];

  ventriculo: ArrayDropDown[] = [
    { value: 'corno anterior', viewValue: 'Corno anterior' },
    { value: 'corno temporal', viewValue: 'Corno temporal' },
    { value: 'corno occipital', viewValue: 'Corno occipital' },
    { value: 'átrio', viewValue: 'Átrio' },
    { value: 'corpo', viewValue: 'Corpo' }
  ];

  localizacaoHemorragia: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal' },
    { value: 'parietal', viewValue: 'Parietal' },
    { value: 'temporal', viewValue: 'Temporal' },
    { value: 'occipital', viewValue: 'Occipital' },
    { value: 'frontoparietal', viewValue: 'Frontoparietal' },
    { value: 'frontotemporal', viewValue: 'Frontotemporal' },
    { value: 'parietotemporal', viewValue: 'Parietotemporal' },
    { value: 'parieto-occipital', viewValue: 'Parieto-occipital' },
    { value: 'insular', viewValue: 'Insular' },
    { value: 'no tálamo', viewValue: 'No tálamo' },
    { value: 'nucleocapsular', viewValue: 'Nucleocapsular' },
    { value: 'cerebelar', viewValue: 'Cerebelar' }
  ];

  contorno: ArrayDropDown[] = [
    { value: 'regulares', viewValue: 'Regulares' },
    { value: 'irregulares', viewValue: 'Irregulares' }
  ];

  atenuacao: ArrayDropDown[] = [
    { value: 'homogênea', viewValue: 'Homogênea' },
    { value: 'heterogênea', viewValue: 'Heterogênea' }
  ];

  localizacaoCalc: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal' },
    { value: 'parietal', viewValue: 'Parietal' },
    { value: 'temporal', viewValue: 'Temporal' },
    { value: 'occipital', viewValue: 'Occipital' },
    { value: 'insular', viewValue: 'Insular' },
    { value: 'nucleocapsular', viewValue: 'Nucleocapsular' },
    { value: 'cerebelar', viewValue: 'Cerebelar' }
  ];

  localizacaoLesoes: ArrayDropDown[] = [
    { value: 'no lobo frontal', viewValue: 'No lobo frontal' },
    { value: 'no lobo parietal', viewValue: 'No lobo parietal' },
    { value: 'no lobo temporal', viewValue: 'No lobo temporal' },
    { value: 'no lobo occipital', viewValue: 'No lobo occipital' },
    { value: 'no tálamo', viewValue: 'No tálamo' },
    { value: 'nucleocapsular', viewValue: 'Nucleocapsular' },
    { value: 'no hemisfério cerebelar', viewValue: 'No hemisfério cerebelar' },
    { value: 'no mesencéfalo', viewValue: 'No mesencéfalo' },
    { value: 'na ponte', viewValue: 'Na ponte' },
    { value: 'no bulbo', viewValue: 'No bulbo' }
  ];

  atenuacaoLesao: ArrayDropDown[] = [
    { value: 'homogênea', viewValue: 'Homogênea' },
    { value: 'heterogênea', viewValue: 'Heterogênea' }
  ];

  impregnacao: ArrayDropDown[] = [
    { value: 'periférica pelo contraste e centro liquefeito/necrótico', viewValue: 'Periférica pelo contraste e centro liquefeito/necrótico' },
    { value: 'heterogênea pelo contraste', viewValue: 'Heterogênea pelo contraste' },
    { value: 'homogênea pelo contraste', viewValue: 'Homogênea pelo contraste' }
  ];

  localizacaoLesoesExtra: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal' },
    { value: 'parietal', viewValue: 'Parietal' },
    { value: 'temporal', viewValue: 'Temporal' },
    { value: 'occipital', viewValue: 'Occipital' },
    { value: 'parafalcina', viewValue: 'Parafalcina' },
    { value: 'no tentório cerebelar', viewValue: 'No tentório cerebelar' },
    { value: 'suprasselar', viewValue: 'Suprasselar' },
    { value: 'parasselar', viewValue: 'Parasselar' },
    { value: 'na goteira olfatória', viewValue: 'Na goteira olfatória' },
    { value: 'na asa esfenoidal', viewValue: 'Na asa esfenoidal' }
  ];

  localizacaoLesoesExtraOsteoma: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal' },
    { value: 'parietal', viewValue: 'Parietal' },
    { value: 'temporal', viewValue: 'Temporal' },
    { value: 'occipital', viewValue: 'Occipital' },
    { value: 'suprasselar', viewValue: 'Suprasselar' },
    { value: 'na goteira olfatória', viewValue: 'Na goteira olfatória' },
    { value: 'na asa esfenoidal', viewValue: 'Na asa esfenoidal' },
  ];

  localizacaoAvc: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal' },
    { value: 'parietal', viewValue: 'Parietal' },
    { value: 'temporal', viewValue: 'Temporal' },
    { value: 'occipital', viewValue: 'Occipital' },
    { value: 'frontoparietal', viewValue: 'Frontoparietal' },
    { value: 'frontotemporal', viewValue: 'Frontotemporal' },
    { value: 'parietotemporal', viewValue: 'Parietotemporal' },
    { value: 'parieto-occipital', viewValue: 'Parieto-occipital' },
    { value: 'insular', viewValue: 'Insular' },
    { value: 'no tálamo', viewValue: 'No tálamo' },
    { value: 'nucleocapsular', viewValue: 'Nucleocapsular' },
    { value: 'na cápsula interna', viewValue: 'Na cápsula interna' },
    { value: 'na cabeça do núcleo caudado', viewValue: 'Na cabeça do núcleo caudado' },
    { value: 'no corpo do núcleo caudado', viewValue: 'No corpo do núcleo caudado' },
    { value: 'no núcleo lentiforme', viewValue: 'No núcleo lentiforme' },
    { value: 'cerebelar', viewValue: 'Cerebelar' }
  ];

  localizacaoAvcNecrose: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal' },
    { value: 'parietal', viewValue: 'Parietal' },
    { value: 'temporal', viewValue: 'Temporal' },
    { value: 'occipital', viewValue: 'Occipital' },
    { value: 'frontoparietal', viewValue: 'Frontoparietal' },
    { value: 'frontotemporal', viewValue: 'Frontotemporal' },
    { value: 'parietotemporal', viewValue: 'Parietotemporal' },
    { value: 'parieto-occipital', viewValue: 'Parieto-occipital' },
    { value: 'insular', viewValue: 'Insular' },
    { value: 'no tálamo', viewValue: 'No tálamo' },
    { value: 'nucleocapsular', viewValue: 'Nucleocapsular' },
    { value: 'na cápsula interna', viewValue: 'Na cápsula interna' },
    { value: 'na cabeça do núcleo caudado', viewValue: 'Na cabeça do núcleo caudado' },
    { value: 'no corpo do núcleo caudado', viewValue: 'No corpo do núcleo caudado' },
    { value: 'no núcleo lentiforme', viewValue: 'No núcleo lentiforme' },
  ];

  arteriaAvc: ArrayDropDown[] = [
    { value: 'da artéria cerebral média', viewValue: 'Da artéria cerebral média' },
    { value: 'da artéria cerebral anterior', viewValue: 'Da artéria cerebral anterior' },
    { value: 'da cerebral posterior', viewValue: 'Da cerebral posterior' },
    { value: 'da artéria coroidea anterior', viewValue: 'Da artéria coroidea anterior' },
    { value: 'das artérias lenticuloestriadas', viewValue: 'Das artérias lenticuloestriadas' },
    { value: 'de fronteira vascular', viewValue: 'De fronteira vascular' },
  ];

  showAndHideTerms: any;
  objetoLocalizacao: any;
  countLesoes: number;
  volumeHemorragia = 'Volume';
  medindoHemorragia = 0;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahMicroangiopatia: false,
      sahLacuna: false,
      sahLacunaUnilateral: false,
      sahLacunaUnica: false,
      sahEncefalomalacia: false,
      sahEnceNaoGraduar: false,
      sahEnceNaoGraduarSing: false,
      sahEnceNaoGraduarPlural: false,
      sahEnceGraduar: false,
      sahEnceGraduarSing: false,
      sahEnceGraduarPlural: false,
      sahEnceEfeitoAtrofico: false,
      sahEnceEfeitoAtroficoSing: false,
      sahEnceEfeitoAtroficoPlural: false,
      sahHemorragia: false,
      sahCalcificacoes: false,
      sahCalcificacaoResidual: false,
      sahLesoesIntra: false,
      sahLesaoExpansiva: false,
      sahMultiplasLesoes: false,
      sahLesoesExtra: false,
      sahLesaoExtraMeningioma: false,
      sahLesaoExtraMeningiomaCalc: false,
      sahLesaoExtraOsteoma: false,
      sahTonsilas: false,
      sahEctopiaChiari: false,
      sahSelaTurca: false,
      sahAvc: false,
      sahAvcRecente: false,
      sahAvcRecenteSing: false,
      sahAvcRecentePlural: false,
      sahAvcTransformacao: false,
      sahAvcTransformacaoSing: false,
      sahAvcTransformacaoPlural: false,
      sahAvcNecrose: false,
      sahAvcNecroseSing: false,
      sahAvcNecrosePlural: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahMicroangiopatia = false;
    this.showAndHideTerms.sahLacuna = false;
    this.showAndHideTerms.sahEncefalomalacia = false;
    this.showAndHideTerms.sahHemorragia = false;
    this.showAndHideTerms.sahCalcificacoes = false;
    this.showAndHideTerms.sahLesoesIntra = false;
    this.showAndHideTerms.sahLesoesExtra = false;
    this.showAndHideTerms.sahTonsilas = false;
    this.showAndHideTerms.sahSelaTurca = false;
    this.showAndHideTerms.sahAvc = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahMicroangiopatia = false;
    this.showAndHideTerms.sahLacuna = false;
    this.showAndHideTerms.sahEncefalomalacia = false;
    this.showAndHideTerms.sahHemorragia = false;
    this.showAndHideTerms.sahCalcificacoes = false;
    this.showAndHideTerms.sahLesoesIntra = false;
    this.showAndHideTerms.sahLesoesExtra = false;
    this.showAndHideTerms.sahTonsilas = false;
    this.showAndHideTerms.sahSelaTurca = false;
    this.showAndHideTerms.sahAvc = false;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  clickLocalizado() {
    this.catchIDs();
  }

  clickDefault() {
    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  clickMicroangiopatia() {
    if (this.showAndHideTerms.sahMicroangiopatia === false) {
      this.showAndHideTerms.sahMicroangiopatia = true;
    } else {
      this.showAndHideTerms.sahMicroangiopatia = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickLacuna() {
    if (this.showAndHideTerms.sahLacuna === false) {
      this.showAndHideTerms.sahLacuna = true;
      this.showAndHideTerms.sahLacunaUnilateral = true;
      this.showAndHideTerms.sahLacunaUnica = false;
    } else {
      this.showAndHideTerms.sahLacuna = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickLacunaUnilateral() {
    this.showAndHideTerms.sahLacunaUnilateral = true;
    this.showAndHideTerms.sahLacunaUnica = false;
    this.catchIDs();
  }

  clickLacunaBilateral() {
    this.showAndHideTerms.sahLacunaUnilateral = false;
    this.showAndHideTerms.sahLacunaUnica = false;
    this.catchIDs();
  }

  clickLacunaUnica() {
    this.showAndHideTerms.sahLacunaUnilateral = false;
    this.showAndHideTerms.sahLacunaUnica = true;
    this.catchIDs();
  }

  clickEncefalomalacia() {
    if (this.showAndHideTerms.sahEncefalomalacia === false) {
      this.showAndHideTerms.sahEncefalomalacia = true;
      this.showAndHideTerms.sahEnceNaoGraduar = false;
      this.showAndHideTerms.sahEnceNaoGraduarSing = false;
      this.showAndHideTerms.sahEnceNaoGraduarPlural = false;
      this.showAndHideTerms.sahEnceGraduar = false;
      this.showAndHideTerms.sahEnceGraduarSing = false;
      this.showAndHideTerms.sahEnceGraduarPlural = false;
      this.showAndHideTerms.sahEnceEfeitoAtrofico = false;
      this.showAndHideTerms.sahEnceEfeitoAtroficoSing = false;
      this.showAndHideTerms.sahEnceEfeitoAtroficoPlural = false;
    } else {
      this.showAndHideTerms.sahEncefalomalacia = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickEnceNaoGraduar() {
    this.showAndHideTerms.sahEnceNaoGraduar = true;
    this.showAndHideTerms.sahEnceNaoGraduarSing = true;
    this.showAndHideTerms.sahEnceNaoGraduarPlural = false;
    this.showAndHideTerms.sahEnceGraduar = false;
    this.showAndHideTerms.sahEnceGraduarSing = false;
    this.showAndHideTerms.sahEnceGraduarPlural = false;
    this.showAndHideTerms.sahEnceEfeitoAtrofico = false;
    this.showAndHideTerms.sahEnceEfeitoAtroficoSing = false;
    this.showAndHideTerms.sahEnceEfeitoAtroficoPlural = false;
  }

  clickEnceNaoGraduarPlural() {
    this.showAndHideTerms.sahEnceNaoGraduar = true;
    this.showAndHideTerms.sahEnceNaoGraduarSing = false;
    this.showAndHideTerms.sahEnceNaoGraduarPlural = true;
    this.showAndHideTerms.sahEnceGraduar = false;
    this.showAndHideTerms.sahEnceGraduarSing = false;
    this.showAndHideTerms.sahEnceGraduarPlural = false;
    this.showAndHideTerms.sahEnceEfeitoAtrofico = false;
    this.showAndHideTerms.sahEnceEfeitoAtroficoSing = false;
    this.showAndHideTerms.sahEnceEfeitoAtroficoPlural = false;
  }

  clickEnceGraduar() {
    this.showAndHideTerms.sahEnceNaoGraduar = false;
    this.showAndHideTerms.sahEnceNaoGraduarSing = false;
    this.showAndHideTerms.sahEnceNaoGraduarPlural = false;
    this.showAndHideTerms.sahEnceGraduar = true;
    this.showAndHideTerms.sahEnceGraduarSing = true;
    this.showAndHideTerms.sahEnceGraduarPlural = false;
    this.showAndHideTerms.sahEnceEfeitoAtrofico = false;
    this.showAndHideTerms.sahEnceEfeitoAtroficoSing = false;
    this.showAndHideTerms.sahEnceEfeitoAtroficoPlural = false;
  }
  clickEnceGraduarPlural() {
    this.showAndHideTerms.sahEnceNaoGraduar = false;
    this.showAndHideTerms.sahEnceNaoGraduarSing = false;
    this.showAndHideTerms.sahEnceNaoGraduarPlural = false;
    this.showAndHideTerms.sahEnceGraduar = true;
    this.showAndHideTerms.sahEnceGraduarSing = false;
    this.showAndHideTerms.sahEnceGraduarPlural = true;
    this.showAndHideTerms.sahEnceEfeitoAtrofico = false;
    this.showAndHideTerms.sahEnceEfeitoAtroficoSing = false;
    this.showAndHideTerms.sahEnceEfeitoAtroficoPlural = false;
  }

  clickEnceEfeito() {
    this.showAndHideTerms.sahEnceNaoGraduar = false;
    this.showAndHideTerms.sahEnceNaoGraduarSing = false;
    this.showAndHideTerms.sahEnceNaoGraduarPlural = false;
    this.showAndHideTerms.sahEnceGraduar = false;
    this.showAndHideTerms.sahEnceGraduarSing = false;
    this.showAndHideTerms.sahEnceGraduarPlural = false;
    this.showAndHideTerms.sahEnceEfeitoAtrofico = true;
    this.showAndHideTerms.sahEnceEfeitoAtroficoSing = true;
    this.showAndHideTerms.sahEnceEfeitoAtroficoPlural = false;
  }
  clickEnceEfeitoPlural() {
    this.showAndHideTerms.sahEnceNaoGraduar = false;
    this.showAndHideTerms.sahEnceNaoGraduarSing = false;
    this.showAndHideTerms.sahEnceNaoGraduarPlural = false;
    this.showAndHideTerms.sahEnceGraduar = false;
    this.showAndHideTerms.sahEnceGraduarSing = false;
    this.showAndHideTerms.sahEnceGraduarPlural = false;
    this.showAndHideTerms.sahEnceEfeitoAtrofico = true;
    this.showAndHideTerms.sahEnceEfeitoAtroficoSing = false;
    this.showAndHideTerms.sahEnceEfeitoAtroficoPlural = true;
  }

  clickHemorragia() {
    if (this.showAndHideTerms.sahHemorragia === false) {
      this.showAndHideTerms.sahHemorragia = true;
    } else {
      this.showAndHideTerms.sahHemorragia = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickCalcificacoes() {
    if (this.showAndHideTerms.sahCalcificacoes === false) {
      this.showAndHideTerms.sahCalcificacoes = true;
      this.showAndHideTerms.sahCalcificacaoResidual = true;
    } else {
      this.showAndHideTerms.sahCalcificacoes = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickCalcificacaoResidual() {
    this.showAndHideTerms.sahCalcificacaoResidual = true;
  }

  clickCalcificacoesPalidais() {
    this.showAndHideTerms.sahCalcificacaoResidual = false;
    this.catchIDs();
  }

  clickLesoesIntra() {
    if (this.showAndHideTerms.sahLesoesIntra === false) {
      this.showAndHideTerms.sahLesoesIntra = true;
      this.showAndHideTerms.sahLesaoExpansiva = true;
      this.showAndHideTerms.sahMultiplasLesoes = false;
    } else {
      this.showAndHideTerms.sahLesoesIntra = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickLesaoExpansiva() {
    this.showAndHideTerms.sahLesaoExpansiva = true;
    this.showAndHideTerms.sahMultiplasLesoes = false;
  }

  clickMultiplasLesoes() {
    this.showAndHideTerms.sahLesaoExpansiva = false;
    this.showAndHideTerms.sahMultiplasLesoes = true;
  }

  clickLesoesExtra() {
    if (this.showAndHideTerms.sahLesoesExtra === false) {
      this.showAndHideTerms.sahLesoesExtra = true;
      this.showAndHideTerms.sahLesaoExtraMeningioma = true;
      this.showAndHideTerms.sahLesaoExtraMeningiomaCalc = false;
      this.showAndHideTerms.sahLesaoExtraOsteoma = false;
    } else {
      this.showAndHideTerms.sahLesoesExtra = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickLesaoExtraMeningioma() {
    this.showAndHideTerms.sahLesaoExtraMeningioma = true;
    this.showAndHideTerms.sahLesaoExtraMeningiomaCalc = false;
    this.showAndHideTerms.sahLesaoExtraOsteoma = false;
    this.catchIDs();
  }

  clickLesaoExtraMeningiomaCalc() {
    this.showAndHideTerms.sahLesaoExtraMeningioma = false;
    this.showAndHideTerms.sahLesaoExtraMeningiomaCalc = true;
    this.showAndHideTerms.sahLesaoExtraOsteoma = false;
    this.catchIDs();
  }

  clickLesaoExtraOsteoma() {
    this.showAndHideTerms.sahLesaoExtraMeningioma = false;
    this.showAndHideTerms.sahLesaoExtraMeningiomaCalc = false;
    this.showAndHideTerms.sahLesaoExtraOsteoma = true;
  }

  clickTonsilas() {
    if (this.showAndHideTerms.sahTonsilas === false) {
      this.showAndHideTerms.sahTonsilas = true;
      this.showAndHideTerms.sahEctopiaChiari = false;
    } else {
      this.showAndHideTerms.sahTonsilas = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickEctopia() {
    this.showAndHideTerms.sahEctopiaChiari = false;
    this.catchIDs();
  }

  clickEctopiaChiari() {
    this.showAndHideTerms.sahEctopiaChiari = true;
    this.catchIDs();
  }

  clickSelaTurca() {
    if (this.showAndHideTerms.sahSelaTurca === false) {
      this.showAndHideTerms.sahSelaTurca = true;
    } else {
      this.showAndHideTerms.sahSelaTurca = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickAvc() {
    if (this.showAndHideTerms.sahAvc === false) {
      this.showAndHideTerms.sahAvc = true;
      this.showAndHideTerms.sahAvcRecente = false;
      this.showAndHideTerms.sahAvcRecenteSing = false;
      this.showAndHideTerms.sahAvcRecentePlural = false;
      this.showAndHideTerms.sahAvcTransformacao = false;
      this.showAndHideTerms.sahAvcTransformacaoSing = false;
      this.showAndHideTerms.sahAvcTransformacaoPlural = false;
      this.showAndHideTerms.sahAvcNecrose = false;
      this.showAndHideTerms.sahAvcNecroseSing = false;
      this.showAndHideTerms.sahAvcNecrosePlural = false;
    } else {
      this.showAndHideTerms.sahAvc = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }
  clickAvcRecente() {
    this.showAndHideTerms.sahAvcRecente = true;
    this.showAndHideTerms.sahAvcRecenteSing = true;
    this.showAndHideTerms.sahAvcRecentePlural = false;
    this.showAndHideTerms.sahAvcTransformacao = false;
    this.showAndHideTerms.sahAvcTransformacaoSing = false;
    this.showAndHideTerms.sahAvcTransformacaoPlural = false;
    this.showAndHideTerms.sahAvcNecrose = false;
    this.showAndHideTerms.sahAvcNecroseSing = false;
    this.showAndHideTerms.sahAvcNecrosePlural = false;
  }

  clickAvcRecentePlural() {
    this.showAndHideTerms.sahAvcRecente = true;
    this.showAndHideTerms.sahAvcRecenteSing = false;
    this.showAndHideTerms.sahAvcRecentePlural = true;
    this.showAndHideTerms.sahAvcTransformacao = false;
    this.showAndHideTerms.sahAvcTransformacaoSing = false;
    this.showAndHideTerms.sahAvcTransformacaoPlural = false;
    this.showAndHideTerms.sahAvcNecrose = false;
    this.showAndHideTerms.sahAvcNecroseSing = false;
    this.showAndHideTerms.sahAvcNecrosePlural = false;
  }

  clickAvcTransformacao() {
    this.showAndHideTerms.sahAvcRecente = false;
    this.showAndHideTerms.sahAvcRecenteSing = false;
    this.showAndHideTerms.sahAvcRecentePlural = false;
    this.showAndHideTerms.sahAvcTransformacao = true;
    this.showAndHideTerms.sahAvcTransformacaoSing = true;
    this.showAndHideTerms.sahAvcTransformacaoPlural = false;
    this.showAndHideTerms.sahAvcNecrose = false;
    this.showAndHideTerms.sahAvcNecroseSing = false;
    this.showAndHideTerms.sahAvcNecrosePlural = false;
  }

  clickAvcTransformacaoPlural() {
    this.showAndHideTerms.sahAvcRecente = false;
    this.showAndHideTerms.sahAvcRecenteSing = false;
    this.showAndHideTerms.sahAvcRecentePlural = false;
    this.showAndHideTerms.sahAvcTransformacao = true;
    this.showAndHideTerms.sahAvcTransformacaoSing = false;
    this.showAndHideTerms.sahAvcTransformacaoPlural = true;
    this.showAndHideTerms.sahAvcNecrose = false;
    this.showAndHideTerms.sahAvcNecroseSing = false;
    this.showAndHideTerms.sahAvcNecrosePlural = false;
  }

  clickAvcNecrose() {
    this.showAndHideTerms.sahAvcRecente = false;
    this.showAndHideTerms.sahAvcRecenteSing = false;
    this.showAndHideTerms.sahAvcRecentePlural = false;
    this.showAndHideTerms.sahAvcTransformacao = false;
    this.showAndHideTerms.sahAvcTransformacaoSing = false;
    this.showAndHideTerms.sahAvcTransformacaoPlural = false;
    this.showAndHideTerms.sahAvcNecrose = true;
    this.showAndHideTerms.sahAvcNecroseSing = true;
    this.showAndHideTerms.sahAvcNecrosePlural = false;
  }

  clickAvcNecrosePlural() {
    this.showAndHideTerms.sahAvcRecente = false;
    this.showAndHideTerms.sahAvcRecenteSing = false;
    this.showAndHideTerms.sahAvcRecentePlural = false;
    this.showAndHideTerms.sahAvcTransformacao = false;
    this.showAndHideTerms.sahAvcTransformacaoSing = false;
    this.showAndHideTerms.sahAvcTransformacaoPlural = false;
    this.showAndHideTerms.sahAvcNecrose = true;
    this.showAndHideTerms.sahAvcNecroseSing = false;
    this.showAndHideTerms.sahAvcNecrosePlural = true;
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }
    if (this.rdAlterado.checked) {

      if (this.countLesoes > 0) {
        this.emitMicroangiopatiaLesao();
      } else {
        this.emitMicroangiopatia();
      }

      if (this.countLesoes > 0) {
        this.emitLacunaLesao();
      } else {
        this.emitLacuna();
      }

      if (this.countLesoes > 0) {
        this.emitEncefalomalaciaLesao();
      } else {
        this.emitEncefalomalacia();
      }

      if (this.countLesoes > 0) {
        this.emitAvcLesao();
      } else {
        this.emitAvc();
      }

      if (this.countLesoes > 0) {
        this.emitHemorragiaLesao();
      } else {
        this.emitHemorragia();
      }

      if (this.countLesoes > 0) {
        this.emitCalcificacoesLesao();
      } else {
        this.emitCalcificacoes();
      }

      if (this.countLesoes > 0) {
        this.emitLesoesIntraLesao();
      } else {
        this.emitLesoesIntra();
      }

      if (this.countLesoes > 0) {
        this.emitLesoesExtraLesao();
      } else {
        this.emitLesoesExtra();
      }

      if (this.countLesoes > 0) {
        this.emitTonsilasLesao();
      } else {
        this.emitTonsilas();
      }

      if (this.countLesoes > 0) {
        this.emitSelaTurcaLesao();
      } else {
        this.emitSelaTurca();
      }

      //RESTANTE PARENQUIMA PRECISA SER O ULTIMO
      if (this.countLesoes > 0) {
        this.emitParemquimaLesao();
      } else {
        this.emitParemquima();
      }

    }
    this.listaCompleta.emit();
  }

  emitParemquima() {
    if (this.ckRestParenquima && this.ckRestParenquima.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckRestParenquima.id);
    }
  }

  emitParemquimaLesao() {
    if (this.ckRestParenquima && this.ckRestParenquima.checked) {
      this.countLesoes++;
      const objetoPar = { id: this.ckRestParenquima.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoPar);
    }
  }

  emitMicroangiopatia() {
    if (this.ckMicroangiopatia && this.ckMicroangiopatia.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckMicroangiopatia.id);

      if (this.rdMicroangiopatiaLeve && this.rdMicroangiopatiaLeve.checked) {
        this.termoSelecionado.emit(this.rdMicroangiopatiaLeve.id);
      }

      if (this.rdMicroangiopatiaModerada && this.rdMicroangiopatiaModerada.checked) {
        this.termoSelecionado.emit(this.rdMicroangiopatiaModerada.id);
      }

      if (this.rdMicroangiopatiaAcentuada && this.rdMicroangiopatiaAcentuada.checked) {
        this.termoSelecionado.emit(this.rdMicroangiopatiaAcentuada.id);
      }
    }
  }

  emitMicroangiopatiaLesao() {
    if (this.ckMicroangiopatia && this.ckMicroangiopatia.checked) {
      this.countLesoes++;
      const objetoPar = { id: this.ckMicroangiopatia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoPar);

      if (this.rdMicroangiopatiaLeve && this.rdMicroangiopatiaLeve.checked) {
        const objetoCov = { id: this.rdMicroangiopatiaLeve.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);
      }
      if (this.rdMicroangiopatiaModerada && this.rdMicroangiopatiaModerada.checked) {
        const objetoCov = { id: this.rdMicroangiopatiaLeve.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);
      }
      if (this.rdMicroangiopatiaAcentuada && this.rdMicroangiopatiaAcentuada.checked) {
        const objetoCov = { id: this.rdMicroangiopatiaAcentuada.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);
      }
    }
  }

  emitLacuna() {
    if (this.ckLacuna && this.ckLacuna.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLacuna.id);

      if (this.rdLacunaUnilateral && this.rdLacunaUnilateral.checked) {
        this.termoSelecionado.emit(this.rdLacunaUnilateral.id);

        if (this.slLateralidadeUnilateral && this.slLateralidadeUnilateral.value) {
          const objeto = { elemento: this.slLateralidadeUnilateral, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
      if (this.rdLacunaBilateral && this.rdLacunaBilateral.checked) {
        this.termoSelecionado.emit(this.rdLacunaBilateral.id);
      }
      if (this.rdLacunaUnica && this.rdLacunaUnica.checked) {
        this.termoSelecionado.emit(this.rdLacunaUnica.id);

        if (this.slLocalizacaoUnica && this.slLocalizacaoUnica.value) {
          const objeto = { elemento: this.slLocalizacaoUnica, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
        if (this.slLateralidadeUnica && this.slLateralidadeUnica.value) {
          const objeto = { elemento: this.slLateralidadeUnica, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitLacunaLesao() {
    if (this.ckLacuna && this.ckLacuna.checked) {
      this.countLesoes++;
      const objetoLac = { id: this.ckLacuna.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoLac);

      if (this.rdLacunaUnilateral && this.rdLacunaUnilateral.checked) {
        const objetoLac = { id: this.rdLacunaUnilateral.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoLac);

        if (this.slLateralidadeUnilateral && this.slLateralidadeUnilateral.value) {
          const objeto = { elemento: this.slLateralidadeUnilateral, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
      if (this.rdLacunaBilateral && this.rdLacunaBilateral.checked) {
        const objetoLac = { id: this.rdLacunaBilateral.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoLac);
      }
      if (this.rdLacunaUnica && this.rdLacunaUnica.checked) {
        const objetoLac = { id: this.rdLacunaUnica.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoLac);

        if (this.slLocalizacaoUnica && this.slLocalizacaoUnica.value) {
          const objeto = { elemento: this.slLocalizacaoUnica, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
        if (this.slLateralidadeUnica && this.slLateralidadeUnica.value) {
          const objeto = { elemento: this.slLateralidadeUnica, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitEncefalomalacia() {
    if (this.ckEncefalomalacia && this.ckEncefalomalacia.checked) {

      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEncefalomalacia.id);
      if (this.slLocalizacaoEnce && this.slLocalizacaoEnce.value) {
        const objeto = { elemento: this.slLocalizacaoEnce, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.rdEnceNaoGraduar && this.rdEnceNaoGraduar.checked) {
        this.termoSelecionado.emit(this.rdEnceNaoGraduar.id);

        if (this.rdEnceNaoGraduarSing && this.rdEnceNaoGraduarSing.checked) {
          this.termoSelecionado.emit(this.rdEnceNaoGraduarSing.id);
        }
        if (this.rdEnceNaoGraduarPlural && this.rdEnceNaoGraduarPlural.checked) {
          this.termoSelecionado.emit(this.rdEnceNaoGraduarPlural.id);
        }
      }

      if (this.rdEnceGraduar && this.rdEnceGraduar.checked) {
        this.termoSelecionado.emit(this.rdEnceGraduar.id);

        if (this.rdEnceGraduarSing && this.rdEnceGraduarSing.checked) {
          this.termoSelecionado.emit(this.rdEnceGraduarSing.id);
          if (this.slGrauGraduarSing && this.slGrauGraduarSing.value) {
            const objeto = { elemento: this.slGrauGraduarSing, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.rdEnceGraduarPlural && this.rdEnceGraduarPlural.checked) {
          this.termoSelecionado.emit(this.rdEnceGraduarPlural.id);
          if (this.slGrauGraduarPlural && this.slGrauGraduarPlural.value) {
            const objeto = { elemento: this.slGrauGraduarPlural, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }
      }

      if (this.rdEnceEfeitoAtrofico && this.rdEnceEfeitoAtrofico.checked) {
        this.termoSelecionado.emit(this.rdEnceEfeitoAtrofico.id);

        if (this.slEfeitoVentriculo && this.slEfeitoVentriculo.value) {
          const objeto = { elemento: this.slEfeitoVentriculo, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
          if (this.rdEnceEfeitoSing && this.rdEnceEfeitoSing.checked) {
            this.termoSelecionado.emit(this.rdEnceEfeitoSing.id);
          }
          if (this.rdEnceEfeitoPlural && this.rdEnceEfeitoPlural.checked) {
            this.termoSelecionado.emit(this.rdEnceEfeitoPlural.id);
          }
        }


      }

      if ((this.rdEnceNaoGraduarSing && this.rdEnceNaoGraduarSing.checked) ||
        (this.rdEnceGraduarSing && this.rdEnceGraduarSing.checked) ||
        (this.rdEnceEfeitoSing && this.rdEnceEfeitoSing.checked)) {
        if (this.slLateralidadeSing && this.slLateralidadeSing.value) {
          const objeto = { elemento: this.slLateralidadeSing, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
      if ((this.rdEnceNaoGraduarPlural && this.rdEnceNaoGraduarPlural.checked) ||
        (this.rdEnceGraduarPlural && this.rdEnceGraduarPlural.checked) ||
        (this.rdEnceEfeitoPlural && this.rdEnceEfeitoPlural.checked)) {
        if (this.slLateralidadePlural && this.slLateralidadePlural.value) {
          const objeto = { elemento: this.slLateralidadePlural, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }
  emitEncefalomalaciaLesao() {
    if (this.ckEncefalomalacia && this.ckEncefalomalacia.checked) {
      this.countLesoes++;
      const objetoEnce = { id: this.ckEncefalomalacia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoEnce);

      if (this.slLocalizacaoEnce && this.slLocalizacaoEnce.value) {
        const objeto = { elemento: this.slLocalizacaoEnce, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.rdEnceNaoGraduar && this.rdEnceNaoGraduar.checked) {
        const objetoEnce = { id: this.rdEnceNaoGraduar.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoEnce);
        if (this.rdEnceNaoGraduarSing && this.rdEnceNaoGraduarSing.checked) {
          const objeto = { id: this.rdEnceNaoGraduarSing.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        }
        if (this.rdEnceNaoGraduarPlural && this.rdEnceNaoGraduarPlural.checked) {
          const objeto = { id: this.rdEnceNaoGraduarPlural.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        }

      }

      if (this.rdEnceGraduar && this.rdEnceGraduar.checked) {
        const objetoEnce = { id: this.rdEnceGraduar.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoEnce);

        if (this.rdEnceGraduarSing && this.rdEnceGraduarSing.checked) {
          const objeto = { id: this.rdEnceGraduarSing.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
          if (this.slGrauGraduarSing && this.slGrauGraduarSing.value) {
            const objeto = { elemento: this.slGrauGraduarSing, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.rdEnceGraduarPlural && this.rdEnceGraduarPlural.checked) {
          const objeto = { id: this.rdEnceGraduarPlural.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
          if (this.slGrauGraduarPlural && this.slGrauGraduarPlural.value) {
            const objeto = { elemento: this.slGrauGraduarPlural, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }
      }

      if (this.rdEnceEfeitoAtrofico && this.rdEnceEfeitoAtrofico.checked) {
        const objetoEnce = { id: this.rdEnceEfeitoAtrofico.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoEnce);

        if (this.slEfeitoVentriculo && this.slEfeitoVentriculo.value) {
          const objeto = { elemento: this.slEfeitoVentriculo, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
        if (this.rdEnceEfeitoSing && this.rdEnceEfeitoSing.checked) {
          const objeto = { id: this.rdEnceEfeitoSing.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        }
        if (this.rdEnceEfeitoPlural && this.rdEnceEfeitoPlural.checked) {
          const objeto = { id: this.rdEnceEfeitoPlural.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        }

      }
      if ((this.rdEnceNaoGraduarSing && this.rdEnceNaoGraduarSing.checked) ||
        (this.rdEnceGraduarSing && this.rdEnceGraduarSing.checked) ||
        (this.rdEnceEfeitoSing && this.rdEnceEfeitoSing.checked)) {
        if (this.slLateralidadeSing && this.slLateralidadeSing.value) {
          const objeto = { elemento: this.slLateralidadeSing, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
      if ((this.rdEnceNaoGraduarPlural && this.rdEnceNaoGraduarPlural.checked) ||
        (this.rdEnceGraduarPlural && this.rdEnceGraduarPlural.checked) ||
        (this.rdEnceEfeitoPlural && this.rdEnceEfeitoPlural.checked)) {
        if (this.slLateralidadePlural && this.slLateralidadePlural.value) {
          const objeto = { elemento: this.slLateralidadePlural, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

    }
  }

  emitHemorragia() {
    if (this.ckHemorragia && this.ckHemorragia.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHemorragia.id);

      if (this.ckSpotSign && this.ckSpotSign.checked) {
        this.termoSelecionado.emit(this.ckSpotSign.id);
      }
      if (this.slLocalizacaoHemorragia && this.slLocalizacaoHemorragia.value) {
        const objeto = { elemento: this.slLocalizacaoHemorragia, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLateralidadeHemorragia && this.slLateralidadeHemorragia.value) {
        const objeto = { elemento: this.slLateralidadeHemorragia, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slContornoHemorragia && this.slContornoHemorragia.value) {
        const objeto = { elemento: this.slContornoHemorragia, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slAtenuacaoHemorragia && this.slAtenuacaoHemorragia.value) {
        const objeto = { elemento: this.slAtenuacaoHemorragia, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
    this.emitMedidas(true);

  }

  emitHemorragiaLesao() {
    if (this.ckHemorragia && this.ckHemorragia.checked) {
      this.countLesoes++;
      const objetoHemo = { id: this.ckHemorragia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoHemo);

      if (this.ckSpotSign && this.ckSpotSign.checked) {
        const objetoHemo = { id: this.ckSpotSign.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoHemo);
      }
      if (this.slLocalizacaoHemorragia && this.slLocalizacaoHemorragia.value) {
        const objeto = { elemento: this.slLocalizacaoHemorragia, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLateralidadeHemorragia && this.slLateralidadeHemorragia.value) {
        const objeto = { elemento: this.slLateralidadeHemorragia, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.slContornoHemorragia && this.slContornoHemorragia.value) {
        const objeto = { elemento: this.slContornoHemorragia, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.slAtenuacaoHemorragia && this.slAtenuacaoHemorragia.value) {
        const objeto = { elemento: this.slAtenuacaoHemorragia, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      this.emitMedidas(false);
    }
  }

  emitMedidas(isPrimeiraLesao) {
    let medindoValor = '';
    let resultado = 0;

    if (this.inMedidaHemorragia1 != undefined &&
      this.inMedidaHemorragia1.nativeElement.id != undefined &&
      this.inMedidaHemorragia1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaHemorragia1.nativeElement.value);
      resultado = this.inMedidaHemorragia1.nativeElement.value;
    }

    if (this.inMedidaHemorragia2 != undefined &&
      this.inMedidaHemorragia2.nativeElement.id != undefined &&
      this.inMedidaHemorragia2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaHemorragia2.nativeElement.value);
        resultado = resultado * this.inMedidaHemorragia2.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaHemorragia2.nativeElement.value);
        resultado = this.inMedidaHemorragia2.nativeElement.value;

      }
    }

    if (this.inMedidaHemorragia3 != undefined &&
      this.inMedidaHemorragia3.nativeElement.id != undefined &&
      this.inMedidaHemorragia3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaHemorragia3.nativeElement.value);
        resultado = resultado * this.inMedidaHemorragia3.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaHemorragia3.nativeElement.value);
        resultado = this.inMedidaHemorragia3.nativeElement.value;
      }
    }

    if (medindoValor != '') {
      // Volume
      resultado = resultado * 0.523;
      this.volumeHemorragia = resultado.toFixed(1).toString();

      if (this.inVolumeHemorragia.nativeElement.value) {
        // Atribuindo a variavel para realizar o calculo
        this.medindoHemorragia = +this.inVolumeHemorragia.nativeElement.value;
      } else {
        // Atribuindo a variavel para realizar o calculo
        this.medindoHemorragia = +resultado;
      }

      const objetoElemento = { id: 4463, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);

      if (this.inVolumeHemorragia && this.inVolumeHemorragia.nativeElement.value) {
        const objeto = { elemento: this.inVolumeHemorragia.nativeElement, isPrimeiraLesao: isPrimeiraLesao };
        this.termosGenericos.emit(objeto);
      } else {
        const objetoElemento2 = { id: this.inVolumeHemorragia.nativeElement.id, value: this.volumeHemorragia };
        const objeto2 = { elemento: objetoElemento2, isPrimeiraLesao: isPrimeiraLesao };
        this.termosGenericos.emit(objeto2);
      }
    }
  }

  emitCalcificacoes() {
    if (this.ckCalcificacoes && this.ckCalcificacoes.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCalcificacoes.id);

      if (this.rdCalcificacaoResidual && this.rdCalcificacaoResidual.checked) {
        this.termoSelecionado.emit(this.rdCalcificacaoResidual.id);

        if (this.slLocalizacaoCalc && this.slLocalizacaoCalc.value) {
          const objeto = { elemento: this.slLocalizacaoCalc, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
        if (this.slLateralidadeCalc && this.slLateralidadeCalc.value) {
          const objeto = { elemento: this.slLateralidadeCalc, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
      if (this.rdCalcificacoesPalidais && this.rdCalcificacoesPalidais.checked) {
        this.termoSelecionado.emit(this.rdCalcificacoesPalidais.id);

      }
    }
  }

  emitCalcificacoesLesao() {
    if (this.ckCalcificacoes && this.ckCalcificacoes.checked) {
      this.countLesoes++;
      const objetoCalc = { id: this.ckCalcificacoes.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCalc);

      if (this.rdCalcificacaoResidual && this.rdCalcificacaoResidual.checked) {
        const objetoCalc = { id: this.rdCalcificacaoResidual.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCalc);

        if (this.slLocalizacaoCalc && this.slLocalizacaoCalc.value) {
          const objeto = { elemento: this.slLocalizacaoCalc, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
        if (this.slLateralidadeCalc && this.slLateralidadeCalc.value) {
          const objeto = { elemento: this.slLateralidadeCalc, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdCalcificacoesPalidais && this.rdCalcificacoesPalidais.checked) {
        const objetoCalc = { id: this.rdCalcificacoesPalidais.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCalc);
      }
    }
  }

  emitLesoesIntraLesao() {
    if (this.ckLesoesIntra && this.ckLesoesIntra.checked) {
      this.countLesoes++;
      const objetoLesoes = { id: this.ckLesoesIntra.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoLesoes);

      if (this.slLocalizacaoLesao && this.slLocalizacaoLesao.value) {
        const objeto = { elemento: this.slLocalizacaoLesao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLateralidadeLesao && this.slLateralidadeLesao.value) {
        const objeto = { elemento: this.slLateralidadeLesao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
    if (this.rdLesaoExpansiva && this.rdLesaoExpansiva.checked) {
      const objetoLesoes = { id: this.rdLesaoExpansiva.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoLesoes);

      if (this.slAtenuacaoLesao && this.slAtenuacaoLesao.value) {
        const objeto = { elemento: this.slAtenuacaoLesao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.slImpregnacaoLesao && this.slImpregnacaoLesao.value) {
        const objeto = { elemento: this.slImpregnacaoLesao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      this.formataMedindoLesaoIntra(false);

    }
    if (this.rdMultiplasLesoes && this.rdMultiplasLesoes.checked) {
      const objetoLesoes = { id: this.rdMultiplasLesoes.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoLesoes);

      if (this.inMedidaUnicaLesao && this.inMedidaUnicaLesao.nativeElement.value) {
        const objeto = { elemento: this.inMedidaUnicaLesao.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitLesoesIntra() {
    if (this.ckLesoesIntra && this.ckLesoesIntra.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLesoesIntra.id);

      if (this.slLocalizacaoLesao && this.slLocalizacaoLesao.value) {
        const objeto = { elemento: this.slLocalizacaoLesao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLateralidadeLesao && this.slLateralidadeLesao.value) {
        const objeto = { elemento: this.slLateralidadeLesao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
    if (this.rdLesaoExpansiva && this.rdLesaoExpansiva.checked) {
      this.termoSelecionado.emit(this.rdLesaoExpansiva.id);

      if (this.slAtenuacaoLesao && this.slAtenuacaoLesao.value) {
        const objeto = { elemento: this.slAtenuacaoLesao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slImpregnacaoLesao && this.slImpregnacaoLesao.value) {
        const objeto = { elemento: this.slImpregnacaoLesao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      this.formataMedindoLesaoIntra(true);
    }
    if (this.rdMultiplasLesoes && this.rdMultiplasLesoes.checked) {
      this.termoSelecionado.emit(this.rdMultiplasLesoes.id);

      if (this.inMedidaUnicaLesao && this.inMedidaUnicaLesao.nativeElement.value) {
        const objeto = { elemento: this.inMedidaUnicaLesao.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  formataMedindoLesaoIntra(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedidaLesaoIntra1 != undefined &&
      this.inMedidaLesaoIntra1.nativeElement.id != undefined &&
      this.inMedidaLesaoIntra1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaLesaoIntra1.nativeElement.value);
    }

    if (this.inMedidaLesaoIntra2 != undefined &&
      this.inMedidaLesaoIntra2.nativeElement.id != undefined &&
      this.inMedidaLesaoIntra2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaLesaoIntra2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaLesaoIntra2.nativeElement.value);
      }
    }

    if (this.inMedidaLesaoIntra3 != undefined &&
      this.inMedidaLesaoIntra3.nativeElement.id != undefined &&
      this.inMedidaLesaoIntra3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaLesaoIntra3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaLesaoIntra3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = { id: 4471, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  emitLesoesExtraLesao() {
    if (this.ckLesoesExtra && this.ckLesoesExtra.checked) {
      this.countLesoes++;
      const objetoLesoes = { id: this.ckLesoesExtra.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoLesoes);

      if (this.slLateralidadeLesaoExtra && this.slLateralidadeLesaoExtra.value) {
        const objeto = { elemento: this.slLateralidadeLesaoExtra, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      this.formataMedindoLesaoExtra(false);

      if ((this.rdLesaoExtraMeningioma && this.rdLesaoExtraMeningioma.checked)
        || this.rdLesaoExtraMeningiomaCalc && this.rdLesaoExtraMeningiomaCalc.checked) {
        if (this.slLocalizacaoLesaoExtra && this.slLocalizacaoLesaoExtra.value) {
          const objeto = { elemento: this.slLocalizacaoLesaoExtra, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
        if (this.ckHiperostoseOssea && this.ckHiperostoseOssea.checked) {
          const objetoLesoes = { id: this.ckHiperostoseOssea.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objetoLesoes);
        }
      }
      if (this.rdLesaoExtraMeningioma && this.rdLesaoExtraMeningioma.checked) {
        const objetoLesoes = { id: this.rdLesaoExtraMeningioma.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoLesoes);
      }
      if (this.rdLesaoExtraMeningiomaCalc && this.rdLesaoExtraMeningiomaCalc.checked) {
        const objetoLesoes = { id: this.rdLesaoExtraMeningiomaCalc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoLesoes);
      }
      if (this.rdLesaoExtraOsteoma && this.rdLesaoExtraOsteoma.checked) {
        const objetoLesoes = { id: this.rdLesaoExtraOsteoma.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoLesoes);
        if (this.slLocalizacaoLesaoExtraOsteoma && this.slLocalizacaoLesaoExtraOsteoma.value) {
          const objeto = { elemento: this.slLocalizacaoLesaoExtraOsteoma, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitLesoesExtra() {
    if (this.ckLesoesExtra && this.ckLesoesExtra.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLesoesExtra.id);

      if (this.slLateralidadeLesaoExtra && this.slLateralidadeLesaoExtra.value) {
        const objeto = { elemento: this.slLateralidadeLesaoExtra, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      this.formataMedindoLesaoExtra(true);

      if ((this.rdLesaoExtraMeningioma && this.rdLesaoExtraMeningioma.checked)
        || this.rdLesaoExtraMeningiomaCalc && this.rdLesaoExtraMeningiomaCalc.checked) {
        if (this.slLocalizacaoLesaoExtra && this.slLocalizacaoLesaoExtra.value) {
          const objeto = { elemento: this.slLocalizacaoLesaoExtra, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
        if (this.ckHiperostoseOssea && this.ckHiperostoseOssea.checked) {
          this.termoSelecionado.emit(this.ckHiperostoseOssea.id);
        }
      }
      if (this.rdLesaoExtraMeningioma && this.rdLesaoExtraMeningioma.checked) {
        this.termoSelecionado.emit(this.rdLesaoExtraMeningioma.id);
      }
      if (this.rdLesaoExtraMeningiomaCalc && this.rdLesaoExtraMeningiomaCalc.checked) {
        this.termoSelecionado.emit(this.rdLesaoExtraMeningiomaCalc.id);
      }
      if (this.rdLesaoExtraOsteoma && this.rdLesaoExtraOsteoma.checked) {
        this.termoSelecionado.emit(this.rdLesaoExtraOsteoma.id);

        if (this.slLocalizacaoLesaoExtraOsteoma && this.slLocalizacaoLesaoExtraOsteoma.value) {
          const objeto = { elemento: this.slLocalizacaoLesaoExtraOsteoma, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  formataMedindoLesaoExtra(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedidaLesaoExtra1 != undefined &&
      this.inMedidaLesaoExtra1.nativeElement.id != undefined &&
      this.inMedidaLesaoExtra1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaLesaoExtra1.nativeElement.value);
    }

    if (this.inMedidaLesaoExtra2 != undefined &&
      this.inMedidaLesaoExtra2.nativeElement.id != undefined &&
      this.inMedidaLesaoExtra2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaLesaoExtra2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaLesaoExtra2.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = { id: 4474, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  emitTonsilasLesao() {
    if (this.ckTonsilas && this.ckTonsilas.checked) {
      this.countLesoes++;
      const objetoTonsilas = { id: this.ckTonsilas.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoTonsilas);

      if (this.rdEctopia && this.rdEctopia.checked) {
        const objetoTonsilas = { id: this.rdEctopia.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoTonsilas);
      }

      if (this.rdEctopiaChiari && this.rdEctopiaChiari.checked) {
        const objetoTonsilas = { id: this.rdEctopiaChiari.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoTonsilas);

        if (this.inMedidaEctopia && this.inMedidaEctopia.nativeElement.value) {
          const objeto = { elemento: this.inMedidaEctopia.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

    }
  }

  emitTonsilas() {
    if (this.ckTonsilas && this.ckTonsilas.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckTonsilas.id);

      if (this.rdEctopia && this.rdEctopia.checked) {
        this.termoSelecionado.emit(this.rdEctopia.id);
      }

      if (this.rdEctopiaChiari && this.rdEctopiaChiari.checked) {
        this.termoSelecionado.emit(this.rdEctopiaChiari.id);

        if (this.inMedidaEctopia && this.inMedidaEctopia.nativeElement.value) {
          const objeto = { elemento: this.inMedidaEctopia.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

    }
  }

  emitSelaTurcaLesao() {
    if (this.ckSelaTurca && this.ckSelaTurca.checked) {
      this.countLesoes++;
      const objetoSela = { id: this.ckSelaTurca.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoSela);

      if (this.rdSelaHipertensao && this.rdSelaHipertensao.checked) {
        const objetoSela = { id: this.rdSelaHipertensao.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoSela);
      }
      if (this.rdSelaInesp && this.rdSelaInesp.checked) {
        const objetoSela = { id: this.rdSelaInesp.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoSela);
      }
      if (this.rdSelaHabitual && this.rdSelaHabitual.checked) {
        const objetoSela = { id: this.rdSelaHabitual.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoSela);
      }
    }
  }
  emitSelaTurca() {
    if (this.ckSelaTurca && this.ckSelaTurca.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckSelaTurca.id);

      if (this.rdSelaHipertensao && this.rdSelaHipertensao.checked) {
        this.termoSelecionado.emit(this.rdSelaHipertensao.id);
      }
      if (this.rdSelaInesp && this.rdSelaInesp.checked) {
        this.termoSelecionado.emit(this.rdSelaInesp.id);
      }
      if (this.rdSelaHabitual && this.rdSelaHabitual.checked) {
        this.termoSelecionado.emit(this.rdSelaHabitual.id);
      }
    }
  }

  emitAvcLesao() {
    if (this.ckAvc && this.ckAvc.checked) {
      this.countLesoes++;
      const objetoAvc = { id: this.ckAvc.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoAvc);

      if (this.rdAvcRecente && this.rdAvcRecente.checked) {
        const objetoAvc = { id: this.rdAvcRecente.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoAvc);

        if (this.rdAvcRecenteSing && this.rdAvcRecenteSing.checked) {
          const objeto = { elemento: this.rdAvcRecenteSing, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
          if (this.slLateralidadeAvcSing && this.slLateralidadeAvcSing.value) {
            const objeto = { elemento: this.slLateralidadeAvcSing, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }
        if (this.rdAvcRecentePlural && this.rdAvcRecentePlural.checked) {
          const objeto = { elemento: this.rdAvcRecentePlural, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
          if (this.slLateralidadeAvcPlural && this.slLateralidadeAvcPlural.value) {
            const objeto = { elemento: this.slLateralidadeAvcPlural, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }
      }

      if (this.rdAvcTransformacao && this.rdAvcTransformacao.checked) {
        const objetoAvc = { id: this.rdAvcTransformacao.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoAvc);

        if (this.rdAvcTransformacaoSing && this.rdAvcTransformacaoSing.checked) {
          const objeto = { elemento: this.rdAvcTransformacaoSing, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
          if (this.slLateralidadeAvcSing && this.slLateralidadeAvcSing.value) {
            const objeto = { elemento: this.slLateralidadeAvcSing, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }
        if (this.rdAvcTransformacaoPlural && this.rdAvcTransformacaoPlural.checked) {
          const objeto = { elemento: this.rdAvcTransformacaoPlural, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
          if (this.slLateralidadeAvcPlural && this.slLateralidadeAvcPlural.value) {
            const objeto = { elemento: this.slLateralidadeAvcPlural, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }
      }

      if ((this.rdAvcRecente && this.rdAvcRecente.checked) ||
        (this.rdAvcTransformacao && this.rdAvcTransformacao.checked)) {
        if (this.slLocalizacaoAvc && this.slLocalizacaoAvc.value) {
          const objeto = { elemento: this.slLocalizacaoAvc, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
        if (this.slArteriaAvc && this.slArteriaAvc.value) {
          const objeto = { elemento: this.slArteriaAvc, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdAvcNecrose && this.rdAvcNecrose.checked) {
        const objetoAvc = { id: this.rdAvcNecrose.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoAvc);
        if (this.slLocalizacaoAvcNecrose && this.slLocalizacaoAvcNecrose.value) {
          const objeto = { elemento: this.slLocalizacaoAvcNecrose, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.rdAvcNecroseSing && this.rdAvcNecroseSing.checked) {
          const objeto = { elemento: this.rdAvcNecroseSing, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
          if (this.slLateralidadeAvcSing && this.slLateralidadeAvcSing.value) {
            const objeto = { elemento: this.slLateralidadeAvcSing, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }
        if (this.rdAvcNecrosePlural && this.rdAvcNecrosePlural.checked) {
          const objeto = { elemento: this.rdAvcNecrosePlural, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
          if (this.slLateralidadeAvcPlural && this.slLateralidadeAvcPlural.value) {
            const objeto = { elemento: this.slLateralidadeAvcPlural, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }
      }

    }
  }

  emitAvc() {
    if (this.ckAvc && this.ckAvc.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAvc.id);

      if (this.rdAvcRecente && this.rdAvcRecente.checked) {
        this.termoSelecionado.emit(this.rdAvcRecente.id);

        if (this.rdAvcRecenteSing && this.rdAvcRecenteSing.checked) {
          this.termoSelecionado.emit(this.rdAvcRecenteSing.id);
          if (this.slLateralidadeAvcSing && this.slLateralidadeAvcSing.value) {
            const objeto = { elemento: this.slLateralidadeAvcSing, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }
        if (this.rdAvcRecentePlural && this.rdAvcRecentePlural.checked) {
          this.termoSelecionado.emit(this.rdAvcRecentePlural.id);
          if (this.slLateralidadeAvcPlural && this.slLateralidadeAvcPlural.value) {
            const objeto = { elemento: this.slLateralidadeAvcPlural, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }
      }

      if (this.rdAvcTransformacao && this.rdAvcTransformacao.checked) {
        this.termoSelecionado.emit(this.rdAvcTransformacao.id);

        if (this.rdAvcTransformacaoSing && this.rdAvcTransformacaoSing.checked) {
          this.termoSelecionado.emit(this.rdAvcTransformacaoSing.id);

          if (this.slLateralidadeAvcSing && this.slLateralidadeAvcSing.value) {
            const objeto = { elemento: this.slLateralidadeAvcSing, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }
        if (this.rdAvcTransformacaoPlural && this.rdAvcTransformacaoPlural.checked) {
          this.termoSelecionado.emit(this.rdAvcTransformacaoPlural.id);
          if (this.slLateralidadeAvcPlural && this.slLateralidadeAvcPlural.value) {
            const objeto = { elemento: this.slLateralidadeAvcPlural, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }
      }

      if ((this.rdAvcRecente && this.rdAvcRecente.checked) ||
        (this.rdAvcTransformacao && this.rdAvcTransformacao.checked)) {
        if (this.slLocalizacaoAvc && this.slLocalizacaoAvc.value) {
          const objeto = { elemento: this.slLocalizacaoAvc, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
        if (this.slArteriaAvc && this.slArteriaAvc.value) {
          const objeto = { elemento: this.slArteriaAvc, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdAvcNecrose && this.rdAvcNecrose.checked) {
        this.termoSelecionado.emit(this.rdAvcNecrose.id);

        if (this.slLocalizacaoAvcNecrose && this.slLocalizacaoAvcNecrose.value) {
          const objeto = { elemento: this.slLocalizacaoAvcNecrose, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.rdAvcNecroseSing && this.rdAvcNecroseSing.checked) {
          this.termoSelecionado.emit(this.rdAvcNecroseSing.id);
          if (this.slLateralidadeAvcSing && this.slLateralidadeAvcSing.value) {
            const objeto = { elemento: this.slLateralidadeAvcSing, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }
        if (this.rdAvcNecrosePlural && this.rdAvcNecrosePlural.checked) {
          this.termoSelecionado.emit(this.rdAvcNecrosePlural.id);
          if (this.slLateralidadeAvcPlural && this.slLateralidadeAvcPlural.value) {
            const objeto = { elemento: this.slLateralidadeAvcPlural, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }
      }
    }
  }

  verificaAlterado() {
    if ((this.ckMicroangiopatia.checked && this.ckMicroangiopatia) ||
      (this.ckLacuna.checked && this.ckLacuna) ||
      (this.ckEncefalomalacia.checked && this.ckLacuna) ||
      (this.ckHemorragia.checked && this.ckLacuna) ||
      (this.ckCalcificacoes.checked && this.ckLacuna) ||
      (this.ckLesoesIntra.checked && this.ckLacuna) ||
      (this.ckLesoesExtra.checked && this.ckLacuna) ||
      (this.ckTonsilas.checked && this.ckTonsilas) ||
      (this.ckSelaTurca.checked && this.ckSelaTurca) ||
      (this.ckAvc.checked && this.ckAvc)) {
      this.rdAlterado.checked = true;
      this.rdNormal.checked = false;
    } else {
      this.rdNormal.checked = true;
      this.rdAlterado.checked = false;
    }
  }

}
