import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material';

@Component({
  selector: 'app-rodape',
  templateUrl: './rodape.component.html',
  styleUrls: ['./rodape.component.css']
})
export class RodapeComponent implements OnInit {

  @Output() enviaRodapeCarotidas = new EventEmitter();

  @ViewChild('nomeMedico') nomeMedico: ElementRef;
  @ViewChild('crm') crm: ElementRef;
  @ViewChild('ckcopiarHtml') ckcopiarHtml: MatCheckbox;

  nomeUser;
  crmBusca;

  nomeObj = '';
  crmObj = '';
  showCampos = false;

  constructor(
  ) { }

  ngOnInit() {
    this.adicionaRemoveCampos();
  }

  adicionaRemoveCampos() {
    this.nomeUser = localStorage.getItem('nomeUsuario');
    this.crmBusca = localStorage.getItem('crm');
    this.nomeObj = `<b>${this.nomeUser}<b>`;
    this.crmObj = `<b> - ${this.crmBusca}<b>`;
    this.adicionaTexto();
  }

  blurNome() {
    let nomeMedico = this.nomeMedico.nativeElement.value;

    if (this.nomeMedico){
      if (nomeMedico !== '') {
        this.nomeObj = `<b>${nomeMedico}</b>`;
        this.adicionaTexto();
      } else {
        this.nomeObj = '';
        this.adicionaTexto();
      }
    }
  }

  blurCrm() {
    let crm = this.crm.nativeElement.value;
    if (this.crm){
      if (crm !== '') {
        this.crmObj = `<b> - CRM: ${crm}</b>`;
        this.adicionaTexto();
      } else {
        this.crmObj = '';
        this.adicionaTexto();
      }
    }
  }

  adicionaTexto(){
    const obj = {
      dataAtual: this.nomeObj,
      nome: this.crmObj,
    }

    let text = Object.entries(obj);
    let textCompleto;

    if(text) {
      text.forEach(desc => {
        if (desc[1] !== undefined || desc[1] !== null || desc[1] !== '') {
          if(textCompleto === undefined && desc[1] !== '') {
            textCompleto = desc[1];
          } else if (textCompleto !== undefined && desc[1] !== '') {
            textCompleto += desc[1];
          }
        }
      });
    }

    if (textCompleto !== undefined && textCompleto !== '') 
      this.enviaRodapeCarotidas.emit(`<br/><br/><br/><br/><br/><br/><div style="text-align: right; display: flex;"><div style="min-width: 30%; border-top: 1px solid black; position: absolute; right: 10px;">${textCompleto}</div></div>`);
    else 
      this.enviaRodapeCarotidas.emit('');
  }
}
