import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-lesoes-osseas',
  templateUrl: './lesoes-osseas.component.html',
  styleUrls: ['./lesoes-osseas.component.css']
})
export class LesoesOsseasComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('ckLesaoLit') ckLesaoLit: MatCheckbox;
  @ViewChild('ckLesaoEsc') ckLesaoEsc: MatCheckbox;
  @ViewChild('ckMultiplasLit') ckMultiplasLit: MatRadioButton;
  @ViewChild('ckMultiplasEsc') ckMultiplasEsc: MatRadioButton;
  @ViewChild('slNivelLit') slNivelLit: MatSelect;
  @ViewChild('slNivelEsc') slNivelEsc: MatSelect;

  nivel: ArrayDropDown[] = [
    { value: 'L5', viewValue: 'L5' },
    { value: 'S1', viewValue: 'S1'},
    { value: 'S2', viewValue: 'S2'},
    { value: 'S3', viewValue: 'S3'},
    { value: 'S4', viewValue: 'S4'},
    { value: 'S5', viewValue: 'S5'},
    { value: 'cóccix', viewValue: 'Cóccix'}
  ];

  showAndHideTerms: any;
  countLesoes = 0;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente : true,
      sahLesaoLit : false,
      sahLesaoEsc : false
    };
  }

  clickAusente(){
    this.showAndHideTerms.sahPresente = false;
    this.showAndHideTerms.sahLesaoEsc = false;
    this.showAndHideTerms.sahLesaoLit = false;
    this.catchIDs();
  }

  clickPresente(){
    this.showAndHideTerms.sahPresente = true;
    this.catchIDs();
  }

  clickLesaoLit(){
    if (this.showAndHideTerms.sahLesaoLit === false) {
      this.showAndHideTerms.sahLesaoLit = true;
      this.showAndHideTerms.sahNivelLit = true;
    } else {
      this.showAndHideTerms.sahLesaoLit = false;
    }
    this.alteraPresente();
    this.catchIDs();
  }

  clickMultiplasLit(){
    if (this.ckMultiplasLit.checked === true) {
      this.showAndHideTerms.sahNivelLit = false
    } else {
      this.showAndHideTerms.sahNivelLit = true;
    }
    this.catchIDs();
  }

  clickLesaoEsc(){
    if (this.showAndHideTerms.sahLesaoEsc === false) {
      this.showAndHideTerms.sahNivelEsc = true;
      this.showAndHideTerms.sahLesaoEsc = true;
    } else {
      this.showAndHideTerms.sahLesaoEsc = false;
    }
    this.alteraPresente();
    this.catchIDs();
  }

  clickMultiplasEsc(){
    if (this.ckMultiplasEsc.checked === true) {
      this.showAndHideTerms.sahNivelEsc = false
    } else {
      this.showAndHideTerms.sahNivelEsc = true;
    }
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked){
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente.checked){
      this.emitLesaoLit();

      if(this.countLesoes > 0){
        this.emitLesaoEscLesao();
      } else {
        this.emitLesaoEsc();
      }

    }

    this.listaCompleta.emit();

  }

  alteraPresente() {
    if (this.ckLesaoLit && this.ckLesaoLit.checked || this.ckLesaoEsc && this.ckLesaoEsc.checked){
      this.rdPresente.checked = true;
    }else {
      this.rdAusente.checked = true;
    }
  }

  emitLesaoLit(){
    if(this.ckLesaoLit.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLesaoLit.id);
      if(this.slNivelLit && this.slNivelLit.value){
        const objeto = {elemento: this.slNivelLit, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }else if(this.ckMultiplasLit.checked){
        this.termoSelecionado.emit(this.ckMultiplasLit.id);
      }
    }
  }

  emitLesaoEsc(){
    if(this.ckLesaoEsc.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLesaoEsc.id);
      if(this.slNivelEsc && this.slNivelEsc.value){
        const objeto = {elemento: this.slNivelEsc, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }else if(this.ckMultiplasEsc && this.ckMultiplasEsc.checked){
        this.termoSelecionado.emit(this.ckMultiplasEsc.id);
      }
    }
  }

  emitLesaoEscLesao(){
    if(this.ckLesaoEsc.checked){
      this.countLesoes++;
      const objeto = { id: this.ckLesaoEsc.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if(this.slNivelEsc && this.slNivelEsc.value){
        const objeto = {elemento: this.slNivelEsc, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }else if(this.ckMultiplasEsc.checked){
        const objeto = { id: this.ckMultiplasEsc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }


}
