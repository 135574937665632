import { Component, OnInit, ElementRef, EventEmitter, ViewChild, Output } from '@angular/core';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { MatSelect, MatRadioButton } from '@angular/material';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-aoili-arteria-externa-direita',
  templateUrl: './aoili-arteria-externa-direita.component.html',
  styleUrls: ['./aoili-arteria-externa-direita.component.css']
})
export class AoiliArteriaExternaDireitaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdnaoCaracterizada') rdnaoCaracterizada: MatRadioButton;
  @ViewChild('rdParcCaracterizada') rdParcCaracterizada: MatRadioButton;
  @ViewChild('rdPlacas') rdPlacas: MatRadioButton;
  @ViewChild('rdEctasia') rdEctasia: MatRadioButton;
  @ViewChild('rdTrombose') rdTrombose: MatRadioButton;
  @ViewChild('rdAneurisma') rdAneurisma: MatRadioButton;

  @ViewChild('slMotivo') slMotivo: MatSelect;

  @ViewChild('slEcogenicidade') slEcogenicidade: MatSelect;
  @ViewChild('slEcotextura') slEcotextura: MatSelect;
  @ViewChild('slSuperficie') slSuperficie: MatSelect;
  @ViewChild('slEstenose') slEstenose: MatSelect;

  @ViewChild('slTrombo') slTrombo: MatSelect;

  @ViewChild('inEspessura') inEspessura: ElementRef;
  @ViewChild('inExtensao') inExtensao: ElementRef;

  @ViewChild('inCalibre') inCalibre: ElementRef;

  @ViewChild('inDiametro') inDiametro: ElementRef;

  @ViewChild('inColoProxi') inColoProxi: ElementRef;
  @ViewChild('inColoDistal') inColoDistal: ElementRef;
  @ViewChild('inDistArterias') inDistArterias: ElementRef;

  motivos: ArrayDropDown[] = [
    { value: 'pela acentuada interposição gasosa.', viewValue: 'Interposição gasosa' },
    { value: 'pela intensa atenuação do feixe acústico posterior.', viewValue: 'Atenuação do feixe acústico' }
  ];

  ecogenicidades: ArrayDropDown[] = [
    { value: 'calcificada', viewValue: 'Calcificada' },
    { value: 'mista', viewValue: 'Mista' },
    { value: 'iso/hiperecogênica', viewValue: 'Iso/hiperecogênica' },
    { value: 'hipoecogênica', viewValue: 'Hipoecogênica' }
  ];

  ecotexturas: ArrayDropDown[] = [
    { value: 'homogênea', viewValue: 'Homogênea' },
    { value: 'heterogênea', viewValue: 'Heterogênea' }
  ];

  supers: ArrayDropDown[] = [
    { value: 'regular', viewValue: 'Regular' },
    { value: 'irregular', viewValue: 'Irregular' },
    { value: 'ulcerada', viewValue: 'Ulcerada' }
  ];

  estenoses: ArrayDropDown[] = [
    { value: 'sem determinar estenose hemodinamicamente significativa (< 50%)', viewValue: '< 50%' },
    { value: 'determinando estenose hemodinamicamente significativa (≥ 50%)', viewValue: '≥ 50%' },
    { value: 'determinando suboclusão', viewValue: 'Determinando suboclusão' }
  ];

  trombos: ArrayDropDown[] = [
    { value: 'Ausência de sinais de trombose no seu interior.', viewValue: 'Sem Trombose' },
    { value: 'Nota-se trombo mural no seu interior, sem determinar oclusão.|com trombo mural.', viewValue: 'Trombo mural' },
    { value: 'Ausência de fluxo vascular detectável no seu interior, inferindo trombose.|com sinais de trombose.', viewValue: 'Trombose' }
  ];

  showAndHideTerms: any;
  semTrombo = "4255";
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahNcarac: false,
      sahPlaca: false,
      sahComMedida: false,
      sahTrombose: false,
      sahEctasia: false,
      sahAneurisma: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  ClickNormal() {
    this.showAndHideTerms.sahNcarac = false;
    this.showAndHideTerms.sahPlaca = false;
    this.showAndHideTerms.sahComMedida = false;
    this.showAndHideTerms.sahTrombose = false;
    this.showAndHideTerms.sahEctasia = false;
    this.showAndHideTerms.sahAneurisma = false;
    this.catchIDs();
  }

  ClickNaoCaracterizada() {
    this.showAndHideTerms.sahNcarac = true;
    this.showAndHideTerms.sahPlaca = false;
    this.showAndHideTerms.sahComMedida = false;
    this.showAndHideTerms.sahTrombose = false;
    this.showAndHideTerms.sahEctasia = false;
    this.showAndHideTerms.sahAneurisma = false;
    this.catchIDs();
  }
  ClickParcCaracterizada() {
    this.showAndHideTerms.sahNcarac = false;
    this.showAndHideTerms.sahPlaca = false;
    this.showAndHideTerms.sahComMedida = false;
    this.showAndHideTerms.sahTrombose = false;
    this.showAndHideTerms.sahEctasia = false;
    this.showAndHideTerms.sahAneurisma = false;
    this.catchIDs();
  }
  clickPlacas() {
    this.showAndHideTerms.sahNcarac = false;
    this.showAndHideTerms.sahPlaca = true;
    this.showAndHideTerms.sahComMedida = true;
    this.showAndHideTerms.sahTrombose = false;
    this.showAndHideTerms.sahEctasia = false;
    this.showAndHideTerms.sahAneurisma = false;
    this.catchIDs();
  }
  ClickEctasia() {
    this.showAndHideTerms.sahNcarac = false;
    this.showAndHideTerms.sahPlaca = false;
    this.showAndHideTerms.sahComMedida = false;
    this.showAndHideTerms.sahTrombose = false;
    this.showAndHideTerms.sahEctasia = true;
    this.showAndHideTerms.sahAneurisma = false;
    this.catchIDs();
  }
  clickTrombose() {
    this.showAndHideTerms.sahNcarac = false;
    this.showAndHideTerms.sahPlaca = false;
    this.showAndHideTerms.sahComMedida = false;
    this.showAndHideTerms.sahTrombose = true;
    this.showAndHideTerms.sahEctasia = false;
    this.showAndHideTerms.sahAneurisma = false;
    this.catchIDs();
  }
  clickAneurisma() {
    this.showAndHideTerms.sahNcarac = false;
    this.showAndHideTerms.sahPlaca = false;
    this.showAndHideTerms.sahComMedida = false;
    this.showAndHideTerms.sahTrombose = false;
    this.showAndHideTerms.sahEctasia = false;
    this.showAndHideTerms.sahAneurisma = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdnaoCaracterizada && this.rdnaoCaracterizada.checked) {
      this.termoSelecionado.emit(this.rdnaoCaracterizada.id);
      if (this.slMotivo && this.slMotivo.value) {
        const objeto = { elemento: this.slMotivo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdParcCaracterizada && this.rdParcCaracterizada.checked) {
      this.termoSelecionado.emit(this.rdParcCaracterizada.id);
    }

    if (this.rdPlacas && this.rdPlacas.checked) {
      this.termoSelecionado.emit(this.rdPlacas.id);

      if (this.slEcogenicidade && this.slEcogenicidade.value) {
        const objeto = { elemento: this.slEcogenicidade, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcotextura && this.slEcotextura.value) {
        const objeto = { elemento: this.slEcotextura, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slSuperficie && this.slSuperficie.value) {
        const objeto = { elemento: this.slSuperficie, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEstenose && this.slEstenose.value) {
        const objeto = { elemento: this.slEstenose, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inEspessura && this.inEspessura.nativeElement.value) {
        const comp = this._validacoes.formataDecimal(this.inEspessura.nativeElement.value);
        const objetoElemento = { id: this.inEspessura.nativeElement.id, value: comp, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inExtensao && this.inExtensao.nativeElement.value) {
        const comp = this._validacoes.formataDecimal(this.inExtensao.nativeElement.value);
        const objetoElemento = { id: this.inExtensao.nativeElement.id, value: comp, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

    }

    if (this.rdTrombose && this.rdTrombose.checked) {
      this.termoSelecionado.emit(this.rdTrombose.id);

      if (this.inExtensao && this.inExtensao.nativeElement.value) {
        const comp = this._validacoes.formataDecimal(this.inExtensao.nativeElement.value);
        const objetoElemento = { id: this.inExtensao.nativeElement.id, value: comp, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdEctasia && this.rdEctasia.checked) {
      this.termoSelecionado.emit(this.rdEctasia.id);
      if (this.inCalibre && this.inCalibre.nativeElement.value) {
        const comp = this._validacoes.formataDecimal(this.inCalibre.nativeElement.value);
        const objetoElemento = { id: this.inCalibre.nativeElement.id, value: comp, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdAneurisma && this.rdAneurisma.checked) {
      this.termoSelecionado.emit(this.rdAneurisma.id);


      if (this.slTrombo.value === 'Ausência de sinais de trombose no seu interior.') {
        const objetoElemento = {id: this.semTrombo, value:''};
        const objetoCriado = { elemento: objetoElemento};
        this.termosGenericos.emit(objetoCriado);
      } else {
        const objeto = { elemento: this.slTrombo, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inDiametro && this.inDiametro.nativeElement.value) {
        const comp = this._validacoes.formataDecimal(this.inDiametro.nativeElement.value);
        const objetoElemento = { id: this.inDiametro.nativeElement.id, value: comp, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inExtensao && this.inExtensao.nativeElement.value) {
        const comp = this._validacoes.formataDecimal(this.inExtensao.nativeElement.value);
        const objetoElemento = { id: this.inExtensao.nativeElement.id, value: comp, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inColoProxi && this.inColoProxi.nativeElement.value) {
        const comp = this._validacoes.formataDecimal(this.inColoProxi.nativeElement.value);
        const objetoElemento = { id: this.inColoProxi.nativeElement.id, value: comp, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inColoDistal && this.inColoDistal.nativeElement.value) {
        const comp = this._validacoes.formataDecimal(this.inColoDistal.nativeElement.value);
        const objetoElemento = { id: this.inColoDistal.nativeElement.id, value: comp, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistArterias && this.inDistArterias.nativeElement.value) {
        const comp = this._validacoes.formataDecimal(this.inDistArterias.nativeElement.value);
        const objetoElemento = { id: this.inDistArterias.nativeElement.id, value: comp, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }


    }

    this.listaCompleta.emit();
  }

}
