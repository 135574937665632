import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-pele-tecido-cotovelo',
  templateUrl: './pele-tecido-cotovelo.component.html',
  styleUrls: ['./pele-tecido-cotovelo.component.css']
})
export class PeleTecidoCotoveloComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdEdema') rdEdema: MatRadioButton;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;

  localizacoes: ArrayDropDown[] = [
    { value: 'anterior', viewValue: 'Anterior' },
    { value: 'posterior', viewValue: 'Posterior' },
    { value: 'medial', viewValue: 'Medial' },
    { value: 'lateral', viewValue: 'Lateral' }
  ];

  showLocalizacao = true;
  constructor() { }

  ngOnInit() {

  }
  clickNormal() {
    this.showLocalizacao = false;
    this.catchIDs();
  }

  clickEdema() {
    this.showLocalizacao = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.slLocalizacao && this.slLocalizacao.value)){
      this.rdEdema.checked = true;
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdEdema.checked) {
      this.termoSelecionado.emit(this.rdEdema.value);

      if (this.slLocalizacao && this.slLocalizacao.value) {
        const objeto = { elemento: this.slLocalizacao};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
