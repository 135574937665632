import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-veia-porta-doppler',
  templateUrl: './veia-porta-doppler.component.html',
  styleUrls: ['./veia-porta-doppler.component.css']
})
export class VeiaPortaDopplerComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdPervia') rdPervia: MatRadioButton;
  @ViewChild('rdTrombose') rdTrombose: MatRadioButton;
  @ViewChild('rdNaoCaract') rdNaoCaract: MatRadioButton;

  // Pervia
  @ViewChild('slCalibre') slCalibre: MatSelect;
  @ViewChild('slFluxo') slFluxo: MatSelect;
  @ViewChild('inCalibrePervia') inCalibrePervia: ElementRef;
  @ViewChild('inVelocidadeMax') inVelocidadeMax: ElementRef;

  // Trombose
  @ViewChild('ckTransformacao') ckTransformacao: MatCheckbox;
  @ViewChild('inCalibreTrombose') inCalibreTrombose: ElementRef;

  // NaoCaract
  @ViewChild('rdInterposicao') rdInterposicao: MatRadioButton;
  @ViewChild('rdEsteatose') rdEsteatose: MatRadioButton;
  @ViewChild('rdTransformacao') rdTransformacao: MatRadioButton;

  calibres: ArrGenerico[] = [
    { value: '1107', viewValue: 'Preservado' },
    { value: '1108', viewValue: 'Aumentado' }
  ];

  fluxos: ArrGenerico[] = [
    { value: '1109', viewValue: 'Hepatopetal' },
    { value: '1110', viewValue: 'Hepatofugal' }
  ];

  calibreSelected: string;
  fluxoSelected: string;
  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPervia: true,
      sahTrombose: false,
      sahNaoCaract: false,
    };

    this.calibreSelected = this.calibres[0].value;
    this.fluxoSelected = this.fluxos[0].value;

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickPervia() {
    this.showAndHideTerms.sahPervia = true;
    this.showAndHideTerms.sahTrombose = false;
    this.showAndHideTerms.sahNaoCaract = false;
    this.catchIDs();
  }

  clickTrombose() {
    this.showAndHideTerms.sahTrombose = true;
    this.showAndHideTerms.sahPervia = false;
    this.showAndHideTerms.sahNaoCaract = false;
    this.catchIDs();
  }

  clickNaoCaract() {
    this.showAndHideTerms.sahNaoCaract = true;
    this.showAndHideTerms.sahTrombose = false;
    this.showAndHideTerms.sahPervia = false;
    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  changeDefault() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdPervia.checked) {
      this.termoSelecionado.emit(this.rdPervia.id);

      if (this.slCalibre != undefined && this.slCalibre.value != undefined) {
        this.termoSelecionado.emit(this.slCalibre.value);
      } else {
        this.termoSelecionado.emit(this.calibreSelected);
      }

      if (this.slFluxo != undefined && this.slFluxo.value != undefined) {
        this.termoSelecionado.emit(this.slFluxo.value);
      }else {
        this.termoSelecionado.emit(this.fluxoSelected);
      }

      if (this.inCalibrePervia && this.inCalibrePervia.nativeElement.value) {
        const objeto = { elemento: this.inCalibrePervia.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inVelocidadeMax && this.inVelocidadeMax.nativeElement.value) {
        const objeto = { elemento: this.inVelocidadeMax.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdTrombose.checked) {
      this.termoSelecionado.emit(this.rdTrombose.id);

      if (this.ckTransformacao && this.ckTransformacao.checked) {
        this.termoSelecionado.emit(this.ckTransformacao.id);
      }
      if (this.inCalibreTrombose && this.inCalibreTrombose.nativeElement.value) {
        const objeto = { elemento: this.inCalibreTrombose.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdNaoCaract.checked) {
      this.termoSelecionado.emit(this.rdNaoCaract.id);
      
      if (this.rdInterposicao && this.rdInterposicao.checked) {
        this.termoSelecionado.emit(this.rdInterposicao.id);
      } else if (this.rdEsteatose && this.rdEsteatose.checked) {
        this.termoSelecionado.emit(this.rdEsteatose.id);
      } else if (this.rdTransformacao && this.rdTransformacao.checked) {
        this.termoSelecionado.emit(this.rdTransformacao.id);
      } else {
        // Envia interposição gasosa como padrão
        this.termoSelecionado.emit(1117);
      }
    }

    this.listaCompleta.emit();
  }
}