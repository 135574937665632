import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule } from '@angular/forms';
import { TextEditorJsComponent } from './text-editor-js.component';
import { MatIconModule, MatMenuModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    AngularEditorModule,
    FormsModule,
    MatIconModule,
    MatMenuModule
  ],
  declarations: [TextEditorJsComponent],
  exports: [TextEditorJsComponent]
})
export class TextEditorJSmodule { }
