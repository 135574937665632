export class Pagamento {
  UserID: number;
  CardHash?: string;
  CardName?: string;
  ExpMonth?: string;
  ExpYear?: string;
  CVV?: string;
  Parcel?: string;
  AmountValue?: number;
  ListPlanID?: number[];
  CPF?: string;
  AddressUser?: EnderecoPagamento;
  Voucher?: String;
  Ip?: String;
};

export class EnderecoPagamento{
  address: string;
  number: number;
  complement: string;
  district: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
}

