import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-acetabulo',
  templateUrl: './acetabulo.component.html',
  styleUrls: ['./acetabulo.component.css']
})
export class AcetabuloComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdRaso') rdRaso: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickDefault(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked)
      this.termoSelecionado.emit(this.rdNormal.value);

    if (this.rdRaso.checked)
      this.termoSelecionado.emit(this.rdRaso.value);

    this.listaCompleta.emit();
  }

}
