import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-arcabouco-osseo',
  templateUrl: './arcabouco-osseo.component.html',
  styleUrls: ['./arcabouco-osseo.component.css']
})
export class ArcaboucoOsseoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;
  @ViewChild('ckFraturaDireita') ckFraturaDireita: MatCheckbox;
  @ViewChild('ckFraturaEsquerda') ckFraturaEsquerda: MatCheckbox;
  @ViewChild('ckFraturaBilateral') ckFraturaBilateral: MatCheckbox;
  @ViewChild('ckArtroseCoxofemoral') ckArtroseCoxofemoral: MatCheckbox;

  @ViewChild('slLateralidadeArtrose') slLateralidadeArtrose : MatSelect;
  @ViewChild('slNivelFraturaDireita') slNivelFraturaDireita : MatSelect;
  @ViewChild('slNivelFraturaEsquerda') slNivelFraturaEsquerda : MatSelect;
  @ViewChild('slNivelFraturaMultiplaDireita') slNivelFraturaMultiplaDireita : MatSelect;
  @ViewChild('slNivelFraturaMultiplaEsquerda') slNivelFraturaMultiplaEsquerda : MatSelect;

  showAndHideTerms;
  countLesoes = 0;
  ausente=true;

  lateralidadeArtrose: ArrayDropDown[]=[
    { value: ' direita', viewValue: 'Direita' },
    { value: ' esquerda', viewValue: 'Esquerda' },
    { value: ' bilateral', viewValue: 'Bilateral' }
  ]

  nivelFraturaEsquerda: ArrayDropDown[]=[
    { value: ' ílio', viewValue: 'Ílio' },
    { value: ' ísquio', viewValue: 'Ísquio' },
    { value: ' pubis', viewValue: 'Pubis' },
    { value: ' acetábulo', viewValue: 'Acetábulo' }
  ]

  nivelFraturaDireita: ArrayDropDown[]=[
    { value: ' ílio', viewValue: 'Ílio' },
    { value: ' ísquio', viewValue: 'Ísquio' },
    { value: ' pubis', viewValue: 'Pubis' },
    { value: ' acetábulo', viewValue: 'Acetábulo' }
  ]

  nivelFraturaBilateralEsquerda: ArrayDropDown[]=[
    { value: ' ílio', viewValue: 'Ílio' },
    { value: ' ísquio', viewValue: 'Ísquio' },
    { value: ' pubis', viewValue: 'Pubis' },
    { value: ' acetábulo', viewValue: 'Acetábulo' }
  ]

  nivelFraturaBilateralDireita: ArrayDropDown[]=[
    { value: ' ílio', viewValue: 'Ílio' },
    { value: ' ísquio', viewValue: 'Ísquio' },
    { value: ' pubis', viewValue: 'Pubis' },
    { value: ' acetábulo', viewValue: 'Acetábulo' }
  ]

  constructor() { }

  ngOnInit() {
      this.showAndHideTerms = {
        artroseCoxofemoral:false,
        fraturaDireita:false,
        fraturaEsquerda:false,
        fraturaBilateral:false
      }
    }

  clickDefault(){
    this.checkAlterado();
    this.catchIDs();
  }

  clickNormal(){
    this.showAndHideTerms.artroseCoxofemoral=false;
    this.showAndHideTerms.fraturaDireita=false;
    this.showAndHideTerms.fraturaEsquerda=false;
    this.showAndHideTerms.fraturaBilateral=false;

    this.ckArtroseCoxofemoral.checked=false;
    this.ckFraturaDireita.checked=false;
    this.ckFraturaEsquerda.checked=false;
    this.ckFraturaBilateral.checked=false;

    this.checkAlterado();
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  clickArtroseCoxofemoral(){
    this.showAndHideTerms.artroseCoxofemoral= !this.showAndHideTerms.artroseCoxofemoral;
    this.checkAlterado();
    this.catchIDs();
  }

  clickFraturaDireita(){
    this.showAndHideTerms.fraturaBilateral=false;
    this.ckFraturaBilateral.checked=false;

    this.showAndHideTerms.fraturaDireita= !this.showAndHideTerms.fraturaDireita;
    this.checkAlterado();
    this.catchIDs();
  }

  clickFraturaEsquerda(){
    this.showAndHideTerms.fraturaBilateral=false;
    this.ckFraturaBilateral.checked=false;

    this.showAndHideTerms.fraturaEsquerda= !this.showAndHideTerms.fraturaEsquerda;
    this.checkAlterado();
    this.catchIDs();
  }

  clickFraturaBilateral(){
    this.showAndHideTerms.fraturaDireita= false;
    this.showAndHideTerms.fraturaEsquerda= false;
    this.ckFraturaDireita.checked=false;
    this.ckFraturaEsquerda.checked=false;

    this.showAndHideTerms.fraturaBilateral= !this.showAndHideTerms.fraturaBilateral;
    this.checkAlterado();
    this.catchIDs();
  }

  checkAlterado(){
    if(this.ckArtroseCoxofemoral.checked || this.ckFraturaDireita.checked || this.ckFraturaEsquerda.checked || this.ckFraturaBilateral.checked ){
      this.rdAlterado.checked= true;
      this.rdNormal.checked=false;
    }else{
      this.rdNormal.checked=true;
      this.rdAlterado.checked=false;
    }
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if(this.rdNormal.checked){
      this.termoSelecionado.emit(this.rdNormal.id);
    }
    if(this.countLesoes > 0){
      this.emitArtroseLesao();
    } else {
      this.emitArtroseSimples();
    }
    if(this.countLesoes > 0){
      this.emitFraturaDireitaLesao();
    } else {
      this.emitFraturaDireitaSimples();
    }

    if(this.countLesoes > 0){
      this.emitFraturaEsquerdaLesao();
    } else {
      this.emitFraturaEsquerdaSimples();
    }

    if(this.countLesoes > 0){
      this.emitFraturaBilateralLesao();
    } else {
      this.emitFraturaBilateralSimples();
    }

    this.listaCompleta.emit();
  }

  emitArtroseLesao() {
    if(this.ckArtroseCoxofemoral.checked){
      this.countLesoes++;
      const objeto = { id: this.ckArtroseCoxofemoral.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLateralidadeArtrose && this.slLateralidadeArtrose.value){
        const objeto = {elemento: this.slLateralidadeArtrose, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitArtroseSimples() {
    if(this.ckArtroseCoxofemoral.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckArtroseCoxofemoral.id);

      if(this.slLateralidadeArtrose && this.slLateralidadeArtrose.value){
        const objeto = {elemento: this.slLateralidadeArtrose, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitFraturaBilateralSimples() {
    if(this.ckFraturaBilateral.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFraturaBilateral.id);

      if(this.slNivelFraturaMultiplaDireita && this.slNivelFraturaMultiplaDireita.value){
        if(this.slNivelFraturaMultiplaDireita.value.length>1){
          let objetoMultiplo= { id:this.slNivelFraturaMultiplaDireita.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaMultiplaDireita.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }else if(this.slNivelFraturaMultiplaDireita.value.length==1){
          const objeto = {elemento: this.slNivelFraturaMultiplaDireita, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }

      if(this.slNivelFraturaMultiplaEsquerda && this.slNivelFraturaMultiplaEsquerda.value){
        if(this.slNivelFraturaMultiplaEsquerda.value.length>1){

          let objetoMultiplo= { id:this.slNivelFraturaMultiplaEsquerda.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaMultiplaEsquerda.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelFraturaMultiplaEsquerda.value.length==1){
          const objeto = {elemento: this.slNivelFraturaMultiplaEsquerda, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitFraturaBilateralLesao() {
    if(this.ckFraturaBilateral.checked){
      this.countLesoes++;
      const objeto = { id: this.ckFraturaBilateral.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slNivelFraturaMultiplaDireita && this.slNivelFraturaMultiplaDireita.value){
        if(this.slNivelFraturaMultiplaDireita.value.length>1){
          let objetoMultiplo= { id:this.slNivelFraturaMultiplaDireita.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaMultiplaDireita.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelFraturaMultiplaDireita.value.length==1){

          const objeto = {elemento: this.slNivelFraturaMultiplaDireita, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }
      }

      if(this.slNivelFraturaMultiplaEsquerda && this.slNivelFraturaMultiplaEsquerda.value){
        if(this.slNivelFraturaMultiplaEsquerda.value.length>1){
          let objetoMultiplo= { id:this.slNivelFraturaMultiplaEsquerda.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaMultiplaEsquerda.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelFraturaMultiplaEsquerda.value.length==1){

          const objeto = {elemento: this.slNivelFraturaMultiplaEsquerda, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }
      }
    }
  }

  emitFraturaEsquerdaSimples() {
    if(this.ckFraturaEsquerda.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFraturaEsquerda.id);

      if(this.slNivelFraturaEsquerda && this.slNivelFraturaEsquerda.value ){
        if(this.slNivelFraturaEsquerda.value.length>1){

          let objetoMultiplo= { id:this.slNivelFraturaEsquerda.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaEsquerda.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelFraturaEsquerda.value.length==1){
          const objeto = {elemento: this.slNivelFraturaEsquerda, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitFraturaEsquerdaLesao() {
    if(this.ckFraturaEsquerda.checked){
      this.countLesoes++;
      const objeto = { id: this.ckFraturaEsquerda.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

    if(this.slNivelFraturaEsquerda && this.slNivelFraturaEsquerda.value){
        if(this.slNivelFraturaEsquerda.value.length>1){
          let objetoMultiplo= { id:this.slNivelFraturaEsquerda.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaEsquerda.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelFraturaEsquerda.value.length==1){

          const objeto = {elemento: this.slNivelFraturaEsquerda, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }
      }
    }
  }

  emitFraturaDireitaSimples() {
    if(this.ckFraturaDireita.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFraturaDireita.id);

      if(this.slNivelFraturaDireita && this.slNivelFraturaDireita.value){
        if(this.slNivelFraturaDireita.value.length>1){
          let objetoMultiplo= { id:this.slNivelFraturaDireita.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaDireita.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelFraturaDireita.value.length==1){

          const objeto = {elemento: this.slNivelFraturaDireita, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);

        }
      }
    }
  }

  emitFraturaDireitaLesao() {
    if(this.ckFraturaDireita.checked){
      this.countLesoes++;
      const objeto = { id: this.ckFraturaDireita.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slNivelFraturaDireita && this.slNivelFraturaDireita.value){
        if(this.slNivelFraturaDireita.value.length>1){
          let objetoMultiplo= { id:this.slNivelFraturaDireita.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaDireita.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelFraturaDireita.value.length==1){

          const objeto = {elemento: this.slNivelFraturaDireita, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }
      }
    }
  }

  replaceVirgulaMultSelect(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }
}

