import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatExpansionModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatTableDataSource,
  MatDialogModule,
  MatTooltipModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatSlideToggleModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from '../../../utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from '../../shared/shared.module';
import { CranioComponent } from './cranio.component';
import { VentriculosComponent } from './TermosComponents/ventriculos/ventriculos.component';
import { VasosComponent } from './TermosComponents/vasos/vasos.component';
import { TroncoCerebeloComponent } from './TermosComponents/tronco-cerebelo/tronco-cerebelo.component';
import { PartesMolesComponent } from './TermosComponents/partes-moles/partes-moles.component';
import { DispositivosComponent } from './TermosComponents/dispositivos/dispositivos.component';
import { EstruturaOsseasComponent } from './TermosComponents/estrutura-osseas/estrutura-osseas.component';
import { ParenquimaCranioComponent } from './TermosComponents/parenquima-cranio/parenquima-cranio.component';
import { AchadosCranioComponent } from './TermosComponents/achados-cranio/achados-cranio.component';
import { EfeitoExpansivoComponent } from './TermosComponents/efeito-expansivo/efeito-expansivo.component';
import { SulcosCisternasComponent } from './TermosComponents/sulcos-cisternas/sulcos-cisternas.component';
import { IndicacaoCranioComponent } from './TermosComponents/indicacao-cranio/indicacao-cranio.component';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';
@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule, 
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  declarations: [
    CranioComponent, 
    VentriculosComponent, 
    VasosComponent, 
    TroncoCerebeloComponent, 
    PartesMolesComponent,
    DispositivosComponent, 
    EstruturaOsseasComponent, 
    ParenquimaCranioComponent, 
    AchadosCranioComponent, 
    EfeitoExpansivoComponent,
    SulcosCisternasComponent,
    IndicacaoCranioComponent]
})
export class CranioModule { }
