import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MenuLateralComponent } from './menu-lateral.component';
import { MatSidenavModule, MatListModule, MatIconModule, MatBadgeModule, MatMenuModule } from '@angular/material';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule, 
    MatMenuModule
  ],
  exports: [MenuLateralComponent],
  declarations: [MenuLateralComponent]
})
export class MenuLateralModule { }
