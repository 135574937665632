import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-vascularizacao-parenquima',
  templateUrl: './vascularizacao-parenquima.component.html',
  styleUrls: ['./vascularizacao-parenquima.component.css']
})
export class VascularizacaoParenquimaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdCalcVasc') rdCalcVasc: MatRadioButton;

  @ViewChild('slGraus') slGraus: MatSelect;
  @ViewChild('slMama') slMama: MatSelect;

  graus: ArrayDropDown[] = [
    { value: 'incipientes', viewValue: 'Incipientes' },
    { value: 'esparsas', viewValue: 'Esparsas' },
    { value: 'difusas', viewValue: 'Difusas' }
  ];

  mamas: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];
  
  showAndHideTerms: any;
  slId: string;
  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCalcVas: false
    };
    this.clickrdNormal();
  }
  clickrdNormal() {
    this.showAndHideTerms.sahCalcVas = false;
    this.catchIDs();
  }

  clickCalcVasc() {
    this.showAndHideTerms.sahCalcVas = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdCalcVasc && this.rdCalcVasc.checked) {
      this.termoSelecionado.emit(this.rdCalcVasc.id);

      if (this.slGraus && this.slGraus.value) {
        const objeto = { elemento: this.slGraus, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slMama && this.slMama.value) {
        const objeto = { elemento: this.slMama, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
