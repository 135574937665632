
import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { MatRadioButton, MatSelect } from '@angular/material';
import { Validacoes } from 'src/app/utils/validacoes';


@Component({
  selector: 'app-placenta',
  templateUrl: './placenta.component.html',
  styleUrls: ['./placenta.component.css']
})
export class PlacentaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdSemMedida') rdSemMedida: MatRadioButton;
  @ViewChild('rdComMedida') rdComMedida: MatRadioButton;
  @ViewChild('inEspessura') inEspessura: ElementRef;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slGrau') slGrau: MatSelect;

  localizacoes: ArrayDropDown[] = [
    { value: 'anterior', viewValue: 'Anterior' },
    { value: 'posterior', viewValue: 'Posterior' },
    { value: 'fúndica', viewValue: 'Fúndica' },
    { value: 'lateral direita', viewValue: 'Lateral direita' },
    { value: 'lateral esquerda', viewValue: 'Lateral esquerda' }
  ];

  graus: ArrayDropDown[] = [
    { value: '0', viewValue: '0' },
    { value: 'I', viewValue: 'I' },
    { value: 'II', viewValue: 'II' },
    { value: 'III', viewValue: 'III' }
  ];

  showMedida = false;
  unidadeMedida: string;
  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  blurParametros(){
    this.catchIDs();
  }

  clickSemMedida(){
    this.showMedida = false;
    this.catchIDs();

  }
  clickComMedida(){
    this.showMedida = true;
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');


    if ((this.slLocalizacao && this.slLocalizacao.value) &&
        (this.slGrau && this.slGrau.value) &&
        (this.inEspessura && this.inEspessura.nativeElement.value)) {

      //com medida
      this.termoSelecionado.emit("1993");

      let objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
      objeto = { elemento: this.slGrau, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);

      const objetoElemento = {id: "1998", value: this._validacoes.formataDecimal(this.inEspessura.nativeElement.value)};
      const objetoCriado = { elemento: objetoElemento};
      this.termosGenericos.emit(objetoCriado);

    } else if ((this.slLocalizacao && this.slLocalizacao.value) &&
    (this.slGrau && this.slGrau.value)) {

      //sem medida
      this.termoSelecionado.emit("1994");

      let objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
      objeto = { elemento: this.slGrau, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    this.listaCompleta.emit();
  }


}
