import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-bursa-subacromial',
  templateUrl: './bursa-subacromial.component.html',
  styleUrls: ['./bursa-subacromial.component.css']
})
export class BursaSubacromialComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdBursite') rdBursite: MatRadioButton;
  @ViewChild('rdLeveBursite') rdLeveBursite: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickDefault() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdBursite.checked) {
      this.termoSelecionado.emit(this.rdBursite.value);
    }

    if (this.rdLeveBursite.checked) {
      this.termoSelecionado.emit(this.rdLeveBursite.value);
    }

    this.listaCompleta.emit();
  }
}
