import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ClipboardService } from 'ngx-clipboard';

@Injectable({
  providedIn: 'root'
})
export class CopyPasteEditor {
  importTextCopy: Subject<any> = new Subject();

  private textReport: string;

  constructor(
    private _clipBoardService: ClipboardService,
  ) { }

  copiaLaudo() {
    let text = '';
    let paragraphs = document.getElementsByClassName("ce-paragraph");

    if (paragraphs != undefined) {
      for (let i = 0; i < paragraphs.length; i++) {
        let reports: any;

        for (let x = 0; x < paragraphs[i].childNodes.length; x++) {
          reports = paragraphs[i].childNodes;

          let textContent: any;
          for (let y = 0; y < reports.length; y++) {
            textContent = reports[y].textContent;

            if (textContent != null) {
              text += textContent;
            }
          }

          if (
            reports.textContent != null
            && isNaN(parseInt(reports.textContent))
            && reports.textContent != undefined
          ) {
            text += reports.textContent;
          }
        }
      }
    }

    this.textReport = text;
    this.copyText(this.textReport)
  };

  copyFromId(id: string, isHtml: boolean) {
    let content;
    let element = document.getElementById(id);
    if(element === undefined || element === null) {
      element = document.getElementById('editor');
    }

    const paragraphs = Array.from(document.getElementsByClassName('ce-paragraph'));
    paragraphs.forEach(paragraph => {
      paragraph.parentElement.parentElement.style.removeProperty('background');
      paragraph.parentElement.parentElement.style.removeProperty('border-left');
    });

    if (this.ruleActive() && !isHtml) {
      this.copyFormated();
      this.copyFormated();

      content = element.innerText;
    } else {
      if (isHtml) {
        content = element.innerHTML;

        content = this.removeToolsToCopy(content);
      } else {
        content = element.innerText;
      }
    }

    this._clipBoardService.copy(content);
  }

  /**
   * @param contentHTML Essa vairável recebe o html do textEditorJS
   * aqui removemos o header com as ferramentas do textEditor na hora de copiar
  */
  removeToolsToCopy(contentHTML: string): string {
    const [textHTML,] = contentHTML.split('<div class="codex-editor-overlay');

    return textHTML;
  }

  copyText(blocks: string): void {
    this.importTextCopy.next(blocks);
  }

  getTextFromId(id: string) {
    let element = document.getElementById(id);
    if(element === undefined || element === null) {
      element = document.getElementById('editor');
    }

    const text = element.innerText;

    return text;
  }

  setTextFromID(id: string, text: string) {
    let element = document.getElementById(id);
    element.innerHTML = text;
  }

  getHtmlFromId(id: string) {
    let element = document.getElementById(id);
    if(element === undefined || element === null) {
      element = document.getElementById('editor');
    }

    const html = element.innerHTML;

    return html;
  }

  ruleActive() {
    let active = false;
    let rule = JSON.parse(localStorage.getItem('copyRule'));

    if (rule && rule.medicalReportRuleID === 3 && rule.active) {
      active = true;
    } else {
      active = false;
    }

    return active;
  }

  copyFormated() {
    setTimeout(() => {
      let range = document.createRange();

      const ceParagraph = document.getElementsByClassName('ce-paragraph')[0];
      if(ceParagraph !== null && ceParagraph !== undefined) {
        range.selectNode(document.getElementsByClassName("codex-editor__redactor")[0]);
      } else {
        range.selectNode(document.getElementsByClassName("angular-editor-textarea")[0]);
      }

      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    }, 100);
  }
}
