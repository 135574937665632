import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-mst-torax',
  templateUrl: './mst-torax.component.html',
  styleUrls: ['./mst-torax.component.css']
})
export class MstToraxComponent implements OnInit {
  @ViewChild('pulmoesAlt') pulmoesAlt: MatSelect;
  @ViewChild('formacaoCostalAlt') formacaoCostalAlt: MatSelect;
  @ViewChild('diafragmasAlt') diafragmasAlt: MatSelect;

  @ViewChild('pulmoesAlterado') pulmoesAlterado: ElementRef;
  @ViewChild('formacaoCostalAlterado') formacaoCostalAlterado: ElementRef;
  @ViewChild('diafragmasAlterado') diafragmasAlterado: ElementRef;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  showAndHideTerms: any;
  
  pulmoes: ArrayDropDown[] = [
    { value: 'homogêneos', viewValue: 'Homogêneos' },
    { value: 'com ecogenicidade habitual', viewValue: 'Com ecogenicidade habitual' },
    { value: 'Pulmões com formações císticas, podendo corresponder a malformação adenomatóide cística', viewValue: 'Presença de formações císticas' },
    { value: 'Sinais de dilatação da árvore brônquica', viewValue: 'Dilatação da árvore brônquica' },
    { value: 'Presença de derrame pleural bilateral', viewValue: 'Presença de efusão pleural bilateral' },
    { value: 'outras alterações: ', viewValue: 'Outras alterações' },
  ];

  formacaoCostal: ArrayDropDown[] = [
    { value: 'simétrica e proporcional', viewValue: 'Simétrica e proporcional' },
    { value: 'alteradas: ', viewValue: 'Alteradas' },
  ];

  diafragmas: ArrayDropDown[] = [
    { value: 'identificado', viewValue: 'Identificado' },
    { value: 'Presença de hérnia', viewValue: 'Presença de hérnia diafragmática' },
    { value: 'outras alterações: ', viewValue: 'Outras alterações' },
  ];
  
  constructor() { }

  ngOnInit() {
    this.pulmoesAlt.value = this.pulmoes[0].value;
    this.formacaoCostalAlt.value = this.formacaoCostal[0].value;
    this.diafragmasAlt.value = this.diafragmas[0].value;
    
    this.showAndHideTerms = {
      sahPulmoes: false,
      sahFormacaoCostal: false,
      sahDiafragmas: false,
    };
  }

  verificaAlterado() {
    if (this.pulmoesAlt.value === 'outras alterações: ') {
      this.showAndHideTerms.sahPulmoes = true;
    } else {
      this.showAndHideTerms.sahPulmoes = false;
    }

    if (this.formacaoCostalAlt.value === 'alteradas: ') {
      this.showAndHideTerms.sahFormacaoCostal = true;
    } else {
      this.showAndHideTerms.sahFormacaoCostal = false;
    }

    if (this.diafragmasAlt.value === 'outras alterações: ') {
      this.showAndHideTerms.sahDiafragmas = true;
    } else {
      this.showAndHideTerms.sahDiafragmas = false;
    } 
  }

  blur() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    let elemento;

    if (this.pulmoesAlt && this.pulmoesAlt.value) {
      if (this.pulmoesAlterado && this.pulmoesAlt.value === 'outras alterações: ')
        elemento = { id: this.pulmoesAlt.id, value: this.pulmoesAlterado.nativeElement.value };
      else
        elemento = { id: this.pulmoesAlt.id, value: this.pulmoesAlt.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.formacaoCostalAlt && this.formacaoCostalAlt.value) {
      
      if (this.formacaoCostalAlterado && this.formacaoCostalAlt.value === 'alteradas: ')
        elemento = { id: this.formacaoCostalAlt.id, value: this.formacaoCostalAlterado.nativeElement.value };
      else
        elemento = { id: this.formacaoCostalAlt.id, value: this.formacaoCostalAlt.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.diafragmasAlt && this.diafragmasAlt.value) {
      
      if (this.diafragmasAlterado && this.diafragmasAlt.value === 'outras alterações: ')
        elemento = { id: this.diafragmasAlt.id, value: this.diafragmasAlterado.nativeElement.value };
      else
        elemento = { id: this.diafragmasAlt.id, value: this.diafragmasAlt.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    this.listaCompleta.emit();
  }
}
