import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-pele-tecido-penis',
  templateUrl: './pele-tecido-penis.component.html',
  styleUrls: ['./pele-tecido-penis.component.css']
})
export class PeleTecidoPenisComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdEdema') rdEdema: MatRadioButton;
  
  
  showAndHideTerms: any;


  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
    };
  }

  clickDefault() {
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdEdema.checked) {
      this.termoSelecionado.emit(this.rdEdema.id);
    }
    
    this.listaCompleta.emit();
  }

}
