import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ToastrManager } from "ng6-toastr-notifications";
import { MedicalReportConfig } from "../models/BO/MedicalReportConfig";
import { Versao } from "../models/BO/Versao";
import { ConfigLaudoService } from "../services/config-laudo.service";
import { UserService } from "../services/user.service";
import { VersaoService } from "../services/versao.service";
import * as moment from 'moment';

@Component({
  selector: 'app-versao',
  templateUrl: './versao.component.html',
  styleUrls: ['./versao.component.css', '../app.component.css']
})
export class VersaoComponent implements OnInit {
  formConfig: FormGroup;
  $usuario: any;
  $config: any;
  gravar: string = "Salvar";
  versao: string;
  desc: string;
  release: string;
  bug: string;
  public loading: boolean = true;
  data: string;

  constructor(
    private _versaoService: VersaoService,
    private toastr: ToastrManager,
  ) { }

 ngOnInit() {
  this.carregaVersao();
  }
  ngAfterViewInit(){
   this.$usuario = new Object();

  }

  carregaVersao() {
  
   this._versaoService.getVersao().toPromise().then((version) => {
    if (version) {
      console.log(version);
      this.versao = version[0].version;
      this.desc = version[0].description;
      this.release = version[0].releases;
      this.bug = version[0].bugs;
      console.log(this.versao+' - '+this.desc+' - '+this.release+' - '+this.bug);
    } 

    if (version != null)
      this.loading = false;
  });
  }
}
