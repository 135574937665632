import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { PagamentoService } from 'src/app/services/pagamento.service';
import { EnderecoPagamento, Pagamento } from 'src/app/models/BO/Pagamento';
import { StatusPagamentoService } from '../../services/status-pagamento.service';
import { ViaCepServiceService } from 'src/app/services/via-cep-service.service';
import { Endereco } from 'src/app/models/BO/Endereco';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatSelect, MatDialog } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { finalize } from 'rxjs/operators';
import { AlertDialogComponent } from 'src/app/utils/dialog/alert-dialog/alert-dialog.component';
import { Validacoes } from '../../utils/validacoes';

@Component({
  selector: 'app-pagamentos',
  templateUrl: './pagamentos.component.html',
  styleUrls: ['./pagamentos.component.css']
})
export class PagamentosComponent implements OnInit {
  @ViewChild('inNome') inNome: ElementRef;
  @ViewChild('inNumero') inNumero: ElementRef;
  @ViewChild('inExpiracao') inExpiracao: ElementRef;
  @ViewChild('inCVV') inCVV: ElementRef;
  @ViewChild('inCPF') inCPF: ElementRef;
  @ViewChild('slParcelamento') slParcelamento: MatSelect;

  @ViewChild('inEndRua') inEndRua: ElementRef;
  @ViewChild('inEndNumero') inEndNumero: ElementRef;
  @ViewChild('inEndComplem') inEndComplem: ElementRef;
  @ViewChild('inEndBairro') inEndBairro: ElementRef;
  @ViewChild('inEndCidade') inEndCidade: ElementRef;
  @ViewChild('inEndEstado') inEndEstado: ElementRef;
  @ViewChild('inEndPais') inEndPais: ElementRef;
  @ViewChild('inEndCEP') inEndCEP: ElementRef;

  @Output() retornoPagamento = new EventEmitter();
  @Output() mostrarPagamento = new EventEmitter();
  @Input() planosSelecionados: any;

  quantidades: ArrayDropDown[] = [
    { value: '01', viewValue: '1x sem juros' },
    { value: '2', viewValue: '2x sem juros' },
    { value: '3', viewValue: '3x sem juros' },
    { value: '4', viewValue: '4x sem juros' }
  ];

  retornoPag: any;
  cartaoHash: string;
  endereco: Endereco;
  showParcelamento: boolean;
  parcelamento: string = '';
  loading: boolean;
  parcelaSelected: string;
  public formPagamentos: FormGroup;
  public formSubmitAttempt: Boolean;

  // messages: any = { validDate: 'valid\ndate', monthYear: 'mm/yyyy' };
  // placeholders: any = { number: '•••• •••• •••• ••••', name: 'Full Name', expiry: '••/••', cvc: '•••' };
  // masks: any;

  constructor(private _servicePagamentos: PagamentoService,
    private _viaCEPService: ViaCepServiceService,
    private _statusPgto: StatusPagamentoService,
    private toastr: ToastrManager,
    private _dialog: MatDialog,
    private fb: FormBuilder,
    private utils: Validacoes,
  ) { }

  ngOnInit() {
    this.formPagamentos = this.fb.group({

      inNumero: ['', [Validators.required,
      Validators.maxLength(20),
      Validators.minLength(14)]],
      cardNome: ['', Validators.required],
      exp: ['', Validators.required],
      cvv: ['', [Validators.required,
      Validators.maxLength(3),
      Validators.minLength(3)]],
      cpf: ['', [Validators.required,
      Validators.maxLength(14),
      Validators.minLength(11)]],
      cep: ['', [Validators.required,
      Validators.minLength(8),
      Validators.maxLength(8)
      ]],
      rua: ['', Validators.required],
      numero: ['', [Validators.required,
      Validators.maxLength(10)]],
      complemento: [null],
      distrito: ['', Validators.required],
      cidade: ['', Validators.required],
      estado: ['', Validators.required],
      paises: ['', Validators.required],
    });
    this.loading = false;
    this.showParcelamento = false;
    this.parcelaSelected = this.quantidades[0].value;
    this.mostrarPacelamento();
  }

  isFieldInvalid(field: string) {
    return (
      (!this.formPagamentos.get(field).valid && this.formPagamentos.get(field).touched) ||
      (this.formPagamentos.get(field).untouched && this.formSubmitAttempt)
    );
  }

  criptografarCartao() {
    const cartaoSemEspaco = this.inNumero.nativeElement.value.replace(/\s/g, '');
    if (cartaoSemEspaco) {
      this._servicePagamentos.getCartaoCriptografado(cartaoSemEspaco).subscribe(
        retorno => {
          this.cartaoHash = retorno.number_token;
          // this.cartaoHash = cartaoSemEspaco;
        }
      );
    }
  }

  mostrarPacelamento() {
    this.planosSelecionados =
      this.planosSelecionados.selec.forEach(plano => {
        this.showParcelamento = plano.isAnual;
      });
  }

  //! Método somente para menos de dois planos
  recebePlano(planos: any) {
    this.planosSelecionados = planos;
    this.mostrarPacelamento();
  }

  changeSelect() {
    if (this.slParcelamento && this.slParcelamento.value) {
      this.parcelamento = this.slParcelamento.value.toString();
    } else {
      this.parcelamento = '';
    }
  }

  clickConfirmar() {
    const pag = this.populaDadosForm();

    this.loading = true;
    this._servicePagamentos.registrarPagamento(pag)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        retPgto => {
          if (retPgto) {
            this.retornoPag = retPgto;
            this._statusPgto.emitStatusPgto(this.retornoPag.statusID);
            this.retornoPagamento.emit(this.retornoPag.statusID);
          }
        },
        erro => {
          this.toastr.errorToastr('Erro ao processar pagamento', 'Desculpe',
            {
              position: 'top-right',
              toastTimeout: 4000
            }
          );
        }
      );

  }

  clickTrial() {
    const pag = this.populaDadosForm();

    this.loading = true;
    this._servicePagamentos.registrarTrial(pag)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        retPgto => {
          if (retPgto) {
            this.retornoPag = retPgto;
            this._statusPgto.emitStatusPgto(this.retornoPag.statusID);
            this.retornoPagamento.emit(this.retornoPag.statusID);
          }
        },
        erro => {
          console.log(erro)
          this.toastr.errorToastr('Erro ao processar requisição tente novamente', 'Desculpe',
            {
              position: 'top-right',
              toastTimeout: 4000
            }
          );
        }
      );
  }

  public populaDadosForm() {

    const pag = new Pagamento();
    this.criptografarCartao();

    const planos = [];


    this.planosSelecionados.selec.forEach(plano => {
      if (plano.id !== 2)
        planos.push(plano.id);
    });

    if (!pag) return;

    this.formPagamentos.patchValue({
      amout: pag.AmountValue = this.planosSelecionados.total,
      cvv: pag.CVV = this.inCVV.nativeElement.value,
      card: pag.CardHash = this.cartaoHash,
      cardNome: pag.CardName = this.inNome.nativeElement.value,
      parcel: pag.Parcel = this.parcelamento,
      user: pag.UserID = + localStorage.getItem('userID'),
      exp: this.inExpiracao.nativeElement.value,
      expMo: pag.ExpMonth = this.inExpiracao.nativeElement.value.substring(0, 2),
      expYe: pag.ExpYear = this.inExpiracao.nativeElement.value.substring(3, 5),
      list: pag.ListPlanID = planos,
      cpf: pag.CPF = this.inCPF.nativeElement.value.replace(/\D/g, ''),
      addre: pag.AddressUser = new EnderecoPagamento(),
      rua: pag.AddressUser.address = this.inEndRua.nativeElement.value,
      numero: pag.AddressUser.number = this.inEndNumero.nativeElement.value,
      complemento: pag.AddressUser.complement = this.inEndComplem.nativeElement.value,
      distrito: pag.AddressUser.district = this.inEndBairro.nativeElement.value,
      cidade: pag.AddressUser.city = this.inEndCidade.nativeElement.value,
      estado: pag.AddressUser.state = this.inEndEstado.nativeElement.value,
      paises: pag.AddressUser.country = this.inEndPais.nativeElement.value,
      cep: pag.AddressUser.zipCode = this.inEndCEP.nativeElement.value.replace(/\D/g, ''),
    });

    return pag;
  }

  espacamentoCartao(value: string) {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length > 0) {
      this.inNumero.nativeElement.value = parts.join(' ');
    } else {
      this.inNumero.nativeElement.value = value;
    }
  }

  formatDataExp(event) {
    var inputChar = String.fromCharCode(event.keyCode);
    var code = event.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }

    event.target.value = event.target.value.replace(
      /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
    ).replace(
      /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
    ).replace(
      /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
    ).replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
    ).replace(
      /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
    ).replace(
      /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
    ).replace(
      /\/\//g, '/' // Prevent entering more than 1 `/`
    );
  }


  buscaCEP() {
    let cep = this.formPagamentos.get('cep').value;

    if (cep !== '') {
      if (this.verificaCEP(cep)) {
        // this.toastr.infoToastr('Buscando CEP...',"CEP OK!!!");
        this._viaCEPService.getEnderecoPorCEP(cep)
          .subscribe(
            address => {
              if (address.erro === true) {
                this.endereco = undefined;
                this.toastr.errorToastr('CEP não encontrado', 'Desculpe',
                  {
                    position: 'top-right',
                    toastTimeout: 4000
                  }
                );
                this.limpaDadosEndereco();
              } else {
                this.endereco = address;
                this.populaDadosFormCEP(address);
                this.toastr.successToastr('Endereço encontrado', 'Tudo OK',
                  {
                    position: 'top-right',
                    toastTimeout: 4000
                  }
                );
              }
            },
            error => {
              this.toastr.errorToastr('Ha algo de errado no CEP digitado.', 'Desculpe',
                {
                  position: 'top-right',
                  toastTimeout: 4000
                }
              );
              this.endereco = undefined;
              this.limpaDadosEndereco();
            }
          );
      } else {
        this.toastr.errorToastr('Digite um CEP válido', 'Desculpe',
          {
            position: 'top-right',
            toastTimeout: 4000
          }
        );
        this.endereco = undefined;
      }
    }
  }


  checkCPF(): Boolean {
    const cpf = this.formPagamentos.get('cpf').value;
    if (!this.utils.validarCPF(cpf)) {
      this.toastr.errorToastr('CPF inválido', 'Falhou!!',
        {
          position: 'top-right',
          toastTimeout: 4000
        }
      );
      this.formPagamentos.patchValue({
        cpf: null
      });
      return false;
    } else {
      return true;
    }
  }

  limpaDadosEndereco() {
    this.formPagamentos.patchValue({
      rua: null,
      numero: null,
      complemento: null,
      distrito: null,
      cidade: null,
      estado: null,
      paises: null,
      // cep: null
    });
  }

  populaDadosFormCEP(dados: Endereco) {
    this.formPagamentos.patchValue({

      rua: dados.logradouro,
      distrito: dados.bairro,
      cidade: dados.localidade,
      estado: dados.uf,
      paises: 'Brasil',
      // cep: dados.cep
    });
  }

  verificaCEP(zipcode: string): boolean {
    if (zipcode.length === 8) {
      return true;
    }

    return false;
  }

  maskCPF(cpf: string) {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

    this.inCPF.nativeElement.value = cpf;
  }

  maskCep(cep: string) {
    cep = cep.replace(/\D/g, "");
    cep = cep.replace(/[^\w\s]/gi, "");

    this.inEndCEP.nativeElement.value = cep;
  }

  // public clickInfoTrial() {
  //   const mensage = 'info|O plano trial garante 90 dias grátis do modulo selecionado, após isso você será cobrado automaticamente';
  //   this.alertStatusMensage(mensage);
  // }

  fechar() {
    return this.mostrarPagamento.emit(false);
  }

  alertStatusMensage(mensage: string) {
    const dialogRef = this._dialog.open(AlertDialogComponent, {
      width: '450px',
      data: mensage
    });
  }

}
