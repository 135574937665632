import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Injuries, Term } from '../../../../models/BO/Term';
import { Validacoes } from '../../../../utils/validacoes';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { TermResult } from '../../../../models/BO/TermResult';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-venoso-membro-superior',
  templateUrl: './venoso-membro-superior.component.html',
  styleUrls: ['./venoso-membro-superior.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
})
export class VenosoMembroSuperiorComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[2504],blockID:220},
    {listTerm:[2515],blockID:221},
    {listTerm:[2526],blockID:222},
    {listTerm:[2534],blockID:223},
    {listTerm:[2542],blockID:224},
    {listTerm:[2550],blockID:225},
    {listTerm:[2556],blockID:226},
    {listTerm:[2562],blockID:227},
  ]

  htmlContent: string;
  mask: string;
  Subtitulo: string;
  subtituloBloco: string;
  term: any = new Term();
  injuries = new Injuries();
  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  public generoLateralidade: string;
  public Lado = '';


  cabecalho = false;
  VeiasRadiais = false;
  VeiasUlnares = false;
  VeiasBraquiais = false;
  VeiaAxilar = false;
  VeiaSubclavia = false;
  VeiaCefalica = false;
  VeiaBasilica = false;
  public EdemaSuperior: Boolean = false;
  public achadosTexto: Boolean = false;
  public rodape: Boolean = false;
  public rodapeTexto: any = '';
  indicacao = false;
  lateralidade = true;
  cabecalhoTexto = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  editLaudoMode: boolean = false;
  medicalReportTypeID: number = 42;

  VeiasRadiaisId = 220;
  VeiasUlnaresId = 221;
  VeiasBraquiaisId = 222;
  VeiaAxilarId = 223;
  VeiaSubclaviaId = 224;
  VeiaCefalicaId = 225;
  VeiaBasilicaId = 226;
  AchadosAdicionaisId = 227;
  public switchFormReport = true;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];

  constructor(
    private _selecteditememiter: SelectedItemEmiter,
    private _toastr: ToastrManager,
    private _copyPaste: CopyPaste,
    private _validacoes: Validacoes,
    private _laudoService: LaudosService,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public dialog: MatDialog,
    public _addText: AddText,
    private _config: ConfigLaudoService,
    private dataService:DataTermService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("venoso-membro-superior-dopplerAnalise", this.descricoes, this.opiniao);
    this.resetaDialog();
    this.Subtitulo = 'Lateralidade';
    this.subtituloBloco = 'Lateralidade';
    this.generoLateralidade = 'masculino';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [
      this.VeiasRadiaisId,
      this.VeiasUlnaresId,
      this.VeiasBraquiaisId,
      this.VeiaAxilarId,
      this.VeiaSubclaviaId,
      this.VeiaCefalicaId,
      this.VeiaBasilicaId,
      this.AchadosAdicionaisId
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "venoso-membro-superior-dopplerMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "tvpsuperior");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-venoso-superior-doppler');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-venoso-superior-doppler');
      }
    });
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("venoso-membro-superior-dopplerAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.opiniao[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.Lado = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();

    this.genericRadial = [
      { value: '', selected: false, termID: 2506, term: '[motivo]' },
      { value: '', selected: false, termID: 2508, term: '[localização]' },
      { value: '', selected: false, termID: 2510, term: '[localização]' },
      { value: '', selected: false, termID: 2511, term: '[calibre]' },
      { value: '', selected: false, termID: 2513, term: '[localização]' },
      { value: '', selected: false, termID: 2514, term: '[calibre]' },
    ];
    this.genericUlnares = [
      { value: '', selected: false, termID: 2517, term: '[motivo]' },
      { value: '', selected: false, termID: 2519, term: '[localização]' },
      { value: '', selected: false, termID: 2521, term: '[localização]' },
      { value: '', selected: false, termID: 2522, term: '[calibre]' },
      { value: '', selected: false, termID: 2524, term: '[localização]' },
      { value: '', selected: false, termID: 2525, term: '[calibre]' },
    ];
    this.genericBraquiais = [
      { value: '', selected: false, termID: 2528, term: '[motivo]' },
      { value: '', selected: false, termID: 2531, term: '[calibre]' },
      { value: '', selected: false, termID: 2533, term: '[calibre]' },
      { value: '', selected: false, termID: 4036, term: '[localizacao]' },
    ];

    this.genericAxilar = [
      { value: '', selected: false, termID: 2536, term: '[motivo]' },
      { value: '', selected: false, termID: 2539, term: '[calibre]' },
      { value: '', selected: false, termID: 2541, term: '[calibre]' },
    ];

    this.genericSubclavia = [
      { value: '', selected: false, termID: 2544, term: '[motivo]' },
      { value: '', selected: false, termID: 2547, term: '[calibre]' },
      { value: '', selected: false, termID: 2549, term: '[calibre]' },
    ];

    this.genericCefalica = [
      { value: '', selected: false, termID: 2552, term: '[motivo]' },
      { value: '', selected: false, termID: 2554, term: '[terço]' },
      { value: '', selected: false, termID: 2555, term: '[localização]' },
    ];

    this.genericBasilica = [
      { value: '', selected: false, termID: 2558, term: '[motivo]' },
      { value: '', selected: false, termID: 2560, term: '[terço]' },
      { value: '', selected: false, termID: 2561, term: '[localização]' },
    ];

    this.genericEdema = [
      { value: '', selected: false, termID: 2563, term: '[lâminas líquidas]' },
      { value: '', selected: false, termID: 2564, term: '[localização face]' },
      { value: '', selected: false, termID: 2565, term: '[localização]' },
    ];

  }

  public blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Lateralidade',
      id: 'lateralidade',
      name: 'lateralidade',
      selected: true,
    },
    {
      id: this.VeiasRadiaisId,
      name: 'VeiasRadiais',
      titulo: 'Veias Radiais',
      selected: false
    },
    {
      id: this.VeiasUlnaresId,
      name: 'VeiasUlnares',
      titulo: 'Veias Ulnares',
      selected: false,
    },
    {
      id: this.VeiasBraquiaisId,
      name: 'VeiasBraquiais',
      titulo: 'Veias Braquiais',
      selected: false,
    },
    {
      id: this.VeiaAxilarId,
      name: 'VeiaAxilar',
      titulo: 'Veia Axilar',
      selected: false,
    },
    {
      id: this.VeiaSubclaviaId,
      name: 'VeiaSubclavia',
      titulo: 'Veia Subclávia',
      selected: false,
    },
    {
      id: this.VeiaCefalicaId,
      name: 'VeiaCefalica',
      titulo: 'Veia Cefálica',
      selected: false,
    },
    {
      id: this.VeiaBasilicaId,
      name: 'VeiaBasilica',
      titulo: 'Veia Basílica',
      selected: false,
    },
    {
      id: this.AchadosAdicionaisId,
      name: 'edema',
      titulo: 'edema',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  public descricoes: DescricaoConclusao[] = [
    { value: '<b>Veias radiais</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler.  ', blockID: this.VeiasRadiaisId, textTermID: 0, visivel: true },
    { value: '<b>Veias ulnares</b> de calibre, compressibilidade e fluxo vascular  preservados ao estudo Doppler.', blockID: this.VeiasUlnaresId, textTermID: 0, visivel: true },
    { value: '<b>Veias braquiais</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler.', blockID: this.VeiasBraquiaisId, textTermID: 0, visivel: true },
    { value: '<b>Veia axilar</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler.', blockID: this.VeiaAxilarId, textTermID: 0, visivel: true },
    {
      value: '<b>Veia subclávia</b> nos segmentos avaliados de calibre e fluxo vascular preservados ao estudo Doppler.', blockID: this.VeiaSubclaviaId, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veia cefálica</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler.', blockID: this.VeiaCefalicaId, textTermID: 0, visivel: true
    },
    {
      value: '<b>Veia basílica</b> de calibre, compressibilidade e fluxo vascular preservados ao estudo Doppler.', blockID: this.VeiaBasilicaId, textTermID: 0, visivel: true
    },
    {
      value: '', blockID: this.AchadosAdicionaisId, textTermID: 0, visivel: true
    }
  ];

  public opiniao: DescricaoConclusao[] = [
    {
      value: 'Não há sinais de trombose venosa.',
      blockID: 0, textTermID: 0, visivel: true
    },
    { value: '', blockID: this.VeiasRadiaisId, textTermID: 0, visivel: true },
    { value: '', blockID: this.VeiasUlnaresId, textTermID: 0, visivel: true },
    { value: '', blockID: this.VeiasBraquiaisId, textTermID: 0, visivel: true },
    { value: '', blockID: this.VeiaAxilarId, textTermID: 0, visivel: true },
    { value: '', blockID: this.VeiaSubclaviaId, textTermID: 0, visivel: true },
    { value: '', blockID: this.VeiaCefalicaId, textTermID: 0, visivel: true },
    { value: '', blockID: this.VeiaBasilicaId, textTermID: 0, visivel: true },
    { value: '', blockID: this.AchadosAdicionaisId, textTermID: 0, visivel: true },
  ];

  genericRadial: TermosGenericos[] = [
    { value: '', selected: false, termID: 2506, term: '[motivo]' },
    { value: '', selected: false, termID: 2508, term: '[localização]' },
    { value: '', selected: false, termID: 2510, term: '[localização]' },
    { value: '', selected: false, termID: 2511, term: '[calibre]' },
    { value: '', selected: false, termID: 2513, term: '[localização]' },
    { value: '', selected: false, termID: 2514, term: '[calibre]' },
  ];

  genericUlnares: TermosGenericos[] = [
    { value: '', selected: false, termID: 2517, term: '[motivo]' },
    { value: '', selected: false, termID: 2519, term: '[localização]' },
    { value: '', selected: false, termID: 2521, term: '[localização]' },
    { value: '', selected: false, termID: 2522, term: '[calibre]' },
    { value: '', selected: false, termID: 2524, term: '[localização]' },
    { value: '', selected: false, termID: 2525, term: '[calibre]' },
  ];
  genericBraquiais: TermosGenericos[] = [
    { value: '', selected: false, termID: 2528, term: '[motivo]' },
    { value: '', selected: false, termID: 2531, term: '[calibre]' },
    { value: '', selected: false, termID: 2533, term: '[calibre]' },
    { value: '', selected: false, termID: 4036, term: '[localizacao]' },
  ];

  genericAxilar: TermosGenericos[] = [
    { value: '', selected: false, termID: 2536, term: '[motivo]' },
    { value: '', selected: false, termID: 2539, term: '[calibre]' },
    { value: '', selected: false, termID: 2541, term: '[calibre]' },
  ];

  genericSubclavia: TermosGenericos[] = [
    { value: '', selected: false, termID: 2544, term: '[motivo]' },
    { value: '', selected: false, termID: 2547, term: '[calibre]' },
    { value: '', selected: false, termID: 2549, term: '[calibre]' },
  ];

  genericCefalica: TermosGenericos[] = [
    { value: '', selected: false, termID: 2552, term: '[motivo]' },
    { value: '', selected: false, termID: 2554, term: '[calibre]' },
    { value: '', selected: false, termID: 2555, term: '[calibre]' },
  ];

  genericBasilica: TermosGenericos[] = [
    { value: '', selected: false, termID: 2558, term: '[motivo]' },
    { value: '', selected: false, termID: 2560, term: '[calibre]' },
    { value: '', selected: false, termID: 2561, term: '[calibre]' },
  ];

  genericEdema: TermosGenericos[] = [
    { value: '', selected: false, termID: 2563, term: '[lâminas líquidas]' },
    { value: '', selected: false, termID: 2564, term: '[localização face]' },
    { value: '', selected: false, termID: 2565, term: '[localização]' },
  ];


  public selecionar(itemSelecionado): any {
    const arrayAtualizado = this._selecteditememiter.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.VeiasRadiais = (itemSelecionado.id === this.VeiasRadiaisId);
    this.VeiasUlnares = (itemSelecionado.id === this.VeiasUlnaresId);
    this.VeiasBraquiais = (itemSelecionado.id === this.VeiasBraquiaisId);
    this.VeiaAxilar = (itemSelecionado.id === this.VeiaAxilarId);
    this.VeiaSubclavia = (itemSelecionado.id === this.VeiaSubclaviaId);
    this.VeiaCefalica = (itemSelecionado.id === this.VeiaCefalicaId);
    this.VeiaBasilica = (itemSelecionado.id === this.VeiaBasilicaId);
    this.EdemaSuperior = (itemSelecionado.id === this.AchadosAdicionaisId);
    this.lateralidade = (itemSelecionado.id === 'lateralidade');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');

    this.AdicionaTexto(this.newEditor);
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor(){
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    if(this.indicacaoTexto){
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-indicacao`,
          'name': ''
        },
        'data': {
          'text': `<p>${this.indicacaoTexto}</p>`,
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]+this.Lado.toUpperCase()}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.opiniao.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoes[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });
  }

  public adicionaTextoOldEditor(): any {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.Lado.toUpperCase();
    this.htmlContent +=  maskSplit[1];
    this.htmlContent += this.indicacaoTexto;
     //Lógica Rules
     if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
     this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContent +=  maskSplit[2];

    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
    }

    this.htmlContent +=  maskSplit[3];
    for (i = 0; i < this.opiniao.length; i++) {
      if (this.opiniao[i].value) {
        this.htmlContent += '<br/>' + this.opiniao[i].value + '<br/>';
      }
    }

    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
    lado
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.opiniao.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.opiniao.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId} p`);

          if(header) {
            header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {

          const footer = document.querySelector(`#e-${blockId} p`);

          if(footer) {
            footer.innerHTML = rodape;
          }
        }

			} else if (indicacao || achados) {
				if(indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.VeiasRadiais) {
        this.term.blockID = this.VeiasRadiaisId;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.VeiasRadiaisId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoRadial(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.VeiasRadiaisId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiasRadiaisId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoRadial(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.opiniao.filter(x => x.value !== '');
          const conclusaoNull = this.opiniao.find(x => x.blockID === 0);
          const conclusao = this.opiniao.find(x => x.blockID === this.VeiasRadiaisId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiasRadiaisId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.VeiasRadiaisId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.VeiasUlnares) {
        this.term.blockID = this.VeiasUlnaresId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.VeiasUlnaresId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoUlnar(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.VeiasUlnaresId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiasUlnaresId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoUlnar(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.opiniao.filter(x => x.value !== '');
          const conclusaoNull = this.opiniao.find(x => x.blockID === 0);
          const conclusao = this.opiniao.find(x => x.blockID === this.VeiasUlnaresId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiasUlnaresId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.VeiasUlnaresId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.VeiasBraquiais) {
        this.term.blockID = this.VeiasBraquiaisId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.VeiasBraquiaisId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoBraquiais(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.VeiasBraquiaisId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiasBraquiaisId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoBraquiais(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.opiniao.filter(x => x.value !== '');
          const conclusaoNull = this.opiniao.find(x => x.blockID === 0);
          const conclusao = this.opiniao.find(x => x.blockID === this.VeiasBraquiaisId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiasBraquiaisId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.VeiasBraquiaisId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.VeiaAxilar) {
        this.term.blockID = this.VeiaAxilarId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaAxilarId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoAxilar(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.VeiaAxilarId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaAxilarId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoAxilar(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.opiniao.filter(x => x.value !== '');
          const conclusaoNull = this.opiniao.find(x => x.blockID === 0);
          const conclusao = this.opiniao.find(x => x.blockID === this.VeiaAxilarId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaAxilarId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.VeiaAxilarId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.VeiaSubclavia) {
        this.term.blockID = this.VeiaSubclaviaId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaSubclaviaId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoSubclavia(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.VeiaSubclaviaId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaSubclaviaId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoSubclavia(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.opiniao.filter(x => x.value !== '');
          const conclusaoNull = this.opiniao.find(x => x.blockID === 0);
          const conclusao = this.opiniao.find(x => x.blockID === this.VeiaSubclaviaId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaSubclaviaId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.VeiaSubclaviaId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.VeiaCefalica) {
        this.term.blockID = this.VeiaCefalicaId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaCefalicaId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoCefalica(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.VeiaCefalicaId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaCefalicaId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoCefalica(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.opiniao.filter(x => x.value !== '');
          const conclusaoNull = this.opiniao.find(x => x.blockID === 0);
          const conclusao = this.opiniao.find(x => x.blockID === this.VeiaCefalicaId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaCefalicaId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.VeiaCefalicaId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.VeiaBasilica) {
        this.term.blockID = this.VeiaBasilicaId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.VeiaBasilicaId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoBasilica(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.VeiaBasilicaId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.VeiaBasilicaId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoBasilica(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.opiniao.filter(x => x.value !== '');
          const conclusaoNull = this.opiniao.find(x => x.blockID === 0);
          const conclusao = this.opiniao.find(x => x.blockID === this.VeiaBasilicaId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.VeiaBasilicaId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.VeiaBasilicaId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.EdemaSuperior) {
        this.term.blockID = this.AchadosAdicionaisId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.AchadosAdicionaisId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoEdema(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.AchadosAdicionaisId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.AchadosAdicionaisId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoEdema(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.opiniao.filter(x => x.value !== '');
          const conclusaoNull = this.opiniao.find(x => x.blockID === 0);
          const conclusao = this.opiniao.find(x => x.blockID === this.AchadosAdicionaisId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.AchadosAdicionaisId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }
          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.AchadosAdicionaisId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  MontaDescricaoRadial(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 2506 || +term === 2508 || +term === 2510 || +term === 2511 ||
        +term === 2513 || +term === 2514) {
        this.genericRadial.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoRadial(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2506 || +term === 2508 || +term === 2510 || +term === 2511 ||
        +term === 2513 || +term === 2514) {
        this.genericRadial.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  MontaConclusaoUlnar(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2517 || +term === 2519 || +term === 2521 || +term === 2522 ||
        +term === 2524 || +term === 2525) {
        this.genericUlnares.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoUlnar(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 2517 || +term === 2519 || +term === 2521 || +term === 2522 ||
        +term === 2524 || +term === 2525) {
        this.genericUlnares.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoBraquiais(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 2528 || +term === 2531 || +term === 2533 || +term === 4036) {
        this.genericBraquiais.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoBraquiais(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2528 || +term === 2531 || +term === 2533 || +term === 4036) {
        this.genericBraquiais.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoAxilar(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 2536 || +term === 2539 || +term === 2541) {
        this.genericAxilar.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoAxilar(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2528 || +term === 2531 || +term === 2533) {
        this.genericAxilar.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoSubclavia(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 2544 || +term === 2547 || +term === 2549) {
        this.genericSubclavia.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoSubclavia(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2544 || +term === 2547 || +term === 2549) {
        this.genericSubclavia.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoCefalica(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 2552 || +term === 2554 || +term === 2555) {
        this.genericCefalica.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoCefalica(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2552 || +term === 2554 || +term === 2555) {
        this.genericCefalica.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoBasilica(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 2558 || +term === 2560 || +term === 2561) {
        this.genericBasilica.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoBasilica(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2558 || +term === 2560 || +term === 2561) {
        this.genericBasilica.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoEdema(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 2563 || +term === 2564 || +term === 2565) {
        this.genericEdema.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoEdema(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2563 || +term === 2564 || +term === 2565) {
        this.genericEdema.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  adicionaSelecaoEdema($event) {
    this.genericEdema.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoRadiais($event) {
    this.genericRadial.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }
  adicionaSelecaoUlnares($event) {
    this.genericUlnares.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoBraquial($event) {
    this.genericBraquiais.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }
  adicionaSelecaoAxilar($event) {
    this.genericAxilar.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoSubclavia($event) {
    this.genericSubclavia.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoCefalica($event) {
    this.genericCefalica.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoBasilica($event) {
    this.genericBasilica.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public enviaLados($event) {
    this.Lado = $event;
    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'lateralidade',
        lado: this.Lado
      });
    }
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        indicacao: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        achados: this.rodapeTexto
      });
    }
  }

  public RetornaConclusaoNull(Texto): any {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }
}
