import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, ViewChildren, QueryList, Input } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Validacoes } from '../../../../../../utils/validacoes';

@Component({
  selector: 'app-linfonodos-parede',
  templateUrl: './linfonodos-parede.component.html',
  styleUrls: ['./linfonodos-parede.component.css']
})
export class LinfonodosParedeComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Input() medicalRepotID: number;

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdPreservados') rdPreservados: MatRadioButton;
  @ViewChild('rdLinfoAusente') rdLinfoAusente: MatRadioButton;
  @ViewChild('rdLinfoPresente') rdLinfoPresente: MatRadioButton;

  @ViewChild('rdLinfoUnica') rdLinfoUnica: MatRadioButton;
  @ViewChild('rdLinfoVarias') rdLinfoVarias: MatRadioButton;

  @ViewChild('slLadoPreservados') slLadoPreservados: MatSelect;
  @ViewChild('inMedidaUm1Preservado') inMedidaUm1Preservado: ElementRef;
  @ViewChild('inMedidaUm2Preservado') inMedidaUm2Preservado: ElementRef;

  @ViewChild('slLadoPresentes') slLadoPresentes: MatSelect;
  @ViewChild('slEcogenicidade') slEcogenicidade: MatSelect;
  @ViewChild('inMedidaUm1') inMedidaUm1: ElementRef;
  @ViewChild('inMedidaUm2') inMedidaUm2: ElementRef;

  @ViewChildren('linfoPresChildren') linfoPresChildren: QueryList<any>;
  @ViewChildren('linfoUniChildren') linfoUniChildren: QueryList<any>;
  @ViewChildren('linfoVarChildren') linfoVarChildren: QueryList<any>;

  lados: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];

  ecogenicidades: ArrayDropDown[] = [
    { value: 'hipoecogênica', viewValue: 'Hipoecogênica' },
    { value: 'hiperecogênica', viewValue: 'Hiperecogênica' }
  ];

  ecogenicidadesMult: ArrayDropDown[] = [
    { value: 'hipoecogênicas', viewValue: 'Hipoecogênicas' },
    { value: 'hiperecogênicas', viewValue: 'Hiperecogênicas' }
  ];

  showAndHideTerms: any;
  localizaCadeia: string;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  obj: any;
  objetofilho: any;
  rdAusenteID: string;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes, private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPreservado: false,
      sahLinfonodomegaliaPresente: false,
      sahUnica: false,
      sahVarias: false,
    };

    this.lesoesForm = this._fb.group({
      linfoPreservado: this._fb.array([this.addLinfoPresGrup()]),
      linfoUnica: this._fb.array([this.addLinfoUniGrup()]),
      linfoVarias: this._fb.array([this.addLinfoVarGrup()]),
    });

    if (this.medicalRepotID === 49)
      this.rdAusenteID = "4200"
    else
      this.rdAusenteID = "4186"

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  //Dinamico Lifo Preservado
  addLinfoPresGrup() {
    return this._fb.group({
      lado: [],
      medida: [],
      ecogenicidade: [],
    });
  }

  addLinfoPres() {
    this.linfoPresArray.push(this.addLinfoPresGrup());
    this.catchIDs();
  }

  removeLinfoPres(index) {
    this.linfoPresArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
       this.catchIDs();
    }, 1000);
  }

  get linfoPresArray() {
    return <FormArray>this.lesoesForm.get('linfoPreservado');
  }

  //Dinamico Lifo Unico
  addLinfoUniGrup() {
    return this._fb.group({
      lado: [],
      medida: [],
      ecogenicidade: [],
    });
  }

  addLinfoUni() {
    this.linfoUniArray.push(this.addLinfoUniGrup());
    this.catchIDs();
  }

  removeLinfoUni(index) {
    this.linfoUniArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
   }, 1000);
  }

  get linfoUniArray() {
    return <FormArray>this.lesoesForm.get('linfoUnica');
  }

  //Dinamico Lifo Varios
  addLinfoVarGrup() {
    return this._fb.group({
      lado: [],
      medida: [],
      ecogenicidade: [],
    });
  }

  addLinfoVar() {
    this.linfoVarArray.push(this.addLinfoUniGrup());
    this.catchIDs();
  }

  removeLinfoVar(index) {
    this.linfoVarArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
   }, 1000);
  }

  get linfoVarArray() {
    return <FormArray>this.lesoesForm.get('linfoVarias');
  }

  clickNormal() {
    this.showAndHideTerms.sahPreservado = false;
    this.showAndHideTerms.sahLinfonodomegaliaPresente = false;
    this.showAndHideTerms.sahUnica = false;
    this.showAndHideTerms.sahVarias = false;
    this.verificaAlterado();
    this.catchIDs();
  }
  clickLinfoPresente() {
    this.showAndHideTerms.sahPreservado = false;
    this.showAndHideTerms.sahLinfonodomegaliaPresente = true;
    this.showAndHideTerms.sahUnica = false;
    this.showAndHideTerms.sahVarias = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }
  clickLinfoAusente() {
    this.showAndHideTerms.sahPreservado = false;
    this.showAndHideTerms.sahUnica = false;
    this.showAndHideTerms.sahVarias = false;
    this.showAndHideTerms.sahLinfonodomegaliaPresente = false;
    this.catchIDs();

  }
  clickPreservado() {
    this.showAndHideTerms.sahPreservado = true;
    this.showAndHideTerms.sahUnica = false;
    this.showAndHideTerms.sahVarias = false;
    this.showAndHideTerms.sahLinfonodomegaliaPresente = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }
  clickLinfoUnica() {
    this.showAndHideTerms.sahLinfonodomegaliaPresente = true;
    this.showAndHideTerms.sahUnica = true;
    this.showAndHideTerms.sahVarias = false;
    this.showAndHideTerms.sahPreservado = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }
  clickLinfoVarias() {
    this.showAndHideTerms.sahLinfonodomegaliaPresente = true;
    this.showAndHideTerms.sahVarias = true;
    this.showAndHideTerms.sahPreservado = false;
    this.showAndHideTerms.sahUnica = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurDimensao() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }

    if (this.rdPreservados.checked) {
      this.termoSelecionado.emit(this.rdPreservados.id);

      // Fazer Lógica de Dinamico para Preservado
      if (this.countLesoes > 0)
        this.emmitPreservadoLesao();
      else
        this.emmitPreservado();
    }

    if (this.rdLinfoAusente.checked) {
      this.termoSelecionado.emit(this.rdLinfoAusente.id);
    }

    if (this.rdLinfoPresente.checked) {
      this.termoSelecionado.emit(this.rdLinfoPresente.id);

      if (this.rdLinfoUnica && this.rdLinfoUnica.checked) {
        this.termoSelecionado.emit(this.rdLinfoUnica.id);

        // Fazer Lógica de Dinamico para Unica
        if (this.countLesoes > 0)
          this.emmitLinfUnicoLesao();
        else
          this.emmitLinfUnico();
      }

      if (this.rdLinfoVarias && this.rdLinfoVarias.checked) {
        this.termoSelecionado.emit(this.rdLinfoVarias.id);

        // Fazer Lógica de Dinamico para Varias
        if (this.countLesoes > 0)
          this.emmitLinfVariosLesao();
        else
          this.emmitLinfVarios();
      }

    }

    this.listaCompleta.emit();

  }

  emmitPreservado() {
    if ((this.slLadoPreservados && this.slLadoPreservados.value) &&
      (this.inMedidaUm1Preservado && this.inMedidaUm1Preservado.nativeElement.value) &&
      (this.inMedidaUm2Preservado)) {
      this.countLesoes++;

      let posicao = 0;
      this.linha = 0;
      let medindo = '';
      this.linfoPresChildren.forEach(item => {
        if (item.nativeElement) {
          if (posicao < 1) {
            posicao++;
          } else {
            posicao = 1;
          }

          if (medindo === '') {
            medindo = this._validacoes.formataDecimal(item.nativeElement.value);
          } else {
            if (item.nativeElement.value) {
              medindo += ' x ' + this._validacoes.formataDecimal(item.nativeElement.value);
            }
            this.objetofilho = { id: "4185", value: medindo, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.obj);
            medindo = '';
          }

        } else {

          if (+item.id === 4184)
            this.linha++;

          this.objetofilho = { id: item.id, value: item.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.obj);
        }
      });
    }
  }
  emmitPreservadoLesao() {

    if ((this.slLadoPreservados && this.slLadoPreservados.value) &&
      (this.inMedidaUm1Preservado && this.inMedidaUm1Preservado.nativeElement.value) &&
      (this.inMedidaUm2Preservado)) {
      this.countLesoes++;

      let posicao = 0;
      this.linha = 0;
      let medindo = '';
      this.linfoPresChildren.forEach(item => {
        if (item.nativeElement) {
          if (posicao < 1) {
            posicao++;
          } else {
            posicao = 1;
          }
          if (medindo === '') {
            medindo = this._validacoes.formataDecimal(item.nativeElement.value);
          }
          else {
            if (item.nativeElement.value) {
              medindo += ' x ' + this._validacoes.formataDecimal(item.nativeElement.value);
            }
            this.objetofilho = { id: "4185", value: medindo, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.obj);
            medindo = '';
          }
        } else {

          if (+item.id === 4184)
            this.linha++;

          this.objetofilho = { id: item.id, value: item.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.obj);
        }
      });
    }

  }

  emmitLinfUnico() {
    if ((this.slLadoPresentes && this.slLadoPresentes.value) && (this.slEcogenicidade && this.slEcogenicidade.value) &&
      (this.inMedidaUm1 && this.inMedidaUm1.nativeElement.value) &&
      (this.inMedidaUm2)) {

      this.countLesoes++;

      let posicao = 0;
      this.linha = 0;
      let medindo = '';
      this.linfoUniChildren.forEach(obj => {
        if (obj.nativeElement) {
          if (posicao < 1) {
            posicao++;
          } else {
            posicao = 1;
          }

          if (medindo === '') {
            medindo = this._validacoes.formataDecimal(obj.nativeElement.value);
          }
          else {
            if (obj.nativeElement.value) {
              medindo += ' x ' + this._validacoes.formataDecimal(obj.nativeElement.value);
            }
            this.objetofilho = { id: "4192", value: medindo, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.obj);
            medindo = '';
          }
        } else {

          if (+obj.id === 4190)
            this.linha++;

          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.obj);
        }
      });
    }

  }
  emmitLinfUnicoLesao() {
    if ((this.slLadoPresentes && this.slLadoPresentes.value) && (this.slEcogenicidade && this.slEcogenicidade.value) &&
      (this.inMedidaUm1 && this.inMedidaUm1.nativeElement.value) &&
      (this.inMedidaUm2)) {

      this.countLesoes++;

      let posicao = 0;
      this.linha = 0;
      let medindo = '';
      this.linfoUniChildren.forEach(obj => {
        if (obj.nativeElement) {
          if (posicao < 1) {
            posicao++;
          } else {
            posicao = 1;
          }

          if (medindo === '') {
            medindo = this._validacoes.formataDecimal(obj.nativeElement.value);
          }
          else {
            if (obj.nativeElement.value) {
              medindo += ' x ' + this._validacoes.formataDecimal(obj.nativeElement.value);
            }
            this.objetofilho = { id: "4192", value: medindo, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.obj);
            medindo = '';
          }
        } else {

          if (+obj.id === 4190)
            this.linha++;

          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.obj);
        }
      });
    }

  }

  emmitLinfVarios() {
    if ((this.slLadoPresentes && this.slLadoPresentes.value) && (this.slEcogenicidade && this.slEcogenicidade.value) &&
      (this.inMedidaUm1 && this.inMedidaUm1.nativeElement.value) &&
      (this.inMedidaUm2)) {

      this.countLesoes++;

      let posicao = 0;
      this.linha = 0;
      let medindo = '';
      this.linfoVarChildren.forEach(obj => {
        if (obj.nativeElement) {
          if (posicao < 1) {
            posicao++;
          } else {
            posicao = 1;
          }
          if (medindo === '') {
            medindo = this._validacoes.formataDecimal(obj.nativeElement.value);
          }
          else {
            if (obj.nativeElement.value) {
              medindo += ' x ' + this._validacoes.formataDecimal(obj.nativeElement.value);
            }
            this.objetofilho = { id: "4192", value: medindo, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.obj);
            medindo = '';
          }
        } else {

          if (+obj.id === 4190)
            this.linha++;

          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.obj);
        }
      });
    }

  }
  emmitLinfVariosLesao() {
    if ((this.slLadoPresentes && this.slLadoPresentes.value) && (this.slEcogenicidade && this.slEcogenicidade.value) &&
      (this.inMedidaUm1 && this.inMedidaUm1.nativeElement.value) &&
      (this.inMedidaUm2)) {

      this.countLesoes++;

      let posicao = 0;
      this.linha = 0;
      let medindo = '';
      this.linfoVarChildren.forEach(obj => {
        if (obj.nativeElement) {
          if (posicao < 2) {
            posicao++;
          } else {
            posicao = 1;
          }
          if (medindo === '') {
            medindo = this._validacoes.formataDecimal(obj.nativeElement.value);
          }
          else {
            if (obj.nativeElement.value) {
              medindo += ' x ' + this._validacoes.formataDecimal(obj.nativeElement.value);
            }
            this.objetofilho = { id: "4192", value: medindo, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.obj);
            medindo = '';
          }
        } else {

          if (+obj.id === 4190)
            this.linha++;

          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.obj);
        }
      });
    }

  }


  formataMedindoPreservado(isPrimeiraLesao): String {

    let medindoValor = '';

    if (this.inMedidaUm1Preservado && this.inMedidaUm1Preservado.nativeElement.value)
      medindoValor = this._validacoes.formataDecimal(this.inMedidaUm1Preservado.nativeElement.value);


    if (this.inMedidaUm2Preservado && this.inMedidaUm2Preservado.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaUm2Preservado.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedidaUm2Preservado.nativeElement.value);
    }

    if (medindoValor) {
      // const objetoElemento = { id: 4185, value: medindoValor };
      // const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      // this.termosGenericos.emit(objetoLesao);
    }
    return medindoValor;
  }

  formataMedindoUniVar(isPrimeiraLesao): String {

    let medindoValor = '';

    if (this.inMedidaUm1 && this.inMedidaUm1.nativeElement.value)
      medindoValor = this._validacoes.formataDecimal(this.inMedidaUm1.nativeElement.value);


    if (this.inMedidaUm2 && this.inMedidaUm2.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaUm2.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedidaUm2.nativeElement.value);
    }

    if (medindoValor) {
      // const objetoElemento = { id: 4192, value: medindoValor };
      // const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      // this.termosGenericos.emit(objetoLesao);
    }
    return medindoValor;
  }

  ajustaArrayDinamico() {
    const arrayPresTotal = this.linfoPresArray.length;
    for (let i = 0; i <= arrayPresTotal; i++) {
      const index = this.linfoPresArray.length - 1
      if (index != 0) {
        this.linfoPresArray.removeAt(index);
      }
    }
    this.linfoPresArray.reset();

    const arrayUniTotal = this.linfoUniArray.length;
    for (let i = 0; i <= arrayUniTotal; i++) {
      const index = this.linfoUniArray.length - 1
      if (index != 0) {
        this.linfoUniArray.removeAt(index);
      }
    }
    this.linfoUniArray.reset();

    const arrayVarTotal = this.linfoVarArray.length;
    for (let i = 0; i <= arrayVarTotal; i++) {
      const index = this.linfoVarArray.length - 1
      if (index != 0) {
        this.linfoVarArray.removeAt(index);
      }
    }
    this.linfoVarArray.reset();
  }

  verificaAlterado() {
    /*
    if (!this.ckLinfonodoCalcificado.checked &&
      !this.ckLinfonodoProeminente.checked &&
      !this.ckLinfonodomegalia.checked) {
      // Marca normal
      this.rdNormal.checked = true;
    }
    */
  }
}
