import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { Validacoes } from '../../../../utils/validacoes';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { AddText, IAtualizaLinha } from '../../../../utils/addText';
import { DataTermService } from 'src/app/services/dataTerm.service';

export interface Descricao {
  value: string;
  textTermID: number;
  blockID: number;
}

export interface Conclusao {
  value: string;
  textTermID: number;
  blockID: number;
}

export interface TermosGenericos {
  value: string;
  selected: boolean;
  term: string;
  termView: string;
  termID: number;
  linha: number;
  cenario: number;
  posicao: number;
  lesionID: number;
}

@Component({
  selector: 'app-pelvico',
  templateUrl: './pelvico.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./pelvico.component.css']
})
export class PelvicoComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[451],blockID:24},
    {listTerm:[400],blockID:20},
    {listTerm:[532],blockID:22},
    {listTerm:[547],blockID:26},
    {listTerm:[417],blockID:21},
    {listTerm:[424],blockID:23},
    {listTerm:[185],blockID:9}
  ]

  descricoes: Descricao[] = [
    {
      value: 'Útero, de forma típica, contornos e dimensões normais para a idade e paridade.</br>' +
        'Útero mede:.', blockID: 24, textTermID: 0
    },
    { value: 'Endométrio linear e homogêneo, com espessura de.', blockID: 20, textTermID: 0 },
    {
      value: 'Ovário direito tópico, com dimensões normais, contornos regulares e ecotextura preservada.</br>' +
        'Ovário direito:', blockID: 22, textTermID: 0
    },
    {
      value: 'Ovário esquerdo tópico, com dimensões normais, contornos regulares e ecotextura preservada.</br>' +
        'Ovário esquerdo:', blockID: 26, textTermID: 0
    },
    { value: 'Ausência de líquido livre na cavidade pélvica.', blockID: 21, textTermID: 0 },
    { value: 'Regiões anexiais livres.', blockID: 23, textTermID: 0 },
    { value: 'Bexiga com adequada repleção, paredes regulares e finas. O conteúdo vesical é anecoico e homogêneo.', blockID: 9, textTermID: 104 }
  ];

  conclusoes: Conclusao[] = [
    { value: 'Estruturas analisadas sem alterações significativas.', blockID: 0, textTermID: 0 },
    { value: '', blockID: 24, textTermID: 0 },
    { value: '', blockID: 20, textTermID: 0 },
    { value: '', blockID: 22, textTermID: 0 },
    { value: '', blockID: 26, textTermID: 0 },
    { value: '', blockID: 21, textTermID: 0 },
    { value: '', blockID: 23, textTermID: 0 },
    { value: '', blockID: 9, textTermID: 0 }
  ];

  genericTermsLiquidoLivre: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 418, termID: 419, cenario: 0, posicao: 0, linha: 0, term: '[Quantidade]', termView: 'slQuantidade' },
    { value: '', selected: false, lesionID: 418, termID: 420, cenario: 0, posicao: 0, linha: 0, term: '[Conteúdo]', termView: 'slConteudo' },
    { value: '', selected: false, lesionID: 418, termID: 421, cenario: 0, posicao: 0, linha: 0, term: '[Local]', termView: 'slLocal' }
  ];

  genericTermsEndometrio: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 0, termID: 404, cenario: 0, posicao: 0, linha: 0, term: '[Espessura]', termView: 'inEspesura' },
    { value: '', selected: false, lesionID: 403, termID: 416, cenario: 0, posicao: 0, linha: 0, term: '[Aspecto]', termView: 'slAspecto' },
    { value: '', selected: false, lesionID: 405, termID: 406, cenario: 0, posicao: 0, linha: 0, term: '[Medida1]', termView: 'inMedidaUmNormo' },
    { value: '', selected: false, lesionID: 405, termID: 407, cenario: 0, posicao: 0, linha: 0, term: '[Medida2]', termView: 'inMedidaDoisNormo' },
    { value: '', selected: false, lesionID: 408, termID: 411, cenario: 0, posicao: 0, linha: 0, term: '[Parede]', termView: 'slParedeDeslocada' },
    { value: '', selected: false, lesionID: 413, termID: 414, cenario: 0, posicao: 0, linha: 0, term: '[Segmento]', termView: 'slSegmentoPolipo' },
    { value: '', selected: false, lesionID: 413, termID: 415, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedidaPolipo' },
    { value: '', selected: false, lesionID: 409, termID: 1135, cenario: 0, posicao: 0, linha: 0, term: '[Medida1]', termView: 'inMedida1' },
    { value: '', selected: false, lesionID: 409, termID: 1136, cenario: 0, posicao: 0, linha: 0, term: '[Medida2]', termView: 'inMedida2' }
  ];

  genericTermsRegiaoAnexial: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 425, termID: 427, cenario: 0, posicao: 0, linha: 0, term: '[Calibre direito]', termView: 'inVarizesDireita' },
    { value: '', selected: false, lesionID: 425, termID: 429, cenario: 0, posicao: 0, linha: 0, term: '[Calibre esquerdo]', termView: 'inVarizesEsquerda' },
    { value: '', selected: false, lesionID: 430, termID: 432, cenario: 0, posicao: 0, linha: 0, term: '[Calibre direito]', termView: 'inHidrossalpingeDireita' },
    { value: '', selected: false, lesionID: 430, termID: 434, cenario: 0, posicao: 0, linha: 0, term: '[Calibre esquerdo]', termView: 'inHidrossalpingeEsquerda' },
    { value: '', selected: false, lesionID: 435, termID: 437, cenario: 0, posicao: 0, linha: 0, term: '[Calibre direito]', termView: 'inHematoDireita' },
    { value: '', selected: false, lesionID: 435, termID: 439, cenario: 0, posicao: 0, linha: 0, term: '[Calibre esquerdo]', termView: 'inHematoEsquerda' },
    { value: '', selected: false, lesionID: 440, termID: 442, cenario: 0, posicao: 0, linha: 0, term: '[Medindo direito]', termView: 'inAbscessoDireita' },
    { value: '', selected: false, lesionID: 440, termID: 444, cenario: 0, posicao: 0, linha: 0, term: '[Medindo esquerdo]', termView: 'inAbscessoEsquerda' },
    { value: '', selected: false, lesionID: 445, termID: 447, cenario: 0, posicao: 0, linha: 0, term: '[Medindo direito]', termView: 'inTeratomaDireita' },
    { value: '', selected: false, lesionID: 445, termID: 449, cenario: 0, posicao: 0, linha: 0, term: '[Medindo esquerdo]', termView: 'inTeratomaEsquerda' }
  ];

  genericTermsUtero: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 0, termID: 453, cenario: 0, posicao: 0, linha: 0, term: '[Posição do útero]', termView: 'slPosicao' },
    { value: '', selected: false, lesionID: 0, termID: 454, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]', termView: 'slContornos' },
    { value: '', selected: false, lesionID: 0, termID: 455, cenario: 0, posicao: 0, linha: 0, term: '[Medidas]', termView: 'inMedidaNormalAumentada' },
    { value: '', selected: false, lesionID: 0, termID: 458, cenario: 0, posicao: 0, linha: 0, term: '[Medidas]', termView: 'inMedidaHisterectomia' },
    { value: '', selected: false, lesionID: 0, termID: 466, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedidaColoUterino' },
    { value: '', selected: false, lesionID: 468, termID: 469, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcogenicidade' },
    { value: '', selected: false, lesionID: 468, termID: 470, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]', termView: 'slContornoNodulo' },
    { value: '', selected: false, lesionID: 468, termID: 471, cenario: 0, posicao: 0, linha: 0, term: '[Subtipo]', termView: 'slSubtipo' },
    { value: '', selected: false, lesionID: 468, termID: 472, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacao' },
    { value: '', selected: false, lesionID: 468, termID: 473, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inMedidaUmNodulo' },
    { value: '', selected: false, lesionID: 474, termID: 522, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcogenicidade' },
    { value: '', selected: false, lesionID: 474, termID: 523, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]', termView: 'slContornoNodulo' },
    { value: '', selected: false, lesionID: 477, termID: 527, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcogenicidade' },
    { value: '', selected: false, lesionID: 477, termID: 528, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]', termView: 'slContornoNodulo' }
  ];

  genericTermsOvarioDireito: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 0, termID: 536, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inMedindo' },
    { value: '', selected: false, lesionID: 537, termID: 538, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Simples]', termView: 'inMedindoCistoSimples' },
    { value: '', selected: false, lesionID: 539, termID: 540, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Lúteo]', termView: 'inMedindoCistoLuteo' },
    { value: '', selected: false, lesionID: 541, termID: 542, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Funcional]', termView: 'inMedindoCistoFuncional' },
    { value: '', selected: false, lesionID: 543, termID: 544, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Hemorrágico]', termView: 'inMedindoCistoHemorragico' },
    { value: '', selected: false, lesionID: 545, termID: 546, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Endometrioma]', termView: 'inMedindoCistoEndometrioma' }
  ];

  genericTermsOvarioEsquerdo: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 0, termID: 551, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inMedindo' },
    { value: '', selected: false, lesionID: 552, termID: 553, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Simples]', termView: 'inMedindoCistoSimples' },
    { value: '', selected: false, lesionID: 554, termID: 555, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Lúteo]', termView: 'inMedindoCistoLuteo' },
    { value: '', selected: false, lesionID: 556, termID: 557, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Funcional]', termView: 'inMedindoCistoFuncional' },
    { value: '', selected: false, lesionID: 558, termID: 559, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Hemorrágico]', termView: 'inMedindoCistoHemorragico' },
    { value: '', selected: false, lesionID: 560, termID: 561, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Endometrioma]', termView: 'inMedindoCistoEndometrioma' }
  ];

  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  dinamicLesions: TermosGenericos[] = [];
  dinamicLesion: any;
  lesionRow = 1;
  cabecalhoTexto = '';
  medicaoNodulo = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  rodapeTexto = '';

  cabecalho = false;
  utero = true;
  endometrio = false;
  ovarioDireito = false;
  ovarioEsquerdo = false;
  liquidoLivre = false;
  regiaoAnexial = false;
  bexiga = false;
  indicacao = false;
  achadosTexto = false;
  rodape = false;

  medicalReportTypeID = 28;
  uteroID = 24;
  endometrioID = 20;
  ovarioDireitoID = 22;
  ovarioEsquerdoID = 26;
  liquidoLivreID = 21;
  regiaoAnexialID = 23;
  bexigaID = 9;

  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  liqLivreSemConclusao = false;
  public laudoCompleto: any = [];
  public newEditor: boolean = false;
  public switchFormReport = true;
  public showForm: Boolean = true;
  unidadeMedida: string;
  editLaudoMode: boolean = false;

  constructor(public _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _toastr: ToastrManager,
    private _dialog: MatDialog,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _copyPaste: CopyPaste,
    private dataService:DataTermService,
    public _addText: AddText,) { }

  ngOnInit() {
    this.carregaDescricaoConclusao();
    this.resetaDialog();
    this.Subtitulo = 'Útero'
    this.subtituloBloco = 'Útero';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [
      this.uteroID,
      this.endometrioID,
      this.ovarioDireitoID,
      this.ovarioEsquerdoID,
      this.liquidoLivreID,
      this.regiaoAnexialID,
      this.bexigaID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "pelvicoMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks,"pelvico");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-pelvico');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-pelvico');
      }
    });

    let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public carregaDescricaoConclusao(){
    this._pouchService.loadDatabase("pelvicoAnalise", this.descricoes, this.conclusoes);
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("pelvicoAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Útero',
      id: 'utero',
      name: 'utero',
      selected: true,
    },
    {
      titulo: 'Endométrio',
      id: 'endometrio',
      name: 'endometrio',
      selected: false
    },
    {
      titulo: 'Ovário direito',
      id: 'ovario-direito',
      name: 'ovario-direito',
      selected: false
    },
    {
      titulo: 'Ovário esquerdo',
      id: 'ovario-esquerdo',
      name: 'ovario-esquerdo',
      selected: false
    },
    {
      titulo: 'Líquido Livre',
      id: 'liquido-livre',
      name: 'liquido-livre',
      selected: false
    },
    {
      titulo: 'Região Anexial',
      id: 'regiao-anexial',
      name: 'regiao-anexial',
      selected: false
    },
    {
      titulo: 'Bexiga',
      id: 'bexiga-pelvico',
      name: 'bexiga-pelvico',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados-adicionais',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }

        this.cabecalho = (itemSelecionado.id === 'cabecalho');
        this.utero = (itemSelecionado.id === 'utero');
        this.endometrio = (itemSelecionado.id === 'endometrio');
        this.ovarioDireito = (itemSelecionado.id === 'ovario-direito');
        this.ovarioEsquerdo = (itemSelecionado.id === 'ovario-esquerdo');
        this.liquidoLivre = (itemSelecionado.id === 'liquido-livre');
        this.regiaoAnexial = (itemSelecionado.id === 'regiao-anexial');
        this.bexiga = (itemSelecionado.id === 'bexiga-pelvico');
        this.indicacao = (itemSelecionado.id === 'indicacao');
        this.achadosTexto = (itemSelecionado.id === 'achados-adicionais');
        this.rodape = (itemSelecionado.id === 'rodape');


    this.AdicionaTexto(this.newEditor);
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;
    this.dinamicLesions = [];
    this.lesionRow = 1;
    this.medicaoNodulo = '';

    this.genericTermsLiquidoLivre = [
      { value: '', selected: false, lesionID: 418, termID: 419, cenario: 0, posicao: 0, linha: 0, term: '[Quantidade]', termView: 'slQuantidade' },
      { value: '', selected: false, lesionID: 418, termID: 420, cenario: 0, posicao: 0, linha: 0, term: '[Conteúdo]', termView: 'slConteudo' },
      { value: '', selected: false, lesionID: 418, termID: 421, cenario: 0, posicao: 0, linha: 0, term: '[Local]', termView: 'slLocal' }
    ];

    this.genericTermsEndometrio = [
      { value: '', selected: false, lesionID: 0, termID: 404, cenario: 0, posicao: 0, linha: 0, term: '[Espessura]', termView: 'inEspesura' },
      { value: '', selected: false, lesionID: 403, termID: 416, cenario: 0, posicao: 0, linha: 0, term: '[Aspecto]', termView: 'slAspecto' },
      { value: '', selected: false, lesionID: 405, termID: 406, cenario: 0, posicao: 0, linha: 0, term: '[Medida1]', termView: 'inMedidaUmNormo' },
      { value: '', selected: false, lesionID: 405, termID: 407, cenario: 0, posicao: 0, linha: 0, term: '[Medida2]', termView: 'inMedidaDoisNormo' },
      { value: '', selected: false, lesionID: 408, termID: 411, cenario: 0, posicao: 0, linha: 0, term: '[Parede]', termView: 'slParedeDeslocada' },
      { value: '', selected: false, lesionID: 413, termID: 414, cenario: 0, posicao: 0, linha: 0, term: '[Segmento]', termView: 'slSegmentoPolipo' },
      { value: '', selected: false, lesionID: 413, termID: 415, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedidaPolipo' },
      { value: '', selected: false, lesionID: 409, termID: 1135, cenario: 0, posicao: 0, linha: 0, term: '[Medida1]', termView: 'inMedida1' },
      { value: '', selected: false, lesionID: 409, termID: 1136, cenario: 0, posicao: 0, linha: 0, term: '[Medida2]', termView: 'inMedida2' }
    ];

    this.genericTermsRegiaoAnexial = [
      { value: '', selected: false, lesionID: 425, termID: 427, cenario: 0, posicao: 0, linha: 0, term: '[Calibre direito]', termView: 'inVarizesDireita' },
      { value: '', selected: false, lesionID: 425, termID: 429, cenario: 0, posicao: 0, linha: 0, term: '[Calibre esquerdo]', termView: 'inVarizesEsquerda' },
      { value: '', selected: false, lesionID: 430, termID: 432, cenario: 0, posicao: 0, linha: 0, term: '[Calibre direito]', termView: 'inHidrossalpingeDireita' },
      { value: '', selected: false, lesionID: 430, termID: 434, cenario: 0, posicao: 0, linha: 0, term: '[Calibre esquerdo]', termView: 'inHidrossalpingeEsquerda' },
      { value: '', selected: false, lesionID: 435, termID: 437, cenario: 0, posicao: 0, linha: 0, term: '[Calibre direito]', termView: 'inHematoDireita' },
      { value: '', selected: false, lesionID: 435, termID: 439, cenario: 0, posicao: 0, linha: 0, term: '[Calibre esquerdo]', termView: 'inHematoEsquerda' },
      { value: '', selected: false, lesionID: 440, termID: 442, cenario: 0, posicao: 0, linha: 0, term: '[Medindo direito]', termView: 'inAbscessoDireita' },
      { value: '', selected: false, lesionID: 440, termID: 444, cenario: 0, posicao: 0, linha: 0, term: '[Medindo esquerdo]', termView: 'inAbscessoEsquerda' },
      { value: '', selected: false, lesionID: 445, termID: 447, cenario: 0, posicao: 0, linha: 0, term: '[Medindo direito]', termView: 'inTeratomaDireita' },
      { value: '', selected: false, lesionID: 445, termID: 449, cenario: 0, posicao: 0, linha: 0, term: '[Medindo esquerdo]', termView: 'inTeratomaEsquerda' }
    ];

    this.genericTermsUtero = [
      { value: '', selected: false, lesionID: 0, termID: 453, cenario: 0, posicao: 0, linha: 0, term: '[Posição do útero]', termView: 'slPosicao' },
      { value: '', selected: false, lesionID: 0, termID: 454, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]', termView: 'slContornos' },
      { value: '', selected: false, lesionID: 0, termID: 455, cenario: 0, posicao: 0, linha: 0, term: '[Medidas]', termView: 'inMedidaNormalAumentada' },
      { value: '', selected: false, lesionID: 0, termID: 458, cenario: 0, posicao: 0, linha: 0, term: '[Medidas]', termView: 'inMedidaHisterectomia' },
      { value: '', selected: false, lesionID: 0, termID: 466, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedidaColoUterino' },
      { value: '', selected: false, lesionID: 468, termID: 469, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcogenicidade' },
      { value: '', selected: false, lesionID: 468, termID: 470, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]', termView: 'slContornoNodulo' },
      { value: '', selected: false, lesionID: 468, termID: 471, cenario: 0, posicao: 0, linha: 0, term: '[Subtipo]', termView: 'slSubtipo' },
      { value: '', selected: false, lesionID: 468, termID: 472, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacao' },
      { value: '', selected: false, lesionID: 468, termID: 473, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inMedidaUmNodulo' },
      { value: '', selected: false, lesionID: 474, termID: 522, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcogenicidade' },
      { value: '', selected: false, lesionID: 474, termID: 523, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]', termView: 'slContornoNodulo' },
      { value: '', selected: false, lesionID: 477, termID: 527, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcogenicidade' },
      { value: '', selected: false, lesionID: 477, termID: 528, cenario: 0, posicao: 0, linha: 0, term: '[Contornos]', termView: 'slContornoNodulo' }
    ];

    this.genericTermsOvarioDireito = [
      { value: '', selected: false, lesionID: 0, termID: 536, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inMedindo' },
      { value: '', selected: false, lesionID: 537, termID: 538, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Simples]', termView: 'inMedindoCistoSimples' },
      { value: '', selected: false, lesionID: 539, termID: 540, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Lúteo]', termView: 'inMedindoCistoLuteo' },
      { value: '', selected: false, lesionID: 541, termID: 542, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Funcional]', termView: 'inMedindoCistoFuncional' },
      { value: '', selected: false, lesionID: 543, termID: 544, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Hemorrágico]', termView: 'inMedindoCistoHemorragico' },
      { value: '', selected: false, lesionID: 545, termID: 546, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Endometrioma]', termView: 'inMedindoCistoEndometrioma' }
    ];

    this.genericTermsOvarioEsquerdo = [
      { value: '', selected: false, lesionID: 0, termID: 551, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: 'inMedindo' },
      { value: '', selected: false, lesionID: 552, termID: 553, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Simples]', termView: 'inMedindoCistoSimples' },
      { value: '', selected: false, lesionID: 554, termID: 555, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Lúteo]', termView: 'inMedindoCistoLuteo' },
      { value: '', selected: false, lesionID: 556, termID: 557, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Funcional]', termView: 'inMedindoCistoFuncional' },
      { value: '', selected: false, lesionID: 558, termID: 559, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Hemorrágico]', termView: 'inMedindoCistoHemorragico' },
      { value: '', selected: false, lesionID: 560, termID: 561, cenario: 0, posicao: 0, linha: 0, term: '[Medindo Endometrioma]', termView: 'inMedindoCistoEndometrioma' }
    ];
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados_adicionais',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      //Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      //adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  incluirLesoesDinamicas($event) {
    const lesoesValue = this._validacoes.formataDecimal($event.elemento.value);

    if ($event.elemento.id == 526 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 526,
          lesionID: 474,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medindo]',
          termView: 'inMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 526;
            obj.lesionID = 474;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medindo]';
            obj.termView = 'inMedindo';
          }
        });
      }
    } else if ($event.elemento.id == 531 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 531,
          lesionID: 477,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medindo]',
          termView: 'inMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 531;
            obj.lesionID = 477;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medindo]';
            obj.termView = 'inMedindo';
          }
        });
      }
    }

    if ($event.linha == 1 && ($event.posicao == 0 || $event.posicao == 1)) {
      this.genericTermsUtero.forEach(obj => {
        if (obj.termID == $event.elemento.id) {
          obj.selected = true;
          obj.value = lesoesValue;
        }
      });
      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaLesoes($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      //NODULO
      case '522':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 522,
          lesionID: 474,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]',
          termView: 'slEcoginicidade'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '523':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 523,
          lesionID: 474,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Contornos]',
          termView: 'slContorno'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '524':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 524,
          lesionID: 474,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Subtipo]',
          termView: 'slSubtipo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '525':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 525,
          lesionID: 474,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizacao'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '527':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 527,
          lesionID: 477,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]',
          termView: 'slEcoginicidade'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '528':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 528,
          lesionID: 477,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Contornos]',
          termView: 'slContorno'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '529':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 529,
          lesionID: 477,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Subtipo]',
          termView: 'slSubtipo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '530':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 530,
          lesionID: 477,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizacao'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }

    this.lesionRow = $event.linha;
  }

  adicionaSelecaoLiquidoLivre($event) {
    this.genericTermsLiquidoLivre.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);

        if (this.genericTermsLiquidoLivre[0].value === 'Mínima' &&
          this.genericTermsLiquidoLivre[1].value === 'anecóico' &&
          this.genericTermsLiquidoLivre[2].value === 'no fundo de saco') {
          this.liqLivreSemConclusao = true;
        } else {
          this.liqLivreSemConclusao = false;
        }
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoEndometrio($event) {
    this.genericTermsEndometrio.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoRegiaoAnexial($event) {
    this.genericTermsRegiaoAnexial.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoUtero($event) {
    this.genericTermsUtero.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoOvarioDireito($event) {
    this.genericTermsOvarioDireito.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoOvarioEsquerdo($event) {
    this.genericTermsOvarioEsquerdo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.liquidoLivre) {
        this.term.blockID = this.liquidoLivreID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.liquidoLivreID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoLiquidoLivre(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.liquidoLivreID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.liquidoLivreID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion && this.liqLivreSemConclusao === false) {
              obj.blockID = this.liquidoLivreID;
              obj.value = obj.value + espaco + this.MontaConclusaoLiquidoLivre(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 1) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.liquidoLivreID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.liquidoLivreID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.bexigaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.endometrio) {
        this.term.blockID = this.endometrioID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.endometrioID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoEndometrio(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.endometrioID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.endometrioID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.endometrioID;
              obj.value = obj.value + espaco + this.MontaConclusaoEndometrio(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.endometrioID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.endometrioID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.bexigaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.regiaoAnexial) {
        this.term.blockID = this.regiaoAnexialID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.regiaoAnexialID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoRegiaoAnexial(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.regiaoAnexialID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.regiaoAnexialID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.regiaoAnexialID;
              obj.value = obj.value + espaco + this.MontaConclusaoRegiaoAnexial(resultado.conclusion);
              espaco = '</br>';
            } else if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              obj.blockID = 0;
              obj.value = '';
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.regiaoAnexialID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.regiaoAnexialID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.bexigaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.utero) {
        this.term.blockID = this.uteroID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.uteroID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoUtero(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.uteroID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.uteroID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.uteroID;
              obj.value = obj.value + espaco + this.MontaConclusaoUtero(resultado.conclusion);
              espaco = '</br>';
            } else if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const consclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.uteroID);

          if (obj.blockID === 0) {
            if (consclusaoAll.length <= 1) {
              if (consclusaoAll[0].blockID === 0 || consclusaoAll[0].blockID === this.uteroID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.bexigaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ovarioDireito) {
        this.term.blockID = this.ovarioDireitoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.ovarioDireitoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoOvarioDireito(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.ovarioDireitoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ovarioDireitoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.ovarioDireitoID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
            } else if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              obj.blockID = 0;
              obj.value = '';
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ovarioDireitoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ovarioDireitoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.bexigaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ovarioEsquerdo) {
        this.term.blockID = this.ovarioEsquerdoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.ovarioEsquerdoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoOvarioEsquerdo(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.ovarioEsquerdoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ovarioEsquerdoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.ovarioEsquerdoID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
            } else if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              obj.blockID = 0;
              obj.value = '';
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ovarioEsquerdoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ovarioEsquerdoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.bexigaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.bexiga) {
        this.term.blockID = this.bexigaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.bexigaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoBexiga(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID == this.bexigaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.bexigaID,
            textTermID: 0
          };
          this.termConclusionAPI = termResults;
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.ovarioEsquerdoID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
            } else if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              obj.blockID = 0;
              obj.value = '';
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.bexigaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.bexigaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.bexigaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
        //por que essa chamada esta aqui?
        //this._laudoService.buscaConclusaoPorTermo(this.term);
        this._pouchService.getLaudos(this.term);
      }
    }
  }

  MontaDescricaoLiquidoLivre(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 419 || +term === 420 || +term === 421) {
        this.genericTermsLiquidoLivre.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoLiquidoLivre(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 419 || +term === 420 || +term === 421) {
        this.genericTermsLiquidoLivre.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoEndometrio(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 404 || +term === 406 || +term === 407 || +term === 411 ||
          +term === 414 || +term === 415 || +term === 416 || +term === 1135 ||
          +term === 1136) {
          this.genericTermsEndometrio.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 404 || +term === 406 || +term === 407 || +term === 411 ||
          +term === 414 || +term === 415 || +term === 416 || +term === 1135 ||
          +term === 1136) {
          this.genericTermsEndometrio.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaConclusaoEndometrio(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 404 || +term === 406 || +term === 407 || +term === 411 ||
        +term === 414 || +term === 415 || +term === 416) {
        this.genericTermsEndometrio.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoRegiaoAnexial(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 427 || +term === 429 || +term === 432 || +term === 434 || +term === 437 ||
          +term === 439 || +term === 442 || +term === 444 || +term === 447 || +term === 449) {
          this.genericTermsRegiaoAnexial.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 427 || +term === 429 || +term === 432 || +term === 434 || +term === 437 ||
          +term === 439 || +term === 442 || +term === 444 || +term === 447 || +term === 449) {
          this.genericTermsRegiaoAnexial.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaConclusaoRegiaoAnexial(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 427 || +term === 429 || +term === 432 || +term === 434 || +term === 437 ||
        +term === 439 || +term === 442 || +term === 444 || +term === 447 || +term === 449) {
        this.genericTermsRegiaoAnexial.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoUtero(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoUteroDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoUteroDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoUteroDescNormal(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        // Remove conclusão de endometrio para Histerectomia Parcial
        if (+term === 457 || +term === 456) {
          const index = this.descricoes.findIndex(x => x.blockID === this.endometrioID);
          if (index > -1) {
            this.descricoes[index].value = '';
          }
        }
        if (+term === 454 || +term === 455 || +term === 458 || +term === 466 || +term === 469 || +term === 453 ||
          +term === 470 || +term === 471 || +term === 472 || +term === 473 || +term === 522 || +term === 523) {
          this.genericTermsUtero.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 454 || +term === 455 || +term === 458 || +term === 466 || +term === 469 || +term === 453 ||
          +term === 470 || +term === 471 || +term === 472 || +term === 473 || +term === 522 || +term === 523) {
          this.genericTermsUtero.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  TextoUteroDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    // Genérico
    if (this.term.injuries[0].listInjuries.length > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 522 || +term === 523 || +term === 527 || +term === 528) {
          this.genericTermsUtero.forEach(generic => {
            if (generic.termID == term) {
              textoRetorno = textoRetorno.replace(generic.term, generic.value);
            }
          });
        }
      });
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 530 || item.termID == 531 || item.termID == 524 || item.termID == 525 ||
            item.termID == 526 || item.termID == 527 || item.termID == 528 || item.termID == 529 ||
            item.termID == 522 || item.termID == 523) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (item.termID == 530 || item.termID == 531 || item.termID == 524 || item.termID == 525 ||
            item.termID == 526 || item.termID == 527 || item.termID == 528 || item.termID == 529 ||
            item.termID == 522 || item.termID == 523) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  MontaConclusaoUtero(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 454 || +term === 455 || +term === 458 || +term === 466 || +term === 469 ||
        +term === 470 || +term === 471 || +term === 472 || +term === 473 || +term === 453) {
        this.genericTermsRegiaoAnexial.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoOvarioDireito(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 536 || +term === 538 || +term === 540 || +term === 542 || +term === 544 || +term === 546) {
          this.genericTermsOvarioDireito.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 536 || +term === 538 || +term === 540 || +term === 542 || +term === 544 || +term === 546) {
          this.genericTermsOvarioDireito.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoOvarioEsquerdo(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 551 || +term === 553 || +term === 555 || +term === 557 || +term === 559 || +term === 561) {
          this.genericTermsOvarioEsquerdo.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 551 || +term === 553 || +term === 555 || +term === 557 || +term === 559 || +term === 561) {
          this.genericTermsOvarioEsquerdo.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoBexiga(texto, contadorTexto) {
    return texto;
  }

  MontarConclusaoBexiga(texto) {
    let textoSplit = '';

    textoSplit = texto.split('|', 2);
    if (textoSplit.length > 1) {
      texto = textoSplit[0] + '\n' + textoSplit[1];
    }
    return texto;
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    // if (this.conclusoes.length <= 1) {
    texto = 'Estruturas analisadas sem alterações significativas.';
    // }
    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    console.log(maskSplit);

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'data': {
          'text': '<p><b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></p>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoes[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    // this.laudoCompleto.push({
    //   'type': 'paragraph',
    //   'data': {
    //     'text': `<p>${maskSplit[3]}</p>`,
    //   }
    // });

    // this.laudoCompleto.push({
    //   'type': 'paragraph',
    //   'data': {
    //     'text': `<p>${maskSplit[4]}</p>`,
    //   }
    // });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados_adicionais`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

  }

  adicionaTextoOldEditor() {
    let i;

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += '<div style="text-align: center;"><b>ULTRASSONOGRAFIA PÉLVICA GINECOLÓGICA</b></div>';
    } else {
      this.htmlContent = '<div style="text-align: center;"><b>ULTRASSONOGRAFIA PÉLVICA GINECOLÓGICA</b></div>';
    }

    this.htmlContent += this.indicacaoTexto + '<br />';
     //Lógica Rules
     if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
     this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';

    this.htmlContent += '<b>ANÁLISE:</b>';

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
    }
    this.htmlContent += '<br/>';
    this.htmlContent += '<b>OPINIÃO:</b>';
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }
    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          let header = document.querySelector(`#e-${blockId}`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          let footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
