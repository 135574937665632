
import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-arteria-ulnar',
  templateUrl: './arteria-ulnar.component.html',
  styleUrls: ['./arteria-ulnar.component.css']
})
export class ArteriaUlnarComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdNaoCaracterizada') rdNaoCaracterizada: MatRadioButton;
  @ViewChild('rdPlaca') rdPlaca: MatRadioButton;
  @ViewChild('rdMultiplasPlacas') rdMultiplasPlacas: MatRadioButton;
  @ViewChild('rdTrombose') rdTrombose: MatRadioButton;
  @ViewChild('rdStent') rdStent: MatRadioButton;
  @ViewChild('rdAneurisma') rdAneurisma: MatRadioButton;

  @ViewChild('slMotivo') slMotivo: MatSelect;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slLocalizacaoStent') slLocalizacaoStent: MatSelect;
  @ViewChild('slLocalizacaoTrombose') slLocalizacaoTrombose: MatSelect;
  @ViewChild('slLocalizacaoAneurisma') slLocalizacaoAneurisma: MatSelect;

  @ViewChild('slEcogenicidade') slEcogenicidade: MatSelect;
  @ViewChild('slEcotextura') slEcotextura: MatSelect;
  @ViewChild('slSuperficie') slSuperficie: MatSelect;
  @ViewChild('slEstenose') slEstenose: MatSelect;
  @ViewChild('slEstenoseStent') slEstenoseStent: MatSelect;
  @ViewChild('slFluxoDistal') slFluxoDistal: MatSelect;
  @ViewChild('slTrombo') slTrombo: MatSelect;

  @ViewChild('inEspessura') inEspessura: ElementRef;
  @ViewChild('inExtensao') inExtensao: ElementRef;
  @ViewChild('inExtensaoTrombose') inExtensaoTrombose: ElementRef;
  @ViewChild('inCalibre') inCalibre: ElementRef;

  semTrombo = "4252";


  motivo: ArrayDropDown[] = [
    { value: 'pelo acentuado edema', viewValue: 'Edema' },
    { value: 'pela intensa atenuação do feixe acústico posterior', viewValue: 'Atenuação do feixe acústico' }
  ];

  localizacao: ArrayDropDown[] = [
    { value: 'no terço proximal', viewValue: 'Terço proximal' },
    { value: 'no terço médio', viewValue: 'Terço médio' },
    { value: 'no terço distal', viewValue: 'Terço distal' }
  ];
  localizacaoTrombose: ArrayDropDown[] = [
    { value: 'em toda sua extensão', viewValue: 'Toda extensão' },
    { value: ' no terço proximal', viewValue: 'Terço proximal' },
    { value: 'no terço médio', viewValue: 'Terço médio' },
    { value: 'no terço distal', viewValue: 'Terço distal' }
  ];

  ecogenicidade: ArrayDropDown[] = [
    { value: 'calcificada', viewValue: 'Calcificada' },
    { value: 'mista', viewValue: 'Mista' },
    { value: 'iso/hiperecogênica', viewValue: 'Iso/hiperecogênica' },
    { value: 'hipoecogênica', viewValue: 'Hipoecogênica' }
  ];
  ecotextura: ArrayDropDown[] = [
    { value: 'homogênea', viewValue: 'Homogênea' },
    { value: 'heterogênea', viewValue: 'Heterogênea' }
  ];
  superficie: ArrayDropDown[] = [
    { value: 'regular', viewValue: 'Regular' },
    { value: 'irregular', viewValue: 'Irregular' },
    { value: 'ulcerada', viewValue: 'Ulcerada' }
  ];
  estenose: ArrayDropDown[] = [
    { value: 'sem determinar estenose hemodinamicamente significativa (< 50%)', viewValue: 'Sem determinar estenose hemodinamicamente significativa (< 50%)' },
    { value: 'determinando estenose hemodinamicamente significativa (≥ 50%)', viewValue: 'Determinando estenose hemodinamicamente significativa (≥ 50%)' },
    { value: 'determinando suboclusão', viewValue: 'Determinando suboclusão' }
  ];
  estenoseStent: ArrayDropDown[] = [
    { value: 'sem sinais de estenose hemodinamicamente significativa', viewValue: 'Sem sinais de estenose hemodinamicamente significativa' },
    { value: 'com sinais de reestenose (> 50%)', viewValue: 'Com sinais de reestenose (> 50%)' },
    { value: 'ocluído', viewValue: 'Ocluído' }
  ];

  fluxoDistal: ArrayDropDown[] = [
    { value: 'bifásico', viewValue: 'Bifásico' },
    { value: 'monofásico', viewValue: 'Monofásico' },
    { value: 'tardus-parvus', viewValue: 'Tardus-parvus' }
  ];
  trombo: ArrayDropDown[] = [
    { value: 'Ausência de sinais de trombose no seu interior', viewValue: 'Sem trombose' },
    { value: 'Nota-se trombo mural no seu interior, sem determinar oclusão', viewValue: 'Trombo mural' },
    { value: 'Ausência de fluxo vascular detectável no seu interior, inferindo trombose', viewValue: 'Trombose' }
  ];

  unidadeMedida: string;


  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  showNaoCaracterizada = false;
  showPlaca = false;
  showMultiplasPlacas = false;
  showTrombose = false;
  showStent = false;
  showAneurisma = false;

  clickNormal() {
    this.showNaoCaracterizada = false;
    this.showPlaca = false;
    this.showMultiplasPlacas = false;
    this.showTrombose = false;
    this.showStent = false;
    this.showAneurisma = false;
    this.catchIDs();
  }
  clickNaoCaracterizada() {
    this.showNaoCaracterizada = true;
    this.showPlaca = false;
    this.showMultiplasPlacas = false;
    this.showTrombose = false;
    this.showStent = false;
    this.showAneurisma = false;
    this.catchIDs();
  }
  clickPlaca() {
    this.showNaoCaracterizada = false;
    this.showPlaca = true;
    this.showMultiplasPlacas = false;
    this.showTrombose = false;
    this.showStent = false;
    this.showAneurisma = false;
    this.catchIDs();
  }
  clickMultiplasPlacas() {
    this.showNaoCaracterizada = false;
    this.showPlaca = false;
    this.showMultiplasPlacas = true;
    this.showTrombose = false;
    this.showStent = false;
    this.showAneurisma = false;
    this.catchIDs();
  }
  clickTrombose() {
    this.showNaoCaracterizada = false;
    this.showPlaca = false;
    this.showMultiplasPlacas = false;
    this.showTrombose = true;
    this.showStent = false;
    this.showAneurisma = false;
    this.catchIDs();
  }
  clickStent() {
    this.showNaoCaracterizada = false;
    this.showPlaca = false;
    this.showMultiplasPlacas = false;
    this.showTrombose = false;
    this.showStent = true;
    this.showAneurisma = false;
    this.catchIDs();
  }
  clickAneurisma() {
    this.showNaoCaracterizada = false;
    this.showPlaca = false;
    this.showMultiplasPlacas = false;
    this.showTrombose = false;
    this.showStent = false;
    this.showAneurisma = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurBatimentos(){
    this.catchIDs();
  }

  blurInput(){
    this.catchIDs();
  }



  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdNaoCaracterizada.checked) {
      this.termoSelecionado.emit(this.rdNaoCaracterizada.id);

      //TODO: Falta corrigir a carga para tirar essa linha
      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.slMotivo && this.slMotivo.value){
        let objeto = { elemento: this.slMotivo, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdPlaca.checked) {
      this.termoSelecionado.emit(this.rdPlaca.id);

      if((this.slEcogenicidade && this.slEcogenicidade.value) &&
         (this.slEcotextura && this.slEcotextura.value) &&
         (this.slSuperficie && this.slSuperficie.value)&&
         (this.slEstenose && this.slEstenose.value) &&
         (this.slLocalizacao && this.slLocalizacao.value)){

         let objeto = { elemento: this.slEcogenicidade, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
        objeto = { elemento: this.slEcotextura, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
        objeto = { elemento: this.slSuperficie, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
        objeto = { elemento: this.slEstenose, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
        objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);

        //Com Medida
        if((this.inExtensao && this.inExtensao.nativeElement.value) &&
           (this.inEspessura && this.inEspessura.nativeElement.value)){

            let objetoElemento = {id: "2131", value: this._validacoes.formataDecimal(this.inEspessura.nativeElement.value)};
            let objetoCriado = { elemento: objetoElemento};
            this.termosGenericos.emit(objetoCriado);

            objetoElemento = {id: "2570", value: this._validacoes.formataDecimal(this.inExtensao.nativeElement.value)};
            objetoCriado = { elemento: objetoElemento};
            this.termosGenericos.emit(objetoCriado);

        }
      }
    }

    if (this.rdMultiplasPlacas.checked) {
      this.termoSelecionado.emit(this.rdMultiplasPlacas.id);

      //TODO: Falta corrigir a carga para tirar essa linha
      this.termoSelecionado.emit(this.rdPlaca.id);


      if (this.slFluxoDistal && this.slFluxoDistal.value){
        let objeto = { elemento: this.slFluxoDistal, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdTrombose.checked) {
      this.termoSelecionado.emit(this.rdTrombose.id);

      if (this.slLocalizacaoTrombose && this.slLocalizacaoTrombose.value){
        let objeto = { elemento: this.slLocalizacaoTrombose, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);

        //Com medida
        if(this.inExtensaoTrombose && this.inExtensaoTrombose.nativeElement.value){
          let objetoElemento = {id: "2144", value: this._validacoes.formataDecimal(this.inExtensaoTrombose.nativeElement.value)};
          let objetoCriado = { elemento: objetoElemento};
          this.termosGenericos.emit(objetoCriado);
        }
      }
    }

    if (this.rdStent.checked) {
      this.termoSelecionado.emit(this.rdStent.id);

      if ((this.slLocalizacaoStent && this.slLocalizacaoStent.value) &&
          (this.slEstenoseStent && this.slEstenoseStent.value)){

        let objeto = { elemento: this.slLocalizacaoStent, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
        objeto = { elemento: this.slEstenoseStent, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
          }
    }


    if (this.rdAneurisma.checked) {
      this.termoSelecionado.emit(this.rdAneurisma.id);

      if((this.slLocalizacaoAneurisma && this.slLocalizacaoAneurisma.value) &&
         (this.inCalibre && this.inCalibre.nativeElement.value) && (this.slTrombo && this.slTrombo.value)){

          let objeto = { elemento: this.slLocalizacaoAneurisma, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);

          let objetoElemento = {id: "2153", value: this._validacoes.formataDecimal(this.inCalibre.nativeElement.value)};
          let objetoCriado = { elemento: objetoElemento};
          this.termosGenericos.emit(objetoCriado);

          if (this.slTrombo.value === 'Ausência de sinais de trombose no seu interior') {
            objetoElemento = {id: this.semTrombo, value:''};
            objetoCriado = { elemento: objetoElemento};
            this.termosGenericos.emit(objetoCriado);
          } else {
            objeto = { elemento: this.slTrombo, isPrimeiraLesao: true};
            this.termosGenericos.emit(objeto);
          }
        }

    }



  this.listaCompleta.emit();
  }

}
