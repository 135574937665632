import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { PagamentoService } from 'src/app/services/pagamento.service';
import { Pagamento } from 'src/app/models/BO/Pagamento';
import { StatusPagamentoService } from '../../services/status-pagamento.service';
import { ViaCepServiceService } from 'src/app/services/via-cep-service.service';
import { Endereco } from 'src/app/models/BO/Endereco';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatSelect, MatDialog } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { finalize } from 'rxjs/operators';
import { AlertDialogComponent } from 'src/app/utils/dialog/alert-dialog/alert-dialog.component';
import { Validacoes } from '../../utils/validacoes';
import { CardLabel, FormLabel } from 'ngx-interactive-paycard';
import { VoucherService } from 'src/app/services/voucher.service';
import { Voucher } from 'src/app/models/BO/Voucher';

@Component({
  selector: 'app-pagamentos-direto',
  templateUrl: './pagamentos-direto.component.html',
  styleUrls: ['./pagamentos-direto.component.css']
})
export class PagamentosDiretoComponent implements OnInit {
  inNome: any;
  inNumero: any;
  inExpiracao: any;
  inCVV: any;
  inCPF: any;

  @Output() retornoPagamento = new EventEmitter();
  @Output() mostrarPagamento = new EventEmitter();
  @Input() planosSelecionados: any;

  @Input() DadosUsuario: any;
  @Input() DadosVoucher: Voucher;

  retornoPag: any;
  cartaoHash: string;
  endereco: Endereco;
  parcelamento: string = '';
  public loading: boolean;
  public formPagamentos: FormGroup;
  public formSubmitAttempt: Boolean;

  cardNumberFormat = "#### #### #### ####";
  cardNumberMask = "#### **** **** ####";

  cardLabel: CardLabel = {
    expires: 'Válido até',
    cardHolder: 'Nome do titular',
    fullName: 'Nome completo',
    mm: 'MM',
    yy: 'AA'
  };

  formLabel: FormLabel = {
    cardNumber: 'Número do cartão',
    cardHolderName: 'Titular do cartão',
    expirationDate: 'Data de expiração',
    expirationMonth: 'Mês',
    expirationYear: 'Ano',
    cvv: 'CVV',
    submitButton: 'Enviar',
  };

  constructor(private _servicePagamentos: PagamentoService,
    private _viaCEPService: ViaCepServiceService,
    private _statusPgto: StatusPagamentoService,
    private toastr: ToastrManager,
    private _dialog: MatDialog,
    private fb: FormBuilder,
    private utils: Validacoes,
    private _voucher: VoucherService
  ) { }

  ngOnInit() {
    this.formPagamentos = this.fb.group({
      inNumero: ['', [Validators.required,
      Validators.maxLength(20),
      Validators.minLength(14)]],
      cardNome: ['', Validators.required],
      exp: ['', Validators.required],
      cvv: ['', [Validators.required,
      Validators.maxLength(3),
      Validators.minLength(3)]],
      cpf: ['', [Validators.required,
      Validators.maxLength(14),
      Validators.minLength(11)]],
      // cep: ['', [Validators.required,
      // Validators.minLength(8),
      // Validators.maxLength(8)
      // ]],
      // rua: ['', Validators.required],
      // numero: ['', [Validators.required,
      // Validators.maxLength(10)]],
      // complemento: [null],
      // distrito: ['', Validators.required],
      // cidade: ['', Validators.required],
      // estado: ['', Validators.required],
      // paises: ['', Validators.required],
    });

    this.loading = false;
  }

  isFieldInvalid(field: string) {
    return (
      (!this.formPagamentos.get(field).valid && this.formPagamentos.get(field).touched) ||
      (this.formPagamentos.get(field).untouched && this.formSubmitAttempt)
    );
  }

  criptografarCartao() {
    const cartaoSemEspaco = this.inNumero.replace(/\s/g, '');
    if (cartaoSemEspaco) {
      this._servicePagamentos.getCartaoCriptografado(cartaoSemEspaco).subscribe(
        retorno => {
          this.cartaoHash = retorno.number_token;
          // this.cartaoHash = cartaoSemEspaco;
        }
      );
    }
  }

  clickConfirmar() {
    const pag = this.populaDadosForm();

    this.loading = true;

    const cartaoSemEspaco = this.inNumero.replace(/\s/g, '');
    if (cartaoSemEspaco) {
      this._servicePagamentos.getCartaoCriptografado(cartaoSemEspaco).subscribe(
        retorno => {
          this.cartaoHash = retorno.number_token;
          // this.cartaoHash = cartaoSemEspaco;
          const planos = [];

          this.planosSelecionados.selec.forEach(plano => {
            if (plano.id !== 2)
              planos.push(plano.id);
          });

          if (!pag) return;

          pag.CardHash = this.cartaoHash;
          
          console.log(pag);

          this.loading = true;
          this._servicePagamentos.registrarPagamento(pag)
          .pipe(finalize(() => this.loading = false))
          .subscribe(
            retPgto => {
              if (retPgto) {
                this.retornoPag = retPgto;
                this._statusPgto.emitStatusPgto(this.retornoPag.statusID);
                this.retornoPagamento.emit(this.retornoPag.statusID);
                if(this.DadosVoucher && this.retornoPag.statusID == 1){
                  this._voucher.salvaVoucherUsuario(this.DadosVoucher).subscribe(
                    res =>{
                      console.log(res);
                    },
                    erro=>{
                      console.log(erro);
                    })
                }
              }
            },
            erro => {
              this.toastr.errorToastr('Erro ao processar pagamento', 'Desculpe',
                {
                  position: 'top-right',
                  toastTimeout: 4000
                }
              );
            }
          );
        }
      );
    }
  }

  clickTrial() {
    const pag = this.populaDadosForm();

    this.loading = true;
    this._servicePagamentos.registrarTrial(pag)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        retPgto => {
          if (retPgto) {
            this.retornoPag = retPgto;
            this._statusPgto.emitStatusPgto(this.retornoPag.statusID);
            this.retornoPagamento.emit(this.retornoPag.statusID);
          }
        },
        erro => {
          this.toastr.errorToastr('Erro ao processar requisição tente novamente', 'Desculpe',
            {
              position: 'top-right',
              toastTimeout: 4000
            }
          );
        }
      );
  }

  public populaDadosForm() {
    const pag = new Pagamento();
    // this.criptografarCartao();

    const planos = [];

    this.planosSelecionados.selec.forEach(plano => {
      if (plano.id !== 2)
        planos.push(plano.id);
    });

    if (!pag) return;

    this.formPagamentos.patchValue({
      amout: pag.AmountValue = this.planosSelecionados.total,
      cvv: pag.CVV = this.inCVV,
      card: pag.CardHash = this.cartaoHash,
      cardNome: pag.CardName = this.inNome,
      parcel: pag.Parcel = this.parcelamento,
      user: pag.UserID = + localStorage.getItem('userID'),
      exp: this.inExpiracao,
      expMo: pag.ExpMonth = this.inExpiracao.substring(0, 2),
      expYe: pag.ExpYear = this.inExpiracao.substring(4, 6),
      list: pag.ListPlanID = planos,
      cpf: this.DadosUsuario.cpf = this.DadosUsuario.cpf.replace(/\D/g, '')
    });

    const address = {
      address: this.DadosUsuario.rua,
      number: +this.DadosUsuario.numero,
      complement: this.DadosUsuario.complemento,
      district: this.DadosUsuario.bairro,
      city: this.DadosUsuario.cidade,
      state: this.DadosUsuario.estado,
      country: this.DadosUsuario.pais,
      zipCode: this.DadosUsuario.cep
    };

    pag.CPF = this.DadosUsuario.cpf.replace(/\D/g, '');
    pag.AddressUser = address;
    if(this.DadosVoucher){
      pag.Voucher =  this.DadosVoucher.code;
    }

    return pag;
  }

  espacamentoCartao(value: string) {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length > 0) {
      this.inNumero = parts.join(' ');
    } else {
      this.inNumero = value;
    }
  }

  formatDataExp(event) {
    var inputChar = String.fromCharCode(event.keyCode);
    var code = event.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }

    event.target.value = event.target.value.replace(
      /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
    ).replace(
      /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
    ).replace(
      /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
    ).replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
    ).replace(
      /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
    ).replace(
      /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
    ).replace(
      /\/\//g, '/' // Prevent entering more than 1 `/`
    );
  }

  checkCPF(): Boolean {
    const cpf = this.formPagamentos.get('cpf').value;
    if (!this.utils.validarCPF(cpf)) {
      this.toastr.errorToastr('CPF inválido', 'Falhou!!',
        {
          position: 'top-right',
          toastTimeout: 4000
        }
      );
      this.formPagamentos.patchValue({
        cpf: null
      });
      return false;
    } else {
      return true;
    }
  }

  limpaDadosEndereco() {
    this.formPagamentos.patchValue({
      rua: null,
      numero: null,
      complemento: null,
      distrito: null,
      cidade: null,
      estado: null,
      paises: null,
      // cep: null
    });
  }

  populaDadosFormCEP(dados: Endereco) {
    this.formPagamentos.patchValue({

      rua: dados.logradouro,
      distrito: dados.bairro,
      cidade: dados.localidade,
      estado: dados.uf,
      paises: 'Brasil',
      // cep: dados.cep
    });
  }

  maskCPF(cpf: string) {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

    this.inCPF = cpf;
  }

  fechar() {
    return this.mostrarPagamento.emit(false);
  }

  alertStatusMensage(mensage: string) {
    const dialogRef = this._dialog.open(AlertDialogComponent, {
      width: '450px',
      data: mensage
    });
  }

  onSubmitEvent($event) {
    this.inNome = $event.cardName;
    this.inNumero = $event.cardNumber
    this.inCVV = $event.cvv;
    this.inExpiracao = $event.expirationMonth + $event.expirationYear;

    this.clickConfirmar();
  }

}
