import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardUser, DashboardMedicalReport } from '../models/BO/Dashboard';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getDashboardUser(userID: string): Observable<DashboardUser[]> {
    return this.http.get<DashboardUser[]>(environment.nLaudoApi + "Dashboard/DashboardUser/" + userID);
  }

  getDashboardMedicalReport(userID: string): Observable<DashboardMedicalReport[]> {
    return this.http.get<DashboardMedicalReport[]>(environment.nLaudoApi + "Dashboard/DashboardMedicalReport/" + userID);
  }
}
