import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarotidaDireitaComponent } from './TermosComponent/carotida-direita/carotida-direita.component';
import { CarotidaEsquerdaComponent } from './TermosComponent/carotida-esquerda/carotida-esquerda.component';
import {
  MatButtonModule,
  MatCardModule, MatCheckboxModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule, MatRadioModule, MatSelectModule,
  MatSidenavModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatDialogModule,
  MatToolbarModule, MatTooltipModule, MatSlideToggleModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {HttpClientModule} from '@angular/common/http';
import {LayoutModule} from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TextEditorModule} from '../../../../utils/textEditor/text-editor/text-editor.module';
import {CarotidasComponent} from './carotidas.component';
import { AteromatoseComponent } from './TermosComponent/ateromatose/ateromatose.component';
import { LesoesDireitaComponent } from './TermosComponent/dialog/lesoes-direita/lesoes-direita.component';
import { LesoesEsquerdaComponent } from './TermosComponent/dialog/lesoes-esquerda/lesoes-esquerda.component';
import { SvgDireitoComponent } from './TermosComponent/dialog/svg-direito/svg-direito.component';
import { SvgEsquerdoComponent } from './TermosComponent/dialog/svg-esquerdo/svg-esquerdo.component';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { CabecalhoComponent } from './TermosComponent/cabecalho/cabecalho.component';
import { RodapeComponent } from './TermosComponent/rodape/rodape.component';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatDialogModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    MatSlideToggleModule,
    TextEditorJSmodule,
  ],
  exports: [
    CarotidasComponent
  ],
  entryComponents: [
    SvgEsquerdoComponent,
    SvgDireitoComponent,
    LesoesDireitaComponent,
    LesoesEsquerdaComponent
  ],
  declarations: [
    CarotidasComponent,
    CarotidaDireitaComponent,
    CarotidaEsquerdaComponent,
    AteromatoseComponent,
    LesoesDireitaComponent,
    LesoesEsquerdaComponent,
    SvgDireitoComponent,
    SvgEsquerdoComponent,
    CabecalhoComponent,
    RodapeComponent
  ]
})
export class CarotidasModule { }
