import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validacoes } from '../../../../utils/validacoes';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { TermosDinamicos } from 'src/app/models/Helper/TermosDinamicos';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { CommonService } from 'src/app/services/common.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';
@Component({
  selector: 'app-pe',
  templateUrl: './pe.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./pe.component.css']
})
export class PeComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[1723],blockID:141},
    {listTerm:[1726],blockID:142},
    {listTerm:[1756],blockID:143},
    {listTerm:[1760],blockID:144},
    {listTerm:[1767],blockID:145},
    {listTerm:[1770],blockID:146},
    {listTerm:[1771],blockID:147},
    {listTerm:[1776],blockID:148},
  ]

  descricoes: DescricaoConclusao[] = [
    { value: 'Pele e tecido subcutâneo preservados.', blockID: 141, textTermID: 0, visivel: true },
    { value: 'Articulações locais sem derrame significativo.', blockID: 142, textTermID: 0, visivel: true },
    { value: 'Superfícies ósseas sem alterações significativas ao método.', blockID: 143, textTermID: 0, visivel: true },
    { value: 'Tendões flexores dos pododáctilos com ecogenicidade e espessura preservadas.', blockID: 144, textTermID: 0, visivel: true },
    { value: 'Tendões extensores dos pododáctilos de aspecto anatômico.', blockID: 145, textTermID: 0, visivel: true },
    { value: 'Musculatura regional íntegra.', blockID: 146, textTermID: 0, visivel: true },
    { value: 'Espaços intermetatársicos sem lesões aparentes.', blockID: 147, textTermID: 0, visivel: true },
    { value: 'Fáscia plantar com espessura e ecogenicidades preservadas.', blockID: 148, textTermID: 0, visivel: true }
  ];
  conclusoes: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 141, textTermID: 0, visivel: true },
    { value: '', blockID: 142, textTermID: 0, visivel: true },
    { value: '', blockID: 143, textTermID: 0, visivel: true },
    { value: '', blockID: 144, textTermID: 0, visivel: true },
    { value: '', blockID: 145, textTermID: 0, visivel: true },
    { value: '', blockID: 146, textTermID: 0, visivel: true },
    { value: '', blockID: 147, textTermID: 0, visivel: true },
    { value: '', blockID: 148, textTermID: 0, visivel: true }
  ];
  genericPeleTecido: TermosGenericos[] = [
    { value: '', selected: false, termID: 1725, term: '[Localizacao]' }
  ];

  genericDerrameArticular: TermosGenericos[] = [
    { value: '', selected: false, termID: 1728, term: '[Articulacao]' },
    { value: '', selected: false, termID: 1729, term: '[Dedos]' },
    { value: '', selected: false, termID: 1730, term: '[Volume]' }
  ];

  genericSuperficieOssea: TermosGenericos[] = [
    { value: '', selected: false, termID: 1758, term: '[Articulacao]' },
    { value: '', selected: false, termID: 1759, term: '[Dedos]' }
  ];

  genericTendoesFlexores: TermosGenericos[] = [
    { value: '', selected: false, termID: 1762, term: '[Dedos]' }
  ];

  genericTendoesExtensores: TermosGenericos[] = [
    { value: '', selected: false, termID: 1769, term: '[Dedos]' }
  ];

  genericEspaco: TermosGenericos[] = [
    { value: '', selected: false, termID: 1774, term: '[Espaco]' },
    { value: '', selected: false, termID: 1775, term: '[Medida eixo trans]' }
  ];

  genericFaasciaPlantar: TermosGenericos[] = [
    { value: '', selected: false, termID: 1784, term: '[Espessura]' },
    { value: '', selected: false, termID: 1785, term: '[Distancia inser calc]' },
    { value: '', selected: false, termID: 1786, term: '[Localizacao terco]' },
    { value: '', selected: false, termID: 1787, term: '[Banda]' },
    { value: '', selected: false, termID: 1788, term: '[Medida]' }
  ];

  Subtitulo: string;
  subtituloBloco:string;
  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  objetoGrupoLesao: any;
  dinamicLesions: TermosDinamicos[] = [];
  dinamicLesion: any;
  lesionRow = 1;
  cabecalhoTexto = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  Lado = '';
  rodapeTexto = '';

  cabecalho = false;
  lateralidade = true;
  peleTecido = false;
  derrameArticular = false;
  superficieOssea = false;
  tendoesFlexores = false;
  tendoesExtensores = false;
  musculatura = false;
  espacoInter = false;
  fasciaPlantar = false;

  rodape = false;
  indicacao = false;
  achadosTexto = false;
  medicalReportTypeID = 21;
  peleTecidoID = 141;
  derrameArticularID = 142;
  superficieOsseaID = 143;
  tendoesFlexoresID = 144;
  tendoesExtensoresID = 145;
  musculaturaID = 146;
  espacoInterID = 147;
  fasciaPlantarID = 148;
  public switchFormReport = true;
  public generoLateralidade: string;
  public showForm: Boolean = true;
  unidadeMedida: string;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  editLaudoMode: boolean = false;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _copyPaste: CopyPaste,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public dialog: MatDialog,
    public _addText: AddText,
    private dataService:DataTermService,
    private _config: ConfigLaudoService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("peAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.generoLateralidade = 'masculino';
    this.Subtitulo = 'Lateralidade';
    this.subtituloBloco = this.Subtitulo;
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [
      this.peleTecidoID,
      this.derrameArticularID,
      this.superficieOsseaID,
      this.tendoesFlexoresID,
      this.tendoesExtensoresID,
      this.musculaturaID,
      this.espacoInterID,
      this.fasciaPlantarID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "peMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "pe");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-pe');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-pe');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  }

  public resetaLaudo(){
    this._pouchService.getAll("peAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.Lado = "";
    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.achadosAdTexto = "";
    this.rodapeTexto = "";
    this.showForm = false;

    setTimeout(() => {
      this.showForm = true;
      this.AdicionaTexto(this.newEditor);
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo:'Preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Lateralidade',
      id: 'lateralidade',
      name: 'lateralidade',
      selected: true,
    },
    {
      titulo: 'Pele e tecido subcutâneo',
      id: this.peleTecidoID,
      name: 'pele-tecido',
      selected: false,
    },
    {
      titulo: 'Derrame articular',
      id: this.derrameArticularID,
      name: 'derrame-articular',
      selected: false,
    },
    {
      titulo: 'Superfícies ósseas',
      id: this.superficieOsseaID,
      name: 'superficie-ossea',
      selected: false,
    },
    {
      titulo: 'Tendões Flexores',
      id: this.tendoesFlexoresID,
      name: 'tendoes-flexores',
      selected: false
    },
    {
      titulo: 'Tendões Extensores',
      id: this.tendoesExtensoresID,
      name: 'tendoes-extensores',
      selected: false
    },
    {
      titulo: 'Musculatura Regional',
      id: this.musculaturaID,
      name: 'musculatura',
      selected: false
    },
    {
      titulo: 'Espaços intermetatársicos',
      id: this.espacoInterID,
      name: 'espaco',
      selected: false
    },
    {
      titulo: 'Fáscia plantar',
      id: this.fasciaPlantarID,
      name: 'fascia-plantar',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados-adicionais',
      name: 'achados-adicionais',
      subtitulo:'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }


    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.lateralidade = (itemSelecionado.id === 'lateralidade');
    this.peleTecido = (itemSelecionado.id === this.peleTecidoID);
    this.derrameArticular = (itemSelecionado.id === this.derrameArticularID);
    this.superficieOssea = (itemSelecionado.id === this.superficieOsseaID);
    this.tendoesFlexores = (itemSelecionado.id === this.tendoesFlexoresID);
    this.tendoesExtensores = (itemSelecionado.id === this.tendoesExtensoresID);
    this.musculatura = (itemSelecionado.id === this.musculaturaID);
    this.espacoInter = (itemSelecionado.id === this.espacoInterID);
    this.fasciaPlantar = (itemSelecionado.id === this.fasciaPlantarID);
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados-adicionais');
    this.rodape = (itemSelecionado.id === 'rodape');

  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.dinamicLesions = [];
    this.lesionRow = 1;

    this.genericPeleTecido = [
      { value: '', selected: false, termID: 1725, term: '[Localizacao]' }
    ];

    this.genericDerrameArticular = [
      { value: '', selected: false, termID: 1728, term: '[Articulacao]' },
      { value: '', selected: false, termID: 1729, term: '[Dedos]' },
      { value: '', selected: false, termID: 1730, term: '[Volume]' }
    ];

    this.genericSuperficieOssea = [
      { value: '', selected: false, termID: 1758, term: '[Articulacao]' },
      { value: '', selected: false, termID: 1759, term: '[Dedos]' }
    ];

    this.genericTendoesFlexores = [
      { value: '', selected: false, termID: 1762, term: '[Dedos]' }
    ];

    this.genericTendoesExtensores = [
      { value: '', selected: false, termID: 1769, term: '[Dedos]' }
    ];

    this.genericEspaco = [
      { value: '', selected: false, termID: 1774, term: '[Espaco]' },
      { value: '', selected: false, termID: 1775, term: '[Medida eixo trans]' }
    ];

    this.genericFaasciaPlantar = [
      { value: '', selected: false, termID: 1784, term: '[Espessura]' },
      { value: '', selected: false, termID: 1785, term: '[Distancia inser calc]' },
      { value: '', selected: false, termID: 1786, term: '[Localizacao terco]' },
      { value: '', selected: false, termID: 1787, term: '[Banda]' },
      { value: '', selected: false, termID: 1788, term: '[Medida]' }
    ];

  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        indicacao: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        achados: this.rodapeTexto
      });
    }
  }

  enviaLados($event) {
    this.Lado = $event;
    this.AdicionaTexto(this.newEditor);
  }

  incluirLesoesDinamicas($event) {
    let lesoesValue = $event.elemento.value;

    if ($event.linha == 1 && ($event.posicao == 0 || $event.posicao == 1)) {
      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaTermos($event.elemento);
      }
    }
    switch ($event.elemento.id) {
      // Derrame Articular
      case '1728':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1728,
          lesionID: 0,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Articulacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1729':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1729,
          lesionID: 0,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1730':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1730,
          lesionID: 0,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Volume]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      //SuperficieOssea
      case '1758':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1758,
          lesionID: 0,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Articulacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1759':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1759,
          lesionID: 0,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      //Tendoes Flexores
      case '1762':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1762,
          lesionID: 0,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      //Tendoes Extensores
      case '1769':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1769,
          lesionID: 0,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Dedos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      //Espaço
      case '1774':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1774,
          lesionID: 0,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Espaco]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1775':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1775,
          lesionID: 0,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida eixo trans]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      //Fascia Plantar
      case '1786':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1786,
          lesionID: 1783,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao terco]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1787':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 1787,
          lesionID: 1783,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Banda]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '1788':
        this.dinamicLesion = {
          value: this._validacoes.formataDecimal(lesoesValue),
          selected: true,
          termID: 1788,
          lesionID: 1783,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }

    this.lesionRow = $event.linha;
  }

  adicionaSelecaoPeleTecido($event) {
    this.genericPeleTecido.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoDerrameArticular($event) {
    this.genericDerrameArticular.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoSuperficieOssea($event) {
    this.genericSuperficieOssea.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoTendoesFlexores($event) {
    this.genericTendoesFlexores.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoTendoesExtensores($event) {
    this.genericTendoesExtensores.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoEspaco($event) {
    this.genericEspaco.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoFasciaPlantar($event) {
    this.genericFaasciaPlantar.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.peleTecido) {
        this.term.blockID = this.peleTecidoID;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.peleTecidoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaPeleTecido(resultado.text);
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.peleTecidoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.peleTecidoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaPeleTecido(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.peleTecidoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.peleTecidoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.peleTecidoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.derrameArticular) {
        this.term.blockID = this.derrameArticularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.derrameArticularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDerrameArticular(resultado.text, contadorTexto);
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.derrameArticularID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.derrameArticularID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDerrameArticular(resultado.conclusion, contadorTexto);
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.derrameArticularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.derrameArticularID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.derrameArticularID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.superficieOssea) {
        this.term.blockID = this.superficieOsseaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.superficieOsseaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaSuperficieOssea(resultado.text, contadorTexto);
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.superficieOsseaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.superficieOsseaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaSuperficieOssea(resultado.conclusion, contadorTexto);
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.superficieOsseaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.superficieOsseaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.superficieOsseaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendoesFlexores) {
        this.term.blockID = this.tendoesFlexoresID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.tendoesFlexoresID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaTendoesFlexores(resultado.text, contadorTexto);
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.tendoesFlexoresID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.tendoesFlexoresID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaTendoesFlexores(resultado.conclusion, contadorTexto);
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.tendoesFlexoresID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendoesFlexoresID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.tendoesFlexoresID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.tendoesExtensores) {
        this.term.blockID = this.tendoesExtensoresID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.tendoesExtensoresID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaTendoesExtensores(resultado.text, contadorTexto);
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.tendoesExtensoresID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.tendoesExtensoresID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaTendoesExtensores(resultado.conclusion, contadorTexto);
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.tendoesExtensoresID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.tendoesExtensoresID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.tendoesExtensoresID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.espacoInter) {
        this.term.blockID = this.espacoInterID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.espacoInterID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoEspaco(resultado.text, contadorTexto);
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.espacoInterID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.espacoInterID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaDescricaoEspaco(resultado.conclusion, contadorTexto);
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.espacoInterID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.espacoInterID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.espacoInterID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.fasciaPlantar) {
        this.term.blockID = this.fasciaPlantarID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.fasciaPlantarID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoFasciaPlantar(resultado.text, contadorTexto);
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.fasciaPlantarID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.fasciaPlantarID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.TextoFasciaNormal(resultado.conclusion);
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.fasciaPlantarID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.fasciaPlantarID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.fasciaPlantarID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      }
    }
  }

  MontaPeleTecido(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1725) {
        this.genericPeleTecido.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDerrameArticular(texto, contadorTexto) {

    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoDerrameDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = texto;
    }
    return texto;
  }

  MontaSuperficieOssea(texto, contadorTexto) {

    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoSuperficieOssea(texto, contadorTexto, lesaoID);
    } else {
      texto = texto;
    }
    return texto;
  }

  MontaTendoesFlexores(texto, contadorTexto) {

    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoTendoesFlexores(texto, contadorTexto, lesaoID);
    } else {
      texto = texto;
    }
    return texto;
  }

  MontaTendoesExtensores(texto, contadorTexto) {

    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoTendoesExtensores(texto, contadorTexto, lesaoID);
    } else {
      texto = texto;
    }
    return texto;
  }

  MontaDescricaoEspaco(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoespacoNeuroma(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoEspacoBursite(texto);
    }
    return texto;
  }

  MontaDescricaoFasciaPlantar(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => {
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoFasciaDinamico(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoFasciaNormal(texto);
    }
    return texto;
  }

  TextoDerrameDescDinamica(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length > 1) {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (+item.termID == 1728 || +item.termID == 1729 || +item.termID == 1730) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length > 1) {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID == 1728 || +item.termID == 1729 || +item.termID == 1730) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    return texto;
  }

  TextoSuperficieOssea(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length > 1) {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (+item.termID == 1758 || +item.termID == 1759) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length > 1) {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID == 1758 || +item.termID == 1759) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    return texto;
  }

  TextoTendoesFlexores(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length > 1) {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (+item.termID == 1762) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length > 1) {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID == 1762) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    return texto;
  }

  TextoTendoesExtensores(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length > 1) {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (+item.termID == 1769) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length > 1) {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID == 1769) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    return texto;
  }

  TextoEspacoBursite(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1774 || +term === 1775) {
        this.genericEspaco.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  TextoespacoNeuroma(texto, contadorTexto, lesaoID) {

    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length > 1) {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (+item.termID == 1774 || +item.termID == 1775) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length > 1) {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID == 1774 || +item.termID == 1775) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    return texto;
  }

  TextoFasciaNormal(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1784 || +term === 1785 ||
        +term === 1786 || +term === 1787 || +term === 1788) {
        this.genericFaasciaPlantar.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  TextoFasciaDinamico(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length > 1) {
      textoRetorno = textoSplit[0] + '</br>';
      textoDinamico = textoSplit[1];
    }

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (+item.termID == 1786 || +item.termID == 1787 || +item.termID == 1788) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          //textoRetorno = textoRetorno + '</br>';
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length > 1) {
            textoDinamico = '</br>' + textoSplit[1];
          }

          if (+item.termID == 1786 || +item.termID == 1787 || +item.termID == 1788) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor(){
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]+this.Lado.toUpperCase()}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoes[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.Lado.toUpperCase();
    this.htmlContent += maskSplit[1];
    this.htmlContent += this.indicacaoTexto;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContent += maskSplit[2];

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
    }
    this.htmlContent += maskSplit[3];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }
    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
			} else if(indicacao || achados) {
				if(indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
