import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-imprimir',
  templateUrl: './imprimir.component.html',
  styleUrls: ['./imprimir.component.css']
})
export class ImprimirComponent implements OnInit {

  constructor(
    private _domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {  }

  copyText(id: string) {
    let laudoCompleto = document.getElementById(id);
    
    if(laudoCompleto === undefined || laudoCompleto === null) {
      laudoCompleto = document.getElementById('editor');
    }

    const paragraphs = Array.from(document.getElementsByClassName('ce-paragraph'));
    paragraphs.forEach(paragraph => {
      paragraph.parentElement.parentElement.style.removeProperty('background');
      paragraph.parentElement.parentElement.style.removeProperty('border-left');
    });
    
    this.createPdf(laudoCompleto);
  }

  createPdf(laudoCompletoPdf) {
    const data = new Date;
    let tituloPdf;
    let tituloExame;
    let codigoLaudo;

    if (document.querySelector('.laudoHeader h1')) {
      let nomeExame = document.querySelector('.laudoHeader h1').textContent;
      let nomeExameSplit = nomeExame.split("-",1);
      tituloExame = nomeExameSplit.toString()
    } else 
      tituloExame = document.getElementById('laudoHeader').innerText;


    if ((<HTMLInputElement>document.getElementById('codigoLaudo'))) 
      codigoLaudo = (<HTMLInputElement>document.getElementById('codigoLaudo')).value;
    else 
      codigoLaudo = document.getElementById('codigoLaudoLista').innerText;


    if (codigoLaudo !== undefined && codigoLaudo !== '' && codigoLaudo !== '-') 
      tituloPdf = tituloExame + " - " + codigoLaudo;  
    else 
      tituloPdf = tituloExame + " - " + data.getDate() + "-" + (data.getMonth()+1) + "-" + data.getFullYear() + " - " + data.getHours() + "-" + data.getMinutes();  
    

    var opt = {
      margin:       [ 20, 20, 20, 20 ],
      enableLinks:  true,
      filename:     tituloPdf,
      image:        { type: 'jpeg', quality: 1 },
      html2canvas:  { scale: 1 },
      jsPDF:        { unit: 'mm', format: 'letter', orientation: 'portrait' }
    };

    html2pdf().from(laudoCompletoPdf).set(opt).toPdf().get('pdf').save();
  }
}
