import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatCheckbox, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-calcificacoes',
  templateUrl: './calcificacoes.component.html',
  styleUrls: ['./calcificacoes.component.css']
})
export class CalcificacoesComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusentes') rdAusentes: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('ckLojaRenal') ckLojaRenal: MatCheckbox;
  @ViewChild('ckPelve') ckPelve: MatCheckbox;
  @ViewChild('ckBexiga') ckBexiga: MatCheckbox;
  @ViewChild('ckLojaVesicular') ckLojaVesicular: MatCheckbox;
  @ViewChild('ckVasculares') ckVasculares: MatCheckbox;

  @ViewChild('slLateralidadeLojaRenal') slLateralidadeLojaRenal : MatSelect;
  @ViewChild('slLateralidadePelve') slLateralidadePelve : MatSelect;
  @ViewChild('slLocalizacaoVascular') slLocalizacaoVascular : MatSelect;

  @ViewChild('inMedida') inMedida: ElementRef;
  @ViewChild('inMedidaPelve') inMedidaPelve: ElementRef;
  @ViewChild('inMedidaBexiga') inMedidaBexiga: ElementRef;

  showAndHideTerms;
  countLesoes = 0;
  presente=true;

  lateralidadeLojaRenal: ArrayDropDown[]=[
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ]

  lateralidadePelve: ArrayDropDown[]=[
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ]
  localizacaoVascular: ArrayDropDown[]=[
    {value: 'da aorta', viewValue: 'Aorta' },
    {value: 'dos vasos ilíacos', viewValue: 'Vasos ilíacos' },
    {value: 'da aorta e vasos ilíacos', viewValue: 'Aorta e Vasos ilíacos' },
    {value: 'da aorta e vasos ilíacos à direita', viewValue:'Aorta e Vasos ilíacos à direita' },
    {value: 'da aorta e vasos ilíacos à esquerda', viewValue:'Aorta e Vasos ilíacos à esquerda'}
  ]

  constructor() { }

  ngOnInit() {
      this.showAndHideTerms = {
        lojaRenal:false,
        pelve:false,
        bexiga:false,
        vasculares:false
      }
    }

  clickDefault(){
    this.checkAlterado();
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  clickNormal(){
    this.showAndHideTerms.lojaRenal=false;
    this.showAndHideTerms.pelve=false;
    this.showAndHideTerms.bexiga=false;
    this.showAndHideTerms.vasculares=false;

    this.ckLojaRenal.checked=false;
    this.ckPelve.checked=false;
    this.ckBexiga.checked=false;
    this.ckLojaVesicular.checked=false;
    this.ckVasculares.checked=false;

    this.checkAlterado();
    this.catchIDs();
  }

  clickLojaRenal(){
    this.showAndHideTerms.lojaRenal= !this.showAndHideTerms.lojaRenal;
    this.checkAlterado();
    this.catchIDs();
  }

  clickPelve(){
    this.showAndHideTerms.pelve= !this.showAndHideTerms.pelve;
    this.checkAlterado();
    this.catchIDs();
  }

  clickBexiga(){
    this.showAndHideTerms.bexiga= !this.showAndHideTerms.bexiga;
    this.checkAlterado();
    this.catchIDs();
  }

  clickVasculares(){
    this.showAndHideTerms.vasculares= !this.showAndHideTerms.vasculares;
    this.checkAlterado();
    this.catchIDs();
  }

  checkAlterado(){
    if(this.ckLojaRenal.checked || this.ckPelve.checked || this.ckBexiga.checked || this.ckLojaVesicular.checked || this.ckVasculares.checked){
      this.rdPresente.checked=true;
      this.rdAusentes.checked=false;
    }else{
      this.rdPresente.checked=false;
      this.rdAusentes.checked=true;
    }
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if(this.countLesoes > 0){
      this.emitLojasRenaisLesao();
    } else {
      this.emitLojasRenaisSimples();
    }

    if(this.countLesoes > 0){
      this.emitPelveLesao();
    } else {
      this.emitPelveSimples();
    }

    if(this.countLesoes > 0){
      this.emitBexigaLesao();
    } else {
      this.emitBexigaSimples();
    }

    if(this.countLesoes > 0){
      this.emitVesiculaLesao();
    } else {
      this.emitVesiculaSimples();
    }

    if(this.countLesoes > 0){
      this.emitVascularLesao();
    } else {
      this.emitVascularSimples();
    }

    if(this.rdAusentes.checked){
      this.termoSelecionado.emit(this.rdAusentes.id);
    }

    this.listaCompleta.emit();
  }

  emitLojasRenaisSimples() {
    if(this.ckLojaRenal.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLojaRenal.id);

      if(this.slLateralidadeLojaRenal && this.slLateralidadeLojaRenal.value){
        if(this.slLateralidadeLojaRenal.value == 'bilateral'){
          let objetoMultiplo= {id:5253, value: this.slLateralidadeLojaRenal.value };
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }else{
          let objetoUnico= {id:5252, value:this.slLateralidadeLojaRenal.value };
          const objeto = {elemento: objetoUnico, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }
      if (this.inMedida && this.inMedida.nativeElement.value != undefined) {
        const objeto = { elemento: this.inMedida.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitLojasRenaisLesao() {
    if(this.ckLojaRenal.checked){
      this.countLesoes++;
      const objeto = { id: this.ckLojaRenal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLateralidadeLojaRenal && this.slLateralidadeLojaRenal.value){
        if(this.slLateralidadeLojaRenal.value == 'bilateral'){
          let objetoMultiplo= {id:5253, value: this.slLateralidadeLojaRenal.value };
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);
        }else{
          let objetoUnico= {id:5252, value: this.slLateralidadeLojaRenal.value };
          const objeto = {elemento: objetoUnico, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.inMedida && this.inMedida.nativeElement.value != undefined) {
        const objeto = { elemento: this.inMedida.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

  }

  emitPelveSimples(){
    if(this.ckPelve.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckPelve.id);

      if(this.slLateralidadePelve && this.slLateralidadePelve.value){
          const objeto = {elemento: this.slLateralidadePelve, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
      }

      if (this.inMedidaPelve && this.inMedidaPelve.nativeElement.value != undefined) {
        const objeto = { elemento: this.inMedidaPelve.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitPelveLesao(){
    if(this.ckPelve.checked){
      this.countLesoes++;
      const objeto = { id: this.ckPelve.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLateralidadePelve && this.slLateralidadePelve.value){
          const objeto = {elemento: this.slLateralidadePelve, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);
      }

      if (this.inMedidaPelve && this.inMedidaPelve.nativeElement.value != undefined) {
        const objeto = { elemento: this.inMedidaPelve.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitBexigaSimples(){
    if(this.ckBexiga.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckBexiga.id);

      if (this.inMedidaBexiga && this.inMedidaBexiga.nativeElement.value != undefined) {
        const objeto = { elemento: this.inMedidaBexiga.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitBexigaLesao(){
    if(this.ckBexiga.checked){
      this.countLesoes++;
      const objeto = { id: this.ckBexiga.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inMedidaBexiga && this.inMedidaBexiga.nativeElement.value != undefined) {
        const objeto = { elemento: this.inMedidaBexiga.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitVesiculaSimples(){
    if(this.ckLojaVesicular.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLojaVesicular.id);
    }
  }

  emitVesiculaLesao(){
    if(this.ckLojaVesicular.checked){
      this.countLesoes++;
      const objeto = { id: this.ckLojaVesicular.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitVascularSimples(){
    if(this.ckVasculares.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckVasculares.id);

      if(this.slLocalizacaoVascular && this.slLocalizacaoVascular.value){
          const objeto = {elemento: this.slLocalizacaoVascular, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
      }
    }
  }

  emitVascularLesao(){
    if(this.ckVasculares.checked){
      this.countLesoes++;
      const objeto = { id: this.ckVasculares.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLocalizacaoVascular && this.slLocalizacaoVascular.value){
          const objeto = {elemento: this.slLocalizacaoVascular, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);
      }
    }
  }
}
