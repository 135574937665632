import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatTooltipModule, 
  MatSlideToggleModule} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from 'src/app/utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { MorfologicoSegundoTrimestreDopplerComponent } from './morfologico-segundo-trimestre-doppler.component';
import { ObsCalculadoraModule } from '../../obstetrico/obs-calculadora/obs-calculadora.module';
import { UsgMorfologicaPrimeiroTrimestreModule } from '../../obstetrico/usg-morfologica-primeiro-trimestre/usg-morfologica-primeiro-trimestre.module';
import { MorfologicoSegundoTrimestreModule } from '../../obstetrico/morfologico-segundo-trimestre/morfologico-segundo-trimestre.module';
import { ArteriasUterinasComponent } from './TermosComponents/arterias-uterinas/arterias-uterinas.component';
import { TransvSegTercTrimestreModule } from '../../obstetrico/transv-seg-terc-trimestre/transv-seg-terc-trimestre.module';
import { UsgMorfoPrimDopplerModule } from '../../obstetrico/usg-morfo-prim-doppler/usg-morfo-prim-doppler.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';
// import { CrescimentoFetalComponent } from '../usg-morfologica-primeiro-trimestre/termosComponents/crescimento-fetal/crescimento-fetal.component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    ObsCalculadoraModule,
    UsgMorfologicaPrimeiroTrimestreModule, 
    MorfologicoSegundoTrimestreModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
    UsgMorfoPrimDopplerModule, 
    TransvSegTercTrimestreModule
  ],
  exports: [

  ],
  declarations: [
    MorfologicoSegundoTrimestreDopplerComponent,
    ArteriasUterinasComponent,
    
  ], 
  entryComponents: [
  ]
})
export class MorfologicoSegundoTrimestreDopplerModule { }
