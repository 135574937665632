import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MedicalReportConfig } from '../models/BO/MedicalReportConfig';

@Injectable({
  providedIn: 'root'
})
export class ConfigLaudoService {

  rules = new Array;

  constructor(private _http: HttpClient) { }


  carregarConfig(): Observable<Array<MedicalReportConfig>> {
    const url = environment.nLaudoApi + 'MedicalReportRules/BuscaLaudoConfig';

    return this._http.get<Array<MedicalReportConfig>>(url);
  }

  buscaConfig(userID: string): Observable<Array<MedicalReportConfig>> {
    const url = environment.nLaudoApi + 'MedicalReportRules/BuscaLaudoConfig/';
    return this._http.get<Array<MedicalReportConfig>>(url + userID);
  }

  salvaConfig(config: MedicalReportConfig[]): Observable<MedicalReportConfig> {
    const url = environment.nLaudoApi + 'MedicalReportRules/SalvaConfig';
    let listObj: any[]= new Array;
    config.forEach( x => {
     listObj.push({
        "description": x.description,
        "active": x.active,
        "MedicalReportRuleID": listObj.length+1,
        "userID": x.userId,
        "id": x.id
      });
    });

    const obj = listObj;

    return this._http.post<MedicalReportConfig>(url, obj);
  }

  buscarRules(usuario: string): Promise<any> {
    return this.buscaConfig(usuario).toPromise();
  }

  carregarConfigs(): Promise<any> {
    return this.carregarConfig().toPromise();
  }

  async buscarRule(usuario: string) {
    let $config: MedicalReportConfig[];
    if (!usuario) return;

    const userID = localStorage.getItem('userID');
    const regras = await this.buscarRules(userID.toString());
    if(regras && regras.length > 0){
      this.rules = regras;
    }
    else{
      let regras2 = await this.carregarConfigs();
      regras2.forEach(x => {
        this.rules.push({
          "description": x.description,
          "active": x.active,
          "MedicalReportRuleID": x.id,
          "userID": x.userId
        });
      });
    }
  }

}
