import { trigger, transition, query, stagger, animate, style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Injuries, Term } from 'src/app/models/BO/Term';
import { TermResult } from 'src/app/models/BO/TermResult';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { TermosGenericos } from 'src/app/models/Helper/TermosGenericos';
import { CommonService } from 'src/app/services/common.service';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';
import { LaudosService } from 'src/app/services/laudos.service.spec';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-rx-coluna-toracica',
  templateUrl: './rx-coluna-toracica.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./rx-coluna-toracica.component.css']
})
export class RxColunaToracicaComponent implements OnInit {

  listTermDefault=[
    {listTerm:[5022],blockID:357},
    {listTerm:[5024],blockID:358},
    {listTerm:[5044],blockID:359},
    {listTerm:[5049],blockID:360},
    {listTerm:[5067],blockID:361},
    {listTerm:[5084],blockID:362},
    {listTerm:[5094],blockID:363},
    {listTerm:[5098],blockID:364},
    {listTerm:[0],blockID:365},
    {listTerm:[5094],blockID:366},
    {listTerm:[5134],blockID:367}
  ]

  descricoes: DescricaoConclusao[] = [
    {
      value: '',//tecnica
      blockID: 357, textTermID: 0, visivel: true
    },
    {
      value: 'Textura óssea preservada.',
      blockID: 358, textTermID: 0, visivel: true
    },
    {
      value: 'Alinhamento preservado dos corpos vertebrais.',
      blockID: 359, textTermID: 0, visivel: true
    },
    {
      value: 'Ausência de lesões ósseas focais.',
      blockID: 360, textTermID: 0, visivel: true
    },
    {
      value: 'Corpos vertebrais torácicos com altura preservada.',
      blockID: 361, textTermID: 0, visivel: true
    },
    {
      value: '',
      blockID: 362, textTermID: 0, visivel: true
    },
    {
      value: '',
      blockID: 363, textTermID: 0, visivel: true
    },
    {
      value: 'Espaços discais torácicos sem alterações.',
      blockID: 364, textTermID: 0, visivel: true
    },
    {
      value: '',
      blockID: 365, textTermID: 0, visivel: true
    },
    {
      value: '',
      blockID: 366, textTermID: 0, visivel: true
    },
    {
      value: '',
      blockID: 367, textTermID: 0, visivel: true
    },
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: '', blockID: 343, textTermID: 0, visivel: true },
    { value: '', blockID: 344, textTermID: 0, visivel: true },
    { value: '', blockID: 345, textTermID: 0, visivel: true },
    { value: '', blockID: 346, textTermID: 0, visivel: true },
    { value: '', blockID: 347, textTermID: 0, visivel: true },
    { value: '', blockID: 348, textTermID: 0, visivel: true },
  ];

  genericTecnica: TermosGenericos[] = [];

  genericAlinhamento: TermosGenericos[] = [
    { value: '', selected: false, termID: 5030, term: '[Lateralidade]' },
    { value: '', selected: false, termID: 5034, term: '[LateralidadeSinuosaPri]' },
    { value: '', selected: false, termID: 5036, term: '[NivelSinuosaPri]' },
    { value: '', selected: false, termID: 5035, term: '[LateralidadeSinuosaSeg]' },
    { value: '', selected: false, termID: 5037, term: '[NivelSinuosaSeg]' },
    { value: '', selected: false, termID: 5039, term: '[GrauAnterolistese]' },
    { value: '', selected: false, termID: 5040, term: '[NivelAnterolistese]' },
    { value: '', selected: false, termID: 5042, term: '[GrauRetrolistese]' },
    { value: '', selected: false, termID: 5043, term: '[NivelRetrolistese]' },
  ];

  genericLesoesOsseas:TermosGenericos[]=[
    { value: '', selected: false, termID: 5051, term: '[Nivel]' },
    { value: '', selected: false, termID: 5054, term: '[NivelEsclerotica]' }
  ]

  genericCorposVertebrais:TermosGenericos[]=[
    { value: '', selected: false, termID: 5069, term: '[NivelOsteofitose]' },
    { value: '', selected: false, termID: 5073, term: '[NivelFraturaUnica]' },
    { value: '', selected: false, termID: 5074, term: '[NivelFraturaMultipla]' },
    { value: '', selected: false, termID: 5077, term: '[NivelFraturaReducao]' },
    { value: '', selected: false, termID: 5078, term: '[ReducaoDaAltura]' }
  ]

  genericProcessoTransverso: TermosGenericos[]=[
    { value: '', selected: false, termID: 5091, term: '[NivelDireito]' },
    { value: '', selected: false, termID: 5090, term: '[NivelEsquerdo]' },
  ]

  genericInterapofisarias: TermosGenericos[]=[
    { value: '', selected: false, termID: 5096, term: '[Nivel]' }
  ]

  genericEspacosDiscais: TermosGenericos[]=[
    { value: '', selected: false, termID: 5100, term: '[Nivel]' },
    { value: '', selected: false, termID: 5101, term: '[Niveis]' }
  ]

  genericPosCirurgico: TermosGenericos[]=[
    { value: '', selected: false, termID: 5104, term: '[LateralidadeSoltura]' },
    { value: '', selected: false, termID: 5105, term: '[NivelSoltura]' },
    { value: '', selected: false, termID: 5108, term: '[LateralidadeParafuso]' },
    { value: '', selected: false, termID: 5109, term: '[NivelParafuso]' },
    { value: '', selected: false, termID: 5112, term: '[LateralidadeHaste]' },
    { value: '', selected: false, termID: 5113, term: '[NivelHaste]' },
    { value: '', selected: false, termID: 5115, term: '[NivelEspacador]' },
    { value: '', selected: false, termID: 5117, term: '[NivelAncora]' },
    { value: '', selected: false, termID: 5119, term: '[NivelProtese]' }
  ]
  genericDispositivos: TermosGenericos[]=[];

  genericProcessosEspinhosos: TermosGenericos[]=[
    { value: '', selected: false, termID: 5138, term: '[Nivel]' },
    { value: '', selected: false, termID: 5137, term: '[Niveis]' }
  ]

  constructor(
    private _selected: SelectedItemEmiter,
    private _copyPaste: CopyPaste,
    public _dialog: MatDialog,
    private _toastr: ToastrManager,
    private _laudoService: LaudosService,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _serviceMedicalReport: MedicalReportService,
    private _config: ConfigLaudoService,
    public _addText: AddText,
    private dataService:DataTermService
    ) { }
    editLaudoMode: boolean = false;
    htmlContent: string;
    mask: string;
    term = new Term();
    injuries = new Injuries();
    cenarioCorrente = 0;
    objetoGrupoLesao: any;

    Subtitulo: string;
    subtituloBloco: string;
    termResultsAPI: Array<TermResult>;
    termConclusionAPI: Array<TermResult>;
    indicacaoTexto = '';
    achadosAdTexto = '';
    cabecalhoTexto = '';
    rodapeTexto = '';

    cabecalho = false;
    indicacao = false;
    tecnica = true;
    densidadeOssea=false;
    alinhamento=false;
    lesoesOsseas=false;
    corposVertebrais=false;
    processoTransverso=false;
    interapofisarias=false;
    espacosDiscais =false;
    posCirurgico=false;
    dispositivos=false;
    processosEspinhosos=false;
    achados = false;
    rodape = false;

    tecnicaID = 357;
    densidadeOsseaID = 358;
    alinhamentoID = 359;
    lesoesOsseasID = 360;
    corposVertebraisID = 361;
    processoTransversoID = 362;
    interapofisariasID = 363;
    espacosDiscaisID = 364;
    posCirurgicoID = 365;
    dispositivosID= 366;
    processosEspinhososID = 367;

    public showForm: Boolean = true;
    public unidadeMedida: string;
    public switchFormReport = true;
    public newEditor: boolean = false;
    public laudoCompleto: any = [];

    medicalReportTypeID = 80;

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("rxColunaToracicaAnalise", this.descricoes, this.conclusoes);
    this.subtituloBloco = this.Subtitulo;
    this.Subtitulo = 'Técnica';
    this.term.listTermID = new Array<number>();
    this.resetaDialog();

    const listBlocks = [
      this.tecnicaID,
      this.densidadeOsseaID,
      this.alinhamentoID,
      this.lesoesOsseasID,
      this.corposVertebraisID,
      this.processoTransversoID,
      this.interapofisariasID,
      this.espacosDiscaisID,
      this.posCirurgicoID,
      this.dispositivosID,
      this.processosEspinhososID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "rxColunaToracicaMask").then((obj: any) => {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase( listBlocks, "colunaToracica");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'rx-coluna-toracica');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'rx-coluna-toracica');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  blocos = [
    {
      titulo: 'Informações',
      id: 'cabecalho',
      name: 'cabecalho',
      selected: false,
    },
    {
      titulo: 'Indicação',
      id: 'indicacao',
      name: 'indicacao',
      subtitulo:'Preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Técnica',
      id: this.tecnicaID,
      name: 'tecnica',
      selected: true
    },
    {
      titulo: 'Densidade óssea',
      id: this.densidadeOsseaID,
      name: 'Densidade óssea',
      selected: false
    },
    {
      titulo: 'Alinhamento',
      id: this.alinhamentoID,
      name: 'Alinhamento',
      selected: false
    },
    {
      titulo: 'Lesões ósseas',
      id: this.lesoesOsseasID,
      name: 'Lesões ósseas',
      selected: false
    },
    {
      titulo: 'Corpos vertebrais',
      id: this.corposVertebraisID,
      name: 'Corpos vertebrais',
      selected: false
    },
    {
      titulo: 'Processo transverso',
      id: this.processoTransversoID,
      name: 'Processo transverso',
      selected: false
    },
    {
      titulo: 'Interapofisárias',
      id: this.interapofisariasID,
      name: 'Interapofisárias',
      selected: false
    },
    {
      titulo: 'Espaços discais',
      id: this.espacosDiscaisID,
      name: 'Espaços discais',
      selected: false
    },
    {
      titulo: 'Pós-cirúrgico',
      id: this.posCirurgicoID,
      name: 'Pós-cirúrgico',
      selected: false
    },
    {
      titulo: 'Dispositivos',
      id: this.dispositivosID,
      name: 'Dispositivos',
      selected: false
    },
    {
      titulo: 'Processos espinhosos',
      id: this.processosEspinhososID,
      name: 'Processos espinhosos',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo:'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.tecnica = (itemSelecionado.id === this.tecnicaID);
    this.densidadeOssea = (itemSelecionado.id === this.densidadeOsseaID);
    this.alinhamento = (itemSelecionado.id === this.alinhamentoID);
    this.lesoesOsseas = (itemSelecionado.id === this.lesoesOsseasID);
    this.corposVertebrais = (itemSelecionado.id === this.corposVertebraisID);
    this.processoTransverso = (itemSelecionado.id === this.processoTransversoID);
    this.interapofisarias = (itemSelecionado.id === this.interapofisariasID);
    this.espacosDiscais = (itemSelecionado.id === this.espacosDiscaisID);
    this.posCirurgico = (itemSelecionado.id === this.posCirurgicoID);
    this.dispositivos = (itemSelecionado.id === this.dispositivosID);
    this.processosEspinhosos = (itemSelecionado.id === this.processosEspinhososID);
    this.achados = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.genericAlinhamento= [
    { value: '', selected: false, termID: 5030, term: '[Lateralidade]' },
    { value: '', selected: false, termID: 5034, term: '[LateralidadeSinuosaPri]' },
    { value: '', selected: false, termID: 5036, term: '[NivelSinuosaPri]' },
    { value: '', selected: false, termID: 5035, term: '[LateralidadeSinuosaSeg]' },
    { value: '', selected: false, termID: 5037, term: '[NivelSinuosaSeg]' },
    { value: '', selected: false, termID: 5039, term: '[GrauAnterolistese]' },
    { value: '', selected: false, termID: 5040, term: '[NivelAnterolistese]' },
    { value: '', selected: false, termID: 5042, term: '[GrauRetrolistese]' },
    { value: '', selected: false, termID: 5043, term: '[NivelRetrolistese]' },
  ];

  this.genericLesoesOsseas=[
    { value: '', selected: false, termID: 5051, term: '[Nivel]' },
    { value: '', selected: false, termID: 5054, term: '[NivelEsclerotica]' }
  ]

  this.genericCorposVertebrais=[
    { value: '', selected: false, termID: 5069, term: '[NivelOsteofitose]' },
    { value: '', selected: false, termID: 5073, term: '[NivelFraturaUnica]' },
    { value: '', selected: false, termID: 5074, term: '[NivelFraturaMultipla]' },
    { value: '', selected: false, termID: 5077, term: '[NivelFraturaReducao]' },
    { value: '', selected: false, termID: 5078, term: '[ReducaoDaAltura]' }
  ]

  this.genericProcessoTransverso=[
    { value: '', selected: false, termID: 5091, term: '[NivelDireito]' },
    { value: '', selected: false, termID: 5090, term: '[NivelEsquerdo]' },
  ]

  this.genericInterapofisarias=[
    { value: '', selected: false, termID: 5096, term: '[Nivel]' }
  ]

  this.genericEspacosDiscais=[
    { value: '', selected: false, termID: 5100, term: '[Nivel]' },
    { value: '', selected: false, termID: 5101, term: '[Niveis]' }
  ]

  this.genericPosCirurgico=[
    { value: '', selected: false, termID: 5104, term: '[LateralidadeSoltura]' },
    { value: '', selected: false, termID: 5105, term: '[NivelSoltura]' },
    { value: '', selected: false, termID: 5108, term: '[LateralidadeParafuso]' },
    { value: '', selected: false, termID: 5109, term: '[NivelParafuso]' },
    { value: '', selected: false, termID: 5112, term: '[LateralidadeHaste]' },
    { value: '', selected: false, termID: 5113, term: '[NivelHaste]' },
    { value: '', selected: false, termID: 5115, term: '[NivelEspacador]' },
    { value: '', selected: false, termID: 5117, term: '[NivelAncora]' },
    { value: '', selected: false, termID: 5119, term: '[NivelProtese]' }
  ]

  this.genericProcessosEspinhosos=[
    { value: '', selected: false, termID: 5138, term: '[Nivel]' },
    { value: '', selected: false, termID: 5137, term: '[Niveis]' }
  ]

  this.term.listTermID = new Array<number>();
  this.term.injuries = new Array<Injuries>();
  this.injuries.listInjuries = new Array<number>();
  this.cenarioCorrente = 0;
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente === 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario === 0 || $event.cenario === this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  adicionaSelecaoAlinhamento($event){
      this.genericAlinhamento.forEach(obj => {
        if (obj.termID == $event.elemento.id) {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      });
      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        if ($event.elemento.cenario) {
          this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
        } else {
          this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
        }
        this.enviaLesoes(this.objetoGrupoLesao);
      }
  }
  adicionaSelecaolesoesOsseas($event){
    this.genericLesoesOsseas.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }
  adicionaSelecaoCorposVertebrais($event){
    this.genericCorposVertebrais.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }
  adicionaSelecaoProcessoTransverso($event){
    this.genericProcessoTransverso.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }
  adicionaSelecaoInterapofisarias($event){
    this.genericInterapofisarias.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }
  adicionaSelecaoEspacosDiscais($event){
    this.genericEspacosDiscais.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }
  adicionaSelecaoPosCirurgico($event){
    this.genericPosCirurgico.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }
  adicionaSelecaoDispositivos($event){
    this.genericDispositivos.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }
  adicionaSelecaoProcessosEspinhosos($event){
    this.genericProcessosEspinhosos.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  consultarAPI(){
    let desc;
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      if (this.tecnica) {
        this.term.blockID = this.tecnicaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.tecnicaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + resultado.text;
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.tecnicaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.tecnicaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + resultado.conclusion
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.tecnicaID);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }else if(this.densidadeOssea){
        this.term.blockID = this.densidadeOsseaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.densidadeOsseaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + resultado.text;
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.densidadeOsseaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.densidadeOsseaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + resultado.conclusion
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.densidadeOsseaID);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }else if(this.alinhamento){
        this.term.blockID = this.alinhamentoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.alinhamentoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoAlinhamento(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.alinhamentoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.alinhamentoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoAlinhamento(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.alinhamentoID);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }else if(this.lesoesOsseas){
        this.term.blockID = this.lesoesOsseasID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.lesoesOsseasID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoLesoesOsseas(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.lesoesOsseasID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.lesoesOsseasID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoLesoesOsseas(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.lesoesOsseasID);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }else if(this.corposVertebrais){
        this.term.blockID = this.corposVertebraisID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.corposVertebraisID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoCorposVertebrais(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.corposVertebraisID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.corposVertebraisID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoCorposVertebrais(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.lesoesOsseasID);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }else if(this.processoTransverso){
        this.term.blockID = this.processoTransversoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.processoTransversoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoProcessoTransverso(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.processoTransversoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.processoTransversoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoProcessoTransverso(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.processoTransversoID);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }else if(this.interapofisarias){
        this.term.blockID = this.interapofisariasID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.interapofisariasID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoInterapofisarias(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.interapofisariasID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.interapofisariasID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoInterapofisarias(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.interapofisariasID);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }else if(this.espacosDiscais){
        this.term.blockID = this.espacosDiscaisID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.espacosDiscaisID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoEspacosDiscais(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.espacosDiscaisID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.espacosDiscaisID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoEspacosDiscais(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.espacosDiscaisID);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }else if(this.posCirurgico){
        this.term.blockID = this.posCirurgicoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.posCirurgicoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoPosCirurgico(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.posCirurgicoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.posCirurgicoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoPosCirurgico(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.posCirurgicoID);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }else if(this.dispositivos){
        this.term.blockID = this.dispositivosID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.dispositivosID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoDispositivos(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.dispositivosID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.dispositivosID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoDispositivos(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.dispositivosID);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }else if(this.processosEspinhosos){
        this.term.blockID = this.processosEspinhososID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.processosEspinhososID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoProcessosEspinhosos(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.processosEspinhososID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.processosEspinhososID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoProcessosEspinhosos(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.processosEspinhososID);

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  MontaDescricaoAlinhamento(texto, contadorTexto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 5030 || +term === 5034 || +term === 5036 || +term === 5035 || +term === 5037 || +term === 5039 || +term === 5040 || +term === 5042 || +term === 5043) {
        this.genericAlinhamento.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    if (this.term.injuries[0].listInjuries != undefined && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
        this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
          if (+term === 5030 || +term === 5034 || +term === 5036 || +term === 5035 || +term === 5037 || +term === 5039 || +term === 5040 || +term === 5042 || +term === 5043) {
            this.genericAlinhamento.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoLesoesOsseas(texto, contadorTexto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 5051 || +term === 5054) {
        this.genericLesoesOsseas.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    if (this.term.injuries[0].listInjuries != undefined && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
        this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
          if (+term === 5051 || +term === 5054) {
            this.genericLesoesOsseas.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoCorposVertebrais(texto, contadorTexto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 5069 || +term === 5073 || +term === 5074 || +term === 5077|| +term === 5078) {
        this.genericCorposVertebrais.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    if (this.term.injuries[0].listInjuries != undefined && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
        this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
          if (+term === 5069 || +term === 5073 || +term === 5074 || +term === 5077|| +term === 5078) {
            this.genericCorposVertebrais.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoProcessoTransverso(texto, contadorTexto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 5090 || +term === 5091) {
        this.genericProcessoTransverso.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    if (this.term.injuries[0].listInjuries != undefined && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
        this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
          if (+term === 5090 || +term === 5091) {
            this.genericProcessoTransverso.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoInterapofisarias(texto, contadorTexto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 5096 ) {
        this.genericInterapofisarias.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    if (this.term.injuries[0].listInjuries != undefined && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
        this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
          if (+term === 5096) {
            this.genericInterapofisarias.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoEspacosDiscais(texto, contadorTexto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 5100 || +term === 5101 ) {
        this.genericEspacosDiscais.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    if (this.term.injuries[0].listInjuries != undefined && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
        this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
          if (+term === 5100 || +term === 5101 ) {
            this.genericEspacosDiscais.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoPosCirurgico(texto, contadorTexto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 5104 || +term === 5105 || +term === 5108  || +term === 5109|| +term === 5112 || +term === 5113 || +term === 5115  || +term === 5117|| +term === 5119) {
        this.genericPosCirurgico.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    if (this.term.injuries[0].listInjuries != undefined && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
        this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
          if (+term === 5104 || +term === 5105 || +term === 5108  || +term === 5109 || +term === 5112 || +term === 5113 || +term === 5115  || +term === 5117|| +term === 5119) {
            this.genericPosCirurgico.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoDispositivos(texto, contadorTexto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 5100 || +term === 5101 ) {
        this.genericDispositivos.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    if (this.term.injuries[0].listInjuries != undefined && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
        this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
          if (+term === 5100 || +term === 5101 ) {
            this.genericDispositivos.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoProcessosEspinhosos(texto, contadorTexto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 5138 || +term === 5137 ) {
        this.genericProcessosEspinhosos.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    if (this.term.injuries[0].listInjuries != undefined && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
        this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
          if (+term === 5138 || +term === 5137 ) {
            this.genericProcessosEspinhosos.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });


    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });


    this.descricoes.forEach(descricao => {
      if (descricao.value && descricao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value !== '' ) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }
    //Lógica Rules
    // if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
    //   this.htmlContent += '<br/><b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';
    // }

    for (i = 0; i < this.descricoes.length; i++) {
      let newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      let newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }

    this.htmlContent += this.indicacaoTexto + ' </br>';
    // this.htmlContent += maskSplit[1];
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
          this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';

      }
    }

    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {

          if (this.conclusoes[i].value) {
            this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';

        }
    }

    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
    calculadora,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }

        if(calculadora !== undefined) {
          const calculo = document.querySelector(`#e-${blockId}`);

          if(calculo) {
						calculo.innerHTML = calculadora;
          }
        }

			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = '<p>' + Texto + '</p>';
    return texto;
  }

  public resetaLaudo() {
    this._pouchService.getAll("rxColunaToracicaAnalise").then((obj) => {
      console.log(obj)
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.showForm = false;
    this.indicacaoTexto = "";
    this.achadosAdTexto = "";
    this.cabecalhoTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
