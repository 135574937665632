import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox, MatInputModule } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-bursa-olecraniana',
  templateUrl: './bursa-olecraniana.component.html',
  styleUrls: ['./bursa-olecraniana.component.css']
})
export class BursaOlecranianaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdSemMedida') rdSemMedida: MatRadioButton;
  @ViewChild('rdComMedida') rdComMedida: MatRadioButton;

  @ViewChild('inLargura') inLargura: ElementRef;
  @ViewChild('inAltura') inAltura: ElementRef;
  @ViewChild('inProfundidade') inProfundidade: ElementRef;
  @ViewChild('inArea') inArea: ElementRef;


  showAndHideTerms: any;
  phVolume = 'Volume';
  unidadeMedida: string;


  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahBursiteComMedida: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickDefault() {
    this.showAndHideTerms.sahBursiteComMedida = false;
    this.catchIDs();
  }

  clickBursiteComMedida() {
    this.showAndHideTerms.sahBursiteComMedida = true;
    this.catchIDs();
  }

  blurDistancia(){
    this.catchIDs();
  }

  clickCk() {
    this.catchIDs();
  }

  formataMedidasBursite() {
    let medindoValor = '';
    // let isPrimeiraLesao = false;
    let resultado = 0;

    if (this.inAltura != undefined &&
      this.inAltura.nativeElement.id != undefined &&
      this.inAltura.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inAltura.nativeElement.value);
      resultado = this.inAltura.nativeElement.value;
    }

    if (this.inLargura != undefined &&
      this.inLargura.nativeElement.id != undefined &&
      this.inLargura.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inLargura.nativeElement.value);
        resultado = resultado * this.inLargura.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inLargura.nativeElement.value);
        resultado = this.inLargura.nativeElement.value;

      }
    }

    if (this.inProfundidade != undefined &&
      this.inProfundidade.nativeElement.id != undefined &&
      this.inProfundidade.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inProfundidade.nativeElement.value);
        resultado = resultado * this.inProfundidade.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inProfundidade.nativeElement.value);
        resultado = this.inProfundidade.nativeElement.value;
      }
    }

    if (medindoValor != '') {
      // Volume
      resultado = resultado * 0.523;

      medindoValor = medindoValor + ' cm (volume = ' + +resultado.toFixed(1) + ' cm³)';


      const objetoElemento = {id: 1343, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento};
      this.termosGenericos.emit(objetoLesao);
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdSemMedida.checked) {
      this.termoSelecionado.emit(this.rdSemMedida.value);
    }

    if (this.rdComMedida.checked) {
      this.termoSelecionado.emit(this.rdComMedida.value);

      if (this.inLargura && this.inLargura.nativeElement.value &&
        this.inAltura && this.inAltura.nativeElement.value &&
        this.inProfundidade && this.inProfundidade.nativeElement.value) {
          this.formataMedidasBursite();

      }

    }

    this.listaCompleta.emit();
  }
}
