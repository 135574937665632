import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';

@Component({
  selector: 'app-alinhamento',
  templateUrl: './alinhamento.component.html',
  styleUrls: ['./alinhamento.component.css']
})
export class AlinhamentoComponent implements OnInit {

  @Output() termosGenericos = new EventEmitter();
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdNormalDinamica') rdNormalDinamica: MatRadioButton;
  @ViewChild('rdRetificacao') rdRetificacao: MatRadioButton;

  @ViewChild('ckAnterolistese') ckAnterolistese: MatCheckbox;
  @ViewChild('slGrauAnterolistese') slGrauAnterolistese: MatSelect;
  @ViewChild('slNivelAnterolistese') slNivelAnterolistese: MatSelect;

  @ViewChild('ckRetrolistese') ckRetrolistese: MatCheckbox;
  @ViewChild('slGrauRetrolistese') slGrauRetrolistese: MatSelect;
  @ViewChild('slNivelRetrolistese') slNivelRetrolistese: MatSelect;

  grausAnterolistese: ArrayDropDown[] = [
    { value: 'I', viewValue: 'I' },
    { value: 'II', viewValue: 'II' },
    { value: 'III', viewValue: 'III' },
    { value: 'IV', viewValue: 'IV' },
    { value: 'V', viewValue: 'V' },
  ];

  niveisAnterolistese: ArrayDropDown[] = [
    { value: 'C2', viewValue: 'C2' },
    { value: 'C3', viewValue: 'C3' },
    { value: 'C4', viewValue: 'C4' },
    { value: 'C5', viewValue: 'C5' },
    { value: 'C6', viewValue: 'C6' },
    { value: 'C7', viewValue: 'C7' },
  ];

  grausRetrolistese: ArrayDropDown[] = [
    { value: 'Pequena', viewValue: 'Pequena' },
    { value: 'Moderada', viewValue: 'Moderada' },
    { value: 'Acentuada', viewValue: 'Acentuada' },
  ];

  niveisRetrolistese: ArrayDropDown[] = [
    { value: 'C2', viewValue: 'C2' },
    { value: 'C3', viewValue: 'C3' },
    { value: 'C4', viewValue: 'C4' },
    { value: 'C5', viewValue: 'C5' },
    { value: 'C6', viewValue: 'C6' },
    { value: 'C7', viewValue: 'C7' },
  ];

  countLesoes = 0;
  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {

    this.showAndHideTerms = {
      sahAnterolistese: false,
      sahRetrolistese: false
    };
  }

  clickDefault(){
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  clickAnterolistese(){
    this.showAndHideTerms.sahAnterolistese = !this.showAndHideTerms.sahAnterolistese;
    this.catchIDs();
  }

  clickRetrolistese(){
    this.showAndHideTerms.sahRetrolistese = !this.showAndHideTerms.sahRetrolistese;
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if(this.countLesoes > 0){
      this.emitNormalLesao();
    } else {
      this.emitNormal();
    }

    if(this.countLesoes > 0){
      this.emitNormalDinamicaLesao();
    } else {
      this.emitNormalDinamica();
    }

    if(this.countLesoes > 0){
      this.emitRetificacaoLesao();
    } else {
      this.emitRetificacao();
    }


    if(this.countLesoes > 0){
      this.emitAnterolisteseLesao();
    } else {
      this.emitAnterolistese();
    }

    if(this.countLesoes > 0){
      this.emitRetrolisteseLesao();
    } else {
      this.emitRetrolistese();
    }


    this.listaCompleta.emit();
  }

  emitNormal(){
    if(this.rdNormal.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdNormal.id);
    }
  }

  emitNormalLesao(){
    if(this.rdNormal.checked){
      this.countLesoes++;
      const objeto = { id: this.rdNormal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitNormalDinamica(){
    if(this.rdNormalDinamica.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdNormalDinamica.id);
    }
  }

  emitNormalDinamicaLesao(){
    if(this.rdNormalDinamica.checked){
      this.countLesoes++;
      const objeto = { id: this.rdNormalDinamica.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitRetificacao(){
    if(this.rdRetificacao.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdRetificacao.id);
    }
  }

  emitRetificacaoLesao(){
    if(this.rdRetificacao.checked){
      this.countLesoes++;
      const objeto = { id: this.rdRetificacao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitAnterolistese(){
    if(this.ckAnterolistese.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAnterolistese.id);

      if(this.slGrauAnterolistese && this.slGrauAnterolistese.value){
        const objeto = {elemento: this.slGrauAnterolistese, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelAnterolistese && this.slNivelAnterolistese.value ){
        const objeto = {elemento: this.slNivelAnterolistese, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }


  emitAnterolisteseLesao(){
    if(this.ckAnterolistese.checked){
      this.countLesoes++;
      const objeto = { id: this.ckAnterolistese.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slGrauAnterolistese && this.slGrauAnterolistese.value){
        const objeto = {elemento: this.slGrauAnterolistese, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelAnterolistese && this.slNivelAnterolistese.value){
        const objeto = {elemento: this.slNivelAnterolistese, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitRetrolistese(){
    if(this.ckRetrolistese.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckRetrolistese.id);

      if(this.slGrauRetrolistese && this.slGrauRetrolistese.value){
        const objeto = {elemento: this.slGrauRetrolistese, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelRetrolistese && this.slNivelRetrolistese.value ){
        const objeto = {elemento: this.slNivelRetrolistese, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitRetrolisteseLesao(){
    if(this.ckRetrolistese.checked){
      this.countLesoes++;
      const objeto = { id: this.ckRetrolistese.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slGrauRetrolistese && this.slGrauRetrolistese.value){
        const objeto = {elemento: this.slGrauRetrolistese, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelRetrolistese && this.slNivelRetrolistese.value){
        const objeto = {elemento: this.slNivelRetrolistese, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }


}
