export class Term {
  listTermID: Array<string|number>;
  blockID: number;
  userID: number;
  companyID: number;
  injuries: Array<Injuries>;
  }
  export  class  Injuries {
    listInjuries: Array<string|number>;
    position: number;
  }
