import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-corpos-vertebrais',
  templateUrl: './corpos-vertebrais.component.html',
  styleUrls: ['./corpos-vertebrais.component.css']
})
export class CorposVertebraisComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('rdFraturaUnica') rdFraturaUnica: MatRadioButton;
  @ViewChild('rdFraturaReducao') rdFraturaReducao: MatRadioButton;

  @ViewChild('slNivelOsteofitose') slNivelOsteofitose: MatSelect;
  @ViewChild('slNivelFraturaReducao') slNivelFraturaReducao: MatSelect;

  @ViewChild('ckOsteofitoseMarginalFocal') ckOsteofitoseMarginalFocal: MatCheckbox;
  @ViewChild('ckOsteofitoseMultisegmentar') ckOsteofitoseMultisegmentar: MatCheckbox;
  @ViewChild('ckAcunhamentoAnterior') ckAcunhamentoAnterior: MatCheckbox;

  @ViewChild('inMedida') inMedida: ElementRef;


  nivelOsteofitose: ArrayDropDown[]=[
    { value: ' C7', viewValue: 'C7' },
    { value: ' T1', viewValue: 'T1' },
    { value: ' T2', viewValue: 'T2' },
    { value: ' T3', viewValue: 'T3' },
    { value: ' T4', viewValue: 'T4' },
    { value: ' T5', viewValue: 'T5' },
    { value: ' T6', viewValue: 'T6' },
    { value: ' T7', viewValue: 'T7' },
    { value: ' T8', viewValue: 'T8' },
    { value: ' T9', viewValue: 'T9' },
    { value: ' T10', viewValue: 'T10' },
    { value: ' T11', viewValue: 'T11' },
    { value: ' T12', viewValue: 'T12' },
    { value: ' L1', viewValue: 'L1' }]

    nivelFraturaReducao: ArrayDropDown[]=[
      { value: 'C7', viewValue: 'C7' },
      { value: 'T1', viewValue: 'T1' },
      { value: 'T2', viewValue: 'T2' },
      { value: 'T3', viewValue: 'T3' },
      { value: 'T4', viewValue: 'T4' },
      { value: 'T5', viewValue: 'T5' },
      { value: 'T6', viewValue: 'T6' },
      { value: 'T7', viewValue: 'T7' },
      { value: 'T8', viewValue: 'T8' },
      { value: 'T9', viewValue: 'T9' },
      { value: 'T10', viewValue: 'T10' },
      { value: 'T11', viewValue: 'T11' },
      { value: 'T12', viewValue: 'T12' },
      { value: 'L1', viewValue: 'L1' }]

  nivelFraturaUnica:any;
  nivelFraturaUnicaFormGroup:FormGroup;
  showAndHideTerms: any;
  countLesoes = 0;
  isDisable=true;

  constructor(private fb:FormBuilder) { }

  ngOnInit() {
    this.iniciaFormFraturaUnica();

    this.nivelFraturaUnica=[
      { id:'C7NF', value: ' C7', name: 'C7' },
      { id:'T1NF', value: ' T1', name: 'T1' },
      { id:'T2NF', value: ' T2', name: 'T2' },
      { id:'T3NF', value: ' T3', name: 'T3' },
      { id:'T4NF', value: ' T4', name: 'T4' },
      { id:'T5NF', value: ' T5', name: 'T5' },
      { id:'T6NF', value: ' T6', name: 'T6' },
      { id:'T7NF', value: ' T7', name: 'T7' },
      { id:'T8NF', value: ' T8', name: 'T8' },
      { id:'T9NF', value: ' T9', name: 'T9' },
      { id:'T10NF', value: ' T10', name: 'T10' },
      { id:'T11NF', value: ' T11', name: 'T11' },
      { id:'T12NF', value: ' T12', name: 'T12' },
      { id:'L1NF', value: ' L1', name: 'L1' }
    ];

    this.showAndHideTerms = {
      sahOsteofitoseMarginaisFocais:false,
      sahFraturaUnica:false,
      sahFraturaReducao:false
    }
  }

  clickDefault(){
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  clickNormal(){
    this.showAndHideTerms.sahOsteofitoseMarginaisFocais=false;
    this.showAndHideTerms.sahFraturaUnica=false;
    this.showAndHideTerms.sahFraturaReducao=false;

    this.rdFraturaUnica.checked=false;
    this.rdFraturaReducao.checked=false;
    this.ckOsteofitoseMarginalFocal.checked=false;
    this.ckOsteofitoseMultisegmentar.checked=false;
    this.rdNormal.checked=true;

    this.catchIDs();
  }

  clickOsteofitoseMarginaisFocais(){
    this.ckOsteofitoseMultisegmentar.checked=false;
    this.showAndHideTerms.sahOsteofitoseMarginaisFocais = !this.showAndHideTerms.sahOsteofitoseMarginaisFocais;
    this.catchIDs();
  }

  clickOsteofitoseMultisegmentar(){
    this.showAndHideTerms.sahOsteofitoseMarginaisFocais=false;
    this.ckOsteofitoseMarginalFocal.checked=false;
    this.catchIDs();
  }

  clickRadioFraturas(){
    if(this.rdFraturaUnica.checked){
      this.showAndHideTerms.sahFraturaUnica = !this.showAndHideTerms.sahFraturaUnica;
      this.showAndHideTerms.sahFraturaReducao = false;
    }else if(this.rdFraturaReducao.checked){
      this.showAndHideTerms.sahFraturaReducao = !this.showAndHideTerms.sahFraturaReducao;
      this.showAndHideTerms.sahFraturaUnica = false;
    }
    this.catchIDs();
  }

  clickCheckboxFraturaUnica(event) {
    let nivelFraturaUnica = <FormArray>this.nivelFraturaUnicaFormGroup.get('nivelFraturaUnica') as FormArray;

    if (event.checked) {
      nivelFraturaUnica.push(new FormControl(event.source.value));
    } else {
      let iNivel = nivelFraturaUnica.controls.findIndex(x => x.value === event.source.value);

      nivelFraturaUnica.removeAt(iNivel);
    }
  this.catchIDs();
  }

  iniciaFormFraturaUnica() {
    this.nivelFraturaUnicaFormGroup = this.fb.group({
      nivelFraturaUnica:this.fb.array([])
    });
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');
    if(this.rdFraturaReducao.checked ||
      this.rdFraturaUnica.checked ||
      this.ckOsteofitoseMarginalFocal.checked ||
      this.ckOsteofitoseMultisegmentar.checked){
        this.rdAlterado.checked=true;
        this.rdNormal.checked=false;
      }

    if(!this.rdFraturaUnica.checked){
      this.iniciaFormFraturaUnica();
    }

    if(this.countLesoes > 0){
      this.emitOsteofitoseMarginalLesao();
    } else {
      this.emitOsteofitoseMarginalSimples();
    }
    if(this.countLesoes > 0){
      this.emitOsteofitoseMultisegmentarLesao();
    } else {
      this.emitOsteofitoseMultisegmentarSimples();
    }
    if(this.countLesoes > 0){
      this.emitFraturaUnicaLesao();
    } else {
      this.emitFraturaUnicaSimples();
    }
    if(this.countLesoes > 0){
      this.emitFraturaReducaoLesao();
    } else {
      this.emitFraturaReducaoSimples();
    }
    if(this.ckOsteofitoseMultisegmentar.checked){
      this.termoSelecionado.emit(this.ckOsteofitoseMultisegmentar.id)
    }
    if(!this.rdFraturaUnica.checked && !this.rdFraturaReducao.checked && !this.ckOsteofitoseMarginalFocal.checked && !this.ckOsteofitoseMultisegmentar.checked){
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if(this.rdNormal.checked){
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    this.listaCompleta.emit();
  }

  emitOsteofitoseMarginalLesao(){
    if(this.ckOsteofitoseMarginalFocal.checked){
      this.countLesoes++;
      const objeto = { id: this.ckOsteofitoseMarginalFocal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slNivelOsteofitose && this.slNivelOsteofitose.value){
        if(this.slNivelOsteofitose.value.length>1){

          let objetoMultiplo= { id:this.slNivelOsteofitose.id, value: this.replaceVirgulaMultSelect(this.slNivelOsteofitose.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelOsteofitose.value.length==1){
          const objeto = {elemento: this.slNivelOsteofitose, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitOsteofitoseMarginalSimples(){
    if(this.ckOsteofitoseMarginalFocal.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckOsteofitoseMarginalFocal.id);

      if(this.slNivelOsteofitose && this.slNivelOsteofitose.value){
        if(this.slNivelOsteofitose.value.length>1){

          let objetoMultiplo= { id:this.slNivelOsteofitose.id, value: this.replaceVirgulaMultSelect(this.slNivelOsteofitose.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelOsteofitose.value.length==1){
          const objeto = {elemento: this.slNivelOsteofitose, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitOsteofitoseMultisegmentarLesao(){
    if(this.ckOsteofitoseMultisegmentar.checked){
      this.countLesoes++;
      const objeto = { id: this.ckOsteofitoseMultisegmentar.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitOsteofitoseMultisegmentarSimples(){
    if(this.ckOsteofitoseMultisegmentar.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckOsteofitoseMultisegmentar.id);
    }
  }

  emitFraturaUnicaLesao(){
    if(this.rdFraturaUnica.checked){
      this.countLesoes++;
      const objeto = { id: this.rdFraturaUnica.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let posicao: any = this.nivelFraturaUnicaFormGroup.value.nivelFraturaUnica;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if(posicao.length > 0){
        if(posicao.length == 1){
          let objeto = {id: 5073, value: posicao};
          let elemento = {elemento: objeto, isPrimeiraLesao: false};
          this.termosGenericos.emit(elemento);

          if(this.ckAcunhamentoAnterior.checked){
            let objetoMultiplo= { id:this.ckAcunhamentoAnterior.id, value:this.ckAcunhamentoAnterior.value };
            let elementoMultiplo ={elemento:objetoMultiplo, isPrimeiraLesao: false};
            this.termosGenericos.emit(elementoMultiplo);
          }
        }else if(posicao.length > 1){
          let objeto = {id: 5074, value: posicaoTratada};
          let elemento = {elemento: objeto, isPrimeiraLesao: false};
          this.termosGenericos.emit(elemento);

          if(this.ckAcunhamentoAnterior.checked){
            let objetoMultiplo= {id:this.ckAcunhamentoAnterior.id, value:this.ckAcunhamentoAnterior.value};
            let elementoMultiplo ={elemento:objetoMultiplo, isPrimeiraLesao: false};
            this.termosGenericos.emit(elementoMultiplo);
          }
        }
      }
    }
  }

  emitFraturaUnicaSimples(){
    if(this.rdFraturaUnica.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdFraturaUnica.id);

      let posicao: any = this.nivelFraturaUnicaFormGroup.value.nivelFraturaUnica;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if(posicao.length > 0){
        if(posicao.length == 1){

          let objeto = {id: 5073, value: posicao};
          let elemento = {elemento: objeto, isPrimeiraLesao: true};
          this.termosGenericos.emit(elemento);

          if(this.ckAcunhamentoAnterior.checked){
            let objetoMultiplo= {id:this.ckAcunhamentoAnterior.id, value:this.ckAcunhamentoAnterior.value };
            let elementoMultiplo ={elemento:objetoMultiplo, isPrimeiraLesao: true};
            this.termosGenericos.emit(elementoMultiplo);
          }
        }else if(posicao.length > 1){
          let objeto = {id: 5074, value: posicaoTratada};
          let elemento = {elemento: objeto, isPrimeiraLesao: true};
          this.termosGenericos.emit(elemento);

          if(this.ckAcunhamentoAnterior.checked){
            let objetoMultiplo= {id:this.ckAcunhamentoAnterior.id, value:this.ckAcunhamentoAnterior.value };
            let elementoMultiplo ={elemento:objetoMultiplo, isPrimeiraLesao: true};
            this.termosGenericos.emit(elementoMultiplo);
          }
        }
      }
    }
  }

  emitFraturaReducaoLesao(){
    if(this.rdFraturaReducao.checked){
      this.countLesoes++;
      const objeto = { id: this.rdFraturaReducao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

    if(this.slNivelFraturaReducao && this.slNivelFraturaReducao.value){
      const objeto = {elemento: this.slNivelFraturaReducao, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }
    if (this.inMedida && this.inMedida.nativeElement.value) {
      const objeto = { elemento: this.inMedida.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
    }
  }
}

  emitFraturaReducaoSimples(){
    if(this.rdFraturaReducao.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdFraturaReducao.id);

      if(this.slNivelFraturaReducao && this.slNivelFraturaReducao.value){
        const objeto = {elemento: this.slNivelFraturaReducao, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
      if (this.inMedida && this.inMedida.nativeElement.value != undefined) {
        const objeto = { elemento: this.inMedida.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  replaceVirgula(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }

  replaceVirgulaMultSelect(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }
}
