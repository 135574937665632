import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-mst-liquido-amniotico',
  templateUrl: './mst-liquido-amniotico.component.html',
  styleUrls: ['./mst-liquido-amniotico.component.css']
})
export class MstLiquidoAmnioticoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdReduzido') rdReduzido: MatRadioButton;
  @ViewChild('rdAumentado') rdAumentado: MatRadioButton;

  @ViewChild('ckMbv') ckMbv: MatCheckbox;
  @ViewChild('inMbv') inMbv: ElementRef;

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    // this.showAndHideTerms = {
    //   sahMbv: true,
    // }
    let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickDefault() {
    debugger
    this.catchIDs();
  }

  // clickMbv() {
  //   if (this.showAndHideTerms.sahMbv == false) {
  //     this.showAndHideTerms.sahMbv = true;
  //   } else {
  //     this.showAndHideTerms.sahMbv = false;
  //   }
  // }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdReduzido.checked) {
      this.termoSelecionado.emit(this.rdReduzido.id);
    }

    if (this.rdAumentado.checked) {
      this.termoSelecionado.emit(this.rdAumentado.id);
    }

    if (this.inMbv && this.inMbv.nativeElement.value){
      const objetoElemento = {id: this.inMbv.nativeElement.id, value: this.inMbv.nativeElement.value};
      const objetoCriado = { elemento: objetoElemento};
      this.termosGenericos.emit(objetoCriado);
    }

    this.listaCompleta.emit();
  }

}
