import { Component, OnInit, EventEmitter, Output, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-ligamentos-talofibular',
  templateUrl: './ligamentos-talofibular.component.html',
  styleUrls: ['./ligamentos-talofibular.component.css']
})
export class LigamentosTalofibularComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdEstiramento') rdEstiramento: MatRadioButton;
  @ViewChild('rdRotura') rdRotura: MatRadioButton;
  @ViewChild('rdFibro') rdFibro: MatRadioButton;

  @ViewChildren('ligamentosChildren') ligamentosChildren: QueryList<any>;

  ligamentos: ArrayDropDown[] = [
    { value: 'talofibular anterior', viewValue: 'Talofibular anterior' },
    { value: 'calcaneofibular', viewValue: 'Calcaneofibular' }
  ];

  showAndHideTerms: any;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  objeto: any;
  objetofilho: any;

  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahLigamento: false
    };
    this.lesoesForm = this._fb.group({
      ligamentos: this._fb.array([this.addLigamentoGrup()]),
    });
  }


  //Dinamico Fibromatose
  addLigamentoGrup() {
    return this._fb.group({
      ligamento: [],
    });
  }

  addLigamento() {
    this.ligamentoArray.push(this.addLigamentoGrup());
    this.catchIDs();
  }

  removeLigamento(index) {
    this.ligamentoArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get ligamentoArray() {
    return <FormArray>this.lesoesForm.get('ligamentos');
  }

  clickNormal() {
    this.showAndHideTerms.sahLigamento = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickAlteracao() {
    this.showAndHideTerms.sahLigamento = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.value);
    }

    if(this.rdEstiramento.checked){
      this.termoSelecionado.emit(this.rdEstiramento.value);

      this.verificaLigamento();
    }

    if(this.rdRotura.checked){
      this.termoSelecionado.emit(this.rdRotura.value);

      this.verificaLigamento();
    }

    if(this.rdFibro.checked){
      this.termoSelecionado.emit(this.rdFibro.value);

      this.verificaLigamento();
    }

    this.listaCompleta.emit();
  }

  verificaLigamento() {
    this.linha = 0;
    this.ligamentosChildren.forEach(obj => {
      this.linha++;
      this.objetofilho = { id: obj.id, value: obj.value, cenario: 1 };
      this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
      this.lesoesDinamicas.emit(this.objeto);
    });
  }

  ajustaArrayDinamico() {
    if ((this.rdFibro && !this.rdFibro.checked) || (this.rdEstiramento && !this.rdEstiramento.checked)
    || (this.rdRotura && !this.rdRotura.checked)) {
  
      const arrayTotal = this.ligamentoArray.length;
  
      for (let i  = 0; i <= arrayTotal; i++) {
        const index = this.ligamentoArray.length - 1
  
        if (index != 0) {
          this.ligamentoArray.removeAt(index);
        }
      }
      this.ligamentoArray.reset();
    }
  }
}
