import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-calcificacoes-arteriais',
  templateUrl: './calcificacoes-arteriais.component.html',
  styleUrls: ['./calcificacoes-arteriais.component.css']
})
export class CalcificacoesArteriaisComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdHabituais') rdHabituais: MatRadioButton;
  @ViewChild('rdAbundantes') rdAbundantes: MatRadioButton;

  
  
  showAndHideTerms: any;


  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
    };
  }

  clickDefault() {
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdHabituais.checked) {
      this.termoSelecionado.emit(this.rdHabituais.id);
    }

    if (this.rdAbundantes.checked) {
      this.termoSelecionado.emit(this.rdAbundantes.id);
    }
    
    this.listaCompleta.emit();
  }

}
