import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-cer-colo-uterino',
  templateUrl: './cer-colo-uterino.component.html',
  styleUrls: ['./cer-colo-uterino.component.css']
})
export class CerColoUterinoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;

  @ViewChild('inMedida') inMedida: ElementRef;

  @ViewChild('slOrificio') slOrificio: MatSelect;

  orificios: ArrayDropDown[] = [
    { value: 'fechado', viewValue: 'Fechado' },
    { value: 'aberto', viewValue: 'Aberto' }
  ];

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMedida: true
    }
    let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clckNormal() {
    this.showAndHideTerms.sahMedida = true;
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.inMedida &&
        this.inMedida.nativeElement.value !== '') {
          const Diam = this._validacoes.formataDecimal(this.inMedida.nativeElement.value);
          const objetoElemento = { id: this.inMedida.nativeElement.id, value: Diam, cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slOrificio && this.slOrificio.value) {
        if (this.slOrificio.value === 'aberto') {
          const objetoElemento = { id: 4269, value: '', cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else if (this.slOrificio.value === 'fechado') {
          const objetoElemento = { id: 4270, value: '', cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

      }
    }
    this.listaCompleta.emit();
  }

}
