import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MedicalReportLog } from '../models/BO/MedicalReportLog';

@Injectable({
  providedIn: 'root'
})

export class MedicalReportLogService {
  constructor(private _http: HttpClient) { }

  async saveLogMedicalReport(medicalReportLog: any) {
    const url = environment.nLaudoApi + 'MedicalReport/LogMedicalReport';
    const _medicalReportLog: MedicalReportLog = medicalReportLog;

    const obj = {
      'UserID': _medicalReportLog.UserID,
      'MedicalReportTypeID': _medicalReportLog.MedicalReportTypeID,
      'ContentReport': _medicalReportLog.ContentReport,
      'MedicalReportLogTypeID': _medicalReportLog.MedicalReportLogTypeID,
      'MedicalReportID': _medicalReportLog.MedicalReportID,
    };

    return this._http.post<MedicalReportLog>(url, obj).toPromise();
  }
}
