import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validacoes } from '../../../../utils/validacoes';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';
import { ControladorFrasesLaudoService } from 'src/app/services/ControladorFrasesLaudo.service';
export interface TermosGenericos {
  value: string;
  selected: boolean;
  termID: number;
  term: string;
}

@Component({
  selector: 'app-partes-moles',
  templateUrl: './partes-moles.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./partes-moles.component.css']
})
export class PartesMolesComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[975],blockID:52},
    {listTerm:[876],blockID:48},
    {listTerm:[931],blockID:49},
  ]

  descricoes: DescricaoConclusao[] = [
    { value: 'Avaliação de abaulamento.', blockID: 52, textTermID: 0, visivel: true },
    {value: 'Pele e tecido celular subcutâneo de espessura e ecogenicidade preservadas.', blockID: 48, textTermID: 0, visivel: true},
    { value: '', blockID: 51, textTermID: 0, visivel: true },
    { value: 'Planos musculares sem alterações significativas.', blockID: 49, textTermID: 0, visivel: true },
    { value: 'Não foram caracterizadas coleções organizadas.', blockID: 0, textTermID: 0, visivel: true},
    { value: 'Ausência de formações expansivas presentes ao estudo.', blockID: 0, textTermID: 0, visivel: true }
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Exame sem alterações significativas.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 48, textTermID: 0, visivel: true },
    { value: '', blockID: 49, textTermID: 0, visivel: true }
  ];

  genericTermIndicacao: TermosGenericos[] = [
    { value: '', selected: false, termID: 978, term: '[Região do corpo]' }
  ];

  genericTermsTecidoSubcutaneo: TermosGenericos[] = [
    { value: '', selected: false, termID: 880, term: '[Medida]' },
    { value: '', selected: false, termID: 881, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 883, term: '[Plano]' },
    { value: '', selected: false, termID: 884, term: '[Contornos]' },
    { value: '', selected: false, termID: 885, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 886, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 887, term: '[Medida]' },
    { value: '', selected: false, termID: 888, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 891, term: '[Paredes]' },
    { value: '', selected: false, termID: 892, term: '[Conteúdo]' },
    { value: '', selected: false, termID: 893, term: '[Medidas]' },
    { value: '', selected: false, termID: 894, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 896, term: '[Plano]' },
    { value: '', selected: false, termID: 897, term: '[Paredes]' },
    { value: '', selected: false, termID: 898, term: '[Conteúdo]' },
    { value: '', selected: false, termID: 899, term: '[Medidas]' },
    { value: '', selected: false, termID: 900, term: '[Volume]' },
    { value: '', selected: false, termID: 901, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 904, term: '[Plano]' },
    { value: '', selected: false, termID: 905, term: '[Medidas]' },
    { value: '', selected: false, termID: 906, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 908, term: '[Plano]' },
    { value: '', selected: false, termID: 909, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 910, term: '[Limites]' },
    { value: '', selected: false, termID: 911, term: '[Sombra acústica posterior]' },
    { value: '', selected: false, termID: 912, term: '[Medidas]' },
    { value: '', selected: false, termID: 913, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 915, term: '[Plano]' },
    { value: '', selected: false, termID: 916, term: '[Loculação]' },
    { value: '', selected: false, termID: 917, term: '[Paredes]' },
    { value: '', selected: false, termID: 918, term: '[Conteúdo]' },
    { value: '', selected: false, termID: 919, term: '[Septos]' },
    { value: '', selected: false, termID: 920, term: '[Medidas]' },
    { value: '', selected: false, termID: 921, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 922, term: '[Fluxo vascular]' }
  ];

  genericTermsPlanoMuscular: TermosGenericos[] = [
    { value: '', selected: false, termID: 933, term: '[Medida da diástase]' },
    { value: '', selected: false, termID: 934, term: '[Distância da cicatriz umbilical]' },
    { value: '', selected: false, termID: 935, term: '[Localização da cicatriz umbilical]' },
    { value: '', selected: false, termID: 937, term: '[Paredes]' },
    { value: '', selected: false, termID: 938, term: '[Conteúdo]' },
    { value: '', selected: false, termID: 939, term: '[Medidas]' },
    { value: '', selected: false, termID: 940, term: '[Volume]' },
    { value: '', selected: false, termID: 941, term: '[Distância da pele]' },
    { value: '', selected: false, termID: 944, term: '[Medidas]' },
    { value: '', selected: false, termID: 945, term: '[Distância da pele]' }
  ];


  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();
  cabecalhoTexto = '';
  achadosAdTexto = '';
  rodapeTexto = '';

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  objetoGrupoLesao: any;
  cenarioCorrente = 0;

  cabecalho = false;
  indicacao = true;
  pelePlanoSubcutaneo = false;
  planoMuscular = false;
  achadosTexto = false;
  rodape = false;

  medicalReportTypeID = 9;
  indicacaoID = 52;
  pelePlanoSubcutaneoID = 48;
  planoMuscularID = 49;
  public switchFormReport = true;
  public newEditor: boolean = false;
  public showForm: Boolean = true;
  public laudoCompleto: any = [];
  unidadeMedida: string;
  editLaudoMode: boolean = false;
  naoContemColecaoPeleTecido: boolean = true;
  naoContemColecaoPlano: boolean = true;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _dialog: MatDialog,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public _addText: AddText,
    private dataService:DataTermService,
    private _config: ConfigLaudoService,
    private _copyPaste: CopyPaste,
    private _controladorFrases:ControladorFrasesLaudoService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("partes-molesAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Indicação'
    this.subtituloBloco = 'Preencha o campo indicação';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    //  });
    const listBlocks = [
      this.indicacaoID,
      this.pelePlanoSubcutaneoID,
      this.planoMuscularID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "partes-molesMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "partesMoles");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-partes-moles');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-partes-moles');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    this._config.buscarRules(localStorage.getItem('userID'))
      .then(configs => {
        configs.forEach(config => {
          if(config.medicalReportRuleID === 5 && config.active) {
            this.newEditor = true;
          } else {
            this.newEditor = false;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("partes-molesAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.showForm = false;
    this.cabecalhoTexto = "";
    this.achadosAdTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  blocos = [
    {
      titulo: 'Informações',
      id: 'cabecalho',
      name: 'cabecalho',
      selected: false,
    },
    {
      titulo: 'Indicação',
      id: this.indicacaoID,
      name: 'indicacao',
      subtitulo: 'Preencha o campo indicação',
      selected: true,
    },
    {
      titulo: 'Pele e tecido subcutâneo',
      id: this.pelePlanoSubcutaneoID,
      name: 'tecido-subcutaneo',
      selected: false
    },
    {
      titulo: 'Plano muscular',
      id: this.planoMuscularID,
      name: 'plano-muscular',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados_adicionais',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape_footer',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === this.indicacaoID);
    this.pelePlanoSubcutaneo = (itemSelecionado.id === this.pelePlanoSubcutaneoID);
    this.planoMuscular = (itemSelecionado.id === this.planoMuscularID);
    this.achadosTexto = (itemSelecionado.id === 'achados_adicionais');
    this.rodape = (itemSelecionado.id === 'rodape_footer');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;

    this.genericTermIndicacao = [
      { value: '', selected: false, termID: 978, term: '[Região do corpo]' }
    ];

    this.genericTermsTecidoSubcutaneo = [
      { value: '', selected: false, termID: 880, term: '[Medida]' },
      { value: '', selected: false, termID: 881, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 883, term: '[Plano]' },
      { value: '', selected: false, termID: 884, term: '[Contornos]' },
      { value: '', selected: false, termID: 885, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 886, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 887, term: '[Medida]' },
      { value: '', selected: false, termID: 888, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 891, term: '[Paredes]' },
      { value: '', selected: false, termID: 892, term: '[Conteúdo]' },
      { value: '', selected: false, termID: 893, term: '[Medidas]' },
      { value: '', selected: false, termID: 894, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 896, term: '[Plano]' },
      { value: '', selected: false, termID: 897, term: '[Paredes]' },
      { value: '', selected: false, termID: 898, term: '[Conteúdo]' },
      { value: '', selected: false, termID: 899, term: '[Medidas]' },
      { value: '', selected: false, termID: 900, term: '[Volume]' },
      { value: '', selected: false, termID: 901, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 904, term: '[Plano]' },
      { value: '', selected: false, termID: 905, term: '[Medidas]' },
      { value: '', selected: false, termID: 906, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 908, term: '[Plano]' },
      { value: '', selected: false, termID: 909, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 910, term: '[Limites]' },
      { value: '', selected: false, termID: 911, term: '[Sombra acústica posterior]' },
      { value: '', selected: false, termID: 912, term: '[Medidas]' },
      { value: '', selected: false, termID: 913, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 915, term: '[Plano]' },
      { value: '', selected: false, termID: 916, term: '[Loculação]' },
      { value: '', selected: false, termID: 917, term: '[Paredes]' },
      { value: '', selected: false, termID: 918, term: '[Conteúdo]' },
      { value: '', selected: false, termID: 919, term: '[Septos]' },
      { value: '', selected: false, termID: 920, term: '[Medidas]' },
      { value: '', selected: false, termID: 921, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 922, term: '[Fluxo vascular]' }
    ];

    this.genericTermsPlanoMuscular = [
      { value: '', selected: false, termID: 933, term: '[Medida da diástase]' },
      { value: '', selected: false, termID: 934, term: '[Distância da cicatriz umbilical]' },
      { value: '', selected: false, termID: 935, term: '[Localização da cicatriz umbilical]' },
      { value: '', selected: false, termID: 937, term: '[Paredes]' },
      { value: '', selected: false, termID: 938, term: '[Conteúdo]' },
      { value: '', selected: false, termID: 939, term: '[Medidas]' },
      { value: '', selected: false, termID: 940, term: '[Volume]' },
      { value: '', selected: false, termID: 941, term: '[Distância da pele]' },
      { value: '', selected: false, termID: 944, term: '[Medidas]' },
      { value: '', selected: false, termID: 945, term: '[Distância da pele]' }
    ];
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados_adicionais',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = `<p><div style="text-align: right;">${texto}</div><br/></p>`;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape_footer',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  adicionaSelecaoIndicacao($event) {
    this.genericTermIndicacao.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    }
  }

  adicionaSelecaoTecidoSubcutaneo($event) {
    this.genericTermsTecidoSubcutaneo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoPlanoMuscular($event) {
    this.genericTermsPlanoMuscular.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      if (this.indicacao) {
        this.term.blockID = this.indicacaoID;
        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.indicacaoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoIndicacao(resultado.text);
              });

              const index = this.descricoes.findIndex(x => x.blockID === this.indicacaoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }


            if(!this.newEditor) {
              this.AdicionaTexto(this.newEditor);
            } else {
              this.atualizaLinha({
                blockId: this.indicacaoID,
                descricao: desc
              });
            }
          }
        });
      });

      } else if (this.pelePlanoSubcutaneo) {
        this.term.blockID = this.pelePlanoSubcutaneoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.pelePlanoSubcutaneoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTecidoSubcutaneo(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.pelePlanoSubcutaneoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              this.alteraDescricaoNormal();

            }
          });

          this.naoContemColecaoPeleTecido = this._controladorFrases.verificarSeContemTermoSelecionado(this.term, 895);
          this.handleValidaSeAdicionaFrase();


          let obj = {
            value: '',
            blockID: this.pelePlanoSubcutaneoID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoTecidoSubcutaneo(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.pelePlanoSubcutaneoID);
          const descColecao = this.descricoes[this.descricoes.length - 1];

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.pelePlanoSubcutaneoID) {
                conclusaoNull.value = obj.value;
                descColecao.value = 'Ausência lesões expansiva ou coleções organizadas no presente estudo.';
                conclusao.value = '';
              } else {
                descColecao.value = '';
                conclusaoNull.value = '';
              }
            } else {
              descColecao.value = '';
              conclusao.value = '';
            }
          } else if (obj.value) {
            descColecao.value = '';
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.pelePlanoSubcutaneoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.planoMuscular) {
        this.term.blockID = this.planoMuscularID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.planoMuscularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoPlanoMuscular(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.planoMuscularID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              this.alteraDescricaoNormal();
            }
          });

          this.naoContemColecaoPlano = this._controladorFrases.verificarSeContemTermoSelecionado(this.term, 936);
          this.handleValidaSeAdicionaFrase();

          let obj = {
            value: '',
            blockID: this.planoMuscularID,
            textTermID: 0
          };
          let espaco = '';
          let contadorTexto = 0;
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.planoMuscularID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.planoMuscularID);
          const descColecao = this.descricoes[this.descricoes.length - 1];

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.planoMuscularID) {
                conclusaoNull.value = obj.value;
                descColecao.value = 'Ausência lesões expansiva ou coleções organizadas no presente estudo.';
                conclusao.value = '';
              } else {
                descColecao.value = '';
                conclusaoNull.value = '';
              }
            } else {
              descColecao.value = '';
              conclusao.value = '';
            }
          } else if (obj.value) {
            descColecao.value = '';
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.planoMuscularID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      }
    }
  }

  MontaDescricaoIndicacao(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 978) {
        this.genericTermIndicacao.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoTecidoSubcutaneo(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 880 || +term === 881 || +term === 883 || +term === 884 || +term === 885 ||
        +term === 886 || +term === 887 || +term === 888 || +term === 891 || +term === 892 ||
        +term === 893 || +term === 894 || +term === 895 || +term === 896 || +term === 897 ||
        +term === 898 || +term === 899 || +term === 900 || +term === 901 || +term === 904 ||
        +term === 905 || +term === 906 || +term === 907 || +term === 908 || +term === 909 ||
        +term === 910 || +term === 911 || +term === 912 || +term === 913 || +term === 915 ||
        +term === 916 || +term === 917 || +term === 918 || +term === 919 || +term === 920 ||
        +term === 921 || +term === 922 || +term === 923 || +term === 924 || +term === 925 ||
        +term === 926 || +term === 927 || +term === 928 || +term === 929 || +term === 930) {
        this.genericTermsTecidoSubcutaneo.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 880 || +term === 881 || +term === 883 || +term === 884 || +term === 885 ||
          +term === 886 || +term === 887 || +term === 888 || +term === 891 || +term === 892 ||
          +term === 893 || +term === 894 || +term === 895 || +term === 896 || +term === 897 ||
          +term === 898 || +term === 899 || +term === 900 || +term === 901 || +term === 904 ||
          +term === 905 || +term === 906 || +term === 907 || +term === 908 || +term === 909 ||
          +term === 910 || +term === 911 || +term === 912 || +term === 913 || +term === 915 ||
          +term === 916 || +term === 917 || +term === 918 || +term === 919 || +term === 920 ||
          +term === 921 || +term === 922 || +term === 923 || +term === 924 || +term === 925 ||
          +term === 926 || +term === 927 || +term === 928 || +term === 929 || +term === 930) {
          this.genericTermsTecidoSubcutaneo.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaConclusaoTecidoSubcutaneo(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 880 || +term === 881 || +term === 883 || +term === 884 || +term === 885 ||
        +term === 886 || +term === 887 || +term === 888 || +term === 891 || +term === 892 ||
        +term === 893 || +term === 894 || +term === 895 || +term === 896 || +term === 897 ||
        +term === 898 || +term === 899 || +term === 900 || +term === 901 || +term === 904 ||
        +term === 905 || +term === 906 || +term === 907 || +term === 908 || +term === 909 ||
        +term === 910 || +term === 911 || +term === 912 || +term === 913 || +term === 915 ||
        +term === 916 || +term === 917 || +term === 918 || +term === 919 || +term === 920 ||
        +term === 921 || +term === 922 || +term === 923 || +term === 924 || +term === 925 ||
        +term === 926 || +term === 927 || +term === 928 || +term === 929 || +term === 930) {
        this.genericTermsTecidoSubcutaneo.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 880 || +term === 881 || +term === 883 || +term === 884 || +term === 885 ||
          +term === 886 || +term === 887 || +term === 888 || +term === 891 || +term === 892 ||
          +term === 893 || +term === 894 || +term === 895 || +term === 896 || +term === 897 ||
          +term === 898 || +term === 899 || +term === 900 || +term === 901 || +term === 904 ||
          +term === 905 || +term === 906 || +term === 907 || +term === 908 || +term === 909 ||
          +term === 910 || +term === 911 || +term === 912 || +term === 913 || +term === 915 ||
          +term === 916 || +term === 917 || +term === 918 || +term === 919 || +term === 920 ||
          +term === 921 || +term === 922 || +term === 923 || +term === 924 || +term === 925 ||
          +term === 926 || +term === 927 || +term === 928 || +term === 929 || +term === 930) {
          this.genericTermsTecidoSubcutaneo.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoPlanoMuscular(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 933 || +term === 934 || +term === 935 || +term === 937 || +term === 938 ||
        +term === 939 || +term === 940 || +term === 941 || +term === 944 || +term === 945) {
        this.genericTermsPlanoMuscular.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 933 || +term === 934 || +term === 935 || +term === 937 || +term === 938 ||
          +term === 939 || +term === 940 || +term === 941 || +term === 944 || +term === 945) {
          this.genericTermsPlanoMuscular.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<div style="text-align: center;"><b>ULTRASSONOGRAFIA DE PARTES MOLES</br><br></b></div>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-${this.indicacaoID}`,
        'name': ''
      },
      'data': {
        'text': `<p><b>INDICAÇÃO:<br></b>${this.descricoes[0].value}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br></br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    this.descricoes.forEach(descricao => {
      if (descricao.value && descricao.visivel) {
        if (descricao.blockID !== this.indicacaoID) {
          this.laudoCompleto.push({
            'type': 'paragraph',
            'blockMenu': {
              'id': `e-${descricao.blockID}`,
              'name': ''
            },
            'data': {
              'text': `<p>${descricao.value}</p>`,
            }
          });
        }
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value && conclusao.visivel) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados_adicionais`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape_footer`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.rodapeTexto}</p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.descricoes[0].value;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
    this.htmlContent += '</br></br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.';
    this.htmlContent += maskSplit[1];

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value && this.descricoes[i].visivel) {
        if (i > 0 && i < this.descricoes.length) {
          this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
        }
      }
    }
    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value && this.conclusoes[i].visivel) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }
    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId} p`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId} p`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  alteraDescricaoNormal() {
    this.term.listTermID.forEach(term => {
      if (+term === 877 || +term === 878 || +term === 879 || +term === 880 || +term === 881 || +term === 882 ||
        +term === 883 || +term === 884 || +term === 885 || +term === 886 || +term === 887 || +term === 888 ||
        +term === 889 || +term === 890 || +term === 891 || +term === 892 || +term === 893 || +term === 894 ||
        +term === 895 || +term === 896 || +term === 897 || +term === 898 || +term === 899 || +term === 900 ||
        +term === 901 || +term === 902 || +term === 903 || +term === 904 || +term === 905 || +term === 906 ||
        +term === 907 || +term === 908 || +term === 909 || +term === 910 || +term === 911 || +term === 912 ||
        +term === 913 || +term === 914 || +term === 915 || +term === 916 || +term === 917 || +term === 918 ||
        +term === 919 || +term === 920 || +term === 921 || +term === 922 || +term === 923 || +term === 924 ||
        +term === 925 || +term === 926 || +term === 927 || +term === 928 || +term === 929 || +term === 930 ||
        +term === 932 || +term === 933 || +term === 934 || +term === 935 || +term === 936 || +term === 937 ||
        +term === 938 || +term === 939 || +term === 940 || +term === 941 || +term === 942 || +term === 943 ||
        +term === 944 || +term === 945 || +term === 4170) {
        this.descricoes[4].visivel = false;
      } else {
        this.descricoes[4].visivel = true;
      }
    });

  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = 'Exame sem alterações significativas.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  handleValidaSeAdicionaFrase() {
    if(this.naoContemColecaoPeleTecido && this.naoContemColecaoPlano){
      this.descricoes[this.descricoes.length-2].visivel = true;
    }else{
      this.descricoes[this.descricoes.length-2].visivel = false;
    }
  }
}
