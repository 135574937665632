import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';


@Component({
  selector: 'app-complexos-ligamentares',
  templateUrl: './complexos-ligamentares.component.html',
  styleUrls: ['./complexos-ligamentares.component.css']
})
export class ComplexosLigamentaresComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdMedial') rdMedial: MatRadioButton;
  @ViewChild('rdLateral') rdLateral: MatRadioButton;

  @ViewChild('rdFibroLcm') rdFibroLcm: MatRadioButton;
  @ViewChild('rdParcialLcm') rdParcialLcm: MatRadioButton;
  @ViewChild('rdCompletaLcm') rdCompletaLcm: MatRadioButton;

  @ViewChild('rdFibroLcl') rdFibroLcl: MatRadioButton;
  @ViewChild('rdParcialLcl') rdParcialLcl: MatRadioButton;
  @ViewChild('rdCompletaLcl') rdCompletaLcl: MatRadioButton;

  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slLocalizacaoSimples') slLocalizacaoSimples: MatSelect;

  localizacoes: ArrayDropDown[] = [
    { value: 'no terço proximal', viewValue: 'Terço proximal' },
    { value: 'no terço médio', viewValue: 'Terço médio' },
    { value: 'no terço distal', viewValue: 'Terço distal' },
    { value: 'difusa', viewValue: 'Difusa' }
  ];

  localizacoesSimples: ArrayDropDown[] = [
    { value: 'proximais', viewValue: 'Proximais' },
    { value: 'médias', viewValue: 'Médias' },
    { value: 'distais', viewValue: 'Distais' }
  ];

  showAndHideTerms: any;
  countLesoes = 0;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMedial: false,
      sahLateral: false,
      sahLocalizacao: false,
      sahLocalizacaoSimples: false
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahMedial = false;
    this.showAndHideTerms.sahLateral = false;
    this.showAndHideTerms.sahLocalizacao = false;
    this.showAndHideTerms.sahLocalizacaoSimples = false;
    this.catchIDs();
  }

  clickMedial() {
    this.showAndHideTerms.sahMedial = true;
    this.showAndHideTerms.sahLateral = false;
    this.showAndHideTerms.sahLocalizacao = true;
    this.showAndHideTerms.sahLocalizacaoSimples = false;
    this.catchIDs();
  }

  clickLateral() {
    this.showAndHideTerms.sahLateral = true;
    this.showAndHideTerms.sahMedial = false;
    this.showAndHideTerms.sahLocalizacao = true;
    this.showAndHideTerms.sahLocalizacaoSimples = false;
    this.catchIDs();
  }

  clickLocalizacao() {
    this.showAndHideTerms.sahLocalizacao = true;
    this.showAndHideTerms.sahLocalizacaoSimples = false;
    this.catchIDs();
  }

  clickLocalizacaoSimples(){
    this.showAndHideTerms.sahLocalizacaoSimples = true;
    this.showAndHideTerms.sahLocalizacao = false;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    //MEDIAL
    if (this.rdMedial.checked) {

      if(this.rdFibroLcm && this.rdFibroLcm.checked){
        this.termoSelecionado.emit(this.rdFibroLcm.id);

        if (this.slLocalizacao && this.slLocalizacao.value) {
          const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }

      if(this.rdParcialLcm && this.rdParcialLcm.checked){
        this.termoSelecionado.emit(this.rdParcialLcm.id);

        if (this.slLocalizacao && this.slLocalizacao.value) {
          const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }

      if(this.rdCompletaLcm && this.rdCompletaLcm.checked){
        this.termoSelecionado.emit(this.rdCompletaLcm.id);

        if (this.slLocalizacaoSimples && this.slLocalizacaoSimples.value) {
          const objeto = { elemento: this.slLocalizacaoSimples, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }
    }

    //LATERAL
    if (this.rdLateral.checked) {

      if(this.rdFibroLcl && this.rdFibroLcl.checked){
        this.termoSelecionado.emit(this.rdFibroLcl.id);

        if (this.slLocalizacao && this.slLocalizacao.value) {
          const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }

      if(this.rdParcialLcl && this.rdParcialLcl.checked){
        this.termoSelecionado.emit(this.rdParcialLcl.id);

        if (this.slLocalizacao && this.slLocalizacao.value) {
          const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }

      if(this.rdCompletaLcl && this.rdCompletaLcl.checked){
        this.termoSelecionado.emit(this.rdCompletaLcl.id);

        if (this.slLocalizacaoSimples && this.slLocalizacaoSimples.value) {
          const objeto = { elemento: this.slLocalizacaoSimples, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }
    }

    this.listaCompleta.emit();
  }
}