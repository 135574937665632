import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule, MatCardModule, MatIconModule, MatTooltipModule } from '@angular/material';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MenuRxComponent } from './menu-rx.component';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonToggleModule,
    MatIconModule, 
    MatTooltipModule,
    FilterPipeModule,
    FlexLayoutModule    
  ],
  exports: [MenuRxComponent],
  declarations: [MenuRxComponent]
})
export class MenuRxModule { }
