import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatRadioButton } from '@angular/material';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-opt-colo-uterino',
  templateUrl: './opt-colo-uterino.component.html',
  styleUrls: ['./opt-colo-uterino.component.css']
})
export class OptColoUterinoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;

  @ViewChild('inMedida') inMedida: ElementRef;

  showAndHideTerms: any;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMedida: true
    }
  }

  clckNormal() {
    this.showAndHideTerms.sahMedida = true;
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
      if (this.inMedida &&
        this.inMedida.nativeElement.value !== '') {
          const Diam = this._validacoes.formataDecimal(this.inMedida.nativeElement.value);
          const objetoElemento = { id: this.inMedida.nativeElement.id, value: Diam, cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
    this.listaCompleta.emit();
  }

}
