
import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-arteria-uterina-dir',
  templateUrl: './arteria-uterina-dir.component.html',
  styleUrls: ['./arteria-uterina-dir.component.css']
})
export class ArteriaUterinaDirComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAumentado') rdAumentado: MatRadioButton;
  @ViewChild('rdNaocitar') rdNaocitar: MatRadioButton;

  @ViewChild('inIR') inIR: ElementRef;
  @ViewChild('inIP') inIP: ElementRef;

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAll: false,
    };
  }

  clickDefault() {
    this.showAndHideTerms.sahAll = true;
    this.catchIDs();
  }

  clickNaoCitar() {
    this.showAndHideTerms.sahAll = false;
    this.catchIDs();
  }

  blurParametros() {
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.inIR && this.inIR.nativeElement.value) {
        const objeto = { elemento: this.inIR.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inIP && this.inIP.nativeElement.value) {
        const objeto = { elemento: this.inIP.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdAumentado.checked) {
      this.termoSelecionado.emit(this.rdAumentado.id);

      if (this.inIR && this.inIR.nativeElement.value) {
        const objeto = { elemento: this.inIR.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inIP && this.inIP.nativeElement.value) {
        const objeto = { elemento: this.inIP.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdNaocitar.checked) {
      this.termoSelecionado.emit(this.rdNaocitar.id);
    }

    this.listaCompleta.emit();
  }
}
