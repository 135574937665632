import { ToastrManager } from 'ng6-toastr-notifications';
import { UserService } from './../services/user.service';
import { DashboardService } from './../services/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { DashboardMedicalReport, DashboardUser } from '../models/BO/Dashboard';
import echarts from '../../../node_modules/echarts/dist/echarts.min.js';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  //dadosMedicalReport Modificados
  dadosAno={};
  dadosAnoVetor=[];
  //dadosUser Modificados
  dadosUserObjeto={};

  carregouDadosMedical = false;
  carregouDadosUser = false;
  $usuario: any;

  constructor(private _service: DashboardService,
    private _userService: UserService,
    private toastr: ToastrManager) { }

  ngOnInit() {
    this.$usuario = new Object();
    this.carregaUsuario();
  }

  carregaUsuario() {
    const userID = +localStorage.getItem('userID');
    if (userID) {
      this._userService.buscarUsuario(userID)
        .subscribe(
          (usuario) => {
            this.carregarDashboardMedicalReport(usuario);
            this.carregarDashboardUser(usuario);
            this.$usuario = usuario;
          }
        );
    } else {
      this.toastr.errorToastr('Erro ao carregar dados', 'Erro',
        {
          position: 'top-right',
          toastTimeout: 4000
        }
      );
    }
  }

  carregarDashboardMedicalReport(usuario:any) {
    this._service.getDashboardMedicalReport(usuario.id)
      .subscribe((dashboards)=>{
        this.carregouDadosMedical = true;
        this.organizarDadosMedical(dashboards);
      });
  }

  carregarDashboardUser(usuario:any) {
    this._service.getDashboardUser(usuario.id)
      .subscribe((dashboards)=>{
        this.carregouDadosUser = true;
        this.organizarDadosUser(dashboards);
      });
  }

  organizarDadosMedical(data:DashboardMedicalReport[]){
    data.forEach(element => {
      element.monthCreatedOn--;
      if(this.dadosAno[element.yearCreatedOn]){
        if(this.dadosAno[element.yearCreatedOn][element.monthCreatedOn]){
          this.dadosAno[element.yearCreatedOn][element.monthCreatedOn]+=1;
        }else{
          this.dadosAno[element.yearCreatedOn][element.monthCreatedOn]=1;
        }
      }else{
        this.dadosAno[element.yearCreatedOn]=[0,0,0,0,0,0,0,0,0,0,0,0];
        this.dadosAno[element.yearCreatedOn][element.monthCreatedOn]=1;
      }
    });
    this.dadosAnoVetor = Object.entries(this.dadosAno);
    this.alterarGraficoMedical(this.dadosAnoVetor[this.dadosAnoVetor.length-1][0]);
  }

  alterarGraficoMedical(ano:string){
    var myChart = echarts.init(document.getElementById('grafico-barra'));
    myChart.setOption(this.carregarOptionsMedical(ano));
  }

  carregarOptionsMedical(ano:string){
    return {
      title: {
          text: 'Quantidade de laudos por mês',
          subtext: 'Gráfico que exibe a quantidade de laudos gerados por mês baseado no ano selecionado',
          left: 'center'
      },
      color: ['#54c5ce'],
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          }
      },
      grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
      },
      xAxis: [
          {
              type: 'category',
              data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
              axisTick: {
                  alignWithLabel: true
              }
          }
      ],
      yAxis: [
          {
              type: 'value'
          }
      ],
      series: [
          {
              name: 'Quantidade de consultas',
              type: 'bar',
              barWidth: '60%',
              data: this.dadosAno[ano]
          }
      ]
  };

  }

  organizarDadosUser(data:DashboardUser[]){
    data.forEach(element => {
      if(this.dadosUserObjeto[element.medicalReportTypeID]){
        this.dadosUserObjeto[element.medicalReportTypeID].value+=1;
      }else {
        this.dadosUserObjeto[element.medicalReportTypeID]= {value:1,name:element.medicalReportTypeTitle};
      }
    });
    this.alterarGraficoUser();
  }

  alterarGraficoUser(){
    var myChart = echarts.init(document.getElementById('grafico-pizza'));
    myChart.setOption(this.carregarOptionsUser());
  }

  carregarOptionsUser(){
    let legendData =  Object.values(this.dadosUserObjeto).filter((e:{name:string})=>e.name);
    let seriesData = Object.values(this.dadosUserObjeto);
    let selected = {};
    for (var i = 0; i < legendData.length; i++) {
      let name = legendData[i]+'';
        selected[name] = i < 6;
    }

    let data = {
        legendData: legendData,
        seriesData: seriesData,
        selected: selected
    };

    return {
      title: {
          text: 'Quantidade de laudos por tipo',
          subtext: 'Gráfico que exibe a quantidade de laudos por tipo. Selecione um para mais detalhes',
          left: 'center'
      },
      tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      legend: {
          type: 'scroll',
          orient: 'horizontal',
          right: 10,
          top: 'auto',
          bottom: 20,
          data: data.legendData,

          selected: data.selected
      },
      series: [
          {
              name: 'Operações',
              type: 'pie',
              radius: '55%',
              center: ['50%', '50%'],
              data: data.seriesData,
              emphasis: {
                  itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
              }
          }
      ]
  };
  }


}
