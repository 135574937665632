import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatRadioButton } from '@angular/material';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-opt-vesicula-vinelinica',
  templateUrl: './opt-vesicula-vinelinica.component.html',
  styleUrls: ['./opt-vesicula-vinelinica.component.css']
})
export class OptVesiculaVinelinicaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAumentada') rdAumentada: MatRadioButton;
  @ViewChild('rdAusente') rdAusente: MatRadioButton;

  @ViewChild('inDiametro') inDiametro: ElementRef;

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMedida: true
    }
    let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahMedida = true;
    this.catchIDs();
  }
  clickAumentada() {
    this.showAndHideTerms.sahMedida = true;
    this.catchIDs();
  }
  clickAusente() {
    this.showAndHideTerms.sahMedida = false;
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente && this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdAumentada && this.rdAumentada.checked) {
      this.termoSelecionado.emit(this.rdAumentada.id);
      if (this.inDiametro &&
        this.inDiametro.nativeElement.value !== '') {
        const Diam = this._validacoes.formataDecimal(this.inDiametro.nativeElement.value);
        const objetoElemento = { id: this.inDiametro.nativeElement.id, value: Diam, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
      if (this.inDiametro &&
        this.inDiametro.nativeElement.value !== '') {
        const Diam = this._validacoes.formataDecimal(this.inDiametro.nativeElement.value);
        const objetoElemento = { id: this.inDiametro.nativeElement.id, value: Diam, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
    this.listaCompleta.emit();
  }

}
