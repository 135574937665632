import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-trombofletibe',
  templateUrl: './trombofletibe.component.html',
  styleUrls: ['./trombofletibe.component.css']
})
export class TrombofletibeComponent implements OnInit {
  public showAndHideTerms: any;
  public countLesoes: number = 0;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;
  @ViewChild('slsafenas') slsafenas: MatSelect;
  @ViewChild('slterco') slterco: MatSelect;
  @ViewChild('sllocalizacao') sllocalizacao: MatSelect;
  @ViewChild('slveiassuperficiais') slveiassuperficiais: MatSelect;
  @ViewChild('sllocalizacaoface') sllocalizacaoface: MatSelect;

  public safenas: ArrayDropDown[] = [
    { value: 'safena magna', viewValue: 'Safena Magna' },
    { value: 'safena parva', viewValue: 'Safena Parva' },
  ];

  public terco: ArrayDropDown[] = [
    { value: 'terço proximal', viewValue: 'Proximal' },
    { value: 'terço médio', viewValue: 'Médio' },
    { value: 'terço distal', viewValue: 'Distal' },
    { value: 'em toda sua extensão', viewValue: 'Toda sua extensão' }
  ];

  public localizacao: ArrayDropDown[] = [
    { value: 'coxa', viewValue: 'Coxa' },
    { value: 'perna', viewValue: 'Perna' }
  ];

  public veiasSuperficiais: ArrayDropDown[] = [
    { value: 'Veias superficiais da coxa', viewValue: 'Veias superficiais da coxa' },
    { value: 'Veias superficiais da perna', viewValue: 'Veias superficiais da perna' }
  ];

  public localizacaoFace: ArrayDropDown[] = [
    { value: 'na face anterior', viewValue: 'Na face anterior' },
    { value: 'na face posterior', viewValue: 'Na face posterior' },
    { value: 'na face lateral', viewValue: 'Na face lateral' },
    { value: 'na face medial', viewValue: 'Na face medial' },
  ];

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true,
    };
  }

  public ClickAusente(): any {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  public ClickPresente(): any {
    this.showAndHideTerms.sahPresente = true;
    this.catchIDs();
  }


  public changeSelect(): any {
    this.alteraAusentePresente();
    this.catchIDs();
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');
    this.countLesoes = 0;

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente && this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);

      this.emitSafenas();

      if (this.countLesoes > 0)
        this.outrasLesao();
      else
        this.outras();
    }

    this.listaCompleta.emit();
  }

  emitSafenas() {
    if (this.slsafenas && this.slsafenas.value) {
      this.countLesoes++;
      const objeto = { elemento: this.slsafenas, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slterco && this.slterco.value) {
      const objeto = { elemento: this.slterco, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.sllocalizacao && this.sllocalizacao.value) {
      const objeto = { elemento: this.sllocalizacao, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  outras() {
    if (this.slveiassuperficiais && this.slveiassuperficiais.value) {
      const objeto = { elemento: this.slveiassuperficiais, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.sllocalizacaoface && this.sllocalizacaoface.value) {
      const objeto = { elemento: this.sllocalizacaoface, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  outrasLesao() {
    this.countLesoes++;
    const obj = { id: this.rdPresente.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(obj);

    if (this.slveiassuperficiais && this.slveiassuperficiais.value) {
      const objeto = { elemento: this.slveiassuperficiais, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
    }

    if (this.sllocalizacaoface && this.sllocalizacaoface.value) {
      const objeto = { elemento: this.sllocalizacaoface, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
    }
  }

  alteraAusentePresente() {
    if ((this.slsafenas && this.slsafenas.value) ||
        (this.slterco && this.slterco.value) ||
        (this.sllocalizacao && this.sllocalizacao.value) ||
        (this.slveiassuperficiais && this.slveiassuperficiais.value) ||
        (this.sllocalizacaoface && this.sllocalizacaoface.value)
    ) {
      this.rdPresente.checked = true;
    } else {
      this.rdAusente.checked = true;
      this.rdPresente.checked = false;
    }
  }

}

