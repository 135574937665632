import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule,
         MatFormFieldModule,
         MatToolbarModule,
         MatButtonModule,
         MatSidenavModule,
         MatIconModule,
         MatInputModule,
         MatListModule,
         MatTableModule,
         MatPaginatorModule,
         MatSortModule,
         MatGridListModule,
         MatCardModule,
         MatMenuModule,
         MatTabsModule,
         MatDatepickerModule,
         MatProgressBarModule,
         MatProgressSpinnerModule,
         MatNativeDateModule,
         MatRadioModule,
         MatCheckboxModule,
         MatSelectModule,
         MatTooltipModule,
         MatSlideToggleModule} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from 'src/app/utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { PenisComponent } from './penis.component';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { PeleTecidoPenisComponent } from './TermosComponents/pele-tecido-penis/pele-tecido-penis.component';
import { CorposCavernososComponent } from './TermosComponents/corpos-cavernosos/corpos-cavernosos.component';
import { SeptoIntercavernosoComponent } from './TermosComponents/septo-intercavernoso/septo-intercavernoso.component';
import { CalcificacoesArteriaisComponent } from './TermosComponents/calcificacoes-arteriais/calcificacoes-arteriais.component';
import { TunicaAlbugineaComponent } from './TermosComponents/tunica-albuginea/tunica-albuginea.component';
import { UretraPenianaComponent } from './TermosComponents/uretra-peniana/uretra-peniana.component';
import { LinfonodomegaliaPenisComponent } from './TermosComponents/linfonodomegalia-penis/linfonodomegalia-penis.component';
import { TabelaPenisComponent } from './TermosComponents/tabela-penis/tabela-penis.component';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { ParedeAbdominalModule } from '../parede-abdominal/parede-abdominal.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    ParedeAbdominalModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [ PenisComponent, SeptoIntercavernosoComponent, CorposCavernososComponent, CalcificacoesArteriaisComponent, TunicaAlbugineaComponent, LinfonodomegaliaPenisComponent, UretraPenianaComponent, PeleTecidoPenisComponent ],
  declarations: [ PenisComponent, PeleTecidoPenisComponent, CorposCavernososComponent, SeptoIntercavernosoComponent, CalcificacoesArteriaisComponent, TunicaAlbugineaComponent, UretraPenianaComponent, LinfonodomegaliaPenisComponent, TabelaPenisComponent ]
})
export class PenisModule { }
