import { Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Plano } from '../models/BO/Plano';
import { ListaPlanosService } from '../services/lista-planos.service';
import { MatDialog, MatCheckbox } from '@angular/material';
import { AlertDialogComponent } from '../utils/dialog/alert-dialog/alert-dialog.component';
import { PagamentoService } from '../services/pagamento.service';
import { StatusPagamentoService } from '../services/status-pagamento.service';
import { take } from 'rxjs/operators';
import { timeStamp } from 'console';
import { P } from '@angular/core/src/render3';
import { PagamentosComponent } from './pagamentos/pagamentos.component';
import { PagamentosDiretoComponent } from './pagamentos-direto/pagamentos-direto.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ViaCepServiceService } from '../services/via-cep-service.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Endereco } from '../models/BO/Endereco';
import { ArrayDropDown } from '../models/Helper/ArrayDropDown';
import { VoucherService } from '../services/voucher.service';
import { Voucher } from '../models/BO/Voucher';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-planos',
  templateUrl: './planos.component.html',
  styleUrls: ['./planos.component.css'],
  // directives: [PagamentosComponent]
})
export class PlanosComponent implements OnInit {
  @ViewChild('ckPlano') ckPlano: MatCheckbox;
  // @ViewChild(PagamentosComponent) child: PagamentosComponent;
  @ViewChild(PagamentosDiretoComponent) child: PagamentosDiretoComponent;

  @ViewChild('inCPF') inCPF: ElementRef;
  @ViewChild('inEndRua') inEndRua: ElementRef;
  @ViewChild('inEndNumero') inEndNumero: ElementRef;
  @ViewChild('inEndComplem') inEndComplem: ElementRef;
  @ViewChild('inEndBairro') inEndBairro: ElementRef;
  @ViewChild('inEndCidade') inEndCidade: ElementRef;
  @ViewChild('inEndEstado') inEndEstado: ElementRef;
  @ViewChild('inEndPais') inEndPais: ElementRef;
  @ViewChild('inEndCEP') inEndCEP: ElementRef;
  @ViewChild('inVoucher') inVoucher: ElementRef;

  planos: Plano[];
  planosMensal: Plano[];
  planosAnual: Plano[];
  selecionados: Plano[];
  removidos: Plano[];
  vlTotal: number;
  isAnual: boolean = false;
  mostrarPagamento = false;
  initVoucher: boolean = false;
  voucher: Voucher;
  voucherCode: string;
  planoTrialFinalizado: boolean;
  valorDesconto: string;

  @ViewChild('SlParcelamento') SlParcelamento: ElementRef;

  showParcelamento: boolean;
  parcelamento: string = '';
  parcelaSelected: string;
  planosSelecionados: any;

  public enderecoForm: FormGroup;
  public dados: any;
  public profissao: string = null;
  public message: string = 'Olá, gostaria de saber sobre as condições especiais para auxiliares de sala.';

  planoFilter = {
    titulo: ''
  };

  quantidades: ArrayDropDown[] = [
    { value: '01', viewValue: '1x sem juros' },
    { value: '2', viewValue: '2x sem juros' },
    { value: '3', viewValue: '3x sem juros' },
    { value: '4', viewValue: '4x sem juros' }
  ];

  constructor(
    private _service: ListaPlanosService,
    private _dialog: MatDialog,
    private _statusPgto: StatusPagamentoService,
    private _pgto: PagamentoService,
    private fb: FormBuilder,
    private _viaCEPService: ViaCepServiceService,
    private toastr: ToastrManager,
    private _voucher: VoucherService,
    private _userService: UserService
  ) { }

  ngOnInit() {
    this.enderecoForm = this.fb.group({
      CPF: ['', [
        <any>Validators.required,
        Validators.maxLength(14),
        Validators.minLength(11)]
      ],
      CEP: ['', [Validators.required]],
      Endereco: [''],
      Numero: [''],
      Bairro: [''],
      Complemento: [''],
      Cidade: [''],
      Estado: [''],
      Pais: [''],
    });

    this.buscaPlanoUsuario();
    this.planosMensal = new Array<Plano>();
    this.planosAnual = new Array<Plano>();
    this.selecionados = new Array<Plano>();
    this.removidos = new Array<Plano>();
    this.carregarPlanos();
    this.buscaPlanoUsuario();
    localStorage.removeItem('exames');

    this.showParcelamento = false;
    this.parcelaSelected = this.quantidades[0].value;
    this.mostrarPacelamento();
  }

  ngAfterViewInit() {
    // child is set
    const obj = {
      total: 99,
      selec: this.selecionados
    };
    this.recebePlano(obj);
    this.clickVigencia(false)
  }

  carregarPlanos() {
    this._service.buscarPlanos()
      .pipe(take(1))
      .subscribe(
        plano => {
          if (plano) {
            this.planosAnual = [];
            this.planosMensal = [];
            plano.forEach(p => {
              if (p.isAnual)
                this.planosAnual.push(p);
              else
                this.planosMensal.push(p);
            });
            if (this.isAnual) {
              this.planos = this.planosAnual;
            } else {
              this.planos = this.planosMensal;
            }
          }
        },
        erro => {
          console.log(erro);
        }
      );
  }

  public clickVigencia(isAnual: boolean) {
    this.isAnual = isAnual;
    if (isAnual) {
      this.planos = this.planosAnual;
      this.selecionarPlano(this.planosAnual[0]);
    } else {
      this.planos = this.planosMensal;
      this.selecionarPlano(this.planosMensal[0]);
    }
    this.blurVoucher();
  }

  selecionarPlano(plano: Plano) {
    (plano.checked === true) ? plano.checked = false : plano.checked = true;

    // // adiciona planos selecionados
    // if (plano.checked === true) {
    //   this.adcionaPlano(plano);
    // }

    // // remove planos não selecionados selecionados
    // if (plano.checked === false) {
    //   this.removePlano(plano);
    // }

    //! TEMPORÁRIO ATÉ HAVER + de dois planos
    this.selecionados = new Array<Plano>();
    this.selecionados.push(plano);

    if (this.selecionados.length === 0) {
      this.mostrarPagamento = false;
    } else {
      if (this.selecionados.length > 1) {
        this.testaPlanosIguais();
      } else {
        this.recebePlano(this.enviarPlanos());
      }
    }
  }

  enviarPlanos() {
    const slPlan: Plano[] = new Array<Plano>();
    let mensage: string;
    let obj: any;
    let vlTotal = 0;
    // console.log(this.selecionados);

    if (this.selecionados.length === 0) {
      this.mostrarPagamento = false;
      mensage = 'info|O carrinho está vazio! Por favor escolha pelo menos 1 (Um) Plano.';
      this.alertMensage(mensage);
    } else {
      this.selecionados.forEach(plano => {
        slPlan.push(plano);
        const vlPreco = plano.price;
        const vlDesconto = (plano.price * (plano.discount / 100));
        const vlPlan = +(vlPreco - vlDesconto).toFixed(2);

        vlTotal += vlPlan;
      });

      // if (this.voucher) {
      //   const descontoVoucher = vlTotal * (this.voucher.discount / 100);
      //   vlTotal = +(vlTotal - descontoVoucher).toFixed(2);
      // }
      this.mostrarPagamento = true;

      this.planosSelecionados = obj;

      return obj = {
        total: vlTotal,
        selec: slPlan
      };
    }
  }

  adcionaPlano(plano: Plano) {
    if (this.selecionados.indexOf(plano) === -1) {
      this.selecionados.push(plano);
    }
  }

  removePlano(plano: Plano) {
    let ind: number;
    ind = this.selecionados.indexOf(plano);
    if (ind > -1) {
      this.removidos = this.selecionados.splice(ind, 1);
    }
  }

  testaPlanosIguais() {
    let men: string;
    if (!this.selecionados.every(pl => pl.isAnual === true) || !this.selecionados.every(pl => pl.isAnual === false)
    ) {
      // envio a mensagem
      men = 'info|Voce selecionou planos com periodicidades diferentes. Por favor escolha somente planos com a mesma periodicidade';
      this.alertMensage(men);
      // fecho a tela de pagamento
      this.mostrarPagamento = false;
      // diseleciono os planos
      this.selecionados.forEach(plano => {
        plano.checked = false;
        this.selecionados = new Array<Plano>();
      });
    }
  }

  fecharpgto(event: boolean) {
    this.mostrarPagamento = event;
    this.ckPlano.checked = false;
    this.planos.forEach(plano => {
      plano.checked = false;
    })
  }

  trataRetorno(event: number) {
    if (event !== 0) {
      this._statusPgto.resolverStatusPgto();
    }
  }

  alertMensage(mensage: string) {
    const dialogRef = this._dialog.open(AlertDialogComponent, {
      width: '450px',
      data: mensage
    });
  }

  buscaPlanoUsuario() {
    this._service.buscaPlanoDoUsuario(
      parseInt(localStorage.getItem('userID'))
    ).subscribe(planoDoUsuario => {
      if (planoDoUsuario.length > 0) {
        for (let i = 0; i < planoDoUsuario.length; i++) {
          if (planoDoUsuario[i].planID == 7) {
            this._userService.buscarProfissaoPorUsuario(
              parseInt(localStorage.getItem('userID'))
            ).subscribe(user => {
              this.profissao = user.profession;
            });

            if (planoDoUsuario[i].active === 0) {
              this.planoTrialFinalizado = true;
            }
          }
        }
      }
    }, erro => {
      console.log('Erro:', erro);
    });
  }

  verificaCEP(zipcode: string): boolean {
    if (zipcode.length === 8) {
      return true;
    }

    return false;
  }

  buscaCEP() {
    let cep = this.enderecoForm.get('CEP').value;

    if (cep !== '') {
      if (this.verificaCEP(cep)) {
        // this.toastr.infoToastr('Buscando CEP...',"CEP OK!!!");
        this._viaCEPService.getEnderecoPorCEP(cep)
          .subscribe(
            address => {
              if (address.erro === true) {
                this.toastr.errorToastr('CEP não encontrado', 'Desculpe',
                  {
                    position: 'top-right',
                    toastTimeout: 4000
                  }
                );
                // this.limpaDadosEndereco();
              } else {
                this.populaDadosFormCEP(address);
                this.toastr.successToastr('Endereço encontrado', 'Tudo OK',
                  {
                    position: 'top-right',
                    toastTimeout: 4000
                  }
                );
              }
            },
            error => {
              this.toastr.errorToastr('Ha algo de errado no CEP digitado.', 'Desculpe',
                {
                  position: 'top-right',
                  toastTimeout: 4000
                }
              );
            }
          );
      } else {
        this.toastr.errorToastr('Digite um CEP válido', 'Desculpe',
          {
            position: 'top-right',
            toastTimeout: 4000
          }
        );
      }
    }
  }

  populaDadosFormCEP(dados: Endereco) {
    this.enderecoForm.patchValue({
      Endereco: dados.logradouro,
      Bairro: dados.bairro,
      Cidade: dados.localidade,
      Estado: dados.uf,
      Pais: 'Brasil',
      // cep: dados.cep
    });
  }

  maskCep(cep: string) {
    cep = cep.replace(/\D/g, "");
    cep = cep.replace(/[^\w\s]/gi, "");

    this.inEndCEP.nativeElement.value = cep;
  }

  passarDadosPagamento() {
    const dadosUsuario = {
      cpf: this.inCPF.nativeElement.value,
      rua: this.inEndRua.nativeElement.value,
      numero: this.inEndNumero.nativeElement.value,
      complemento: this.inEndComplem.nativeElement.value,
      bairro: this.inEndBairro.nativeElement.value,
      cidade: this.inEndCidade.nativeElement.value,
      estado: this.inEndEstado.nativeElement.value,
      pais: this.inEndPais.nativeElement.value,
      cep: this.inEndCEP.nativeElement.value
    };

    this.dados = dadosUsuario;

    return this.dados;
  }

  mostrarPacelamento() {
    this.planosSelecionados =
      this.planosSelecionados.selec.forEach(plano => {
        this.showParcelamento = plano.isAnual;
        if (plano.isAnual == true)
          this.child.parcelamento = '01';
      });
  }

  //! Método somente para menos de dois planos
  recebePlano(planos: any) {
    this.planosSelecionados = planos;
    this.mostrarPacelamento();
  }

  changeSelect() {
    if (this.SlParcelamento && this.SlParcelamento.toString()) {
      // this.parcelamento = this.slParcelamento.value.toString();
      this.child.parcelamento = this.SlParcelamento['value'].toString();
    } else {
      this.child.parcelamento = '';
    }
  }

  blurVoucher() {
    this.voucherCode = this.inVoucher.nativeElement.value;
    if (this.voucherCode && this.inVoucher.nativeElement.value) {
      this.verificaVoucher(this.voucherCode);
    } else {
      this.selecionados = this.planos;
      this.voucher = null;
    }
  }

  carregaPlanoVoucher() {
    this._service.buscarPlanosPorVoucher(this.voucher.id).subscribe(
      plano => {
        if (plano) {
          if (plano.isAnual && this.voucher.isAnual) {
            this.planosAnual = [];
            this.planosAnual.push(plano);
            this.selecionados = this.planosAnual;
          } else if (!plano.isAnual && !this.voucher.isAnual) {
            this.planosMensal = [];
            this.planosMensal.push(plano);
            this.selecionados = this.planosMensal;
          }
        }
      },
      erro => {
        console.log(erro);
      }
    )
  }

  verificaVoucher(voucher) {
    this.voucher = null;
    if (voucher) {
      this._voucher.getVoucherByCode(voucher)
        .subscribe(
          voucher => {
            this.initVoucher = true
            if (voucher) {
              var voucherTemp = voucher;
              this.planos.forEach(planos => {
                if (planos.isAnual && voucherTemp.isAnual) {
                  this.voucher = voucher;
                } else if (!planos.isAnual && !voucherTemp.isAnual) {
                  this.voucher = voucher;
                }
              });
              if (this.voucher) {
                this.carregaPlanoVoucher();
              }

              this.valorDesconto = ((this.planos[0].price - (this.planos[0].price * (this.planos[0].discount / 100))) - (this.planos[0].price * (this.voucher.discount / 100))).toFixed(2);
              if (this.voucher) {
                this.toastr.successToastr('Cupom aplicado', 'Tudo OK',
                  {
                    position: 'top-right',
                    toastTimeout: 4000
                  }
                );
              } else {
                this.toastr.errorToastr('Cupom inválido para o plano atual', 'Desculpe',
                  {
                    position: 'top-right',
                    toastTimeout: 4000
                  }
                );
              }

            } else {
              this.voucher = null;
              this.carregarPlanos();
              const obj = {
                total: 99,
                selec: this.planos
              };
              this.recebePlano(obj);
              this.toastr.errorToastr('Cupom inválido ou expirado', 'Desculpe',
                {
                  position: 'top-right',
                  toastTimeout: 4000
                }
              );
            }
          },
          erro => {
            console.log(erro);
          }
        );
    }

  }
  passarDadosVoucher() {
    if (this.voucher) {
      return this.voucher;
    }
  }
}
