import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-estrutura-osseas',
  templateUrl: './estrutura-osseas.component.html',
  styleUrls: ['./estrutura-osseas.component.css']
})
export class EstruturaOsseasComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;
  @ViewChild('rdUmFratura') rdUmFratura: MatRadioButton;
  @ViewChild('rdDoisFratura') rdDoisFratura: MatRadioButton;
  @ViewChild('rdUmFraturaCom') rdUmFraturaCom: MatRadioButton;
  @ViewChild('rdDoisFraturasCom') rdDoisFraturasCom: MatRadioButton;
  @ViewChild('rdUmTrepanacao') rdUmTrepanacao: MatRadioButton;
  @ViewChild('rdDoisTrepanacoes') rdDoisTrepanacoes: MatRadioButton;
  @ViewChild('rdBasilar') rdBasilar: MatRadioButton;
  @ViewChild('rdAngulacao') rdAngulacao: MatRadioButton;
  @ViewChild('rdPlasti') rdPlasti: MatRadioButton;

  @ViewChild('ckHiperostose') ckHiperostose: MatCheckbox;
  @ViewChild('ckFraturaSem') ckFraturaSem: MatCheckbox;
  @ViewChild('ckFraturaCom') ckFraturaCom: MatCheckbox;
  @ViewChild('ckTrepanacao') ckTrepanacao: MatCheckbox;
  @ViewChild('ckCraniotomia') ckCraniotomia: MatCheckbox;
  @ViewChild('ckCranioPterional') ckCranioPterional: MatCheckbox;
  @ViewChild('ckCranioSuboccipital') ckCranioSuboccipital: MatCheckbox;
  @ViewChild('ckCraniectomia') ckCraniectomia: MatCheckbox;
  @ViewChild('ckHemangioma') ckHemangioma: MatCheckbox;
  @ViewChild('ckLiticas') ckLiticas: MatCheckbox;
  @ViewChild('ckPartMoles') ckPartMoles: MatCheckbox;
  @ViewChild('ckInvagina') ckInvagina: MatCheckbox;
  @ViewChild('ckSuspeita') ckSuspeita: MatCheckbox;

  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slLado') slLado: MatSelect;
  @ViewChild('slLocalizacaoCom') slLocalizacaoCom: MatSelect;
  @ViewChild('slLadoCom') slLadoCom: MatSelect;
  @ViewChild('slLocalizacaoTre') slLocalizacaoTre: MatSelect;
  @ViewChild('slLadoTre') slLadoTre: MatSelect;
  @ViewChild('slLocalizacaoCra') slLocalizacaoCra: MatSelect;
  @ViewChild('slLadoCra') slLadoCra: MatSelect;
  @ViewChild('slLocalizacaoCraniec') slLocalizacaoCraniec: MatSelect;
  @ViewChild('slLadoCraniec') slLadoCraniec: MatSelect;
  @ViewChild('slLocalizacaoHeman') slLocalizacaoHeman: MatSelect;
  @ViewChild('slLadoHeman') slLadoHeman: MatSelect;
  @ViewChild('slFonAnterior') slFonAnterior: MatSelect;
  @ViewChild('slFonPosterior') slFonPosterior: MatSelect;

  @ViewChildren('FraturasChildren') FraturasChildren: QueryList<any>;
  @ViewChildren('FraturasComChildren') FraturasComChildren: QueryList<any>;
  @ViewChildren('TrepanacoesChildren') TrepanacoesChildren: QueryList<any>;

  localizacoes: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal' },
    { value: 'parietal', viewValue: 'Parietal' },
    { value: 'temporal', viewValue: 'Temporal' },
    { value: 'occipital', viewValue: 'Occipital' },
    { value: 'frontoparietal', viewValue: 'Frontoparietal' },
    { value: 'frontotemporal', viewValue: 'Frontotemporal' },
    { value: 'parietotemporal', viewValue: 'Parietotemporal' },
    { value: 'parieto-occipital', viewValue: 'Parieto-occipital' }
  ];

  lados: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' }
  ];

  fontanelas: ArrayDropDown[] = [
    { value: 'aberta', viewValue: 'Aberta' },
    { value: 'fechada', viewValue: 'Fechada' }
  ];


  showAndHideTerms: any;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  obj: any;
  objetofilho: any;

  constructor(private _validacoes: Validacoes,
    private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahFraturaSem: false,
      sahUmaFratura: false,
      sahDuasFraturas: false,
      sahFraturaCom: false,
      sahUmaFraturasCom: false,
      sahDuasFraturasCom: false,
      sahTrepanacao: false,
      sahUmaTrepanacao: false,
      sahDuasTrepanacoes: false,
      sahCraniotomia: false,
      sahPterional: false,
      sahSuboccipital: false,
      sahCraniectomia: false,
      sahHemangioma: false,
      sahLiticas: false,
      sahInvagina: false,
      sahSuspeita: false,
    };
    this.lesoesForm = this._fb.group({
      fraturaSem: this._fb.array([this.addFraturaSemGrup()]),
      fraturaCom: this._fb.array([this.addFraturaComGrup()]),
      trepanacoes: this._fb.array([this.addTrepanacoesGrup()]),
    });
  }

  addFraturaSemGrup() {
    return this._fb.group({
      localizacao: [],
      lado: [],
    });
  }

  addFraturaComGrup() {
    return this._fb.group({
      localizacaoCom: [],
      ladoCom: [],
    });
  }

  addTrepanacoesGrup() {
    return this._fb.group({
      localizacaoTre: [],
      ladoTre: [],
    });
  }

  addFraturaSem() {
    this.fraturaSemArray.push(this.addFraturaSemGrup());
    this.catchIDs();
  }

  removeFraturaSem(index) {
    this.fraturaSemArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get fraturaSemArray() {
    return <FormArray>this.lesoesForm.get('fraturaSem');
  }

  addFraturaCom() {
    this.fraturaComArray.push(this.addFraturaComGrup());
    this.catchIDs();
  }

  removeFraturaCom(index) {
    this.fraturaComArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get fraturaComArray() {
    return <FormArray>this.lesoesForm.get('fraturaCom');
  }

  addTrepanacoes() {
    this.trepanacoesArray.push(this.addTrepanacoesGrup());
    this.catchIDs();
  }

  removeTrepanacoes(index) {
    this.trepanacoesArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get trepanacoesArray() {
    return <FormArray>this.lesoesForm.get('trepanacoes');
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahFraturaSem = false;
    this.showAndHideTerms.sahFraturaCom = false;
    this.showAndHideTerms.sahTrepanacao = false;
    this.showAndHideTerms.sahCraniotomia = false;
    this.showAndHideTerms.sahCraniectomia = false;
    this.showAndHideTerms.sahHemangioma = false;
    this.showAndHideTerms.sahLiticas = false;
    this.showAndHideTerms.sahInvagina = false;
    this.showAndHideTerms.sahSuspeita = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahFraturaSem = false;
    this.showAndHideTerms.sahFraturaCom = false;
    this.showAndHideTerms.sahTrepanacao = false;
    this.showAndHideTerms.sahCraniotomia = false;
    this.showAndHideTerms.sahCraniectomia = false;
    this.showAndHideTerms.sahHemangioma = false;
    this.showAndHideTerms.sahLiticas = false;
    this.showAndHideTerms.sahInvagina = false;
    this.showAndHideTerms.sahSuspeita = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickHiperostose() {
    this.mudaAlterado();
    this.catchIDs();
  }

  clickFraturaSem() {
    if (this.showAndHideTerms.sahFraturaSem === false) {
      this.showAndHideTerms.sahFraturaSem = true;
      this.showAndHideTerms.sahUmaFratura = true;
    } else {
      this.showAndHideTerms.sahFraturaSem = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickUmFratura() {
    this.showAndHideTerms.sahUmaFratura = true;
    this.showAndHideTerms.sahDuasFraturas = false;
  }
  clickDoisFratura() {
    this.showAndHideTerms.sahDuasFraturas = true;
    this.showAndHideTerms.sahUmaFratura = false;
  }

  clickFraturaCom() {
    if (this.showAndHideTerms.sahFraturaCom === false) {
      this.showAndHideTerms.sahFraturaCom = true;
      this.showAndHideTerms.sahUmaFraturasCom = true;
    } else {
      this.showAndHideTerms.sahFraturaCom = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickUmFraturaCom() {
    this.showAndHideTerms.sahUmaFraturasCom = true;
    this.showAndHideTerms.sahDuasFraturasCom = false;
  }
  clickDoisFraturasCom() {
    this.showAndHideTerms.sahDuasFraturasCom = true;
    this.showAndHideTerms.sahUmaFraturasCom = false;
  }

  clickTrepanacao() {
    if (this.showAndHideTerms.sahTrepanacao === false) {
      this.showAndHideTerms.sahTrepanacao = true;
      this.showAndHideTerms.sahUmaTrepanacao = true;
    } else {
      this.showAndHideTerms.sahTrepanacao = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickUmTrepanacao() {
    this.showAndHideTerms.sahUmaTrepanacao = true;
    this.showAndHideTerms.sahDuasTrepanacoes = false;
  }
  clickDoisTrepanacoes() {
    this.showAndHideTerms.sahDuasTrepanacoes = true;
    this.showAndHideTerms.sahUmaTrepanacao = false;
  }

  clickCraniotomia() {
    if (this.showAndHideTerms.sahCraniotomia === false) {
      this.showAndHideTerms.sahCraniotomia = true;
      this.showAndHideTerms.sahPterional = true;
      this.showAndHideTerms.sahSuboccipital = true;
    } else {
      this.showAndHideTerms.sahCraniotomia = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickCranioPterional() {
    if (this.showAndHideTerms.sahPterional === true) {
      this.showAndHideTerms.sahPterional = false;
      this.showAndHideTerms.sahSuboccipital = true;
    } else {
      this.showAndHideTerms.sahPterional = true;
      this.showAndHideTerms.sahSuboccipital = true;
    }
    this.catchIDs();
  }

  clickCranioSuboccipital() {
    this.catchIDs();
  }

  clickCraniectomia() {
    if (this.showAndHideTerms.sahCraniectomia === false) {
      this.showAndHideTerms.sahCraniectomia = true;
    } else {
      this.showAndHideTerms.sahCraniectomia = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickHemangioma() {
    if (this.showAndHideTerms.sahHemangioma === false) {
      this.showAndHideTerms.sahHemangioma = true;
    } else {
      this.showAndHideTerms.sahHemangioma = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickLiticas() {
    if (this.showAndHideTerms.sahLiticas === false) {
      this.showAndHideTerms.sahLiticas = true;
    } else {
      this.showAndHideTerms.sahLiticas = false;
    }
    this.mudaAlterado();
    this.catchIDs()  
  }

  clickPartMoles() {
    this.catchIDs()
  }

  clickInvagina() {
    if (this.showAndHideTerms.sahInvagina === false) {
      this.showAndHideTerms.sahInvagina = true;
    } else {
      this.showAndHideTerms.sahInvagina = false;
    }
    this.mudaAlterado();
    this.catchIDs()  
  }

  clickSuspeita() {
    if (this.showAndHideTerms.sahSuspeita === false) {
      this.showAndHideTerms.sahSuspeita = true;
    } else {
      this.showAndHideTerms.sahSuspeita = false;
    }
    this.mudaAlterado();
    this.catchIDs()  
  }

  clickInva() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }


  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    } else {

      if (this.ckHiperostose && this.ckHiperostose.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckHiperostose.id);
      }

      if (this.countLesoes > 0) {
        this.emitFraturaSemLesao();
      } else {
        this.emitFraturaSem();
      }

      if (this.countLesoes > 0) {
        this.emitFraturaComLesao();
      } else {
        this.emitFraturaCom();
      }

      if (this.countLesoes > 0) {
        this.emitTrepanacaoLesao();
      } else {
        this.emitTrepanacao();
      }

      if (this.countLesoes > 0) {
        this.emitCraniotomiaLesao();
      } else {
        this.emitCraniotomia();
      }

      if (this.countLesoes > 0) {
        this.emitCraniectomiaLesao();
      } else {
        this.emitCraniectomia();
      }

      if (this.countLesoes > 0) {
        this.emitHemangiomaLesao();
      } else {
        this.emitHemangioma();
      }

      if (this.countLesoes > 0) {
        this.emitLiticasLesao();
      } else {
        this.emitLiticas();
      }

      if (this.countLesoes > 0) {
        this.emitInvaginaLesao();
      } else {
        this.emitInvagina();
      }

      if (this.countLesoes > 0) {
        this.emitSuspeitaLesao();
      } else {
        this.emitSuspeita();
      }

    }

    this.listaCompleta.emit();
  }

  emitFraturaSem() {
    if (this.ckFraturaSem && this.ckFraturaSem.checked) {
      this.countLesoes++
      this.termoSelecionado.emit(this.ckFraturaSem.id);

      if (this.rdUmFratura && this.rdUmFratura.checked) {
        this.termoSelecionado.emit(this.rdUmFratura.id);

        if (this.slLocalizacao && this.slLocalizacao.value) {
          const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
        if (this.slLado && this.slLado.value) {
          const objeto = { elemento: this.slLado, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdDoisFratura && this.rdDoisFratura.checked) {
        this.termoSelecionado.emit(this.rdDoisFratura.id);


        this.linha = 0;

        this.FraturasChildren.forEach(obj => {
          if (+obj.id === 4332)
            this.linha++;
          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.obj);
        });

      }
    }
  }
  emitFraturaSemLesao() {
    if (this.ckFraturaSem && this.ckFraturaSem.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckFraturaSem.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rdUmFratura && this.rdUmFratura.checked) {
        const objeto = { id: this.rdUmFratura.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.slLocalizacao && this.slLocalizacao.value) {
          const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
        if (this.slLado && this.slLado.value) {
          const objeto = { elemento: this.slLado, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdDoisFratura && this.rdDoisFratura.checked) {
        const objeto = { id: this.rdDoisFratura.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        this.linha = 0;

        this.FraturasChildren.forEach(obj => {
          if (+obj.id === 4332)
            this.linha++;
          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.obj);
        });

      }
    }
  }

  emitFraturaCom() {
    if (this.ckFraturaCom && this.ckFraturaCom.checked) {
      this.countLesoes++
      this.termoSelecionado.emit(this.ckFraturaCom.id);

      if (this.rdUmFraturaCom && this.rdUmFraturaCom.checked) {
        this.termoSelecionado.emit(this.rdUmFraturaCom.id);

        if (this.slLocalizacaoCom && this.slLocalizacaoCom.value) {
          const objeto = { elemento: this.slLocalizacaoCom, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
        if (this.slLadoCom && this.slLadoCom.value) {
          const objeto = { elemento: this.slLadoCom, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdDoisFraturasCom && this.rdDoisFraturasCom.checked) {
        this.termoSelecionado.emit(this.rdDoisFraturasCom.id);

        this.linha = 0;

        this.FraturasComChildren.forEach(obj => {
          if (+obj.id === 4337)
            this.linha++;
          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.obj);
        });

      }
    }
  }
  emitFraturaComLesao() {
    if (this.ckFraturaCom && this.ckFraturaCom.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckFraturaCom.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rdUmFraturaCom && this.rdUmFraturaCom.checked) {
        const objeto = { id: this.rdUmFraturaCom.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.slLocalizacaoCom && this.slLocalizacaoCom.value) {
          const objeto = { elemento: this.slLocalizacaoCom, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
        if (this.slLadoCom && this.slLadoCom.value) {
          const objeto = { elemento: this.slLadoCom, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdDoisFraturasCom && this.rdDoisFraturasCom.checked) {
        const objeto = { id: this.rdDoisFraturasCom.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        this.linha = 0;

        this.FraturasComChildren.forEach(obj => {
          if (+obj.id === 4337)
            this.linha++;
          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.obj);
        });

      }
    }
  }

  emitTrepanacao() {
    if (this.ckTrepanacao && this.ckTrepanacao.checked) {
      this.countLesoes++
      this.termoSelecionado.emit(this.ckTrepanacao.id);

      if (this.rdUmTrepanacao && this.rdUmTrepanacao.checked) {
        this.termoSelecionado.emit(this.rdUmTrepanacao.id);

        if (this.slLocalizacaoTre && this.slLocalizacaoTre.value) {
          const objeto = { elemento: this.slLocalizacaoTre, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
        if (this.slLadoTre && this.slLadoTre.value) {
          const objeto = { elemento: this.slLadoTre, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdDoisTrepanacoes && this.rdDoisTrepanacoes.checked) {
        this.termoSelecionado.emit(this.rdDoisTrepanacoes.id);

        this.linha = 0;

        this.TrepanacoesChildren.forEach(obj => {
          if (+obj.id === 4342)
            this.linha++;
          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.obj);
        });

      }
    }
  }
  emitTrepanacaoLesao() {
    if (this.ckTrepanacao && this.ckTrepanacao.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckTrepanacao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rdUmTrepanacao && this.rdUmTrepanacao.checked) {
        const objeto = { id: this.rdUmTrepanacao.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.slLocalizacaoTre && this.slLocalizacaoTre.value) {
          const objeto = { elemento: this.slLocalizacaoTre, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
        if (this.slLadoTre && this.slLadoTre.value) {
          const objeto = { elemento: this.slLadoTre, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdDoisTrepanacoes && this.rdDoisTrepanacoes.checked) {
        const objeto = { id: this.rdDoisTrepanacoes.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        this.linha = 0;

        this.TrepanacoesChildren.forEach(obj => {
          if (+obj.id === 4342)
            this.linha++;
          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.obj);
        });
      }
    }
  }

  emitCraniotomia() {
    if (this.ckCraniotomia && this.ckCraniotomia.checked) {
      this.countLesoes++
      this.termoSelecionado.emit(this.ckCraniotomia.id);

      if (this.ckCranioPterional && this.ckCranioPterional.checked) {
        this.termoSelecionado.emit(this.ckCranioPterional.id);
      }
      if (this.ckCranioSuboccipital && this.ckCranioSuboccipital.checked) {
        this.termoSelecionado.emit(this.ckCranioSuboccipital.id);
      }

      if (this.slLocalizacaoCra && this.slLocalizacaoCra.value) {
        const objeto = { elemento: this.slLocalizacaoCra, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLadoCra && this.slLadoCra.value) {
        const objeto = { elemento: this.slLadoCra, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitCraniotomiaLesao() {
    if (this.ckCraniotomia && this.ckCraniotomia.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCraniotomia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckCranioPterional && this.ckCranioPterional.checked) {
        const objeto = { id: this.ckCranioPterional.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      if (this.ckCranioSuboccipital && this.ckCranioSuboccipital.checked) {
        const objeto = { id: this.ckCranioSuboccipital.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      if (this.slLocalizacaoCra && this.slLocalizacaoCra.value) {
        const objeto = { elemento: this.slLocalizacaoCra, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLadoCra && this.slLadoCra.value) {
        const objeto = { elemento: this.slLadoCra, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

    }
  }

  emitCraniectomia() {
    if (this.ckCraniectomia && this.ckCraniectomia.checked) {
      this.countLesoes++
      this.termoSelecionado.emit(this.ckCraniectomia.id);

      if (this.slLocalizacaoCraniec && this.slLocalizacaoCraniec.value) {
        const objeto = { elemento: this.slLocalizacaoCraniec, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLadoCraniec && this.slLadoCraniec.value) {
        const objeto = { elemento: this.slLadoCraniec, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitCraniectomiaLesao() {
    if (this.ckCraniectomia && this.ckCraniectomia.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCraniectomia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacaoCraniec && this.slLocalizacaoCraniec.value) {
        const objeto = { elemento: this.slLocalizacaoCraniec, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLadoCraniec && this.slLadoCraniec.value) {
        const objeto = { elemento: this.slLadoCraniec, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

    }
  }

  emitHemangioma() {
    if (this.ckHemangioma && this.ckHemangioma.checked) {
      this.countLesoes++
      this.termoSelecionado.emit(this.ckHemangioma.id);

      if (this.slLocalizacaoHeman && this.slLocalizacaoHeman.value) {
        const objeto = { elemento: this.slLocalizacaoHeman, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLadoHeman && this.slLadoHeman.value) {
        const objeto = { elemento: this.slLadoHeman, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitHemangiomaLesao() {
    if (this.ckHemangioma && this.ckHemangioma.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckHemangioma.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacaoHeman && this.slLocalizacaoHeman.value) {
        const objeto = { elemento: this.slLocalizacaoHeman, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLadoHeman && this.slLadoHeman.value) {
        const objeto = { elemento: this.slLadoHeman, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

    }
  }

  emitLiticas() {
    if (this.ckLiticas && this.ckLiticas.checked) {
      this.countLesoes++
      this.termoSelecionado.emit(this.ckLiticas.id);

      if (this.ckPartMoles && this.ckPartMoles.checked) {
        this.termoSelecionado.emit(this.ckPartMoles.id);
      }

    }
  }
  emitLiticasLesao() {
    if (this.ckLiticas && this.ckLiticas.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckLiticas.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckPartMoles && this.ckPartMoles.checked) {
        const objeto = { id: this.ckPartMoles.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

    }
  }

  emitInvagina() {
    if (this.ckInvagina && this.ckInvagina.checked) {
      this.countLesoes++
      this.termoSelecionado.emit(this.ckInvagina.id);

      if (this.rdBasilar && this.rdBasilar.checked) {
        this.termoSelecionado.emit(this.rdBasilar.id);
      }
      if (this.rdAngulacao && this.rdAngulacao.checked) {
        this.termoSelecionado.emit(this.rdAngulacao.id);
      }
      if (this.rdPlasti && this.rdPlasti.checked) {
        this.termoSelecionado.emit(this.rdPlasti.id);
      }
    }
  }
  emitInvaginaLesao() {
    if (this.ckInvagina && this.ckInvagina.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckInvagina.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rdBasilar && this.rdBasilar.checked) {
        const objeto = { id: this.rdBasilar.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
      if (this.rdAngulacao && this.rdAngulacao.checked) {
        const objeto = { id: this.rdAngulacao.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
      if (this.rdPlasti && this.rdPlasti.checked) {
        const objeto = { id: this.rdPlasti.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  emitSuspeita() {
    if (this.ckSuspeita && this.ckSuspeita.checked) {
      this.countLesoes++
      this.termoSelecionado.emit(this.ckSuspeita.id);

      if (this.slFonAnterior && this.slFonAnterior.value) {
        const objeto = { elemento: this.slFonAnterior, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slFonPosterior && this.slFonPosterior.value) {
        const objeto = { elemento: this.slFonPosterior, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitSuspeitaLesao() {
    if (this.ckSuspeita && this.ckSuspeita.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckSuspeita.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slFonAnterior && this.slFonAnterior.value) {
        const objeto = { elemento: this.slFonAnterior, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.slFonPosterior && this.slFonPosterior.value) {
        const objeto = { elemento: this.slFonPosterior, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

    }
  }

  ajustaArrayDinamico() {
    const arrayPresTotal = this.fraturaSemArray.length;
    for (let i = 0; i <= arrayPresTotal; i++) {
      const index = this.fraturaSemArray.length - 1
      if (index != 0) {
        this.fraturaSemArray.removeAt(index);
      }
    }
    this.fraturaSemArray.reset();

    const arrayUniTotal = this.fraturaComArray.length;
    for (let i = 0; i <= arrayUniTotal; i++) {
      const index = this.fraturaComArray.length - 1
      if (index != 0) {
        this.fraturaComArray.removeAt(index);
      }
    }
    this.fraturaComArray.reset();

    const arrayVarTotal = this.trepanacoesArray.length;
    for (let i = 0; i <= arrayVarTotal; i++) {
      const index = this.trepanacoesArray.length - 1
      if (index != 0) {
        this.trepanacoesArray.removeAt(index);
      }
    }
    this.trepanacoesArray.reset();

  }

  mudaAlterado() {

    if ((this.ckHiperostose && this.ckHiperostose.checked) ||
        (this.ckFraturaSem && this.ckFraturaSem.checked) ||
        (this.ckFraturaCom && this.ckFraturaCom.checked) ||
        (this.ckTrepanacao && this.ckTrepanacao.checked) ||
        (this.ckCraniotomia && this.ckCraniotomia.checked) ||
        (this.ckCraniectomia && this.ckCraniectomia.checked) ||
        (this.ckHemangioma && this.ckHemangioma.checked) ||
        (this.ckLiticas && this.ckLiticas.checked) ||
        (this.ckInvagina && this.ckInvagina.checked) ||
        (this.ckSuspeita && this.ckSuspeita.checked)
        ) {
      this.rdAlterado.checked = true;
      this.rdNormal.checked = false;
    } else {
      this.rdNormal.checked = true;
      this.rdAlterado.checked = false;
    }
  }

}
