import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-obstetrico-images-referencia',
  templateUrl: './obstetrico-images-referencia.component.html',
  styleUrls: ['./obstetrico-images-referencia.component.css']
})
export class ObstetricoImagesReferenciaComponent {

  constructor(
    public dialogRef: MatDialogRef<ObstetricoImagesReferenciaComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

    ClickNao(): void {
      this.dialogRef.close();
    }

}
