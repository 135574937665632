import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox, MatTextareaAutosize } from '@angular/material';

@Component({
  selector: 'app-indicacao-torax',
  templateUrl: './indicacao-torax.component.html',
  styleUrls: ['./indicacao-torax.component.css']
})
export class IndicacaoToraxComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdIndicacaoCOVID') rdIndicacaoCOVID: MatRadioButton;
  @ViewChild('rdCOVIDrastreamento') rdCOVIDrastreamento: MatRadioButton;
  @ViewChild('rdIndicacao') rdIndicacao: MatRadioButton;

  @ViewChild('inOutro') inOutro: ElementRef;

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      outro: false,
    };
  }

  clickDefault() {
    this.showAndHideTerms.outro = false;
    this.catchIDs();
  }

  clickOutro() {
    this.showAndHideTerms.outro = true;
    this.catchIDs();
  }

  blurText() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }
    
    if (this.rdIndicacaoCOVID.checked) {
      this.termoSelecionado.emit(this.rdIndicacaoCOVID.id);
    }

    if (this.rdCOVIDrastreamento.checked) {
      this.termoSelecionado.emit(this.rdCOVIDrastreamento.id);
    }

    if (this.rdIndicacao.checked) {
      this.termoSelecionado.emit(this.rdIndicacao.id);

      if (this.inOutro && this.inOutro.nativeElement.value) {
        const objeto = { elemento: this.inOutro.nativeElement };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
