import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-espacos-discais',
  templateUrl: './espacos-discais.component.html',
  styleUrls: ['./espacos-discais.component.css']
})
export class EspacosDiscaisComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdReducaoEspaco') rdReducaoEspaco: MatRadioButton;

  showAndHideTerms;
  countLesoes;
  nivelReducaoEspaco;
  nivelReducaoEspacoFormGroup:FormGroup;
  sahReducaoEspaco:boolean;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {

    this.iniciaFormReducaoEspaco();

    this.nivelReducaoEspaco=[
      { name: 'T1-T2', id: 'T1-T2', value:' T1-T2' },
      { name: 'T2-T3', id: 'T2-T3', value:' T2-T3' },
      { name: 'T3-T4', id: 'T3-T4', value:' T3-T4' },
      { name: 'T4-T5', id: 'T4-T5', value:' T4-T5' },
      { name: 'T5-T6', id: 'T5-T6', value:' T5-T6'},
      { name: 'T6-T7', id: 'T6-T7', value:' T6-T7' },
      { name: 'T7-T8', id: 'T7-T8', value:' T7-T8' },
      { name: 'T8-T9', id: 'T8-T9', value:' T8-T9'},
      { name: 'T9-T10', id: 'T9-T10', value:' T9-T10'},
      { name: 'T10-T11', id: 'T10-T11', value:' T10-T11'},
      { name: 'T11-T12', id: 'T11-T12', value:' T11-T12'},
      { name: 'T12-L1', id: 'T12-L1', value:' T12-L1'}
    ]

    this.sahReducaoEspaco=false;
  }
  clickCheckboxReducaoEspaco(event) {
    let nivelReducaoEspaco = <FormArray>this.nivelReducaoEspacoFormGroup.get('nivelReducaoEspaco') as FormArray;

    if (event.checked) {
      nivelReducaoEspaco.push(new FormControl(event.source.value));
    } else {
      let iNivel = nivelReducaoEspaco.controls.findIndex(x => x.value === event.source.value);

      nivelReducaoEspaco.removeAt(iNivel);
    }

    this.catchIDs();
  }

  iniciaFormReducaoEspaco() {
    this.nivelReducaoEspacoFormGroup = this.fb.group({
      nivelReducaoEspaco: this.fb.array([])
    });
  }

  clickNormal(){
    this.sahReducaoEspaco = false;
    this.catchIDs();
  }
  clickReducaoEspaco(){
    this.sahReducaoEspaco = !this.sahReducaoEspaco;
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (!this.rdReducaoEspaco.checked) {
      this.iniciaFormReducaoEspaco();
    }

    if(this.rdNormal.checked){
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if(this.rdReducaoEspaco){
      this.emitReducao();
    }

    this.listaCompleta.emit();
  }

  emitReducao(){
    if(this.rdReducaoEspaco.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdReducaoEspaco.id);

      let posicao = this.nivelReducaoEspacoFormGroup.value.nivelReducaoEspaco;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if(posicao.length > 0){
        if(posicao.length == 1){
          let objeto = {id: 5100, value: posicao};
          let elemento = {elemento: objeto, isPrimeiraLesao: true};
          this.termosGenericos.emit(elemento);

        }else if(posicao.length > 1){
          let objeto = {id: 5101, value: posicaoTratada};
          let elemento = {elemento: objeto, isPrimeiraLesao: true};
          this.termosGenericos.emit(elemento);
        }
      }
    }
  }

  replaceVirgula(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }
}
