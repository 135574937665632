import { ArrayDropDown } from './../../../../../models/Helper/ArrayDropDown';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-costela-cervical',
  templateUrl: './costela-cervical.component.html',
  styleUrls: ['./costela-cervical.component.css']
})
export class CostelaCervicalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdCostela') rdCostela: MatRadioButton;

  @ViewChild('slLateralidade') slLateralidade: MatSelect;

  lateralidade: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'À direita' },
    { value: 'à esquerda', viewValue: 'À esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral ' },
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCostela: false,
    };
  }

  clickNaoCitar(){
    this.showAndHideTerms.sahCostela = false;
    this.catchIDs();
  }

  clickCostela(){
    this.showAndHideTerms.sahCostela = true;
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs(){
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }

    if (this.rdCostela.checked) {
      this.termoSelecionado.emit(this.rdCostela.id);
      if(this.slLateralidade && this.slLateralidade.value){
        const objeto = {elemento: this.slLateralidade, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
